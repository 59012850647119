export default function reducer(state = {
  cellPhone: null,
  userInfo: null,
  error: null,

}, action) {

  switch (action.type) {

    case "CODE_INPUT_PENDING":
      {
        return {
          ...state,
          cellPhone: null,
          userInfo: null,
          error: null
        };
      }

    case "CODE_INPUT_FULFILLED":
      {
        return {
          ...state,
          cellPhone: action.payload.cellPhone,
          userInfo: action.payload.userInfo,
          error: action.payload.error
        };
      }

    case "CODE_INPUT_REJECTED":
      {
        return {
          ...state,
          cellPhone: null,
          userInfo: null,
          error: null
        };
      }

    default:
      return state;
  }
}