import { makeStyles } from "@mui/styles";
import styled from 'styled-components';


export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },

  backButton: {
    textTransform: "capitalize",
    fontSize: "25px",
    fontWeight: "700",
    cursor: "pointer",
    float: "right",
    marginRight: "1.3rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
    },
  },

  title: {
    fontWeight: "bold",
  },

  content: {
    marginTop: "2rem",
    marginLeft: "2rem",
  },

  card: {
    border: "1px solid #E8E8E8",
    width: "33%",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  buttonCard: {
    marginTop: "3rem",
  },
}));

export const Container = styled.div`
  padding: 0rem 1rem 4rem 1rem;
  @media (min-width: 1020px) {
    max-width: 1000px;
    margin: 0px auto 150px auto;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
`;