import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  Input,
  OutlinedInput,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Snackbar,
  Button,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Modal,
  Paper,
} from '@mui/material';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';
import { userReadCellphone_v3, userReadCPF_v3 } from 'js/library/utils/API/apiUserRead';
import { setUserInfo_v1 } from 'js/library/utils/API/setUserInfo_v1.js';
import { RegisterUsersBatch } from 'js/library/utils/API/apiRegisterUsersBatch';
import { login_v3, findValueInArray, isAdminPage } from 'js/library/utils/helpers';
import { geocoding } from 'js/library/utils/API/apiGeocoding';
import { voucherSendAction } from 'js/core/actions/voucherSendAction';
import { SendVoucherBatch } from 'js/library/utils/API/Store/apiSendVoucher';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '+',
        5,
        5,
        ' ',
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TextMaskCustomCPF = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
};

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const VoucherSendExternal = ({
  location,
  voucherSendComponent,
  toggleSendVoucherModal,
  openSendVoucherModal,
  openModalSelectClients,
  loading,
}) => {
  // const { establishmentId, establishmentName } = location.state;

  const [establishmentId, setEstablishmentId] = useState('');
  const [establishmentName, setEstablishmentName] = useState('');
  const [clientList, setClientList] = useState([]);
  const [clientListByWalletSelect, setClientListByWalletSelect] = useState([]);
  const [clientPhone, setClientPhone] = useState('');
  const [templateId, setTemplateId] = useState('');
  // const [templateId] = useState(location.state.templateId);
  const [generator, setGenerator] = useState(false);
  const [qtdDisponivel, setQtdDisponivel] = useState(null);
  const [batchId, setBatchId] = useState(null);
  // const [batchList, setBatchList] = useState(null)
  // const [groupBatchList, setGroupBatchList] = useState({})
  // const [finalBatchList, setFinalBatchList] = useState({})
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [informationLoading, setInformationLoading] = useState(false);
  const [searchUserLoading, setSearchUserLoading] = useState(false);

  const [creatingUser, setCreatingUser] = useState(false);
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newBirthDate, setNewBirthDate] = useState('');
  const [newCPF, setNewCPF] = useState('');
  const [newZipCode, setNewZipCode] = useState('');
  const [newCountry] = useState('Brasil');
  const [newState, setNewState] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newNeighborhood, setNewNeighborhood] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newNumber, setNewNumber] = useState('');
  const [newExtra, setNewExtra] = useState('');
  const [newLatitude, setNewLatitude] = useState(0);
  const [newLongitude, setNewLongitude] = useState(0);

  const [uploadFile, setUploadFile] = useState(false);
  const [promotionType, setPromotionType] = useState('');

  const fileUploader = useRef(null);

  useEffect(() => {
    //Baixando dados da Voucher Template para controle da quantidade de Vouchers
    getVoucherTemplate(templateId).then((dataReceived) => {
      if (dataReceived) {
        setGenerator(dataReceived.generator);
        setQtdDisponivel(dataReceived.qtdDisponivel);
        setBatchId(dataReceived.generator ? 'AUTO' : null);
      }
    });

    window.pathname = null;
  }, [templateId, clientList]);

  useEffect(() => {
    const clientList = JSON.parse(localStorage.getItem('ClientsSelect'));
    const statePromotion = JSON.parse(localStorage.getItem('statePromotion'));

    if (clientList && statePromotion) {
      setEstablishmentId(clientList.location.dataEstabelecimento.id);
      setEstablishmentName(clientList.location.dataEstabelecimento.nome);
      setTemplateId(statePromotion.templateId);
      setClientListByWalletSelect(clientList.clients);

      const promoType = statePromotion.hasGroup
        ? 'Promoção Fidelidade'
        : statePromotion.type === 'promotion'
        ? 'Promoção'
        : statePromotion.type === 'product'
        ? 'Vitriine'
        : statePromotion.type === 'businessPartner' && 'Site externo';

      setPromotionType(promoType);
    } else {
      const { establishmentId, establishmentName } = location.state;
      console.log('entrou aqui');
      setTemplateId(location.state.templateId);
      setEstablishmentId(establishmentId);
      setEstablishmentName(establishmentName);

      const promoType = location.state.hasGroup
        ? 'Promoção Fidelidade'
        : location.state.type === 'promotion'
        ? 'Promoção'
        : location.state.type === 'product'
        ? 'Vitriine'
        : location.state.type === 'businessPartner' && 'Site externo';

      setPromotionType(promoType);
    }
  }, []);

  const searchUser = (cellphone) => {
    setSearchUserLoading(true);

    cellphone = '+55' + cellphone.replace(/\+55/g).replace(/\D+/g, '');

    if (cellphone.length >= 14) {
      userReadCellphone_v3(cellphone)
        .then((result) => {
          if (result) {
            const newClientList = [...clientList];
            newClientList.push(result.userInfo);
            setClientList(newClientList);

            setClientPhone('');
          } else {
            setCreatingUser(true);
          }

          setSearchUserLoading(false);
        })
        .catch((error) => {
          setSearchUserLoading(false);
        });
    } else {
      setSearchUserLoading(false);
    }
  };

  const searchUsers = async (cellphones) => {
    setSearchUserLoading(true);

    const promises = [];

    cellphones.map((cellphone) => {
      cellphone = '+' + cellphone.replace(/\D+/g, '');

      if (cellphone.length >= 14) {
        promises.push(userReadCellphone_v3(cellphone));
      }
      return cellphones;
    });

    Promise.all(promises)
      .then(async (results) => {
        const newClientList = [...clientList];

        //FILTRA OS USUARIOS SEM CADASTRO.
        const userWithoutRegistration = cellphones.filter((item, index) => {
          if (!results[index]) {
            return item;
          }
        });

        if (userWithoutRegistration.length !== 0) {
          const cellphoneFilteredList = [];
          userWithoutRegistration.forEach((phone) => {
            if (phone.length >= 14) {
              cellphoneFilteredList.push({ cellphone: phone });
              newClientList.push({
                name: 'Triiber',
                contactList: [{ type: 'cellPhone', value: phone }],
              });
            }
          });

          try {
            await RegisterUsersBatch(cellphoneFilteredList, establishmentId, establishmentName);
          } catch {
            setSearchUserLoading(false);
            setSnackBarMessage('Arquivo de usuários não carregado.');
            return;
          }
        }

        results.map((result) => {
          if (result) {
            newClientList.push(result.userInfo);
          }
          return results;
        });

        setClientList(newClientList);
        setSearchUserLoading(false);
      })
      .catch((error) => {
        setSearchUserLoading(false);
        setSnackBarMessage('Algo saiu errado.');
      });
  };

  const goBack = () => {
    toggleSendVoucherModal(false);
  };

  const checkInformation = (voucherConsumed, clientIndex) => {
    if (!generator && qtdDisponivel <= 0) {
      setSnackBarMessage('Essa promoção não possui cupons restantes.');
    } else {
      setInformationLoading(true);

      const data = [];

      const client = clientList[clientIndex];

      const cellPhone = findValueInArray(client.contactList, 'type', 'cellPhone').value;

      data.push([cellPhone + '@sms,triibo,com,br']);

      const fakeFile = new File(data, 'fakeFile.txt');

      voucherSendComponent(templateId, batchId, fakeFile, voucherConsumed, () => {
        setInformationLoading(false);

        const newClientList = [...clientList];
        newClientList.splice(clientIndex, 1);
        setClientList(newClientList);

        setSnackBarMessage(`Promoção ${voucherConsumed ? 'consumida' : 'enviada'} com sucesso.`);
      });
    }
  };

  const checkInformations = (voucherConsumed) => {
    if (!uploadFile) {
      if (clientListByWalletSelect.length > qtdDisponivel || (!generator && qtdDisponivel <= 0)) {
        setSnackBarMessage('Essa promoção não possui cupons restantes.');
        return;
      } else {
        setInformationLoading(true);

        let obj = {};

        const clientList = clientListByWalletSelect.map((client) => {
          return {
            clientID: client,
            templateID: templateId,
            consumed: voucherConsumed,
          };
        });

        obj.vouchers = clientList;

        SendVoucherBatch(obj)
          .then((res) => {
            setSnackBarMessage(
              `Promoções ${voucherConsumed ? 'consumidas' : 'enviadas'} com sucesso.`
            );
            setInformationLoading(false);
            setClientListByWalletSelect([]);
          })
          .catch((error) => {
            alert('erro');
          });
      }
    } else {
      if (clientList.length > qtdDisponivel || (!generator && qtdDisponivel <= 0)) {
        setSnackBarMessage('Essa promoção não possui cupons restantes.');
        return;
      } else {
        let data = [];
        let obj = {};

        clientList.map((client, i) => {
          const cellPhone = findValueInArray(client.contactList, 'type', 'cellPhone').value;

          data.push({
            clientID: cellPhone + '@sms,triibo,com,br',
            templateID: templateId,
            consumed: voucherConsumed,
          });

          return;
        });

        obj.vouchers = data;

        SendVoucherBatch(obj)
          .then((res) => {
            setSnackBarMessage(
              `Promoções ${voucherConsumed ? 'consumidas' : 'enviadas'} com sucesso.`
            );
            setInformationLoading(false);
            setClientList([]);
          })
          .catch((error) => {
            setSnackBarMessage('Erro ao enviar promoções');
          });
      }
    }
  };

  const handleNewZipCode = (value) => {
    value = value.replace(/[a-z]|[A-Z]|-/g, '');

    setNewZipCode(value);

    if (value.length >= 8) {
      axios.get(`https://viacep.com.br/ws/${value}/json/`).then((result) => {
        const response = result.data;

        setNewState(response.uf);
        setNewCity(response.localidade);
        setNewNeighborhood(response.bairro);
        setNewStreet(response.logradouro);
      });
    }
  };

  useEffect(() => {
    if (newStreet === '') {
      return;
    }
    function searchLatLong() {
      const address = encodeURIComponent(
        `${newStreet} ${newNumber} ${newNeighborhood} ${newCity} ${newState}`
      );

      geocoding(address)
        .then((result) => {
          if (result.status === 'OK' && result.results.length > 0) {
            setNewLatitude(result.results[0].geometry.location.lat);
            setNewLongitude(result.results[0].geometry.location.lng);
          } else {
            setNewLatitude(0);
            setNewLongitude(0);
          }
        })
        .catch((error) => {
          setNewLatitude(0);
          setNewLongitude(0);
        });
    }

    searchLatLong();
  }, [newStreet, newCity, newNeighborhood, newNumber, newState]);

  const clearForm = () => {
    setNewName('');
    setNewEmail('');
    setClientPhone('');
    setNewBirthDate('');
    setNewCPF('');
    setNewZipCode('');
    setNewState('');
    setNewCity('');
    setNewNeighborhood('');
    setNewStreet('');
    setNewNumber('');
    setNewExtra('');
  };

  const createUser = async () => {
    setCreateUserLoading(true);

    const cellphone = '+' + clientPhone.replace(/\D+/g, '');
    const cpf = newCPF.replace(/\D+/g, '');

    let cpfVerification = false;

    if (cpf !== '') {
      cpfVerification = await userReadCPF_v3(cpf);
    }

    if (cpfVerification !== false) {
      setCreateUserLoading(false);
      setSnackBarMessage('Erro ao cadastrar o usuário! O CPF já existe na base');
      return;
    }

    login_v3(cellphone)
      .then((replyAuth) => {
        if (replyAuth !== false) {
          let convertedDate = newBirthDate.split('-');
          convertedDate = new Date(
            parseInt(convertedDate[0], 10),
            parseInt(convertedDate[1] - 1, 10),
            parseInt(convertedDate[2], 10),
            0,
            0,
            0
          ).getTime();

          const userInfoCreation = {
            updateDate: Date.now(),
            syncType: 'merge',
            addressList: [
              {
                city: newCity,
                country: newCountry,
                extra: newExtra,
                googleAddr: `${newStreet}, ${newNumber} - ${newNeighborhood}, ${newCity} - ${newState}, ${newZipCode.substring(
                  0,
                  5
                )}-${newZipCode.substring(5, 8)}, Brasil`,
                latitude: newLatitude,
                longitude: newLongitude,
                neighbordhood: newNeighborhood,
                number: newNumber,
                state: newState,
                streetAve: newStreet,
                type: 'principal',
                zipCode: newZipCode,
              },
            ],
            contactList: [
              {
                type: 'email',
                value: newEmail,
              },
              {
                type: 'cellPhone',
                value: cellphone,
              },
            ],
            documentList:
              newCPF && newCPF !== ''
                ? [
                    {
                      type: 'cpf',
                      value: newCPF,
                    },
                  ]
                : null,
            birthDate: convertedDate,
            aliasName: newName,
            name: newName,
            uId: replyAuth.uid,
            triiboId: cellphone + '@sms,triibo,com,br',
          };

          setUserInfo_v1(replyAuth.uid, ['setUserInfo'], userInfoCreation)
            .then((response) => {
              if (response !== null && response !== false) {
                clearForm();

                const newClientList = [...clientList];
                newClientList.push(userInfoCreation);
                setClientList(newClientList);

                setCreatingUser(false);
                setCreateUserLoading(false);
              }
            })
            .catch((error) => {
              setCreateUserLoading(false);
              setSnackBarMessage('Erro ao tentar cadastrar usuário');
            });
        } else {
          setCreateUserLoading(false);
          setSnackBarMessage('Erro ao buscar usuário');
        }
      })
      .catch((error) => {
        setCreateUserLoading(false);
        setSnackBarMessage('Erro ao cadastrar o usuário! O celular já existe na base');
      });
  };

  const handleFileChange = (e) => {
    try {
      setUploadFile(true);
      const fr = new FileReader();
      fr.onload = () => {
        try {
          const cellphones = fr.result.split('\n').map((cellphone) => {
            cellphone =
              '+55' +
              cellphone.replace('@sms.triibo.com.br', '').replace(/\+55/g, '').replace(/\D+/g, '');
            return cellphone;
          });
          const cleanCellPhone = cellphones.filter((phone) => phone !== '+55');
          searchUsers(cleanCellPhone);
        } catch (err) {
          setSnackBarMessage('Erro ao carregar arquivo');
        }
      };
      fr.readAsText(e.target.files[0]);
    } catch (err) {
      setUploadFile(false);

      setSnackBarMessage('Erro ao carregar arquivo');
    }
  };

  return (
    <>
      <Modal
        open={openSendVoucherModal}
        onClose={() => {
          localStorage.removeItem('ClientsSelect');
          localStorage.removeItem('statePromotion');
          toggleSendVoucherModal(false);
        }}
        style={{
          padding: 15,
          boxSizing: 'border-box',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Paper
          style={{
            width: 800,
            maxWidth: '90%',
            maxHeight: '90%',
            padding: '50px 20px 30px 20px',
            boxSizing: 'border-box',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          {(loading && informationLoading) || searchUserLoading || createUserLoading ? (
            <div
              style={{
                width: 200,
                maxWidth: '100%',
                margin: '0 auto',
                display: 'block',
              }}
            >
              <Loading />
            </div>
          ) : (
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Cancel
                  color="secondary"
                  onClick={() => setOpenDialog(true)}
                  style={{
                    cursor: 'pointer',
                    top: 20,
                    right: 20,
                    position: 'absolute',
                  }}
                />

                {creatingUser ? (
                  <>
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="primary"
                      style={{ fontWeight: '600' }}
                    >
                      Cadastrar usuário
                    </Typography>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        createUser();
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newName}
                            autoFocus
                            required
                            placeholder="Nome completo*"
                            onChange={(e) => setNewName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newEmail}
                            type="email"
                            placeholder="E-mail"
                            onChange={(e) => setNewEmail(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={clientPhone}
                            inputComponent={TextMaskCustom}
                            required
                            placeholder="Celular*"
                            onChange={(e) => setClientPhone(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newBirthDate}
                            type="date"
                            placeholder="Data de nascimento"
                            onChange={(e) => setNewBirthDate(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newCPF}
                            inputComponent={TextMaskCustomCPF}
                            placeholder="CPF"
                            onChange={(e) => setNewCPF(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newZipCode}
                            placeholder="CEP"
                            onChange={(e) => handleNewZipCode(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newCountry}
                            disabled
                            placeholder="País"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newState}
                            disabled
                            placeholder="Estado"
                            onChange={(e) => setNewState(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newCity}
                            disabled
                            placeholder="Cidade"
                            onChange={(e) => setNewCity(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newNeighborhood}
                            disabled
                            placeholder="Bairro"
                            onChange={(e) => setNewNeighborhood(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newStreet}
                            disabled
                            placeholder="Rua"
                            onChange={(e) => setNewStreet(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newNumber}
                            placeholder="Número"
                            onChange={(e) => setNewNumber(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInput
                            fullWidth={true}
                            value={newExtra}
                            placeholder="Complemento"
                            onChange={(e) => setNewExtra(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <div align="center" style={{ marginTop: 20 }}>
                        <Button
                          onClick={() => {
                            setCreatingUser(false);
                            clearForm();
                          }}
                          style={{
                            margin: '0 5px',
                            fontWeight: '550',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          color="secondary"
                          type="button"
                        >
                          Voltar
                        </Button>
                        <Button
                          style={{
                            margin: '0 5px',
                            fontWeight: '550',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Cadastrar
                        </Button>
                      </div>
                    </form>
                  </>
                ) : clientListByWalletSelect.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      gap: '1rem',
                    }}
                  >
                    <h3 style={{ textAlign: 'center' }}>
                      {clientListByWalletSelect.length} clientes selecionados, escolha a ação
                      desejada.
                    </h3>
                    <div
                      style={{
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      {promotionType !== 'Promoção Fidelidade' && (
                        <Button
                          onClick={() => checkInformations(false)}
                          style={{
                            minWidth: 160,
                            margin: '0 5px',
                            fontWeight: '550',
                            color: 'white',
                            textTransform: 'capitalize',
                            whiteSpace: 'nowrap',
                          }}
                          variant="contained"
                          color="primary"
                          type="button"
                        >
                          Enviar todos
                        </Button>
                      )}

                      <Button
                        onClick={() => checkInformations(true)}
                        style={{
                          minWidth: 160,
                          margin: '0 5px',
                          fontWeight: '550',
                          color: 'white',
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap',
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                      >
                        Consumir todos
                      </Button>
                      <Button
                        onClick={() => {
                          openModalSelectClients();
                          toggleSendVoucherModal(false);
                        }}
                        style={{
                          minWidth: 160,
                          margin: '0 5px',
                          fontWeight: '550',
                          color: 'white',
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap',
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                      >
                        Editar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <S.HeaderModalContainer>
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="primary"
                        style={{ fontWeight: '600' }}
                      >
                        Pesquisar usuário
                      </Typography>
                      <S.Button
                        onClick={() => {
                          openModalSelectClients();

                          toggleSendVoucherModal(false);
                        }}
                      >
                        <D.TriiboH5>Selecionar clientes</D.TriiboH5>
                      </S.Button>
                    </S.HeaderModalContainer>
                    <FormControl fullWidth>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          searchUser(clientPhone);
                        }}
                      >
                        <Input
                          fullWidth={true}
                          value={clientPhone}
                          autoFocus
                          inputComponent={TextMaskCustom}
                          placeholder="Digite o celular do cliente e pressione Enter"
                          onChange={(e) => setClientPhone(e.target.value)}
                        />
                      </form>
                    </FormControl>

                    {clientList.length > 0 && (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="center">Telefone</TableCell>
                            <TableCell align="right">
                              {clientList.length > 1 && (
                                <>
                                  {promotionType !== 'Promoção Fidelidade' && (
                                    <Button
                                      onClick={() => checkInformations(false)}
                                      style={{
                                        minWidth: 160,
                                        margin: '0 5px',
                                        fontWeight: '550',
                                        color: 'white',
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                      }}
                                      variant="contained"
                                      color="secondary"
                                      type="button"
                                    >
                                      Enviar todos
                                    </Button>
                                  )}

                                  <Button
                                    onClick={() => checkInformations(true)}
                                    style={{
                                      minWidth: 160,
                                      margin: '0 5px',
                                      fontWeight: '550',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                      whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                  >
                                    Consumir todos
                                  </Button>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {clientList.map((client, index) => {
                            try {
                              const cellPhone = findValueInArray(
                                client.contactList,
                                'type',
                                'cellPhone'
                              ).value;

                              return (
                                <TableRow key={index}>
                                  <TableCell align="left">{client.name}</TableCell>
                                  <TableCell align="center">{cellPhone}</TableCell>
                                  <TableCell align="right">
                                    {promotionType !== 'Promoção Fidelidade' && (
                                      <Button
                                        onClick={() => checkInformation(false, index)}
                                        style={{
                                          minWidth: 160,
                                          margin: '0 5px',
                                          fontWeight: '550',
                                          color: 'white',
                                          textTransform: 'capitalize',
                                          whiteSpace: 'nowrap',
                                        }}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                      >
                                        Enviar cupom
                                      </Button>
                                    )}

                                    <Button
                                      onClick={() => checkInformation(true, index)}
                                      style={{
                                        minWidth: 160,
                                        margin: '0 5px',
                                        fontWeight: '550',
                                        color: 'white',
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                      }}
                                      variant="contained"
                                      color="primary"
                                      type="button"
                                    >
                                      Consumir cupom
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            } catch (err) {
                              return null;
                            }
                          })}
                        </TableBody>
                      </Table>
                    )}

                    <div align="center" style={{ marginTop: 20 }}>
                      <input
                        type="file"
                        accept=".txt"
                        ref={fileUploader}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      {isAdminPage() && (
                        <Button
                          onClick={() => fileUploader.current.click()}
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            fontWeight: '550',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          color="secondary"
                          type="button"
                        >
                          Carregar arquivo
                        </Button>
                      )}
                      <Button
                        onClick={() => searchUser(clientPhone)}
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          fontWeight: '550',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                      >
                        Pesquisar
                      </Button>
                    </div>
                  </>
                )}
              </Grid>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBarMessage.length > 0}
                onClose={() => setSnackBarMessage('')}
                message={snackBarMessage}
              />

              <Dialog
                open={openDialog}
                disableEnforceFocus
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                  localStorage.removeItem('ClientsSelect');
                  localStorage.removeItem('statePromotion');
                  setOpenDialog(false);
                }}
              >
                <DialogTitle>Deseja fechar?</DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ padding: 0 }}>
                    As alterações realizadas não serão salvas.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      localStorage.removeItem('ClientsSelect');
                      localStorage.removeItem('statePromotion');
                      goBack();
                    }}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Paper>
      </Modal>
    </>
  );
};

//recebe as props dos Reducers
const mapStateToProps = (state) => {
  return {
    loading: state.voucherSendComponent.loading,
    success: state.voucherSendComponent.success,
    error: state.voucherSendComponent.error,
  };
};

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  voucherSendComponent: (templateId, batchId, emailFile, consumed, callback) =>
    voucherSendAction(dispatch, templateId, batchId, emailFile, consumed, callback),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherSendExternal);

