import React, { createRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// redux imports config
import { connect } from 'react-redux';
import { voucherSendAction } from 'js/core/actions/voucherSendAction';

// helpers
import { getUserInfo } from 'js/library/utils/helpers';

// context
import { useOptions, usePromotions, useModal, useVouchers } from '../Context/Attendance';

// global services
import { downloadImage } from 'js/library/services/StorageManager.js';

// modal animations config
import { useSpring, animated } from 'react-spring/web.cjs';

import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  IconButton,
  CircularProgress,
  Snackbar,
} from '@mui/material';

// icons
import {
  SentimentDissatisfied as SentimentVeryDissatisfiedIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

// assets
import placeholderItem from 'styles/assets/placeholder/placeholder_thumbnail.png';

// styles
import { useModalsStyles } from './styles';
import { AttendanceServices } from '../AttendanceScreen/AttendanceServices';
// import { WalletModalCard } from "js/components/Establishment/AttendanceScreen/Modals";

// wallet
export const ClientWalletModalCard = ({ classes, wallet }) => {
  const { setSelectedVoucher, selectedVoucher } = useVouchers();
  const [thumbnail, setThumbnail] = useState(placeholderItem);

  const ref = createRef();

  const { key, title, type } = wallet;

  const isSelectedWallet = key === wallet.key;

  const downloadCouponImage = () =>
    wallet.thumbnail &&
    downloadImage('voucher', wallet.thumbnail)
      .then((downloaded) => {
        setThumbnail(downloaded);
      })
      .catch((error) => {
        return error;
      });

  const handleSelectWallet = () => setSelectedVoucher({ key, title, type });

  useEffect(() => {
    isSelectedWallet &&
      ref.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });

    downloadCouponImage();
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.walletContainer}
      maxWidth={390}
      maxHeight={139}
      ref={ref}
      onClick={handleSelectWallet}
      style={{
        border: selectedVoucher.title === wallet.title ? '5px solid #08BAD0' : 'none',
      }}
    >
      <img src={thumbnail} alt="" width={186} height={139} />
      <div className={classes.walletTextContainer}>
        <Typography variant="body1" component="span">
          {wallet.title || 'Nome da promoção'}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
          classes={{ body2: classes.walletDescriptionText }}
        >
          {wallet.description || 'Descrição da promoção...'}
        </Typography>
      </div>
    </Box>
  );
};

const ClientPromotionModalCard = ({ classes, promotion }) => {
  const { selectedPromotion, setSelectedPromotion } = usePromotions();
  const ref = createRef();

  const { id, templateId } = promotion;

  const isSelectedPromotion = id === selectedPromotion.id;

  const handleSelectPromotion = () => setSelectedPromotion({ id, templateId });

  useEffect(() => {
    isSelectedPromotion &&
      ref.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
  }, []);

  return (
    <Box width={225} minWidth={225} mr={5}>
      <Card
        className={classes.card}
        ref={ref}
        style={{ border: isSelectedPromotion ? '5px solid #08BAD0' : 'none' }}
      >
        <CardActionArea onClick={handleSelectPromotion}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height={169}
            image={promotion.downloadedThumb || placeholderItem}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="body2"
              component="h3"
              align="center"
              classes={{ body2: classes.cardTitle }}
            >
              {promotion.title || 'Nome da Promoção'}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              color="textSecondary"
              component="h6"
              align="center"
              classes={{ body2: classes.cardText }}
            >
              {promotion.description || 'Descrição da promoção...'}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

const PromotionModalFormComponent = React.memo(
  ({ userData, walletInfo, filteredWallets, voucherSendComponent }) => {
    const batchId = null;

    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [informationLoading, setInformationLoading] = useState(false);

    const { location } = useHistory();

    const { optionSelected } = useOptions();
    const { setOpenModal } = useModal();
    const { currentPromotions, selectedPromotion, setSelectedPromotion } = usePromotions();
    const { selectedVoucher, setSelectedVoucher } = useVouchers();

    const classes = useModalsStyles();

    const attendanceServices = new AttendanceServices({});

    const handleCloseModal = () => {
      setInformationLoading(false);
      setSelectedPromotion({});
      setSelectedVoucher({});
      setOpenModal(false);
    };

    const handleVoucherConsume = () => {
      setInformationLoading(true);

      const type = 'validation';

      attendanceServices
        .validateVoucher(
          getUserInfo().triiboId,
          selectedVoucher.key,
          location.state.lat,
          location.state.long,
          type,
          userData.triiboId
        )
        .then((result) => {
          setInformationLoading(false);
          setSnackBarMessage('Cupom consumido com sucesso!');
        })
        .catch((err) => {
          setInformationLoading(false);
          setSnackBarMessage('Erro ao consumir cupom!');
        });
    };

    const handleGiveVoucher = () => {
      setInformationLoading(true);

      const { templateId } = selectedPromotion;

      const voucherConsumed = false;

      const data = [userData.triiboId];

      const fakeFile = new File(data, 'fakeFile.txt');

      attendanceServices
        .getVouchers(templateId)
        .then((result) => {
          if (!result.generator && result.qtdDisponivel <= 0) {
            setInformationLoading(false);

            return setSnackBarMessage('Essa promoção não possui cupons restantes.');
          }

          voucherSendComponent(templateId, batchId, fakeFile, voucherConsumed, () => {
            setInformationLoading(false);

            setSnackBarMessage('Promoção enviada com sucesso!');
          });
        })
        .catch((err) => {
          setSnackBarMessage('Erro ao enviar promoção!');
        });
    };

    const RenderPromotionModalCards = () => {
      if (currentPromotions.length === 0) {
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            justifySelf="center"
          >
            <SentimentVeryDissatisfiedIcon
              color="disabled"
              classes={{ colorDisabled: classes.sadIcon }}
            />
            <br />
            <Typography variant="body2" color="textSecondary" component="span">
              Seu estabelecimento não possui promoções!
            </Typography>
          </Box>
        );
      }

      const promotionList = currentPromotions.filter((promotion) => {
        return (
          (promotion.type !== 'businessPartner' &&
            promotion.isPublished === true &&
            promotion.enableDate <= Date.now() &&
            promotion.disableDate >= Date.now()) ||
          promotion.aprovacao?.status === 'aberto'
        );
      });

      return promotionList.map((promotion) => (
        <ClientPromotionModalCard key={promotion.key} {...{ classes, promotion }} />
      ));
    };

    const RenderWalletModalCards = () => {
      if (filteredWallets.length === 0)
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            justifySelf="center"
          >
            <SentimentVeryDissatisfiedIcon
              color="disabled"
              classes={{ colorDisabled: classes.sadIcon }}
            />
            <br />
            <Typography variant="body2" color="textSecondary" component="span">
              Este usuário não possui cupons do seu estabelecimento.
            </Typography>
          </Box>
        );

      return filteredWallets.map((wallet) => (
        <ClientWalletModalCard key={wallet.key} {...{ classes, wallet }} />
      ));
    };

    const ConditionalCards = useCallback(
      () => (
        <Box
          display="flex"
          justifyContent={
            optionSelected === 'promotions'
              ? currentPromotions.length === 0
                ? 'center'
                : 'flex-start'
              : filteredWallets.length === 0
              ? 'center'
              : 'flex-start'
          }
          className={classes.content}
          p={optionSelected === 'wallet' && 3}
        >
          {optionSelected === 'promotions' ? (
            <RenderPromotionModalCards />
          ) : (
            <RenderWalletModalCards />
          )}
        </Box>
      ),
      []
    );

    const ActionButton = () => {
      const informationValues = Object.values(
        optionSelected === 'promotions' ? selectedPromotion : selectedVoucher
      ).length;
      const actionFunction =
        optionSelected === 'promotions' ? handleGiveVoucher : handleVoucherConsume;

      return (
        <>
          {informationValues > 0 &&
            (selectedVoucher.type === 'promo' ? (
              <Button
                variant="contained"
                color="primary"
                classes={{ contained: classes.button }}
                disabled="true"
              >
                Cupom não consumivel
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                classes={{ contained: classes.button }}
                onClick={actionFunction}
                disabled={informationLoading}
              >
                {optionSelected === 'promotions' && !informationLoading
                  ? 'Dar Cupom'
                  : !informationLoading
                  ? 'Consumir cupom'
                  : ''}
                {informationLoading && (
                  <CircularProgress
                    size={25}
                    color="default"
                    classes={{ root: classes.loadingIcon }}
                  />
                )}
              </Button>
            ))}
        </>
      );
    };

    return (
      <Box width={700}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackBarMessage.length > 0}
          onClose={() => setSnackBarMessage('')}
          message={snackBarMessage}
        />

        <IconButton
          color="secondary"
          classes={{ colorSecondary: classes.iconButton }}
          onClick={handleCloseModal}
        >
          <CancelIcon />
        </IconButton>

        <Box display="flex" flexDirection="column">
          <br />
          <br />

          {Object.values(userData).length > 0 && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" color="primary" component="span">
                  {userData.name || 'Nome do usuário'}
                </Typography>

                <Typography variant="body1" color="primary" component="span">
                  Pontos: {walletInfo.totalPoints || 0}
                </Typography>
              </Box>

              <hr />
              <br />

              <Box display="flex" flexDirection="column">
                <Typography variant="body2" color="primary" component="span">
                  {optionSelected === 'promotions' ? 'Minhas promoções:' : 'Carteira:'}
                </Typography>

                <br />

                <ConditionalCards />

                <br />
                <ActionButton />
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
);

// redux connection config
//recebe as props dos Reducers
const mapStateToProps = (state) => {
  return {
    loading: state.voucherSendComponent.loading,
    success: state.voucherSendComponent.success,
    error: state.voucherSendComponent.error,
  };
};
//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  voucherSendComponent: (templateId, batchId, emailFile, consumed, callback) =>
    voucherSendAction(dispatch, templateId, batchId, emailFile, consumed, callback),
});
export const PromotionModalForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionModalFormComponent);

// modal animation config
export const Fade = React.memo(
  React.forwardRef((props, ref) => {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });

    return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
    );
  })
);

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

