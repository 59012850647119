import { useState } from 'react';

const useForm = (initialValues) => {
  const [form, setForm] = useState(initialValues);

  const handleChange = ({ target }) => {
    const { id, name, type, value } = target;
    const fieldName = id || name;

    let newValue = type === 'checkbox' ? target.checked : value;

    if (fieldName === 'publicName') {
      newValue = newValue.replace(/[^a-zA-Z0-9-]/g, '');
    }

    setForm({
      ...form,
      [fieldName]: {
        ...form[fieldName],
        value: newValue,
      },
    });
  };

  const validateForm = () => {
    for (const key in form) {
      const fieldValue = form[key].value;

      if (form[key].required) {
        if (typeof fieldValue === 'string' && fieldValue.trim() === '') {
          return false;
        }

        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
          return false;
        }
      }
    }
    return true;
  };

  const clearForm = () => {
    const clearedForm = {};
    for (const key in form) {
      if (Array.isArray(form[key].value)) {
        clearedForm[key] = { ...form[key], value: [] };
      } else if (typeof form[key].value === 'boolean') {
        clearedForm[key] = { ...form[key], value: false };
      } else {
        clearedForm[key] = { ...form[key], value: '' };
      }
    }
    setForm(clearedForm);
  };

  return {
    form,
    handleChange,
    setForm,
    validateForm,
    clearForm,
  };
};

export default useForm;
