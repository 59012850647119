export default function reducer(state = {
  cellPhone: null,
  transactionId: null,
  error: null

}, action) {

  switch (action.type) {

    case "PHONE_INPUT_PENDING":
      {
        return {
          ...state,
          cellPhone: null,
          transactionId: null,
          error: null
        };
      }

    case "PHONE_INPUT_FULFILLED":
      {
        return {
          ...state,
          cellPhone: action.payload.cellPhone,
          transactionId: action.payload.transactionId,
          error: action.payload.error
        };
      }

    case "PHONE_INPUT_REJECTED":
      {
        return {
          ...state,
          cellPhone: null,
          transactionId: null,
          error: null
        };
      }

    default:
      return state;
  }
}