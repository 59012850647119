import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Grid } from '@mui/material';

export default function StoreMenu() {
  return (
    <div style={{ padding: '100px 50px' }}>
      <Typography
        variant="h5"
        gutterBottom
        color="primary"
        style={{ fontWeight: '700', float: 'left' }}
      >
        Selecione o tipo da sua oferta
      </Typography>

      <Grid container spacing={2}>
        <Grid item lg={4} sm={6} xs={12}>
          <Link
            to={{
              pathname:
                '/admin/estabelecimento/' + this.props.location.state.key + '/ofertas/promocao/add',
              state: 'promotion',
            }}
            style={{ textDecoration: 'none' }}
          >
            <div className="cs-item cs-item-ofertasPromocoes">
              <p className="cs-title">Promoções e descontos</p>
            </div>
          </Link>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <Link
            to={{
              pathname:
                '/admin/estabelecimento/' +
                this.props.location.state.key +
                '/ofertas/empresasParceiras/add',
              state: '',
            }}
          >
            <div className="cs-item cs-item-ofertaEmpresaParceiras">
              <p className="cs-title">Site externo</p>
            </div>
          </Link>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <Link
            to={{
              pathname:
                '/admin/estabelecimento/' +
                this.props.location.state.key +
                '/ofertas/promocaoAgrupada/add',
              state: 'promotion',
            }}
            style={{ textDecoration: 'none' }}
          >
            <div className="cs-item cs-item-ofertasPromocoesGrupo">
              <p className="cs-title">Ofertas Agrupadas</p>
            </div>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

