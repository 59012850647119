import * as React from 'react';

const IconSwitch = (props) => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Adicionar estabelecimento">
            <path
                data-name="Ret\xE2ngulo 1515"
                fill="none"
                style={{
                    strokeWidth: 0.950001,
                }}
                d="M2.5 2.5h95v95h-95z"
            />
            <path
                data-name="Icon awesome-exchange-alt"
                d="m26.31 60.406-1.04-1.077a2.244 2.244 0 0 1 .054-3.172L49.53 32.759l-3.12-3.227a2.246 2.246 0 0 1 1.652-3.803l10.573.184a2.244 2.244 0 0 1 2.206 2.281l-.18 10.577a2.246 2.246 0 0 1-3.857 1.522l-3.119-3.227L29.483 60.46a2.244 2.244 0 0 1-3.172-.054zm42.685-21.493-24.2 23.392-3.12-3.226A2.246 2.246 0 0 0 37.82 60.6l-.183 10.577a2.244 2.244 0 0 0 2.206 2.281l10.577.18a2.246 2.246 0 0 0 1.654-3.803l-3.12-3.228 24.2-23.393a2.244 2.244 0 0 0 .063-3.174l-1.043-1.079a2.244 2.244 0 0 0-3.178-.048z"
                fill="#fff"
                style={{
                    strokeWidth: 1.89999,
                }}
            />
            <path
                d="M50 10.1c-10.658 0-20.677 4.15-28.214 11.686C14.25 29.323 10.1 39.342 10.1 50c0 10.658 4.15 20.677 11.686 28.214C29.323 85.75 39.342 89.9 50 89.9c10.658 0 20.677-4.15 28.214-11.686C85.75 70.677 89.9 60.658 89.9 50c0-10.658-4.15-20.677-11.686-28.214C70.677 14.25 60.658 10.1 50 10.1m0-7.6c26.234 0 47.5 21.266 47.5 47.5S76.234 97.5 50 97.5 2.5 76.234 2.5 50 23.766 2.5 50 2.5Z"
                fill="#fff"
                style={{
                    strokeWidth: 1.89999,
                }}
            />
        </g>
    </svg>
);

export default IconSwitch;
