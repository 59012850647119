import React from 'react';
import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';

import { BsXCircleFill } from 'react-icons/bs';

import ImageDisplay from './ImageDisplay';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background-color: transparent;
`;

const GalleryDisplay = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  width: 100%;
  justify-content: space-between;
`;

const ImageColumn = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 20%;
`;

const BorderGiver = styled.span`
  position: relative;
  width: 100%;
  border: 1px solid ${D.triiboGray};
  border-radius: 10px;
  overflow: hidden;
`;

const DeleteButton = styled.span`
  font-size: 0.8rem;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  z-index: 2;
  cursor: pointer;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    `
    visibility: visible;
  `}
`;

const DeleteButtonBig = styled.span`
  font-size: 1rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
  cursor: pointer;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    `
    visibility: visible;
  `}
`;

const FirstImageDisplay = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${D.triiboGray};
  border-radius: 10px;
`;

const PromoLabelContainer = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: flex-start;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0.6rem;
  border-top: 1px solid ${D.triiboGray};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PromoTitle = styled.span`
  font-weight: 600;
  font-size: 0.6rem;

  @media (min-height: 1100px) {
    font-size: 0.8rem;
  }
`;

const PromoEstablishment = styled.span`
  font-weight: 500;
  font-style: italic;
  font-size: 0.5rem;

  @media (min-height: 1100px) {
    font-size: 0.7rem;
  }
`;

const PromoDescription = styled.span`
  font-weight: 500;
  font-style: italic;
  font-size: 0.5rem;

  @media (min-height: 1100px) {
    font-size: 0.6rem;
  }
`;

const FirstGroup = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
  width: 70%;
`;

const FirstLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.6rem;
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-height: 1100px) {
    font-size: 1rem;
  }
`;

const ImageGallery = (props) => {
  const { arrSrcImg, setArrSrcImg, formState, setDisabledButton } = props;

  const handleRemove = (e, index) => {
    e.preventDefault();
    const newArr = [...arrSrcImg];
    newArr.splice(index, 1);
    setArrSrcImg(newArr);
    setDisabledButton && setDisabledButton(false);
  };

  return (
    <Container>
      <GalleryDisplay id="gallery-display">
        <FirstGroup>
          <FirstLabel>Foto de destaque</FirstLabel>
          <FirstImageDisplay>
            <DeleteButtonBig
              onClick={(e) => handleRemove(e, 0)}
              visible={arrSrcImg[0] ? true : false}
            >
              {formState.status && formState.status !== 'aberto' ? <></> : <BsXCircleFill />}
            </DeleteButtonBig>
            <ImageDisplay srcImg={arrSrcImg[0]} />
            <PromoLabelContainer>
              <PromoTitle>Nome da promoção</PromoTitle>
              <PromoEstablishment>Nome do estabelecimento</PromoEstablishment>
              <PromoDescription>Descrição da promoção</PromoDescription>
            </PromoLabelContainer>
          </FirstImageDisplay>
        </FirstGroup>

        <ImageColumn>
          <BorderGiver>
            <DeleteButton
              onClick={(e) => {
                handleRemove(e, 1);
              }}
              visible={arrSrcImg[1] ? true : false}
            >
              {formState.status && formState.status !== 'aberto' ? <></> : <BsXCircleFill />}
            </DeleteButton>

            <ImageDisplay srcImg={arrSrcImg[1]} />
          </BorderGiver>
          <BorderGiver>
            <DeleteButton
              onClick={(e) => {
                handleRemove(e, 2);
              }}
              visible={arrSrcImg[2] ? true : false}
            >
              <BsXCircleFill />
            </DeleteButton>

            <ImageDisplay srcImg={arrSrcImg[2]} />
          </BorderGiver>
          <BorderGiver>
            <DeleteButton
              onClick={(e) => {
                handleRemove(e, 3);
              }}
              visible={arrSrcImg[3] ? true : false}
            >
              <BsXCircleFill />
            </DeleteButton>

            <ImageDisplay srcImg={arrSrcImg[3]} />
          </BorderGiver>
          <BorderGiver>
            <DeleteButton
              onClick={(e) => {
                handleRemove(e, 4);
              }}
              visible={arrSrcImg[4] ? true : false}
            >
              <BsXCircleFill />
            </DeleteButton>

            <ImageDisplay srcImg={arrSrcImg[4]} />
          </BorderGiver>
        </ImageColumn>
      </GalleryDisplay>
    </Container>
  );
};

export default ImageGallery;
