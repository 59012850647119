import React from 'react';

import * as S from './style';

const ButtonPurple = (props) => {

    const { open, toggleButton, isAdmin, setIsAdmin, borderTop, setSwitchText } = props;

    return (
        <>
            <S.SidebarLink open={open} to={props.to} purple={true.toString()}>
                <S.SidebarButton open={open} borderTop={borderTop} 
                    onClick={()=>{
                        if(toggleButton) {
                            if (isAdmin === true) {
                                setSwitchText('trocar para admin'); 
                            } 
                            if ((isAdmin === false)) {
                                setSwitchText('trocar para usuário'); 
                            }
                            setIsAdmin(!isAdmin);
                        }
                    }}
                >
                
                    <S.SidebarButtonContents open={open}>
                        
                        <S.SidebarButtonIcon open={open} white={true}>
                            <S.SidebarIconContainer >
                                {props.icon}  
                            </S.SidebarIconContainer>
                        </S.SidebarButtonIcon>
                        
                        <S.SidebarButtonChildren open={open} white={true} purple={true}>
                            {props.children}
                        </S.SidebarButtonChildren>
                        
                    </S.SidebarButtonContents>        
                
                </S.SidebarButton>
            </S.SidebarLink>
        </>
    );
};

export default ButtonPurple;