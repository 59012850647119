/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/button-has-type */
import React from 'react';
import propTypes from 'prop-types';
import {
  Container,
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 450,
    padding: 10,
    backgroundColor: '#e5e5e5',
  },
  cardContent: {
    height: 'auto',
    paddingTop: 0,
  },
  cardActions: {
    height: 70,
  },
  text: {
    fontSize: 16,
    padding: 0,
    margin: 0,
    marginBottom: 20,
  },
});

export function PopupContainer({ title, description }) {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="md">
      <Box m="auto">
        <Card className={classes.card} raised>
          <CardHeader title={title} />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.text} component="p">
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

PopupContainer.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
};
