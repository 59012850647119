import {
  getDataList,
  removeDatabase,
  updateDatabase,
} from "js/library/services/DatabaseManager";
import { uploadImage, deleteImage } from "js/library/services/StorageManager";
import { firebaseDatabase } from "js/library/utils/firebaseUtils";
import apiQRCode from "js/library/utils/API/apiQRCode";
import dispatcher from "js/core/dispatcher";

export const generateQRCodeAction = (
  dispatch,
  promoId,
  qrCodeId,
  name,
  action,
  description,
  image,
  qrCodeInfo
) => {

  const key = firebaseDatabase.ref("Promotions-").push().key

  const now = Date.now();

  const imageName = `thumb:${promoId}:${Math.floor(
    Math.random(0, 9999999999) * 10000000000
  )}.png`;

  const data = {
    name,
    action,
    description,
    image: imageName,
    qrCodeId,
    updateDate: now,
    createdAt: now,
  };

  const generateQRCode = () => {
    return new Promise((resolve, reject) => {
      apiQRCode(qrCodeInfo)
        .then(() => {
          updateDatabase(`Promotions-/${promoId}`, data, key, null)
            .then((receivedData) => {
              resolve(receivedData);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return dispatcher(
    dispatch,
    "GENERATE_QR_CODE",
    generateQRCode(),
    uploadImage("promotions-", image, imageName)
  );
};

export const listQRCodeAction = (dispatch, promoId) => {
  return dispatcher(
    dispatch,
    "LIST_QR_CODE",
    getDataList(`Promotions-/${promoId}`, 999)
  );
};

export const deleteQRCodeAction = (dispatch, promoId, qrCode) => {
  return dispatcher(
    dispatch,
    "DELETE_QR_CODE",
    removeDatabase(`Promotions-/${promoId}`, qrCode.key),
    deleteImage("promotions-", qrCode.image)
  );
};
