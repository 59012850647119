import React, { useEffect, useState } from 'react';

import SimpleTable from 'js/components/TriiboComponents/Tables/SimpleTable';
import { GetOrgsPaginatedSearch } from 'js/library/utils/API/Org/apiGetOrgsPaginatedSearch';
import { useHistory, Link } from 'react-router-dom';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import InputSearch from 'js/components/TriiboComponents/InputSearch';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';
import { Pagination } from '@mui/material';

const TableSchema = [
  {
    heading: 'Nome',
    value: 'name',
  },
  {
    heading: 'Método de Acceso',
    value: 'accessMethod',
  },
  {
    heading: 'ID da Organização',
    value: 'orgID',
  },
  {
    heading: 'Visibilidade',
    value: 'visibleModify',
  },
];

export default function OrganizationsList() {
  const [orgsList, setOrgsList] = useState([]);
  const [orgsListSearch, setOrgsListSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const PER_PAGE = 10;
  const count =
    inputSearch.length > 2
      ? Math.ceil(orgsListSearch.length / PER_PAGE)
      : Math.ceil(orgsList.length / PER_PAGE);

  const _DATA_SEARCH = usePagination(orgsListSearch, PER_PAGE);
  const _DATA = usePagination(orgsList, PER_PAGE);

  const handleChangePage = (e, p) => {
    inputSearch.length > 2 ? _DATA_SEARCH.jump(p) : _DATA.jump(p);
    inputSearch.length > 2 ? setPageSearch(p) : setPage(p);
  };

  const transformData = (data) => {
    return data.map((org) => {
      return {
        ...org,
        visibleModify: org.visible ? 'Visível' : 'Escondido',
      };
    });
  };

  useEffect(() => {
    (async () => {
      const payload = {
        from: 0,
        size: 50,
        accessMethod: 'all',
        visibility: 'all',
      };

      try {
        const { results } = await GetOrgsPaginatedSearch(payload);

        if (results.length > 0) {
          setOrgsList(transformData(results));
        }
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    })();
  }, []);

  let debouce;

  useEffect(() => {
    setOrgsListSearch([]);
    if (inputSearch.length > 2) {
      setPageSearch(1);
      _DATA_SEARCH.setCurrentPage(1);

      debouce = setTimeout(() => {
        const payload = {
          from: 0,
          size: 50,
          accessMethod: 'all',
          visibility: 'all',
          organizationName: inputSearch,
        };

        GetOrgsPaginatedSearch(payload).then(({ results }) => {
          setOrgsListSearch(transformData(results));
        });
      }, 1000);
    }

    return () => {
      clearTimeout(debouce);
    };
  }, [inputSearch]);

  useEffect(() => {
    const payload = {
      size: 20,
      accessMethod: 'all',
      visibility: 'all',
    };

    if (count === page) {
      payload.from = orgsList.length;
      GetOrgsPaginatedSearch(payload).then(({ results }) => {
        setOrgsList((prev) => [...prev, ...transformData(results)]);
      });
    }

    if (count === pageSearch) {
      payload.from = orgsListSearch.length;
      GetOrgsPaginatedSearch(payload).then(({ results }) => {
        setOrgsListSearch((prev) => [...prev, ...transformData(results)]);
      });
    }
  }, [page, pageSearch]);

  return (
    <div>
      <S.Container>
        <AreaHeader
          style={{
            color: '#08bad0',
            justifyContent: 'end',
            padding: '1rem',
            marginLeft: '3rem',
          }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>
        <D.TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3px',
          }}
        >
          Lista de Organizações
        </D.TriiboH1>

        <S.SearchBox>
          <InputSearch
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            disabled={false}
            placeholder="Pesquise uma organização"
          />

          <Link
            to={{
              pathname: '/admin/configuracoes/organizacoes/adicionar',
              state: '',
            }}
          >
            <DashedButton width={'250px'}>Adicionar Organização</DashedButton>
          </Link>
        </S.SearchBox>

        <SimpleTable
          data={inputSearch.length > 2 ? _DATA_SEARCH.currentData() : _DATA.currentData()}
          column={TableSchema}
          usePaginate={false}
          redirectOnClick={(state) => {
            delete state.thumbnailModify;
            delete state.visibleModify;
            history.push({
              pathname: '/admin/configuracoes/organizacoes/editar/' + state.orgID,
              state,
            });
          }}
        />
        <Pagination
          sx={{ margin: '0 auto' }}
          count={count}
          size="large"
          page={inputSearch.length > 2 ? pageSearch : page}
          variant="outlined"
          shape="rounded"
          color="secondary"
          onChange={handleChangePage}
        />
      </S.Container>
    </div>
  );
}

