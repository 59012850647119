import React from "react";

import { liveloSendPoints } from "js/library/utils/API/Livelo/apiLiveloSendPoints";

import { mergeObjects, getValueByType } from "js/library/utils/helpers";

import * as D from "js/components/DesignSystem/styles";

const SaveButton = (props) => {
  const { usersArr, form } = props;

  function createSubmissionArray(arr, form) {
    const createdArray = arr.map((user) => {
      let mergedObj = mergeObjects(
        {
          uid: user?.uId ? user["uId"] : null,
          cpf: user.cpf
            ? user.cpf
            : getValueByType(user["documentList"], "cpf"),
        }, //user.cpf se refere ao campo cpf que vem do registro em lote enquanto getValueByType(user["documentList"], "cpf")serve para o unitário
        form
      );

      if (!mergedObj.uid) {
        delete mergedObj.uid;
      }

      return mergedObj;
    });

    return createdArray;
  }

  return (
    <>
      <D.TriiboFilledButton
        blue={true}
        disabled={!usersArr.length}
        onClick={(e) => {
          e.preventDefault();
          liveloSendPoints(createSubmissionArray(usersArr, form));
        }}
      >
        Creditar pontos para usuários
      </D.TriiboFilledButton>
    </>
  );
};

export default SaveButton;
