import axios from 'axios';

export function sendEmail(email, subject, message) {
    return new Promise((resolve, reject) => {
        axios.post('https://clubmapfre-hml.triibo.com.br/wp-content/themes/applay/php/sendEmail.php', {
            email,
            subject,
            message
        }).then((result) => {
            resolve(result.data);
        }).catch((error) => {
            reject(error.data);
        });
    });
}