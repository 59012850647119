import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import {
  AddPhotoAlternate as AddImageIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import {
  Snackbar,
  Typography,
  Grid,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  Modal,
  FormControlLabel,
  Button,
} from '@mui/material';
import { Cropper } from 'react-image-cropper';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { updateValidatorsAction } from 'js/core/actions/validatorsInformationActions';
import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';
import { withStyles } from '@mui/styles';

import Loading from 'js/containers/Loading/Loading';

import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import QRCode from 'qrcode.react';
import crachaCabecalho from 'styles/assets/cracha/crachaCabecalho.png';
import crachaVerso from 'styles/assets/cracha/crachaVerso.png';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '+',
        5,
        5,
        ' ',
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    height: '80%',
    width: '60%',
    backgroundColor: 'white',
    padding: '30px',
  };
}

class ValidatorsInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validador: '',
      nome: props.state.nome,
      email: props.state.email,
      isValidator: true,
      openAlert: false,

      idEstabelecimento: props.state.id,
      idGestor: props.state.idGestor,

      //impressao
      crachaCabecalho: crachaCabecalho,
      crachaVerso: crachaVerso,
      identificacao1: props.state.identificacao1,
      textoApresentacao: 'Escaneie o QR Code abaixo:',
      QRCodePrint: null,
      identificacao2: '',

      openModal: false,
      loading: false,
    };

    window.pathname = null;
  }

  searchUser = (name) => {
    userReadCellphone_v3('+' + name.replace(/\D+/g, '')).then(
      (verification) => {
        if (verification !== false) {
          let isValidator = false;

          getDataListRestApi(
            'Estabelecimento-Validador/' +
              verification.userInfo.triiboId.replace('+', '%2b')
          ).then((dataReceived) => {
            if (dataReceived !== null) {
              Object.values(dataReceived).map((item, i) =>
                item.idEstabelecimento === this.state.idEstabelecimento
                  ? (isValidator = true)
                  : null
              );
            }

            this.setState({ isValidator });
          });

          this.setState({
            nome: verification.userInfo.name,
            email: verification.userInfo.triiboId,
            identificacao1: verification.userInfo.name,
            identificacao2: '',
          });
        } else {
          this.setState({
            nome: false,
            openAlert: true,
            messageAlert: 'Este usuário não existe!',
          });
        }
      }
    );
  };

  handleChangeObject = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleChange = (name) => (event) => {
    if (name === 'textoApresentacao' && event.target.value.length > 50) {
      this.setState({
        openAlert: true,
        messageAlert: 'Número máximo de caracteres atingido!',
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  downloadQRCode = () => {
    var element = document.createElement('a');

    element.setAttribute(
      'href',
      document.getElementById('QRCode').toDataURL('image/png')
    );
    element.setAttribute('download', this.state.email + '.png');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  print = () => {
    var win = window.open('Crachá', 'PRINT', 'height=700,width=700');
    win.document.write('<html>');
    win.document.write('<head>');
    win.document.write(
      '<style>@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:900");</style>'
    );
    win.document.write('</head');
    win.document.write('<body>');
    win.document.write('<div style="width:110%; margin-left: -5%">');
    win.document.write(
      '<div style="-webkit-print-color-adjust:exact; background-color: white; height: 10px"></div>'
    );
    win.document.write('<div>');
    win.document.write(
      '<img src="' + this.state.crachaCabecalho + '" width="100%" />'
    );
    win.document.write('</div>');

    win.document.write(
      '<div style="-webkit-print-color-adjust:exact; background-color: orange; color: white; text-align: center; margin-top: -16px; margin-bottom: -10px;">'
    );
    win.document.write(
      '<p style="padding-top:10px; padding-bottom : 10px; font-size: 12px;font-family:Source Sans Pro,sans-serif;">' +
        this.state.identificacao1.toUpperCase() +
        '</p>'
    );
    win.document.write('</div>');

    this.state.textoApresentacao.length === 0
      ? win.document.write('<br/><br/>')
      : this.state.textoApresentacao.length > 32
      ? win.document.write(
          '<div style="text-align: center; width: 70%; margin: 0 auto;"><p style="font-size: 10px; font-family:Source Sans Pro,sans-serif; color: #363636;">' +
            this.state.textoApresentacao +
            '</p></div>'
        )
      : win.document.write(
          '<div style="text-align: center; width: 70%; margin: 0 auto;"><p style="font-size: 10px; font-family:Source Sans Pro,sans-serif; color: #363636;">' +
            this.state.textoApresentacao +
            '</p></div><br/>'
        );

    win.document.write('<div>');
    win.document.write(
      '<img width="45%" style=" display: block; margin-left: auto; margin-right: auto;" src="' +
        document.getElementById('QRCode').toDataURL('image/png') +
        '" />'
    );
    win.document.write('</div>');

    win.document.write('<div style="text-align: center">');
    win.document.write(
      '<p style="font-size: 10px; font-family:Source Sans Pro,sans-serif; color: #363636;">' +
        this.state.identificacao2.toUpperCase() +
        '</p>'
    );
    win.document.write('</div>');

    win.document.write('</div>');
    win.document.write('</body');
    win.document.write('</html>');

    //win.focus();
    win.onfocus = function () {
      setTimeout(function () {
        win.print();
        win.close();
      }, 1000);
    }; //REMOVER

    //win.close();
    /* win.document.write('<br/><br/>')
		win.document.write('<div style="width:200px; border: 1px solid #000; border-radius: 7px">')
			win.document.write('<div style="-webkit-print-color-adjust:exact; background-color: white; height: 10px"></div>')
			win.document.write('<img width="100%" src="' + document.getElementById('imgVerso').src + '" onload="window.print();window.close()" />');
		win.document.write('</div>') */
  };

  //handle Modal
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  fileChangedHandler = (name, ratio) => (event) => {
    if (event.target.files[0] !== undefined) {
      const data = URL.createObjectURL(event.target.files[0]);

      this.setState({
        openModal: true,
        ratio,
        imageToCrop: data,
        imageCroped: name,
      });
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;

    //imagem recebida do crop
    let node = this[state].crop();

    this.setState({
      [newCrop]: node,
      openModal: false,
      imageToCrop: null,
      goBack: true,
    });
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    this.props.updateValidatorsComponent(
      this.state.idEstabelecimento,
      this.state.idGestor,
      this.state.email,
      this.state.isValidator
    );
  };

  render = () => {
    const { classes } = this.props;

    if (this.props.loading && this.state.loading)
      return (
        <div className='loading'>
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.props.state,
            }}
          />
        );
      } else {
        return (
          <div style={{ padding: '0' }}>
            {/* <Typography variant="h5" gutterBottom color='primary' style={{fontWeight: '600'}}>
						Validadores do Estabelecimento
					</Typography>
					<br /><br /> */}

            {/* <Grid container>
						<Grid key='valdiador' item md={6} sm={12} xs={12}>
							<Input fullWidth={true} value = { this.state.validador }
									id="formatted-text-mask-input"
									inputComponent={TextMaskCustom}
									onChange={this.handleChange('validador')}
									endAdornment={
										<IconButton style={{cursor : 'pointer'}} onClick={() => this.searchUser(this.state.validador)}> </IconButton>
									}
							/>
						</Grid>
					</Grid> */}

            {this.state.nome !== '' ? (
              this.state.nome !== false ? (
                <div>
                  <Grid container>
                    {/* <Grid item md={3} sm={6} xs={12} style={{marginTop: '20px'}}>
							<Typography variant="subtitle2" color='secondary' style={{fontWeight: '500'}}> Nome: </Typography>
						{this.state.nome}
						</Grid> */}

                    {/* <Grid item md={3} sm={6} xs={12} style={{marginTop: '20px'}}>
							<Typography variant="subtitle2" color='secondary' style={{fontWeight: '500'}}> Email: </Typography>
						{this.state.email.replace(/[,]/gi, ".")}
						</Grid> */}

                    <Grid item md={6} sm={6} xs={0} />

                    {this.state.isValidator ? (
                      <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                        style={{ marginTop: '20px' }}>
                        <Typography
                          variant='subtitle2'
                          color='secondary'
                          style={{ fontWeight: '500' }}>
                          {' '}
                          QRCode do Validador{' '}
                        </Typography>

                        <QRCode
                          style={{ height: '128px', width: '128px' }}
                          id='QRCode'
                          value={this.state.email.replace(/[.]/gi, ',')}
                          size={1000}
                          bgColor={'#ffffff'}
                          fgColor={'#000000'}
                          level={'M'}
                        />
                        <br />
                        <Button
                          onClick={() => this.downloadQRCode()}
                          style={{ width: '128px' }}
                          variant='outlined'>
                          Baixar
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>

                  {/* <Grid container>
						<Grid item md={6} sm={6} xs={12} style={this.state.isValidator === false ? {marginTop: '151px'} : null }>
							<Typography variant="subtitle2" color='secondary' style={{fontWeight: '500'}}> Este usuário é um validador do estabelecimento? </Typography>
							
							<Switch
								checked= { this.state.isValidator}
								onChange= { this.handleChangeObject('isValidator') }
								color="primary"
							/>
							
						</Grid>
					</Grid> */}

                  {/* <Typography variant="h5" gutterBottom color='primary'>Editor de Impressão </Typography> */}

                  <Grid container>
                    <Grid item md={6} sm={6} xs={12}>
                      <Typography
                        variant='subtitle2'
                        gutterBottom
                        color='secondary'
                        style={{ fontWeight: '550' }}>
                        Cabeçalho do Crachá
                      </Typography>
                      <FormControl className='input-field'>
                        <input
                          style={{ display: 'none' }}
                          type='file'
                          accept='image/*'
                          onChange={this.fileChangedHandler(
                            'crachaCabecalho',
                            4 / 2
                          )}
                          id='thumbnail'
                        />

                        <label htmlFor='thumbnail'>
                          <Button
                            style={{
                              fontWeight: '550',
                              color: '#6c6c6c',
                              textTransform: 'capitalize',
                            }}
                            variant='contained'
                            component='span'
                            classes={{
                              root: classes.root,
                              disabled: classes.disabled,
                            }}
                            startIcon={<AddImageIcon color='secondary' />}>
                            Alterar imagem
                          </Button>
                        </label>

                        <FormLabel style={{ marginTop: '20px' }}>
                          1º campo de identificação
                        </FormLabel>
                        <RadioGroup
                          aria-label='identificacao1'
                          name='identificacao1'
                          value={this.state.identificacao1}
                          onChange={this.handleChange('identificacao1')}>
                          <FormControlLabel
                            value={this.state.nome}
                            control={<Radio color='primary' />}
                            label='Nome do Usuário'
                            labelPlacement='end'
                          />

                          <FormControlLabel
                            value={this.props.state.nomeEstabelecimento}
                            control={<Radio color='primary' />}
                            label='Nome do Estabelecimento'
                            labelPlacement='end'
                          />
                        </RadioGroup>

                        <TextField
                          style={{ width: '90%' }}
                          className='input-field'
                          value={this.state.textoApresentacao}
                          onChange={this.handleChange('textoApresentacao')}
                          type='text'
                          id='textoApresentacao'
                          label='Texto de Apresentação'
                        />

                        <FormLabel style={{ marginTop: '20px' }}>
                          2º campo de identificação
                        </FormLabel>
                        <RadioGroup
                          aria-label='identificacao2'
                          name='identificacao2'
                          value={this.state.identificacao2}
                          onChange={this.handleChange('identificacao2')}>
                          <FormControlLabel
                            value={this.state.nome}
                            control={<Radio color='primary' />}
                            label='Usuário'
                            labelPlacement='end'
                          />

                          <FormControlLabel
                            value=''
                            control={<Radio color='primary' />}
                            label='Vazio'
                            labelPlacement='end'
                          />
                        </RadioGroup>

                        {/* <Typography variant="h6" gutterBottom color='secondary'>Imagem do Verso do Crachá </Typography>
							
							<input style={{ display : 'none' }} type = "file" accept=".png,.jpg" onChange = { this.fileChangedHandler('crachaVerso', 54/86) } id="crachaVerso" />
					
							<label htmlFor="crachaVerso">
								<Button style={{ textTransform: 'capitalize'}}  variant="raised" component="span"
									classes={{ root: classes.root, disabled: classes.disabled }}>
										<AddImageIcon color='secondary' />&nbsp; <span style={{ fontWeight: '550', color: '#6c6c6c'}}>Alterar imagem</span>
									</Button>
							</label> */}
                      </FormControl>
                    </Grid>

                    <Grid style={{ padding: '3px' }} item md={3} sm={3} xs={12}>
                      <Typography variant='h6' gutterBottom color='secondary'>
                        Frente{' '}
                      </Typography>

                      <div
                        style={{
                          position: 'relative',
                          border: '1px solid #000',
                          borderRadius: '7px',
                        }}
                        align='center'>
                        <div
                          style={{
                            backgroundColor: 'white',
                            height: '10px',
                          }}></div>
                        <img
                          id='crachaCabecalho'
                          width='100%'
                          src={this.state.crachaCabecalho}
                          alt='Frente do Cracha'
                        />

                        <div
                          style={{
                            width: '100%',
                            backgroundColor: 'orange',
                            textAlign: 'center',
                            marginTop: '-16px',
                          }}>
                          <Typography
                            id='identificacao1'
                            style={{
                              fontWeight: 'bold',
                              paddingTop: '20px',
                              paddingBottom: '10px',
                            }}
                            color='secondary'
                            variant='subtitle2'
                            gutterBottom>
                            <span style={{ color: 'white' }}>
                              {this.state.identificacao1.toUpperCase()}
                            </span>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            style={{ fontWeight: '550', width: '60%' }}
                            color='secondary'
                            variant='subtitle2'
                            gutterBottom>
                            <span style={{ color: '#363636' }}>
                              {this.state.textoApresentacao}&nbsp;
                            </span>
                          </Typography>
                        </div>
                        <div style={{ width: '100%', height: '100%' }}>
                          <QRCode
                            style={{ width: '45%', height: '45%' }}
                            id='QRCode'
                            value={this.state.email.replace(/[.]/gi, ',')}
                            size={1000}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                            level={'M'}
                          />
                        </div>
                        <br />
                        <div
                          style={{
                            display: 'block',
                            margin: '0 auto',
                            textAlign: 'center',
                          }}>
                          <Typography
                            style={{ fontWeight: 'bold' }}
                            color='secondary'
                            variant='subtitle2'
                            gutterBottom>
                            <span style={{ color: '#363636' }}>
                              {this.state.identificacao2.toUpperCase()}&nbsp;
                            </span>
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    {/* <Grid style={{padding : '3px'}} item md={3} sm={3} xs={12}>
							<Typography variant="h6" gutterBottom color='secondary'>Verso </Typography>
							<div style={{ border: '1px solid #000', borderRadius: '7px' }}>
								<div style={{ backgroundColor: 'white', height: '10px' }}></div>
								<img id="imgVerso" width="99%" src={ this.state.crachaVerso } alt="Verso do Cracha" />
							</div>
						</Grid> */}
                  </Grid>
                </div>
              ) : null
            ) : null}

            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby='simple-modal-title'
              aria-describedby='simple-modal-description'
              open={this.state.openModal}>
              <div style={getModalStyle()}>
                <Typography
                  variant='h5'
                  gutterBottom
                  color='primary'
                  style={{ fontWeight: '600' }}>
                  Editar Imagem
                </Typography>

                <div
                  style={{
                    maxWidth: '30%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}>
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={this.state.ratio}
                    />
                  )}
                </div>

                <div style={{ position: 'fixed', bottom: 10 }}>
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant='contained'
                    color='secondary'
                    onClick={() => this.handleCloseModal()}
                    startIcon={<SaveIcon color='white' />}>
                    Cancelar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant='contained'
                    color='primary'
                    onClick={() => this.cropImage('image')}
                    startIcon={<SaveIcon color='white' />}>
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}

            <Button
              disabled={
                this.state.nome === '' || this.state.nome === false
                  ? true
                  : false
              }
              style={{
                fontWeight: '550',
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant='contained'
              color='secondary'
              onClick={() => this.print()}
              startIcon={<SaveIcon color='white' />}>
              Imprimir
            </Button>

            {/* <Button disabled={this.state.nome === '' || this.state.nome === false ? true : false} style={{fontWeight: '550', marginTop:'20px', marginLeft:'20px', color: 'white', textTransform: 'capitalize'}} variant="contained" color="primary" onClick = {() => this.handleSubmit() } startIcon={<SaveIcon color='white' />} >
						Salvar
					</Button> */}

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id='message-id'>{this.state.messageAlert}</span>}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.validatorsInformationComponent.loading,
    success: state.validatorsInformationComponent.success,
    error: state.validatorsInformationComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateValidatorsComponent: (
    idEstabelecimento,
    idGestor,
    validador,
    isValidator
  ) =>
    updateValidatorsAction(
      dispatch,
      idEstabelecimento,
      idGestor,
      validador,
      isValidator
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ValidatorsInformation);
