import React from 'react';

//styles
import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';

//icons
import { MdModeEditOutline } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';

//components
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import { CircularProgress } from '@mui/material';
import Switch from '@mui/material/Switch';

export default function FieldsList({
  formsFields,
  setFormsFields,
  fieldIndexKey,
  loading,
  handleSubmit,
  setFieldToEdit,
  setIsModalOpen,
  rules,
  setRules,
  setQuestions,
  handleRemoveField,
}) {
  // const handleRemoveField = (fieldName, isIndexKey) => {
  //   const newFormsFields = Array.from(formsFields).filter(
  //     (field) => field.fieldName !== fieldName
  //   );

  //   if (isIndexKey) {
  //     const findCellPhone = Array.from(formsFields).find(
  //       (field) => field.fieldName === "cellPhone"
  //     );

  //     if (!findCellPhone) {
  //       setFormsFields(
  //         new Set([
  //           ...formsFields,
  //           {
  //             id: formsFields.size.toString(),
  //             fieldName: "cellPhone",
  //             label: "Insira seu celular",
  //             fieldType: "cellPhone",
  //             inputType: "text",
  //             inputMask: "cellPhone",
  //             allowEdition: true,
  //             required: true,
  //             type: "contactList",
  //             indexKey: false,
  //           },
  //         ])
  //       );
  //     } else {
  //       setFormsFields(new Set(newFormsFields));
  //     }
  //   } else {
  //     setFormsFields(new Set(newFormsFields));
  //   }
  // };

  const handleOpenEditField = (fieldName) => {
    setFieldToEdit(fieldName);
    setIsModalOpen(true);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = formsFields;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setQuestions(items);
  }

  const getItemStyle = (draggableStyle) => ({
    ...draggableStyle,
  });

  const handleSwitchChange = ({ target }) => {
    setRules({
      ...rules,
      [target.ariaLabel]: !rules[target.ariaLabel],
    });
  };

  const label = { inputProps: { 'aria-label': 'estimatedMinimumTime' } };
  // const label2 = { inputProps: { 'aria-label': 'npsFormAnswered' } };
  const label3 = { inputProps: { 'aria-label': 'postphoneCheck' } };
  const label4 = { inputProps: { 'aria-label': 'isActive' } };

  return (
    <>
      <S.AddNewFieldContainer>
        <DashedButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setIsModalOpen(true);
            setFieldToEdit('');
          }}
        >
          + Adicionar novo campo
        </DashedButton>
      </S.AddNewFieldContainer>

      <S.DragContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <T.TriiboH4>Ativar formulário.</T.TriiboH4>

          <Switch
            value={rules?.isActive}
            onChange={handleSwitchChange}
            checked={rules?.isActive}
            {...label4}
          />
        </div>
        <>
          <T.TriiboH4>
            <strong>Regras</strong>
          </T.TriiboH4>

          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <T.TriiboH4>Tempo minimo de cadastro do usuario (15 dias).</T.TriiboH4>

              <Switch
                value={rules?.estimatedMinimumTime}
                onChange={handleSwitchChange}
                checked={rules?.estimatedMinimumTime}
                {...label}
              />
            </div>

            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <T.TriiboH4>Usuario só poderá responder uma vez.</T.TriiboH4>

              <Switch
                value={rules?.npsFormAnswered}
                onChange={handleSwitchChange}
                checked={rules?.npsFormAnswered}
                {...label2}
              />
            </div> */}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <T.TriiboH4>Usuario pode adiar 7 dias.</T.TriiboH4>

              <Switch
                value={rules?.postphoneCheck}
                onChange={handleSwitchChange}
                checked={rules?.postphoneCheck}
                {...label3}
              />
            </div>
          </div>
        </>

        <hr />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <T.TriiboH4>
              <strong>Campos do formulário</strong>
            </T.TriiboH4>
            <T.TriiboH5>
              Para alterar a ordem de exibição dos campos no formulário, clique a arraste.
            </T.TriiboH5>
          </div>
        </div>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="formsFields">
            {(provided, snapshot) => (
              <S.DragContainerList
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDraggingOver}
              >
                {formsFields.map(({ question, type, required }, index) => {
                  return (
                    <Draggable key={question} draggableId={question} index={index}>
                      {(provided, snapshot) => (
                        <S.DragItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          style={getItemStyle(provided.draggableProps.style)}
                        >
                          <S.CardItemInfos>
                            <div>
                              <T.TriiboH2>{question}</T.TriiboH2>
                              <T.TriiboH4>
                                Tipo: {type === 'rating' ? 'Avaliação' : 'Texto'}
                              </T.TriiboH4>
                            </div>

                            <S.Actions>
                              <S.ButtonAction
                                onClick={() => {
                                  handleOpenEditField({
                                    label: question,
                                    type,
                                    required,
                                    index,
                                  });
                                }}
                                bgColor={'#3A63F1'}
                              >
                                <MdModeEditOutline color="#fff" />
                              </S.ButtonAction>

                              <S.ButtonAction
                                onClick={() => {
                                  handleRemoveField(index);
                                }}
                                bgColor={'#F13A3A'}
                              >
                                <FiTrash2 color="#fff" />
                              </S.ButtonAction>
                            </S.Actions>
                          </S.CardItemInfos>
                        </S.DragItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </S.DragContainerList>
            )}
          </Droppable>
        </DragDropContext>
        <PrimaryButton onClick={handleSubmit} disabled={loading} width={'100px'}>
          {loading ? <CircularProgress size={20} /> : 'Salvar'}
        </PrimaryButton>
      </S.DragContainer>
    </>
  );
}

