import React from 'react';

import * as S from './style';

const ButtonExit = (props) => {

    const { open, setModalStatus } = props;

    return (
        <>
            <S.SidebarLinkExit open={open} to={props.to} exit={open} onClick={() => { if(open) {setModalStatus(true);} }}>
                <S.SidebarButton open={open} exit={true}>
                
                    <S.SidebarButtonContents purple={open} exit={true}>
                        
                        <S.SidebarButtonIcon open={open}>
                            <S.SidebarIconContainer>
                                {props.icon}  
                            </S.SidebarIconContainer>
                        </S.SidebarButtonIcon>
                        
                        <S.SidebarButtonChildren open={open}>
                            {props.children}
                        </S.SidebarButtonChildren>
                        
                    </S.SidebarButtonContents>        
                
                </S.SidebarButton>
            </S.SidebarLinkExit>
        </>
    );
};

export default ButtonExit;