import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//STYLES
import * as D from 'js/components/DesignSystem/Table/styles';
import { Autocomplete, Button, CircularProgress, Grid, TextField } from '@mui/material';
import Switch from 'react-switch';

// COMPONENTS
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { GoBackHeader } from '../RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../RelationshipClub/HeaderTitle';
import { MainContainer } from 'js/components/Clubs/clubeTableStyles';
import { ModalCreateFileType } from './ModalCreateFileType';

// APIS
import { getPartnerFilesType } from 'js/library/utils/API/getPartnerFilesType';
import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { updatePartnerFilesActive } from 'js/library/utils/API/updatePartnerFilesActive';

export function CreatePartnerFileType() {
  const column = [
    { heading: 'Parceiro', value: 'clubName' },
    { heading: 'Prefixo', value: 'filePrefix' },
    { heading: 'Status', value: 'active' },
    { heading: 'Nome do processo', value: 'processName' },
  ];
  const [callPage, setCallPage] = useState(false);
  const [fileTypesList, setFileTypesList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [partners, setPartners] = useState([]);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [loadingId, setLoadingId] = useState(null);
  const [message, setMessage] = useState('');

  // BUSCA OS FILESTYPES DE ACORDO COM O PARTNERID INFORMADO
  useEffect(() => {
    getPartnerFilesType(selectedPartnerId)
      .then((types) => {
        if (types.length === 0)
          return setMessage(
            'Selecione um parceiro. Se você já fez essa seleção e nenhum tipo de arquivo foi listado, isso significa que o parceiro ainda não possui arquivos cadastrados.'
          );

        const processedTypes = types.map((type) => ({
          ...type,
          active: type.active ? 'Ativo' : 'Inativo',
          clubName: extractClubName(type.partnerId),
        }));

        setFileTypesList(processedTypes);
        setMessage('');
      })
      .catch((err) => {
        setMessage(err);
      });
  }, [callPage, selectedPartnerId]);

  // BUSCA OS PARTNERSID DOS CLUBES
  useEffect(() => {
    getSeuClubList().then((res) => {
      const partnerList = [];
      const clubsActive = res.filter((club) => club.status === true);
      clubsActive.map((club) => {
        const necessaryInfos = {
          name: club.clubeId,
          partnerId: club.partnerId,
        };

        return partnerList.push(necessaryInfos);
      });
      setPartners(partnerList);
    });
  }, []);

  // PEGA O NOME DO CLUBE
  const extractClubName = (str) => {
    const lastUnderscore = str.lastIndexOf('_');
    if (lastUnderscore !== -1) {
      return str.substring(lastUnderscore + 1);
    }
    return str; // Retorna a string original se não houver underscore
  };

  const handleChangeModal = () => {
    setIsOpenModalCreate(!isOpenModalCreate);
  };

  const handleSelectPartnerId = (event, newValue) => {
    if (newValue) {
      setSelectedPartnerId(newValue.partnerId);
    } else {
      setSelectedPartnerId(null);
    }
  };

  const handleChangeToggleStatus = async (idPartnerType, isActive) => {
    setLoadingId(idPartnerType);
    try {
      await updatePartnerFilesActive(idPartnerType, !isActive);
      setFileTypesList((prev) =>
        prev.map((type) =>
          type.id === idPartnerType ? { ...type, active: !isActive ? 'Ativo' : 'Inativo' } : type
        )
      );
    } catch (e) {
      toast.error(e);
      setFileTypesList((prev) =>
        prev.map((type) =>
          type.id === idPartnerType ? { ...type, active: isActive ? 'Ativo' : 'Inativo' } : type
        )
      );
    } finally {
      setLoadingId(null);
    }
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <D.TableTH>Status</D.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <D.TableTD>
              <span style={{ display: 'flex', gap: '1rem' }}>
                {loadingId === childrens.id ? (
                  <CircularProgress size={26} thickness={6} sx={{ marginLeft: '1rem' }} />
                ) : (
                  <Switch
                    onColor="#06bad0"
                    checked={childrens.active === 'Ativo' ? true : false}
                    onChange={() =>
                      handleChangeToggleStatus(
                        childrens.id,
                        childrens.active === 'Ativo' ? true : false
                      )
                    }
                  />
                )}
              </span>
            </D.TableTD>
          </>
        ),
    };
  };
  return (
    <MainContainer style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Criação de tipos de arquivos" />

      <Grid container spacing={2} sx={{ marginTop: '2rem' }}>
        <Grid item md={3} xs={12}>
          <Autocomplete
            options={partners.filter((partner) =>
              partner.name.toLowerCase().includes(inputValue.toLowerCase())
            )}
            getOptionLabel={(option) => option.name}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={handleSelectPartnerId}
            renderInput={(params) => (
              <TextField {...params} label="Filtrar por parceiro" variant="outlined" fullWidth />
            )}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            sx={{ marginLeft: '2rem' }}
          />
        </Grid>
        <Grid
          item
          md={9}
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Button
            color="primary"
            sx={{ height: '50px', fontWeight: 700, marginRight: '2rem', marginBottom: '1.5rem' }}
            onClick={handleChangeModal}
            variant="contained"
          >
            Criar Tipo de arquivo
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid item md={12} sx={{ marginTop: '2rem' }}>
          {message !== '' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.1rem',
                lineHeight: '1.6rem',
                fontWeight: 600,
                textAlign: 'center',
                maxWidth: '800px',
                margin: '0 auto',
              }}
            >
              {message}
            </div>
          ) : (
            <CustomButtonsTable
              data={fileTypesList}
              column={column}
              perPage={1000}
              componentReceiver={componentReceiver}
              isPagination={true}
            />
          )}
        </Grid>
      </Grid>
      <ModalCreateFileType
        close={handleChangeModal}
        isOpen={isOpenModalCreate}
        partnersList={partners}
        setCallPage={setCallPage}
      />
    </MainContainer>
  );
}
