import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import DataTableTemplates from 'js/containers/DataTable/DataTableTemplates';
import { GoBackHeader } from '../CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../CreateClub/RelationshipClub/HeaderTitle';

const useStyles = makeStyles((theme) => ({
  buttonAddQrcode: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1.3rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const TemplatesList = () => {
  const classes = useStyles();

  const state = {
    rows: [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
      },
    ],
    pathName: '/admin/configuracoes/templates/editar/',
  };

  return (
    <div
      style={{
        maxHeight: '100%',
        padding: '4rem 32px',
        maxWidth: '100%',
      }}
    >
      <GoBackHeader />
      <HeaderTitle pageInfo="Templates" />
      <Grid container>
        <Grid item xs={6} />
        <Grid item xs={6} />
      </Grid>
      <Box className={classes.buttonAddQrcode}>
        <Link
          to={{
            pathname: '/admin/configuracoes/templates/adicionar',
            state: '',
          }}
          style={{ textDecoration: 'none' }}
        >
          <Button
            style={{
              fontWeight: '550',
              color: 'white',
              textTransform: 'capitalize',
            }}
            title="Adicionar Template"
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
          >
            Adicionar Template
          </Button>
        </Link>
      </Box>

      <DataTableTemplates rows={state.rows} tableNode={'Templates'} pathName={state.pathName} />
    </div>
  );
};

export default TemplatesList;

