import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUserInfo } from 'js/library/utils/helpers.js';

import EstablishmentScreen from 'js/components/Establishment/EstablishmentScreen';
import indexCredits from 'js/components/Establishment/indexCredits';
import AttendanceScreen from 'js/components/Establishment/AttendanceScreen';
import ClientScreen from 'js/components/Establishment/ClientScreen';
import ResultsScreen from 'js/components/Establishment/ResultsScreen';
import RelationshipScreen from 'js/components/Establishment/RelationshipScreen';
import ContactTriiboExternal from 'js/components/Store/ContactTriiboExternal';
import AdminConfigurationExternal from 'js/components/Configurations/AdminConfigurationExternal';
import StorePromotionExternal from 'js/components/Store/Promotion/StorePromotionExternal';
import StoreGroupExternal from 'js/components/Store/Group/StoreGroupExternal';
import StoreListExternal from 'js/components/Store/StoreListExternal';
import StoreBusinessPartnerExternal from 'js/components/Store/BusinessPartner/StoreBusinessPartnerExternal';
import QRCodeGenerate from 'js/components/Store/QRCode/QRCodeGenerate';
import PromotionTemplate from 'js/components/Promotions/PromotionTemplate';
import PromotionForm from 'js/components/Promotions/PromotionForm';
import Welcome from 'js/components/Welcome/Welcome';
import Promote from 'js/components/Establishment/PromoteScreen';
import MarketingScreen from 'js/components/Establishment/MaterialMarketing';
import WidgetWhatsapp from 'js/components/WidgetWhatsapp/WidgetWhatsapp';
import FAQ from 'js/components/FAQ/FAQ';
import NotFound from 'js/components/ErrorPages/NotFound';
import RegistrationOption from 'js/components/Establishment/RelationshipScreen/RegistrationOption';
import RegisterListUsers from 'js/components/Establishment/RelationshipScreen/RegisterListUsers';
import ListUsers from 'js/components/Establishment/RelationshipScreen/ListUsers';
import StepsConcludedPage from 'js/components/StepsConcluded/ModalFirstStep/StepsConcludedPage';
import SecondStepConcludedPage from 'js/components/StepsConcluded/ModalSecondStep/SecondStepConcludedPage';
import ThirdStepConcludedPage from 'js/components/StepsConcluded/ModalThirdStep/ThirdStepConcludedPage';
import EstablishmentCreateNew from 'js/components/Establishment/EstablishmentCreateNew';
import EstablishmentEdit from 'js/components/Establishment/EstablishmentEdit';
import RegisterUsefullLinks from 'js/components/Establishment/RelationshipScreen/RegisterUsefullLinks';
import ClubTable from 'js/components/Clubs/ClubTable';
import EditClub from 'js/components/Configurations/CreateClub/EditClub';
import ListCommunications from 'js/components/Configurations/Communications/ListCommunications';
import SendCommunication from 'js/components/Configurations/Communications/SendCommunication';
import RelationshipClub from 'js/components/Configurations/CreateClub/RelationshipClub/index';
import RegistrationOptionClub from 'js/components/Configurations/CreateClub/RelationshipClub/RegistrationOption';
import RegisterListUsersClub from 'js/components/Configurations/CreateClub/RelationshipClub/RegisterListUsers';
import ListUsersClub from 'js/components/Configurations/CreateClub/RelationshipClub/ListUsers';
import ClubMenu from 'js/components/Configurations/CreateClub/ClubMenu';
import ClubManageAdmin from 'js/components/Configurations/CreateClub/ClubManageAdmin';
import ListPosts from 'js/components/Configurations/Posts/ListPosts';
import SendPosts from 'js/components/Configurations/Posts/SendPosts';
import FormSignUp from 'js/components/Configurations/CreateClub/FormSignUp';
import { AccessPermission } from 'js/components/Configurations/CreateClub/AccessPermissions/AccessPermissions';
import { EditWhitelist } from 'js/components/Configurations/CreateClub/AccessPermissions/EditWhitelist/EditWhitelist';
import AttachWhitelist from 'js/components/Configurations/CreateClub/AccessPermissions/AttachWhitelist/AttachWhitelist';
import { CardManagement } from 'js/components/Configurations/CreateClub/CardManagement/CardManagement';
import getEstablishmentId from '../API/Establishment/apiReadEstablishment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ForbiddenWords from 'js/components/Configurations/CreateClub/ForbiddenWords';
import NpsFormSignUp from 'js/components/Configurations/CreateClub/NpsFormSignUp';
import PointsEstablishment from 'js/components/Establishment/PointsManagement/PointsEstablishment';
import PointsClub from 'js/components/Configurations/CreateClub/PointsManagement/PointsClub';
import { SegmentationList } from 'js/components/Clubs/Segmentation/SegmentationList';
import { MemberManagement } from 'js/components/Clubs/Segmentation/MemberManagement';
import { AddSegmentation } from 'js/components/Clubs/Segmentation/AddSegmentation';
import Rules from 'js/components/Clubs/Segmentation/Rules';
import ResourceManagement from 'js/components/Clubs/Segmentation/ResourceManagement';
import LinkType from 'js/components/Clubs/Segmentation/LinkType';
import { PartnerFilesList } from 'js/components/Configurations/CreateClub/CreatingAndListingFiles/PartnerFilesList';

const PrivateRoute = ({ component: Component, roles, userRole, ...rest }) => {
  const loading = userRole === undefined || userRole === null || userRole === '' ? true : false;

  return (
    <>
      <WidgetWhatsapp />
      <Route
        {...rest}
        render={(props) =>
          roles.includes(userRole) ? (
            <Component {...props} />
          ) : loading ? (
            ''
          ) : (
            <Redirect to="/notpermission" />
          )
        }
      />
    </>
  );
};

const UserRouter = (props) => {
  const { state } = props;

  let id = useSelector((state) => state.currentEstModel.currentEst.id);
  // let id = store.getState().establishmentInformationModel.id; //TODO arrumar aqui
  const location = useLocation();

  const loggedUid = getUserInfo().uId;

  const [roleUser, setRoleUser] = useState('');

  useEffect(() => {
    if (!id && state.establishmentList.length > 0) {
      id = state.establishmentList[0].id;
    }
    if (id) {
      setTimeout(() => {
        getEstablishmentId(id).then((result) => {
          const findRole =
            result && Array.isArray(result.admins)
              ? result.admins.find((user) => user.uid === loggedUid)
              : '';
          if (findRole && findRole.cargo) {
            setRoleUser(findRole.cargo);
          }
        });
      }, 800);
    }
  }, [id, loggedUid, location]);

  return (
    <>
      <WidgetWhatsapp />
      <div>
        <Switch>
          <Route exact path="/admin/" component={Welcome} />

          {/* Adicionar estabelecimento */}
          <Route exact path="/estabelecimento/novo/adicionar" component={EstablishmentCreateNew} />

          {/* Seu Clube */}

          <Route
            exact
            path="/admin/clube/seu-clube"
            // roles={["gestor", "administrador"]}
            component={ClubTable}
            // userRole={roleUser}
          />

          <Route
            exact
            path="/admin/seu-clube/:id"
            // roles={["gestor", "administrador"]}
            component={EditClub}
            // userRole={roleUser}
          />

          <Route exact path="/admin/seu-clube/configuracoes/:id" component={ClubMenu} />
          <Route exact path="/admin/seu-clube/gerir-admin/:id" component={ClubManageAdmin} />

          <Route exact path="/admin/seu-clube/relacionamento/:id" component={RelationshipClub} />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/cadastro-usuarios/:id"
            component={RegistrationOptionClub}
          />

          {/* <Route
            exact
            path="/admin/seu-clube/lista-comunicacoes"
            component={ListCommunications}
          /> */}

          <Route
            exact
            path="/admin/seu-clube/relacionamento/cadastra-lista-usuarios/:id"
            component={RegisterListUsersClub}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/lista-usuarios/:id"
            component={ListUsersClub}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/lista-postagem/:id"
            roles={['gestor', 'administrador', 'validador']}
            component={ListPosts}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/envio-postagem/:id"
            roles={['gestor', 'administrador', 'validador']}
            component={SendPosts}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/envio-postagem/"
            roles={['gestor', 'administrador', 'validador']}
            component={SendPosts}
          />

          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/relacionamento/envio-postagem/:id"
            component={SendPosts}
            userRole={roleUser}
          />

          <Route exact path="/admin/configuracoes/enviaPostagem" component={SendPosts} />

          <Route exact path="/admin/configuracoes/enviaPostagem/:id" component={SendPosts} />

          <Route exact path="/admin/seu-clube/formulario/:id" component={FormSignUp} />

          <Route exact path="/admin/seu-clube/permissoes-acesso/:id" component={AccessPermission} />
          <Route exact path="/admin/seu-clube/formulario-nps/:id" component={NpsFormSignUp} />
          <Route exact path="/admin/seu-clube/gestao-pontos/:id" component={PointsClub} />

          <Route
            exact
            path="/admin/seu-clube/permissoes-acesso/editar-whitelist/:id"
            component={EditWhitelist}
          />

          <Route
            exact
            path="/admin/seu-clube/permissoes-acesso/cadastrar-lote-whitelist/:id"
            component={AttachWhitelist}
          />

          <Route exact path="/admin/seu-clube/gerenciar-cards/:id" component={CardManagement} />

          <Route exact path="/admin/seu-clube/palavras-proibidas/:id" component={ForbiddenWords} />

          {/* SEGMENTAÇÃO */}

          <Route exact path="/admin/seu-clube/segmentacao/:id" component={SegmentationList} />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/lista-usuarios/:id"
            component={MemberManagement}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/lista-recursos/:id"
            component={ResourceManagement}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/adicionar-segmento/:id"
            component={AddSegmentation}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/editar-segmento-pessoa/:id"
            component={AddSegmentation}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/editar-segmento-recurso/:id"
            component={AddSegmentation}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/regras-segmento-pessoa/:id"
            component={Rules}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/regras-segmento-recurso/:id"
            component={Rules}
          />

          <Route exact path="/admin/seu-clube/segmentacao/linkar-tipo/:id" component={LinkType} />
          <Route exact path="/admin/seu-clube/listagem-arquivos/:id" component={PartnerFilesList} />

          {/* Estabelecimento */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id"
            roles={['gestor', 'administrador', 'validador']}
            component={EstablishmentScreen}
            userRole={roleUser}
          />

          {/* Resultados Graficos */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/resultados"
            roles={['gestor', 'administrador']}
            component={ResultsScreen}
            userRole={roleUser}
          />

          {/* Atendimento */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/atendimento"
            roles={['gestor', 'administrador', 'validador']}
            component={AttendanceScreen}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/atendimento/cliente"
            roles={['gestor', 'administrador', 'validador']}
            component={ClientScreen}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/suporte"
            roles={['gestor', 'administrador', 'validador']}
            component={ContactTriiboExternal}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            path="/estabelecimento/:id/editar"
            roles={['gestor', 'administrador']}
            component={EstablishmentEdit}
            userRole={roleUser}
          />

          {/* Gerenciar Equipe */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/administradores"
            roles={['gestor', 'administrador']}
            component={AdminConfigurationExternal}
            userRole={roleUser}
          />

          {/* Promoções */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocoes"
            roles={['gestor', 'administrador']}
            component={StoreListExternal}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocao/adicionar"
            roles={['gestor', 'administrador']}
            component={StorePromotionExternal}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocao/:promoId/editar"
            roles={['gestor', 'administrador']}
            component={StorePromotionExternal}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocaofidelidade/adicionar"
            roles={['gestor', 'administrador']}
            component={StoreGroupExternal}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocaofidelidade/editar"
            roles={['gestor', 'administrador']}
            component={StoreGroupExternal}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocaoexterna/adicionar"
            roles={['gestor', 'administrador']}
            component={StoreBusinessPartnerExternal}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocaoexterna/editar"
            roles={['gestor', 'administrador']}
            component={StoreBusinessPartnerExternal}
            userRole={roleUser}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocaoexterna/adicionar"
            component={StoreBusinessPartnerExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocaoexterna/:promoId/editar"
            component={StoreBusinessPartnerExternal}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promocoes/qrcode/generate"
            roles={['gestor', 'administrador']}
            component={QRCodeGenerate}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promotions/simple"
            roles={['gestor', 'administrador']}
            component={PromotionTemplate}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promotions/simple/add"
            roles={['gestor', 'administrador']}
            component={PromotionForm}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promotions/loyalty"
            roles={['gestor', 'administrador']}
            component={PromotionTemplate}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/promotions/loyalty/add"
            roles={['gestor', 'administrador']}
            component={PromotionForm}
            userRole={roleUser}
          />

          {/* Comprar Créditos */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/comprarcreditos/"
            roles={['gestor']}
            component={indexCredits}
            userRole={roleUser}
          />

          {/* Gestão de pontos */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/gestao-pontos/"
            roles={['gestor']}
            component={PointsEstablishment}
            userRole={roleUser}
          />

          {/* Relacionamento */}
          <PrivateRoute
            exact
            path="/estabelecimento/:id/relacionamento/"
            roles={['gestor', 'administrador']}
            component={RelationshipScreen}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/:id/relacionamento/cadastro-usuarios"
            component={RegistrationOption}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/:id/relacionamento/cadastra-lista-usuarios"
            component={RegisterListUsers}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/:id/relacionamento/lista-usuarios"
            component={ListUsers}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/relacionamento/envio-comunicacoes/:id"
            component={SendCommunication}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/relacionamento/lista-comunicacoes/:id"
            component={ListCommunications}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/relacionamento/lista-postagem"
            component={ListPosts}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            path="/estabelecimento/:id/promover/"
            roles={['gestor', 'administrador']}
            component={Promote}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/marketing/"
            roles={['gestor', 'administrador']}
            component={MarketingScreen}
            userRole={roleUser}
          />
          <PrivateRoute
            exact
            roles={['gestor', 'administrador']}
            path="/estabelecimento/:id/promover/links-uteis"
            component={RegisterUsefullLinks}
            userRole={roleUser}
          />

          <PrivateRoute
            exact
            path="/estabelecimento/:id/relacionamento/cadastro-usuarios/step"
            roles={['gestor', 'administrador']}
            component={RegistrationOption}
            userRole={roleUser}
          />

          <Route exact path="/estabelecimento/:id/passos" component={StepsConcludedPage} />

          <Route exact path="/estabelecimento/:id/secondStep" component={SecondStepConcludedPage} />

          <Route exact path="/estabelecimento/:id/thirdStep" component={ThirdStepConcludedPage} />

          <Route exact path="/admin/faq/" component={FAQ} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

export default withRouter(UserRouter);
