import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const ContentAction = styled.div`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`;

export const EditInfoButton = styled.button`
  background: none;
  border: none;
  color: #0372ff;
  text-decoration: underline;
  cursor: pointer;
`;

export const ContentActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
