import * as React from 'react';

const IconExit = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        // width={100}
        // height={100}
        viewBox="0 0 100 100"
        xmlSpace="preserve"
        {...props}
    >
        <g fill="#6E3296">
            <path d="m67.319 33.759 6.717-6.717 23.997 23.994L74.036 75.03l-6.717-6.717 12.479-12.477H33.256v-9.593h46.3L67.319 33.759z" />
            <path d="M12.631 7.843h38.458c5.294.014 9.584 4.304 9.598 9.598v19.197h-9.598V17.441H12.631v67.187h38.458V65.429h9.598v19.197c-.014 5.294-4.304 9.584-9.598 9.598H12.631c-5.294-.014-9.582-4.301-9.598-9.596V17.441c.014-5.295 4.304-9.584 9.598-9.598z" />
            <path d="M18.86 48.255h5.746v5.747H18.86z" />
        </g>
    </svg>
);

export default IconExit;
