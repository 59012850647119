import React, { useState } from 'react';
import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const CropperContainer = styled.span`
  max-width: 24rem;
  /* max-height: 15rem; */
`;

const ButtonContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
  margin-top: 0;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

const CropperToolArr = (props) => {
  const { setOpen, img, imgPreview, setImgPreview, setImg } = props;

  const [cropper, setCropper] = useState();

  //aqui seta a imagem quando clica em cortar
  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      let newArr = [...img];
      newArr[newArr.length] = cropper.getCroppedCanvas().toDataURL();
      setImg(newArr);
    }
  };

  return (
    <>
      <CropperContainer>
        <div style={{ width: '100%' }}>
          {imgPreview && (
            <Cropper
              style={{ maxHeight: '15rem', width: '100%' }}
              zoomTo={0.2}
              aspectRatio={4 / 3}
              preview=".img-preview"
              src={imgPreview}
              viewMode={2}
              dragMode={'move'}
              minCropBoxWidth={100}
              background={false}
              responsive={true}
              autoCropArea={4 / 3}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          )}
        </div>
      </CropperContainer>
      <ButtonContainer>
        <D.TriiboFilledButton
          blue={true}
          disabled={!imgPreview}
          onClick={(e) => {
            e.preventDefault();
            getCropData();
            setImgPreview(null);
            setOpen(false);
          }}
        >
          Cortar
        </D.TriiboFilledButton>
        <D.TriiboWhiteButton
          blue={true}
          onClick={(e) => {
            e.preventDefault();
            const newArr = [...img];
            newArr.pop();
            setImgPreview(null);
            setOpen(false);
          }}
        >
          Cancelar
        </D.TriiboWhiteButton>
      </ButtonContainer>
    </>
  );
};

export default CropperToolArr;
