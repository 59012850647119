import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  TextField,
  Typography,
  Snackbar,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Grid,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import Loading from 'js/containers/Loading/Loading';

import { getDataRedshift } from 'js/library/utils/API/apiRedshift.js';
import { updateBatchActionsAction } from 'js/core/actions/batchActionsActions';
import { getUserInfo } from 'js/library/utils/helpers';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const BatchActionsEdit = (props) => {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [state, setState] = useState({
    queryId: props.location.state.queryId === undefined ? null : props.location.state.queryId,
    name: props.location.state.name === undefined ? '' : props.location.state.name,
    createdDate:
      props.location.state.createdDate === undefined ? null : props.location.state.createdDate,
    creatorUId:
      props.location.state.creatorUId === undefined
        ? getUserInfo().uId
        : props.location.state.creatorUId,
    status: props.location.state.status === undefined ? 1 : props.location.state.status,

    query: props.location.state.query === undefined ? '' : props.location.state.query,
    lastQuery: '',
    userList: [],

    exportField: '',

    openSnackbar: false,
    snackMessage: '',
    authenticated: false,
  });

  const handleCloseAlert = () => {
    setState((prevState) => ({ ...prevState, openSnackbar: false }));
  };

    const redshiftQuery = (query) => {
      setLoadingAdd(true);
    
      setState((prevState) => ({
        ...prevState,
        userList: false,
        lastQuery: '',
        queryResult: [],
      }));
    
      getDataRedshift(query)
        .then((result) => {
          if (result !== false) {
            if (result.length === 0) {
              setState((prevState) => ({
                ...prevState,
                openSnackbar: true,
                snackMessage: 'Nenhum resultado encontrado',
                userList: [],
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                userList: result,
                lastQuery: query,
                queryResult: result,
              }));
            }
          } else {
            setState((prevState) => ({
              ...prevState,
              openSnackbar: true,
              snackMessage: 'Busca inválida',
              userList: [],
            }));
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            openSnackbar: true,
            snackMessage: 'Erro ao realizar busca: ' + error.toString(),
            userList: [],
          }));
        })
        .finally(() => {
          setLoadingAdd(false);
        });
    };
    

  const clearList = () => {
    setState((prevState) => ({
      ...prevState,
      userList: [],
      lastQuery: '',
      queryResult: [],
    }));
  };

  const getExportList = () => {
    if (state.queryResult) {
      let finalList = '';

      Object.keys(state.queryResult).map((item, index) => {
        return (finalList +=
          (index === 0 ? '' : '\n') + state.queryResult[item][state.exportField]);
      });
      if (state.exportField === 'linked_account') {
        return (finalList = finalList.replace(/[.]/gi, ','));
      }
      return finalList;
    }
  };

  const SaveQuery = () => {
    setState((prevState) => ({
      ...prevState,
      openSnackbar: true,
      snackMessage: `${prevState.name} 
		  ${prevState.status} 
		  ${prevState.lastQuery} 
		  ${Date.now()} 
		  ${prevState.creatorUId}`,
    }));

    let data = {
      queryId: state.queryId,
      name: state.name,
      query: state.lastQuery,
      createdDate: Date.now(),
      creatorUId: state.creatorUId,
      status: state.status,
    };

    props.updateBatchActionsComponent(data);
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  const handleChange = (name) => (event) => {
    if (name === 'query') {
      setState((prevState) => ({...prevState, query: event.target.value, lastQuery: '', queryResult: [], userList: [] }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleCheck = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.checked ? 1 : 0,
    }));
  };

  if (props.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  if (window.pathname) {
    return <Redirect to={window.pathname} />;
  } else {
    return (
      <div style={{ padding: '4rem 32px' }}>
        <GoBackHeader />
        <HeaderTitle pageInfo="Ações em Lote" />
        <br />
        <Typography variant="body2" gutterBottom color="primary" style={{ fontWeight: '600' }}>
          Consulta no Redshift
        </Typography>

        <TextField
          id="name"
          label="Nome da Query"
          value={state.name}
          onChange={handleChange('name')}
          margin="normal"
          style={{ width: '50%' }}
          required
        />

        <br />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.status === 1 ? true : false}
              onChange={handleCheck('status')}
              value="status"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Status"
          color="primary"
        />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            redshiftQuery(state.query);
          }}
        >
          <TextField
            id="query"
            label="Query"
            value={state.query}
            onChange={handleChange('query')}
            margin="normal"
            multiline
            style={{ width: '50%' }}
            required
          />

          <br />

          <TextField
            id="exportField"
            label="Campo que será exportado para arquivo"
            value={state.exportField}
            onChange={handleChange('exportField')}
            margin="normal"
            style={{ width: '50%' }}
          />

          <br />

          <Button
            type="submit"
            disabled={loadingAdd}
            style={{
              color: 'white',
              marginLeft: '0px',
              fontWeight: '550',
              textTransform: 'capitalize',
            }}
            variant="contained"
            color="primary"
          >
            {loadingAdd ? (
            <CircularProgress size={25} thickness={6} style={{ color: '#fff' }} />
          ) : (
            'Realizar consulta'
          )}
          </Button>
        </form>

        <br />

        <Typography variant="body2" gutterBottom color="primary" style={{ fontWeight: '600' }}>
          Lista de usuários
        </Typography>

        <Grid container spacing={5}>
          <Grid item md={6} sm={12}>
            {state.userList === false ? (
              <div align="center">
                <CircularProgress style={{ padding: '20px' }} />
              </div>
            ) : state.userList === null ? (
              <div>
                <br />
                <br />
                <Typography variant="body2">Nenhum resultado encontrado.</Typography>
              </div>
            ) : (
              <List style={{ height: '500px', overflowY: 'scroll', backgroundColor: '#fafafa' }}>
                {state.userList.map((item, i) => (
                  <ListItem key={i} role={undefined} dense button>
                    <ListItemText
                      primary={<pre> {JSON.stringify(state.userList[i], null, 2)} </pre>}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <Button
              disabled={state.userList.length === 0 || state.userList === false}
              onClick={() => clearList()}
              style={{
                color: 'white',
                marginLeft: '0px',
                fontWeight: '550',
                marginTop: '10px',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
            >
              Limpar Lista
            </Button>

            <Button
              disabled={
                state.lastQuery === '' || state.queryResult == [] || state.name.length === 0
              }
              href={'data:text/plain;charset=utf-8,' + encodeURIComponent(getExportList())}
              download={state.name + '.txt'}
              style={{
                color: 'white',
                fontWeight: '550',
                marginLeft: '10px',
                marginTop: '10px',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
            >
              Exportar resultado
            </Button>

            <Button
              disabled={
                state.lastQuery === '' || state.queryResult == [] || state.name.length === 0
              }
              onClick={() => SaveQuery()}
              style={{
                color: 'white',
                marginLeft: '10px',
                fontWeight: '550',
                marginTop: '10px',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
            >
              Salvar Query
            </Button>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={state.openSnackbar}
          onClose={handleCloseAlert}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<span id="message-id">{state.snackMessage}</span>}
        />
      </div>
    );
  }
};

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.batchActionsComponent.loading,
    success: state.batchActionsComponent.success,
    error: state.batchActionsComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateBatchActionsComponent: (value, eventType, eventDescription, clientList) =>
    updateBatchActionsAction(dispatch, value, eventType, eventDescription, clientList),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchActionsEdit);
