import React, { useRef, useState } from "react";

import * as S from "./styles";
import * as C from "../../../styles";
import * as D from "js/components/DesignSystem/styles";

import { Modal } from "@mui/material";
import importFileImg from "../../../../../../../../styles/assets/communications/importFile.svg";
import clipImg from "../../../../../../../../styles/assets/communications/clip.svg";
import { AiFillCloseCircle } from "react-icons/ai";
import { useEffect } from "react";

export default function ModalFileTxt({
  isModalFileTxtOpen,
  handleCloseModal,
  fileTxt,
  setFileTxt,
  setTargetAudienceIsSelected,
  setSelectedTargetAudience,
}) {
  const [file, setFile] = useState("");
  const hiddenFileInput = useRef(null);

  const handleClickInputFile = (e) => {
    hiddenFileInput.current.click();
  };

  const dragEvents = {
    onDragEnter: (e) => {
      e.preventDefault();
    },
    onDragLeave: (e) => {
      e.preventDefault();
    },
    onDragOver: (e) => {
      e.preventDefault();
    },
    onDrop: (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer) {
        setFile(e.dataTransfer.files[0]);
      } else if (e.target) {
        setFile(e.target.files[0]);
      }

      e.target.value = null;
    },
  };

  const setStateOpition = () => {
    if (file === "") {
      setSelectedTargetAudience("");
    }
  }


  useEffect(() => {
    if (fileTxt === "") {
      setFile("");
    }
  }, [fileTxt]);

  return (
    <Modal
      onClose={() => {
        setStateOpition();
        handleCloseModal("fileTxt");
      }}
      open={isModalFileTxtOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <C.ModalContainer>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={(e) => {
            setFile(e.target.files[0]);
            e.target.value = null;
          }}
          accept=".txt"
          style={{ visibility: "hidden", display: "none" }}
        />
        <C.ModalBox>
          <C.CloseModalIcon
          onClick={() => {
            handleCloseModal("fileTxt");
            setStateOpition();
            }}>
            <AiFillCloseCircle size={20} />
          </C.CloseModalIcon>

          <D.TriiboH4 style={{ textAlign: "center" }}>
            <strong>Adicione um arquivo de texto</strong>
          </D.TriiboH4>

          <S.ImportFileContainer
            fileTxt={file}
            onClick={() => file === "" && handleClickInputFile()}
            {...dragEvents}>
            {file === "" ? (
              <>
                <D.TriiboH5 className="title">
                  Arraste e solte os arquivos ou <span>selecione</span>
                </D.TriiboH5>
                <img src={importFileImg} alt="" />
              </>
            ) : (
              <>
                <D.TriiboH5 className="title">
                  Arquivo carregado com sucesso!
                </D.TriiboH5>
                <div>
                  <S.FileName>
                    <img src={clipImg} alt="" />
                    <D.TriiboH5>{file.name}</D.TriiboH5>
                  </S.FileName>
                  <S.RemoveFileButton
                  onClick={() => {
                    setFile("");
                    setStateOpition();
                    }}>
                    <D.TriiboH6>clique para remover</D.TriiboH6>
                  </S.RemoveFileButton>
                </div>
              </>
            )}
          </S.ImportFileContainer>

          <C.ActionButtons>
            <C.CancelButton 
              onClick={() => {
                setStateOpition();
                handleCloseModal("fileTxt");
              }}>
              <D.TriiboH5>Cancelar</D.TriiboH5>
            </C.CancelButton>
            <C.ContinueButton
              onClick={() => {
                setFileTxt(file);
                handleCloseModal("fileTxt");
                setTargetAudienceIsSelected(true);
                setStateOpition();
              }}>
              <D.TriiboH5>Continuar</D.TriiboH5>
            </C.ContinueButton>
          </C.ActionButtons>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
