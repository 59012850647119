import React, { useState } from "react";
import { useSelector } from "react-redux";

import * as D from "js/components/DesignSystem/styles";
import * as S from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

import { getUserInfo_v1 } from "js/library/utils/API/getUserInfo_v1";
import { Container, FormControlLabel, Radio } from "@mui/material";
import { HeaderTitle } from "./RelationshipClub/HeaderTitle";
import { TooltipEnvolveElement } from "./Tooltips";
import { GoBackHeader } from "./RelationshipClub/GoBackHeader";

function ButtonConditional({ history, ...props }) {
  const { isLoading, getUId } = props;
  if (isLoading) {
    return (
      <S.SearchButtonContainer>
        <CircularProgress size={window.screen.width <= 900 ? 22 : 30} />
      </S.SearchButtonContainer>
    );
  } else {
    return (
      <S.SearchButtonContainer>
        <S.SearchButton onClick={() => getUId()}>
          <SearchIcon sx={{ color: "#fff" }} />
        </S.SearchButton>
      </S.SearchButtonContainer>
    );
  }
}

const EditClubAdmin = ({ history, ...props }) => {
  const { AddAdmin } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [uId, setUId] = useState("");
  const [role, setRole] = useState("administrador");
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.authModel);

  function getUId() {
    setIsLoading(true);
    getUserInfo_v1(["getUserInfo"], {
      contactList: [
        { type: "cellPhone", value: phoneNumber.replace(/\D/g, "") },
      ],
    }).then((result) => {
      setUId(result.data.success.userInfo.uId);
      props.setAdminAddInfo({
        cellphone: phoneNumber.replace(/\D/g, ""),
        name: result.data.success.userInfo.name,
      });
      setIsLoading(false);
    });
  }

  function handleOptionChange(e) {
    setRole(e.target.value);
  }

  return (
   <>
   <GoBackHeader />
      <HeaderTitle  pageInfo="Gerenciar administradores" />
      <Container maxWidth={false}>
      <S.ManagerForm
        onSubmit={(e) => {
          e.preventDefault();
          AddAdmin(uId, {
            role: role,
          });
        }}
      >
        <S.FormDivider>
          <S.FormLabel style={{ fontSize: "1.3rem", fontWeight: 600 }}>
            Telefone do gestor:
          </S.FormLabel>
          <S.InputContainerWithSearchButton>
            <S.inputPhoneMuiu
              type="cellphone"
              placeholder="(00) 0 0000 0000"
              mask="+55 (99) 99999 9999"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            <ButtonConditional getUId={getUId} isLoading={isLoading} />
          </S.InputContainerWithSearchButton>
          <S.RadioContainer defaultValue="medium" name="radiuttons-group">
            <FormControlLabel
              control={
                <Radio
                  checked={role === "administrador"}
                  color="primary"
                  onChange={(e) => handleOptionChange(e)}
                  value="administrador"
                  variant="solid"
                />
              }
              label="Administrador"
            />

            <FormControlLabel
              control={
                <Radio
                  checked={role === "gestor"}
                  disabled={state.superUser ? false : true}
                  label="gestor"
                  onChange={(e) => handleOptionChange(e)}
                  value="gestor"
                  variant="solid"
                />
              }
              label="Gestor"
            />
          </S.RadioContainer>
        </S.FormDivider>
        <S.FormDivider>
          <TooltipEnvolveElement
            larg="18rem"
            title="Botão desabilitado ?"
            description="Após digitar um número válido, clique no simbolo da lupa para iniciar a busca pelo usuário."
            label="infoButton"
          >
            <D.SearchUserButton
              disabled={uId ? false : true}
              blue={true}
              type="submit"
            >
              {"Adicionar administrador"}
            </D.SearchUserButton>
          </TooltipEnvolveElement>
        </S.FormDivider>
      </S.ManagerForm>
    </Container>
    </>
  );
};

export default EditClubAdmin;
