import React, { useState } from 'react';

import { Modal } from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import * as S from './styles';
import * as D from '../../../DesignSystem/styles';

export default function ModalTemplate({
  isModalOpen,
  handleCloseModal,
  data,
  setData,
  title,
  placeholder,
}) {
  const [inputData, setInputData] = useState('');

  const addData = (e) => {
    e.preventDefault();
    data ? setData([...data, inputData]) : setData([inputData]);
    setInputData('');
  };

  const deleteItem = (item) => {
    const newData = data.filter((e) => e !== item);
    setData(newData);
  };

  return (
    <Modal
      onClose={handleCloseModal}
      open={isModalOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.ModalBox>
        <S.Header>
          <D.TriiboH2>{title}</D.TriiboH2>
          <S.CloseModalButton onClick={handleCloseModal}>
            <IoIosClose size={40} />
          </S.CloseModalButton>
        </S.Header>

        <S.InsertInfo onSubmit={(e) => addData(e)}>
          <S.TextFild
            placeholder={placeholder}
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
          />
          <S.addButton type="submit">
            <D.TriiboH5>Adicionar</D.TriiboH5>
          </S.addButton>
        </S.InsertInfo>

        {data && (
          <S.ListInfos>
            {data.map((item, index) => (
              <S.Item key={index}>
                <D.TriiboH5>
                  <b>{item}</b>
                </D.TriiboH5>
                <S.DeleteItem onClick={() => deleteItem(item)}>
                  <IoIosClose size={40} />
                </S.DeleteItem>
              </S.Item>
            ))}
          </S.ListInfos>
        )}
      </S.ModalBox>
    </Modal>
  );
}

