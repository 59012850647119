export default function reducer(
  state = {
    loading: false,
    error: null,
    success: false,
  },
  action
) {
  switch (action.type) {
    case "CAMPAING_TRIIBO_PENDING": {
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    }

    case "CAMPAING_TRIIBO_FULFILLED": {
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
    }

    case "CAMPAING_TRIIBO_REJECTED": {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}
