import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;

  .inputFile {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 3rem;
  width: 100%;
`;

export const AddCarousel = styled.div`
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const ListCarousels = styled.div`
  width: 100%;
`;

export const FormElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const InputContainer = styled.span`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const InputCounter = styled.span`
  position: absolute;
  right: 1rem;
  align-self: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  color: rgb(112, 112, 112);
`;

export const RadioSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const Radio = styled.div``;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const InputRadio = styled.input.attrs((props) => ({
  type: "radio",
}))`
  margin-right: 1rem;
`;

export const SelectDateContainer = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #afafaf;
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
`;

export const InputDate = styled.input`
  border: none;
  outline: none;
  height: 100%;
  font-size: 14px;
  color: #383838;
  flex: 1;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #afafaf;
  border-radius: 10px;
  width: 100%;
  gap: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

export const ListBannersContainer = styled.div`
  width: 100%;
`;

export const OptionImgContainer = styled.span`
  background: #be0f12;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
