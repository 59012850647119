import dispatcher from 'js/core/dispatcher';
import { store } from 'js/core/configureStore';
import { setUserInfo_v1_creation } from 'js/library/utils/API/setUserInfo_v1.js';
import { CreateBaseUser } from 'js/library/utils/API/Login/apiCreateBaseUser';
import { LoginHistory } from 'js/library/utils/API/api_loginHistory';

export const signInAction = (dispatch, cpf, aliasname, name, email) => {
  const cellPhone = store.getState().phoneInputModel.cellPhone;
  const currentDate = new Date().getTime();

  CreateBaseUser(cellPhone).then((response) => {
    const userInfoCreation = {
      updateDate: currentDate,
      syncType: 'merge',
      aliasName: aliasname,
      name,
      uId: response.uId,
      documentList: [
        {
          type: 'cpf',
          value: cpf,
        },
      ],
      authenticationMethodList: [
        {
          type: 'SMS - Triibo',
          cellPhone: cellPhone,
          enabled: true,
          verified: true,
          dateVerified: currentDate,
          lastlogin: currentDate,
        },
      ],
      contactList: [
        {
          type: 'cellPhone',
          value: cellPhone,
          verified: true,
        },
        {
          type: 'email',
          value: email,
        },
      ],
    };

    // tageamento de login
    const objHistoricoLogin = {
      tag: 'FIRST_LOGIN_WEBADM',
      uid: response.uId,
    };

    return dispatcher(
      dispatch,
      'SIGN_IN',
      setUserInfo_v1_creation(
        response.uId,
        ['setUserInfo'],
        userInfoCreation,
        false
      ),
      LoginHistory(objHistoricoLogin)
    );
  });
};
