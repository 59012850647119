import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1.js';
import {
  userReadCPF_v3,
  userReadCellphone_v3,
} from 'js/library/utils/API/apiUserRead';

export class HeaderServices {
  getUserWallet(uId, triiboId, setWalletData, setRedirect, setStatus) {
    return getWallet_v1(uId, triiboId).then((walletReceived) => {
      if (walletReceived != null) {
        let activeCoupons = [];
        let inactiveCoupons = [];

        if (walletReceived.coupons !== null) {
          Object.keys(walletReceived.coupons).map(function (item, i) {
            let element = Object.keys(walletReceived.coupons)[i];
            walletReceived.coupons[item].key = element;

            if (
              walletReceived.coupons[item].state === 'ongoing' ||
              walletReceived.coupons[item].status === 'enviado'
            ) {
              return activeCoupons.push(walletReceived.coupons[item]);
            } else {
              return inactiveCoupons.push(walletReceived.coupons[item]);
            }
          });
        }

        setWalletData({
          activeCoupons,
          inactiveCoupons,
          totalPoints: walletReceived.totalPoints,
        });
      }

      setRedirect(true);

      setStatus({
        openCheck: false,
        openModal: false,
        loading: false,
      });
    });
  }

  getUser({
    setUserData,
    searchInputText,
    searchType,
    userTypeInputOptions,
    setRedirect,
    setWalletData,
    setStatus,
    setIsUserRegister,
  }) {
    if (!setUserData || !setStatus || !setRedirect || !setWalletData)
      throw new Error(
        '[HeaderServices]: setUserData, setStatus, setRedirect and setWalletData function is required!'
      );

    if (!searchInputText || !searchType || !userTypeInputOptions)
      throw new Error(
        '[HeaderServices]: searchInputText, searchType and userTypeInputOptions params are required!'
      );

    let info = searchInputText;

    info = info
      .replace(/[-]/gi, '')
      .replace(/[.]/gi, '')
      .replace(/[(]/gi, '')
      .replace(/[)]/gi, '')
      .replace(/[\s]/gi, '');

    setStatus({ loading: true });

    if (searchType === 'phone') {
      if (info.substring(0, 3) !== '+55') {
        info = '+55' + info;
      }

      userReadCellphone_v3(info)
        .then((result) => {
          if (result.userInfo !== false) {
            result.userInfo =
              Object.keys(result.userInfo).length === 1 &&
              (result.userInfo.uId !== undefined ||
                result.userInfo.uId !== null)
                ? null
                : result.userInfo;

            setUserData({
              userv3: result.userInfo,
              partnerList: result.partnerList,
            });

            result.userInfo ?? setRedirect(true);

            if (result.userInfo !== false) {
              this.getUserWallet(
                result.userInfo?.uId,
                result.userInfo?.triiboId,
                setWalletData,
                setRedirect,
                setStatus
              );
              setIsUserRegister(false);
            }
          } else {
            setIsUserRegister(true);
            setStatus({
              openModal: true,
              loading: false,
            });
          }
        })
        .catch(() => {
          setStatus({
            snackMessage: 'Erro ao buscar usuário.',
            openCheck: true,
            loading: false,
          });
        });
    } else {
      userReadCPF_v3(
        info,
        userTypeInputOptions.mapfreCheck,
        userTypeInputOptions.cofryCheck
      )
        .then((result) => {
          const setResult = (userResult) => {
            if (userResult !== false) {
              setUserData({
                userv3: userResult.userInfo,
                partnerList: userResult.partnerList,
              });

              !result.userInfo ?? setRedirect(true);

              if (userResult.userInfo !== null) {
                this.getUserWallet(
                  userResult.userInfo.uId && userResult.userInfo.uId,
                  setWalletData,
                  setRedirect,
                  setStatus
                );
              }
            } else {
              setStatus({
                openModal: true,
                loading: false,
              });
            }
          };

          if (Array.isArray(result.userInfo)) {
            result.userInfo.forEach(setResult);
          } else {
            setResult(result);
          }
        })
        .catch((error) => {
          // console.log("ERROR:", error);

          setStatus({
            snackMessage: 'Erro ao buscar usuário.',
            openCheck: true,
            loading: false,
          });
        });
    }
  }
}
