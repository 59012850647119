import React, { useState, createContext, useContext } from 'react';

export const EstablishmentContext = createContext();

export default function EstablishmentProvider({ children }) {
    // charts info config
    const [pieChartInfo, setPieChartInfo] = useState([
        { title: 'Cupons Consumidos', value: 'rep.portal_estabelecimento' },
    ]);
    const [firstBarChartInfo, setFirstBarChartInfo] = useState([
        { title: 'Cupons Consumidos', value: 'rep.portal_estabelecimento' },
    ]);
    const [secondBarChartInfo, setSecondBarChartInfo] = useState([
        { title: 'Cupons Consumidos', value: 'rep.portal_estabelecimento' },
    ]);
    const [establishmentData, setEstablishmentData] = useState({});
    const [selectedModalType, setSelectedModalType] = useState('');

    return (
        <EstablishmentContext.Provider
            value={{
                pieChartInfo,
                setPieChartInfo,
                firstBarChartInfo,
                setFirstBarChartInfo,
                secondBarChartInfo,
                setSecondBarChartInfo,
                selectedModalType,
                setSelectedModalType,
                establishmentData,
                setEstablishmentData,
            }}>
            {children}
        </EstablishmentContext.Provider>
    );
}

export const useEstablishment = () => {
    const { establishmentData, setEstablishmentData } =
    useContext(EstablishmentContext);
    return { establishmentData, setEstablishmentData };
};

export const useCharts = () => {
    const {
        pieChartInfo,
        setPieChartInfo,
        firstBarChartInfo,
        setFirstBarChartInfo,
        secondBarChartInfo,
        setSecondBarChartInfo,
        selectedModalType,
        setSelectedModalType,
    } = useContext(EstablishmentContext);
    return {
        pieChartInfo,
        setPieChartInfo,
        firstBarChartInfo,
        setFirstBarChartInfo,
        secondBarChartInfo,
        setSecondBarChartInfo,
        selectedModalType,
        setSelectedModalType,
    };
};
