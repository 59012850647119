import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { store } from 'js/core/configureStore';

import { cloneDeep } from 'lodash';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Autocomplete,
  TextField,
  Box,
} from '@mui/material';
import { getOrgById } from 'js/library/utils/API/Org/apiGetOrgById';

function sortByClosestKey(arrayOfObjects, key, targetString) {
  return arrayOfObjects.sort((a, b) => {
    const aDistance = Math.abs(a[key]?.localeCompare(targetString));
    const bDistance = Math.abs(b[key]?.localeCompare(targetString));
    return aDistance - bDistance;
  });
}

export default function TypeCommunication({
  formCommunication,
  setFormCommunication,
  selectedOrg,
  setSelectedOrg,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState('');

  const [clubSelected, setClubSelected] = useState({});
  const [orgsData, setOrgsData] = useState([]);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  let isSuperAdmin = useSelector((state) => state.authModel.superUser);

  useEffect(() => {
    if (formCommunication.sender['type'] === 'establishment') {
      setIsLoading(true);
      // if (input.length > 0) {
      apiListEstablishment(input, 0, 10).then((response) => {
        setIsLoading(false);
        setOptions(sortByClosestKey(response, 'nome', input));
      });
      // }
    }
    if (formCommunication.sender['type'] === 'club') {
      setIsLoading(true);
      // if (input.length > 0) {
      getSeuClubList(store.getState().codeInputModel.userInfo.id).then((response) => {
        setIsLoading(false);
        setOptions(sortByClosestKey(response, 'name', input));
      });
      // }
    }
  }, [formCommunication.sender.type]);

  let establishmentTimeout;
  useEffect(() => {
    setIsLoading(true);
    if (formCommunication.sender['type'] === 'club') {
      establishmentTimeout = setTimeout(() => {
        getSeuClubList(store.getState().codeInputModel.userInfo.id).then((response) => {
          setIsLoading(false);
          setOptions(sortByClosestKey(response, 'name', input));
        });
      }, 1200);
    }
    if (formCommunication.sender['type'] === 'establishment') {
      establishmentTimeout = setTimeout(() => {
        apiListEstablishment(input, 0, 10).then((response) => {
          setIsLoading(false);
          setOptions(sortByClosestKey(response, 'nome', input));
        });
      }, 1200);
    }
    return () => {
      clearTimeout(establishmentTimeout);
    };
  }, [input]);

  useEffect(() => {
    if (
      formCommunication.sender['type'] === 'club' &&
      clubSelected.id &&
      clubSelected.orgs.length > 0
    ) {
      const fetchData = async () => {
        try {
          const responses = await Promise.all(clubSelected.orgs.map((orgId) => getOrgById(orgId)));

          const orgsInfo = responses.map((response) => response.orgInfo);

          // Atualiza o estado com o array de informações das orgs
          setOrgsData(orgsInfo);
        } catch (error) {
          console.error('Erro ao buscar informações das organizações:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [clubSelected]);

  useEffect(() => {
    // Quando orgsData mudar, muda a primeira opção selecionada do autocomplete
    if (orgsData.length > 0) {
      setSelectedOrg(orgsData[0]);
    } else {
      setSelectedOrg(null);
    }
  }, [orgsData]);

  const handleChangeTypeSender = (event) => {
    let cloneForm = cloneDeep(formCommunication);
    cloneForm.sender['type'] = event.target.value;
    cloneForm.orgs = [];
    setFormCommunication(cloneForm);
    setIsLoading(false);
  };

  // limpa o state de orgsData e seta o loading pra false quando mudar para estabelecimento
  useEffect(() => {
    if (formCommunication.sender['type'] === 'establishment') {
      setOrgsData([]);
      setLoading(false);
    }
  }, [formCommunication]);

  // label Remetente: limpa o valor do nome do estabelecimento ou clube selecionado sempre que o sender.type mudar
  useEffect(() => {
    formCommunication.sender['name'] = '';
  }, [formCommunication.sender.type]);

  if (!isSuperAdmin) {
    return <></>;
  }
  return (
    <S.Container>
      <D.TriiboH4>
        <b>Informações da origem</b>
      </D.TriiboH4>
      <div>
        <D.TriiboH4>Escolha o tipo</D.TriiboH4>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            checked={formCommunication.sender['type'] === 'establishment' ? true : false}
            onClick={handleChangeTypeSender}
            value="establishment"
            control={
              <Radio
                sx={{
                  '&.Mui-checked': {
                    color: '#6E3296',
                  },
                }}
              />
            }
            label="Estabelecimento"
          />
          <FormControlLabel
            value="club"
            checked={formCommunication.sender['type'] === 'club' ? true : false}
            onClick={handleChangeTypeSender}
            control={
              <Radio
                sx={{
                  '&.Mui-checked': {
                    color: '#6E3296',
                  },
                }}
              />
            }
            label="Clube"
          />
        </RadioGroup>
      </div>

      <S.InputGroupContainer style={{ width: '100%' }}>
        <S.OptionsDisplayContainer isOpen={isFocused}>
          {options.map((element, index) => (
            <S.OptionSelectable
              onClick={(e) => {
                e.preventDefault();

                setClubSelected(element);
                setLoading(true);

                if (element) {
                  let cloneForm = cloneDeep(formCommunication);
                  cloneForm.sender['id'] = element.id;

                  if (formCommunication.sender['type'] === 'establishment') {
                    cloneForm.sender['id'] = element.id;
                    cloneForm.sender['name'] = element.nome;
                    cloneForm.sender['logo'] =
                      'estabelecimento/' + (element.fotoThumb ? element.fotoThumb : '');
                  } else if (
                    formCommunication.sender['type'] === 'club' ||
                    formCommunication.sender['type'] === 'org'
                  ) {
                    cloneForm.sender['name'] = element.name;
                    cloneForm.sender['logo'] = 'seuClube/' + (element.logo ? element.logo : '');
                  }

                  setFormCommunication(cloneForm);
                }
              }}
              key={`selectable-option-${index}`}
            >
              {formCommunication.sender['type'] === 'establishment' ? element.nome : element.name}
            </S.OptionSelectable>
          ))}
        </S.OptionsDisplayContainer>
        <D.TriiboH4>Remetente: {formCommunication.sender.name}</D.TriiboH4>
        <S.InputContainer>
          <S.Input
            value={input}
            placeholder={'Buscar remetente de postagem'}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
          <S.SpinnerContainer isLoading={isLoading}>
            <CircularProgress />
          </S.SpinnerContainer>
        </S.InputContainer>

        {orgsData.length > 0 ? (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={orgsData}
            disabled={orgsData.length === 1}
            getOptionLabel={(option) => option.name}
            sx={{
              width: '100%',
              maxWidth: '250px',
            }}
            blurOnSelect
            noOptionsText={'Nenhum resultado encontrado...'}
            value={selectedOrg} // Define o valor do Autocomplete
            onChange={(event, newValue) => {
              setSelectedOrg(newValue); // Atualiza o estado com a organização selecionada
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione uma organização:"
                onClick={() => orgsData.length > 1 && setOptionsOpen(true)}
                onFocus={() => orgsData.length > 1 && setOptionsOpen(true)}
                onBlur={() => setOptionsOpen(false)}
              />
            )}
            ListboxProps={{ style: { maxHeight: '200px' } }}
            open={optionsOpen}
            onClose={() => setOptionsOpen(false)}
            renderOption={(props, option) => (
              <li {...props} key={`${option.clubeId}-${option.name}`}>
                {option.name}
              </li>
            )}
          />
        ) : (
          loading && <CircularProgress size={25} />
        )}
      </S.InputGroupContainer>
    </S.Container>
  );
}
