import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import searchIcon from 'styles/assets/SearchUser/search.svg';
import accessKeyIcon from 'styles/assets/SearchUser/accessKey.svg';
import { maskCEP, randomizeNumbersString } from 'js/library/utils/helpers';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import * as S from './styles';

export default function RenderInputDynamic({
  fieldInfos,
  inputValue,
  setInputValue,
  handleSubmit,
}) {
  const [cellphoneInput, setCellphoneInput] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputValue('');
    setCurrentDDD(valuesArr[0]);
    setCurrentCountry(e.target.value);
  };

  const handleInputChange = (event, input) => {
    let value = event.target.value;

    value = value.replace(/[^\d]/g, '');

    if (input.fieldName === 'cellPhone') {
      return setCellphoneInput(value);
    }

    if (input.fieldName === 'cpf') {
      return setInputValue(value.replace(/\D/g, ''));
    }

    if (input.fieldName === 'cep') {
      return setInputValue(maskCEP(value));
    }

    return setInputValue(value);
  };

  const maskMapping = {
    cellPhone: '(99) 99999-9999',
    cpf: '999.999.999-99',
    cnpj: '99.999.999/9999-99',
    data: '99/99/9999',
  };

  const renderInput = () => {
    const mask = (fieldInfos && maskMapping[fieldInfos.inputMask]) || fieldInfos.inputMask;

    switch (fieldInfos.inputType) {
      case 'text':
      case 'email':
      case 'date':
        return fieldInfos.fieldType === 'cellPhone' ? (
          <S.BoxInput>
            <S.Adornment>
              <select
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  flex: '1',
                  height: '100%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '17px',
                  color: '#383838d6',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                value={currentCountry}
                onChange={(e) => handleCountryChange(e)}
              >
                {phoneMaskArray.map((country, index) => (
                  <option
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.emoji} {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
            </S.Adornment>

            <S.SearchInput
              mask={mask}
              value={cellphoneInput}
              placeholder={randomizeNumbersString(mask)}
              onChange={(e) => handleInputChange(e, fieldInfos)}
              id={fieldInfos.fieldName}
              name={fieldInfos.fieldName}
            />

            <S.SearchIcon>
              <Button type="submit">
                <img src={searchIcon} alt="" />
              </Button>
            </S.SearchIcon>
          </S.BoxInput>
        ) : (
          <S.BoxInput>
            <S.KeyType>
              <img src={accessKeyIcon} alt="" />
              {fieldInfos.label}:
            </S.KeyType>
            <S.SearchInput
              mask={mask}
              value={inputValue}
              placeholder={randomizeNumbersString(mask)}
              onChange={(e) => handleInputChange(e, fieldInfos)}
              id={fieldInfos.fieldName}
              name={fieldInfos.fieldName}
            />

            <S.SearchIcon>
              <Button type="submit">
                <img src={searchIcon} alt="" />
              </Button>
            </S.SearchIcon>
          </S.BoxInput>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    if (fieldInfos.fieldType === 'cellPhone') {
      setInputValue(currentDDD + cellphoneInput.replace(/\D/g, ''));
    } else {
      setInputValue('');
      setCellphoneInput('');
    }
  }, [fieldInfos, currentDDD, cellphoneInput]);

  return <S.Form onSubmit={handleSubmit}>{renderInput()}</S.Form>;
}

