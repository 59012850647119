import React from 'react';
import { cloneDeep } from 'lodash';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

export default function ButtonText({ form, setForm }) {
  const handleCurrentTextChange = (e) => {
    e.preventDefault();
    const newObj = cloneDeep(form);
    newObj.action.label = e.target.value;
    setForm(newObj);
  };

  return form?.action ? (
    <S.Container>
      <div>
        <D.TriiboH4>
          <b>Texto do botão</b>
        </D.TriiboH4>
        <D.TriiboH5>Defina a ação que será realizada ao clicar no Botão de Ação.</D.TriiboH5>
      </div>

      <S.CustomInputArea>
        <D.TriiboTextField
          rows={1}
          value={
            form.action.label !== null &&
            form.action.label !== undefined &&
            form.action.label !== ''
              ? form.action.label
              : 'Ver mais'
          }
          maxLength="20"
          onChange={(e) => handleCurrentTextChange(e)}
        />
      </S.CustomInputArea>
    </S.Container>
  ) : null;
}
