export default function reducer(state = {
    nameNotification: null,
    groupId : null,
    total: null,
    type: null,
    title: null,
    mensagem: null,
    url: null
  }, action) {
  
    switch (action.type) {
  
      case "SEND_NOTIFICATION_PENDING":
        {
          return {
            ...state,
            nameNotification: null,
            groupId : null,
            total: null,
            type: null,
            title: null,
            mensagem: null,
            url: null
          };
        }
  
      case "SEND_NOTIFICATION_FULFILLED":
        {
          return {
            ...state,
            nameNotification: action.payload.nameNotification,
            groupId :  action.payload.groupId,
            total: action.payload.total,
            type: action.payload.type,
            title: action.payload.title,
            mensagem: action.payload.mensagem,
            url: action.payload.url
          };
        }
  
      case "SEND_NOTIFICATION_REJECTED":
        {
          return {
            ...state,
            nameNotification: null,
            groupId : null,
            total: null,
            type: null,
            title: null,
            mensagem: null,
            url: null
          };
        }
  
      default:
        return state;
    }
}