import React from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import searchIcon from 'styles/assets/SearchUser/search.svg';
import editIcon from 'styles/assets/communications/edit.svg';
import {
  Box,
  CircularProgress,
  Pagination,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { AreaHeader, GoBack } from '../../styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { ToastContainer } from 'react-toastify';
import { convertToDateDefault } from 'js/library/utils/helpers';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';

function View({
  setInputValue,
  inputSearch,
  loading,
  landingPageList,
  goToLPSignUp,
  setlandingPages,
  partnerId,
  count,
  page,
  handleChangePage,
  handleCopyUrl,
  openSnackbar,
  handleCloseSnackbar,
}) {
  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          rowGap: '60px',
          flexDirection: 'column',
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container style={{ marginTop: '2rem' }}>
        <D.TriiboH1>Lista de Landing Pages</D.TriiboH1>
        <S.BoxSearch>
          <S.InputBox>
            <S.Input
              placeholder="Pesquise por uma landing page..."
              value={inputSearch}
              onChange={(e) => setInputValue(e)}
            />
            <S.SearchIconButton type="button" onClick={() => setlandingPages(partnerId)}>
              <img src={searchIcon} alt="" />
            </S.SearchIconButton>
          </S.InputBox>
        </S.BoxSearch>
        <Box display={'flex'} justifyContent={'right'} width={'100%'}>
          <DashedButton width={'200px'} onClick={goToLPSignUp}>
            + Adicionar LP
          </DashedButton>
        </Box>

        {loading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="50px" />
          </div>
        ) : landingPageList?.length > 0 ? (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="medium" aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Titulo</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Id da Landing Page</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Data Início</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Data Fim</strong>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {landingPageList?.map((LP) => (
                    <TableRow
                      key={LP.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{LP?.title}</TableCell>
                      <TableCell align="left">{LP?.id}</TableCell>
                      <TableCell align="left">
                        {Array.isArray(LP?.periods)
                          ? convertToDateDefault(LP?.periods[0].startAt || LP?.periods[0].startsAt)
                          : ''}
                      </TableCell>
                      <TableCell align="left">
                        {Array.isArray(LP?.periods?.periods)
                          ? convertToDateDefault(LP?.periods[LP?.periods.length - 1]?.endsAt)
                          : ''}
                      </TableCell>
                      <TableCell align="left">
                        <S.EditButton
                          onClick={() => goToLPSignUp(LP)}
                          style={{ minWidth: '1.8rem' }}
                        >
                          <img src={editIcon} alt="" />
                        </S.EditButton>
                      </TableCell>

                      <TableCell align="left">
                        <DashedButton width={'100px'} onClick={() => handleCopyUrl(LP.id)}>
                          Copiar Link
                        </DashedButton>
                        <Snackbar
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          open={openSnackbar}
                          onClose={handleCloseSnackbar}
                          message="Link copiado!"
                          autoHideDuration={2000}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              sx={{ mt: 3 }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </>
        ) : (
          <div style={{ margin: '0 auto' }}>Nenhuma Landing Page encontrada.</div>
        )}
      </S.Container>
    </div>
  );
}

export default View;
