import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { GetAllNotifications } from 'js/library/utils/API/Communication/apiGetAllNotification';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import InputSearch from 'js/components/TriiboComponents/InputSearch';
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { TableTD, TableTH } from 'js/components/DesignSystem/Table/styles';
import { CircularProgress } from '@mui/material';
import { GetMultipleTemplates } from 'js/library/utils/API/Communication/apiGetMultipleTemplates';
import { convertToDateDefault } from 'js/library/utils/helpers';
import ModalStatus from './ModalStatus';
import { AreaHeader, GoBack } from '../../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

export default function ListCommunications(props) {
  const [notifications, setNotifications] = useState([]);
  const [inputSearchNotification, setInputSearchNotification] = useState('');
  const [status, setStatus] = useState({});
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const history = useHistory();

  const isGestorPage = window.location.href.split('/').includes('admin') ? false : true;

  useEffect(() => {
    GetAllNotifications()
      .then((res) => {
        const templatesIds = res.map((result) => result.templateId);
        const result = res;
        GetMultipleTemplates(templatesIds)
          .then((response) => {
            const newNotificationsArray = response.map((notification) => {
              const statusNotification = result.filter((obj) => obj.templateId === notification.id);
              const sendDate = convertToDateDefault(notification.updateDate);
              return {
                ...notification,
                sendDate,
                statusNotification: statusNotification[0].totalSend,
              };
            });

            newNotificationsArray.sort((a, b) => b.updateDate - a.updateDate);

            setNotifications(newNotificationsArray);
            setLoadingPage(false);
          })
          .catch((err) => {
            // console.log(err);
            setLoadingPage(false);
          });
      })
      .catch(() => {
        setLoadingPage(false);
      });
  }, []);

  const column = [
    { heading: 'Titulo', value: 'title' },
    { heading: 'Data do Envio', value: 'sendDate' },
  ];

  const redirectToPage = () => {
    if (isGestorPage) {
      history.push('/estabelecimento/relacionamento/envio-comunicacoes/' + props.match.params.id);
    } else {
      history.push('/admin/configuracoes/enviaNotificacoes');
    }
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <TableTH>
          <S.AddNotificationButton style={{ marginLeft: 'auto' }} onClick={redirectToPage}>
            <span>+</span> Criar notificação.
          </S.AddNotificationButton>
        </TableTH>
      ),
      childrenComponent: (
        <TableTD style={{ textAlign: 'right' }}>
          <S.StatusButton
            onClick={() => {
              setStatus(childrens.statusNotification);
              setIsModalStatusOpen(true);
            }}
          >
            Status de envio.
          </S.StatusButton>
        </TableTD>
      ),
    };
  };

  const handleChangeInputSearchNotification = (event) => {
    setInputSearchNotification(event.target.value);
  };

  const handleCloseModalStatus = () => {
    setIsModalStatusOpen(false);
  };

  const filteredNotifications = inputSearchNotification
    ? notifications.filter((notification) => {
        return notification.title.toLowerCase().includes(inputSearchNotification.toLowerCase());
      })
    : notifications;

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container>
        <S.Header>
          <D.TriiboH1 style={{ marginTop: '1rem' }}>Histórico de Notificações</D.TriiboH1>
        </S.Header>

        <S.SearchBox>
          <InputSearch
            value={inputSearchNotification}
            onChange={handleChangeInputSearchNotification}
            disabled={false}
            placeholder="Titulo da notificação"
          />
        </S.SearchBox>

        <CustomButtonsTable
          data={filteredNotifications}
          perPage={10}
          column={column}
          componentReceiver={componentReceiver}
        />
        {notifications.length < 1 && (
          <S.AddNotificationButton onClick={redirectToPage}>
            <span>+</span> Criar notificação
          </S.AddNotificationButton>
        )}

        <ModalStatus
          handleCloseModalStatus={handleCloseModalStatus}
          isModalStatusOpen={isModalStatusOpen}
          status={status}
        />
      </S.Container>
    </div>
  );
}

