import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as D from 'js/components/DesignSystem/styles';
import * as S from 'js/components/Establishment/EstablishmentSteps/styles';

import { Box } from '@mui/material';

import InterruptLeaveModal from 'js/components/Establishment/EstablishmentSteps/InterruptLeaveModal';

import StepOne from './EstablishmentSteps/StepOne';

import StepTwo from './EstablishmentSteps/StepTwo';

import StepThree from './EstablishmentSteps/StepThree';

import ProgressBars from 'js/components/Establishment/EstablishmentSteps/ProgressBars';

import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from '../Configurations/CreateClub/RelationshipClub/GoBackHeader';

const EstablishmentCreateNew = (props) => {
  let state = useSelector((state) => state.authModel);
  let cellphone = useSelector((state) => state.phoneInputModel);
  let contactListCodeInput = useSelector((state) => state.codeInputModel);
  let contactListSignIn = useSelector((state) => state.signInModel);
  let contactList = contactListCodeInput.userInfo
    ? contactListCodeInput.userInfo.contactList
    : contactListSignIn.userInfo.contactList;
  let emailFind = contactList ? contactList.find((e) => e.type === 'email') : '';

  const [newEstablishment, setNewEstablishment] = useState({
    id: '',
    idGestor: state.authenticated.email,
    nome: '',
    // keywords: [],
    descricao: '',
    tags: [],
    dataInclusao: Date.now(), // + 31536000000,
    dataAlteracao: Date.now(), // + 31536000000,
    endereco: '',
    lat: 0,
    long: 0,
    complemento: '',
    site: '',
    email: emailFind ? emailFind.value : '',
    contatos: [cellphone.cellPhone],
    fotoThumb: '',
    listaFotos: [],
    funcionamento: [
      {
        ativo: true,
        horarioFim: '18:00',
        horarioInicio: '09:00',
      },
      {
        ativo: true,
        horarioFim: '18:00',
        horarioInicio: '09:00',
      },
      {
        ativo: true,
        horarioFim: '18:00',
        horarioInicio: '09:00',
      },
      {
        ativo: true,
        horarioFim: '18:00',
        horarioInicio: '09:00',
      },
      {
        ativo: true,
        horarioFim: '18:00',
        horarioInicio: '09:00',
      },
      {
        ativo: false,
      },
      {
        ativo: false,
      },
    ],
    // thumbnailFile: [],
    // photoListFile: [],
    // OldThumbnail: [],
    // OldPhotoList: [],
    isPublished: false,
    enableDate: Date.now(), // + 31536000000,
    disableDate: Date.now() + 31556926 * 1000 * 20, //TODO TRATAR DAS DATAS DEPOIS
    contatoComercial: [
      {
        type: 'telefone',
        value: cellphone.cellPhone,
      },
    ],
    contatoTriibo: [],
    horarios: [
      {
        tipo: 'aberto',
        turnos: [
          {
            fim: '18:00',
            inicio: '09:00',
          },
        ],
      },
      {
        tipo: 'aberto',
        turnos: [
          {
            fim: '18:00',
            inicio: '09:00',
          },
        ],
      },
      {
        tipo: 'aberto',
        turnos: [
          {
            fim: '18:00',
            inicio: '09:00',
          },
        ],
      },
      {
        tipo: 'aberto',
        turnos: [
          {
            fim: '18:00',
            inicio: '09:00',
          },
        ],
      },
      {
        tipo: 'aberto',
        turnos: [
          {
            fim: '18:00',
            inicio: '09:00',
          },
        ],
      },
      {
        tipo: 'fechado',
      },
      {
        tipo: 'fechado',
      },
    ],
    redesSociais: [],
    cep: '',
    logradouro: '',
    cidade: '',
    estado: '',
    bairro: '',
    numero: '',
    // messageApproval: null,
    // newStatus: true,
    // signatureId: "",
    // useFullLinks: [],
  });
  let [estThumb, setEstThumb] = useState('');
  let [galleryArr, setGalleryArr] = useState([]);

  let [isBlocking, setIsBlocking] = useState(false);
  //   const [lastLocation, setLastLocation] = useState(location.pathname);
  const [modalVisible, setModalVisible] = useState(false);

  const [currentScreen, setCurrentScreen] = useState(0); // index do array de páginas, define qual que é renderizado no momento
  const [progressOne, setProgressOne] = useState(0);
  const [progressTwo, setProgressTwo] = useState(0);
  const [progressThree, setProgressThree] = useState(0);

  useEffect(() => {
    if (progressOne > 25) {
      setIsBlocking(true);
    }
    if (progressThree === 100) {
      setIsBlocking(false);
    }
  }, [newEstablishment, estThumb, galleryArr, progressOne, progressThree]);

  //os passos estão dentro de um array
  const AllSteps = [
    <StepOne
      formState={newEstablishment}
      setFormState={setNewEstablishment}
      setCurrentScreen={setCurrentScreen}
      cellphone={cellphone}
      progress={progressOne}
      setProgress={setProgressOne}
    />,
    <StepTwo
      formState={newEstablishment}
      setFormState={setNewEstablishment}
      setCurrentScreen={setCurrentScreen}
      progress={progressTwo}
      setProgress={setProgressTwo}
    />,
    <StepThree
      formState={newEstablishment}
      setCurrentScreen={setCurrentScreen}
      progress={progressThree}
      setProgress={setProgressThree}
      estThumb={estThumb}
      setEstThumb={setEstThumb}
      galleryArr={galleryArr}
      setGalleryArr={setGalleryArr}
    />,
  ];

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cadastro Negócio" />
      <Box sx={{ px: { xs: '1.4rem', md: '6rem' } }}>
        <InterruptLeaveModal status={modalVisible} setStatus={setModalVisible} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <D.TriiboH5>Preencha as informações sobre o seu negócio</D.TriiboH5>
        </Box>

        <Box sx={{ marginTop: '2rem' }}>
          <ProgressBars
            progressOne={progressOne}
            progressTwo={progressTwo}
            progressThree={progressThree}
            currentScreen={currentScreen}
          />
        </Box>

        {AllSteps[currentScreen]}

        <S.WarningContainer>
          <D.TriiboH6>
            Os campos marcados com asterisco (*) são de preenchimento obrigatório.
          </D.TriiboH6>
        </S.WarningContainer>

        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.target.reset();
            setIsBlocking(false);
          }}
          style={{ display: 'none' }}
        >
          <Prompt
            when={isBlocking}
            message="Seus dados serão perdidos. Tem certeza que deseja prosseguir?"
          />

          <p>Blocking? {isBlocking ? 'Yes, click a link or the back button' : 'Nope'}</p>

          <p>
            <input
              size="10"
              placeholder="type something to block transitions"
              onChange={(event) => {
                setIsBlocking(event.target.value.length > 0);
              }}
            />
          </p>

          <p>
            <button type="button">Submit to stop blocking</button>
          </p>
        </form>
      </Box>
    </div>
  );
};

export default EstablishmentCreateNew;

