import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const ContentAction = styled.div`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`;

export const EditInfoButton = styled.button`
  background: none;
  border: none;
  color: #0372ff;
  text-decoration: underline;
  cursor: pointer;
`;

export const ContentActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const TextCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 1rem;
  /* max-height: 407px; */
  /* min-height: 200px; */
  height: 100%;
  flex: 1;
`;

export const TitleCountContainer = styled(TextCountContainer)`
  height: 20px;
`;

export const TextArea = styled.textarea`
  font-family: "Source Sans Pro", sans-serif;
  width: 50%;
  /* font-size: 15px; */
  border-radius: 10px;
  border: 1px solid #757575;
  resize: none;
  outline: none;
  flex: 1;
  height: 40px;
  padding: 0.5rem 1rem;

  ::placeholder {
    font-style: italic;
    color: #707070;
  }
`;

export const CustomInputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
