import React, { useEffect, useState } from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from '../styles';
import { IoIosWarning } from 'react-icons/io';
import { FormControlLabel, Switch, Typography } from '@mui/material';

const FormStepTwo = ({
  checked,
  setChecked,
  formState,
  newFormState,
  finalFormState,
  setFinalFormState,
  handleClickBtnEdit,
}) => {
  const [oldDataAdress] = useState({ ...finalFormState });

  const handleChange = (event) => {
    if (checked) {
      setFinalFormState({
        ...finalFormState,
        endereco: 'Brasil',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
      });
    } else {
      setFinalFormState({
        ...finalFormState,
        endereco: oldDataAdress.endereco,
        logradouro: oldDataAdress.logradouro,
        numero: oldDataAdress.numero,
        bairro: oldDataAdress.bairro,
        cidade: oldDataAdress.cidade,
        estado: oldDataAdress.estado,
        cep: oldDataAdress.cep,
      });
    }
    setChecked(event.target.checked);
  };

  const [enderecoModel, setEnderecoModel] = useState('');
  const [auxiliarAddress, setAuxiliarAddress] = useState(false);

  useEffect(() => {
    setEnderecoModel(finalFormState.endereco);
    const addressFields = ['bairro', 'cep', 'cidade', 'estado', 'logradouro'];
    const hasAllKeys = addressFields.every((field) => finalFormState[field]);

    if (finalFormState.endereco !== 'Brasil') {
      setChecked(true);
      if (!hasAllKeys) {
        setAuxiliarAddress(true);
      }
    }
  }, []);

  return (
    <S.CardContainer>
      <FormControlLabel
        sx={{ color: '#ffff' }}
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Possui endereço*"
      />
      {checked ? (
        <>
          {auxiliarAddress && (
            <div>
              <Typography
                variant="h6"
                color="primary"
                style={{ color: 'white', fontSize: 15, fontWeight: 900 }}
              >
                Endereço mal formatado. Use o modelo abaixo para corrigir
              </Typography>
              <Typography
                variant="h6"
                style={{ backgroundColor: 'white', fontSize: 15, padding: 5 }}
              >
                {enderecoModel}
              </Typography>
            </div>
          )}
          <S.FormElement>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <S.FormLabel>CEP</S.FormLabel>
              {newFormState.cep !== formState.cep && (
                <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
              )}
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <D.TriiboTextField disabled rows={1} placeholder={'CEP'} value={finalFormState.cep} />
              <S.MoveIconMobile>
                <S.Icon
                  onClick={(e) => handleClickBtnEdit(e, 'CEP')}
                  size={24}
                  style={{ marginLeft: '0.5rem' }}
                />
              </S.MoveIconMobile>
            </div>
          </S.FormElement>
          <S.FormElement>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <S.FormLabel>Rua</S.FormLabel>
              {newFormState.logradouro !== formState.logradouro && (
                <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
              )}
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <D.TriiboTextField
                disabled
                rows={1}
                placeholder={'Rua'}
                value={finalFormState.logradouro}
              />
              <S.MoveIconMobile>
                <S.Icon
                  onClick={(e) => handleClickBtnEdit(e, 'Rua')}
                  size={24}
                  style={{ marginLeft: '0.5rem' }}
                />
              </S.MoveIconMobile>
            </div>
          </S.FormElement>
          <S.Logradouro>
            <S.FormElement>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <S.FormLabel>n</S.FormLabel>
                {newFormState.numero !== formState.numero && (
                  <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
                )}
              </div>
              <S.LogradouroNumber>
                <D.TriiboTextField
                  disabled
                  rows={1}
                  placeholder={'n'}
                  value={finalFormState.numero}
                />
                <S.MoveIconMobile right={true}>
                  <S.Icon onClick={(e) => handleClickBtnEdit(e, 'Numero')} size={24} />
                </S.MoveIconMobile>
              </S.LogradouroNumber>
            </S.FormElement>
            <S.FormElement>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <S.FormLabel>Complemento</S.FormLabel>
                {newFormState.complemento !== formState.complemento && (
                  <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <D.TriiboTextField
                  disabled
                  rows={1}
                  placeholder={'Complemento'}
                  value={finalFormState.complemento}
                />
                <S.MoveIconMobile>
                  <S.Icon onClick={(e) => handleClickBtnEdit(e, 'Complemento')} size={24} />
                </S.MoveIconMobile>
              </div>
            </S.FormElement>
          </S.Logradouro>
          <S.FormElement>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <S.FormLabel>Bairro</S.FormLabel>
              {newFormState.bairro !== formState.bairro && (
                <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
              )}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <D.TriiboTextField
                disabled
                rows={1}
                placeholder={'Bairro'}
                value={finalFormState.bairro}
              />
              <S.MoveIconMobile>
                <S.Icon onClick={(e) => handleClickBtnEdit(e, 'Bairro')} size={24} />
              </S.MoveIconMobile>
            </div>
          </S.FormElement>
          <S.Logradouro>
            <S.FormElement>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <S.FormLabel>Cidade</S.FormLabel>
                {newFormState.cidade !== formState.cidade && (
                  <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
                )}
              </div>
              <S.LogradouroNumber>
                <D.TriiboTextField
                  disabled
                  rows={1}
                  placeholder={'Cidade'}
                  value={finalFormState.cidade}
                />
                <S.MoveIconMobile right={true}>
                  <S.Icon onClick={(e) => handleClickBtnEdit(e, 'Cidade')} size={24} />
                </S.MoveIconMobile>
              </S.LogradouroNumber>
            </S.FormElement>
            <S.FormElement>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <S.FormLabel>UF</S.FormLabel>
                {newFormState.estado !== formState.estado && (
                  <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <D.TriiboTextField
                  disabled
                  rows={1}
                  placeholder={'UF'}
                  value={finalFormState.estado}
                />
                <S.MoveIconMobile uf={true}>
                  <S.Icon onClick={(e) => handleClickBtnEdit(e, 'UF')} size={24} />
                </S.MoveIconMobile>
              </div>
            </S.FormElement>
          </S.Logradouro>
        </>
      ) : (
        <D.TriiboTextField disabled rows={1} value={finalFormState.endereco} />
      )}
    </S.CardContainer>
  );
};

export default FormStepTwo;
