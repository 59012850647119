import axios from 'axios';

import authToken from './authToken';
import cfac22 from '../cfac22';

export function CreateCard(uId, sender, itemId, latitude, longitude, type, receiver, status) {
  return new Promise((resolve, reject) => {
    const currentDate = new Date().getTime();

    authToken(uId).then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/order',
          {
            itemId,
            receiver,
            sender,
            type,
            requestDate: currentDate,
            status,
            requestLat: latitude,
            requestLon: longitude,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
