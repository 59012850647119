import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTemplate, useElementQrcode } from '../Context/Marketing';
import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22';

const TabQrcode = ({ classes, tabIndex, establishmentId }) => {
  const { selectedTemplate, storeData, establishmentData } = useTemplate();
  const { elementQrcode, setElementQrcode, qrcodeLink, setQrcodeLink, setLoadingLink } =
    useElementQrcode();

  const [key, setKey] = useState('');
  const [actionType, setActionType] = useState('');

  function getLink(type = null, id = null) {
    const dynamicLinkKey = cfac22('DYNAMIC_LINK_KEY');
    const dynamicLinkApi = `${cfac22('DYNAMIC_LINK_API')}${dynamicLinkKey}`;
    const dynamicLinkDomain = cfac22('DYNAMIC_LINK_DOMAIN');
    const androidPackageName = cfac22('ANDROID_PACKAGE_NAME');
    const iosPackageName = cfac22('IOS_PACKAGE_NAME');
    const iosStoreId = cfac22('IOS_STORE_ID');
    const baseLink = cfac22('BASE_LINK');

    const params = `?${type || actionType}=${id || key}`;
    const finalLink = `${baseLink}${params}`;

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain: dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName: androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    setLoadingLink(true);

    axios
      .post(dynamicLinkApi, linkRequest)
      .then((result) => {
        setLoadingLink(false);
        setQrcodeLink(result.data.shortLink);
      })
      .catch((error) => {
        setLoadingLink(false);
        console.log('Error api gerador de link: ', error);
      });
  }

  function handleSelectStore(event) {
    const { value } = event.target;

    setKey(value);
  }

  // AO SELECIONAR A PROMOÇÃO GERA O LINK AUTOMATICO
  useEffect(() => {
    if (key !== '') {
      getLink();
    }
  }, [key]);

  useEffect(() => {
    const filterElement =
      Object.values(selectedTemplate).length > 0 &&
      selectedTemplate?.elements.filter((element) => element.entity === 'qrCode');

    const qrCodeAction = selectedTemplate.elements[tabIndex].qrCodeAction;
    const elementType = selectedTemplate.elements[tabIndex].type;

    setElementQrcode(filterElement[0]);
    setActionType(qrCodeAction);

    if (qrCodeAction === 'place') {
      getLink(qrCodeAction, establishmentId);
    }

    if (elementType === 'free') {
      setQrcodeLink(qrCodeAction);
    }
  }, [selectedTemplate]);

  function handleActionQrCodeEstablishment(event) {
    const { value } = event.target;

    if (value === 'place') {
      setActionType(value);
      getLink(value, establishmentId);
      return;
    }

    const linkAction = establishmentData?.links.reduce((acc, item) => {
      if (item.title === value) {
        return (acc = item.link);
      }
    }, '');

    setQrcodeLink(linkAction);
    setActionType(value);
  }

  return (
    <div>
      {elementQrcode === undefined && (
        <Typography variant="subtitle2" className={classes.elementTypeTitle}>
          Escolha um Template
        </Typography>
      )}
      {elementQrcode?.type === 'establishment' && (
        <Box className={classes.boxElement}>
          <Typography variant="subtitle2" className={classes.elementTypeTitle}>
            Tipo estabelecimento
          </Typography>

          <FormControl className={classes.inputElementText}>
            <InputLabel id="qrcode-action-label">Ação do QR Code</InputLabel>
            <Select
              disabled={elementQrcode?.blockQrCodeAction || establishmentData.links === null}
              labelId="qrcode-action-label"
              id="qrcode-action"
              value={actionType}
              onChange={(event) => {
                handleActionQrCodeEstablishment(event);
              }}
            >
              <MenuItem value="place">Abrir Estabelecimento</MenuItem>

              {establishmentData.links &&
                establishmentData.links.map((item, index) => (
                  <MenuItem key={index} value={item.title}>
                    {item.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {elementQrcode?.type === 'store' &&
        (storeData.length === 0 ? (
          <Box className={classes.boxElement}>
            <Typography variant="subtitle2" className={classes.elementTypeTitle}>
              Estabelecimento ainda não possui promoções ativas.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.boxElement}>
            <Typography variant="subtitle2" className={classes.elementTypeTitle}>
              Tipo promoção
            </Typography>

            <FormControl className={classes.inputElementText}>
              <InputLabel id="store-select-label">Ação do QR Code</InputLabel>
              <Select
                disabled
                labelId="store-select"
                id="store-select"
                value={actionType}
                onChange={(e) => {
                  setActionType(e.target.value);
                }}
              >
                <MenuItem value="promo">Abrir promoção</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.inputElementText}>
              <InputLabel id="store-select-label">Selecione a Promoção</InputLabel>
              <Select
                labelId="store-select-label"
                id="store-select"
                value={storeData.title}
                onChange={(e) => {
                  handleSelectStore(e);
                }}
              >
                {storeData.map(({ key, title }) => (
                  <MenuItem key={key} value={key}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ))}
      {elementQrcode?.type === 'free' && (
        <Box className={classes.boxElement}>
          <Typography variant="subtitle2" className={classes.elementTypeTitle}>
            Tipo livre
          </Typography>

          <TextField
            type="text"
            label="Link do qrCode"
            placeholder="https://triibo.com.br"
            disabled={selectedTemplate.elements[tabIndex].blockQrCodeAction}
            value={qrcodeLink}
            onChange={(e) => setQrcodeLink(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

export default TabQrcode;

