import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import * as S from '../styles';

import { GetKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';

import { BiCircle } from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IoIosAdd } from 'react-icons/io';

import { toast } from 'react-toastify';
import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';
import { removeDuplicateUsingFilter } from 'js/library/utils/helpers';
import { setUserInfo_v1 } from 'js/library/utils/API/setUserInfo_v1';
import { getDataRedshift } from 'js/library/utils/API/apiRedshift';

export default function BatchKeywords({ handleCloseModal, isModalBatchKeywordOpen }) {
  const [allKeywords, setAllKeywords] = useState([]);
  const [inputSearchKeyword, setInputSearchKeyword] = useState('');
  const [keywordsSelected, setKeywordsSelected] = useState([]);
  const [keyLimitationCheck, setKeyLimitationCheck] = useState(false);
  const [keyCustomizationCheck, setKeyCustomizationCheck] = useState(false);
  const [selectAction, setSelectAction] = useState('');
  const [selectBatchAction, setSelectBatchAction] = useState('');
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const resGetKeywords = async () => {
      const result = await GetKeywords().then((data) => {
        setAllKeywords(data);
      });
      return result;
    };

    resGetKeywords();
  }, []);

  const filteredKeywordsSuggestions = inputSearchKeyword
    ? allKeywords.filter((key) => {
        return key.value.toLowerCase().includes(inputSearchKeyword.toLowerCase());
      })
    : [];

  const handleChangeAction = (event) => {
    setSelectAction(event.target.value);
  };

  const handleChangeBatchAction = (event) => {
    setSelectBatchAction(event.target.value);
  };

  const handleSubmit = async () => {
    let userList = [];
    setLoading(true);

    //   if (
    //       keywordsSelected === [] &&
    // (!keyLimitationCheck || !keyCustomizationCheck) &&
    // selectAction === ''
    //   ) {
    //       console.log('to aqui');
    //       toast.warn('Preencha os campos.');
    //       return;
    //   }

    if (selectAction !== '') {
      if (selectBatchAction === 'arquivo') {
        if (file === null || file === undefined || file === '') {
          toast.warn('Por favor insira um arquivo.');
        } else {
          userList = await readTxt(file);
        }
      } else {
        getDataRedshift(this.state.query).then((result) => {
          result.map((item, i) => {
            item.contato = item.contato.replace(/-|\(|\)|\s/g, '');

            if (item.contato.substring(0, 3) === '+55') {
              if (item.contato.substring(0, 2) === '55') {
                item.contato = '+' + item.contato;
              }
            }
            return userList.push(item.contato);
          });
        });
      }
    } else {
      toast.warn('Preencha os campos');
      setLoading(false);
      return;
    }

    if (userList.length > 0) {
      changeKeywords(userList).then(() => {
        toast.success('Alteração realizada com sucesso!');
        setLoading(false);
        setKeywordsSelected([]);
        setKeyLimitationCheck(false);
        setKeyCustomizationCheck(false);
        setFile('');
        setSelectBatchAction('');
        setSelectAction('');
      });
    } else {
      setLoading(false);
      toast.warn('Arquivo vazio ou com informação inválida.');
    }
  };

  const readTxt = (txtFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        let data = this.result.split('\n');
        let listaCellphone = [];
        for (let index = 0; index < data.length; index++) {
          const numero = data[index].trim();
          if (numero === '' || numero === undefined || numero === null);
          else listaCellphone.push(numero.replace(/@\S+/gi, ''));
        }
        resolve(listaCellphone);
      };
      reader.readAsText(txtFile);
    }).catch((error) => {
      setLoading(false);
      toast.error('Erro ao ler conteúdo do arquivo!');
    });
  };

  const changeKeywords = async (userList) => {
    await Promise.all(
      userList.map(async function (item, i) {
        await userReadCellphone_v3(item)
          .then(async (result) => {
            if (result !== false) {
              let userInfo = {
                updateDate: new Date().getTime(),
                syncType: 'merge',
                uId: result.userInfo.uId,
                keyWordsSetCustom: result.userInfo.keyWordsSetCustom,
                keyWordsSetLimit: result.userInfo.keyWordsSetLimit,
              };

              // Verifica se usuário não tem os campos e gera os campos
              if (!result.userInfo.keyWordsSetCustom) {
                result.userInfo.keyWordsSetCustom = [];
              }

              if (!result.userInfo.keyWordsSetLimit) {
                result.userInfo.keyWordsSetLimit = [];
              }

              if (selectAction === 'adicionar') {
                if (keyCustomizationCheck) {
                  keywordsSelected.map((keyword) => {
                    return (userInfo['keyWordsSetCustom'] = removeDuplicateUsingFilter(
                      result.userInfo.keyWordsSetCustom.concat(keyword.value)
                    ));
                  });
                }

                if (keyLimitationCheck) {
                  keywordsSelected.map((keyword) => {
                    return (userInfo['keyWordsSetLimit'] = removeDuplicateUsingFilter(
                      result.userInfo.keyWordsSetLimit.concat(keyword.value)
                    ));
                  });
                }
              } else {
                userInfo['syncType'] = 'override';

                if (keyCustomizationCheck) {
                  keywordsSelected.map((keyword) => {
                    return (userInfo['keyWordsSetCustom'] =
                      result.userInfo.keyWordsSetCustom.filter(
                        (item) => keyword.value.indexOf(item) < 0
                      ));
                  });
                }

                if (keyLimitationCheck) {
                  keywordsSelected.map((keyword) => {
                    return (userInfo['keyWordsSetLimit'] = result.userInfo.keyWordsSetLimit.filter(
                      (item) => keyword.value.indexOf(item) < 0
                    ));
                  });
                }
              }

              setUserInfo_v1(result.userInfo.uId, ['setUserInfo'], userInfo).catch((error) => {
                toast.error('Erro ao alterar informação do usuário: ' + item);
              });
            } else {
              // console.log("Usuário", item, "não encontrado.");
            }
          })
          .catch((error) => {
            toast.error('Erro ao buscar informação do usuário: ' + item + ' erro: ' + error);
          });
      }, this)
    ).catch((error) => {
      toast.error('Erro ao buscar ou alterar informação do usuário!');
    });
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalBatchKeyword')}
      open={isModalBatchKeywordOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={{ width: '100%', height: '100%' }}>
        <S.ModalBox style={{ maxHeight: '95%' }}>
          <div className="header">
            <Typography variant="h2" component="h2" id="modal-title">
              Gerenciamento de Keywords em Lote
            </Typography>
          </div>

          <S.BatchKeywordsBox>
            <Box className="inputSearchBox">
              <Typography variant="h4" component="h4" id="modal-description">
                Selecione a Keyword na lista abaixo.
              </Typography>
              <Box className="inputSearch">
                <Autocomplete
                  className="input"
                  multiple
                  id="tags-outlined"
                  options={filteredKeywordsSuggestions}
                  getOptionLabel={(option) => option.value}
                  value={keywordsSelected}
                  onChange={(event, value) => setKeywordsSelected(value)}
                  filterSelectedOptions
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={props.id}>
                        {option.value}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      value={inputSearchKeyword}
                      onChange={(e) => setInputSearchKeyword(e.target.value)}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box className="options">
              <Box>
                <Typography variant="h5" component="h5" className="titleOptions">
                  Tipo:
                </Typography>
                <FormGroup sx={{ flexDirection: 'row' }}>
                  <FormControlLabel
                    className="optionBox"
                    control={
                      <Checkbox
                        checked={keyLimitationCheck === true ? true : false}
                        onChange={(e) => setKeyLimitationCheck(e.target.checked)}
                        icon={<BiCircle fontSize="25px" />}
                        checkedIcon={<BsFillCheckCircleFill fontSize="25px" color="#5A287C" />}
                      />
                    }
                    label="Limitação"
                  />
                  <FormControlLabel
                    className="optionBox"
                    control={
                      <Checkbox
                        checked={keyCustomizationCheck === true ? true : false}
                        onChange={(e) => setKeyCustomizationCheck(e.target.checked)}
                        icon={<BiCircle fontSize="25px" />}
                        checkedIcon={<BsFillCheckCircleFill fontSize="25px" color="#5A287C" />}
                      />
                    }
                    label="Customização"
                  />
                </FormGroup>
              </Box>
              <Box>
                <Typography variant="h5" component="h5" className="titleOptions">
                  Ação:
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    className="optionBox"
                    checked={selectAction === 'adicionar' ? true : false}
                    onClick={handleChangeAction}
                    value="adicionar"
                    control={
                      <Radio
                        disableRipple
                        checkedIcon={<BsFillCheckCircleFill style={{ fontSize: '25px' }} />}
                        icon={<BiCircle style={{ fontSize: '25px' }} />}
                        sx={{
                          '&.Mui-checked': {
                            color: '#6E3296',
                          },
                        }}
                      />
                    }
                    label="Adicionar"
                  />
                  <FormControlLabel
                    className="optionBox"
                    checked={selectAction === 'remover' ? true : false}
                    onClick={handleChangeAction}
                    value="remover"
                    control={
                      <Radio
                        checkedIcon={<BsFillCheckCircleFill style={{ fontSize: '25px' }} />}
                        icon={<BiCircle style={{ fontSize: '25px' }} />}
                        sx={{
                          '&.Mui-checked': {
                            color: '#6E3296',
                          },
                        }}
                      />
                    }
                    label="Remover"
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography variant="h5" component="h5" className="titleOptions">
                  Ação em lote:
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    className="optionBox"
                    checked={selectBatchAction === 'arquivo' ? true : false}
                    onClick={handleChangeBatchAction}
                    value="arquivo"
                    control={
                      <Radio
                        disableRipple
                        checkedIcon={<BsFillCheckCircleFill style={{ fontSize: '25px' }} />}
                        icon={<BiCircle style={{ fontSize: '25px' }} />}
                        sx={{
                          '&.Mui-checked': {
                            color: '#6E3296',
                          },
                        }}
                      />
                    }
                    label="Arquivo"
                  />
                  <FormControlLabel
                    className="optionBox"
                    checked={selectBatchAction === 'redshift' ? true : false}
                    onClick={handleChangeBatchAction}
                    value="redshift"
                    control={
                      <Radio
                        checkedIcon={<BsFillCheckCircleFill style={{ fontSize: '25px' }} />}
                        icon={<BiCircle style={{ fontSize: '25px' }} />}
                        sx={{
                          '&.Mui-checked': {
                            color: '#6E3296',
                          },
                        }}
                      />
                    }
                    label="Query do Redshift"
                  />
                </RadioGroup>
              </Box>
            </Box>

            {selectBatchAction === 'arquivo' && (
              <Box className="importFile">
                <Typography variant="subtitle2" component="span" className="fileSpan">
                  {file ? file.name : 'Arquivo para envio da notificação'}
                </Typography>
                <div className="inputFileBox">
                  <label htmlFor="inputFileRef">
                    <IoIosAdd className="icon" /> Adicionar arquivo
                  </label>
                  <input
                    id="inputFileRef"
                    type="file"
                    accept=".txt"
                    style={{ display: 'none' }}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </Box>
            )}

            {selectBatchAction === 'redshift' && <h4>Em desenvolvimento</h4>}

            <Box className="actionsButtons">
              <Button
                variant="contained"
                className="button1"
                onClick={() => handleCloseModal('closeModalBatchKeyword')}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                className="button2"
                disabled={loading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <CircularProgress style={{ marginRight: '0.5rem' }} size="23px" />}
                Salvar
              </Button>
            </Box>
          </S.BatchKeywordsBox>
        </S.ModalBox>
      </div>
    </Modal>
  );
}
