import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function getPartnerCodesByCampaign(campaignId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(`${cfac22('API_HOST_V2')}/campaign/partnerCode?campaignId=${campaignId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Origin: 'localhost:3000',
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  });
}
