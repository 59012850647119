import styled from 'styled-components';
import * as D from 'js/components/DesignSystem/styles';

export const TagsLabelContainer = styled.span`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${D.triiboBlack};

  @media (max-width: 900px) {
  color: ${D.triiboBlack};
  margin-top: 0;
  }
`;

export const TagsBigLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
`;

export const TagsSmallLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
`;

export const TagsContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const TagsAddContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  @media (min-width: 900px) {
    gap: 1.8rem;
  }
`;

export const TagsAddButton = styled.button`
  border: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: white;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) top/100% 800%;
  background-color: ${D.triiboBlue};
  padding: 0.4rem;
  border-radius: 10px;
  min-width: 7rem;
  transition: 0.35 ease-in-out;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

export const FailButton = styled.button`
  border: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: white;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) top/100% 800%;
  background-color: ${D.triiboBlue};
  padding: 0.4rem;
  border-radius: 10px;
  min-width: 7rem;
  transition: 0.35 ease-in-out;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;


export const SideContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-content: center;

  @media (min-width: 900px) {
    &::before {
      z-index: -2;
      position: absolute;
      width: 100%;
      height: 100%;
      content: " ";
      background: white;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);
    }

    &::after {
      z-index: -1;
      content: " ";
      position: absolute;
      top: -1rem;
      width: 100%;
      height: 25%;
      border-radius: 10px 10px 0 0;
      border: 1px solid ${D.triiboBlue};
      background: ${D.triiboBlue};
    }
  }
`;

export const ButtonSaveContainer = styled.div`
  width: 100%;
  height: auto;

  @media (max-width: 900px) {
    width: auto;
  }
`;

export const CardContainer = styled.div`
  /* z-index: 1; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.2rem;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  &::before {
    content: " ";
    z-index: -1;
    border-radius: 10px;
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);

    ${({ white }) =>
      white &&
      `
          border: 1px solid rgba(0, 0, 0, 0.20);
          background: white;
        
    `}

    ${({ blue }) =>
      blue &&
      `
          border: 1px solid rgba(0, 0, 0, 0.20);
          background: ${D.triiboBlue};
        
    `}
  }

  @media (max-width: 900px) {
    justify-content: flex-start;
  }
`;

export const SectionLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.6rem;
  font-family: "Source Sans Pro", sans-serif;
  min-height: 3rem;
  width: 100%;
  color: ${D.triiboBlack};
  padding: 2rem;

  ${({ white }) =>
    white &&
    `
    color: white;
    `}

  ${({ flexStart }) =>
    flexStart &&
    `
    align-items: flex-start;
    `}

  @media (max-width: 900px) {
    padding: 2rem;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

`;

export const TitleH2 = styled.h2`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: white;

  @media (max-width: 900px) {
    font-size: 20px;
    color: black;
  }
`;

export const TextField = styled.text`
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${D.triiboGray};
  width: 70%;
  resize: none;
  background-color: white;
  transition: 0.3s ease-in-out;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;