import { updateDatabase } from 'js/library/services/DatabaseManager';
import { uploadImage, downloadImageAsBlob } from 'js/library/services/StorageManager';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import {
  getUserInfo,
  findValueInArray,
  getBase64FromUrl,
  separateBase64String,
  b64toBlob
} from 'js/library/utils/helpers';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

import { userReadUid_v3 } from 'js/library/utils/API/apiUserRead';
import createEstablishment from 'js/library/utils/API/Establishment/apiCreateEstablishment';

export const SubmitNewEst = async (
  establishmentData,
  newThumbnailFile,
  newPhotoListFile
) => {
  let payload = {};
  payload = establishmentData;

  let id = firebaseDatabase.ref('Estabelecimento-v2').push().key;

  const storageFolder = 'estabelecimento';

  if (newThumbnailFile !== null) {
    newThumbnailFile = separateBase64String(newThumbnailFile);

    let fotoThumbWithTimestamp;

    await uploadFiles(storageFolder, {
      mimeType: newThumbnailFile.mimeType,
      fileName: 'thumb_' + id,
      buffer: newThumbnailFile.buffer,
    })
      .then((res) => {
        const response = res.split('/');
        fotoThumbWithTimestamp = response[response.length - 1].split('?')[0];
        payload.fotoThumb = fotoThumbWithTimestamp;
      })
      .catch(() => {
        delete payload.fotoThumb;
      });
  }

  if (newPhotoListFile.length > 0) {
    let photoList = [];

    await Promise.all(
      newPhotoListFile.map(async (image, i) => {
        const newGalleryFile = separateBase64String(image);
        let galleryImgWithTimestamp;

        try {
          const res = await uploadFiles(storageFolder, {
            mimeType: newGalleryFile.mimeType,
            fileName: 'gal' + i + '_' + id,
            buffer: newGalleryFile.buffer,
          });

          const response = res.split('/');
          galleryImgWithTimestamp = response[response.length - 1].split('?')[0];
          photoList.push(galleryImgWithTimestamp);
        } catch (error) {
          // console.log(error);
        }
      })
    );

    payload.listaFotos = photoList;
  }

  payload.isPublished = false;
  payload.status = 0;
  const userInfo = getUserInfo();
  const idGestor = userInfo.uId;

  payload.admins = [
    {
      cargo: 'gestor',
      nome: userInfo.name,
      telefone: findValueInArray(userInfo.contactList, 'type', 'cellPhone')
        .value,
      uid: userInfo.uId,
    },
  ];

  let establishmentInfo = { idEstabelecimento: id, idGestor };
  userReadUid_v3(idGestor).then((resultUser) => {
    const user = resultUser.userInfo;
    let estAdmin = user.estAdmin;

    if (!estAdmin) {
      estAdmin = [];
    }
    estAdmin.push(id);

    let keyWordsSetLimit = user.keyWordsSetLimit;
    if (!keyWordsSetLimit) {
      keyWordsSetLimit = [];
    }

    if (!keyWordsSetLimit.some((item) => item === 'parceirostriibo')) {
      keyWordsSetLimit.push('parceirostriibo');
    }

    if (!keyWordsSetLimit.some((item) => item === 'triibo')) {
      keyWordsSetLimit.push('triibo');
    }

    keyWordsSetLimit = keyWordsSetLimit.reduce(
      (final, item, idx) => ({ ...final, [idx]: item }),
      {}
    );

    let keyWordsSetCustom = user.keyWordsSetCustom;
    if (!keyWordsSetCustom) {
      keyWordsSetCustom = [];
    }

    if (!keyWordsSetCustom.some((item) => item === 'parceirostriibo')) {
      keyWordsSetCustom.push('parceirostriibo');
    }

    if (!keyWordsSetCustom.some((item) => item === 'triibo')) {
      keyWordsSetCustom.push('triibo');
    }

    keyWordsSetCustom = keyWordsSetCustom.reduce(
      (final, item, idx) => ({ ...final, [idx]: item }),
      {}
    );

    updateDatabase(
      'Users-v3/',
      { keyWordsSetLimit, keyWordsSetCustom },
      // { estAdmin, keyWordsSetLimit, keyWordsSetCustom },
      idGestor,
      null
    );
    updateDatabase(
      'Estabelecimento-Validador',
      establishmentInfo,
      `${userInfo.triiboId}/${id}`,
      null
    );
  });

  payload.id = id;
   payload.vitrineOrdem = id;

  createEstablishment(id, payload, '/');
  //  uploadImage(storageFolder, newPhotoListFile, payload.listaFotos);
  //  uploadImage(storageFolder, newThumbnailFile, payload.fotoThumb);

  return payload;
};

export const EditImages = async (
  establishmentData,
  newThumbnailFile,
  newPhotoListFile
) => {
  const id = establishmentData.id;
  const storageFolder = 'estabelecimento';

  let fotoThumb;
  let listaFotos;

  if (newThumbnailFile !== null) {
    fotoThumb = 'thumb_' + id;
    let newImg = await getBase64FromUrl(newThumbnailFile);
    // newThumbnailFile = b64toBlob(newImg);
    newThumbnailFile = separateBase64String(newImg);
  }

  if (newPhotoListFile !== null) {
    listaFotos = [];
    for (const key in newPhotoListFile) {
      listaFotos[key] = 'gal' + key + '_' + id;
      if (newPhotoListFile[key].indexOf('https') === 0) {
        newPhotoListFile[key] = await downloadImageAsBlob(
          newPhotoListFile[key]
        );
      } else {
        let newImg = await getBase64FromUrl(newPhotoListFile[key]);
        newPhotoListFile[key] = b64toBlob(newImg);
      }
    }
  }

  // uploadImage(storageFolder, newThumbnailFile, fotoThumb);
  uploadFiles(storageFolder, {
    mimeType: newThumbnailFile.mimeType,
    fileName: fotoThumb,
    buffer: newThumbnailFile.buffer,
  });
  uploadImage(storageFolder, newPhotoListFile, listaFotos);
  // uploadFiles(storageFolder, {
  //   mimeType: "image/*",
  //   fileName: listaFotos,
  //   buffer: newPhotoListFile
  // })
};

export const EditClubImages = async (newThumbnailFile, imageType) => {
  const storageFolder = 'seuClube';

  let fotoThumb;

  if (newThumbnailFile !== null) {
    fotoThumb = imageType;
    let newImg = await getBase64FromUrl(newThumbnailFile);
    newThumbnailFile = b64toBlob(newImg);
  }

  uploadImage(storageFolder, newThumbnailFile, fotoThumb);
};

export const EditCardsClubImages = async (newThumbnailFile, imageType) => {
  const storageFolder = 'cards';

  let fotoThumb;

  if (newThumbnailFile !== null) {
    fotoThumb = imageType;
    let newImg = await getBase64FromUrl(newThumbnailFile);
    newThumbnailFile = b64toBlob(newImg);
  }

  uploadImage(storageFolder, newThumbnailFile, fotoThumb);
};
