import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography, Grid, Checkbox } from '@mui/material';
import { List, ArrowBack } from '@mui/icons-material';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import arrayMove from 'array-move';

import placeholder from 'styles/assets/placeholder/placeholder_card.png';
import { downloadImage } from 'js/library/services/StorageManager';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { updateDatabase } from 'js/library/services/DatabaseManager';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const SortCards = () => {
  const [priorityIncrement] = useState(100);
  const [order, setOrder] = useState('asc');
  const [orderBy] = useState('priority');
  const [items, setItems] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [filter, setFilter] = useState([]);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [keywordScroll, setKeywordScroll] = useState(0);
  const [cardScroll, setCardScroll] = useState(0);

  useEffect(() => {
    getDataListRestApi('Cards')
      .then((dataReceived) => {
        let cards = [];
        let keywords = [];
        Object.keys(dataReceived).map(function (item) {
          dataReceived[item].downloadedThumb = placeholder;

          return cards.push(dataReceived[item]);
        });

        cards = stableSort(cards, getSorting(order, orderBy));

        cards.forEach((card) => {
          if (Array.isArray(card.keyWordsSet)) {
            card.keyWordsSet.map((key) => {
              if (!keywords.includes(key)) {
                return keywords.push(key);
              }
            });
          }
        });

        setItems(cards);
        setKeywords(
          keywords.sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
        );
      })
      .catch(() => {});
  }, [order, orderBy]);

  const downloadImageHandler = (fotoThumb, key, downloadedThumb) => {
    if (fotoThumb && downloadedThumb === placeholder) {
      const data = [...items];
      const index = data.findIndex((x) => x.cardId === key);

      const storageInfo = fotoThumb.split('/');
      downloadImage(storageInfo[0], storageInfo[1])
        .then((downloadedImage) => {
          data[index].downloadedThumb = downloadedImage;
          setItems(data);
        })
        .catch(() => {});
    }
  };

  const saveScrollState = () => {
    setKeywordScroll(document.getElementById('keywordList').scrollTop);
    setCardScroll(document.getElementById('cardList').scrollTop);
  };

  const loadScrollState = () => {
    document.getElementById('keywordList').scrollTop = keywordScroll;
    document.getElementById('cardList').scrollTop = cardScroll;
  };

  const onSortKeywordEnd = ({ oldIndex, newIndex }) => {
    saveScrollState();
    setKeywords((prevKeywords) => arrayMove(prevKeywords, oldIndex, newIndex));
    loadScrollState();
  };

  const onSortCardEnd = ({ oldIndex, newIndex }) => {
    saveScrollState();
    setItems((prevItems) => arrayMove(prevItems, oldIndex, newIndex));
    loadScrollState();
  };

  const savePriority = () => {
    let finalList = [];
    let data = items;

    let keywordCounter = 0;
    let innerCounter = 0;

    keywords.map((keyword, index) => {
      if (keyword !== 'triibo') {
        keywordCounter = index * priorityIncrement;
        innerCounter = 0;
        return data.map((item) => {
          if (item.keyWordsSet.indexOf(keyword) >= 0) {
            if (item.downloadedThumb) {
              item.downloadedThumb = null;
            }
            item.priority = keywordCounter + innerCounter;
            innerCounter += 1;
            return finalList.push(item);
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    });

    //Triibo por último
    keywordCounter = keywordCounter + 1000;
    innerCounter = 0;
    data.map((item) => {
      if (item.keyWordsSet.indexOf('triibo') >= 0) {
        item.priority = keywordCounter + innerCounter;
        innerCounter += 1;
        return finalList.push(item);
      } else {
        return null;
      }
    });
    finalList.map(function (item) {
      return updateDatabase('Cards', item, item.cardId, '');
    });
  };

  const handleCheckArray = (name) => (event) => {
    saveScrollState();

    //let aux = this.state.filter;
    let aux = [];

    if (event.target.checked === true) {
      aux.push(event.target.value);
      //this.setState({filter: [...new Set(aux)]});
      setFilter(aux);
      loadScrollState();
    } else {
      // for (var i=aux.length-1; i>=0; i--) {
      // 	if (aux[i] === event.target.value) {
      // 		aux.splice(i, 1);
      // 	}
      // }

      // this.setState({filter: [...new Set(aux)]});
      setFilter([]);
    }
  };

  const handleCheck = (name) => (event) => {
    setShowActiveOnly(event.target.checked);
  };

  const inFilter = (keywords) => {
    let exists = false;
    if (Array.isArray(keywords) && keywords.length > 0) {
      keywords.map((item) => {
        if (filter.indexOf(item) >= 0) {
          return (exists = true);
        } else {
          return null;
        }
      });
    }
    return exists;
  };

  const SortableItem = SortableElement(({ value }) => (
    <li
      key={value.cardId}
      index={value.cardId}
      style={{
        listStyleType: 'none',
        zIndex: 99999999,
        display: showActiveOnly
          ? value.isPublished && value.enableDate <= Date.now() && value.disableDate >= Date.now()
            ? 'block'
            : 'none'
          : 'block',
      }}
    >
      <img
        key={value.cardId}
        src={value.downloadedThumb}
        alt="Thumb do Card"
        width="500px"
        onLoad={() =>
          downloadImageHandler(value.images.mobile, value.cardId, value.downloadedThumb)
        }
      />
    </li>
  ));
  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul
        id="cardList"
        style={{
          listStyleType: 'none',
          overflowY: 'scroll',
          maxHeight: '100vh',
          backgroundColor: '#fafafa',
        }}
      >
        {items.map((value, index) =>
          inFilter(value.keyWordsSet) ? (
            <SortableItem key={`${items[index].cardId}`} index={index} value={value} />
          ) : null
        )}
      </ul>
    );
  });

  const SortableKeyword = SortableElement(({ value }) => (
    <div key={value} index={value} style={{ marginLeft: '10px', zIndex: 99999999 }}>
      <Checkbox
        key={value}
        checked={filter.indexOf(value) >= 0}
        onChange={handleCheckArray(value)}
        value={value}
        color="primary"
      />
      {value}
    </div>
  ));

  const SortableKeywordList = SortableContainer(({ items }) => {
    return (
      <div
        id="keywordList"
        style={{
          overflowY: 'scroll',
          maxHeight: '200px',
          backgroundColor: '#fafafa',
          marginBottom: '30px',
        }}
      >
        {items.map((value, index) => (
          <SortableKeyword key={`${value}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo=" Cards" />
      <Grid container spacing={5}>
        <Grid item md={6} xs={12}></Grid>

        <Grid item md={6} xs={12}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              marginBottom: '1rem',
              float: 'right',
            }}
          >
            <Link
              to={{ pathname: '/admin/configuracoes/cards', state: '' }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  marginRight: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>
            </Link>

            <Link
              to={{ pathname: '/admin/configuracoes/', state: '' }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                title="Salvar Lista"
                variant="contained"
                color="primary"
                size="small"
                onClick={savePriority}
                startIcon={<List />}
              >
                Salvar Lista
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Checkbox checked={showActiveOnly} onChange={handleCheck(showActiveOnly)} color="primary" />
      Mostrar apenas cards ativos
      <Typography variant="body2" color="secondary">
        Ordem das keywords
      </Typography>
      <SortableKeywordList items={keywords} onSortEnd={onSortKeywordEnd} />
      <Typography variant="body2" color="secondary">
        Ordem dos cards
      </Typography>
      <SortableList items={items} onSortEnd={onSortCardEnd} />
    </div>
  );
};

export default SortCards;
