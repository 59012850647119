import React from "react";

import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";

const UsefullLinkImagePickerModal = (props) => {
  const { modalStatus, setModalStatus } = props;

  return (
    <D.ModalContainer show={modalStatus}>
      <D.ModalBody>
        <D.ModalLabelContainer>
          <D.ModalTitle>Escolha a imagem desejada</D.ModalTitle>
          <D.ModalSubtitle></D.ModalSubtitle>
        </D.ModalLabelContainer>{" "}
        <S.ButtonGrid>
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
          <button
            style={{ background: "red", width: "3rem", height: "3rem" }}
          />
        </S.ButtonGrid>
      </D.ModalBody>
      <D.ModalBackground
        onClick={(e) => {
          e.preventDefault();
          setModalStatus(false);
        }}
      />
    </D.ModalContainer>
  );
};

export default UsefullLinkImagePickerModal;
