
import { TriiboH5, triiboGray } from 'js/components/DesignSystem/styles';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 529px;
  max-height: 80%;
  width: 100%;
  padding: 1rem 2rem;
  background: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 10px;
    border: 3px solid #f2f2f2;
  }

  > h2 {
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
`;

export const InputRadio = styled.input.attrs((props) => ({
  type: 'radio',
}))`
  margin-right: 1rem;
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const RadioSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const ActionsButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const InputContainer = styled.div`
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  border: 1px solid ${({ error }) => (error ? 'red' : triiboGray)};
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Input = styled.input`
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #fff;
  transition: 0.3s ease-in-out;
`;

export const TextCounter = styled.span`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  color: #707070;
`;

export const ErrorText = styled(TriiboH5)`
  margin-top: 0.5rem;
  color: red;
`;

export const HelpText = styled(TriiboH5)`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
