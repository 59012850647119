import React, { useEffect, useState } from 'react';

import { Modal } from '@mui/material';
import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';
import DropDown from 'js/components/DesignSystem/DropDown';
import { toast } from 'react-toastify';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import { PiWarningCircleLight } from 'react-icons/pi';

export default function ModalAddField({
  open,
  handleClose,
  formsFields,
  setFormsFields,
  fieldToEdit,
  setFieldToEdit,
  fieldTypes,
  questions,
  setQuestions,
}) {
  const [field, setField] = useState({
    label: '',
    required: true,
    type: '',
  });
  const [typeSelected, setTypeSelected] = useState({ label: '', value: '' });
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const errorFields = [];

  const fieldTypesFiltered = fieldTypes
    .map((field) => {
      return {
        label: field.name,
        value: field.type,
        helpText: field.helpText ?? null,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (fieldToEdit) {
      setField({
        label: fieldToEdit.label,
        required: fieldToEdit.required,
        type: fieldToEdit.type,
      });
      setTypeSelected({
        label: fieldToEdit.type === 'rating' ? 'Avaliação' : 'Texto',
        value: fieldToEdit.type,
      });
    }
  }, [fieldToEdit]);

  const handleSubmit = () => {
    const newField = {
      question: field.label,
      required: field.required,
      type: typeSelected.value,
    };

    if (!newField.question.length) {
      toast.error('Preencha o campo: Pergunta');
      return;
    }

    if (fieldToEdit) {
      questions[fieldToEdit.index] = newField;
      setQuestions(questions);
    } else setQuestions((prevState) => [...prevState, newField]);

    setField({
      label: '',
      required: true,
      type: '',
    });
    handleClose();
  };

  useEffect(() => {
    if (typeSelected.value === 'rating') setField({ ...field, required: true });
  }, [typeSelected]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <S.Container>
          <T.TriiboH2>Configurar campo</T.TriiboH2>

          <S.Content>
            <div>
              <T.TriiboH4>Tipo do campo*</T.TriiboH4>
              <DropDown
                selectedOption={typeSelected}
                setSelectedOption={setTypeSelected}
                isSelectOpen={isTypeOpen}
                setIsSelectOpen={setIsTypeOpen}
                data={fieldTypesFiltered}
                top={true}
                height={'42px'}
                error={errorFields.includes('fieldType')}
              />
              {errorFields.includes('fieldType') && (
                <S.ErrorText>Informe o tipo do campo</S.ErrorText>
              )}

              {typeSelected.helpText && (
                <S.HelpText>
                  <PiWarningCircleLight size={30} />
                  {typeSelected.helpText}
                </S.HelpText>
              )}
            </div>

            <div>
              <T.TriiboH4>Pergunta*</T.TriiboH4>
              <S.InputContainer error={errorFields.includes('fieldLabel')}>
                <S.Input
                  type="text"
                  maxLength="100"
                  value={field.label}
                  onChange={(e) => {
                    setField({ ...field, label: e.target.value });
                  }}
                />
                <S.TextCounter>{field.label.length}/100</S.TextCounter>
              </S.InputContainer>
              {errorFields.includes('fieldLabel') && (
                <S.ErrorText>Informe o nome do campo</S.ErrorText>
              )}
            </div>

            <div>
              <T.TriiboH4>O campo será obrigatório?*</T.TriiboH4>
              <S.RadioSelectContainer>
                <S.Label>
                  <S.InputRadio
                    checked={field.required === true}
                    onChange={(e) => setField({ ...field, required: true })}
                  />
                  Sim
                </S.Label>
                {typeSelected.value !== 'rating' && (
                  <S.Label>
                    <S.InputRadio
                      checked={field.required === false}
                      onChange={(e) => setField({ ...field, required: false })}
                    />
                    Não
                  </S.Label>
                )}
              </S.RadioSelectContainer>
            </div>
          </S.Content>

          <S.ActionsButtons>
            <PrimaryButton
              bgColor={'#F13A3A'}
              onClick={() => {
                setField({
                  id: formsFields.size.toString(),
                  fieldName: '',
                  label: '',
                  inputType: '',
                  allowEdition: true,
                  required: true,
                  type: '',
                });
                setFieldToEdit('');
                handleClose();
              }}
            >
              Cancelar
            </PrimaryButton>
            <PrimaryButton onClick={handleSubmit}>
              {fieldToEdit ? 'Atualizar' : 'Adicionar'}
            </PrimaryButton>
          </S.ActionsButtons>
        </S.Container>
      </>
    </Modal>
  );
}
