import React from "react";
import styled from "styled-components";

import * as D from "js/components/DesignSystem/styles";

import * as S from "./styles";

const Container = styled.div`
  height: 7px;
  width: 100%;
  position: relative;
`;

const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
`;

const Background = styled(BaseBox)`
  background: ${D.triiboGray};
  width: 100%;
`;

const Progress = styled(BaseBox)`
  background: ${D.triiboPurple};
  width: ${({ percent }) => percent}%;
`;

const ProgressBars = (props) => {
  const { progressOne, progressTwo, progressThree, currentScreen } = props;

  return (
    <S.BarsContainer>
      <S.BarsSeparators>
        <S.StepsName active={currentScreen === 0 ? true : false}>
          1<S.StepsNameDisplay>. Dados do Negócio</S.StepsNameDisplay>
        </S.StepsName>
        <Container>
          <Background />
          <Progress percent={progressOne} />
        </Container>
      </S.BarsSeparators>
      <S.BarsSeparators>
        <S.StepsName active={currentScreen === 1 ? true : false}>
          2<S.StepsNameDisplay>. Contatos e Redes Sociais</S.StepsNameDisplay>
        </S.StepsName>
        <Container>
          <Background />
          <Progress percent={progressTwo} />
        </Container>
      </S.BarsSeparators>
      <S.BarsSeparators>
        <S.StepsName active={currentScreen === 2 ? true : false}>
          3<S.StepsNameDisplay>. Fotos</S.StepsNameDisplay>
        </S.StepsName>
        <Container>
          <Background />
          <Progress percent={progressThree} />
        </Container>
      </S.BarsSeparators>
    </S.BarsContainer>
  );
};

export default ProgressBars;
