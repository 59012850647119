import styled from 'styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Box } from '@mui/material';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  width: 100%;

  button {
    font-weight: 700;
  }
`;

export const InvisibleButton = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.$color ? props.$color : 'inherit')};
`;

export const InvisibleLink = styled.a`
  text-decoration: none;
  color: ${(props) => (props.$color ? props.$color : 'inherit')};
`;

export const ContainerSearch = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const BoxSearch = styled.form`
  display: flex;
  max-width: 600px;
  width: 100%;
  max-height: 42px;
  /* margin: 0 auto; */
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const InputBox = styled.div`
  flex: 1;
  width: 100%;
  height: 42px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 5px;
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0 1rem;
  font-size: 16px;
  height: 100%;
`;

export const SearchIconButton = styled.button`
  background: none;
  height: 100%;
  border-left: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  img {
    width: 24px;
  }
`;
