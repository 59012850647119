import React from 'react';

import {
  Typography,
  Grid,
  Paper,
  ListItem,
  ListItemText,
  List,
  Divider,
  ListItemIcon,
  Button,
} from '@mui/material';
import placeholderItem from 'styles/assets/placeholder/placeholder_thumbnail.png';
import { downloadImage } from 'js/library/services/StorageManager.js';
import { convertToDateDefault } from 'js/library/utils/helpers.js';

export const BalanceItem = (voucher) => {
  const [thumbnail, setThumbnail] = React.useState(placeholderItem);
  const [details, showDetails] = React.useState(false);

  voucher = voucher.voucher;

  function downloadImageOnLoad() {
    if (voucher.thumbnail !== undefined) {
      downloadImage('voucher', voucher.thumbnail)
        .then((downloaded) => {
          setThumbnail(downloaded);
        })
        .catch((error) => {
          return error;
        });
    }
  }

  const descricao =
    voucher.description === undefined
      ? ''
      : voucher.description.length < 115
      ? voucher.description
      : voucher.description.substring(0, 70) + '...';

  const lenVouchers =
    voucher.type === 'promo' && voucher.vouchers ? Object.keys(voucher.vouchers).length : 0;
  return (
    <Paper style={{ height: '100%' }}>
      <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
        <Grid item sm={6} xs={12}>
          <img
            style={{ maxWidth: '100%', maxHeight: 176, margin: '0 auto', display: 'block' }}
            className="img-radius"
            src={thumbnail}
            alt="carteira"
            onLoad={() => downloadImageOnLoad()}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle1" color="secondary">
            {voucher.title}
          </Typography>

          <Typography variant="subtitle1" color="secondary">
            {voucher.establishmentName}
          </Typography>

          <Typography variant="subtitle2" color="secondary">
            {descricao}
          </Typography>

          <Typography style={{ fontWeight: 'bold' }} variant="subtitle2" color="secondary">
            {voucher.key}
          </Typography>

          {voucher.type === 'promo' && voucher.vouchers ? (
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: 'capitalize', color: 'white' }}
              onClick={() => showDetails(!details)}
            >
              {details ? 'Ver menos' : 'Ver mais'}
            </Button>
          ) : null}
        </Grid>

        {voucher.type === 'promo' && voucher.vouchers && details ? (
          <div
            style={{
              margin: '20px',
              padding: 0,
              boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.1)',
              width: '100%',
            }}
          >
            <Typography
              style={{ padding: 5, fontWeight: 'bold' }}
              variant="subtitle2"
              color="body2"
            >
              Você possui {lenVouchers} {lenVouchers > 1 ? 'cupons' : 'cupom'}
            </Typography>
            {Object.keys(voucher.vouchers).map((voucherItem, voucherI) => {
              const voucher2 = voucher.vouchers[voucherItem];
              return (
                <List style={{ padding: 0 }}>
                  <Divider />
                  <ListItem style={{ padding: 5 }}>
                    <ListItemText
                      primary={
                        <Typography
                          style={{ fontWeight: 'bold' }}
                          variant="subtitle2"
                          color="body2"
                        >
                          {voucherItem}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="h6" color="body2">
                          {convertToDateDefault(voucher2.sentDate)}
                        </Typography>
                      }
                    />
                    <ListItemIcon>
                      <Typography style={{ fontWeight: 'bold' }} variant="body2">
                        {voucher2.value}
                      </Typography>
                    </ListItemIcon>
                  </ListItem>
                </List>
              );
            })}
          </div>
        ) : null}
      </Grid>
    </Paper>
  );
};
export default BalanceItem;

