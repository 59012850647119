import React from 'react';

//Styles
import { MainContainer } from 'js/components/Clubs/clubeTableStyles';

// Components
import { DragDrop } from './DragDrop';
import { GoBackHeader } from '../RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../RelationshipClub/HeaderTitle';

export function CardManagement() {
  return (
    <MainContainer style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo=" Gerenciamento de cards" />
      <DragDrop />
    </MainContainer>
  );
}
