import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  TextField,
  Typography,
  Snackbar,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Divider,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';

import axios from 'axios';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import cfac22 from 'js/library/utils/cfac22';

const GenerateLink = (props) => {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [keysList, setKeysList] = useState({});
  const [generatedLink, setGeneratedLink] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  window.pathname = null;

  //altera os states dos campos, caso o usuário insira informações nos inputs
  const handleChange = (name) => (event) => {
    if (name === 'key' && key === 'dest' && event.target.value !== 'dest') {
      setValue('');
    } else if (name === 'key' && event.target.value === 'dest') {
      setValue('PROFILE');
    }

    if (name === 'key') {
      setKey(event.target.value);
    } else if (name === 'value') {
      setValue(event.target.value);
    }
  };

  const addKey = () => {
    setKeysList({ ...keysList, [key]: value.trim() });
    setKey('');
    setValue('');
    setGeneratedLink('');
  };

  const removeKey = (key) => {
    const { [key]: _, ...rest } = keysList;
    setKeysList(rest);
  };

  const getLink = async (keysList) => {
    if (keysList.dest) {
      keysList.dest = keysList.dest.toUpperCase();
      if (keysList.dest !== 'WALLET' && keysList.dest !== 'PROFILE' && keysList.dest !== 'INBOX') {
        setOpenSnackbar(true);
        setSnackbarMessage(
          'Valor do campo dest inválido. Valores aceitos: WALLET, PROFILE e INBOX'
        );
        return;
      }
    }

    const dynamicLinkKey = cfac22('DYNAMIC_LINK_KEY');
    const dynamicLinkApi = `${cfac22('DYNAMIC_LINK_API')}${dynamicLinkKey}`;
    const dynamicLinkDomain = cfac22('DYNAMIC_LINK_DOMAIN');
    const androidPackageName = cfac22('ANDROID_PACKAGE_NAME');
    const iosPackageName = cfac22('IOS_PACKAGE_NAME');
    const iosStoreId = cfac22('IOS_STORE_ID');
    const baseLink = cfac22('BASE_LINK');

    const params = keysList
      ? Object.keys(keysList)
          .map((e) => `${e}=${keysList[e]}`)
          .join('&')
      : null;

    const finalLink = baseLink + (params ? '?' + params : '');

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain: dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName: androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    try {
      const result = await axios.post(dynamicLinkApi, linkRequest);
      setGeneratedLink(result.data.shortLink);
      setKeysList({});
      setKey('');
      setValue('');
    } catch (err) {
      setOpenSnackbar(true);
      setSnackbarMessage('Erro ao gerar link.');
    }
  };

  const copyLink = (link) => {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
  };

  if (props.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  } else {
    if (window.pathname !== null) {
      return <Redirect to={window.pathname} />;
    } else {
      return (
        <div style={{ padding: '4rem 32px' }}>
          <GoBackHeader />
          <HeaderTitle pageInfo="Gerador de Links" />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              addKey();
            }}
          >
            <TextField
              id="key"
              label="Chave"
              select
              value={key}
              onChange={handleChange('key')}
              margin="normal"
              required
              style={{ width: '200px' }}
            >
              <MenuItem key="1" value="keyword">
                Keyword
              </MenuItem>

              <MenuItem key="2" value="dest">
                Direcionamento
              </MenuItem>

              <MenuItem key="3" value="promo">
                Promoção
              </MenuItem>

              <MenuItem key="4" value="place">
                Estabelecimento
              </MenuItem>

              <MenuItem key="5" value="chatThread">
                ID da Conversa do Chat
              </MenuItem>

              <MenuItem key="6" value="chatUserId">
                UID do Usuário para iniciar o Chat
              </MenuItem>
            </TextField>

            <TextField
              id="value"
              label="Valor"
              value={value}
              onChange={handleChange('value')}
              margin="normal"
              required
              select={key === 'dest'}
              style={{
                width: '200px',
                marginLeft: '10px',
                margin: '16px 32px 7px 10px',
              }}
            >
              <MenuItem key="1" value="PROFILE">
                Perfil
              </MenuItem>
              <MenuItem key="2" value="WALLET">
                Carteira
              </MenuItem>
              <MenuItem key="3" value="INBOX">
                Caixa de Entrada
              </MenuItem>
            </TextField>

            <Button
              type="submit"
              disabled={key.length === 0 || value.length === 0}
              style={{
                fontWeight: '550',
                color: 'white',
                marginLeft: '-20px',
                marginTop: '25px',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
            >
              Adicionar
            </Button>
            <br />
            <Button
              onClick={() => getLink(keysList)}
              style={{
                fontWeight: '550',
                color: 'white',
                marginBottom: '8px',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
            >
              Gerar Link
            </Button>

            {generatedLink ? (
              <div>
                <Typography variant="h5" style={{ display: 'inline-block', marginLeft: '24px' }}>
                  Short Link: {generatedLink}
                </Typography>
                <Button
                  onClick={() => copyLink(generatedLink)}
                  style={{
                    display: 'inline-block',
                    fontWeight: '550',
                    color: 'white',
                    marginLeft: '24px',
                    marginBottom: '8px',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="primary"
                >
                  Copiar Link
                </Button>
              </div>
            ) : (
              ''
            )}
          </form>

          <Grid container spacing={5}>
            <Grid item md={6} sm={12}>
              <Divider />
              {Object.keys(keysList).length === 0 ? (
                <div>
                  <br />
                  <br />
                  <Typography variant="body2">Nenhum valor adicionado.</Typography>
                </div>
              ) : (
                <List>
                  {Object.keys(keysList).map((item) => (
                    <ListItem key={item} role={undefined} dense button>
                      <ListItemText primary={`${item} : ${keysList[item]}`} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeKey(item)}
                          edge="end"
                          aria-label="Comments"
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openSnackbar}
            onClose={handleCloseAlert}
            ContentProps={{ 'aria-describedby': 'message-id' }}
            message={<span id="message-id">{snackbarMessage}</span>}
          />
        </div>
      );
    }
  }
};

export default GenerateLink;
