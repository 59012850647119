import styled from 'styled-components';

export const Button = styled.button`
  width: ${({ width }) => width || '300px'};
  min-height: ${({ height }) => height || '42px'}!important;
  height: 100%;
  background: ${({ bgColor }) => bgColor || '#328796'};
  border: none;
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border-radius: 10px;
  /* padding: 4rem; */
  color:${({ color }) => color || '#fff'};
  cursor: pointer;

  :hover {
    background-color: ${({ bgColor }) =>
      bgColor ? bgColor + 'cc' : '#6e3296'};
  }

  :disabled {
    background: #dbdbdb;
    color: #555555;
    cursor: default;
  }
`;
