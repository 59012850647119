export default function reducer(
  state = {
    id: null,
    templateName: null,
    listAllImages: null,
    listImageURL: null,
    formElements: null,
    imageFormat: null,
  },
  action
) {
  switch (action.type) {
    case "MARKETING_TEMPLATE_PENDING": {
      return {
        ...state,
        id: null,
        templateName: null,
        listAllImages: null,
        listImageURL: null,
        formElements: null,
        imageFormat: null,
      };
    }

    case "MARKETING_TEMPLATE_FULFILLED": {
      return {
        ...state,
        id: action.payload.id,
        templateName: action.payload.templateName,
        listAllImages: action.payload.listAllImages,
        listImageURL: action.payload.listImageURL,
        formElements: action.payload.formElements,
        imageFormat: action.payload.imageFormat,
      };
    }

    case "MARKETING_TEMPLATE_REJECTED": {
      return {
        ...state,
        id: null,
        templateName: null,
        listAllImages: null,
        listImageURL: null,
        formElements: null,
        imageFormat: null,
      };
    }

    default:
      return state;
  }
}
