import React, { useState } from "react";

import { cloneDeep } from "lodash";

import * as D from "js/components/DesignSystem/styles";
import { TagsAddContainer, TagsAddButton } from "./styles";

const TagsAdd = (props) => {
  const { form, formKey, setForm } = props;
  const [tagCapture, setTagCapture] = useState("");

  const handlePushTag = () => {
    let tagHolder = cloneDeep(form[formKey]);
    if (
      tagHolder.indexOf(tagCapture.toLocaleLowerCase()) === -1 &&
      tagCapture.length !== 0
    ) {
      tagHolder.push(tagCapture.toLowerCase());
    }
    setForm({
      ...form,
      [formKey]: [...tagHolder],
    });
    setTagCapture("");
  };

  return (
    <TagsAddContainer>
      <D.TriiboTextField
        rows={1}
        placeholder={"Ex.pizza*"}
        value={tagCapture}
        onChange={(e) => {
          setTagCapture(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handlePushTag();
          }
        }}
      />
      <TagsAddButton
        blue={true}
        onClick={(e) => {
          e.preventDefault();
          handlePushTag(e);
        }}
      >
        Adicionar tag
      </TagsAddButton>
    </TagsAddContainer>
  );
};

export default TagsAdd;
