import React from "react";
import styled from "styled-components";

import * as D from "js/components/DesignSystem/styles";

const BackgroundBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #e3e3e3;
  border-radius: 1.6rem;
  height: 1.6rem;
  width: 2.6rem;
  padding: 0 0 0 0.2rem; //top right bottom left

  ${({ checked }) =>
    checked &&
    `
    background-color: ${D.triiboPurple};
    padding: 0 0 0 1.2rem;

    `}

  cursor: pointer;
  transition: 0.35s ease-in-out;
`;

const Circle = styled.div`
  background-color: white;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
`;

const ToggleSwitch = (props) => {
  const { checked } = props;

  return (
    <BackgroundBody
      checked={checked}
    >
      <Circle />
    </BackgroundBody>
  );
};

export default ToggleSwitch;
