import dispatcher from "js/core/dispatcher";
import { updateDatabase } from "js/library/services/DatabaseManager";
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';


export const updateBatchActionsAction = (dispatch, data) =>
{
    data.queryId = data.queryId === null ? firebaseDatabase.ref("queries-redshift").push().key : data.queryId;

    const pathname = '/admin/configuracoes'
    
    return dispatcher(dispatch, "BATCH_ACTIONS",
    updateDatabase('queries-redshift', data, data.queryId, pathname)
    );
}