import React, { useState, useEffect } from "react";

import styled from "styled-components";

import InputMask from "react-input-mask";

import { GrStatusPlaceholderSmall } from "react-icons/gr";

import * as D from "js/components/DesignSystem/styles";

import phoneMaskArray from "js/components/DesignSystem/phoneMaskArray.json";
import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 1.4rem;
  border-radius: 10px;
  border: 1px solid ${D.triiboGray};
  padding: 0.6rem;
  background: white;
`;

const PlaceholderContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(0, 0, 0, 0.3);
`;

const XContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ContactTextField = styled(InputMask)`
  border: 0;
  outline-width: 0;
  width: 90%;
  text-transform: lowercase;

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none
    `}
`;

const ContactCountryField = styled.select`
  font-family: NotoColorEmojiLimited, "Source Sans Pro", sans-serif;
  border: 0;
  outline-width: 0;
  display: none;
  background: white;

  ${({ isPhone }) =>
    isPhone &&
    `
          display: block;
        
    `}
`;

const LeftContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 1rem;
`;

const Options = styled.option`
  font-family: NotoColorEmojiLimited, "Source Sans Pro", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContactInputFieldCuration = ({
  icon,
  placeholder,
  formState,
  setFormState,
  index,
  group,
  type,
}) => {
  // props
  // icon = react-icon inserido no componente
  // placeholder = texto de placeholder
  // formState e setFormState = useState do objeto do formulario inteiro
  // index = index do componente
  // group = denota qual dos campos (contato para os clientes e redes sociais) o component pertence
  // type = tipo de campo, usado para definir se tem um campo de input extra para definir qual o ddd de um número de telefone
  // const { icon, placeholder, formState, setFormState, index, group, type } =
  //   props;

  const [countryID, setCountryID] = useState(0);
  const [inputStr, setInputStr] = useState("");
  const [stopper, setStopper] = useState(true); //serve para impedir que os valores sejam sobreescritos em reload

  useEffect(() => {
    if (stopper) {
      setStopper(false);
      if (group === "contact") {
        let numberOfLettersToRemove = 0;
        for (let i = 0; i < phoneMaskArray.length; i++) {
          if (
            formState.contato[index].value.startsWith(phoneMaskArray[i].ddd)
          ) {
            //serve para identificar qual que é o ddd do número a partir de comparação
            setCountryID(i); //seta qual que é o país no momento que encontra match
            if (i === 1 && formState.contatoTriibo[index].value.length === 14) {
              setCountryID(0);
            } // lida com a exceção de número BR domiciliar
            numberOfLettersToRemove = phoneMaskArray[i].ddd.length; // seta quais letras da string devem ser retirados
          }
        }
        setInputStr(
          formState.contato[index].value.substring(numberOfLettersToRemove)
        ); //retira as letras
        return;
      }
      if (group === "contactDeleted") {
        let numberOfLettersToRemove = 0;
        for (let i = 0; i < phoneMaskArray.length; i++) {
          if (
            formState.contatosExcluido[index].value.startsWith(
              phoneMaskArray[i].ddd
            )
          ) {
            //serve para identificar qual que é o ddd do número a partir de comparação
            setCountryID(i); //seta qual que é o país no momento que encontra match
            if (
              i === 1 &&
              formState.contatosExcluido[index].value.length === 14
            ) {
              setCountryID(0);
            } // lida com a exceção de número BR domiciliar
            numberOfLettersToRemove = phoneMaskArray[i].ddd.length; // seta quais letras da string devem ser retirados
          }
        }
        setInputStr(
          formState.contatosExcluido[index].value.substring(
            numberOfLettersToRemove
          )
        ); //retira as letras
        return;
      }
      if (group === "socials") {
        setInputStr(formState.redesSociais[index].value);
      }
      if (group === "socialsDeleted") {
        setInputStr(formState.redesSociaisExcluidas[index].value);
      }
      return;
    }
    if (!stopper) {
      if (group === "contact") {
        let newArr = formState.contato;
        newArr[index].value = inputStr.toLowerCase();
        if (type === "telefone" || type === "whatsapp" || type === "sms") {
          const countryDDD = phoneMaskArray.filter(
            (e) => e.id === parseInt(countryID)
          )[0].ddd;
          newArr[index].value = countryDDD + inputStr;
          newArr[index].value = newArr[index].value.replace(/\W+/g, "");
          newArr[index].value = "+" + newArr[index].value;
        }
        setFormState({
          ...formState,
          contato: [...newArr],
        });
      }
      if (group === "socials" && inputStr.length > 1) {
        let newArr = formState.redesSociais;
        newArr[index].value = inputStr.toLowerCase();
        setFormState({
          ...formState,
          redesSociais: [...newArr],
        });
      }
    }
  }, [inputStr]);

  return (
    <>
      <InputContainer>
        <LeftContainer>
          {icon ? icon : <GrStatusPlaceholderSmall />}
          <ContactCountryField
            isPhone={
              type === "telefone" || type === "whatsapp" || type === "sms"
                ? true
                : false
            }
            value={countryID}
            onChange={(e) => {
              e.preventDefault();
              setCountryID(e.target.value);
            }}
          >
            {phoneMaskArray.map(({ initials, emoji, ddd, id }, index) => (
              <Options key={id} value={id}>
                {emoji} {initials} {ddd}
              </Options>
            ))}
          </ContactCountryField>
          <PlaceholderContainer>
            {" "}
            <ContactTextField
              placeholder={placeholder ? placeholder : "triibo.com.br"}
              value={inputStr}
              disabled={
                group === "contactDeleted" || group === "socialsDeleted"
              }
              onChange={(e) => {
                e.preventDefault();
                setInputStr(e.target.value);
              }}
              mask={
                type === "telefone" || type === "whatsapp" || type === "sms"
                  ? phoneMaskArray.filter(
                      (e) => e.id === parseInt(countryID)
                    )[0].mask
                  : ""
              }
            />
          </PlaceholderContainer>
        </LeftContainer>

        {/* botão para deletar campo da lista de inputs de contato */}
        <XContainer
          onClick={(e) => {
            e.preventDefault();
            if (group === "contact") {
              let newArr = formState.contato;
              let removedFromArray = newArr.splice(index, 1)[0];
              let newArrAdd = formState.contatosExcluido;
              newArrAdd.push(removedFromArray);

              setFormState({
                ...formState,
                contato: newArr,
                contatosExcluido: newArrAdd,
              });
            }
            if (group === "socials") {
              let newArr = formState.redesSociais;
              let removedFromArray = newArr.splice(index, 1)[0];
              let newArrAdd = formState.redesSociaisExcluidas;
              newArrAdd.push(removedFromArray);

              setFormState({
                ...formState,
                redesSociais: newArr,
                redesSociaisExcluidas: newArrAdd,
              });
            }

            if (group === "socialsDeleted") {
              let newArrDeleted = formState.redesSociaisExcluidas;
              let removeFromArrayDeleted = newArrDeleted.splice(index, 1)[0];
              let newArrAdd = formState.redesSociais;
              newArrAdd.push(removeFromArrayDeleted);

              setFormState({
                ...formState,
                redesSociais: newArrAdd,
                redesSociaisExcluidas: newArrDeleted,
              });
            }

            if (group === "contactDeleted") {
              let newArrDeleted = formState.contatosExcluido;
              let removeFromArrayDeleted = newArrDeleted.splice(index, 1)[0];
              let newArrAdd = formState.contato;
              newArrAdd.push(removeFromArrayDeleted);

              setFormState({
                ...formState,
                contato: newArrAdd,
                contatosExcluido: newArrDeleted,
              });
            }
          }}
        >
          {group === "contactDeleted" || group === "socialsDeleted" ? (
            <IoMdAddCircle style={{ color: "#6E3296" }} />
          ) : (
            <IoMdRemoveCircle style={{ color: "#6E3296" }} />
          )}
        </XContainer>
      </InputContainer>
    </>
  );
};

export default ContactInputFieldCuration;
