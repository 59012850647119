import React from 'react';

import ButtonMenu from './ButtonMenu';

import { HiHome } from 'react-icons/hi';
import IconEstablishments from './IconEstablishments';
import IconEstablishmentBadge from './IconEstablishmentBadge';
import IconOffersBadge from './IconOffersBadge';
import IconClub from './IconClub';

import * as S from './style';

const AdminMenu = (props) => {
  const { open, setOpen } = props;

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <div
      onClick={() => {
        handleCloseDrawer();
      }}
    >
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
      <ButtonMenu
        icon={<HiHome />}
        open={open}
        to={{ pathname: '/admin/configuracoes', state: '' }}
        type="home"
      >
        Home
      </ButtonMenu>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
      <ButtonMenu
        icon={<IconEstablishments width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />}
        open={open}
        to={{ pathname: '/admin/estabelecimentos', state: '' }}
      >
        Estabelecimentos
      </ButtonMenu>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
      <ButtonMenu
        icon={<IconEstablishmentBadge width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />}
        open={open}
        to={{ pathname: '/admin/aprovar/estabelecimentos', state: '' }}
      >
        Curadoria de Estabelecimentos
      </ButtonMenu>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
      <ButtonMenu
        icon={<IconOffersBadge width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />}
        open={open}
        to={{ pathname: '/admin/aprovar/promocoes', state: '' }}
      >
        Curadoria de Promoções
      </ButtonMenu>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
      <ButtonMenu
        icon={<IconClub width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />}
        open={open}
        to={{ pathname: '/admin/seu-clube', state: '' }}
        type="club"
      >
        Clube
      </ButtonMenu>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
    </div>
  );
};

export default AdminMenu;

