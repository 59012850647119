import React, { useEffect, useState } from 'react';
// import Qrcode from "qrcode.react";
import html2canvas from 'html2canvas';

import { useTemplate, useElementQrcode } from '../Context/Marketing';

import { CircularProgress, Button } from '@mui/material';

import QRCode from 'qrcode.react';

import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

const PreviewTemplate = ({ classes }) => {
  const {
    selectedTemplate,
    listElementSelected,
    selectedFormat,
    loadingPreview,
    setLoadingPreview,
    selectedColorTemplate,
  } = useTemplate();
  const { qrcodeLink, loadingLink } = useElementQrcode();

  const [imageBackground, setImageBackground] = useState(placeholder);
  const [imageDimensions, setImageDimensions] = useState({ width: '300px', height: '300px' });

  const backgroudImgHeight = `${String(Math.floor(imageDimensions.height / 4))}px`;
  const backgroudImgWidth = `${String(Math.floor(imageDimensions.width / 4))}px`;

  const imageSize = {
    landscape: { width: '350px', height: '250px' },
    portrait: { width: '250px', height: '350px' },
    square: { width: '270px', height: '270px' },
  };

  const isLoading = loadingPreview || loadingLink;

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        getImageDimensions(base64data);
        resolve(base64data);
      };
    });
  };

  //PEGA O TAMANHO ORIGINAL DA IMAGEM DO TEMPLATE.
  function getImageDimensions(image) {
    return new Promise(() => {
      var imageFake = new Image();

      imageFake.onload = function () {
        setLoadingPreview(false);
        setImageDimensions({ width: imageFake.width, height: imageFake.height });
      };
      imageFake.src = image;
    });
  }

  useEffect(() => {
    setLoadingPreview(true);
    getBase64FromUrl(selectedTemplate.backgroundImages[selectedColorTemplate].image).then((img) => {
      setImageBackground(img);
    });
  }, [selectedTemplate, selectedColorTemplate]);

  function handleImageDownload() {
    setLoadingPreview(true);

    const { width, height } = imageDimensions;
    // SETA O VALOR REAL DA IMAGEM DO TEMPLATE.
    const imageWrapper = document.getElementById('canvas-marketing');
    imageWrapper.style.width = `${width}px`;
    imageWrapper.style.height = `${height}px`;
    imageWrapper.style.overflow = '';

    const imageBackground = document.getElementById('image-background');
    imageBackground.style.width = `${width}px`;
    imageBackground.style.height = `${height}px`;

    listElementSelected.forEach((element, index) => {
      if (element.entity === 'text') {
        const calculationBasedValue = selectedFormat === 'landscape' ? height : width;

        const textElement = document.getElementById(`text-template-${index}`);
        textElement.style.fontSize = `${(calculationBasedValue * element.fontSize) / 100}%`;
      }
    });

    html2canvas(document.querySelector('#canvas-marketing'), { width, height }).then((canvas) => {
      let img = canvas.toDataURL('image/png');

      let link = document.createElement('a');
      link.download = 'template.png';
      link.href = img;
      link.click();

      imageWrapper.style.width = `${backgroudImgWidth}`;
      imageWrapper.style.height = `${backgroudImgHeight}`;
      imageWrapper.style.overflow = 'hidden';

      imageBackground.style.width = '100%';
      imageBackground.style.height = '100%';

      setLoadingPreview(false);
    });
  }

  if (isLoading) {
    return (
      <div className={classes.boxPreview}>
        <CircularProgress style={{ margin: 'auto 0' }} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <section className={classes.boxPreview}>
        <div
          id="canvas-marketing"
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: `${imageSize[selectedFormat].width}`,
            height: `${imageSize[selectedFormat].height}`,
            fontSize: '25px',
          }}
        >
          <img
            id="image-background"
            style={{ width: '100%', height: '100%' }}
            src={imageBackground}
            alt="Preview"
          />

          {listElementSelected.map((element, key) => {
            if (element?.entity === 'text') {
              return (
                <span
                  id={`text-template-${key}`}
                  key={key}
                  style={{
                    position: 'absolute',
                    left: `${element.widthPosition}%`,
                    top: `${element.heightPosition}%`,
                    transform: `translate(${-element.widthPosition}%, ${-element.heightPosition}%)`,
                    fontSize: `${Math.floor(element.fontSize / 1.4)}px`,
                    width: `${element.widthSize}%`,
                    height: `${element.heightSize}%`,
                    fontFamily: `${element.fontStyle}`,
                    textAlign: 'center',
                    color: `${element.fontColor}`,
                  }}
                >
                  {element.text}
                </span>
              );
            } else if (element.entity === 'image') {
              return (
                <>
                  {element.image !== undefined && element.image !== '' && (
                    <img
                      key={key}
                      id="image-template"
                      src={element.image}
                      alt="Imagem preview"
                      style={{
                        position: 'absolute',
                        left: `${element.widthPosition}%`,
                        top: `${element.heightPosition}%`,
                        width: `${element.widthSize}%`,
                        transform: `translate(${-element.widthPosition}%, ${-element.heightPosition}%)`,
                      }}
                    />
                  )}
                </>
              );
            } else if (element.entity === 'qrCode') {
              return (
                <QRCode
                  id="qrcode-image"
                  size={imageDimensions.height}
                  key={key}
                  style={{
                    position: 'absolute',
                    left: `${element.widthPosition}%`,
                    top: `${element.heightPosition}%`,
                    transform: `translate(${-element.widthPosition}%, ${-element.heightPosition}%)`,
                    width: `${element.widthSize}%`,
                    height: 'none',
                    display: 'block',
                  }}
                  level="L"
                  value={qrcodeLink}
                />
              );
            }
          })}
        </div>
      </section>

      <div style={{ width: '250px', marginTop: '20px' }}>
        <Button
          disabled={isLoading}
          onClick={handleImageDownload}
          type="button"
          variant="outlined"
          color="primary"
          style={{
            width: '100%',
          }}
        >
          Baixar material
        </Button>
      </div>
    </div>
  );
};

export default PreviewTemplate;

