import React from 'react';

import * as S from './style';

const ArrowButton = ({ open, setOpen }) => {
  return (
    <>
      <S.ArrowButtonDiv>
        <S.StickyArrow>
          <S.ArrowCircle
            onClick={() => {
              setOpen(!open);
            }}
          >
            <S.BsArrow open={open} />
          </S.ArrowCircle>
        </S.StickyArrow>
      </S.ArrowButtonDiv>
    </>
  );
};

export default ArrowButton;
