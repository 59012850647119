import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  padding: 1rem;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    padding-left: 0;
  }
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const ContainerVoucher = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  max-width: 700px;
  width: 100%;
`;

export const SubTitle = styled.p`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-left: 0;

  @media (max-width: 440px) {
    font-size: 14px;
    padding: 1rem;
  }
`;

export const FileInstructions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-left: 0;
  flex-direction: column;
  gap: 1rem;
`;

export const ErrorMessage = styled.p`
  color: red;
  padding-left: 0;
  margin: 0;
  font-size: 12px;
`;
