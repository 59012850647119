import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  TextField,
  Typography,
  Snackbar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import Loading from "js/containers/Loading/Loading";
import {
  removeDatabase,
  createDatabase,
} from "js/library/services/DatabaseManager.js";
import { getDataListRestApi } from "js/library/utils/API/restApiFirebase";
import { GoBackHeader } from "./CreateClub/RelationshipClub/GoBackHeader";
import { HeaderTitle } from "./CreateClub/RelationshipClub/HeaderTitle";

const Stamping = (props) => {
  const [carimbo, setCarimbo] = useState("");
  const [tag, setTag] = useState("");
  const [stampList, setStampList] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    getDataListRestApi("template-carimba-users").then((dataReceived) => {
      let data = [];

      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map((item) => {
          dataReceived[item].key = item;
          return data.push(dataReceived[item]);
        });
      }
      setStampList(data);
    });

    window.pathname = null;
  }, []);
//altera os states dos campos, caso o usuário insira informações nos input
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
  };

  const addStamp = (carimbo, tag) => {
    let validation = 0;

    if (stampList !== null && stampList !== undefined) {
      stampList.forEach((item) => {
        validation =
          item.carimbo === carimbo && item.tag === tag ? validation + 1 : validation;
      });
    }

    if (validation === 0) {
      const stampInfo = {
        carimbo,
        tag,
      };

      createDatabase("template-carimba-users", stampInfo, null).then(() => {
        setCarimbo("");
        setTag("");
        setStampList(null);

        getDataListRestApi("template-carimba-users").then((dataReceived) => {
          let data = [];
          Object.keys(dataReceived).map((item) => {
            dataReceived[item].key = item;
            return data.push(dataReceived[item]);
          });
          setStampList(data);
        });
      });
    } else {
      setOpenSnackbar(true);
    }
  };

  const removeStamp = (key) => {
    removeDatabase("template-carimba-users", key).then(() => {
      setCarimbo("");
      setTag("");
      setStampList(null);

      getDataListRestApi("template-carimba-users").then((dataReceived) => {
        let data = [];
        if (dataReceived !== null && dataReceived !== undefined) {
          Object.keys(dataReceived).map((item) => {
            dataReceived[item].key = item;
            return data.push(dataReceived[item]);
          });
        } else {
          return null;
        }
        setStampList(data);
      });
    });
  };

  if (props.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  } else {
    if (window.pathname !== null) {
      return <Redirect to={window.pathname} />;
    } else {
      return (
        <div style={{ padding: "4rem 32px" }}>
          <GoBackHeader />
          <HeaderTitle pageInfo="Cadastro de carimbos" />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addStamp(carimbo, tag);
            }}
          >
            <TextField
              style={{ width: "250px" }}
              id="carimbo"
              label="Carimbo"
              placeholder="Keyword que usuário receberá"
              value={carimbo}
              onChange={handleChange(setCarimbo)}
              margin="normal"
            />

            <TextField
              style={{ marginLeft: "10px", width: "250px" }}
              id="tag"
              label="Tag"
              placeholder="Tag cadastrada na promoção"
              value={tag}
              onChange={handleChange(setTag)}
              margin="normal"
            />

            <Button
              type="submit"
              disabled={carimbo.length === 0 || tag.length === 0}
              style={{
                fontWeight: "550",
                color: "white",
                marginLeft: "10px",
                marginTop: "25px",
                textTransform: "capitalize",
              }}
              variant="contained"
              color="primary"
            >
              Adicionar
            </Button>
          </form>

          <Grid container spacing={5}>
            <Grid item md={6} sm={12}>
              {stampList === false ? (
                <div align="center">
                  <CircularProgress style={{ padding: "20px" }} />
                </div>
              ) : stampList === null ? (
                <div>
                  <br />
                  <br />
                  <Typography variant="body2">
                    Nenhum carimbo cadastrado.
                  </Typography>
                </div>
              ) : (
                <List>
                  {stampList.map((item) => (
                    <ListItem key={item.key} role={undefined} dense button>
                      <ListItemText primary={`${item.carimbo} | ${item.tag}`} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeStamp(item.key)}
                          edge="end"
                          aria-label="Comments"
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            onClose={handleCloseAlert}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">Carimbo já existe!</span>}
          />
        </div>
      );
    }
  }
};

export default Stamping;

