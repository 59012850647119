import axios from 'axios';

import { getUserInfo } from 'js/library/utils/helpers.js';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import cfac22 from '../../cfac22';

export function insertUserOrgs(uId, orgID) {
  const loggedUid = getUserInfo().uId;

  return new Promise(function async(resolve, reject) {
    getChannelToken_v1(loggedUid)
      .then((channelTokenId) => {
        axios
          .post(cfac22('API_HOST_V1') + 'insertUserOrgs', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: loggedUid,
              channelTokenId: channelTokenId,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            uId,
            orgID,
          })
          .then(async (result) => {
            // console.log("OLA", result);
            return resolve(result.data);
          })
          .catch((error) => {
            // console.log("ERROR2-> ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log("ERROR1-> ", error);
        return reject(error);
      });
  });
}
