import React from 'react';
// import { Link } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';

const InitialMessage = (props) => {
    const { setShowInitial } = props;

    const handleClick = () => {
        setShowInitial(true);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid
                    container
                    item
                    xs={10}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={3}
                    sx={
                        {
                            // minHeight: "50vh",
                        }
                    }>
                    <Typography
                        variant="h4"
                        align="center"
                        color="#383838"
                        display="block"
                        sx={{ fontFamily: 'Source Sans Pro' }}>
                        <strong>Bem-vindo a interface Triibo</strong>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="#383838"
                        sx={{ fontFamily: 'Source Sans Pro' }}>
            Aqui você pode gerenciar seus negócios de forma{' '}
                        <strong>simples e rápida</strong>
                    </Typography>
                    {/* <Link
                        to={{ pathname: '/estabelecimento/novo/adicionar', state: '' }}
                        style={{ textDecoration: 'none' }}
                    > */}
                    <Button
                        onClick={handleClick}
                        variant="contained"
                        disableElevation
                        sx={{
                            minWidth: '10rem',
                            backgroundColor: '#08BAD0',
                            borderRadius: '0.6rem',
                            fontFamily: 'Source Sans Pro',
                            fontWeight: 'Semibold',
                            fontSize: '1rem',
                            textTransform: 'none',
                        }}>
            Começar
                    </Button>
                    {/* </Link> */}
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    );
};

export default InitialMessage;
