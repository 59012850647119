import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import DropDown from 'js/components/DesignSystem/DropDown';
import { Input } from '../styles';
import ModalPromotion from './ModalPromotion';
import axios from 'axios';

import { apiGetAppActions } from 'js/library/utils/API/Communication/apiAppActions';
import itemIDList from './placeholderAppScreenItemID.json';
import { handleModeUrl } from 'js/library/utils/helpers';
import cfac22 from 'js/library/utils/cfac22';

function getObjectByKey(id, array, key) {
  return array.find((obj) => obj[key] === id);
}

function extractValuesByKey(arrayOfObjects, key) {
  return arrayOfObjects.map((obj) => obj[key]);
}

function checkKeysForSubstring(obj) {
  let hasSubstring = false;
  const keysWithSubstring = [];

  function searchKeys(object, parentKey = '') {
    for (let key in object) {
      const value = object[key];
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === 'string' && value.includes('{') && value.includes('}')) {
        hasSubstring = true;
        keysWithSubstring.push(currentKey);
      }

      if (typeof value === 'object' && value !== null) {
        searchKeys(value, currentKey);
      }
    }
  }

  searchKeys(obj);

  return { hasSubstring, keysWithSubstring };
}

export default function ActionsButtons({
  formCommunication,
  setFormCommunication,
  selectedOptionActionButton,
  setSelectedOptionActionButton,
  senderType,
}) {
  const [isSelectedActionButtonOpen, setIsSelectedActionButtonOpen] = useState(
    selectedOptionActionButton ? true : false
  );
  const [promotion, setPromotion] = useState('');
  const [tag, setTag] = useState('');
  const [query, setQuery] = useState('');
  const [isModalPromotionOpen, setIsModalPromotionOpen] = useState(false);
  let establishmentList = useSelector((state) => state.authModel.establishmentList);
  const currentEst = useSelector((state) => state.currentEstModel.currentEst);
  const [actionOptions, setActionOptions] = useState([]);
  const [custom, setCustom] = useState('');
  const [isAppDropdownOpen, setIsAppDropdownOpen] = useState(false);
  const [selectedAppAction, setSelectedAppAction] = useState('');

  const handleCloseModalPromotion = () => {
    setIsModalPromotionOpen(false);
  };

  const handleBlurUrl = (e) => {
    // Verifica se há uma URL válida no campo
    if (e.target.value) {
      // Formata a URL usando a função handleModeUrl
      const formattedUrl = handleModeUrl(e.target.value);
      // Atualiza o estado do formulário com a URL formatada
      let newObj = cloneDeep(formCommunication);
      newObj.appFilter = {
        itemId: '',
        query: '',
        url: formattedUrl,
      };
      setFormCommunication(newObj);
    }
  };

  const handleGenerateLink = (type, value) => {
    const dynamicLinkKey = cfac22('DYNAMIC_LINK_KEY');
    const dynamicLinkApi = `${cfac22('DYNAMIC_LINK_API')}${dynamicLinkKey}`;
    const dynamicLinkDomain = cfac22('DYNAMIC_LINK_DOMAIN');
    const androidPackageName = cfac22('ANDROID_PACKAGE_NAME');
    const iosPackageName = cfac22('IOS_PACKAGE_NAME');
    const iosStoreId = cfac22('IOS_STORE_ID');
    const baseLink = cfac22('BASE_LINK');

    let params = '';

    if (type === 'establishment') {
      params = `app/estabelecimento/?id=${value}`;
    }

    if (type === 'promotion') {
      params = `app/oferta/?id=${value}`;
    }

    const finalLink = baseLink + params;

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain: dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName: androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    return new Promise(function async(resolve, reject) {
      axios.post(dynamicLinkApi, linkRequest).then((result) => resolve(result.data.shortLink));
    });
  };

  useEffect(() => {
    if (selectedOptionActionButton === 'Enviar para link externo') {
      let cloneForm = cloneDeep(formCommunication);

      cloneForm.appFilter = { itemId: '', query: '', url: '' };
      cloneForm.action.type = 'browser';
      setFormCommunication(cloneForm);
    }

    if (selectedOptionActionButton === 'Abrir uma promoção/produto') {
      let cloneForm = cloneDeep(formCommunication);

      cloneForm.action.type = 'promotion_item';
      setFormCommunication(cloneForm);
      setIsModalPromotionOpen(true);
      return;
    }

    if (selectedOptionActionButton === 'Acessar perfil do Negócio') {
      let cloneForm = cloneDeep(formCommunication);
      cloneForm.action.type = 'establishment_item';

      let establishmentId = '';

      if (Object.keys(currentEst).length !== 0) {
        establishmentId = currentEst.id;
      } else {
        establishmentId = establishmentList[0].id;
      }

      handleGenerateLink('establishment', establishmentId).then((res) => {
        cloneForm.appFilter.itemId = res;
        cloneForm.appFilter.query = '';
        cloneForm.appFilter.url = '';
        setFormCommunication(cloneForm);
      });
    }

    const obj = getObjectByKey(selectedOptionActionButton, actionOptions, 'actionLabel');

    let cloneForm = cloneDeep(formCommunication);

    if (obj) {
      cloneForm.action = {
        label: obj.buttonLabel ? obj.buttonLabel : 'Ver mais',
        type: '',
      };
      cloneForm.appFilter = {
        itemId: '',
        query: '',
        url: '',
      };
      setCustom('');

      if (obj.hasOwnProperty('buttonLabel')) {
        cloneForm.action.label = obj.buttonLabel;
      }

      if (obj.hasOwnProperty('actionSubtype')) {
        cloneForm.action.type = obj.actionSubtype;
      }

      if (obj.hasOwnProperty('actionSubtype')) {
        cloneForm.action.type = obj.actionSubtype;
      }

      if (obj.hasOwnProperty('itemId')) {
        cloneForm.appFilter.itemId = obj.itemId;
      }

      if (obj.hasOwnProperty('query')) {
        cloneForm.appFilter.query = obj.query;
      }

      if (obj.hasOwnProperty('url')) {
        cloneForm.appFilter.url = obj.url;
      }

      if (obj.hasOwnProperty('app_screen')) {
        setCustom('app_screen');
      }

      const hasSubstring = checkKeysForSubstring(cloneForm);
      if (hasSubstring.hasSubstring) {
        setCustom(
          hasSubstring.keysWithSubstring[0].slice(
            hasSubstring.keysWithSubstring[0].indexOf('.') + 1
          )
        );

        if (cloneForm.appFilter.url === '{url}') {
          cloneForm.appFilter.url = '';
        }
      }
      setFormCommunication(cloneForm);
    }
  }, [selectedOptionActionButton]);

  useEffect(() => {
    if (selectedAppAction) {
      let cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter.itemId = getObjectByKey(selectedAppAction, itemIDList, 'label').value;
      setFormCommunication(cloneForm);
    }
  }, [selectedAppAction]);

  useEffect(() => {
    if (!!promotion) {
      let cloneForm = cloneDeep(formCommunication);

      cloneForm.appFilter.itemId = Array.from(promotion)[0];
      setFormCommunication(cloneForm);

      // handleGenerateLink('promotion', Array.from(promotion)[0]).then((res) => {
      //   cloneForm.actionButton.promotion = res;
      //   cloneForm.actionButton.link = '';
      //   cloneForm.actionButton.establishment = '';
      //   setFormCommunication(cloneForm);
      // });
    }
  }, [promotion]);

  useEffect(() => {
    if (custom === 'itemId') {
      setIsModalPromotionOpen(true);
    }
  }, [custom]);

  useEffect(() => {
    let newObj = cloneDeep(formCommunication);
    if (custom === 'query') {
      newObj.appFilter = {
        itemId: '',
        query: query,
        url: '',
      };
    }
    setFormCommunication(newObj);
  }, [query]);

  useEffect(() => {
    setQuery(`[(description:LIKE:${tag})]`);
  }, [tag]);

  useEffect(() => {
    apiGetAppActions(senderType).then((res) => {
      setActionOptions(res);
    });
  }, []);

  return (
    <>
      <S.Container>
        <div>
          <D.TriiboH4>
            <b>Botão de Ação</b>
          </D.TriiboH4>
          <D.TriiboH5>Defina a ação que será realizada ao clicar no Botão de Ação.</D.TriiboH5>
        </div>
        <S.ContentActionContainer>
          <div style={{ flex: '1', width: '100%' }}>
            <DropDown
              height={'48px'}
              width={'100%'}
              top={'3.5rem'}
              selectedOption={selectedOptionActionButton}
              setSelectedOption={setSelectedOptionActionButton}
              isSelectOpen={isSelectedActionButtonOpen}
              setIsSelectOpen={setIsSelectedActionButtonOpen}
              data={extractValuesByKey(actionOptions, 'actionLabel')}
              // data={actionButton}
            />
          </div>

          <S.ContentAction
            visible={
              Array.from(promotion).length === 1 &&
              selectedOptionActionButton === 'Abrir uma promoção/produto'
            }
          >
            <D.TriiboH5 style={{ textAlign: 'center' }}>
              <strong>Promoção selecionada</strong>
            </D.TriiboH5>
            <S.EditInfoButton onClick={() => setIsModalPromotionOpen(true)}>
              clique para editar
            </S.EditInfoButton>
          </S.ContentAction>
        </S.ContentActionContainer>
        {custom === 'url' && (
          <>
            <D.TriiboH4>
              <b>Endereço URL de destino</b>
            </D.TriiboH4>
            <Input
              placeholder="Digite a URL"
              value={formCommunication.appFilter ? formCommunication.appFilter.url : ''}
              onChange={(e) => {
                e.preventDefault();
                let newObj = cloneDeep(formCommunication);
                newObj.appFilter = {
                  itemId: '',
                  query: '',
                  url: e.target.value,
                };
                setFormCommunication(newObj);
              }}
              onBlur={handleBlurUrl}
            />
          </>
        )}
        {custom === 'query' && (
          <>
            <D.TriiboTextField
              rows={'1'}
              value={tag}
              placeholder={'Insira a tag da query'}
              onChange={(e) => {
                e.preventDefault();
                setTag(e.target.value);
              }}
            />
          </>
        )}
        {custom === 'app_screen' && (
          <>
            <DropDown
              height={'48px'}
              width={'100%'}
              top={'3.5rem'}
              selectedOption={selectedAppAction}
              setSelectedOption={setSelectedAppAction}
              isSelectOpen={isAppDropdownOpen}
              setIsSelectOpen={setIsAppDropdownOpen}
              data={extractValuesByKey(itemIDList, 'label')}
            />
          </>
        )}
      </S.Container>

      <ModalPromotion
        handleCloseModal={handleCloseModalPromotion}
        isModalPromotionOpen={isModalPromotionOpen}
        setPromotion={setPromotion}
        promotion={promotion}
      />
    </>
  );
}

