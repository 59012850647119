import React, { useEffect, useState } from 'react';


import { Modal } from "@mui/material";
import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";

import { getClientsEstablishment } from "js/library/utils/API/apiGetClients";
import InputSearch from "js/components/TriiboComponents/InputSearch";
import MultipleSelectTable from "js/components/TriiboComponents/Tables/MultipleSelectTable";

export default function ModalSelectClients({
  modalSelectClientsIsOpen,
  closeModalSelectClients,
  toggleSendVoucherModal,
  location,
}) {
  const [clients, setClients] = useState([]);
  const [checkedByCellPhone, setCheckedByCellPhone] = useState(new Set());
  const [inputSearchClients, setInputSearchClients] = useState('');

  useEffect(() => {
    async function getClientList() {
      const url = window.location.href;
      const establishmentId = url.split('/').find((e) => e.startsWith('-'));

      const response = await getClientsEstablishment(establishmentId);

      const clientesFiltradosSemNull = response.registered.filter(
        (e) => e !== null
      );

      const newClintList = (arr) => {
        return arr.reduce((prev, next) => {
          let newCurrent = { ...next };

          newCurrent.checkedId =
            next.contactList ? next.contactList[0].value + "@sms,triibo,com,br" : null;
          newCurrent.cellPhone = next.contactList ? next.contactList[0].value : null;

          prev.push(newCurrent);

          return prev;
        }, []);
      };

      setClients(newClintList(clientesFiltradosSemNull));
    }

    getClientList();
  }, []);

  const handleChangeInputSearchCell = (event) => {
    setInputSearchClients(event.target.value);
  };

  const handleSubmit = () => {
    localStorage.setItem(
      'ClientsSelect',
      JSON.stringify({
        clients: Array.from(checkedByCellPhone),
        location: { ...location },
      })
    );
    // console.log(location);
    closeModalSelectClients();
    toggleSendVoucherModal(true);
  };

  const filteredClients = inputSearchClients
    ? clients.filter((client) => {
        return (
          client.contactList[0].value
            .toLowerCase()
            .includes(inputSearchClients.toLowerCase()) ||
          client.name.toLowerCase().includes(inputSearchClients.toLowerCase())
        );
      })
    : clients;

  const column = [
    { heading: 'Nome do usuário', value: 'name' },
    { heading: 'Telefone', value: 'cellPhone' },
  ];

  return (
    <Modal open={modalSelectClientsIsOpen} onClose={closeModalSelectClients}>
      <S.Container>
        <S.Header>
          <D.TriiboH4>
            <strong>Selecionar usuários</strong>
          </D.TriiboH4>
          <D.TriiboH4>
            Selecione um ou mais usuários que receberão seu cupom.
          </D.TriiboH4>
        </S.Header>

        <S.BoxSearch>
          <InputSearch
            value={inputSearchClients}
            onChange={handleChangeInputSearchCell}
            disabled={false}
            placeholder='Nome do usuário ou número de telefone'
          />
        </S.BoxSearch>

        <MultipleSelectTable
          data={filteredClients}
          column={column}
          checkedById={checkedByCellPhone}
          setCheckedById={setCheckedByCellPhone}
          perPage={10}
          resetValue={false}
        />

        <S.Buttons>
          <S.Button
            bg="#ffffff"
            color="#328796"
            border="#328796"
            onClick={() => {
              closeModalSelectClients();
              toggleSendVoucherModal(true);
            }}>
            Voltar
          </S.Button>
          <S.Button onClick={handleSubmit}>Confirmar</S.Button>
        </S.Buttons>
      </S.Container>
    </Modal>
  );
}
