import React, { useEffect, useState } from 'react';

//Styles
import { LoadingTableClube } from 'js/components/Clubs/clubeTableStyles';
import { Button, CircularProgress } from '@mui/material';
import moreCards from '../../../../../styles/assets/moreCard.svg';
import saveImg from 'styles/assets/save.png';
import EditIcon from '@mui/icons-material/Edit';
import SwapVertIcon from '@mui/icons-material/SwapVert';

//Libs
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//Components
import { ModalNewCard } from './ModalNewCard';
import { TooltipEnvolveElement } from '../Tooltips';

//Apis
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { updateCard } from 'js/library/utils/API/apiCards';
import { toast } from 'react-toastify';
import {
  ButtonMoveCards,
  CardDefault,
  Cards,
  ContainerCards,
  ContainerDragDrop,
  ContainerNoHaveCard,
  ContentDragDrop,
  ContentHeaderDragDrop,
  EditCardButton,
  FlutuantButton,
} from './CardManagementStyles';
import { getActiveCardByOrg, getInactiveCardByOrg } from 'js/library/utils/API/Org/apiGetOrgById';
import cfac22 from 'js/library/utils/cfac22';

export function DragDrop() {
  const clubeId = document.location.pathname.split('/')[4];
  const isMobile = window.screen.width <= 1357;
  const [openModal, setOpenModal] = useState(false);
  const [cardsClub, setCardsClub] = useState([]);
  const [disabledDraggableCards, setDisabledDraggableCards] = useState(true);
  const [infoCardsForEdit, setInfoCardsForEdit] = useState({});
  const [isNewCard, setIsNewCard] = useState(false);
  const [seeInactive, setSeeInactive] = useState(false);
  const [cardsInactive, setCardsInactive] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [seuClube, setSeuClube] = useState();
  const [orderInitialized, setOrderInitialized] = useState('');
  const [isSaveOrder, setIsSaveOrder] = useState(false);

  function handleCloseModal() {
    if (loading) {
      return;
    }
    setInfoCardsForEdit({});
    setOpenModal(false);
  }

  //Pega a org do clube e trás os cards ativos e inativos.
  async function getInfosCards() {
    setLoadingInfo(true);
    // O setTimeout foi usado pois o realtime demora a passar as informações para o elastic aonde as apis fazem a consulta.
    setTimeout(async () => {
      const data = await getSeuClube(clubeId);

      const dataCardsActives = await getActiveCardByOrg(data.orgs[0], 0, 100);
      const dataCardsInactives = await getInactiveCardByOrg(data.orgs[0], 0, 100);

      setSeuClube(data);

      if (dataCardsActives.success) {
        setCardsClub(dataCardsActives.result);
        setLoadingInfo(false);
      } else {
        setCardsClub([]);
        setLoadingInfo(false);
      }

      if (dataCardsInactives.success) {
        setCardsInactive(dataCardsInactives.result);
        setLoadingInfo(false);
      } else {
        setCardsInactive([]);
        setLoadingInfo(false);
      }
    }, 2000);
  }

  useEffect(() => {
    getInfosCards();
  }, [loading]);

  //useeffect que faz com que a lista de cards ativos seja recarregada após salvar a ordem dos cards
  useEffect(() => {
    if (isSaveOrder) {
      getInfosCards();
    }
  }, [isSaveOrder]);

  // Função que mantem os cards em suas novas posições.
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(cardsClub);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCardsClub(items);
  }

  // Salva os cards na posição do index enviando informação para API
  async function saveCardsOrder() {
    if (cardsClub.length > 0) {
      cardsClub.map((item, index) => {
        let cardId = item.cardId;
        let data = {
          priority: index,
        };
        updateCard(cardId, data);
        return setIsSaveOrder(true);
      });
      toast.success('Cards salvo com sucesso.');
      setDisabledDraggableCards(!disabledDraggableCards);
    }
  }

  // Verifica se houve mudanças nas ordens dos cards;
  function verifyObject(obj1, obj2) {
    return obj1.cardId === obj2.cardId;
  }

  function verifyOrder(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    // Verificar se algum objeto mudou de posição
    for (let i = 0; i < array1.length; i++) {
      // Comparar os objetos em cada posição
      if (!verifyObject(array1[i], array2[i])) {
        return true; // Objeto diferente encontrado, posição mudou
      }
    }

    return false; // Nenhum objeto mudou de posição
  }

  //Switch ativa ou desativa mover cards
  function handleMoveCards() {
    setDisabledDraggableCards(!disabledDraggableCards);
    if (disabledDraggableCards) {
      const order = cardsClub.map((item) => ({ ...item }));
      setOrderInitialized(order);
      setIsSaveOrder(false);
    } else {
      const orderChanged = verifyOrder(orderInitialized, cardsClub);
      if (orderChanged && !isSaveOrder) {
        setCardsClub(orderInitialized);
      }
    }
    switch (disabledDraggableCards) {
      case true:
        toast.info(
          'Modo ORGANIZAÇÃO, agora você pode mover seus cards. Não esqueça de clicar no botão de SALVAR ao finalizar suas mudanças.'
        );
        break;
      case false:
        toast.info('Modo EDIÇÃO, agora você pode editar seus cards.');
        break;
    }
  }

  //Mostrar cards ativos e inativos
  function ViewActiveInactiveCards(e) {
    e.preventDefault();
    if (!seeInactive) {
      setSeeInactive(true);
    } else {
      setSeeInactive(false);
    }
  }

  //Passa a info do card quer será editado para o modal
  function EditInfosCard(card) {
    let dataActive = cardsClub[card];
    let dataInactive = cardsInactive[card];

    if (!seeInactive) {
      const {
        action,
        appFilter: { query },
      } = dataActive;
      if (action && query) {
        if (query.includes('[(endereco.keyword:DIFF:Brasil)]') && action === 'promotion_list') {
          dataActive.label = 'Promoções próximas';
        } else if (
          query.includes('[(endereco.keyword:DIFF:Brasil)]') &&
          action === 'establishment_list'
        ) {
          dataActive.label = 'Estabelecimento próximo';
        } else if (query.includes('[(tags:LIKE:Awin)]') && action === 'promotion_list') {
          dataActive.label = 'Cashback completo';
        } else if (query.includes('[(tags' || '[(description') && action === 'promotion_list') {
          dataActive.label = 'Promoções selecionadas (por # ou TAG)';
        } else {
          dataActive.label = 'Estabelecimentos selecionados (por # ou TAG)';
        }
      }
      setInfoCardsForEdit(dataActive);
    } else {
      const {
        action,
        appFilter: { query },
      } = dataInactive;
      if (action && query) {
        if (query.includes('[(endereco.keyword:DIFF:Brasil)]') && action === 'promotion_list') {
          dataInactive.label = 'Promoções próximas';
        } else if (
          query.includes('[(endereco.keyword:DIFF:Brasil)]') &&
          action === 'establishment_list'
        ) {
          dataInactive.label = 'Estabelecimento próximo';
        } else if (query.includes('[(tags:LIKE:Awin)]') && action === 'promotion_list') {
          dataInactive.label = 'Cashback completo';
        } else if (query.includes('[(tags' || '[(description') && action === 'promotion_list') {
          dataInactive.label = 'Promoções selecionadas (por # ou TAG)';
        } else {
          dataInactive.label = 'Estabelecimentos selecionados (por # ou TAG)';
        }
      }
      setInfoCardsForEdit(dataInactive);
    }
    setIsNewCard(false);
    setOpenModal(true);
  }

  function newCard() {
    setIsNewCard(true);

    setOpenModal(true);
  }

  if (loadingInfo) {
    return (
      <LoadingTableClube style={{ height: '40vh' }}>
        <CircularProgress size="100px" />
      </LoadingTableClube>
    );
  }
  return (
    <ContainerDragDrop>
      {openModal && (
        <ModalNewCard
          close={handleCloseModal}
          openModal={openModal}
          arr={cardsClub}
          infosForEdit={infoCardsForEdit}
          isNewCard={isNewCard}
          setUpdateData={setUpdateData}
          updateData={updateData}
          loading={loading}
          seeInactive={seeInactive}
          setLoading={setLoading}
          getInfosCards={getInfosCards}
          data={seuClube}
          setCardsClub={setCardsClub}
        />
      )}
      <ContentDragDrop>
        <ContentHeaderDragDrop>
          <Button variant="contained" onClick={(e) => ViewActiveInactiveCards(e)}>
            {seeInactive ? 'ver cards ativos' : 'ver cards inativos'}
          </Button>
          {!seeInactive && (
            <TooltipEnvolveElement
              title="Ordenar cards"
              description="Ativa/desativa o modo de organização de cards, defina a ordem dos seus cards."
              label="CardOrder"
              larg="3.2rem"
            >
              <ButtonMoveCards onClick={handleMoveCards}>
                {disabledDraggableCards ? (
                  <SwapVertIcon fontSize="large" />
                ) : (
                  <EditIcon fontSize="large" />
                )}
              </ButtonMoveCards>
            </TooltipEnvolveElement>
          )}
        </ContentHeaderDragDrop>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {disabledDraggableCards && !seeInactive ? (
                  <CardDefault>
                    <div>
                      <img onClick={newCard} src={moreCards} alt="mais" />
                    </div>
                  </CardDefault>
                ) : (
                  <div style={{ display: 'grid', margin: '20px auto' }}></div>
                )}
                {seeInactive && cardsInactive.length !== 0 ? (
                  cardsInactive.map((value, index) => {
                    let image = value.images.web;

                    if (image.includes('/')) {
                      image = image.split('/')[1];
                    }
                    return (
                      <Draggable
                        key={value.cardId}
                        draggableId={value.cardId}
                        index={index}
                        isDragDisabled={disabledDraggableCards}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ContainerCards
                              className="characters-thumb"
                              cursor={disabledDraggableCards}
                            >
                              <Cards
                                src={cfac22('STORAGE_URL') + 'cards%2F' + image + '?alt=media'}
                                alt={`${value.title} Thumb`}
                              />{' '}
                              <EditCardButton
                                onClick={disabledDraggableCards ? () => EditInfosCard(index) : null}
                              >
                                {disabledDraggableCards ? <EditIcon /> : <SwapVertIcon />}
                              </EditCardButton>
                            </ContainerCards>{' '}
                          </li>
                        )}
                      </Draggable>
                    );
                  })
                ) : seeInactive && cardsInactive.length === 0 ? (
                  <ContainerNoHaveCard>
                    <p>Você ainda não possui nenhum card Inativo</p>
                  </ContainerNoHaveCard>
                ) : !seeInactive && cardsClub.length !== 0 ? (
                  //quando for ordenar o sort nao pode existir e quando for editar o sort tem que existir
                  !disabledDraggableCards ? (
                    cardsClub.map((value, index) => {
                      let image = value.images.web;

                      if (image.includes('/')) {
                        image = image.split('/')[1];
                      }

                      return (
                        <Draggable
                          key={value.cardId}
                          draggableId={value.cardId}
                          index={index}
                          isDragDisabled={disabledDraggableCards}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ContainerCards
                                className="characters-thumb"
                                cursor={disabledDraggableCards}
                              >
                                <Cards
                                  src={cfac22('STORAGE_URL') + 'cards%2F' + image + '?alt=media'}
                                  alt={`${value.title} Thumb`}
                                />{' '}
                                <EditCardButton
                                  onClick={
                                    disabledDraggableCards ? () => EditInfosCard(index) : null
                                  }
                                >
                                  {disabledDraggableCards ? <EditIcon /> : <SwapVertIcon />}
                                </EditCardButton>
                              </ContainerCards>{' '}
                            </li>
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                    cardsClub
                      .sort((a, b) => a.priority - b.priority)
                      .map((value, index) => {
                        let image = value.images.web;

                        if (image.includes('/')) {
                          image = image.split('/')[1];
                        }

                        return (
                          <Draggable
                            key={value.cardId}
                            draggableId={value.cardId}
                            index={index}
                            isDragDisabled={disabledDraggableCards}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ContainerCards
                                  className="characters-thumb"
                                  cursor={disabledDraggableCards}
                                >
                                  <Cards
                                    src={cfac22('STORAGE_URL') + 'cards%2F' + image + '?alt=media'}
                                    alt={`${value.title} Thumb`}
                                  />{' '}
                                  <EditCardButton
                                    onClick={
                                      disabledDraggableCards ? () => EditInfosCard(index) : null
                                    }
                                  >
                                    {disabledDraggableCards ? <EditIcon /> : <SwapVertIcon />}
                                  </EditCardButton>
                                </ContainerCards>{' '}
                              </li>
                            )}
                          </Draggable>
                        );
                      })
                  )
                ) : (
                  <ContainerNoHaveCard>
                    <p>
                      Você ainda não possui nenhum card... clique no <span>+</span> acima para
                      criar.
                    </p>
                  </ContainerNoHaveCard>
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </ContentDragDrop>
      <FlutuantButton movemode={!disabledDraggableCards} onClick={saveCardsOrder}>
        {isMobile ? (
          <span>
            {' '}
            <img alt="Icone de salvar" src={saveImg} />
            Salvar
          </span>
        ) : (
          'Salvar'
        )}
      </FlutuantButton>
    </ContainerDragDrop>
  );
}
