import React, { useState, useEffect } from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { BsXCircleFill } from 'react-icons/bs';
import { MdArrowDropDown } from 'react-icons/md';

import { ClickAwayListener } from '@mui/base';

import { establishmentIcons } from 'js/library/utils/helpers';

import PurpleButton from './PurpleButton';

const LinkField = (props) => {
  const { newLinks, setNewLinks, index } = props;

  const [imagePickerStatus, setImagePickerStatus] = useState(false);
  const [displayIcon, setDisplayIcon] = useState(establishmentIcons[0].icon);

  const handleTitleChange = (e) => {
    e.preventDefault();
    let newArr = [...newLinks];
    newArr[index].title = e.target.value;
    setNewLinks(newArr);
  };

  const handleLinkChange = (e) => {
    e.preventDefault();
    let newArr = [...newLinks];
    newArr[index].link = e.target.value;
    setNewLinks(newArr);
  };

  useEffect(() => {
    for (let i = 0; i < establishmentIcons.length; i++) {
      if (newLinks[index].icon === establishmentIcons[i].name) {
        setDisplayIcon(establishmentIcons[i].icon);
      }
    }
  }, [newLinks]);

  return (
    <>
      <S.LinkFieldContent>
        <ClickAwayListener
          onClickAway={(e) => {
            e.preventDefault();
            setImagePickerStatus(false);
          }}
        >
          <S.DropdownZone
            onClick={(e) => {
              e.preventDefault();
              setImagePickerStatus(!imagePickerStatus);
            }}
          >
            <S.DropdownContainer show={imagePickerStatus}>
              <S.ButtonGrid>
                {establishmentIcons.map(({ icon, name }, i) => (
                  <PurpleButton
                    icon={icon}
                    name={name}
                    index={index}
                    newLinks={newLinks}
                    setNewLinks={setNewLinks}
                  />
                ))}
              </S.ButtonGrid>
            </S.DropdownContainer>
            <S.DropdownButton>
              <img alt="Icone Display" src={displayIcon} style={{ width: '2.3rem' }} />
            </S.DropdownButton>
            <MdArrowDropDown />
          </S.DropdownZone>
        </ClickAwayListener>

        <S.LinkFieldGroup>
          <S.LinkFieldLabel>Nome*</S.LinkFieldLabel>
          <D.TriiboTextField
            rows={1}
            placeholder={'Nome*'}
            value={newLinks[index].title}
            onChange={(e) => {
              handleTitleChange(e);
            }}
          />
        </S.LinkFieldGroup>
        <S.LinkFieldGroup>
          <S.LinkFieldLabel>Link*</S.LinkFieldLabel>
          <D.TriiboTextField
            rows={1}
            placeholder={'Link*'}
            value={newLinks[index].link}
            onChange={(e) => {
              handleLinkChange(e);
            }}
          />
        </S.LinkFieldGroup>
        <S.DeleteButton
          onClick={(e) => {
            e.preventDefault(e);
            let newArr = [...newLinks];
            setNewLinks(newArr);
          }}
        >
          <BsXCircleFill style={{ fontSize: '1.3rem' }} />
        </S.DeleteButton>
      </S.LinkFieldContent>
    </>
  );
};

export default LinkField;

