import React, { useEffect, useState } from 'react';
import ButtonMenu from './ButtonMenu';

import * as S from './style';

import { HiHome } from 'react-icons/hi';
import { MdAddCircleOutline } from 'react-icons/md';
import IconMenu from './IconMenu';
import IconOffers from './IconOffers';
import IconClients from './IconClients';
import IconClub from './IconClub';

const UserMenu = (props) => {
  const { open, setOpen, currentEst, establishmentArr } = props;
  const [statusEst, setStatusEst] = useState(true);
  const [statusAdd, setStatusAdd] = useState(false);

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!establishmentArr.length) {
      setStatusEst(false);
      setStatusAdd(true);
    }
    if (establishmentArr.length) {
      setStatusEst(true);
      setStatusAdd(false);
    }
  }, [establishmentArr]);

  return (
    <div
      onClick={() => {
        handleCloseDrawer();
      }}>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
      <ButtonMenu
        icon={<HiHome />}
        open={open}
        home={true}
        to={{ pathname: '/admin', state: '' }}>
        Home
      </ButtonMenu>
      <S.DisplayToggle status={statusAdd}>
        <S.DividerContainer open={open}>
          <S.Divider />
        </S.DividerContainer>
        <ButtonMenu
          icon={<MdAddCircleOutline />}
          open={open}
          to={{ pathname: '/estabelecimento/novo/adicionar', state: '' }}>
          Adicionar negócio
        </ButtonMenu>
        <S.DividerContainer open={open}>
          <S.Divider />
        </S.DividerContainer>
      </S.DisplayToggle>

      <S.DisplayToggle status={statusEst}>
        <ButtonMenu
          icon={
            <IconMenu
              width={document.body.clientWidth < 600 ? '2rem' : '3rem'}
            />
          }
          open={open}
          to={
            Object.keys(currentEst).length === 0
              ? establishmentArr.length
                ? {
                    pathname: '/estabelecimento/' + establishmentArr[0].id,
                    state: establishmentArr[0],
                  }
                : { pathname: '/admin', state: '' }
              : {
                  pathname: '/estabelecimento/' + currentEst.id,
                  state: currentEst,
                }
          }>
          Painel Administrativo
        </ButtonMenu>
        <S.DividerContainer open={open}>
          <S.Divider />
        </S.DividerContainer>
        <ButtonMenu
          icon={
            <IconOffers
              width={document.body.clientWidth < 600 ? '2rem' : '3rem'}
            />
          }
          open={open}
          to={
            Object.keys(currentEst).length === 0
              ? establishmentArr.length
                ? {
                    pathname:
                      '/estabelecimento/' +
                      establishmentArr[0].id +
                      '/promocoes',
                    state: establishmentArr[0],
                  }
                : { pathname: '/admin', state: '' }
              : {
                  pathname: '/estabelecimento/' + currentEst.id + '/promocoes',
                  state: currentEst,
                }
          }>
          Promoções
        </ButtonMenu>
        <S.DividerContainer open={open}>
          <S.Divider />
        </S.DividerContainer>
        <ButtonMenu
          icon={
            <IconClients
              width={document.body.clientWidth < 600 ? '2rem' : '3rem'}
            />
          }
          open={open}
          to={
            Object.keys(currentEst).length === 0
              ? establishmentArr.length
                ? {
                    pathname:
                      '/estabelecimento/' +
                      establishmentArr[0].id +
                      '/relacionamento/cadastro-usuarios',
                    state: establishmentArr[0],
                  }
                : { pathname: '/admin', state: '' }
              : {
                  pathname:
                    '/estabelecimento/' +
                    currentEst.id +
                    '/relacionamento/cadastro-usuarios',
                  state: currentEst,
                }
          }>
          Adicionar Clientes
        </ButtonMenu>
        <S.DividerContainer open={open}>
          <S.Divider />
        </S.DividerContainer>
      </S.DisplayToggle>

      <ButtonMenu
        icon={
          <IconClub width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />
        }
        open={open}
        to={{ pathname: '/admin/clube/seu-clube', state: '' }}>
        Clube
      </ButtonMenu>
      <S.DividerContainer open={open}>
        <S.Divider />
      </S.DividerContainer>
    </div>
  );
};

export default UserMenu;
