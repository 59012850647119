export default function reducer(state = {
    action : null,
    appFilter : null,
    cardId : null,
    createDate : null,
    description : null,
    disableDate : null,
    enableDate : null,
    images : null,
    isPublished : null,
    keyWordsSet : null,
    location : null,
    platform : null,
    priority : null,
    status : null,
    subtitle : null,
    title : null,
    type : null
}, action) {

  switch (action.type) {

    case "CARDS_LIST_PENDING": {
      return {
        ...state,
        action : null,
        appFilter : null,
        cardId : null,
        createDate : null,
        description : null,
        disableDate : null,
        enableDate : null,
        images : null,
        isPublished : null,
        keyWordsSet : null,
        location : null,
        platform : null,
        priority : null,
        status : null,
        subtitle : null,
        title : null,
        type : null
      };
    }

    case "CARDS_LIST_FULFILLED": {
      return {
        ...state,
        action : action.payload.action,
        appFilter : action.payload.appFilter,
        cardId : action.payload.cardId,
        description : action.payload.description,
        createDate : action.payload.createDate,
        disableDate : action.payload.disableDate,
        enableDate : action.payload.enableDate,
        images : action.payload.images,
        isPublished : action.payload.isPublished,
        keyWordsSet : action.payload.keyWordsSet,
        location : action.payload.location,
        platform : action.payload.platform,
        priority : action.payload.priority,
        status : action.payload.status,
        subtitle : action.payload.subtitle,
        title : action.payload.title,
        type : action.payload.type
      };
    }

    case "CARDS_LIST_REJECTED": {
      return {
        ...state,
        action : null,
        appFilter : null,
        cardId : null,
        createDate : null,
        description : null,
        disableDate : null,
        enableDate : null,
        images : null,
        isPublished : null,
        keyWordsSet : null,
        location : null,
        platform : null,
        priority : null,
        status : null,
        subtitle : null,
        title : null,
        type : null
      };
    }

    default: return state;

  }

}