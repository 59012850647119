import styled from "styled-components";

export const ModalContainer = styled.div`
  z-index: 3000;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

export const ModalBody = styled.span`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.6rem;
  background: white;
  min-width: 20rem;
  min-height: 10rem;
  border-radius: 10px;
  padding: 3rem 1.8rem;
  font-family: "Source Sans Pro", sans-serif;
`;

export const ModalLabelContainer = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  gap: 1rem;
`;

export const ModalText = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ModalTitle = styled(ModalText)`
  width: 60%;
  font-weight: 600;
`;

export const ModalSubtitle = styled(ModalText)`
  width: 80%;
  font-weight: 500;
`;
