import * as React from 'react';

const IconEstablishmentNegative = (props) => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Grupo 10630">
            <path
                data-name="Caminho 15563"
                d="M46.858 3.521a47.5 47.5 0 1 1-47.5 47.5 47.5 47.5 0 0 1 47.5-47.5z"
                fill="#6e3296"
                style={{
                    strokeWidth: 3.97457,
                }}
                transform="translate(3.142 -1.021)"
            />
            <path
                data-name="Icon awesome-store"
                d="m74.1 38.294-6.014-9.598a2.965 2.965 0 0 0-2.512-1.391H28.146a2.965 2.965 0 0 0-2.512 1.39l-6.014 9.6a8.267 8.267 0 0 0 5.45 12.635 9.63 9.63 0 0 0 1.267.083 9.141 9.141 0 0 0 6.837-3.068 9.141 9.141 0 0 0 13.676 0 9.141 9.141 0 0 0 13.677 0 9.177 9.177 0 0 0 6.836 3.068 9.539 9.539 0 0 0 1.268-.083A8.255 8.255 0 0 0 74.1 38.294zM67.383 53.99a11.757 11.757 0 0 1-2.735-.354v9.245H29.072v-9.245a12.273 12.273 0 0 1-2.735.354 12.5 12.5 0 0 1-1.669-.111 11.653 11.653 0 0 1-1.518-.342v18.231a2.961 2.961 0 0 0 2.965 2.965H67.62a2.961 2.961 0 0 0 2.965-2.965V53.537a9.46 9.46 0 0 1-1.518.334 12.886 12.886 0 0 1-1.69.115z"
                fill="#fff"
                style={{
                    strokeWidth: 3.97457,
                }}
                transform="translate(3.142 -1.021)"
            />
        </g>
    </svg>
);

export default IconEstablishmentNegative;
