import React, { useState, createContext, useContext } from 'react';

export const AttendanceContext = createContext();

export default function AttendanceProvider({ children }) {
    const [isUserRegister, setIsUserRegister] = useState(true);
    const [currentCellphone, setCurrentCellphone] = useState('');
    const [currentPromotions, setCurrentPromotions] = useState([]);
    const [selectedPromotion, setSelectedPromotion] = useState({});
    const [selectedVoucher, setSelectedVoucher] = useState({});
    const [optionSelected, setOptionSelected] = useState('');
    const [nameButtonLabel, setNameButtonLabel] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isRelationshipModal, setIsRelationshipModal] = useState(false);

    return (
        <AttendanceContext.Provider
            value={{
                currentPromotions,
                setCurrentPromotions,
                selectedPromotion,
                setSelectedPromotion,
                optionSelected,
                setOptionSelected,
                nameButtonLabel,
                setNameButtonLabel,
                openModal,
                setOpenModal,
                selectedVoucher,
                setSelectedVoucher,
                isRelationshipModal,
                setIsRelationshipModal,
                isUserRegister,
                setIsUserRegister,
                currentCellphone,
                setCurrentCellphone,
            }}
        >
            {children}
        </AttendanceContext.Provider>
    );
}

export const usePromotions = () => {
    const {
        currentPromotions,
        setCurrentPromotions,
        selectedPromotion,
        setSelectedPromotion,
    } = useContext(AttendanceContext);

    return {
        currentPromotions,
        setCurrentPromotions,
        selectedPromotion,
        setSelectedPromotion,
    };
};

export const useOptions = () => {
    const {
        optionSelected,
        setOptionSelected,
        nameButtonLabel,
        setNameButtonLabel,
    } = useContext(AttendanceContext);

    return {
        optionSelected,
        setOptionSelected,
        nameButtonLabel,
        setNameButtonLabel,
    };
};

export const useModal = () => {
    const {
        openModal,
        setOpenModal,
        isRelationshipModal,
        setIsRelationshipModal,
        isUserRegister,
        setIsUserRegister,
        currentCellphone,
        setCurrentCellphone,
    } = useContext(AttendanceContext);

    return {
        openModal,
        setOpenModal,
        isRelationshipModal,
        setIsRelationshipModal,
        isUserRegister,
        setIsUserRegister,
        currentCellphone,
        setCurrentCellphone,
    };
};

export const useVouchers = () => {
    const { selectedVoucher, setSelectedVoucher } = useContext(AttendanceContext);

    return { selectedVoucher, setSelectedVoucher };
};
