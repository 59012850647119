import styled from 'styled-components';

export const InputBox = styled.div`
  flex: 1;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 8px 1rem;
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  height: 100%;
`;

export const SearchIconButton = styled.button`
  background: none;
  height: 100%;
  border-left: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  padding-left: 10px;
  cursor: pointer;

  img {
    width: 24px;
  }
`;
