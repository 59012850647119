import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Pagination,
  Box,
} from '@mui/material';

const ResourceTable = ({ data, column, perPage, componentReceiver, isEmpty, loading }) => {
  const [page, setPage] = useState(1);

  const PER_PAGE = perPage;
  const count = Math.ceil(data.length / PER_PAGE);
  const startIndex = (page - 1) * PER_PAGE;
  const endIndex = startIndex + PER_PAGE;
  const slicedData = data.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { columnComponent } = componentReceiver(data, null);

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {isEmpty && data.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <strong>Ainda não existem informações cadastradas.</strong>
            </Box>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#f9f9f9' }}>
                      {/* Renderiza a coluna de Ações primeiro */}
                      <TableCell>{columnComponent}</TableCell>
                      {/* Renderiza as demais colunas */}
                      {column.map((item, index) => (
                        <TableCell key={index} style={{ fontWeight: 'bold' }}>
                          {item.heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slicedData.map((item, index) => (
                      <TableRow key={index}>
                        {/* Renderiza a coluna de Ações */}
                        <TableCell>{componentReceiver(null, item).childrenComponent}</TableCell>
                        {/* Renderiza as demais colunas */}
                        {column.map((columnItem, index) => (
                          <TableCell key={index} style={{ maxWidth: '400px' }}>
                            {item[columnItem.value] || item[columnItem.origin] || 'Não cadastrado'}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {data.length > 9 && (
                <Pagination
                  sx={{ margin: '0 auto' }}
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  color="secondary"
                  onChange={handleChangePage}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ResourceTable;
