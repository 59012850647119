import React from 'react';
import { checkInfo } from '../../../library/utils/helpers';
import { TreeItem } from '@mui/x-tree-view';
import { toast } from 'react-toastify';

export function RecursiveItem(info, path) {
  if (info) {
    return Object.keys(info).map((value) => {
      let newPath = path + ':' + value;

      if (
        typeof info[value] !== 'object' ||
        (Array.isArray(info[value]) && info[value].length === 1 && typeof info[value] !== 'object')
      ) {
        return (
          <TreeItem
            nodeId={newPath}
            key={newPath}
            onClick={() => {
              navigator.clipboard.writeText(checkInfo(value, info[value]).replace(/^"|"$/g, ''));
              toast.success(
                `Valor copiado: ${checkInfo(value, info[value]).replace(/^"|"$/g, '')}`
              );
            }}
            label={
              <div>
                <span style={{ fontWeight: 700 }}>{value + ': '}</span>
                <span>{checkInfo(value, info[value])}</span>
              </div>
            }
          />
        );
      } else {
        if (info[value]) {
          return (
            <TreeItem
              nodeId={newPath}
              key={newPath}
              label={<span style={{ fontWeight: 700 }}>{value}</span>}
            >
              {RecursiveItem(info[value], newPath)}
            </TreeItem>
          );
        } else {
          return (
            <TreeItem
              nodeId={newPath}
              key={newPath}
              label={
                <span>
                  <span style={{ fontWeight: 700 }}>{value + ': '}</span>
                  <span style={{ color: 'red' }}>Informação não existe!</span>
                </span>
              }
            />
          );
        }
      }
    });
  } else {
    return (
      <TreeItem
        nodeId={path + ': Invalido'}
        key={path + ': Invalido'}
        label={'Informação não existe!'}
      />
    );
  }
}
