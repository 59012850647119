import styled from "styled-components/macro";
import Autocomplete from "@mui/material/Autocomplete";

export const Container = styled.div`
  width: 100%;
  min-height: 2rem;
`;

export const TriiboComplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#ffffff",
    "& fieldset": {
      borderColor: "rgba(112, 112, 112)",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "rgba(112, 112, 112)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(112, 112, 112)",
    },
  },
}));
