import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getUserInfo } from 'js/library/utils/helpers';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';

import { Button, Grid, Typography } from '@mui/material';

const CompletedMessage = (props) => {
    const { establishmentId } = props;

    useEffect(() => {
        const getStatusUser = async () => {
            const uid = getUserInfo().uId;
            const res = await getTrialStatus(uid);
            if (res.establishmentStep === true && res.storeStep === true && res.relationshipStep === true && res.congrats === false) {
                updateTrialStatus({
                    'establishmentStep': true,
                    'storeStep': true,
                    'relationshipStep': true,
                    'congrats': true,
                });
                // .then((result) => {
                //     setAllStepsOnboarding(result);
                //     window.location.reload();
                // });  
            }        
        };

        getStatusUser();

    }, []);

    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid
                    container
                    item
                    xs={10}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={3}
                    sx={
                        {
                            // minHeight: "50vh",
                        }
                    }
                >
                    <Typography
                        variant="h4"
                        align="center"
                        color="#383838"
                        display="block"
                        sx={{ fontFamily: 'Source Sans Pro' }}
                    >
                        <strong>Parabéns</strong>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="#383838"
                        sx={{ fontFamily: 'Source Sans Pro' }}
                    >
            Você acabou de concluir as estapas. Agora você já pode acessar o
            Painel Administrativo e explorar as demais funcionalidades da
            Triibo.
                    </Typography>
                    <Link
                        to={{ pathname: '/estabelecimento/' + establishmentId.id, state: establishmentId }}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{
                                minWidth: '15rem',
                                backgroundColor: '#08BAD0',
                                borderRadius: '0.6rem',
                                fontFamily: 'Source Sans Pro',
                                fontWeight: '700',
                                fontSize: '1.2rem',
                                textTransform: 'none',
                            }}
                        >
              Painel Administrativo
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    );
};

export default CompletedMessage;
