import React from 'react';
import { useSelector } from 'react-redux';

import StepsConcluded from './StepsConcluded';

const StepsConcludedPage = () => {

    let state = useSelector((state) => state.authModel);

    return (
        <div>
            <StepsConcluded state={state} />
        </div>
    );
};

export default StepsConcludedPage;