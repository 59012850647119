import styled from 'styled-components';
import { Box, FormGroup, Accordion } from '@mui/material';
import InputMask from 'react-input-mask';

export const SearchUserBox = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Input = styled(InputMask)`
  flex: 1;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #383838d6;
  border-radius: 10px;
`;

export const ContentAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    margin: 0.4rem 0;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ContentGroupInputAddress = styled.div`
  display: flex;
  gap: 1rem;
`;

export const BoxCellPhone = styled.div`
  width: 100%;
  border: 1px solid #afafaf;
  border-radius: 10px;
  height: 42px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-left: 0.5rem;

  select {
    font-size: 14px;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }

  input {
    flex: 1;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }
`;

export const Header = styled(Box)`
  position: relative;
  padding-top: 5rem;

  h1 {
    text-align: center;
    color: #555555;
    font-family: Source Sans Pro;
    font-weight: bold;
    font-size: 26px;
    line-height: 41px;
  }

  button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: #08bad0;
    color: #fff;
    text-transform: capitalize;

    &:hover {
      background: #08bad0;
      filter: brightness(0.9);
    }
  }

  @media (max-width: 600px) {
    button {
      top: 1rem;
      right: 5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 935px;
  width: 100%;
  margin: 2rem auto;
  padding: 1rem;
`;

export const OptionsSearch = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 730px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const AccessTokenFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    flex: 1;
  }

  @media (max-width: 920px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

export const BoxSearch = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;

    form {
      width: 94%;
    }

    .css-tp8o0d-MuiFormGroup-root {
      padding-left: 1.5rem;
    }
  }
`;

export const SerachInput = styled(InputMask)`
  flex: 1;
  padding-left: 1rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #383838d6;
  border-radius: 10px;
`;

export const BoxSearchInput = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 42px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;

  .inputSearch {
    flex: 1;
    padding-left: 1rem;
    height: 100%;
    border: none;
    outline: none;
    font-size: 17px;
    color: #383838d6;
    border-radius: 10px;
    width: 100%;
  }

  .searchIcon {
    button {
      background: none;
      border-left: 1px solid #ccc;
      border-radius: 0;

      img {
        width: 24px;
      }
    }
  }

  @media (max-width: 730px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    .inputSearch {
      padding-left: 0.7rem;
      font-size: 16px;
      flex: 1;
    }
  }
`;

export const FormGroupBox = styled(FormGroup)`
  > div {
    display: flex;
    align-items: center;
  }
`;

export const Adornment = styled.div`
  margin-left: 1rem;
  color: #383838;

  @media screen and (max-width: 600px) {
    div .gIvpzy {
      overflow: auto;
    }
  }
`;

export const AccordionStyle = styled(Accordion)`
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 600px) {
    max-width: 58%;
  }
`;

export const UserInfoBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ActionsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .icons {
    ul {
      list-style: none;
      display: flex;
      gap: 0.5rem;

      .buttonAction {
        background: none;

        img {
          width: 35px;
        }
      }
    }

    @media (max-width: 780px) {
      li {
        width: 35px;
      }
    }
  }

  .deleteUser {
    button {
      background: none;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.2rem;

      span {
        font-size: 16px;
        font-weight: 600;
        font-family: Source Sans Pro;
        color: #555555;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .ActionsBox {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
    }

    .deleteUser {
      gap: 10px;

      span {
        display: none;
      }
    }
  }
`;

export const PrintUserBox = styled(Box)`
  width: 100%;
  overflow: auto;

  .button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    a {
      background: #08bad0;
      color: #fff;
      font-weight: 550;
      text-transform: capitalize;

      &:hover {
        color: #fff;
        background: #08bad0;
        filter: brightness(0.95);
      }
    }
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  span {
    font-size: 18px;
    font-weight: 600;
    font-family: Source Sans Pro;
    color: #555555;

    &:hover {
      color: #333333;
    }
  }
`;

export const ModalBox = styled(Box)`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 10px;
    border: 3px solid #f2f2f2;
  }

  .header {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      font-size: 26px;
      font-weight: 600;
      font-family: Source Sans Pro;
      color: #555555;
    }
  }

  .addButton {
    background-color: #6e32960c;
    color: #6e3296;
    border: 1px solid #6e3296;
    width: 177px;
    text-transform: capitalize;
    margin-left: auto;
    font-size: 15px;
    font-weight: 600;
    font-family: Source Sans Pro;
    height: 42px;

    span {
      font-size: 24px;
      margin-right: 0.3rem;
    }

    &:hover {
      background-color: #6e329633;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 90%;
    width: 100%;
    padding: 2rem;
    overflow: auto;

    .inputSearchBox .inputSearch {
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: -webkit-fill-available;
    }

    .inputSearchBox .inputSearch .input {
      width: -webkit-fill-available;
    }

    .inputSearchBox .inputSearch .submitButton {
      width: -webkit-fill-available;
    }

    .inputSearchBox .inputSearch .submitButton:disabled {
      width: -webkit-fill-available;
    }

    .addButton {
      width: 80px;
      font-size: 13px;
      height: 50px;
      font-size: 14px;
    }

    .header span {
      display: none;
    }

    .gxeAOy {
      max-width: 100%;
    }

    .gxeAOy .inputNumberPhoneBox button {
      width: 0px;
    }

    .css-qfz70r-MuiFormGroup-root {
      display: flex;
      gap: 0rem;
      flex-direction: column;
      align-content: flex-start;
    }

    .actionsButtons {
      gap: 1rem;
      width: 100%;
    }

    .actionsButtons button {
    }

    .glWhMz {
      max-width: 109%;
      width: 103%;
    }

    .bmEYBX {
      padding: 1rem;
    }

    .lbyGwV {
      max-width: 100%;
    }

    .lbyGwV .actionsButtons button {
      width: 45%;
    }

    .dbwyUU .actionsButtons {
      gap: inherit;
    }

    .importFile .fileSpan {
      display: none;
    }

    .importFile {
      display: flex;
      justify-content: center;
    }
  }
`;

export const DeleteUserModalBox = styled(Box)`
  max-width: 645px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;

  h2 {
    font-size: 33px;
    font-weight: bold;
    text-align: center;
    font-family: Source Sans Pro;
    color: #555555;
  }

  h4 {
    font-size: 22px;
    color: #383838;
    text-align: center;
    max-width: 450px;
  }

  .boxButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .deleteUser {
      margin-right: 1rem;
      button {
        background: none;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        span {
          font-size: 18px;
          font-weight: 600;
          font-family: Source Sans Pro;
          color: #555555;

          &:hover {
            color: #333333;
          }
        }
      }
    }

    .cancel {
      background: #dbdbdb;
      color: #555555;
      font-weight: 600;
      box-shadow: 0px 3px 6px #00000029;
      text-transform: capitalize;
      padding: 0.2rem 2rem;

      &:hover {
        filter: brightness(0.9);
      }
    }

    .deletePartial {
      background: #08bad0;
      color: #fff;
      font-weight: 600;
      box-shadow: 0px 3px 6px #00000029;
      text-transform: capitalize;
      padding: 0.2rem 2rem;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        background: #dbdbdb;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .boxButtons .deleteUser button {
      display: flex;
      justify-content: center;
    }
  }
`;

export const AddKeywordsModalBox = styled(Box)`
  max-width: 787px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inputSearchBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h4 {
      font-size: 20px;
      color: #383838;
      font-family: Source Sans Pro;
      margin-bottom: 1rem;
    }

    .inputSearch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding-left: 0;

      .input {
        flex: 1;
      }

      .submitButton {
        background: #08bad0;
        color: #fff;

        &:disabled {
          background: #dbdbdb;
        }
      }
    }
  }

  .linkedKeywordsLists {
    .titles {
      font-size: 18px;
      font-weight: bold;
      font-family: Source Sans Pro;
      color: #555555;
      margin-bottom: 1rem;
    }

    .messageNoKey {
      font-size: 16px;
      font-family: Source Sans Pro;
      color: #555555;
    }

    .list {
      .listItem {
        border-bottom: 1px solid #ccc;
      }

      .listItem:last-child {
        border-bottom: none;
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #707070;
        border-radius: 20px;
        border: 3px solid #f2f2f2;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 2rem;

    .inputSearchBox {
      overflow: auto;
    }

    .linkedKeywordsLists {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const AddOrgsModalBox = styled(Box)`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inputSearchBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;

    h4 {
      font-size: 20px;
      color: #383838;
      font-family: Source Sans Pro;
      margin-bottom: 1rem;
    }

    .inputSearch {
      width: 100%;
      padding-left: 0;
    }

    .submitButton {
      width: 164px;
      margin: 0 auto;
      background: #08bad0;
      color: #ffffff;

      &:disabled {
        background: #dbdbdb;
      }

      &:hover {
        filter: brightness(0.91);
      }
    }
  }

  .linkedKeywordsLists {
    width: 100%;
    max-height: 235px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .titles {
      font-size: 18px;
      font-weight: bold;
      font-family: Source Sans Pro;
      color: #555555;
      margin-bottom: 1rem;
    }

    .messageNoOrg {
      font-size: 16px;
      font-family: Source Sans Pro;
      color: #555555;
    }

    .list {
      .listItem {
        border-bottom: 1px solid #ccc;
      }

      .listItem:last-child {
        border-bottom: none;
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #707070;
        border-radius: 20px;
        border: 3px solid #f2f2f2;
      }
    }
  }
`;

export const WalletModalBox = styled(Box)`
  max-width: 684px;
  width: 550px;
  display: flex;
  flex-direction: column;

  .walletHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .walletTitle {
      display: flex;
      gap: 1rem;
      align-items: center;

      h2 {
        font-size: 26px;
        font-weight: 600;
        font-family: Source Sans Pro;
        color: #555555;
      }
    }

    .totalPoints {
      display: flex;
      gap: 1rem;
      align-items: center;

      img {
        width: 40px;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
        font-family: Source Sans Pro;
        color: #555555;
      }
    }
  }

  .dropdown {
    border-radius: 10px !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    margin-bottom: 1rem;

    &:before {
      background-color: white;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    #modal-title {
      padding-right: 1rem;
    }

    .walletHeader {
      justify-content: flex-start;
      gap: 1rem;
    }

    .buttonModalWallet {
      width: 50%;
      display: flex;
    }

    .dropdown {
      max-width: 100%;
    }

    .containerSelect {
      width: 100%;
      max-width: 100%;
    }

    .dropdown:before {
      overflow: auto;
    }
  }
`;

export const GenerateModalBox = styled(Box)`
  max-width: 684px;
  width: 550px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .generateHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .generateTitle {
      display: flex;
      gap: 1rem;
      align-items: center;

      h2 {
        font-size: 26px;
        font-weight: 600;
        font-family: Source Sans Pro;
        color: #555555;
      }
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .generateHeader {
      justify-content: flex-start;
      gap: 1rem;
    }
  }
`;

export const EditInfoUserModalBox = styled(Box)`
  max-width: 450px;
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inputNumberPhoneBox {
    margin-bottom: 1rem;

    .changePhoneTitle {
      display: flex;
      gap: 0.8rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      margin-right: auto;
      color: #6e3296;
      font-size: 16px;
      text-transform: capitalize;
    }

    div {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1rem;
    }

    .inputNumberPhone {
      flex: 1;
    }

    button {
      width: 164px;
      background: #08bad0;
      font-size: 16px;
      text-transform: capitalize;

      &:disabled {
        background: #dbdbdb;
      }

      &:hover {
        background: #08bad0;
        filter: brightness(0.9);
      }
    }
  }

  .actionsButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      width: 120px;
      text-transform: capitalize;
    }

    .button1 {
      background: #dbdbdb;
      color: #555555;
    }

    .button2 {
      background: #08bad0;
      color: #ffffff;

      &:disabled {
        background: #dbdbdb;
      }

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;

    .inputNumberPhoneBox button {
      width: 28%;
    }
  }
`;

export const BoxMigrateCellphoneInput = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  height: 42px;
  border: 1px solid #afafaf;
  border-radius: 10px;
  padding: 0.7rem;
  background-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.2)' : 'white')};

  input {
    outline: none;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const SignatureBox = styled(Box)`
  max-width: 550px;
  width: 550px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  max-height: 90%;
  height: 90%;

  .wrapper {
    max-height: 500px;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f2;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #707070;
      border-radius: 10px;
      border: 3px solid #f2f2f2;
    }

    .listSignatures {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .SignatureCard.add {
      box-shadow: 0px 3px 6px #0000007f;
      margin-bottom: 1rem;
    }

    .SignatureCard {
      width: 100%;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      padding: 30px;
      display: flex;
      gap: 1rem;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;

      .inputsBox {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .inputBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        label {
          font-size: 18px;
          color: #383838;
          font-family: Source Sans Pro;
          font-weight: normal;
        }
      }

      .dropDownContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          font-size: 18px;
          color: #383838;
          font-family: Source Sans Pro;
          font-weight: normal;
        }

        .dropDown {
          width: 300px;
          position: relative;

          .dropDownHeader {
            border-radius: 10px;
            border: 1px solid #707070;
            box-shadow: 0px 3px 6px #00000029;
            background: #fff;
            padding: 0.5rem 1rem;
            cursor: pointer;

            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              text-align: left;
              padding-left: 0;
              line-height: 16px;
              font-weight: normal;
              font-size: 16px;
              color: #383838;
              text-transform: capitalize;
            }

            .iconActive {
              transform: rotate(180deg);
            }
          }

          .dropDownListContainer {
            position: absolute;
            top: 3rem;
            z-index: 10;

            border-radius: 10px;
            width: 100%;
            background: #fff;
            box-shadow: 0px 3px 6px #00000029;

            .dropDownList {
              list-style: none;

              .listItem {
                padding: 0.7rem 1rem;
                width: 100%;
                background: #fff;
                border-radius: 10px;
                cursor: pointer;
                text-transform: capitalize;

                &:hover {
                  filter: brightness(0.8);
                }
              }
            }
          }
        }
      }

      .input {
        width: 300px;
        border-radius: 10px;
        border: 1px solid #707070;
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
        padding: 0.8rem 1rem;
        font-size: 16px;
        font-weight: normal;
        color: #383838;
        text-transform: capitalize;

        &:disabled {
          opacity: 0.8;
        }
      }

      .iconDelete {
        fill: #5a287c;
      }
    }
  }

  .actionsButtons {
    display: flex;
    justify-content: space-evenly;

    button {
      width: 164px;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
    }

    .button1 {
      background: #dbdbdb;
      color: #555555;

      &:hover {
        background: #dbdbdbd8;
      }
    }

    .button2 {
      background: #08bad0;
      color: #fff;

      &:hover {
        background: #08bad0d8;
      }

      &:disabled {
        background: #dbdbdb;
        cursor: not-allowed;
        pointer-events: all !important;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0rem;
    width: 282px;
    max-width: 360px;

    .wrapper {
      max-height: 375px;
      height: 100%;
      max-width: 285px;
    }

    .actionsButtons {
      display: flex;
      justify-content: space-between;
      width: 18rem;
    }

    .actionsButtons button {
      width: 125px;
    }

    .wrapper .SignatureCard.add {
      box-shadow: none;
      padding: 1rem 0rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .wrapper .SignatureCard {
      box-shadow: none;
      padding: 1rem 0rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
    }

    .dropDownHeader {
      width: 15rem;
    }

    .wrapper .SignatureCard .input {
      width: 15rem;
    }

    .wrapper .SignatureCard .inputBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1vmax;
    }

    .wrapper .SignatureCard .dropDownContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.7rem;
    }
    .wrapper .SignatureCard .dropDownContainer .dropDown {
      width: 260px;
      position: relative;
    }
  }
`;

export const BatchKeywordsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inputSearchBox {
    margin-top: 1rem;

    h4 {
      font-size: 18px;
      color: #383838;
      font-family: Source Sans Pro;
      margin-bottom: 1rem;
    }

    .input {
      max-width: 350px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .titleOptions {
      font-size: 18px;
      font-weight: 600;
      color: #555555;
      font-family: Source Sans Pro;
    }

    .optionBox {
      min-width: 150px;
      color: #555555;

      .checkedIcon {
        font-size: 25px;
      }
    }
  }

  .importFile {
    display: flex;
    align-items: center;

    .fileSpan {
      margin-right: 1.5rem;
      color: #555555;
      font-size: 18px;
      font-family: Source Sans Pro;
    }

    .inputFileBox {
      border: 1px solid #6e3296;
      border-radius: 4px;
      max-width: 190px;

      .icon {
        font-size: 25px;
        fill: #6e3296;
      }

      label {
        color: #6e3296;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        padding: 0.6rem 0.6rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          background-color: #6e329633;
        }
      }
    }
  }

  .actionsButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      width: 120px;
      text-transform: capitalize;
    }

    .button1 {
      background: #dbdbdb;
      color: #555555;
    }

    .button2 {
      background: #08bad0;
      color: #ffffff;

      &:disabled {
        background: #dbdbdb;
      }

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  @media screen and (max-width: 600px) {
    .inputSearch {
      padding-left: 0;
    }
  }
`;

export const ContainerPass = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
`;

export const CardContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 17px 64px;
  border-left: 9px solid #5a287c;

  @media (max-width: 700px) {
    padding: 1rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  width: 100%;

  /* @media (max-width: 720px) {
    flex-direction: column;
  } */
`;

export const CardHeaderItem = styled.div`
  > span {
    text-align: right;
    font: italic normal normal 14px/18px Source Sans Pro;
    color: #707070;
  }

  > h4 {
    font-weight: 600;
  }

  @media (max-width: 720px) {
    text-align: center;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CardContent = styled.div`
  display: ${({ open }) => (open ? 'default' : 'none')};
`;

export const EditInfosContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  gap: 0.3rem;
`;

export const IconEdit = styled.div`
  background: #5a287c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconPhone = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #6e3296;
`;

export const ChangeCellphoneContent = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const CellphoneBoxChange = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const InputChangeContainer = styled.div`
  max-width: 300px;
  width: 100%;
`;

export const ActionsButtons = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  button {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0.3rem 2rem;
  }
`;
