import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  h1 {
    text-align: center;
  }
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
`;

export const ButtonsContainer = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  max-width: 748px;
  margin-top: 2rem;
`;

export const RegisterButton = styled(Link)`
  height: 97px;
  width: 100%;
  background: #6e3296;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  h4 {
    color: #fff;
  }

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ListButton = styled(Link)`
  height: 97px;
  width: 100%;
  background: #06bad0;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  h4 {
    color: #fff;
  }

  &:hover {
    filter: opacity(0.9);
  }
`;
