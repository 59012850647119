import React from "react";
import { Box, Typography } from "@mui/material";

import { FaArrowAltCircleRight, FaCheckCircle } from "react-icons/fa";

const StepsButton = (props) => {
  const { mainText, status } = props;

  const Icon = () => {
    if (status === 0) {
      //verde
      return (
        <FaCheckCircle
          style={{
            fontSize: "2.1rem",
            color: "#78AA1E",
          }}
        />
      );
    }

    if (status === 1) {
      //azul
      return (
        <FaArrowAltCircleRight
          style={{
            fontSize: "2.1rem",
            color: "#08bad0",
          }}
        />
      );
    }

    if (status === 2) {
      //cinza
      return (
        <FaArrowAltCircleRight
          style={{
            fontSize: "2.1rem",
            color: "#38383880",
          }}
        />
      );
    }
  };

  return (
    <div>
      <Box
        sx={[
          {
            display: "flex",
            justifyContent: "space-between",
            border: "0.1rem solid",
            borderColor: status === 2 ? "#38383880" : "black",
            borderRadius: "0.3rem",
            margin: '5px 0px',
            width: {
              xs: "100%",
              md: "100%",
            },
            transform: "scale(1)",
            // height: { xs: '2.9rem', md: '3.6rem' },
            paddingLeft: { xs: "0.7rem", md: "1rem" },
            paddingRight: { xs: "0.7rem", md: "1rem" },
            paddingTop: { xs: "0.4rem", md: "0.8rem" },
            paddingBottom: { xs: "0.4rem", md: "0.8rem" },
            "&:hover": {
              backgroundColor: "#ededed",
              transition: "all 0.5s ease-in-out",
            },
          },
        ]}
      >
        <Box
          sx={[
            {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "space-between", md: "space-between" },
              alignItems: "center",
              gap: ".5rem",
            },
          ]}
        >
          <Typography
            variant="subtitle1"
            align="center"
            display="block"
            sx={{
              fontSize: { xs: "1rem", md: "1.1rem" },
              fontWeight: "500",
              color: "#383838",
            }}
          >
            {mainText}
          </Typography>

          <Icon />
        </Box>
      </Box>
    </div>
  );
};

export default StepsButton;
