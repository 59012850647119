import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { debounce } from 'lodash';

// STYLES
import * as S from '../Establishment/ListEstablishment/styles';
import {
  Paper,
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';

import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

// ICONES
import { AddPhotoAlternate, Send } from '@mui/icons-material';

// APIS
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { getUserInfo, separateBase64String, blobToBase64 } from 'js/library/utils/helpers';
import { pointsExChangeInBatch } from 'js/library/utils/API/Points/pointsExChangeInBatch';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { pointsExchangeBetweenContracts } from 'js/library/utils/API/Points/pointsExchangeBetweenContracts';
import { getBalance_v1 } from 'js/library/utils/API/Points/getBalance_v1';

export default function SendingPoints(props) {
  const clubId = props.location.state.clubId;
  const contractIdClub = props.location.state.contractIdClub;
  const establishmentId = props.location.state.establishmentId;
  const contractIdEstablishment = props.location.state.contractIdEstablishment;

  const history = useHistory();

  const [value, setValue] = useState(0);
  const [eventDescription, setEventDescription] = useState('');
  const [clientList, setClientList] = useState(null);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [recipient, setRecipient] = useState(1);
  const [fromWhere, setFromWhere] = useState('');
  const [loading, setLoading] = useState(false);

  const [dataEstablishment, setDataEstablishment] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);

  const [dataClub, setDataClub] = useState([]);
  const [selectedClub, setSelectedClub] = useState(null);
  const [filteredDataClub, setFilteredDataClub] = useState([]);

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');

  const [balancePoints, setBalancePoints] = useState(0);
  const [loadingBalancePoints, setLoadingBalancePoints] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(600));

  const uId = getUserInfo().uId;

  const previousUrl = localStorage.getItem('previousUrlPoints');

  useEffect(() => {
    if (previousUrl.includes('estabelecimento')) {
      setFromWhere('estabelecimento');
    } else if (previousUrl.includes('seu-clube')) {
      setFromWhere('seuClube');
    } else {
      setFromWhere('adminConfig');
    }
  }, [previousUrl]);

  useEffect(() => {
    // é o contractId do estabelecimento triiboWifi + @contrato-triibo,com,br
    let triiboId = '';

    if (fromWhere === 'adminConfig') {
      triiboId = '-LEWO0lc274ebMRbuFnG@contrato-triibo,com,br';
    } else if (fromWhere === 'estabelecimento' && contractIdEstablishment !== '') {
      triiboId = `${contractIdEstablishment}@contrato-triibo.com.br`;
    } else if (fromWhere === 'seuClube' && contractIdClub !== '') {
      triiboId = `${contractIdClub}@contrato-triibo.com.br`;
    }

    setLoadingBalancePoints(true);

    if (triiboId !== '') {
      getBalance_v1(uId, triiboId)
        .then((res) => {
          setBalancePoints(res.balance.consolidateBalance?.total);
        })
        .catch((e) => {
          console.error('Erro getBalance: ', e);
          setLoadingBalancePoints(false);
        })
        .finally(() => {
          setLoadingBalancePoints(false);
        });
    }
  }, [fromWhere, contractIdClub, contractIdEstablishment]);

  const fetchData = async (query = '', from = 0, size = 10) => {
    setLoading(true);
    try {
      if (recipient === 3) {
        const establishmentData = await apiListEstablishment(query, from, size);
        setDataEstablishment(establishmentData);
      }
      if (recipient === 2) {
        const clubData = await getSeuClubList(uId);
        setDataClub(clubData);
        setFilteredDataClub(clubData);
      }
    } catch (error) {
      console.error('Erro fetchData: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    if (recipient !== 1) {
      setClientList(null);
    }

    if (recipient !== 2) {
      setSelectedClub(null);
    }

    if (recipient !== 3) {
      setSelectedEstablishment(null);
    }
  }, [recipient]);

  const performFetch = async (query) => {
    setLoading(true);

    // Se o valor digitado não estiver na lista inicial, fazer nova requisição (estabelecimento)
    if (
      !dataEstablishment.some(
        (establishment) => establishment.nome.toLowerCase() === query.toLowerCase()
      )
    ) {
      await fetchData(query, 0, 10);
      setLoading(false);
    }
  };

  // Função debounce utilizando lodash
  const debouncedFetch = useCallback(
    debounce(async (query) => {
      await performFetch(query);
    }, 1000),
    [dataEstablishment]
  );

  useEffect(() => {
    debouncedFetch(textFieldValue.trim());
  }, [textFieldValue]);

  const optionsAutoComplete = () => {
    switch (recipient) {
      case 3:
        return dataEstablishment;
      case 2:
        return filteredDataClub;
      default:
        break;
    }
  };

  const labelType = () => {
    switch (recipient) {
      case 3:
        return 'Selecione um estabelecimento';
      case 2:
        return 'Selecione um clube';
      default:
        break;
    }
  };

  // estabelecimento / clube selecionado
  const handleChangeAutocomplete = (event, value) => {
    if (value) {
      const selectedId = value.id;
      if (recipient === 3) {
        const filteredDataEstablishment = dataEstablishment.filter(
          (item) => item.id === selectedId
        );
        setSelectedEstablishment(
          filteredDataEstablishment.length > 0 ? filteredDataEstablishment[0] : null
        );
      } else if (recipient === 2) {
        const filteredDataClub = dataClub.filter((item) => item.id === selectedId);
        setSelectedClub(filteredDataClub.length > 0 ? filteredDataClub[0] : null);
      }
    } else {
      setSelectedEstablishment(null);
      setSelectedClub(null);
    }
  };

  // Filtra os clubes com base no valor digitado
  useEffect(() => {
    if (textFieldValue === '') {
      setFilteredDataClub(dataClub);
    } else {
      const filteredClubs = dataClub.filter((club) =>
        club.name.toLowerCase().includes(textFieldValue.toLowerCase())
      );
      setFilteredDataClub(filteredClubs);
    }
  }, [textFieldValue]);

  const handleChangeRecipient = (event) => {
    setRecipient(event.target.value);
  };

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const fileChangedHandler = (event) => {
    if (event.target.files.length !== 0) {
      setClientList(event.target.files[0]);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById('thumbnailStore').click();
  };

  // sobe o arquivo com a lista de usuários
  const uploadingFile = async (file) => {

    return new Promise(async (resolve, reject) => {
      const newFile = separateBase64String(await blobToBase64(file));
      
      uploadFiles('points-exchanges', {
        mimeType: newFile.mimeType,
        fileName: file.type === 'text/plain' ? 'usuarios.txt' : 'usuarios.csv',
        buffer: newFile.buffer,
      }).then((res) => {
          const response = res.split('/');
          const path = 'points-exchanges/' + response[response.length - 1].split('?')[0];
              resolve(path);
        })
        .catch(() => {
          reject('');
        });
    });
  };

  const addEvent = async (event) => {
    event.preventDefault();

    let type;
    let id;

    if (clubId) {
      type = 'clubId';
      id = clubId;
    } else if (establishmentId) {
      type = 'establishmentId';
      id = establishmentId;
    }

    // validações do form
    if (value <= 0) {
      toast.error('Preencha um valor positivo para a quantidade de pontos!', { autoClose: 2500 });
      return;
    }

    if (!eventDescription) {
      toast.error('A descrição é obrigatória!', { autoClose: 2500 });
      return;
    }

    if (recipient === 1 && clientList === null) {
      toast.error('Insira o arquivo com os uId dos usuários que serão creditados.', {
        autoClose: 2500,
      });
      return;
    }

    if (recipient === 2 && selectedClub === null) {
      toast.error('Selecione um clube!', {
        autoClose: 2500,
      });
      return;
    }

    if (recipient === 3 && selectedEstablishment === null) {
      toast.error('Selecione um estabelecimento!', {
        autoClose: 2500,
      });
      return;
    }

    const handleUserPoints = async (path, additionalPayload = {}) => {
      const payloadUser = {
        filePath: path,
        value: value,
        description: eventDescription,
        ...additionalPayload,
        [type]: id,
      };

      await pointsExChangeInBatch(payloadUser);
    };

    const handlePointsExchangeBetweenContracts = async (typeSender, senderId, type, receiverId) => {
      const payload = {
        typeOfSender: typeSender,
        senderId: senderId,
        typeOfReceiver: type,
        receiverId: receiverId,
        value: value,
        description: eventDescription,
      };

      await pointsExchangeBetweenContracts(payload);
    };

    try {
      setLoadingAdd(true);

      // quando a opção do select for usuário
      if (recipient === 1) {
        const path = await uploadingFile(clientList);

        // quando for pela rota de configurações do admin,
        // mandar o establishmentId padrão da triibo (-TriiboWifi)
        if (fromWhere === 'adminConfig') {
          await handleUserPoints(path, { establishmentId: '-TriiboWifi' });

          // quando for pela rota de estabelecimento ou do seu clube
        } else if (fromWhere === 'estabelecimento' || fromWhere === 'seuClube') {
          await handleUserPoints(path);
        }
      }

      // se for pelas config do admin, e a opção do select for igual a clube
      if (fromWhere === 'adminConfig' && recipient === 2) {
        await handlePointsExchangeBetweenContracts(
          'establishment',
          '-TriiboWifi',
          'club',
          selectedClub.clubeId
        );
      }

      // se for pelas config do admin, e a opção do select for igual a estabelecimento
      if (fromWhere === 'adminConfig' && recipient === 3) {
        await handlePointsExchangeBetweenContracts(
          'establishment',
          '-TriiboWifi',
          'establishment',
          selectedEstablishment.id
        );
      }

      // se não for pelas config do admin, e a opção do select for igual a clube
      if (fromWhere !== 'adminConfig' && recipient === 2) {
        await handlePointsExchangeBetweenContracts('club', clubId, 'club', selectedClub.clubeId);
      }

      // se não for pelas config do admin, e a opção do select for igual a estabelecimento
      if (fromWhere !== 'adminConfig' && recipient === 3) {
        await handlePointsExchangeBetweenContracts(
          'establishment',
          establishmentId,
          'establishment',
          selectedEstablishment.id
        );
      }

      toast.success('Pontos enviados com sucesso!');
      setTimeout(() => {
        setLoadingAdd(false);
        history.goBack();
      }, 2000);
    } catch (error) {
      const errorMessage = error?.response?.data.error ? error.response.data.error : 'Ocorreu um erro ao enviar os pontos.';
      toast.error(errorMessage, { autoClose: 2500 });

      setLoadingAdd(false);
    }
  };

  return (
    <div style={{ paddingBottom: '10rem' }}>
      <S.Container>
        <GoBackHeader />
        <HeaderTitle pageInfo="Gestão de Pontos" />
        <ToastContainer autoClose={2000} />
      </S.Container>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', marginBottom: '20px' }}
      >
        <Typography variant={isMobile ? 'h6' : 'h5'}>Saldo disponível:</Typography>
        <Box sx={{ marginLeft: '10px' }}>
          {loadingBalancePoints ? (
            <Box sx={{ marginTop: '5px' }}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Typography variant={isMobile ? 'h6' : 'h5'}>
              <strong>{balancePoints ?? 0}</strong>
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={2}
          sx={{
            padding: '2rem 32px',
            margin: '0px 40px',
            width: '800px',
            height: 'max-content',
          }}
        >
          <form>
            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                onChange={handleChange(setValue)}
                value={value}
                type="number"
                id="value"
                required
                label="Quantidade de Pontos"
                margin="normal"
              />
              <TextField
                fullWidth
                required
                multiline
                type="text"
                value={eventDescription}
                id="eventDescription"
                label="Descrição"
                onChange={handleChange(setEventDescription)}
                margin="normal"
              />
              <Box
                sx={{
                  maxWidth: '400px',
                  marginTop: '17px',
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Destinatário</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={recipient}
                    label="Destinatário"
                    onChange={handleChangeRecipient}
                    required
                  >
                    {fromWhere === 'adminConfig'
                      ? [
                          <MenuItem key={1} value={1}>
                            Usuário
                          </MenuItem>,
                          <MenuItem key={2} value={2}>
                            Clube
                          </MenuItem>,
                          <MenuItem key={3} value={3}>
                            Estabelecimento
                          </MenuItem>,
                        ]
                      : fromWhere === 'estabelecimento'
                      ? [
                          <MenuItem key={1} value={1}>
                            Usuário
                          </MenuItem>,
                          <MenuItem key={3} value={3}>
                            Estabelecimento
                          </MenuItem>,
                        ]
                      : [
                          <MenuItem key={1} value={1}>
                            Usuário
                          </MenuItem>,
                          <MenuItem key={2} value={2}>
                            Clube
                          </MenuItem>,
                        ]}
                  </Select>
                </FormControl>

                {/* autocomplete estabelecimento */}
                {recipient === 3 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={optionsAutoComplete()}
                    getOptionLabel={(option) => option.nome}
                    sx={{ maxWidth: '400px', marginTop: '25px' }}
                    blurOnSelect
                    noOptionsText={
                      loading ? <CircularProgress size={15} /> : 'Nenhum resultado encontrado...'
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={labelType()}
                        onClick={() => setOptionsOpen(true)}
                        onFocus={() => setOptionsOpen(true)}
                        onBlur={() => setOptionsOpen(false)}
                      />
                    )}
                    ListboxProps={{ style: { maxHeight: '200px' } }}
                    open={optionsOpen}
                    onClose={() => setOptionsOpen(false)}
                    onChange={handleChangeAutocomplete}
                    onInputChange={(event, newInputValue) => {
                      setTextFieldValue(newInputValue);
                      if (newInputValue === '') {
                        fetchData('', 0, 10);
                      }
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={`${option.id}-${option.nome}`}>
                        {option.nome}
                      </li>
                    )}
                  />
                )}

                {/* autocomplete club */}
                {recipient === 2 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={optionsAutoComplete()}
                    getOptionLabel={(option) => option.name}
                    sx={{ maxWidth: '400px', marginTop: '25px' }}
                    blurOnSelect
                    noOptionsText={
                      loading ? <CircularProgress size={15} /> : 'Nenhum resultado encontrado...'
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={labelType()}
                        onClick={() => setOptionsOpen(true)}
                        onFocus={() => setOptionsOpen(true)}
                        onBlur={() => setOptionsOpen(false)}
                      />
                    )}
                    ListboxProps={{ style: { maxHeight: '200px' } }}
                    open={optionsOpen}
                    onClose={() => setOptionsOpen(false)}
                    onChange={handleChangeAutocomplete}
                    onInputChange={(event, newInputValue) => {
                      setTextFieldValue(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={`${option.clubeId}-${option.name}`}>
                        {option.name}
                      </li>
                    )}
                  />
                )}

                {/* dados do estabelecimento selecionado: */}
                {selectedEstablishment !== null && recipient === 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                      padding: '10px',
                      marginTop: '20px',
                      cursor: 'default',
                      border: '1px solid #e0e0e0',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Informações do estabelecimento:
                    </Typography>
                    <Divider />

                    <Typography variant="subtitle1" sx={{ marginTop: '20px' }}>
                      <span style={{ fontWeight: 550 }}>Id: </span>
                      {selectedEstablishment?.id ?? 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography variant="subtitle1">
                      <span style={{ fontWeight: 550 }}>Nome: </span>
                      {selectedEstablishment?.nome ?? 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography variant="subtitle1">
                      <span style={{ fontWeight: 550 }}>Id do contrato: </span>
                      {selectedEstablishment?.contractId ?? 'N/A'}
                    </Typography>
                  </Box>
                )}

                {/* dados do clube selecionado: */}
                {selectedClub !== null && recipient === 2 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                      padding: '10px',
                      marginTop: '20px',
                      cursor: 'default',
                      border: '1px solid #e0e0e0',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Informações do clube:
                    </Typography>
                    <Divider />

                    <Typography variant="subtitle1" sx={{ marginTop: '20px' }}>
                      <span style={{ fontWeight: 550 }}>Id do contrato: </span>
                      {selectedClub?.contractId ?? 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography variant="subtitle1">
                      <span style={{ fontWeight: 550 }}>Nome: </span>
                      {selectedClub?.name ?? 'N/A'}
                    </Typography>
                  </Box>
                )}
              </Box>

              {recipient === 1 && (
                <>
                  <FormLabel component="legend" sx={{ marginTop: '20px' }}>
                    Arquivo com a lista de usuários (uId) - Apenas formatos .txt e .csv são aceitos
                  </FormLabel>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <input
                      id="thumbnailStore"
                      accept=".txt"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={fileChangedHandler}
                    />
                    <label
                      htmlFor="thumbnailStore"
                      style={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Button
                        sx={{ textTransform: 'capitalize', fontWeight: '550' }}
                        variant="contained"
                        color="secondary"
                        startIcon={<AddPhotoAlternate />}
                        onClick={handleFileButtonClick}
                      >
                        Adicionar Arquivo
                      </Button>
                      {clientList && (
                        <Box sx={{ fontSize: '14px' }}>
                          <b>Arquivo selecionado: </b>
                          {clientList.name}
                        </Box>
                      )}
                    </label>
                  </Box>
                </>
              )}
            </FormControl>

            <Button
              sx={{
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
                fontWeight: '550',
              }}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={!loadingAdd && <Send />}
              disabled={loadingAdd}
              onClick={addEvent}
            >
              {loadingAdd ? (
                <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
              ) : (
                'Enviar'
              )}
            </Button>
          </form>
        </Paper>
      </Box>
    </div>
  );
}
