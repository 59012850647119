export default function reducer(state = {
  idUsuario: null,
  idGestor: null,
  idEstabelecimento: null,
  nomeEstabelecimento: null
}, action) {

  switch (action.type) {

    case "VALIDATORS_INFORMATION_PENDING":
      {
        return {
          ...state,
          idUsuario: null,
          idGestor: null,
          idEstabelecimento: null,
          nomeEstabelecimento: null
        };
      }

    case "VALIDATORS_INFORMATION_FULFILLED":
      {
        return {
          ...state,
          idUsuario: action.payload.idUsuario,
          idGestor: action.payload.idGestor,
          idEstabelecimento: action.payload.idEstabelecimento,
          nomeEstabelecimento: action.payload.nomeEstabelecimento
        };
      }

    case "VALIDATORS_INFORMATION_REJECTED":
      {
        return {
          ...state,
          idUsuario: null,
          idGestor: null,
          idEstabelecimento: null,
          nomeEstabelecimento: null
        };
      }

    default:
      return state;
  }

}