import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';
import {
    CircularProgress,
    Snackbar,
    Typography,
    Grid,
    OutlinedInput,
    Modal,
    Button
} from '@mui/material';
import axios from 'axios';
import { geocoding } from 'js/library/utils/API/apiGeocoding';
import { RegisterUsers } from '../../../library/utils/API/apiRegisterUsersBatch';
import { useModal } from '../Context/Attendance';
import { maskCEP, randomizeNumbersString } from 'js/library/utils/helpers';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 10px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.9)',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '38%',
    minWidth: '300px',
  },
  title: {
    fontSize: '1.0rem',
    marginBottom: '20px',
    marginTop: '20px',
  },
  contentButton: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 0px',
    marginTop: '20px',
    justifyContent: 'end',
  },
  button: {
    marginRight: '20px',
  },
}));

export default function ModalImageAspectRation({
  open,
  setStatus,
  setSearchInputText,
  setInputSearchCell,
  establishmentName,
  userCellphone,
}) {
  const classes = useStyles();
  const [cellphone, setCellphone] = useState();
  const [loading, setLoading] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newBirthDate, setNewBirthDate] = useState('');
  const [newZipCode, setNewZipCode] = useState('');
  const [newCountry] = useState('Brasil');
  const [newState, setNewState] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newNeighborhood, setNewNeighborhood] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newNumber, setNewNumber] = useState('');
  const [newExtra, setNewExtra] = useState('');
  const [newLatitude, setNewLatitude] = useState(0);
  const [newLongitude, setNewLongitude] = useState(0);

  const { setCurrentCellphone, setIsUserRegister } = useModal();
  const { id: establishmentId } = useParams();

  const [inputCellphone, setInputCellphone] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');
  const [fullMask, setFullMask] = useState('+55(99)99999-9999');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputCellphone('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMask(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setCellphone(currentDDD + inputCellphone.replace(/\D/g, ''));
  }, [inputCellphone]);

  const handleNewZipCode = (value) => {
    value = value.replace(/[a-z]|[A-Z]|-/g, '');

    setNewZipCode(value);

    if (value.length >= 8) {
      axios.get(`https://viacep.com.br/ws/${value}/json/`).then((result) => {
        const response = result.data;

        setNewState(response.uf);
        setNewCity(response.localidade);
        setNewNeighborhood(response.bairro);
        setNewStreet(response.logradouro);
      });
    }
  };

  const clearInputs = () => {
    setNewBirthDate('');
    setNewCity('');
    setNewEmail('');
    setNewExtra('');
    setNewLatitude(0);
    setNewLongitude(0);
    setNewName('');
    setNewNeighborhood('');
    setNewNumber('');
    setNewState('');
    setNewStreet('');
    setNewZipCode('');
    setSearchInputText('');
    setInputSearchCell('');
  };

  const handleRegister = (event) => {
    event.preventDefault();
    setLoading(true);

    const getIncomingBirthDate =
      newBirthDate === '' ? null : newBirthDate.split('/');

    let address = {
      zipCode: newZipCode,
      country: newCountry,
      state: newState,
      city: newCity,
      neighborhood: newNeighborhood,
      streetAve: newStreet,
      number: newNumber,
      extra: newExtra,
      latitude: newLatitude,
      longitude: newLongitude,
    };

    if (newZipCode === '' || newZipCode.length !== 8) {
      address = null;
    }

    for (let item in address) {
      if (address[item] === '') {
        delete address[item];
      }
    }

    const userInformation = {
      name: newName,
      email: newEmail,
      birthDate:
        getIncomingBirthDate !== null
          ? new Date(
              `${getIncomingBirthDate[2]}/${getIncomingBirthDate[1]}/${getIncomingBirthDate[0]}`
            ).getTime()
          : null,
      addressList: address === null ? [] : [address],
    };

    if (newEmail.trim() === '') {
      delete userInformation.email;
    }

    if (!userInformation.birthDate) {
      delete userInformation.birthDate;
    }

    const formatMask = '+' + fullMask.replace(/\D/g, '');

    if (cellphone.length !== formatMask.length) {
      setOpenSnackbar(true);
      setMessageSnackbar('Número do usuário inválido.');
      setLoading(false);
      return;
    }

    RegisterUsers(
      cellphone,
      userInformation,
      establishmentId,
      establishmentName
    )
      .then((result) => {
        setLoading(false);
        setStatus(false);
        setOpenSnackbar(true);
        setMessageSnackbar('Usuário cadastrado com sucesso!');
      })
      .catch((error) => {
        setLoading(false);
        setOpenSnackbar(true);
        setMessageSnackbar('Ops, usuário não foi cadastrado.');
      });
  };

  const closeModalRegister = () => {
    clearInputs();
    setCurrentCellphone('');
    setIsUserRegister(false);
    setStatus({ openModal: false });
  };

  useEffect(() => {
    if (newStreet === '') {
      return;
    }
    function searchLatLong() {
      const address = encodeURIComponent(
        `${newStreet} ${newNumber} ${newNeighborhood} ${newCity} ${newState}`
      );

      geocoding(address)
        .then((result) => {
          if (result.status === 'OK' && result.results.length > 0) {
            setNewLatitude(result.results[0].geometry.location.lat);
            setNewLongitude(result.results[0].geometry.location.lng);
          } else {
            setNewLatitude(0);
            setNewLongitude(0);
          }
        })
        .catch((error) => {
          setNewLatitude(0);
          setNewLongitude(0);
        });
    }

    searchLatLong();
  }, [newStreet, newCity, newNeighborhood, newNumber, newState]);

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={() => closeModalRegister()}
        closeAfterTransition>
        <div className={classes.paper}>
          <Typography variant='h4' color='primary' className={classes.title}>
            Este usuário não possui cadastro. Deseja cadastrá-lo?
          </Typography>
          <form onSubmit={handleRegister} className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <OutlinedInput
                  fullWidth={true}
                  value={newName}
                  autoFocus
                  required
                  placeholder='Nome completo*'
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  fullWidth={true}
                  value={newEmail}
                  type='email'
                  placeholder='E-mail'
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <S.searchCellBox height={'55px'}>
                  <select
                    style={{
                      fontFamily:
                        "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    value={currentCountry}
                    onChange={handleCountryChange}>
                    {phoneMaskArray.map((country, index) => (
                      <option
                        style={{
                          fontFamily:
                            "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                        }}
                        key={country.id}
                        value={[country.ddd, country.mask, country.fullMask]}>
                        {country.emoji} {country.initials} {country.ddd}
                      </option>
                    ))}
                  </select>

                  <InputMask
                    mask={inputMask}
                    value={inputCellphone}
                    placeholder={randomizeNumbersString(inputMask)}
                    onChange={(e) => setInputCellphone(e.target.value)}
                    className='searchInput'
                  />
                </S.searchCellBox>
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newBirthDate}
                  type='date'
                  placeholder='Data de nascimento'
                  onChange={(e) => setNewBirthDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newZipCode}
                  placeholder='CEP'
                  onChange={(e) => handleNewZipCode(maskCEP(e.target.value))}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newCountry}
                  disabled
                  placeholder='País'
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newState}
                  disabled
                  placeholder='Estado'
                  onChange={(e) => setNewState(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newCity}
                  disabled
                  placeholder='Cidade'
                  onChange={(e) => setNewCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  fullWidth={true}
                  value={newNeighborhood}
                  disabled
                  placeholder='Bairro'
                  onChange={(e) => setNewNeighborhood(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  fullWidth={true}
                  value={newStreet}
                  disabled
                  placeholder='Rua'
                  onChange={(e) => setNewStreet(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newNumber}
                  placeholder='Número'
                  onChange={(e) => setNewNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth={true}
                  value={newExtra}
                  placeholder='Complemento'
                  onChange={(e) => setNewExtra(e.target.value)}
                />
              </Grid>
            </Grid>

            <div className={classes.contentButton}>
              <Button
                onClick={() => closeModalRegister()}
                type='button'
                className={classes.button}
                variant='contained'
                color='primary'
                disableElevation>
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disableElevation>
                {loading ? (
                  <CircularProgress
                    size={25}
                    color='default'
                    classes={{ root: classes.loadingIcon }}
                  />
                ) : (
                  'Cadastrar'
                )}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={messageSnackbar}
      />
    </>
  );
}
