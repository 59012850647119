import React, { useState, useEffect } from 'react';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';

// STYLES, ICONS AND COMPONENTS
import * as S from './styles';
import { TextField, Button, Typography, Box, Grid, CircularProgress, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

// APIS
import { postPartnerCode } from 'js/library/utils/API/Campaigns/apiPostPartnerCode';
import { getPartnerCodesByCampaign } from 'js/library/utils/API/Campaigns/apiGetPartnerCode';
import { deletePartnerCode } from 'js/library/utils/API/Campaigns/apiDeletePartnerCode';

export default function PartnerCode(props) {
  // STATES
  const [fields, setFields] = useState([]);
  const [removedFields, setRemovedFields] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [duplicateIndexes, setDuplicateIndexes] = useState([]);
  const [loadingStates, setLoadingStates] = useState({}); // New state for loading

  // ID'S
  const campaignId = props.match.params.id;
  const ownerId = props.match.params.ownerId;
  console.log(ownerId);

  // BUSCA INFORMAÇÕES DE CÓDIGOS EXISTENTES
  useEffect(() => {
    getPartnerCodesByCampaign(campaignId)
      .then((res) => {
        const formattedFields = Object.entries(res)
          .filter(([key, value]) => key !== 'success' && typeof value === 'object')
          .map(([key, value]) => ({
            partnerCode: key,
            relatedPromotion: value.store,
            codeExists: true,
          }));

        setFields(formattedFields);
      })
      .catch((err) => {
        toast.error('Erro ao carregar códigos de parceiro existentes.');
        console.log(err);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [campaignId]);

  const handleAddField = () => {
    const newField = { partnerCode: '', relatedPromotion: '', codeExists: false };
    setFields([...fields, newField]);
  };

  const handleChangeField = (index, event) => {
    const { name, value } = event.target;
    const newFields = fields.slice();
    newFields[index][name] = value;
    setFields(newFields);

    if (name === 'partnerCode') {
      const duplicate = fields.some(
        (field, fieldIndex) => fieldIndex !== index && field.partnerCode === value
      );

      if (duplicate) {
        toast.error(`O código de parceiro ${value} já existe. Por favor, tente outro.`);
        setDuplicateIndexes((prevIndexes) => [...prevIndexes, index]);
      } else {
        setDuplicateIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));
      }
    }
  };

  const handleDelete = async (index) => {
    setLoadingStates((prev) => ({ ...prev, [index]: { delete: true } }));
    const fieldToRemove = fields[index];
    const newFields = fields.slice();
    newFields.splice(index, 1);

    try {
      if (fields[index].codeExists) {
        await deletePartnerCode(fields[index].partnerCode);
        toast.success('Código do parceiro deletado.');
      } else {
        setRemovedFields([...removedFields, fieldToRemove]);
      }
    } catch (err) {
      toast.error(err);
      console.log('error:', err);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: { delete: false } }));
      setFields(newFields);
    }
  };

  const saveCodePartner = async (index) => {
    setLoadingStates((prev) => ({ ...prev, [index]: { save: true } }));

    try {
      const data = fields[index];
      const partnerCodesData = {
        campaign: campaignId,
        partnerCode: data.partnerCode,
        partnerId: ownerId,
        store: data.relatedPromotion,
      };

      await postPartnerCode(partnerCodesData);
      toast.success('Código do parceiro adicionado com sucesso.');
    } catch (err) {
      toast.error(err);
      console.log('error:', err);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: { save: false } }));
    }
  };

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <S.Container>
      <GoBackHeader />
      <Typography variant="h4" component="h1" gutterBottom sx={{ marginBottom: '60px' }}>
        Códigos do Parceiro
      </Typography>
      {fields.map((field, index) => {
        return (
          <Grid
            container
            spacing={2}
            key={index}
            sx={{ marginBottom: '15px', padding: '0px 80px' }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                label="Código do parceiro"
                name="partnerCode"
                value={field.partnerCode}
                onChange={(event) => handleChangeField(index, event)}
                error={duplicateIndexes.includes(index)}
                fullWidth
                disabled={fields[index].codeExists}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}
            >
              <TextField
                label="Promoção relacionada"
                name="relatedPromotion"
                value={field.relatedPromotion}
                onChange={(event) => handleChangeField(index, event)}
                error={duplicateIndexes.includes(index)}
                fullWidth
                disabled={fields[index].codeExists}
              />
              <div style={{ display: 'flex', position: 'absolute', left: '100%' }}>
                {field.codeExists === false && (
                  <Tooltip title="Salvar" placement="top">
                    <S.IconButton onClick={() => saveCodePartner(index)} $bgcolor="#28a745">
                      {loadingStates[index]?.save ? (
                        <CircularProgress size={22} thickness={5} style={{ color: '#fff' }} />
                      ) : (
                        <SaveRoundedIcon color="primary" size={25} style={{ color: '#fff' }} />
                      )}
                    </S.IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Apagar" placement="top">
                  <S.IconButton onClick={() => handleDelete(index)} $bgcolor="#ff0000">
                    {loadingStates[index]?.delete ? (
                      <CircularProgress size={22} thickness={5} style={{ color: '#fff' }} />
                    ) : (
                      <DeleteIcon size={25} style={{ color: '#fff' }} />
                    )}
                  </S.IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        );
      })}
      <Box mt={4} display="flex" justifyContent="center" sx={{ width: '100%' }}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddField}>
          Adicionar Novo
        </Button>
      </Box>
    </S.Container>
  );
}
