import { firebaseStorage } from 'js/library/utils/firebaseUtils';
import Axios from 'axios';

export function deleteImage(pastaStorage, image) {
  if (!pastaStorage || !image) return 'Falha';

  return new Promise((resolve, reject) => {
    if (Array.isArray(image)) {
      Object.keys(image).map((item, i) => deleteImg(pastaStorage, image[i]));

      resolve(image);
    } else {
      deleteImg(pastaStorage, image).then(() => {
        resolve(true);
      });
    }
  });
}

//upload de imagem no Storage do Firebase
export function uploadImage(pastaStorage, newImg, newImagesName) {
  if (!pastaStorage || !newImg) return 'Falha';

  return new Promise((resolve, reject) => {
    if (Array.isArray(newImagesName)) {
      Object.keys(newImg).map((item, i) =>
        uploadImageToStorage(pastaStorage, newImg[i], newImagesName[i])
      );

      resolve(newImagesName);
    } else {
      uploadImageToStorage(pastaStorage, newImg, newImagesName).then(() => {
        resolve(true);
      });
    }
  });
}

export function uploadImageToStorage(pastaStorage, newImg, newImageName) {
  const newStorageRef = firebaseStorage.ref(pastaStorage).child(newImageName);

  return new Promise((resolve, reject) => {
    newStorageRef
      .put(newImg)
      .then(function (snapshot) {
        resolve(snapshot);
      })
      .catch(function (error) {
        // Handle any errors
        reject(error);
      });
  });
}

function deleteImg(pastaStorage, image) {
  const oldStorageRef = firebaseStorage.ref(pastaStorage).child(image);

  return new Promise((resolve, reject) => {
    oldStorageRef
      .delete()
      .then(function (img) {
        // Imagem deletada

        resolve(img);
      })
      .catch(function (error) {
        // Algum erro ocorreu. Verificar existenciada imagem no storage, ou se o estabelecimento
        //não possuia imagem
        resolve(error);
      });
  });
}

//funcao para baixar imagens do storage do firebase
export function downloadImage(pastaStorage, img) {
  const storage = firebaseStorage;
  return new Promise((resolve, reject) => {
    storage
      .ref(pastaStorage)
      .child(img)
      .getDownloadURL()
      .then(function (url) {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        /* xhr.onload = function (event) {
                const blob = xhr.response;
            }; */

        xhr.open('GET', url);
        xhr.send();
        resolve(url);
      })
      .catch(function (error) {
        // Handle any errors
        reject(error);
      });
  });
}

export async function downloadImageAsBlob(url) {
  return new Promise(async (resolve, reject) => {
    await Axios.request({ url: url, method: 'GET', responseType: 'blob' })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(function (error) {
        // Handle any errors
        reject('ERROR!', error);
      });
  });
}
