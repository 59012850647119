import axios from 'axios';
import { firebaseAuth } from 'js/library/utils/firebaseUtils';
import cfac22 from '../cfac22';

export function getDataListRestApi(node) {
  return new Promise(function (resolve, reject) {
    firebaseAuth.onAuthStateChanged(function (user) {
      try {
        user
          .getIdToken(true)
          .then(function (idToken) {
            axios
              .get(cfac22('FIREBASE_DATABASEURL') + node + '.json?auth=' + idToken, {
                headers: {
                  access_token: cfac22('FIREBASE_APIKEY'),
                },
              })
              .then((result) => {
                resolve(result.data);
              })
              .catch((error) => {
                // console.log(error);

                reject(error);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      } catch (err) {}
    });
  });
}
