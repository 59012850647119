import React from "react";

import { Box, Grid } from "@mui/material";

const StepsGrid = (props) => {
  const { showInitial, placeholderBanner } = props;

  return (
    <div sx={{ overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          justifyContent: "space-between",
          marginTop: { md: "0" },
          height: "93.7vh",
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          lg={6}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              alignItems: "center",
              justifyContent: "center",
              minHeight: { md: "100%" },
              width: { xs: "100%", md: "60%" },
              paddingLeft: { xs: "3.5rem", md: 0 },
              paddingRight: { xs: "3.5rem", md: 0 },
            }}
          >
            {props.children}
          </Box>
        </Grid>

        <Grid
          item
          xs={showInitial ? 0 : 12}
          md={6}
          lg={6}
          sx={{
            backgroundColor: "transparent",
            backgroundImage: `url(${placeholderBanner})`,
            backgroundSize: "cover",
            marginTop: { xs: "0.8rem", md: "0" },
            width: { xs: "100%", md: "60%" },
          }}
        ></Grid>
      </Box>
    </div>
  );
};

export default StepsGrid;
