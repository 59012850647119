export default function reducer(state={
    loading: false,
    error: null,
    success: false
  }, action) {
  
    switch(action.type) {
  
      case "PAYMENT_INFORMATION_PENDING": {
        return {
          ...state,
          loading: true,
          success: false,
          error: null
        };
      }
  
      case "PAYMENT_INFORMATION_FULFILLED": {
        return {
          ...state,
          loading: false,
          success: true,
          error: null
        };
      }
  
      case "PAYMENT_INFORMATION_REJECTED": {
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };
      }
  
      default: return state;
  
    }
  }