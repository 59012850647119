import React from 'react';

import * as S from './styles';

import logoPlaceholder from 'styles/assets/placeholder/placeholder_card.png';
import bannersPlaceholder from 'styles/assets/placeholder/placeholder_triibo.png';

import { RiWallet3Line } from 'react-icons/ri';
import { AiOutlineMail, AiOutlineHome } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';

import cfac22 from 'js/library/utils/cfac22';

const PreviewClub = (props) => {
  const { clubForm, filesForUpload } = props;

  return (
    <>
      <S.ClubPreviewContainer>
        <S.ClubPreview>
          <S.ClubHeader previewColor={clubForm.colorPrimary}>
            <S.SearchGroup>
              {filesForUpload.logo ? (
                <S.LogoPreview src={filesForUpload.logo} />
              ) : (
                <S.LogoPreview
                  src={
                    clubForm.logo !== undefined
                      ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.logo + '?alt=media'
                      : logoPlaceholder
                  }
                />
              )}
              {/* <S.MockSearchBar /> */}
            </S.SearchGroup>

            <S.ShortcutHeader style={{ margin: '0 2rem' }}>
              <S.IconContainer highlight={true} previewColor={clubForm.colorSecondary}>
                <AiOutlineHome styled={{ fontSize: '100%' }} />
              </S.IconContainer>
              <S.IconContainer highlight={true} previewColor={clubForm.colorSecondary}>
                <RiWallet3Line styled={{ fontSize: '100%' }} />
              </S.IconContainer>
              <S.IconContainer highlight={true} previewColor={clubForm.colorSecondary}>
                <AiOutlineMail styled={{ fontSize: '100%' }} />
              </S.IconContainer>
            </S.ShortcutHeader>

            <S.IconMenu>
              <S.IconContainer>
                <GiHamburgerMenu value={{ size: 70 }} />
              </S.IconContainer>
            </S.IconMenu>
          </S.ClubHeader>
          <S.ClubBody>
            <S.PreviewCol>
              <S.PreviewCardBorder />
              {filesForUpload.bannerLeft ? (
                <S.PreviewBanner src={filesForUpload.bannerLeft} />
              ) : (
                <S.PreviewBanner
                  src={
                    clubForm.bannerLeft !== undefined
                      ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.bannerLeft + '?alt=media'
                      : bannersPlaceholder
                  }
                />
              )}
              {/* <S.PreviewBanner src={previewCardsImages} /> */}
            </S.PreviewCol>
            <S.PreviewCol>
              <S.PreviewCardBorder></S.PreviewCardBorder>
            </S.PreviewCol>
            <S.PreviewCol>
              {filesForUpload.bannerRight ? (
                <S.PreviewBanner src={filesForUpload.bannerRight} />
              ) : (
                <S.PreviewBanner
                  src={
                    clubForm.bannerRight !== undefined
                      ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.bannerRight + '?alt=media'
                      : bannersPlaceholder
                  }
                />
              )}
            </S.PreviewCol>
          </S.ClubBody>
        </S.ClubPreview>
      </S.ClubPreviewContainer>
    </>
  );
};

export default PreviewClub;
