const defaultState = {
    hasPromo: [],
    hasClient: {
        registered: [],
        notRegistered: []
    },
    loading: true,
};

function reducer(state = defaultState, action) {
    switch (action.type) {
    case 'TRIAL_VERIFY': {
        let tempState = { ...state };

        tempState.hasPromo = action.payload.hasPromo;
        tempState.hasClient = action.payload.hasClient;

        return tempState;
    }

    default:
        return state;
    }
}

export default reducer;