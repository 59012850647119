import React, { useEffect, useState } from 'react';

import { Button, CircularProgress, Modal, Typography } from '@mui/material';

import * as S from '../styles';
import * as D from 'js/components/DesignSystem/styles';
import InputMask from 'react-input-mask';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { findValueInArray, randomizeNumbersString } from 'js/library/utils/helpers';
import phone from 'phone';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import apiChangeCellphone from 'js/library/utils/API/SearchUser/apiChangeCellphone';

export default function ChangeCellphone({
  userInfo,
  userUid,
  isModalChangeCellphoneOpen,
  handleCloseModal,
}) {
  const [inputPhone1, setInputPhone1] = useState('');
  const [inputMask1, setInputMask1] = useState('(99) 99999-9999');
  const [currentCountry1, setCurrentCountry1] = useState('');

  const [fullNumber2, setFullNumber2] = useState('');
  const [inputPhone2, setInputPhone2] = useState('');
  const [currentDDD2, setCurrentDDD2] = useState('+55');
  const [inputMask2, setInputMask2] = useState('(99) 99999-9999');
  const [currentCountry2, setCurrentCountry2] = useState('');

  const [fullNumber3, setFullNumber3] = useState('');
  const [inputPhone3, setInputPhone3] = useState('');
  const [currentDDD3, setCurrentDDD3] = useState('+55');
  const [inputMask3, setInputMask3] = useState('(99) 99999-9999');
  const [currentCountry3, setCurrentCountry3] = useState('');

  const [loading, setLoading] = useState(false);

  const handleCountry1Change = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputPhone1('');
    setInputMask1(valuesArr[1]);
    setCurrentCountry1(e.target.value);
  };

  const handleCountry2Change = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputPhone2('');
    setCurrentDDD2(valuesArr[0]);
    setInputMask2(valuesArr[1]);
    setCurrentCountry2(e.target.value);
  };

  const handleCountry3Change = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputPhone3('');
    setCurrentDDD3(valuesArr[0]);
    setInputMask3(valuesArr[1]);
    setCurrentCountry3(e.target.value);
  };

  const handlePhone2Change = (e) => {
    setInputPhone2(e.target.value);
    setFullNumber2(currentDDD2 + e.target.value.replace(/\D/g, ''));
  };

  const handlePhone3Change = (e) => {
    setInputPhone3(e.target.value);
    setFullNumber3(currentDDD3 + e.target.value.replace(/\D/g, ''));
  };

  useEffect(() => {
    const cellPhoneInfos = phone(findValueInArray(userInfo.contactList, 'type', 'cellPhone').value);

    const maskFilter = phoneMaskArray.filter((mask) => mask.ddd === cellPhoneInfos.countryCode);

    setInputPhone1(cellPhoneInfos.phoneNumber.split(maskFilter[0].ddd)[1]);
    setInputMask1(maskFilter[0].mask);
    setCurrentCountry1([maskFilter[0].ddd, maskFilter[0].mask, maskFilter[0].fullMask]);
  }, []);

  const handleChangePhone = () => {
    if (fullNumber3 !== fullNumber2) {
      return toast.warning('Número de confirmação incorreto.');
    }

    setLoading(true);
    const newPhone = fullNumber3;

    if (userInfo) {
      const oldPhone = findValueInArray(userInfo.contactList, 'type', 'cellPhone').value;

      apiChangeCellphone(userUid, oldPhone, newPhone)
        .then((res) => {
          setLoading(false);
          toast.success(res.data.result);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.data.error);
        });
    }
  };

  return (
    <Modal
      open={isModalChangeCellphoneOpen}
      onClose={() => handleCloseModal('closeModalChangeCellphone')}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <S.ModalBox>
          <div className="header">
            <S.IconPhone>
              <BsFillTelephoneFill color="#fff" />
            </S.IconPhone>
            <Typography variant="h2" component="h2" id="modal-title">
              Alterar telefone
            </Typography>
          </div>

          <S.ChangeCellphoneContent>
            <S.CellphoneBoxChange>
              <D.TriiboH4>Telefone atual</D.TriiboH4>
              <S.InputChangeContainer>
                <S.BoxMigrateCellphoneInput>
                  <select
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      flex: '1',
                      height: '100%',
                      border: 'none',
                      outline: 'none',
                      fontSize: '17px',
                      color: '#383838d6',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    value={currentCountry1}
                    disabled
                    onChange={handleCountry1Change}
                  >
                    {phoneMaskArray.map((country, index) => (
                      <option
                        style={{
                          fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                        }}
                        key={country.id}
                        value={[country.ddd, country.mask, country.fullMask]}
                      >
                        {country.emoji} {country.initials} {country.ddd}
                      </option>
                    ))}
                  </select>
                  <InputMask
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      fontSize: '17px',
                      color: '#383838d6',
                      border: 'none',
                    }}
                    mask={inputMask1}
                    value={inputPhone1}
                    placeholder={randomizeNumbersString(inputMask1)}
                    onChange={(e) => setInputPhone1(e.target.value)}
                    className="inputSearch"
                    disabled
                  />
                </S.BoxMigrateCellphoneInput>
              </S.InputChangeContainer>
            </S.CellphoneBoxChange>

            <S.CellphoneBoxChange>
              <D.TriiboH4>Novo telefone</D.TriiboH4>
              <S.InputChangeContainer>
                <S.BoxMigrateCellphoneInput>
                  <select
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      flex: '1',
                      height: '100%',
                      border: 'none',
                      outline: 'none',
                      fontSize: '17px',
                      color: '#383838d6',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    value={currentCountry2}
                    onChange={handleCountry2Change}
                  >
                    {phoneMaskArray.map((country, index) => (
                      <option
                        style={{
                          fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                        }}
                        key={country.id}
                        value={[country.ddd, country.mask, country.fullMask]}
                      >
                        {country.emoji} {country.initials} {country.ddd}
                      </option>
                    ))}
                  </select>
                  <InputMask
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      fontSize: '17px',
                      color: '#383838d6',
                      border: 'none',
                    }}
                    mask={inputMask2}
                    value={inputPhone2}
                    placeholder={randomizeNumbersString(inputMask2)}
                    onChange={handlePhone2Change}
                    className="inputSearch"
                  />
                </S.BoxMigrateCellphoneInput>
              </S.InputChangeContainer>
            </S.CellphoneBoxChange>

            <S.CellphoneBoxChange>
              <D.TriiboH4>Confirmar telefone</D.TriiboH4>
              <S.InputChangeContainer>
                <S.BoxMigrateCellphoneInput>
                  <select
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      flex: '1',
                      height: '100%',
                      border: 'none',
                      outline: 'none',
                      fontSize: '17px',
                      color: '#383838d6',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    value={currentCountry3}
                    onChange={handleCountry3Change}
                  >
                    {phoneMaskArray.map((country, index) => (
                      <option
                        style={{
                          fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                        }}
                        key={country.id}
                        value={[country.ddd, country.mask, country.fullMask]}
                      >
                        {country.emoji} {country.initials} {country.ddd}
                      </option>
                    ))}
                  </select>
                  <InputMask
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      fontSize: '17px',
                      color: '#383838d6',
                      border: 'none',
                    }}
                    mask={inputMask3}
                    value={inputPhone3}
                    placeholder={randomizeNumbersString(inputMask3)}
                    onChange={handlePhone3Change}
                    className="inputSearch"
                  />
                </S.BoxMigrateCellphoneInput>
              </S.InputChangeContainer>
            </S.CellphoneBoxChange>
          </S.ChangeCellphoneContent>

          <S.ActionsButtons>
            <Button
              style={{
                background: '#DBDBDb',
                color: '#555555',
              }}
              variant="contained"
              onClick={() => handleCloseModal('closeModalChangeCellphone')}
            >
              Voltar
            </Button>
            <Button variant="contained" disabled={loading} onClick={handleChangePhone}>
              {loading && <CircularProgress style={{ marginRight: '0.5rem' }} size="23px" />}
              Salvar
            </Button>
          </S.ActionsButtons>
        </S.ModalBox>
      </>
    </Modal>
  );
}
