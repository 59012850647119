import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function getSegmentById(segmentId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/userSegment/get/${segmentId}`, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then((result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          return reject(error.response.data);
        });
    });
  });
}
