import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  // inicio styles index
  backButton: {
    textTransform: "capitalize",
    fontSize: "25px",
    fontWeight: "700",
    cursor: "pointer",
    float: "right",
    marginRight: "1.3rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
    },
  },

  title: {
    fontWeight: "bold",
  },

  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "35px 22px 100px",
    maxWidth: "2200px",
  },
  
  content: {
    margin: "0 auto",
    width: "90%",
    marginTop: "1rem",
  },
  
  contentTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "95%",
    marginTop: "1rem",
  },

  tabPanelArea: {
    marginTop: "1rem",
  },
  // final styles index

  // inicio styles template
  boxTemplate: {
    border: "1px solid #D1CFCF",
    width: "95%",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0.8rem",
    marginTop: "1rem",
  },
  
  imgTemplate: {
    width: "100%",
    height: "100%",
    // object-fit: ''
    // height: "250px",
  },
  // final styles template

  // inicio styles Preview
  boxPreview: {
    border: "1px solid #D1CFCF",
    width: "100%",
    height: "400px",
    maxWidth: "550px",
    marginTop: "1rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  imageWrapper: {
    position: "relative",
  },

  inputElementColor: {
    marginBottom: '10px'
  },

  imagePreview: {
   width: "100%",
   height: "100%"
  },

  contentPreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  maskPreview: {
    height: "500px",
    width: '80%',
    position: 'absolute',
    zIndex: '99',
  },
  // final styles template

  // inicio styles Elementos
  boxElement: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

    width: "100%",
  },

  inputElementText: {
    width: "100%",
    marginBottom: "1.5rem",
  },

  elementTypeTitle: { 
    fontWeight: "600",
    fontSize: '1rem', 
    marginBottom: "1rem", 
  },

  elementImage: {
    width: '200px',
    height: '150px',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 8px 0px',

    '& + img': {
      marginLeft: '1rem',
    }
  },

  buttonAddElement: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  // final styles Elementos

  // image free 
  imageContainerFree: {
    position: "relative",
    width: "70%",

    '& #arrow-image': {
      position: 'absolute', 
      width: "40px", 
      height: "150px", 
      display: "flex", 
      alignItems: "center", 
      justifyContent: "center", 
      zIndex: "99", 
      overflow: "hidden", 
      cursor: "pointer", 
      opacity: "0",
    },

    '&:hover #arrow-image': {
      opacity: '1',
    },

  },

  arrowLeft: {
    left: 0, 

    '& > svg': {
      fontSize: '60', 
      color: '#FFFF',
      backgroundColor: "rgba(0,0,0,0.5)",
      borderRadius: "50%",
      padding: "5px",
    }
  },

  arrowRight: {
    right: 0, 

    '& > svg': {
      fontSize: '60', 
      color: '#FFFF',
      backgroundColor: "rgba(0,0,0,0.5)",
      borderRadius: "50%",
      padding: "5px",
    }
  },

  imageListContent: {
    overflowX: "hidden",
  },

  imageList: {
    transition: "all ease 0.5s"
  },

  imageItem: {
    display: "inline-block", 
    width: "150px",
    height: "150px",

    '& > img': {
      width: "100%",
      height: "100%",
      
      transform: "scale(0.9)",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      cursor: "pointer",
    },

  },

  contentAreaButtonFile: {
    display: "flex", 
    justifyContent: "flex-start",
    marginTop: '20px',
  },

  buttonFile: {
    marginTop: "15px",
    fontWeight: "550",
    color: "#fff",
    textTransform: "capitalize",
  }
  // final styles image free
}));
