import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  Grid,
  Modal,
  Tooltip,
  Button,
  FormLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Snackbar,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import {
  Save,
  AddPhotoAlternate,
  Help,
  ArrowBack,
  CropFree,
} from "@mui/icons-material";

import { Cropper } from "react-image-cropper";
import Loading from "js/containers/Loading/Loading";

import { downloadImage } from "js/library/services/StorageManager";

import { organizationInformationAction } from '../../core/actions/organizationInformationAction';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    height: "70%",
    width: "60%",
    backgroundColor: "white",
    padding: "30px",
  };
}

/**
 * This component is responsible for registering organizations through a registration form.
 *
 * @accessMethod 					{string}			Valor pré definido public ou private.
 * @administratorsList		{Array}				Administradores da organização(definir como será a entrada desse dado).
 * @coverImage 					{String}    	Nome da imagem.
 * @coverImage 						{String}			Nome da imagem. (Depreciado)
 * @description 					{String}    	["Para você que mora ou frequenta a Vila Clementino, aqui você tem informações sobre o bairro além de promoções exclusivas em diversos estabelecimentos parceiros!"]
 * @lockdown 							{Boolean}			[false]
 * @name									{String}			["Vila Clementino"]										Nome da organização.
 * @orgID									{String}			["triibo_vilaclementino"]							Igual ao id da coluna na tabela.
 * @owner									{String}			["7pCUUZjOf6VlxY3S73m9LfsBU2K3"]			Dono da organização(definir como será a entrada desse dado).
 * @tags									{String[]}		[ "seguros" ]													Array com tags e valor livre.
 * @thumbnail							{String}			["triibo_vilaclementino:thumbnail"]		Nome da imagem.
 * @type									{String}			["triibo"]														String fixa c/ valor "triibo".
 * @visible								{Boolean}
 *
 * @returns {JSX:Component}
 */

class OrganizationInformation extends Component {
  constructor(props) {
    super(props);

    const { state: childState } = props.location;

    this.state = {
      accessMethod: childState.accessMethod || "private",
      administratorsList: childState.administratorsList || [],

      description: childState.description || "",
      lockdown: childState.lockdown || false,
      name: childState.name || "",
      orgID: childState.orgID || null,
      owner: childState.owner || "",
      tags: childState.tags || [],
      type: childState.type || "triibo",
      visible: childState.visible || false,

      //imagens
      thumbnail: null,
      oldThumbnail: childState.thumbnail || null,
      thumbnailFile: childState.downloadedThumb || null,

      coverImage: null,
      oldCoverImage: childState.coverImage || null,
      coverImageFile: null,

      logoImage: null,
      oldLogoImage: childState.logoImage || null,
      logoImageFile: null,

      imageRatio: 4 / 3,

      loading: false,
      submitLoading: false,
      openModal: false,

      indexCrop: 0,
    };

    this.fetchOrganizationFiles();

    window.pathname = null;
  }

  fetchOrganizationFiles() {
    if (this.state.oldCoverImage) {
      const splitedCoverImageData = this.state.oldCoverImage.split("/");

      downloadImage(splitedCoverImageData[0], splitedCoverImageData[1])
        .then((downloadedImage) => {
          this.setState({
            coverImageFile: downloadedImage,
          });
        })
        .catch(() => {
          // console.log(
          //   "Foto mobile não encontrada:",
          //   this.props.location.state.coverImage
          // );
          this.setState({
            coverImage: null,
          });
        });
    }

    if (
      this.state.oldThumbnail 
      // &&
      // this.state.thumbnailFile ===
      //   "/static/media/placeholder_thumbnail.145b14e0.png"
    ) {
      const splitedThumbnailData = this.state.oldThumbnail.split("/");

      downloadImage(splitedThumbnailData[0], splitedThumbnailData[1])
        .then((downloadedImage) => {
          this.setState({
            thumbnailFile: downloadedImage,
          });
        })
        .catch(() => {
          // console.log(
          //   "Foto mobile não encontrada:",
          //   this.props.location.state.thumbnail
          // );
          this.setState({
            thumbnail: null,
          });
        });
    }

    if (this.state.oldLogoImage) {
      const splitedLogoImageData = this.state.oldLogoImage.split("/");

      downloadImage(splitedLogoImageData[0], splitedLogoImageData[1])
        .then((downloadedImage) => {
          this.setState({
            logoImageFile: downloadedImage,
          });
        })
        .catch(() => {
          // console.log(
          //   "Foto mobile não encontrada:",
          //   this.props.location.state.logoImage
          // );
          this.setState({
            logoImage: null,
          });
        });

      // console.log('downloadimage:', this.state);
    }
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    if (name === "quantity" && event.target.value < 0) {
      this.setState({ openCheck: true });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      goBack: true,
    });
  };

  fileChangedHandler = (name) => (event) => {
    if (name === "fotoThumb") {
      this.setState({ oldThumbnail: this.state.thumbnail, imageRatio: 4 / 4 });

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        this.setState({
          openModal: true,
          imageToCrop: data,
          imageCroped: "thumbnailFile",
          thumbnail: true,
        });
      }
    }

    if (name === "coverImage") {
      this.setState({
        oldCoverImage: this.state.coverImage,
        imageRatio: 4 / 3,
      });

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        this.setState({
          openModal: true,
          imageToCrop: data,
          imageCroped: "coverImageFile",
          coverImage: true,
        });
      }
    }

    if (name === "logoImage") {
      this.setState({
        oldLogoImage: this.state.logoImage,
        imageRatio: 95 / 12,
      });

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        this.setState({
          openModal: true,
          imageToCrop: data,
          imageCroped: "logoImageFile",
          logoImage: true,
        });
      }
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    //verificando se é um array de imagens
    let data = [];
    if (this.state[newCrop] !== null) {
      const a = this.state.indexCrop === 0 ? [] : this.state[newCrop];
      data = a.concat(node);
    } else {
      data = [node];
    }

    this.setState({
      [newCrop]: this.state.imageCroped === "photoListFile" ? data : node,
      openModal: endArray === undefined ? false : true,
      indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
      goBack: true,
      imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
    });
  }

  handleCloseAlert = () => {
    this.setState({ openCheck: false });
  };

  handleSubmit = async () => {
    const {
      coverImageFile,
      thumbnailFile,
      oldThumbnail,
      oldCoverImage,
      logoImageFile,
      oldLogoImage,
    } = this.state;

    if (!coverImageFile || !thumbnailFile || !logoImageFile) {
      this.setState({
        openCheck: true,
        errorMessage: "As imagens são obrigatórias.",
      });
    } else {
      this.setState({
        loading: true,
        submitLoading: true,
        coverImage: !oldCoverImage,
        thumbnail: !oldThumbnail,
        logoImage: !oldLogoImage,
      });

      this.props
        .organizationInformationComponent(this.state)
        .payload.catch(() => this.setState({ openCheck: true }))
        .finally(() =>
          this.setState({
            submitLoading: false,
            errorMessage: "Erro desconhecido!",
          })
        );
    }
  };

  handleCloseModal() {
    this.setState({ openModal: false });
  }

  render = () => {
    if (this.props.loading && this.state.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.props.location.state,
            }}
          />
        );
      } else {
        return (
          <div style={{ padding: '4rem 32px' }}>
            <GoBackHeader />
           <HeaderTitle pageInfo='Cadastrar Organização'/>
            <br />
            <br />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSubmit();
              }}
            >
              <br />
              <br />
              <FormLabel component="legend">Método de Acesso</FormLabel>
              <FormControl className="input-field">
                <Select
                  style={{ minWidth: "500px" }}
                  value={this.state.accessMethod}
                  onChange={this.handleChange("accessMethod")}
                  inputProps={{ id: "accessMethod" }}
                >
                  <MenuItem value="private">Privado</MenuItem>
                  <MenuItem value="public">Público</MenuItem>
                </Select>

                <TextField
                  value={this.state.name}
                  required
                  onChange={this.handleChange("name")}
                  className="input-field"
                  type="text"
                  id="name"
                  label="Nome da Organização"
                />

                <TextField
                  value={this.state.description}
                  required
                  multiline
                  onChange={this.handleChange("description")}
                  className="input-field"
                  type="text"
                  id="description"
                  label="Descrição"
                />

                <br />
                <br />

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: "550" }}
                    >
                      Foto Thumbnail*
                    </Typography>
                    {this.state.thumbnailFile === null ? (
                      <div>
                        <br />
                        <br />
                        <span style={{ fontSize: "14px" }}>
                          Nenhuma Imagem Cadastrada
                        </span>
                        <br />
                        <br />
                      </div>
                    ) : (
                      <img
                        alt="thumbnail"
                        width="15%"
                        src={this.state.thumbnailFile}
                      />
                    )}
                    <br />
                    <br />
                    <input
                      value=""
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={this.fileChangedHandler("fotoThumb")}
                      id="fotoThumb"
                    />

                    <label htmlFor="fotoThumb">
                      <Button
                        style={{
                          fontWeight: "550",
                          color: "#6c6c6c",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        component="span"
                        startIcon={<AddPhotoAlternate color="secondary" />}
                      >
                        Adicionar Foto
                      </Button>
                      <Tooltip title="Evite imagens com fundo branco.">
                        <Help
                          color="secondary"
                          style={{ fontSize: 18, bottom: 0 }}
                        />
                      </Tooltip>
                    </label>

                    <br />
                    <br />

                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: "550" }}
                    >
                      Foto de Capa*
                    </Typography>

                    {this.state.coverImageFile === null ? (
                      <div>
                        <br />
                        <br />
                        <span style={{ fontSize: "14px" }}>
                          Nenhuma Imagem Cadastrada
                        </span>
                        <br />
                        <br />
                      </div>
                    ) : (
                      <img
                        alt="thumbnail"
                        width="15%"
                        src={this.state.coverImageFile}
                      />
                    )}

                    <br />
                    <input
                      value=""
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={this.fileChangedHandler("coverImage")}
                      id="coverImage"
                    />

                    <label htmlFor="coverImage">
                      <Button
                        style={{
                          fontWeight: "550",
                          color: "#6c6c6c",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        component="span"
                        startIcon={<AddPhotoAlternate color="secondary" />}
                      >
                        Adicionar Foto
                      </Button>
                      <Tooltip title="Evite imagens com fundo branco.">
                        <Help
                          color="secondary"
                          style={{ fontSize: 18, bottom: 0 }}
                        />
                      </Tooltip>
                    </label>

                    <br />
                    <br />

                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: "550" }}
                    >
                      Foto de Logo*
                    </Typography>
                    {this.state.logoImageFile === null ? (
                      <div>
                        <br />
                        <br />
                        <span style={{ fontSize: "14px" }}>
                          Nenhuma Imagem Cadastrada
                        </span>
                        <br />
                        <br />
                      </div>
                    ) : (
                      <img
                        alt="logo"
                        width="15%"
                        src={this.state.logoImageFile}
                      />
                    )}
                    <br />
                    <br />
                    <input
                      value=""
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={this.fileChangedHandler("logoImage")}
                      id="logoImage"
                    />

                    <label htmlFor="logoImage">
                      <Button
                        style={{
                          fontWeight: "550",
                          color: "#6c6c6c",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        component="span"
                        startIcon={<AddPhotoAlternate color="secondary" />}
                      >
                        Adicionar Foto
                      </Button>
                      <Tooltip title="Evite imagens com fundo.">
                        <Help
                          color="secondary"
                          style={{ fontSize: 18, bottom: 0 }}
                        />
                      </Tooltip>
                    </label>
                  </Grid>
                </Grid>

                <FormControlLabel
                  style={{ marginTop: "10px" }}
                  control={
                    <Switch
                      checked={this.state.lockdown}
                      onChange={this.handleChangeSwitch("lockdown")}
                      color="primary"
                    />
                  }
                  label={"Lockdown?"}
                />

                <FormControlLabel
                  style={{ marginTop: "10px" }}
                  control={
                    <Switch
                      checked={this.state.visible}
                      onChange={this.handleChangeSwitch("visible")}
                      color="primary"
                    />
                  }
                  label={"Visível?"}
                />
              </FormControl>
              <Button
                style={{
                  fontWeight: "550",
                  marginTop: "20px",
                  color: "white",
                  textTransform: "capitalize",
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
                disabled={this.state.submitLoading}
              >
                {this.state.submitLoading ? (
                  <CircularProgress size="23px" style={{ color: "white" }} />
                ) : (
                  "Salvar"
                )}
              </Button>
            </form>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.openCheck}
              onClose={this.handleCloseAlert}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={
                <span id="message-id">
                  {this.state.errorMessage || "Erro desconhecido!"}
                </span>
              }
            />

            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openModal}
            >
              <div style={getModalStyle()}>
                <Typography
                  variant="h5"
                  gutterBottom
                  color="primary"
                  style={{ fontWeight: "600" }}
                >
                  Editar Imagem
                </Typography>

                <div
                  style={{
                    maxWidth: "40%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop[this.state.indexCrop]}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={this.state.imageRatio}
                    />
                  )}
                </div>

                <div style={{ position: "fixed", bottom: 10 }}>
                  <Button
                    style={{
                      fontWeight: "550",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleCloseModal()}
                    startIcon={<ArrowBack color="white" />}
                  >
                    Voltar
                  </Button>
                  <Button
                    style={{
                      fontWeight: "550",
                      marginLeft: "10px",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.cropImage("image");
                    }}
                    startIcon={<CropFree color="white" />}
                  >
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.paymentInformationComponent.loading,
    success: state.paymentInformationComponent.success,
    error: state.paymentInformationComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  organizationInformationComponent: (data) =>
    organizationInformationAction(dispatch, data),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationInformation);
