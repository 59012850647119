/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import * as S from '../styles';
import { toast } from 'react-toastify';
import { getUserInfo, maskCEP } from 'js/library/utils/helpers';

import PencilIcon from '../../../../../styles/assets/SearchUser/pencil.svg';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiCircle } from 'react-icons/bi';
import axios from 'axios';
import { setUserInfo_v1 } from 'js/library/utils/API/setUserInfo_v1';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { updateDatabase } from 'js/library/services/DatabaseManager';

export default function EditInfosUser({
  handleCloseModal,
  isModalEditInfoUserOpen,
  userv3,
  setUserv3,
}) {
  const [inputCodinome, setInputCodinome] = useState('');
  const [inputFullName, setInputFullName] = useState('');
  const [inputBirthDate, setInputBirthDate] = useState('');
  const [inputCep, setInputCep] = useState('');
  const [inputFullAddress, setInputFullAddress] = useState('');
  const [inputAddressNumber, setInputAddressNumber] = useState('');
  const [inputAddressExtra, setInputAddressExtra] = useState('');
  const [selectGenreType, setSelectGenreType] = useState(0);
  const [inputAuthorizedBy, setInputAuthorizedBy] = useState('');
  const [adminIsCheck, setAdminIsCheck] = useState(false);
  const [termsTriiboCheck, setTermsTriiboCheck] = useState(false);
  const [termsList, setTermsList] = useState([]);
  const [loadingChangeInfos, setLoadingChangeInfos] = useState(false);

  const handleChangeGenreType = (event) => {
    setSelectGenreType(event.target.value);
  };

  const handleTermsCheck = (name) => (event) => {
    let newOptInList = termsList;

    if (event.target.checked === false) {
      termsList.map(function (item, i) {
        if (item.optInId !== undefined && item.optInId.indexOf(name) >= 0) {
          return newOptInList.splice(i, 1);
        } else {
          return null;
        }
      });
    } else {
      newOptInList.push({
        accept: true,
        dateAcceptance: new Date().getTime(),
        optInId: name,
        type: event.target.value,
        version: 1,
      });
    }

    setTermsList(newOptInList);

    if (name === '-Kzj_F6wDcfHkLMO_q4c') {
      setTermsTriiboCheck(event.target.checked);
    }
  };

  useEffect(() => {
    if (userv3 !== null && userv3 !== false && userv3 !== undefined) {
      let userInfo = userv3;

      const birthDate = new Date(userInfo.birthDate).toLocaleDateString('pt-BR');

      let newOptinTriibo = false;

      if (userv3.optInList !== null && userv3.optInList !== undefined) {
        userInfo.optInList.map(function (array) {
          if (array.optInId === '-Kzj_F6wDcfHkLMO_q4c' && array.accept === true) {
            return (newOptinTriibo = true);
          } else {
            return null;
          }
        });
      }

      const addressList = userInfo.addressList;

      setInputCodinome(userInfo.aliasName);
      setInputFullName(userInfo.name);
      setSelectGenreType(String(userInfo.gender));
      setInputBirthDate(birthDate);
      setInputFullAddress(userInfo.addressList ? userInfo.addressList[0] : '');
      setInputCep(
        userInfo.addressList
          ? userInfo.addressList[0].zipCode
            ? userInfo.addressList[0].zipCode
            : ''
          : ''
      );
      setInputAddressNumber(userInfo.addressList ? userInfo.addressList[0].number : '');
      setInputAddressExtra(addressList ? userInfo.addressList[0].extra : '');
      setTermsTriiboCheck(newOptinTriibo);
      setAdminIsCheck(userInfo.admin ? userInfo.admin : false);
    }
  }, [userv3]);

  useEffect(() => {
    if (inputCep !== '') {
      const cep = inputCep.replace('-', '');

      if (cep.length === 9) {
        axios.get(`https://viacep.com.br/ws/${cep}/json/`).then((result) => {
          if (result.data.erro !== undefined) {
            toast.error('CEP não encontrado');
          } else {
            const newFullAddress = {
              type: 'principal',
              googleAddr:
                result.data.logradouro +
                ' - ' +
                result.data.bairro +
                ', ' +
                result.data.localidade +
                ' - ' +
                result.data.uf +
                ', ' +
                result.data.cep +
                ', Brasil',
              latitude: 0,
              longitude: 0,
              streetAve: result.data.logradouro,
              number: null,
              state: result.data.uf,
              city: result.data.localidade,
              neighborhood: result.data.bairro,
              country: 'Brasil',
              zipCode: result.data.cep,
            };

            setInputFullAddress(newFullAddress);
          }
        });
      }
    }
  }, [inputCep]);

  const handleSubmit = () => {
    setLoadingChangeInfos(true);
    const uId = userv3.uId;

    const birthDate = new Date(inputBirthDate).getTime() + 1000 * 60 * 60 * 3;

    let newAddressList = inputFullAddress;

    if (newAddressList !== null && newAddressList !== '') {
      newAddressList.extra = inputAddressExtra;
      newAddressList.number = inputAddressNumber;
    }

    let userInfo = {
      updateDate: new Date().getTime(),
      syncType: 'merge',
      uId: uId,
      aliasName: inputCodinome,
      name: inputFullName,
      gender: parseInt(selectGenreType, 10) || null,
      birthDate: birthDate ? birthDate : null,
      addressList: newAddressList ? [newAddressList] : null,
      optInList: termsList,
      admin: adminIsCheck,
    };

    Object.keys(userInfo).map(function (item) {
      if (userInfo[item] === null || userInfo[item] === undefined || userInfo[item] === '') {
        return delete userInfo[item];
      } else {
        return null;
      }
    });

    setUserInfo_v1(uId, ['setUserInfo'], userInfo).then((result) => {
      let user = null;
      let useruId = null;

      if (userv3 !== null && userv3 !== false && userv3 !== undefined) {
        user = userv3;
        useruId = user.uId;
      }

      let editedInfos = {
        editedUser: useruId,
        editedBy: getUserInfo().uId,
        authorizatedBy: inputAuthorizedBy !== '' ? inputAuthorizedBy : null,
        editDate: new Date().getTime(),
        oldInfos: {},
        newInfos: {},
      };

      Object.keys(userInfo).map(function (item) {
        if (user[item] === undefined) {
          user[item] = null;
        }

        if (userInfo[item] === undefined) {
          userInfo[item] = null;
        }

        if (Array.isArray(user[item])) {
          editedInfos.oldInfos[item] = JSON.stringify(user[item]);
        } else {
          editedInfos.oldInfos[item] = user[item];
        }

        if (Array.isArray(userInfo[item])) {
          editedInfos.newInfos[item] = JSON.stringify(userInfo[item]);
        } else {
          editedInfos.newInfos[item] = userInfo[item];
        }

        return null;
      });

      let editId = firebaseDatabase.ref('log-edituser').push().key;
      updateDatabase('log-edituser', editedInfos, editId, null);

      toast.success('Usuário atualizado com sucesso.');
      setLoadingChangeInfos(false);
      setUserv3(result);
    });
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalEditInfoUser')}
      open={isModalEditInfoUserOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <S.ModalBox
          style={{
            msOverflowY: 'scroll',
            overflowY: 'scroll',
            maxHeight: '90%',
          }}
        >
          <div className="header">
            <img src={PencilIcon} alt="" />
            <Typography variant="h2" component="h2" id="modal-title">
              Editar Informações
            </Typography>
          </div>

          <S.EditInfoUserModalBox>
            <TextField
              type="text"
              size="small"
              label="Codinome"
              value={inputCodinome}
              onChange={(e) => setInputCodinome(e.target.value)}
              required
            />
            <TextField
              type="text"
              size="small"
              label="Nome completo"
              value={inputFullName}
              onChange={(e) => setInputFullName(e.target.value)}
              required
            />
            <TextField
              type="date"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              label="Data de Nascimento"
              value={inputBirthDate}
              onChange={(e) => setInputBirthDate(e.target.value)}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              Gênero
              <FormControlLabel
                checked={selectGenreType === '0'}
                onClick={handleChangeGenreType}
                value="0"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#6E3296',
                      },
                    }}
                  />
                }
                label="Masculino"
              />
              <FormControlLabel
                checked={selectGenreType === '1'}
                onClick={handleChangeGenreType}
                value="1"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#6E3296',
                      },
                    }}
                  />
                }
                label="Feminino"
              />
            </RadioGroup>
            <TextField
              type="text"
              size="small"
              label="CEP"
              value={inputCep}
              onChange={(e) => setInputCep(maskCEP(e.target.value))}
            />
            <TextField
              type="text"
              size="small"
              label="Endereço"
              disabled={inputFullAddress !== ''}
              value={
                inputFullAddress === ''
                  ? ''
                  : inputFullAddress.streetAve +
                    ' - ' +
                    inputFullAddress.neighborhood +
                    ' - ' +
                    inputFullAddress.state
              }
              onChange={(e) => setInputFullAddress(e.target.value)}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="text"
                  size="small"
                  label="Número"
                  value={inputAddressNumber}
                  onChange={(e) => setInputAddressNumber(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="text"
                  size="small"
                  label="Complemento"
                  value={inputAddressExtra}
                  onChange={(e) => setInputAddressExtra(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <TextField
              type="text"
              size="small"
              label="Autorizado por"
              value={inputAuthorizedBy}
              onChange={(e) => setInputAuthorizedBy(e.target.value)}
            />
            <FormGroup sx={{ flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminIsCheck}
                    onChange={(e) => setAdminIsCheck(e.target.checked)}
                    icon={<BiCircle fontSize="25px" />}
                    checkedIcon={<BsFillCheckCircleFill fontSize="23px" color="#5A287C" />}
                  />
                }
                label="Usuário super admin"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsTriiboCheck}
                    onChange={handleTermsCheck('-Kzj_F6wDcfHkLMO_q4c')}
                    icon={<BiCircle fontSize="25px" />}
                    checkedIcon={<BsFillCheckCircleFill fontSize="23px" color="#5A287C" />}
                    value="Termos de Uso da Triibo"
                  />
                }
                label="Aceitar termos e condições Triibo"
              />
            </FormGroup>
            <div className="actionsButtons">
              <Button
                variant="contained"
                className="button1"
                onClick={() => handleCloseModal('closeModalEditInfoUser')}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                className="button2"
                disabled={loadingChangeInfos}
                onClick={handleSubmit}
              >
                {loadingChangeInfos && (
                  <CircularProgress style={{ marginRight: '0.5rem' }} size="23px" />
                )}
                Salvar
              </Button>
            </div>
          </S.EditInfoUserModalBox>
        </S.ModalBox>
      </>
    </Modal>
  );
}
