import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { MdArrowBackIosNew } from 'react-icons/md';
import { TriiboH2, TriiboH4 } from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from '../../styles';
import {
  Actions,
  ButtonAction,
  CardItemInfos,
  Container,
  DragContainerList,
  DragItem,
} from './styles';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import BackgroundModal from '../Modals/BackgroundModal';
import BannerModal from '../Modals/BannerModal';
import TextModal from '../Modals/TextModal';
import BlogModal from '../Modals/BlogModal';
import CarouselModal from '../Modals/CarouselModal';
import HighlightsModal from '../Modals/HighlightsModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function View({ formData, modalData, handlers, formatDate, loaders }) {
  const { form, campaigns, periods, modules } = formData;
  const {
    isActiveModals,
    selectedModalInfos,
    modalsList,
    modulesImages,
    modulesList,
    backgroundModule,
  } = modalData;

  const {
    setSelectedModalInfos,
    closeModal,
    openModal,
    setSelectedPeriods,
    handleChange,
    saveLandingPage,
    removeModule,
    setPayload,
  } = handlers;

  const { saveLPLoading } = loaders;

  const renderCampaigns = campaigns?.map((campaign) => (
    <MenuItem
      key={campaign?.id}
      onClick={() => setSelectedPeriods(campaign?.periods)}
      value={campaign?.id}
    >
      {campaign?.id}
    </MenuItem>
  ));

  const renderPeriods = periods?.map(({ endsAt, startAt, id }) => (
    <MenuItem key={id} value={id?.toString()}>
      De: {formatDate(startAt)} Até: {formatDate(endsAt)}
    </MenuItem>
  ));

  const renderModulesOptions = modulesList.map(({ id, option }) => (
    <MenuItem
      key={id}
      onClick={() => {
        const newModule = { type: option.toLocaleLowerCase(), priority: modules.length };
        handleEdit(newModule, modules.length, option.toLocaleLowerCase());
      }}
      value={option}
    >
      {option}
    </MenuItem>
  ));

  const handleEdit = (module, index, type) => {
    setSelectedModalInfos({
      modalInfos: module,
      currentIndex: index,
    });
    openModal(modalsList[type]);
  };

  const renderModules = modules?.map((module, index) => {
    const { type } = module;

    const handleRemove = () => {
      removeModule(index);
    };

    return (
      <Draggable key={index + type} draggableId={index + type} index={index}>
        {(provided, snapshot) => (
          <DragItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            style={getItemStyle(provided.draggableProps.style)}
          >
            <img src={modulesImages[type]} alt={type} />
            <Actions>
              <CardItemInfos>
                <TriiboH4>{type}</TriiboH4>
              </CardItemInfos>

              <ButtonAction
                type="button"
                onClick={() => handleEdit(module, index, type)}
                bgColor={'#3A63F1'}
              >
                <EditIcon style={{ color: 'white', fontSize: '16px' }} />
              </ButtonAction>

              <ButtonAction type="button" onClick={handleRemove} bgColor={'#F13A3A'}>
                <DeleteIcon style={{ color: 'white', fontSize: '16px' }} />
              </ButtonAction>
            </Actions>
          </DragItem>
        )}
      </Draggable>
    );
  });

  const renderModals = (
    <>
      <BackgroundModal
        setPayload={setPayload}
        closeModal={closeModal}
        isActiveModals={isActiveModals?.bgModal}
        selectedModalInfos={selectedModalInfos}
      />
      <BannerModal
        closeModal={closeModal}
        setPayload={setPayload}
        isActiveModals={isActiveModals?.bannerModal}
        selectedModalInfos={selectedModalInfos}
      />
      <TextModal
        closeModal={closeModal}
        setPayload={setPayload}
        isActiveModals={isActiveModals?.textModal}
        selectedModalInfos={selectedModalInfos}
      />

      <BlogModal
        closeModal={closeModal}
        setPayload={setPayload}
        isActiveModals={isActiveModals?.blogModal}
        selectedModalInfos={selectedModalInfos}
      />

      <CarouselModal
        closeModal={closeModal}
        setPayload={setPayload}
        isActiveModals={isActiveModals?.carouselModal}
        selectedModalInfos={selectedModalInfos}
      />

      <HighlightsModal
        closeModal={closeModal}
        setPayload={setPayload}
        isActiveModals={isActiveModals?.highlightsModal}
        selectedModalInfos={selectedModalInfos}
      />
    </>
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = [...modules];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newModules = items.map((item, index) => ({ ...item, priority: index }));
    setPayload((prevState) => ({
      campaignLandpage: {
        ...prevState.campaignLandpage,
        modules: newModules,
      },
    }));
  }

  const getItemStyle = (draggableStyle) => ({
    ...draggableStyle,
  });

  return (
    <>
      {renderModals}
      <AreaHeader style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem' }}>
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>

      <form
        onSubmit={saveLandingPage}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Container>
          <Box display={'flex'} justifyContent={'flex-start'} flexDirection={'column'}>
            <TriiboH2>Configurações Da Landing Page</TriiboH2>

            <TextField
              fullWidth
              label="Título"
              id="title"
              placeholder="Adicione um título."
              margin="normal"
              onChange={(event) => handleChange(event)}
              value={form?.title?.value || ''}
              required={form?.title?.required}
            />

            <TextField
              fullWidth
              label="Descrição"
              id="description"
              placeholder="Adicione um descrição."
              margin="normal"
              onChange={(event) => handleChange(event)}
              value={form?.description?.value || ''}
              required={form?.description?.required}
            />

            <TextField
              fullWidth
              label="URL Pública"
              id="publicName"
              placeholder="URL de identificação"
              margin="normal"
              onChange={(event) => handleChange(event)}
              value={form?.publicName?.value || ''}
              required={form?.publicName?.required}
            />

            <TextField
              fullWidth
              select
              name="campaignId"
              label="ID da campanha"
              value={form?.campaignId?.value || ''}
              size="medium"
              margin="normal"
              required={form?.campaignId?.required}
              onChange={(event) => handleChange(event)}
            >
              <MenuItem key={'selecione'} value={''}>
                {'Selecione'}
              </MenuItem>
              {renderCampaigns}
            </TextField>

            <FormControl disabled={!renderPeriods?.length} fullWidth margin="normal">
              <InputLabel id="periodo">Período</InputLabel>
              <Select
                inputProps={{
                  name: 'periodsId',
                }}
                onChange={(event) => handleChange(event)}
                id="periodsId"
                label="Período"
                value={form.periodsId.value || ''}
                required={form?.periodsId?.required}
              >
                <MenuItem key={'selecione'} value={''}>
                  {'Selecione'}
                </MenuItem>
                {renderPeriods}
              </Select>
            </FormControl>

            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    id="isActive"
                    value={form.isActive.value}
                    onChange={(event) => handleChange(event)}
                    checked={form.isActive.value}
                  />
                }
                label="Ativo"
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedModalInfos({
                  modalInfos: backgroundModule,
                });
                openModal(modalsList.background);
              }}
            >
              Adcionar Background
            </Button>
          </Box>
        </Container>

        <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
          width={'100%'}
          maxWidth={'800px'}
        >
          <FormControl fullWidth margin="normal">
            <InputLabel id="addModule">Adicione um módulo</InputLabel>
            <Select value={''} labelId="addModule" label="Adicione um módulo">
              {renderModulesOptions}
            </Select>
          </FormControl>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="formsFields">
              {(provided, snapshot) => (
                <DragContainerList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDragging={snapshot.isDraggingOver}
                >
                  {renderModules}
                  {provided.placeholder}
                </DragContainerList>
              )}
            </Droppable>
          </DragDropContext>
        </Box>

        <Box
          margin={'20px 0'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          maxWidth={'800px'}
        >
          <Button type="submit" fullWidth variant="contained" color="primary">
            {saveLPLoading ? (
              <CircularProgress style={{ color: '#fff' }} size={'30px'} />
            ) : (
              'salvar'
            )}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default View;
