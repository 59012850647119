import React from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import editIcon from 'styles/assets/communications/edit.svg';
import { IoIosClose } from 'react-icons/io';

export default function ListTemplate({
  title,
  data,
  type,
  setFormData,
  setIndexItem,
  handleRemoveItem,
}) {
  return (
    <S.Container>
      <D.TriiboH3>
        <strong>{title}</strong>
      </D.TriiboH3>

      <S.ListContainer>
        {data.length > 0 ? (
          data.map((item, index) => (
            <S.ListItem key={index}>
              <D.TriiboH5>
                {type} {index + 1}
              </D.TriiboH5>
              <S.Actions>
                <S.EditButton
                  onClick={() => {
                    setFormData(item);
                    setIndexItem(index);
                  }}
                >
                  <img alt="Icone" src={editIcon} />
                </S.EditButton>
                <S.DeleteButton onClick={() => handleRemoveItem(index)}>
                  <IoIosClose size={40} />
                </S.DeleteButton>
              </S.Actions>
            </S.ListItem>
          ))
        ) : (
          <D.TriiboH4>Nenhum {type} adicionado</D.TriiboH4>
        )}
      </S.ListContainer>
    </S.Container>
  );
}

