import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import registerUserImg from 'styles/assets/estabelecimentos/estabBanner2.png';
import attachCustomersImg from 'styles/assets/estabelecimentos/customerBanner2.svg';
import listUsersImg from 'styles/assets/estabelecimentos/customerBanner3.svg';

import { Container, Box, Button, Grid } from '@mui/material';

import { useStyles } from './useStyles';
import { RegisterUserModal } from './RegisterUserModal';
import { Header } from './Header';

export default function RegistrationOption({ history, ...props }) {
  const [isModalRegisterUserOpen, setIsModalRegisterUserOpen] = useState(false);

  const classes = useStyles();

  const handleOpenModalRegisterUser = () => {
    setIsModalRegisterUserOpen(true);
  };

  const handleCloseModalRegisterUser = () => {
    setIsModalRegisterUserOpen(false);
  };

  const pathnameRegisterListUser = `/estabelecimento/${props.match.params.id}/relacionamento/cadastra-lista-usuarios`;

  return (
    <Container maxWidth={false} className={classes.root}>
      <Header history={history} pageInfo="Cadastrar clientes" />
      <Grid container spacing={3}>
        <Grid item xs>
          <Box className={classes.boxActions}>
            <img alt="" src={registerUserImg} width={285} />
            <Button
              variant="contained"
              color="primary"
              className={classes.option1Button}
              onClick={handleOpenModalRegisterUser}
              style={{ width: 340, marginTop: 30 }}
            >
              Cadastrar um novo cliente
            </Button>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.boxActions}>
            <img alt="" src={attachCustomersImg} width={300} />
            <Link
              className={classes.link}
              to={{
                pathname: pathnameRegisterListUser,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.option1Button}
                // onClick={handleModalChange}
                style={{ width: 340, marginTop: 30 }}
              >
                Anexar lista de clientes
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.boxActions}>
            <img alt="" src={listUsersImg} width={300} />
            <Link
              className={classes.link}
              to={{
                pathname: `/estabelecimento/${props.match.params.id}/relacionamento/lista-usuarios`,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.option1Button}
                // onClick={handleModalChange}
                style={{ width: 340, marginTop: 30 }}
              >
                Ver meus clientes
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
      {/* MODAL COM O FORMULÁRIO DE CADASTRO DE NOVO CLIENTE */}
      <RegisterUserModal
        isModalRegisterUserOpen={isModalRegisterUserOpen}
        handleCloseModalRegisterUser={handleCloseModalRegisterUser}
      />
    </Container>
  );
}

