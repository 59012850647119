import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';

function sortByClosestKey(arrayOfObjects, key, targetString) {
  return arrayOfObjects.sort((a, b) => {
    const aDistance = Math.abs(a[key].localeCompare(targetString));
    const bDistance = Math.abs(b[key].localeCompare(targetString));
    return aDistance - bDistance;
  });
}

const Sender = (props) => {
  const {
    form,
    setForm,
    selectedEstablishments,
    setSelectedEstablishments,
    selectedOrganizations,
    setSelectedOrganizations,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const input = '';

  const [inputEstablishment, setInputEstablishment] = useState('');
  const [optionsEstablishment, setOptionsEstablishment] = useState([]);
  const [isFocusedEstablishment, setIsFocusedEstablishment] = useState(false);

  const [orgsList, setOrgsList] = useState([]);
  const [isFocusedOrganization, setIsFocusedOrganization] = useState(false);
  const [completeOrgsList, setCompleteOrgsList] = useState([]);

  const isSuperAdmin = useSelector((state) => state.authModel.superUser);

  useEffect(() => {
    if (form.receiverOrgs?.length) {
      const previousOrgs = form.receiverOrgs.map((orgId) => {
        return completeOrgsList?.find((org) => org.orgID === orgId) || '';
      });
      if (previousOrgs !== '') {
        setSelectedOrganizations(previousOrgs);
      }
    }

    if (form.receiverPlaces?.length) {
      form.receiverPlaces.forEach(async (placeId) => {
        const res = await getEstablishmentId(placeId);
        res.id = placeId;
        if (!selectedEstablishments.find((est) => est.id === res.id)) {
          setSelectedEstablishments([...selectedEstablishments, res]);
        }
      });
    }
  }, [form.receiverOrgs, form.receiverPlaces, completeOrgsList]);

  useEffect(() => {
    getDataListRestApi('Organizations').then((data) => {
      const orgsArray = Object.values(data || {});
      setOrgsList(orgsArray);
      setCompleteOrgsList(orgsArray);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    apiListEstablishment(inputEstablishment, 0, 10).then((response) => {
      setIsLoading(false);
      setOptionsEstablishment(sortByClosestKey(response, 'nome', inputEstablishment));
    });
  }, [inputEstablishment, form]);

  const handleOptionClick = (element) => {
    const updatedSelectedEstablishments = [...selectedEstablishments, element];
    setSelectedEstablishments(updatedSelectedEstablishments);
    setInputEstablishment('');
    setIsFocusedEstablishment(false);

    const estIds = updatedSelectedEstablishments.map((est) => est.id);

    setForm((prevForm) => ({
      ...prevForm,
      receiverPlaces: estIds,
    }));
  };

  const handleDeleteChip = (index) => {
    const updatedSelectedEstablishments = [...selectedEstablishments];
    updatedSelectedEstablishments.splice(index, 1);
    setSelectedEstablishments(updatedSelectedEstablishments);

    const estIds = updatedSelectedEstablishments.map((est) => est.id);

    setForm((prevForm) => ({
      ...prevForm,
      receiverPlaces: estIds,
    }));
  };

  const handleOptionClickOrg = (element) => {
    const updatedSelectedOrganizations = [...selectedOrganizations, element];
    setSelectedOrganizations(updatedSelectedOrganizations);
    setIsFocusedOrganization(false);

    const orgIds = updatedSelectedOrganizations.map((org) => org.orgID);

    setForm((prevForm) => ({
      ...prevForm,
      receiverOrgs: orgIds,
    }));
  };

  const handleDeleteChipOrg = (index) => {
    const updatedSelectedOrganizations = [...selectedOrganizations];
    updatedSelectedOrganizations.splice(index, 1);
    setSelectedOrganizations(updatedSelectedOrganizations);

    const orgIds = updatedSelectedOrganizations.map((org) => org.orgID);

    setForm((prevForm) => ({
      ...prevForm,
      receiverOrgs: orgIds,
    }));
  };

  useEffect(() => {
    if (form.sender['type'] === 'establishment') {
      setIsLoading(true);
      if (input.length > 0) {
        apiListEstablishment(input, 0, 10).then((response) => {
          setIsLoading(false);
        });
      }
    }
  }, [input, form.sender.type]);

  useEffect(() => {
    setIsLoading(true);

    const establishmentTimeout = setTimeout(() => {
      apiListEstablishment(inputEstablishment, 0, 10).then((response) => {
        setIsLoading(false);
        setOptionsEstablishment(sortByClosestKey(response, 'nome', inputEstablishment));
      });
    }, 1200);

    return () => {
      clearTimeout(establishmentTimeout);
    };
  }, [inputEstablishment]);

  if (!isSuperAdmin) {
    return <></>;
  }

  return (
    <S.Container>
      <D.TriiboH4>
        <b>Informações da origem</b>
      </D.TriiboH4>
      <S.InputGroupContainer style={{ width: '100%' }}>
        <D.TriiboH4>Estabelecimentos que vão receber:</D.TriiboH4>

        {selectedEstablishments.length > 0 && (
          <ul style={{ padding: 0, margin: 0 }}>
            {selectedEstablishments.map((establishment) => {
              return (
                <div key={establishment?.id}>
                  <li
                    style={{
                      listStyle: 'none',
                      marginRight: '8px',
                      marginBottom: '8px',
                      display: 'inline-block',
                      backgroundColor: '#CDA8E0',
                      borderRadius: '16px',
                    }}
                  >
                    <Chip
                      label={establishment.nome}
                      onDelete={() => handleDeleteChip(establishment?.id)}
                      style={{
                        color: '#fff',
                      }}
                    />
                  </li>
                </div>
              );
            })}
          </ul>
        )}

        <div style={{ position: 'relative', width: '100%' }}>
          <S.InputContainer>
            <S.InputEstablishment
              value={inputEstablishment}
              placeholder={'Buscar estabelecimentos'}
              onChange={(e) => setInputEstablishment(e.target.value)}
              onFocus={() => setIsFocusedEstablishment(true)}
              onBlur={() => setIsFocusedEstablishment(false)}
            />
            <S.SpinnerContainer isLoading={isLoading}>
              <CircularProgress />
            </S.SpinnerContainer>
          </S.InputContainer>

          <S.OptionsDisplayContainerEstablishment isOpen={isFocusedEstablishment}>
            {optionsEstablishment.map((element, index) => {
              const isOptionDisabled = selectedEstablishments.some(
                (selected) => selected.id === element.id
              );

              return (
                <S.OptionSelectable
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();

                    if (!isOptionDisabled) {
                      handleOptionClick(element);
                    }
                  }}
                  style={{
                    cursor: isOptionDisabled ? 'not-allowed' : 'pointer',
                    opacity: isOptionDisabled ? '0.5' : '1',
                  }}
                >
                  {element.nome}
                </S.OptionSelectable>
              );
            })}
          </S.OptionsDisplayContainerEstablishment>
        </div>

        <D.TriiboH4>Organizações que vão receber:</D.TriiboH4>

        {selectedOrganizations.length > 0 && (
          <ul style={{ padding: 0, margin: 0 }}>
            {selectedOrganizations.map((org) => (
              <div key={org.orgID}>
                <li
                  style={{
                    listStyle: 'none',
                    marginRight: '8px',
                    marginBottom: '8px',
                    display: 'inline-block',
                    backgroundColor: '#CDA8E0',
                    borderRadius: '16px',
                  }}
                >
                  <Chip
                    label={org.name}
                    onDelete={() => handleDeleteChipOrg(org.orgID)}
                    style={{
                      color: '#fff',
                    }}
                  />
                </li>
              </div>
            ))}
          </ul>
        )}

        <div style={{ position: 'relative', width: '100%' }}>
          <S.InputContainer>
            <S.InputOrganization
              placeholder={'Buscar organizações'}
              onChange={(e) => {
                const inputValue = e.target.value;

                if (inputValue.trim() === '') {
                  setOrgsList(completeOrgsList);
                } else {
                  if (Array.isArray(completeOrgsList)) {
                    const filteredList = completeOrgsList.filter((element) =>
                      element.name.toLowerCase().includes(inputValue.toLowerCase())
                    );

                    setOrgsList(filteredList);
                  } else {
                    console.error('completeOrgsList is not an array:', completeOrgsList);
                  }
                }
              }}
              onFocus={() => {
                setIsFocusedOrganization(true);
              }}
              onBlur={() => {
                setIsFocusedOrganization(false);
              }}
            />
            <S.SpinnerContainer isLoading={isLoading}>
              <CircularProgress />
            </S.SpinnerContainer>
          </S.InputContainer>

          <S.OptionsDisplayContainerOrganization isOpen={isFocusedOrganization}>
            {Object.entries(orgsList)
              .slice(0, 10)
              .map(([key, element]) => {
                const isOptionDisabled = selectedOrganizations.some(
                  (selected) => selected.name === element.name
                );

                return (
                  <S.OptionSelectable
                    key={element.orgID}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!isOptionDisabled) {
                        handleOptionClickOrg(element);
                      }
                    }}
                    style={{
                      cursor: isOptionDisabled ? 'not-allowed' : 'pointer',
                      opacity: isOptionDisabled ? '0.5' : '1',
                    }}
                  >
                    {element.name}
                  </S.OptionSelectable>
                );
              })}
          </S.OptionsDisplayContainerOrganization>
        </div>
      </S.InputGroupContainer>
    </S.Container>
  );
};

export default Sender;
