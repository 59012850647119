import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";
import { AreaHeader, GoBack } from "../CreateClub/styles";
import { MdArrowBackIosNew } from "react-icons/md";

export default function ConfigHomeMapfre() {
  return (
  <div  style={{ padding: '4rem'}}>
    <AreaHeader style={{ color: "#08bad0", justifyContent: 'end', padding: '1rem',  marginLeft: '3rem' }}>
    <GoBack
      onClick={() => {
        window.history.back();;
      }}
    >
      <MdArrowBackIosNew /> Voltar
    </GoBack>
  </AreaHeader>
    <S.Container>
  
      <D.TriiboH1
      style={{marginBottom: '1rem'}}
      >Home Mapfre</D.TriiboH1>
      <S.Cards>
        <Link to={"/admin/configuracoes/configHomeMapfre/adicionarModulos"}>
          <S.Card>
            <D.TriiboH4>Adicionar Módulos</D.TriiboH4>
          </S.Card>
        </Link>
        <Link to={"/admin/configuracoes/configHomeMapfre/listarModulos"}>
          <S.Card>
            <D.TriiboH4>Listagem de Módulos </D.TriiboH4>
          </S.Card>
        </Link>
        <Link to={"/admin/configuracoes/configHomeMapfre/organizarModulos"}>
          <S.Card>
            <D.TriiboH4>Organizar Módulos </D.TriiboH4>
          </S.Card>
        </Link>
      </S.Cards>
    </S.Container>
    </div>
  );
}
