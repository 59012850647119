import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';

import { BsXCircleFill } from 'react-icons/bs';

const StepConcluded = (props) => {
  const { state } = props;

  const url = window.location.href;
  const route = url.split('/').find((e) => e.startsWith('-'));

  const style = {
    fontFamily: 'Source Sans Pro',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: { xs: '100%', sm: '30rem', md: '30rem' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
  };

  const styleButton = {
    fontFamily: 'Source Sans Pro',
    fontWeight: '700',
    textTransform: 'none',
    borderRadius: '0.8rem',
  };

  const styleButtonBlack = {
    backgroundColor: '#383838',
    ' &:hover': {
      backgroundColor: '#3d3d3d',
    },
  };
  const styleButtonPainel = {
    backgroundColor: '#fff',
    border: '1px solid #383838',
    color: '#383838',
    '&:hover': {
      backgroundColor: '#fff',
      opacity: 0.7,
    },
  };

  const styleButtonTeal = {
    backgroundColor: '#08BAD0',
    ' &:hover': {
      backgroundColor: '#0799ab',
    },
  };

  return (
    <div>
      <Modal
        open={true}
        // BackdropProps={{ style: { backgroundColor: 'gray' } }}
        BackdropProps={{ transitionDuration: '0' }}
      >
        <Box sx={style}>
          <Link
            to={{
              pathname: state.establishmentList === null ? '/admin' : `/estabelecimento/${route}/`,
              state: state.establishmentList === null ? '' : state.establishmentList[0],
            }}
            style={{ textDecoration: 'none' }}
          >
            <BsXCircleFill
              // onClick={handleClose}
              onClick={() => {
                sessionStorage.setItem('secondStepNew', 'complete');
              }}
              style={{
                fontSize: '1.4rem',
                position: 'absolute',
                top: '0.8rem',
                right: '0.8rem',
                cursor: 'pointer',
                color: '#383838',
              }}
            />
          </Link>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            // gap={2}
            xs={12}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Source Sans Pro',
                fontWeight: '700',
              }}
            >
              Passo concluído
            </Typography>

            <Typography
              variant="subtitle2"
              align="center"
              sx={{
                mt: 1.4,
                fontFamily: 'Source Sans Pro',
                fontWeight: '400',
                maxWidth: '23.5rem',
              }}
            >
              Recomendamos que você avance para a próxima etapa.
              {/* Se preferir, já
              pode acessar o Painel Administrativo. */}
            </Typography>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              justifyContent="center"
              xs={12}
              gap={2.4}
              sx={{ mt: 2.4 }}
            >
              <Link to={{ pathname: '/admin', state: '' }} style={{ textDecoration: 'none' }}>
                <Button
                  onClick={() => {
                    sessionStorage.setItem('secondStepNew', 'complete');
                  }}
                  variant="contained"
                  disableElevation
                  sx={[styleButton, styleButtonTeal]}
                >
                  Próximo Passo
                </Button>
              </Link>

              <Button
                onClick={() => {
                  window.history.back();
                  sessionStorage.setItem('secondStepNew', 'incomplete');
                }}
                variant="contained"
                disableElevation
                sx={[styleButton, styleButtonBlack]}
              >
                Continuar Cadastrando Promoção
              </Button>

              <Link
                to={{
                  pathname:
                    state.establishmentList === null
                      ? '/admin'
                      : `/estabelecimento/${state.establishmentList[0].id}/`,
                  state: state.establishmentList === null ? '' : state.establishmentList[0],
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" disableElevation sx={[styleButton, styleButtonPainel]}>
                  Painel Administrativo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default StepConcluded;

