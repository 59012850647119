import { getClientsEstablishment } from "js/library/utils/API/apiGetClients";
import { getStoreByEstablishment } from "../utils/API/Store/apiGetStoreByEstablishment";
import { getUserInfo } from "../utils/helpers";

import { CheckEstAdmin } from "../utils/API/SearchUser/apiCheckEstAdmin";

const TrialRequisition = async () => {
  let payload = {};

  // const promiseEstablishment = await CheckEstAdmin(getUserInfo().uId, "all");
  let promiseEstablishment;
  try {
    promiseEstablishment = await CheckEstAdmin(getUserInfo().uId, "all");
  } catch (error) {
    promiseEstablishment = [];
  }

  const fakePromise = [{ id: "placeholder" }];

  function getId(promise) {
    if (promise === null || promise === undefined) {
      return fakePromise[0].id;
    } else if (!promise.length) {
      return fakePromise[0].id;
    } else {
      return promise[0].id;
    }
  }

  const promisePromo = await getStoreByEstablishment(
    getId(promiseEstablishment),
    0,    
    1000, 
    'true'  
  );

  const promiseClient = await getClientsEstablishment(
    getId(promiseEstablishment)
  );
  // const promiseClient = await getClientsEstablishment(
  //   promiseEstablishment[0].id
  // );

  payload.loading = false;
  payload.hasPromo = promisePromo;
  payload.hasClient = promiseClient;
  // payload.hasClient = promiseClient.filter((client) => client !== null);

  return payload;
};

export default TrialRequisition;
