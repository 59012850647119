import React from 'react';
import QRCode from 'qrcode.react';
import './styles-preview.css';
import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';

const PreviewElementsForm = ({ imageTemplate, formValues, selectedFormat }) => {
  const imageSize = {
    landscape: { width: '350px', height: '250px' },
    portrait: { width: '250px', height: '350px' },
    square: { width: '270px', height: '270px' },
  };

  return (
    <div className="container-preview-form">
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: `${imageSize[selectedFormat].width}`,
          height: `${imageSize[selectedFormat].height}`,
          fontSize: '25px',
        }}
      >
        <img
          id="image-background"
          style={{ width: '100%', height: '100%' }}
          src={imageTemplate}
          alt="Preview"
        />

        {formValues.map((element, key) => {
          if (element?.entity === 'text') {
            return (
              <span
                id={`text-template-${key}`}
                key={key}
                style={{
                  position: 'absolute',
                  left: `${element.widthPosition}%`,
                  top: `${element.heightPosition}%`,
                  transform: `translate(${-element.widthPosition}%, ${-element.heightPosition}%)`,
                  fontSize: `${Math.floor(element.fontSize / 1.4)}px`,
                  width: `${element.widthSize}%`,
                  height: `${element.heightSize}%`,
                  fontFamily: `${element.fontStyle}`,
                  textAlign: 'center',
                  color: `${element.fontColor}`,
                  border: '1px solid blue',
                }}
              >
                Texto do template
              </span>
            );
          } else if (element.entity === 'image') {
            return (
              <>
                <img
                  key={key}
                  id="image-template"
                  src={placeholder}
                  alt="Imagem preview"
                  style={{
                    position: 'absolute',
                    left: `${element.widthPosition}%`,
                    top: `${element.heightPosition}%`,
                    width: `${element.widthSize}%`,
                    // height: `${element.heightSize}%`,
                    transform: `translate(${-element.widthPosition}%, ${-element.heightPosition}%)`,
                  }}
                />
              </>
            );
          } else if (element.entity === 'qrCode') {
            return (
              <QRCode
                id="qrcode-image"
                size={1200}
                key={key}
                style={{
                  position: 'absolute',
                  left: `${element.widthPosition}%`,
                  top: `${element.heightPosition}%`,
                  transform: `translate(${-element.widthPosition}%, ${-element.heightPosition}%)`,
                  width: `${element.widthSize}%`,
                  height: 'none',
                  display: 'block',
                }}
                level="L"
                value=""
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default PreviewElementsForm;

