import React, { useEffect, useState } from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from '../styles';
import { cloneDeep } from 'lodash';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { compareDatesFromStr } from 'js/library/utils/helpers';
import { FaCircle } from 'react-icons/fa';

const OpeningHours = ({
  formState,
  newFormState,
  setEditModal,
  infoOfTheWeek,
  finalFormState,
  setEditModalTwo,
  setFinalFormState,
  setDataFuncionamentoEdit,
}) => {
  infoOfTheWeek.map((item) => {
    if (!item.nova) {
      item.nova = item.antiga;
    }
  });

  const [state, setState] = useState([
    { type: 'new', data: infoOfTheWeek[0].nova },
    { type: 'new', data: infoOfTheWeek[1].nova },
    { type: 'new', data: infoOfTheWeek[2].nova },
    { type: 'new', data: infoOfTheWeek[3].nova },
    { type: 'new', data: infoOfTheWeek[4].nova },
    { type: 'new', data: infoOfTheWeek[5].nova },
    { type: 'new', data: infoOfTheWeek[6].nova },
  ]);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(infoOfTheWeek.length).fill({ new: 'new', old: false })
  );
  const clonedFuncionamentoAntigo = cloneDeep(formState.funcionamento);
  const clonedFuncionamentoNovo = cloneDeep(newFormState.funcionamentoAntigos);
  const funcionamentoAntigo = JSON.stringify(clonedFuncionamentoAntigo);
  const funcionamento = JSON.stringify(clonedFuncionamentoNovo);

  const weekArr = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

  const handleRadioChange = (index, type, data, value) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      const isSelected = newSelectedOptions[index][type] === value;

      newSelectedOptions[index] = {
        ...newSelectedOptions[index],
        [type]: isSelected ? false : value,
      };

      if (type === 'old') {
        newSelectedOptions[index] = {
          ...newSelectedOptions[index],
          new: false,
        };
      } else if (type === 'new') {
        newSelectedOptions[index] = {
          ...newSelectedOptions[index],
          old: false,
        };
      }
      return newSelectedOptions;
    });

    setState((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      const isSelected = newSelectedOptions[index][type] === value;

      newSelectedOptions[index] = {
        ...newSelectedOptions[index],
        type: isSelected ? null : type,
        data: isSelected ? null : data,
      };

      return newSelectedOptions;
    });
  };

  useEffect(() => {
    setDataFuncionamentoEdit(state);
    let newArr = [];
    const data = state.map((value) => value.data);
    newArr.push(data);
    setFinalFormState((prevState) => {
      return {
        ...prevState,
        funcionamento: newArr[0],
      };
    });
  }, [state, selectedOptions]);

  return (
    <>
      {funcionamentoAntigo !== funcionamento ? (
        <S.CardContainer white={true}>
          <S.OpenTimesLabels>Horário de funcionamento</S.OpenTimesLabels>
          <Typography sx={{ marginLeft: '5px' }} variant="caption" display="block" gutterBottom>
            antigo: <FaCircle style={{ marginLeft: '5px' }} size={8} color="#ee0c0c" />
            <Typography sx={{ marginLeft: '5px' }} variant="caption" display="inline" gutterBottom>
              novo: <FaCircle style={{ marginLeft: '5px' }} size={8} color="#1bdd14" />
            </Typography>
          </Typography>
          <S.TimesContainer>
            {infoOfTheWeek.map(({ antiga, nova, dia }, index) => (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} key={index}>
                  {/* Horário Antigo */}
                  <FormControl>
                    <RadioGroup
                      value={selectedOptions[index].old}
                      onChange={(e) => {
                        handleRadioChange(index, 'old', antiga, e.target.value);
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginLeft: '0', marginRight: '0' }}
                        value={`old-${index}`}
                        control={
                          <Radio
                            sx={{
                              color: D.triiboPurple,
                              '&.Mui-checked': {
                                color: D.triiboPurple,
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: 20,
                              },
                            }}
                          />
                        }
                        label={
                          <S.OpenTimeElements
                            open={antiga.ativo ? true : false}
                            invalid={compareDatesFromStr(antiga.horarioFim, antiga.horarioInicio)}
                            key={`old-${index}`}
                          >
                            <S.OpenTimeFields>{dia}</S.OpenTimeFields>
                            <S.OpenTimeFields activity={true}>
                              <S.TextJustifier>
                                <S.OpenBullet
                                  open={antiga.ativo ? true : false}
                                  invalid={compareDatesFromStr(
                                    antiga.horarioFim,
                                    antiga.horarioInicio
                                  )}
                                />
                                {antiga.ativo ? <span>Aberto</span> : <span>Fechado</span>}
                              </S.TextJustifier>
                            </S.OpenTimeFields>
                            <S.OpenTimeFields times={true}>
                              {antiga.horarioInicio === '00:00' && antiga.horarioFim === '23:59' ? (
                                <span>{'24h'}</span>
                              ) : (
                                <span>
                                  {antiga.horarioInicio ? antiga.horarioInicio : '09:00'}
                                  {' às '}
                                  {antiga.horarioFim ? antiga.horarioFim : '18:00'}
                                </span>
                              )}
                            </S.OpenTimeFields>
                            <FaCircle style={{ marginLeft: '1rem' }} size={8} color="#ee0c0c" />
                          </S.OpenTimeElements>
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* Horário Novo */}
                  <FormControl>
                    <RadioGroup
                      value={selectedOptions[index].new}
                      onChange={(e) => {
                        handleRadioChange(index, 'new', nova, e.target.value);
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginLeft: '0', marginRight: '0' }}
                        value={`new-${index}`}
                        control={
                          <Radio
                            checked={selectedOptions[index].new}
                            sx={{
                              color: D.triiboPurple,
                              '&.Mui-checked': {
                                color: D.triiboPurple,
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: 20,
                              },
                            }}
                          />
                        }
                        label={
                          <S.OpenTimeElements
                            open={nova.ativo ? true : false}
                            invalid={compareDatesFromStr(nova.horarioFim, nova.horarioInicio)}
                            key={`new-${index}`}
                          >
                            <S.OpenTimeFields>{dia}</S.OpenTimeFields>
                            <S.OpenTimeFields activity={true}>
                              <S.TextJustifier>
                                <S.OpenBullet
                                  open={nova.ativo ? true : false}
                                  invalid={compareDatesFromStr(nova.horarioFim, nova.horarioInicio)}
                                />
                                {nova.ativo ? <span>Aberto</span> : <span>Fechado</span>}
                              </S.TextJustifier>
                            </S.OpenTimeFields>
                            <S.OpenTimeFields times={true}>
                              {nova.horarioInicio === '00:00' && nova.horarioFim === '23:59' ? (
                                <span>{'24h'}</span>
                              ) : (
                                <span>
                                  {nova.horarioInicio ? nova.horarioInicio : '09:00'}
                                  {' às '}
                                  {nova.horarioFim ? nova.horarioFim : '18:00'}
                                </span>
                              )}
                            </S.OpenTimeFields>
                            <FaCircle style={{ marginLeft: '1rem' }} size={8} color="#1bdd14" />
                          </S.OpenTimeElements>
                        }
                      />
                    </RadioGroup>
                    <S.DividerContainer>
                      <S.Divider />
                    </S.DividerContainer>
                  </FormControl>
                </Box>
              </>
            ))}
          </S.TimesContainer>

          <S.EditTimesButton
            onClick={() => {
              setEditModal(true);
            }}
          >
            Editar horários de funcionamento
          </S.EditTimesButton>
        </S.CardContainer>
      ) : (
        <S.CardContainer white={true}>
          <S.OpenTimesLabels>Horário de funcionamento</S.OpenTimesLabels>
          <S.TimesContainer>
            {finalFormState.funcionamento.map((item, index) => (
              <span key={'funcionamento' + index}>
                <S.OpenTimeElements
                  open={item && item.ativo ? true : false}
                  invalid={item ? compareDatesFromStr(item.horarioFim, item.horarioInicio) : false}
                  key={index}
                >
                  <S.OpenTimeFields>{weekArr[index]}</S.OpenTimeFields>
                  <S.OpenTimeFields activity={true}>
                    <S.TextJustifier>
                      <S.OpenBullet
                        open={item && item.ativo ? true : false}
                        invalid={
                          item ? compareDatesFromStr(item.horarioFim, item.horarioInicio) : false
                        }
                      />
                      {item && item.ativo ? <span>Aberto</span> : <span>Fechado</span>}
                    </S.TextJustifier>
                  </S.OpenTimeFields>
                  <S.OpenTimeFields times={true}>
                    {item && item.horarioInicio === '00:00' && item.horarioFim === '23:59' ? (
                      <span>{'24h'}</span>
                    ) : (
                      <span>
                        {item && item.horarioInicio ? item.horarioInicio : '09:00'}
                        {' às '}
                        {item && item.horarioFim ? item.horarioFim : '18:00'}
                      </span>
                    )}
                  </S.OpenTimeFields>
                </S.OpenTimeElements>
                <S.DividerContainer>
                  <S.Divider />
                </S.DividerContainer>
              </span>
            ))}
          </S.TimesContainer>
          <S.EditTimesButton
            onClick={() => {
              setEditModalTwo(true);
            }}
          >
            Editar horários de funcionamento
          </S.EditTimesButton>
        </S.CardContainer>
      )}
    </>
  );
};

export default OpeningHours;
