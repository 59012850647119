import React, { useEffect, useState } from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import { MdArrowBackIosNew } from 'react-icons/md';

import InputSearch from 'js/components/TriiboComponents/InputSearch';
import SimpleTable from 'js/components/TriiboComponents/Tables/SimpleTable';
import { CircularProgress, Pagination } from '@mui/material';
import { AreaHeader, GoBack } from '../../Configurations/CreateClub/styles';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { Link, useHistory } from 'react-router-dom';

export default function ListEstablishment() {
  const [establishments, setEstablishments] = useState([]);
  const [establishmentsSearch, setEstablishmentsSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const PER_PAGE = 10;
  const count =
    inputSearch.length > 2
      ? Math.ceil(establishmentsSearch.length / PER_PAGE)
      : Math.ceil(establishments.length / PER_PAGE);

  const _DATA_SEARCH = usePagination(establishmentsSearch, PER_PAGE);
  const _DATA = usePagination(establishments, PER_PAGE);

  const handleChangePage = (e, p) => {
    inputSearch.length > 2 ? _DATA_SEARCH.jump(p) : _DATA.jump(p);
    inputSearch.length > 2 ? setPageSearch(p) : setPage(p);
  };

  const transformData = (data) => {
    return data.map((item) => {
      const today = new Date(); // Data atual
      const disableDate = new Date(item.disableDate); // Data de desativação do item

      let isPublished = 'Inativo'; // Inicia por padrão como inativo

      // Verifica se o isPublished é true e se a data de desativação está no prazo
      if (item.isPublished && disableDate >= today) {
        isPublished = 'Ativo';
      }

      return {
        ...item,
        published: isPublished,
        origin: item.origin ? item.origin : 'Triibo',
      };
    });
  };

  useEffect(() => {
    apiListEstablishment('', 0, 50)
      .then((res) => {
        setEstablishments(transformData(res));
        setLoadingPage(false);
      })
      .catch(() => {
        setLoadingPage(false);
      });
  }, []);

  let debouce;

  useEffect(() => {
    setEstablishmentsSearch([]);
    setLoading(true);
    if (inputSearch.length > 2) {
      setPageSearch(1);
      _DATA_SEARCH.setCurrentPage(1);

      debouce = setTimeout(() => {
        apiListEstablishment(inputSearch, 0, 50)
          .then((res) => {
            setEstablishmentsSearch(transformData(res));
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }, 1000);
    } else {
      setLoading(false);
    }
    return () => {
      clearTimeout(debouce);
    };
  }, [inputSearch]);

  useEffect(() => {
    if (count === page) {
      apiListEstablishment('', establishments.length, 20).then((res) => {
        setEstablishments((prev) => [...prev, ...transformData(res)]);
      });
    }

    if (count === pageSearch) {
      apiListEstablishment('', establishmentsSearch.length, 20).then((res) => {
        setEstablishmentsSearch((prev) => [...prev, ...transformData(res)]);
      });
    }
  }, [page, pageSearch]);

  const redirectOnClick = (item) => {
    history.push({
      pathname: `/admin/estabelecimento/${item.id}`,
      state: item,
    });
  };

  const column = [
    { heading: 'ID', value: 'id' },
    { heading: 'Estabelecimento', value: 'nome' },
    { heading: 'Endereço', value: 'endereco' },
    { heading: 'Origin', value: 'origin' },
    { heading: 'Status', value: 'published' },
  ];

  if (loadingPage) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress size="100px" />
      </div>
    );
  }

  return (
    <div>
      <S.Container>
        <AreaHeader
          style={{
            color: '#08bad0',
            justifyContent: 'end',
            padding: '1rem',
            marginLeft: '3rem',
          }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>

        <D.TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3px',
          }}
        >
          Lista de Estabelecimentos
        </D.TriiboH1>

        <S.SearchBox>
          <InputSearch
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            disabled={false}
            placeholder="Pesquise um estabelecimento"
          />

          <Link to={{ pathname: '/admin/estabelecimentos/adicionar', state: '' }}>
            <DashedButton width={'250px'}>Adicionar Estabelecimento</DashedButton>
          </Link>
        </S.SearchBox>

        {loading ? (
          <div
            style={{
              margin: '0 auto',
            }}
          >
            <CircularProgress size="50px" />
          </div>
        ) : (
          <>
            <SimpleTable
              data={inputSearch.length > 2 ? _DATA_SEARCH.currentData() : _DATA.currentData()}
              column={column}
              usePaginate={false}
              redirectOnClick={redirectOnClick}
            />
            <Pagination
              sx={{ margin: '0 auto' }}
              count={count}
              size="large"
              page={inputSearch.length > 2 ? pageSearch : page}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={handleChangePage}
            />
          </>
        )}
      </S.Container>
    </div>
  );
}
