import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

export function voucherSend_v1(templateId, batchId, clientList, consumed, callback = null) {
  return new Promise(async function (resolve, reject) {
    getChannelTokenUidNull()
      .then(async (token) => {
        for (let index = 0; index < clientList.length; ) {
          await axios
            .post(
              cfac22('API_HOST_V1') + 'voucherSend_v1',
              {
                triiboHeader: {
                  apiToken: cfac22('keyapiv1triibowebadmin'),
                  uId: null,
                  channelGroup: cfac22('channelGroup'),
                  channelName: cfac22('channelName'),
                  channelTokenId: token,
                  channelId: cfac22('channelId'),
                  sessionId: '001',
                  transactionId: '002',
                },
                clientID: clientList[index],
                templateID: templateId,
                consumed: consumed,
              },
              {
                headers: { access_token: cfac22('ACCESS_TOKEN') },
              }
            )
            .then((result) => {
              index = index + 1;
              resolve(result.data.success);
            })
            .catch((error) => {
              index = index + 1;
              reject(error);
            });
        }
        if (callback === null) {
          window.history.back();
        } else {
          callback();
        }
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}
