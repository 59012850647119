import authToken from 'js/library/utils/API/authToken';
import axios from 'axios';
import cfac22 from '../../cfac22';

export function getStoreByEstablishment(establishmentId, from, size, status) {
  return new Promise(async (resolve, reject) => {
    try {
      // Obter o token de autenticação
      const token = await authToken();

      // Construir a URL com os parâmetros de consulta
      const baseUrl = cfac22('API_HOST_V2') + `/stores/all/storeEstablishmentId`;
      const params = {
        establishmentId,
        from,
        size,
        status,
      };

      // Criar a URL com os parâmetros de consulta
      const queryParams = new URLSearchParams(params).toString();
      const url = `${baseUrl}?${queryParams}`;

      // Configuração da requisição axios
      const config = {
        method: 'get',
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Fazer a requisição
      const response = await axios(config);
      resolve(response.data.promotions);
    } catch (error) {
      reject(error);
    }
  });
}
