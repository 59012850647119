import React, { useEffect } from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import Dropdown from './Dropdown';
import ContactInputField from './ContactInputField';

import {
  FaWhatsapp,
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitter,
  FaLinkedinIn,
  FaPhoneAlt,
  FaSms,
  FaHandsHelping,
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import logger from 'js/library/utils/logger';

const countDigits = (value) => {
  return value.replace(/\D/g, '').length;
};

const StepTwo = (props) => {
  const {
    formState,
    setFormState,
    setCurrentScreen,
    progress,
    setProgress,
    editPage,
    setDisabledButton,
  } = props;

  const numbersProps = [
    {
      icon: <FaPhoneAlt style={{ color: '#06BAD0' }} />,
      placeholdeText: '00 00000-0000',
      type: 'telefone',
    },
    {
      icon: <FaWhatsapp style={{ color: '#42BF00' }} />,
      placeholdeText: '00 00000-0000',
      type: 'whatsapp',
    },
    {
      icon: <FaSms style={{ color: '#06BAD0' }} />,
      placeholdeText: '00 00000-0000',
      type: 'sms',
    },
    {
      icon: <FaHandsHelping style={{ color: '#AC10D1' }} />,
      placeholdeText: '0800 0800',
      type: '0800',
    },
    // {
    //   icon: <FaMailBulk style={{ color: "#08BAD0" }} />,
    //   placeholdeText: "email@email.com.br",
    //   type: "email",
    // },
  ];

  const socialsProps = [
    {
      icon: <FaInstagramSquare style={{ color: '#DD2A7B' }} />,
      placeholdeText: 'https://instagram.com/triibo/',
      type: 'instagram',
    },
    {
      icon: <FaFacebookSquare style={{ color: '#4267B2' }} />,
      placeholdeText: 'https://facebook.com/triibo/',
      type: 'facebook',
    },
    {
      icon: <FaTwitter style={{ color: '#1DA1F2' }} />,
      placeholdeText: 'https://twitter.com/triibo/',
      type: 'twitter',
    },
    {
      icon: <FaLinkedinIn style={{ color: '#0077b5' }} />,
      placeholdeText: 'https://linkedin.com/company/triibo/',
      type: 'linkedin',
    },
    // {
    //   icon: <FaGlobeAmericas style={{ color: "#00a0dc" }} />,
    //   placeholdeText: "https://triibo.com.br",
    //   type: "website",
    // },
  ];

  useEffect(() => {
    let hasContact = 0;

    console.log(formState.contatoTriibo.length);
    console.log(formState);

    for (let i = 0; i < formState.contatoTriibo.length; i++) {
      const contato = formState.contatoTriibo[i].value;
      if (countDigits(contato) >= 13) {
        hasContact = 100;
        break;
      }
    }

    for (let i = 0; i < formState.redesSociais.length; i++) {
      if (formState.redesSociais[i].value.length > 0) {
        hasContact = 100;
      } else {
        hasContact = 0;
      }
    }
    setProgress((prevProgress) => 0 + hasContact);
  }, [formState]);

  return (
    <>
      <S.MainContainer stepTwo={true}>
        <S.CardContainer id="card-01" white={true}>
          <S.SectionLabelContainer>
            <S.SectionLabelBig>Contatos para os clientes</S.SectionLabelBig>
            <S.SectionLabelSmall>
              estes contatos estarão disponíveis para o público*
            </S.SectionLabelSmall>
          </S.SectionLabelContainer>
          <S.ContactInfoContainer>
            <Dropdown
              placeholder={'Selecione um meio de contato'}
              formState={formState}
              setFormState={setFormState}
              socials={false}
              numbersProps={numbersProps}
            />
            <S.ContactFieldsContainer>
              {formState.contatoTriibo.map(({ value, type }, index) => (
                <ContactInputField
                  key={index}
                  placeholder={numbersProps.filter((e) => e.type === type)[0].placeholdeText}
                  icon={numbersProps.filter((e) => e.type === type)[0].icon}
                  formState={formState}
                  setFormState={setFormState}
                  index={index}
                  group={'contact'}
                  type={type}
                  setDisabledButton={setDisabledButton}
                />
              ))}
            </S.ContactFieldsContainer>
          </S.ContactInfoContainer>
        </S.CardContainer>
        <S.CardContainer id="card-02" blue={true}>
          <S.SectionLabelContainer white={true}>
            <S.SectionLabelBig>Sites e redes sociais</S.SectionLabelBig>
          </S.SectionLabelContainer>
          <S.ContactInfoContainer>
            <Dropdown
              placeholder={'Selecione uma rede social'}
              formState={formState}
              setFormState={setFormState}
              socials={true}
              socialsProps={socialsProps}
            />
            <S.ContactFieldsContainer>
              {formState.redesSociais.map(({ value, type }, index) => (
                <ContactInputField
                  key={index}
                  placeholder={socialsProps.filter((e) => e.type === type)[0].placeholdeText}
                  icon={socialsProps.filter((e) => e.type === type)[0].icon}
                  formState={formState}
                  setFormState={setFormState}
                  index={index}
                  group={'socials'}
                  setDisabledButton={setDisabledButton}
                />
              ))}
            </S.ContactFieldsContainer>
          </S.ContactInfoContainer>
        </S.CardContainer>
      </S.MainContainer>

      {!editPage ? (
        <S.ButtonContainer>
          <D.TriiboWhiteButton
            onClick={() => {
              setCurrentScreen(0);
            }}
            blue={true}
          >
            Etapa anterior
          </D.TriiboWhiteButton>

          <D.TriiboFilledButton
            onClick={() => {
              const contatoPreenchido = formState.contatoTriibo.some(
                (contato) => contato.value.trim().length >= 14
              );
              if (progress < 100) {
                toast.error(`Os seguintes campos ainda precisam ser preenchidos: 
                  ${formState.contatoTriibo < 11 ? 'CONTATOS PARA OS CLIENTES,' : ''}
                  ${formState.redesSociais[0] ? 'REDE SOCIAL' : ''}
                  `);
                return;
              }

              if (!contatoPreenchido) {
                toast.error(`Você precisa preencher ao menos um número de contato válido.`);
                return;
              }

              if (formState.redesSociais) {
                formState.redesSociais.map((redeSocial, index) => {
                  if (redeSocial.value.length < 1) {
                    formState.redesSociais.splice(index, 1);
                  }
                });
              }

              setCurrentScreen(2);
            }}
            blue={true}
          >
            Próxima etapa
          </D.TriiboFilledButton>
        </S.ButtonContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default StepTwo;
