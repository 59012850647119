import { ModalBoxClube } from 'js/components/Configurations/CreateClub/BasicRegistration/styles';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { Box } from '@mui/material';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  width: 100%;

  button {
    font-weight: 700;
  }
`;

export const ContentSwitchSegment = styled.span`
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.2rem;

  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

export const InvisbleButton = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.$color ? props.$color : 'inherit')};
`;

export const ContainerFormSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;

  background: #fff;
  border: 1px solid #70707033;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 16px;
  padding: 30px 30px;
  min-height: 20rem;
  width: 70%;

  button {
    margin: 0 auto;
    width: 60%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;

    button {
      width: 100%;
    }

    input {
      height: 0.6rem;
    }
  }
`;

export const BoxModal = styled(ModalBoxClube)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const BoxAutocomplete = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentButton = styled.span`
  display: flex;
  gap: 1rem;
`;

export const ContentInfoModalAdd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  max-width: 400px;

  p {
    font-weight: 500;
    font-size: 0.9rem;
    padding-left: 0;
    height: max-content;
    word-wrap: break-word;
    white-space: normal;

    span {
      font-weight: 600;
      min-width: max-content;
    }
  }

  strong {
    font-weight: 600;
  }
`;

export const BoxInfo = styled(Box)`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  border-bottom: 1px solid #6f6f6f1f;
`;

export const ContentInfoModalAddResource = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  width: max-content;

  p {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-weight: 500;
    font-size: 0.9rem;
    padding-left: 0;

    span {
      font-weight: 600;
    }
  }

  strong {
    font-weight: 600;
  }
`;

export const DeleteUserButton = styled.button`
  display: block;
  padding: 6px 6px 3px 6px;
  background: red;
  border-radius: 4px;
  margin-left: 10px;
  border: 0;
  cursor: pointer;
`;

export const ListResourcesLinked = styled(Box)`
  border: 1px solid #c5c5c5;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  max-height: 330px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cda8e0;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
`;
