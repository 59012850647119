import React from "react";

import { getValueByType, findValueByKey } from "js/library/utils/helpers";

import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";

const UserInfoPreview = (props) => {
  const { currentSearchResult, setUsersArr, usersArr } = props;

  if (!Object.keys(currentSearchResult).length) {
    return <></>;
  }

  return (
    <S.PreviewCard>
      <D.TriiboH3>Prévia de usuário</D.TriiboH3>
      <div>
        <span class="fieldName">apelido:</span>
        <span></span>
        <span>{findValueByKey(currentSearchResult, "aliasName")}</span>
      </div>
      <div>
        <span class="fieldName">nome:</span>
        <span></span>
        <span>{findValueByKey(currentSearchResult, "name")}</span>
      </div>
      <div>
        <span class="fieldName">triiboId:</span>
        <span></span>
        <span>{findValueByKey(currentSearchResult, "triiboId")}</span>
      </div>
      <span>
        <D.TriiboFilledButton
          purple={true}
          onClick={(e) => {
            e.preventDefault();

            // checa se a uId já existe no array de usuários
            if (
              usersArr.some((item) =>
                item["uId"].includes(currentSearchResult.uId)
              )
            ) {
              return;
            }

            setUsersArr([
              ...usersArr,
              {
                ...findValueByKey(currentSearchResult, "partnerInfo"),
                ...findValueByKey(currentSearchResult, "userInfo"),
                uId: currentSearchResult.uId,
                cpf: getValueByType(
                  findValueByKey(currentSearchResult, "partnerInfo")[
                    "documentList"
                  ],
                  "cpf"
                ),
              },
            ]);
          }}
        >
          Adicionar usuário
        </D.TriiboFilledButton>
      </span>
    </S.PreviewCard>
  );
};

export default UserInfoPreview;
