import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 1.3rem;

  @media (max-width: 690px) {
    flex-direction: column;
  }
`;

export const SelectDateContainer = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
`;

export const InputDate = styled.input`
  border: none;
  outline: none;
  height: 100%;
  font-size: 20px;
  color: #383838;
  flex: 1;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export const SelectTimeContainer = styled.div`
  /* width: 100%; */
  height: 47px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 0.5rem;
`;

export const SelectTime = styled.select`
  border: none;
  outline: none;
  height: 100%;
  flex: 1;
  font-size: 20px;
  color: #383838;
`;

export const SheduleButton = styled.button`
  width: 100%;
  height: 47px;
  border-radius: 10px;
  background: #297380;
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: #297380e5;
  }
`;
