import React, { useEffect, useState } from 'react';
import cfac22 from 'js/library/utils/cfac22';
import { blobToBase64, convertToDateDefault } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';

// Styles
import * as D from 'js/components/DesignSystem/Table/styles';
import { Autocomplete, Button, CircularProgress, Grid, TextField, Tooltip } from '@mui/material';
import { FileArrowDown, CheckCircle } from 'phosphor-react';

// Components
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { GoBackHeader } from '../RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../RelationshipClub/HeaderTitle';
import { MainContainer } from 'js/components/Clubs/clubeTableStyles';
import { ModalValidationList } from './ModalValidationList';

// Apis
import { createPartnerFiles } from 'js/library/utils/API/createPartnerFiles';
import { getPartnerFiles } from 'js/library/utils/API/getPartnerFiles';
import { getPartnerFilesType } from 'js/library/utils/API/getPartnerFilesType';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { validatePartnerFile } from 'js/library/utils/API/validatePartnerFile';

export function PartnerFilesList() {
  const column = [
    { heading: 'Nome do arquivo', value: 'filename' },
    { heading: 'Status', value: 'status' },
    { heading: 'Tipo', value: 'type' },
    { heading: 'Data de criação', value: 'creationDate' },
  ];
  const { pathname } = document.location;
  const clubeId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const partnerIdClube = localStorage.getItem('partnerIdClube');
  const [refreshPage, setRefreshPage] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileTypeList, setFileTypeList] = useState([]);
  const [fileTypeSelected, setFileTypeSelected] = useState('');
  const [loadingUpdateFile, setLoadingUpdateFile] = useState(false);
  const [isOpenModalValidation, setIsOpenModalValidation] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [validateInfos, setValidateInfos] = useState({
    clubName: '',
    fileId: '',
    filename: '',
    fileTypeId: '',
  });

  // Retorna os tipos e os arquivos pertecentes ao partner
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [filesResponse, typesResponse] = await Promise.all([
          getPartnerFiles(partnerIdClube),
          getPartnerFilesType(partnerIdClube),
        ]);

        const processedFiles = filesResponse.map((file) => ({
          ...file,
          creationDate: convertToDateDefault(file.creationDate), // Formata a data
          status: formatStatus(file.status), // Formata o status
        }));

        setFileList(processedFiles);
        setFileTypeList(typesResponse);
      } catch (e) {
        console.error(e);
        toast.error(e);
      }
    };

    partnerIdClube && fetchData();
  }, [partnerIdClube, refreshPage]);

  // Trata os status da api getPartnerFiles
  const formatStatus = (status) => {
    switch (status) {
      case 'registered':
        return 'Registrado';
      case 'validated':
        return 'Validado';
      case 'processed':
        return 'Processado';
      case 'validationError':
        return 'Validado com erro';
      default:
        return status; // Retorna o status original se não corresponder
    }
  };

  // Função que pega as informações do arquivo selecionado para upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Cria o partnerFile
  const handleFileUpdate = async () => {
    try {
      setLoadingUpdateFile(true);

      if (!selectedFile) {
        return toast.warning('É necessário selecionar um arquivo para continuar.');
      }

      const arrayBuffer = await readFileAsArrayBuffer(selectedFile);
      const blob = new Blob([arrayBuffer], { type: selectedFile.type });
      const base64String = await blobToBase64(blob);
      const base64Data = base64String.split(',')[1];

      const fileInfo = {
        mimeType: selectedFile.type,
        fileName: `${fileTypeSelected}_${Date.now()}_${selectedFile.name}`,
        buffer: base64Data,
      };

      const successUpload = await uploadFile(fileInfo);
      if (successUpload) {
        const payload = {
          filename: fileInfo.fileName,
          partnerId: partnerIdClube,
          path: `partnerFiles/${clubeId}/registered/${fileInfo.fileName}`,
          type: fileTypeSelected,
        };

        const newPartnerFiles = await createPartnerFiles(payload);
        toast.success('Arquivo processado com sucesso.');
        insertValidateInfos(null, newPartnerFiles.filename, newPartnerFiles.id); // Seta os valores necessários para validar o partnerFile.

        // Limpa as seleções após enviar o arquivo
        setFileTypeSelected('');
        setInputValue('');
        setSelectedFile(null);
      } else {
        toast.warning('O arquivo não preenche todos os requisitos.');
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setLoadingUpdateFile(false);
    }
  };

  const handleOpenModalValidation = () => {
    setIsOpenModalValidation((prev) => !prev);
  };

  // Insere os valores necessários para validação do arquivo e abre o modal de validar.
  const insertValidateInfos = (fileType, fileName, fileId) => {
    const fileTypeId = fileType
      ? fileTypeList.find((type) => type.filePrefix === fileType)?.id
      : fileTypeList.find((type) => type.filePrefix === fileTypeSelected)?.id;

    setValidateInfos({
      ...validateInfos,
      clubName: clubeId,
      fileTypeId: fileTypeId,
      filename: fileName,
      fileId: fileId,
    });

    handleOpenModalValidation();
  };

  // Faz a leitura do arquivo selecionado
  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = () => reject(new Error('Erro ao ler o arquivo.'));
    });
  };

  // Envia o arquivo para o storage
  const uploadFile = async (fileInfo) => {
    return await uploadFiles(`partnerFiles/${clubeId}/registered`, fileInfo, false);
  };

  const handleSelected = (event, newValue) => {
    if (newValue) {
      setFileTypeSelected(newValue.filePrefix);
    } else {
      setFileTypeSelected(null);
    }
  };

  // Valida se o arquivo enviado está de acordo com o tipo de arquivo selecionado
  const validatePartner = async () => {
    setLoadingModal(true);
    try {
      await validatePartnerFile(validateInfos);
      setRefreshPage((prev) => !prev);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoadingModal(false);
      handleOpenModalValidation();
      setRefreshPage((prev) => !prev);
    }
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <D.TableTH>Ações</D.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <D.TableTD>
              <span style={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Baixar arquivo" placement="left">
                  <a
                    href={cfac22('STORAGE_URL') + encodeURIComponent(childrens.path) + '?alt=media'}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                  >
                    <FileArrowDown size={28} color="#06bad0" />
                  </a>
                </Tooltip>
                {childrens.status !== 'Registrado' ? null : (
                  <Tooltip title="Validar arquivo" placement="right">
                    <button
                      onClick={() =>
                        insertValidateInfos(childrens.type, childrens.filename, childrens.id)
                      }
                      style={{
                        background: 'transparent',
                        border: 0,
                        cursor: 'pointer',
                      }}
                    >
                      <CheckCircle size={28} color="#37b24d" />
                    </button>
                  </Tooltip>
                )}
              </span>
            </D.TableTD>
          </>
        ),
    };
  };

  return (
    <MainContainer style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Listagem e upload de arquivos" />

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: '2rem' }}
      >
        <Grid item>
          <Autocomplete
            options={fileTypeList?.filter((type) =>
              type.filePrefix.toLowerCase().includes(inputValue.toLowerCase())
            )}
            getOptionLabel={(option) => option.filePrefix}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={handleSelected}
            renderInput={(params) => (
              <TextField {...params} label="Tipo do arquivo" variant="outlined" fullWidth />
            )}
            renderOption={(props, option) => <li {...props}>{option.filePrefix}</li>}
            sx={{ minWidth: '14rem' }}
          />
        </Grid>

        <Grid item>
          <input
            type="file"
            accept=".csv, .txt"
            style={{ display: 'none' }}
            id="file-upload"
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              sx={{ width: '200px', height: '53px', fontWeight: 700 }}
            >
              Selecionar Arquivo
            </Button>
          </label>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            disabled={!selectedFile || fileTypeSelected === ''}
            sx={{ width: '200px', height: '53px', fontWeight: 700 }}
            onClick={handleFileUpdate}
            variant="contained"
          >
            {loadingUpdateFile ? (
              <CircularProgress size={25} thickness={6} sx={{ color: '#fafafa' }} />
            ) : (
              'Enviar'
            )}
          </Button>
        </Grid>

        <Grid item md={12} xs={12} sx={{ color: 'red', textAlign: 'center' }}>
          {selectedFile && <h4>{`Arquivo selecionado: ${selectedFile?.name}`}</h4>}
        </Grid>
      </Grid>

      <Grid container sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid item md={12} sx={{ marginTop: '2rem' }}>
          <CustomButtonsTable
            data={fileList}
            column={column}
            perPage={1000}
            componentReceiver={componentReceiver}
            isPagination={true}
          />
        </Grid>
      </Grid>
      <ModalValidationList
        close={handleOpenModalValidation}
        loading={loadingModal}
        isOpen={isOpenModalValidation}
        setRefreshPage={setRefreshPage}
        validateApi={validatePartner}
      />
    </MainContainer>
  );
}
