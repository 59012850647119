import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { withStyles } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

//Tooltip com button de ?
export function TooltipInterrogation({ title, description }) {
  return (
    <HtmlTooltip
      arrow
      placement="top"
      title={
        <>
          <h4>{title}</h4>
          <em>{description}</em>
        </>
      }
    >
      <IconButton
        style={{
          width: '0px',
          color: '#FFF',
        }}
        aria-label="infoFavicon"
      >
        <HelpOutlineIcon width="25px" />
      </IconButton>
    </HtmlTooltip>
  );
}

export function TooltipEnvolveElement({ title, description, children, label, larg }) {
  return (
    <HtmlTooltip
      style={{ width: '2rem' }}
      placement="top"
      title={
        <>
          <h4>{title}</h4>
          <em>{description}</em>
        </>
      }
    >
      <IconButton
        style={{
          width: `${larg === undefined ? '10.5rem' : larg}`,
          maxWidth: '100%',
          padding: '0px',
        }}
        aria-label={label}
      >
        {children}
      </IconButton>
    </HtmlTooltip>
  );
}

