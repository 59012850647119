import { makeStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxWidth: 'calc(100vw - 72px)',
    display: 'flex',
    flexDirection: 'column',
  },

  boxActions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid #E8E8E8',
    padding: '2rem 1rem 1rem 1rem',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2rem 0',
  },

  title: {
    fontWeight: 'bold',
    color: '#08bad0',
  },

  titleModal: {
    fontWeight: 'bold',
    color: '#08bad0',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  modalInput1: {
    marginTop: '40px',
  },

  arrowBackButton: {
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '20px',
    color: '#08bad0',
  },

  option1Button: {
    fontWeight: 'bold',
    fontSize: '20px',
    textTransform: 'capitalize',
  },

  option2Button: {
    width: 240,
    fontWeight: 'bold',
    fontSize: '20px',
    textTransform: 'capitalize',
    position: 'relative',
    bottom: theme.spacing(6),
    left: theme.spacing(7),
  },

  gridContent: {
    display: 'flex',
    flexDirection: 'row',
  },

  boxImage3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modalStyle: {
    maxWidth: '50rem',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    backgroundColor: 'white',
    padding: '3rem',
  },

  modalButtonSubmit: {
    // width: "146px",
    margin: '2rem auto 0',
  },

  modalQRcodeStyle: {
    maxHeight: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    backgroundColor: 'white',
    padding: '3%',
    width: '75%',
    overflow: 'auto',
  },

  equipeButton: {
    position: 'fixed',
    top: '2px',
    right: '80px',
    padding: '0',
    margin: '0.5em',
    fontSize: '16px',
    textTransform: 'capitalize',
    width: '110px',
    height: '30px',
  },

  link: {
    textDecoration: 'none',
    textTransform: 'capitalize',
  },

  boxInportFiles: {
    marginBottom: '2rem',
  },

  table: {
    minWidth: 650,
  },
}));

export const stylesMui5 = {
  searchTitle: {
    color: '#08bad0',
    fontSize: '2rem',
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  boxSearch: {
    display: 'flex',
    minWidth: '250px',
    width: '50%',
    position: 'relative',
    marginBottom: '6rem',
  },
  inputSearch: {
    position: 'absolute',
    width: '100%',
  },
  searchIcon: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },

  tableHead: {
    backgroundColor: '#FAFAFA',
    fontWeight: 'bold',
  },
  buttonAction: {
    color: '#000',
    padding: '0',
    minWidth: '30px',
  },
};
