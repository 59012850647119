import axios from 'axios';

import { getUserInfo, validateRegistrationDate } from '../../utils/helpers';
import cfac22 from '../cfac22';

export default async function authToken(uId) {
  return new Promise(async (resolve, reject) => {
    //PEGA O TOKEN DO LOCAL_STORAGE
    const userInfo = getUserInfo();
    let loggedUID = null;
    loggedUID = userInfo && userInfo.uId;
    const token = JSON.parse(localStorage.getItem('triiboToken'));
    const auth = btoa(`${cfac22('USERNAME')}:${cfac22('PASSWORD')}`);

    const config = {
      method: 'post',
      url: cfac22('API_HOST_V2') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
      },
    };

    if (userInfo) {
      config.headers.uid = uId ? uId : userInfo.uId;
    }

    const validateDate = validateRegistrationDate(token?.date, 15);

    if (token === null || token === undefined || !validateDate || loggedUID !== token.uId || uId) {
      axios(config)
        .then(function (response) {
          localStorage.setItem(
            'triiboToken',
            JSON.stringify({
              token: response.data.token,
              date: Date.now(),
              uId: loggedUID,
            })
          );
          resolve(response.data.token);
        })
        .catch(function (error) {
          reject(error);
        });
    } else {
      resolve(token.token);
    }
  });
}
