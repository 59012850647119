import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
  padding-top: 3rem;
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  a {
    text-decoration: none;
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: #06bad0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 3rem;
  border-radius: 10px;

  :hover {
    filter: brightness(0.9);
  }

  h4 {
    color: #fff;
  }
`;
