import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const ListContainer = styled.ul`
  width: 100%;
  list-style: none;
  max-height: 525px;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #f2f2f2;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  background: #f2f2f2;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  margin-bottom: 6px;

  h5 {
    padding-left: 1rem;
  }
`;

export const Actions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const EditButton = styled.button`
  height: 100%;
  width: 44px;
  border: none;
  border-radius: 5px;
  background: #6e3296;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  height: 100%;
  width: 44px;
  border: none;
  background: #f13a3a;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
`;
