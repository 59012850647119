import React from 'react';
import * as S from './styles';

export default function DashedButton({
  children,
  width,
  height,
  bgColor,
  ...props
}) {
  return (
    <S.Button width={width} height={height} bgColor={bgColor} {...props}>
      {children}
    </S.Button>
  );
}
