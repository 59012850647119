import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InputLabel } from '@mui/material';
import { DebounceInput } from 'react-debounce-input';

export const MainContainer = styled.div`
  max-height: 100%;
  /* overflow-y: auto; */
  padding: 35px 22px;
`;

export const ContentAddClube = styled.div`
  float: right;
`;

export const LoadingTableClube = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RedirectAddClube = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

export const Title = styled.h2`
  float: left;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #08bad0;
`;

export const ContainerSearch = styled.div`
  margin-top: 1rem;
  position: relative;

  .searchIcon {
    position: absolute;
    bottom: 14px;
    left: -1px;
  }
`;

export const InputSearch = styled(DebounceInput)`
  border-bottom: 1px solid #6b6b6b;
  border-left: none;
  border-right: none;
  border-top: none;

  box-shadow: 0 0 0 0;
  font-size: 16px;
  margin-bottom: 10px;
  outline: 0;
  padding-bottom: 5px;
  padding-left: 26px;
  width: 100%;
`;

export const LabelSearch = styled(InputLabel)`
  font-size: 13px;
  margin-bottom: 1rem;
`;
