import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Grid } from '@mui/material';

import DataTableEstablishmentApproved from 'js/containers/DataTable/DataTableEstablishmentApproved';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const EstablishmentApprovedList = () => {
  const state = {
    id: '',
    fotoThumb: '',
    nome: '',
    endereco: '',
    data: [],
    rows: [
      {
        id: 'thumbnail',
        numeric: false,
        disablePadding: false,
        label: 'Thumbnail',
      },
      {
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome do estabelecimento',
      },
      {
        id: 'tags',
        numeric: false,
        disablePadding: false,
        label: 'Tipo de estabelecimento',
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
      },
      {
        id: 'approvalDate',
        numeric: false,
        disablePadding: false,
        label: 'Data da aprovação',
      },
    ],
    pathName: '/admin/aprovacao/estabelecimentos',
    tableColumns: ['fotoThumb'],
  };

  return (
    <Container>
      <GoBackHeader />
      <HeaderTitle pageInfo="Curadoria de Estabelecimentos" />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} />
        <Grid item md={6} xs={12}>
          <div className="position-btn3">
            <Link to="/admin/aprovar/estabelecimentos" style={{ textDecoration: 'none' }}>
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'none',
                }}
                title="Ver estabelecimentos"
                variant="contained"
                color="primary"
                size="small"
              >
                Ver estabelecimentos em avaliação
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <DataTableEstablishmentApproved
        rows={state.rows}
        tableNode={'Estabelecimento-Aprovacoes'}
        pathName={state.pathName}
      />
    </Container>
  );
};

export default EstablishmentApprovedList;

