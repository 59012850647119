import axios from 'axios';

import authTokenWithScAndUid from '../authTokenWithScAndUid';
import cfac22 from '../../cfac22';

export default async function SetUserMapfre(partnerList, uId) {
  const token = await authTokenWithScAndUid(uId);

  var config = {
    method: 'post',
    url: `${cfac22('API_HOST_V2')}/mapfre/setUserMapfre`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      partnerList: partnerList,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
}
