import React from 'react';
import { makeStyles } from '@mui/styles'
import { Button, Modal } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 10px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0,0.9)',
        borderRadius: '8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        fontSize: '1.5rem',
        marginBottom: '20px',
    },
    text: {
        fontSize: '1rem',
    },
    contentButton: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '0 20px',
        marginTop: '20px',
        justifyContent: 'end',
    },
    button: {
        marginRight: '20px'
    }
}));

export default function ModalImageAspectRation({ open, handleClose, handleResetImageAndColor }) {
    const classes = useStyles();

    return(
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
        
            <div className={classes.paper}>
                <h3 className={classes.title} >Mudança do formato das imagens do template</h3>
                <span className={classes.text} >Caso confirme a mudança do formato das imagens do template, as imagens do template anteriores serão apagadas.</span>

                <div className={classes.contentButton}>
                    <Button onClick={handleClose} className={classes.button} variant="contained" color="primary" disableElevation>
                    Cancelar
                    </Button>
                    <Button onClick={handleResetImageAndColor} variant="contained" color="primary" disableElevation>
                    Confirmar
                    </Button>
                </div>
            </div>
        
        </Modal>
    );
}