import React from 'react';

import * as S from './style';

const ButtonMenu = (props) => {
  const { open, type } = props;

  const handleClick = () => {
    if (type) {
      localStorage.setItem('type', type);
    }
  };

  return (
    <>
      <S.SidebarLink open={open} to={props.to}>
        <S.SidebarButton open={open} onClick={handleClick}>
          <S.SidebarButtonContents open={open}>
            <S.SidebarButtonIcon open={open}>
              <S.SidebarIconContainer>{props.icon}</S.SidebarIconContainer>
            </S.SidebarButtonIcon>

            <S.SidebarButtonChildren open={open}>{props.children}</S.SidebarButtonChildren>
          </S.SidebarButtonContents>
        </S.SidebarButton>
      </S.SidebarLink>
    </>
  );
};

export default ButtonMenu;
