import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import GiveCouponsImg from 'styles/assets/estabelecimentos/estabBanner.png';
import UserLoginImg from 'styles/assets/estabelecimentos/estabBanner2.png';
import SendMessageImg from 'styles/assets/estabelecimentos/estabBanner3.png';

import { useStyles } from './useStyles';
import { Grid, Button, Container, Snackbar, Box } from '@mui/material';

// APIS
import { HeaderTitle } from './HeaderTitle';

export default function RelationshipClub({ history, ...props }) {
  const [openAlert, setOpenAlert] = useState(false);

  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Usuário não existe.</span>}
      />
      <HeaderTitle pageInfo="Relacionamento" />
      <Grid container spacing={10}>
        <Grid item container sm={12}>
          <Grid item sm={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img alt="" src={GiveCouponsImg} width={355} />
              <Button
                variant="contained"
                color="primary"
                disabled
                className={classes.option1Button}
                // onClick={handleModalChange}
                style={{ width: 340, marginTop: 30 }}
              >
                Dar cupom
              </Button>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box display="flex" flexDirection="column" alignItems="center" border="">
              <img alt="" src={UserLoginImg} width={308} />
              <Link
                className={classes.link}
                to={{
                  pathname: `/admin/seu-clube/relacionamento/cadastro-usuarios/${props.match.params.id}`,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.option1Button}
                  style={{ width: 300, marginTop: 10 }}
                >
                  Cadastrar cliente
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="center" mt={10}>
        <div className={classes.boxImage3}>
          <img alt="" src={SendMessageImg} width={350} />
          <Link
            className={classes.link}
            to={{
              pathname: `/admin/seu-clube/relacionamento/lista-comunicacoes/notify`,
            }}
          >
            <Button disabled variant="contained" color="primary" className={classes.option2Button}>
              Enviar comunicação
            </Button>
          </Link>
        </div>
      </Box>
    </Container>
  );
}

