import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 3rem 0; */
  /* gap: 3rem; */
  padding: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 30px;
`;

export const Input = styled.input`
  border-radius: 10px;
  border: 1px solid #757575;
  height: 42px;
  padding: 1rem;
`;

export const DragContainerList = styled.ul`
  list-style: none;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardItemInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h4 {
    /* font-weight: bold; */
    font-size: 14px;
    text-transform: capitalize;
  }
`;

export const DragItem = styled.li`
  width: 100%;
  padding: 1rem 1rem 2rem 2.5rem;
  background: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  height: 200px;

  img {
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 42px;
  gap: 0.5rem;
  z-index: 5;
`;

export const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${({ bgColor }) => bgColor};
`;
