const defaultState = {
    currentEst: {},
};

function reducer(state = defaultState, action) {
    switch (action.type) {
    case 'SET_ESTABLISHMENT': {
        let tempState = { ...state };

        tempState.currentEst = action.payload.currentEst;

        return tempState;
    }

    default:
        return state;
    }
}

export default reducer;