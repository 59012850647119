import React, { useState, useEffect } from 'react';

import { Cancel } from '@mui/icons-material';
import { Typography, IconButton, FormControl, Modal } from '@mui/material';

import { GetPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';

import RegisterUserModalForm from '../RelationshipClub/RegisterUserModalForm';
import { useStyles } from '../RelationshipClub/useStyles';

export function ModalSingleRegistration({
  isModalRegisterUserOpen,
  handleCloseModalRegisterUser,
  propsClube,
}) {
  //ALTERACOES DE MASCARA CELULAR
  const classes = useStyles();

  const [clubInfo, setClubInfo] = useState({});
  const [clubTokenField, setClubTokenField] = useState(null);
  const [clubForms, setClubForms] = useState({});

  const [formNotFound, setFormNotFound] = useState(false);

  useEffect(() => {
    if (propsClube.partnerId) {
      setClubInfo(propsClube);
      GetPartnerUserForms(propsClube.partnerId)
        .then((result) => {
          const tokenField = result.formFields.find((field) => field.indexKey === true);
          setClubTokenField([tokenField]);
          setClubForms(result);
        })
        .catch(() => {
          setFormNotFound(true);
        });
    }
  }, [propsClube]);

  return (
    <>
      <Modal
        open={isModalRegisterUserOpen}
        onClose={handleCloseModalRegisterUser}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FormControl className={classes.modalStyle}>
          <IconButton
            onClick={handleCloseModalRegisterUser}
            edge="end"
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              padding: '0',
              margin: '0.5em',
            }}
          >
            <Cancel />
          </IconButton>
          <Typography variant="h5" component="span" className={classes.titleModal}>
            Cadastro individual de permissão
          </Typography>

          <RegisterUserModalForm
            formFields={clubTokenField}
            clubForms={clubForms}
            clubInfo={clubInfo}
            formNotFound={formNotFound}
            modalClose={handleCloseModalRegisterUser}
          />

          {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              JustifyContent: 'center',
              paddingTop: '2rem',
              margin: '0 auto',
            }}>
            <small>
              * Usuários com número de celular inválido não serão cadastrados.
            </small>
          </div> */}
        </FormControl>
      </Modal>
    </>
  );
}
