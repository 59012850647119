import React, { useState, useRef } from 'react';
import * as S from './styles';

// ICONS AND COMPONENTS
import {
  CircularProgress,
  TextField,
  Button,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { preGenVouchers } from 'js/library/utils/API/Campaigns/apiPreGenVouchers';
import { blobToBase64, separateBase64String } from 'js/library/utils/helpers';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';

export default function VoucherCampaign(props) {
  // ID
  const campaignId = props.match.params.id;
  // DATA VOUCHER STATES
  const [dataVoucher, setDataVoucher] = useState({});
  const [batchName, setBatchName] = useState(props.match.params.id);
  const [prefix, setPrefix] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [forceConsumed, setForceConsumed] = useState(false);

  // LOADING
  const [loadingPage, setLoadingPage] = useState(false);

  // ERRORS
  const [errors, setErrors] = useState({});

  // FILE
  const [fileName, setFileName] = useState('Carregar');
  const fileInputRef = useRef(null);

  const validateForm = () => {
    const newErrors = {};
    if (!prefix) newErrors.prefix = 'Prefixo do Voucher é obrigatório';
    if (!expirationDate) newErrors.expirationDate = 'Data de expiração é obrigatória';
    if (!dataVoucher.fileThumbnail) newErrors.fileThumbnail = 'Arquivo .csv é obrigatório';
    return newErrors;
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];

      if (!file) throw new Error('Nenhum arquivo selecionado.');

      const validFileTypes = ['text/csv', 'text/plain'];
      if (!validFileTypes.includes(file.type)) {
        throw new Error('Tipo de arquivo inválido. Selecione um arquivo .csv ou .txt.');
      }

      setDataVoucher({ fileThumbnail: file, state: { thumbnail: file.name } });
      setFileName(file.name);
      setErrors((prevErrors) => ({ ...prevErrors, fileThumbnail: '' }));
    } catch (error) {
      toast.error(error.message || 'Erro ao selecionar o arquivo.');
    }
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    try {
      setLoadingPage(true);

      const base64 = await blobToBase64(dataVoucher.fileThumbnail);
      const newFile = separateBase64String(base64);

      const uploadedFileName = await uploadFiles('arquivosVouchers', {
        mimeType: newFile.mimeType,
        fileName: `${campaignId}-${prefix}.${newFile.mimeType === 'text/csv' ? 'csv' : 'txt'}`,
        buffer: newFile.buffer,
      });

      const splited = uploadedFileName.split('/');
      const indexOf = splited.indexOf('arquivosVouchers');
      const realFileName = splited.slice(indexOf + 1, indexOf + 2)[0].split('?')[0];

      await preGenVouchers({
        fileName: realFileName,
        batchName,
        forceConsumed,
        prefix,
        expirationDate: new Date(expirationDate + 'T12:00:00-03:00').getTime(),
        skip: [],
        origin: window.location.origin,
      });

      setTimeout(() => {
        toast.success('Vouchers gerados com sucesso!', {
          onClose: () => {
            window.history.back();
          },
        });
      }, 0.5 * 1000);
    } catch (error) {
      toast.error(error.message || 'Erro ao fazer upload do voucher.');
      setLoadingPage(false);
    }
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: '' }));
  };

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <S.Container>
      <GoBackHeader />
      <S.ContainerVoucher>
        <S.Title>Subir Voucher</S.Title>

        <TextField
          disabled
          label="Nome da Campanha"
          name="batchName"
          value={batchName}
          onChange={handleInputChange(setBatchName)}
          fullWidth
          margin="normal"
          error={!!errors.batchName}
          helperText={errors.batchName}
        />
        <TextField
          label="Prefixo do Voucher"
          name="prefix"
          value={prefix}
          onChange={handleInputChange(setPrefix)}
          fullWidth
          margin="normal"
          error={!!errors.prefix}
          helperText={errors.prefix}
        />
        <TextField
          label="Data de expiração"
          name="expirationDate"
          type="date"
          value={expirationDate}
          onChange={handleInputChange(setExpirationDate)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.expirationDate}
          helperText={errors.expirationDate}
        />

        <FormControlLabel
          control={
            <Switch checked={forceConsumed} onChange={() => setForceConsumed(!forceConsumed)} />
          }
          label="Forçar consumo"
        />

        <S.SubTitle>Carregar o arquivo .csv com os vouchers</S.SubTitle>
        <S.FileInstructions>
          <Typography variant="body1">
            O arquivo deve ser no formato <strong>.csv</strong> ou <strong>.txt</strong> e conter o
            número do voucher, id da promoção, id do template, número da campanha e token de
            segurança (opcional) separados por ponto e vírgula (<strong>;</strong>):
          </Typography>
          <Paper
            elevation={3}
            style={{ padding: '1rem', marginTop: '2rem', backgroundColor: '#f5f5f5' }}
          >
            <Typography variant="body2">
              <strong>Exemplo de conteúdo:</strong>
            </Typography>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {`A21A0804270;-Kbufy7bdqjE9IGB63z97;-L5z2Ep8syLl8Y7Loc9G;-campanhaEXemplo;COD0001
              A21A0804278;-Kbufy7bdqjE9IGB63z97;-L5z2Ep8syLl8Y7Loc9G;-campanhaEXemplo;COD0002
              A21A3001800;-Kbufy7bdqjE9IGB63z97;-L5z2Ep8syLl8Y7Loc9G;-campanhaEXemplo;COD0003
              ...`}
            </pre>
          </Paper>
        </S.FileInstructions>

        <input
          accept=".txt, .csv"
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current.click()}
          style={{ marginTop: '1rem' }}
        >
          {fileName}
        </Button>
        {errors.fileThumbnail && <S.ErrorMessage>{errors.fileThumbnail}</S.ErrorMessage>}
        <Button
          variant="contained"
          color="primary"
          onClick={async (e) => await handleSubmit(e)}
          style={{ marginTop: '1rem', backgroundColor: '#6E3296' }}
        >
          Enviar
        </Button>
      </S.ContainerVoucher>
    </S.Container>
  );
}
