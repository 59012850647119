import React from 'react';

import { MdHelpOutline } from 'react-icons/md';
import IconExit from './IconExit';
import ButtonExit from './ButtonExit';
import ButtonPurple from './ButtonPurple';
import ExitModal from './ExitModal';
import IconSwitch from './IconSwitch';

import * as S from './style';

const ExitMenu = (props) => {
  const {
    open,
    modalStatus,
    setModalStatus,
    superUser,
    isAdmin,
    setIsAdmin,
    switchText,
    setSwitchText,
  } = props;

  return (
    <>
      <ExitModal status={modalStatus} setStatus={setModalStatus} />
      <S.ExitContainer>
        {!superUser ? (
          <ButtonPurple
            icon={<MdHelpOutline />}
            open={open}
            to={{ pathname: '/admin/faq/', state: '' }}
          >
            Dúvidas
          </ButtonPurple>
        ) : (
          <ButtonPurple
            icon={<IconSwitch width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />}
            open={open}
            toggleButton={true}
            setIsAdmin={setIsAdmin}
            isAdmin={isAdmin}
            to={{ pathname: '', state: '' }}
            setSwitchText={setSwitchText}
          >
            {switchText}
          </ButtonPurple>
        )}
        <ButtonExit
          icon={<IconExit width={document.body.clientWidth < 600 ? '2rem' : '3rem'} />}
          open={open}
          setModalStatus={setModalStatus}
        >
          Sair
        </ButtonExit>
      </S.ExitContainer>
    </>
  );
};

export default ExitMenu;

