import React, { useState, useEffect, useRef } from 'react';

//lib OwlCarousel e estilos
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';

// estilos e assets
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  AppBar,
  Tab,
  Tabs,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import { Help } from '@mui/icons-material';

import useStyles from './styles';
import './font-picker.css';

//componentes
import TabText from './TabText';
import TabImage from './TabImage';
import TabQrcode from './TabQrcode';
import Templates from './Templates';
import { useTemplate } from '../Context/Marketing';
import { getTemplate, getEstablishment, getStore } from './MarketingServices';
import PreviewTemplate from './PreviewTemplate';
import { Header } from '../RelationshipScreen/Header';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function selectTab(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MarketingScreen = (props) => {
  const classes = useStyles();
  const {
    templateList,
    setTemplateList,
    setEstablishmentData,
    setSelectedTemplate,
    storeDate,
    setStoreData,
    selectedTemplate,
    setListElementSelected,
    listElementSelected,
    selectedFormat,
    setSelectedFormat,
    establishmentData,
    loadingElements,
    setLoadingElements,
    loadingPreview,
    setSelectedColorTemplate,
    selectedColorTemplate,
  } = useTemplate();

  const [valueTab, setValueTab] = useState(0);
  const [elements, setElements] = useState([]);
  const [templateListFiltered, setTemplateListFiltered] = useState([]);
  const [showTabs, setShowTabs] = useState(true);

  const refInterval = useRef(null);
  const history = useHistory();

  const establishmentId = props.match.params.id;

  // CONFIGURAÇÕES DO CARROSSEL
  const options = {
    margin: 3,
    nav: false,
    dots: false,
    mouseDrag: true,
    startPosition: 0,
    items: 2,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 2,
      },
      1250: {
        items: 2,
      },
    },
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    setLoadingElements(true);
    getTemplate(setTemplateList, setSelectedTemplate, setListElementSelected);
    getEstablishment(establishmentId, setEstablishmentData);
  }, []);

  // BAIXA TODAS AS PROMOÇÕES DO ESTABELECIMENTO
  useEffect(() => {
    if (establishmentData) {
      getStore(establishmentData, setStoreData, setLoadingElements);
    }
  }, [establishmentData]);

  useEffect(() => {
    // RESETA QUANDO SAIR DA PÁGINA
    return () => {
      setLoadingElements(false);
      setSelectedFormat('portrait');
      setSelectedTemplate({});
      setListElementSelected([]);
      setTemplateListFiltered([]);
      setStoreData([]);
      setSelectedColorTemplate(0);
    };
  }, []);

  useEffect(() => {
    setElements(selectedTemplate?.elements);
  }, [selectedTemplate]);

  function handleFormatSelected(event) {
    const { value } = event.target;

    setSelectedColorTemplate(0);

    setSelectedFormat(value);
  }

  useEffect(() => {
    if (templateList) {
      const objectToArray = Object.values(templateList);
      const templatesFiltered = objectToArray.filter(
        ({ format }) => format === selectedFormat
      );

      if (templatesFiltered.length === 0) {
        setTemplateListFiltered([]);
        return;
      }

      setTemplateListFiltered(templatesFiltered);
      setSelectedTemplate(templatesFiltered[0]);
      if (templatesFiltered[0]?.elements) {
        setListElementSelected([...templatesFiltered[0]?.elements]);
      }
    }
  }, [selectedFormat, templateList]);

  // ADICIONA TODOS OS ELEMENTOS AO PREVIEW FONT, COR, TEXT ETC...
  useEffect(() => {
    if (templateListFiltered.length > 0) {
      const elements = selectedTemplate.elements;
      const elementLength = elements.length - 1;

      let counter = 0;

      if (elementLength > 0) {
        setShowTabs(false);

        refInterval.current = setInterval(() => {
          counter += 1;

          setValueTab(counter);

          if (counter === elementLength) {
            setShowTabs(true);
            setValueTab(0);
            clearInterval(refInterval.current);
          }
        }, 10);
      } else {
        setValueTab(0);
      }
    }

    return () => {
      clearInterval(refInterval.current);
      setShowTabs(true);
    };
  }, [templateListFiltered, selectedTemplate, storeDate]);

  if (loadingElements && templateListFiltered.length === 0) {
    return (
      <div
        style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress style={{ margin: 'auto 0' }} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Header history={history} pageInfo={'Geração de Material'} />
      <Grid container className={classes.content}>
        <Grid item xs={12} lg={7} xl={6}>
          <FormControl
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
              marginTop: '20px',
            }}
            component='fieldset'>
            <FormLabel component='legend'>Formato</FormLabel>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              aria-label='format'
              name='format1'
              value={selectedFormat}
              onChange={handleFormatSelected}>
              <FormControlLabel
                disabled={loadingPreview}
                value='portrait'
                control={<Radio />}
                label='Retrato'
              />
              <FormControlLabel
                disabled={loadingPreview}
                value='landscape'
                control={<Radio />}
                label='Paisagem'
              />
              <FormControlLabel
                disabled={loadingPreview}
                value='square'
                control={<Radio />}
                label='Quadrado'
              />
            </RadioGroup>
          </FormControl>

          <Typography variant='subtitle2' style={{ fontWeight: '550' }}>
            Gabarito
          </Typography>

          <Box className={classes.boxTemplate}>
            {templateListFiltered.length === 0 ? (
              <Typography variant='subtitle2'>
                O formato selecionado ainda não possui templates.
              </Typography>
            ) : (
              <OwlCarousel options={options}>
                {templateListFiltered.map((template, index) => (
                  <Templates
                    key={`${template.name}-${index}`}
                    setValueTab={setValueTab}
                    {...{ classes, template, index }}
                  />
                ))}
              </OwlCarousel>
            )}
          </Box>
          {/* COR DO TEMPLATE */}

          {selectedTemplate.backgroundImages !== undefined && (
            <>
              <span className='label-picker-font-mkt'>Cor principal</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {selectedTemplate.backgroundImages.map((element, index) => (
                  <button
                    onClick={() => setSelectedColorTemplate(index)}
                    key={element.image}
                    type='button'
                    className='select-color-mkt'
                    style={{
                      backgroundColor: `${element.color}`,
                      border: `${
                        index === selectedColorTemplate
                          ? 'solid 2px #2bd7f0'
                          : ''
                      }`,
                    }}
                  />
                ))}
              </div>
            </>
          )}

          <Box className={classes.contentTab}>
            <AppBar position='static'>
              {showTabs && templateListFiltered.length > 0 && (
                <Tabs
                  value={valueTab}
                  onChange={handleChange}
                  aria-label='marketing tabs'
                  indicatorColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'>
                  {elements !== undefined &&
                    elements.map((element, index) => (
                      <Tab
                        key={index}
                        label={
                          element.entity === 'text'
                            ? `${index + 1}. Texto`
                            : element.entity === 'image'
                            ? `${index + 1}. Imagem`
                            : element.entity === 'qrCode'
                            ? `${index + 1}. Qrcode`
                            : 'Elemento'
                        }
                        {...selectTab(index)}
                      />
                    ))}
                </Tabs>
              )}
            </AppBar>
            {elements !== undefined &&
              templateListFiltered.length > 0 &&
              elements.map(
                (element, index) =>
                  valueTab === index && (
                    <div
                      key={index}
                      className={classes.tabPanelArea}
                      style={{ display: `${showTabs ? '' : 'none'}` }}>
                      {element.entity === 'text' && (
                        <TabText
                          elementIndex={index}
                          tabIndex={valueTab}
                          classes={classes}
                        />
                      )}
                      {element.entity === 'image' && (
                        <TabImage classes={classes} tabIndex={valueTab} />
                      )}
                      {element.entity === 'qrCode' && (
                        <TabQrcode
                          classes={classes}
                          tabIndex={valueTab}
                          establishmentId={establishmentId}
                        />
                      )}
                    </div>
                  )
              )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          xl={6}
          style={{ maxWidth: '550px', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='subtitle2' style={{ fontWeight: '550' }}>
              Visualização prévia
            </Typography>

            <Tooltip
              title={
                <span style={{ color: 'with', fontSize: '0.8rem' }}>
                  {selectedTemplate?.tutorial || ''}
                </span>
              }>
              <Help color='secondary' style={{ fontSize: '1.3rem' }} />
            </Tooltip>
          </div>
          {listElementSelected.length > 0 &&
            templateListFiltered.length > 0 && (
              <PreviewTemplate classes={classes} />
            )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketingScreen;
