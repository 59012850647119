import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  outline: none;
  max-width: 500px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 10px;
    border: 3px solid #f2f2f2;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem;
`;

export const CloseModalIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const ImportFileContainer = styled.div`
  padding: 27px 38px;
  border-radius: 10px;
  border: 1px dashed #707070;
  background: #f2f2f2;
  max-width: 260px;
  width: 100%;
  max-height: 183px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  cursor: ${(props) => (props.fileTxt === "" ? "pointer" : "")};

  .title {
    color: #757575;
    text-align: center;

    span {
      color: #328796;
      font-weight: 600;
    }
  }
`;

export const FileName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 5px 1rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  background: #fff;

  h5 {
    font-style: italic;
  }
`;

export const RemoveFileButton = styled.button`
  border: none;
  cursor: pointer;
  margin-top: 6px;

  h6 {
    color: #0372ff;
    font-style: italic;
    text-decoration: underline;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
