import styled from "styled-components";
import { Box } from "@mui/material";
import PromoBanner from "./../../../styles/assets/promoBanner.png";
import SimpleBanner from "./../../../styles/assets/simpleBanner.png";
import IconCheck from "./../../../styles/assets/iconCheck.svg";

const triiboPurple = "#6e3296";

const softBlack = "#383838";

const triiboCyan = "#08BAD0";

const triiboGreen = "#78AA1F";

const bannerTextBase = 1.6;

const bannerTextBaseMobile = 1;

export const Container = styled.div`
  /* max-width: 1313px; */
  margin: 0 auto;
  /* padding-top: 3rem; */
  /* padding-bottom: 5rem; */
  display: flex;
  flex-direction: column;
  gap: 3rem;

  padding: 3rem 1rem 4rem 1rem; //top right bottom left

  @media screen and (min-width: 600px) {
    padding: 3rem 3rem 4rem 3rem;
  }

  @media screen and (min-width: 900px) {
    padding: 3rem 5rem 4rem 5rem;
  }

  @media screen and (min-width: 1340px) {
    padding: 3rem 6rem 4rem 6rem;
  }

  @media screen and (min-width: 1600px) {
    padding: 3rem 10rem 4rem 10rem;
  }
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 79px;
  padding: 0 1rem;

  h1 {
    font-size: 50px;
    font-weight: 600;
    color: #383838;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    color: #6e3296;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  h3 {
    max-width: 643px;
    font-size: 25px;
    color: #383838;
    text-align: center;
    line-height: 37px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
      line-height: 20px;
      max-width: 450px;
    }

    @media screen and (max-width: 590px) {
      font-size: 15px;
      line-height: 20px;
      max-width: 350px;
    }
  }
`;

export const ArrowButton = styled.button`
  visibility: hidden;

  ${({ displayArrow }) =>
    displayArrow &&
    `
      visibility: visible;
    `}
`;

export const Carousel = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: #6e3296;
  width: 100%;
  height: auto;
  padding: 3rem;
  border-radius: 10px;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 1rem;
  }

  h3 {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    line-height: 37px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }

  .sliderBox {
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      max-width: 750px;
    }

    @media screen and (max-width: 880px) {
      max-width: 680px;
    }

    @media screen and (max-width: 600px) {
      max-width: 550px;
    }
  }

  .sliderCustom {
    width: 80%;
  }

  /* .slick-slide {
    max-width: 170px !important;
    width: 100%;
  }  */

  .slick-slide > div {
    margin: 0 45px;

    @media screen and (max-width: 1300px) {
      margin: 0 20px;
    }

    @media screen and (max-width: 1024px) {
      margin: 0 15px;
    }

    @media screen and (max-width: 880px) {
      margin: 0 10px;
    }

    @media screen and (max-width: 600px) {
      margin: 0 10px;
    }
  }

  .slick-list {
    margin: 0 -45px;

    @media screen and (max-width: 1300px) {
      margin: 0 -20px;
    }

    @media screen and (max-width: 1024px) {
      margin: 0 -15px;
    }

    @media screen and (max-width: 880px) {
      margin: 0 -10px;
    }

    @media screen and (max-width: 600px) {
      margin: 0 -10px;
    }
  }

  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .prevArrow,
  .nextArrow {
    z-index: 1000;
    background: transparent;
    border: none;
    cursor: pointer;

    .icon {
      font-size: 50px;
      color: #fff;

      @media screen and (max-width: 800px) {
        font-size: 40px;
      }

      @media screen and (max-width: 600px) {
        font-size: 30px;
      }
    }
  }

  /* .prevArrow {
    margin-right: 30px;

    @media screen and (max-width: 800px) {
      margin-right: 15px;
    }

    @media screen and (max-width: 600px) {
      margin-right: 8px;
    }
  }

  .nextArrow {
    margin-left: 30px;

    @media screen and (max-width: 800px) {
      margin-left: 15px;
    }

    @media screen and (max-width: 600px) {
      margin-left: 8px;
    }
  } */
`;

export const CardCarousel = styled.div`
  max-width: 170px;
  background: #fff;
  padding: 7px;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto;
  flex: none;
  /* max-height: 120px; */

  @media screen and (max-width: 880px) {
    padding: 5px;
    max-width: 120px;
    max-height: 150px;
  }

  @media screen and (max-width: 600px) {
    padding: 3px;
    max-width: 110px;
    max-height: 120px;
  }

  .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
  }

  .imageBox > img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .addBusiness {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .addBusiness,
  .imageBox > img {
    height: 165px;

    @media screen and (max-width: 1024px) {
      height: 120px;
    }

    @media screen and (max-width: 880px) {
      height: 100px;
    }

    @media screen and (max-width: 600px) {
      height: 80px;
    }
  }

  .addBusiness img {
    width: 45%;
  }

  h5 {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #383838;
    font-size: 19px;
    font-weight: 600;
    text-align: center;
    padding: 10px 0;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      padding: 10px 0;
    }

    @media screen and (max-width: 880px) {
      font-size: 14px;
      padding: 8px 0;
    }

    @media screen and (max-width: 600px) {
      font-size: 8px;
      padding: 5px 0;
    }
  }
`;

export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.6rem;
  width: 100%;
  padding: auto;

  @media screen and (min-width: 600px) {
    gap: 5rem;
    padding: 2.4rem;
  }
`;

export const PlanBanner = styled.a`
  background-color: transparent;
  background-image: url(${PromoBanner});
  background-size: cover;
  border-radius: 1rem;
  width: 100%;
  height: 16rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    width: 55%;
    height: 26rem;
  }
`;

export const IllustrationBanner = styled.div`
  background-color: transparent;
  background-image: url(${SimpleBanner});
  background-size: cover;
  border-radius: 1rem;
  width: 100%;
  height: 26rem;
  display: none;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    width: 55%;
    display: block;
  }
`;

export const BannerText = styled.span`
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  font-weight: 700;
  margin-left: 1rem;
  position: relative;

  @media screen and (min-width: 600px) {
    margin-left: 4rem;
  }
`;

// const greenWidth = 1.2;
// const greenHeight = 0.4;

export const TextGreen = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  right: 0.6rem;
  background-color: ${triiboGreen};
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;

  @media screen and (min-width: 600px) {
    right: 1.2rem;
    padding: 0.8rem 1.8rem;
    border-radius: 0.5rem;
  }
`;

export const TextPlan = styled.span`
  font-size: ${bannerTextBaseMobile * 1.2}rem;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    font-size: ${bannerTextBase * 1.2}rem;
  }
`;

export const TextBasic = styled.span`
  font-size: ${bannerTextBaseMobile * 2.5}rem;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    font-size: ${bannerTextBase * 2.5}rem;
  }
`;

export const TextFor = styled.span`
  font-size: ${bannerTextBaseMobile * 0.9}rem;
  position: relative;
  top: 0.4rem;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    font-size: ${bannerTextBase}rem;
    top: 0.8rem;
  }
`;

export const TextPrice = styled.span`
  font-size: ${bannerTextBaseMobile * 2}rem;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    font-size: ${bannerTextBase * 2}rem;
  }
`;

export const TextPriceRow = styled.span`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 0.6rem;
`;

export const TextMonth = styled.span`
  font-size: ${bannerTextBaseMobile * 0.9}rem;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    font-size: ${bannerTextBase}rem;
  }
`;

export const GetPlan = styled.span`
  background-color: transparent;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 1.4rem;
  color: ${softBlack};
  width: 45%;
  padding: 3.4rem;

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    display: flex;
  }
`;

export const GetPlanList = styled.ul`
  list-style-image: url(${IconCheck});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 2rem;
`;

export const GetPlanEntry = styled.li`
  /* list-style-type: "✔  "; */
  list-style-image: url(${IconCheck});
  list-style-position: outside;
`;

export const PlanButton = styled.a`
  background-color: ${triiboGreen};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  min-height: 2.5rem;
  border-radius: 6px;
  padding: 0.8rem 0.8rem;
  margin-top: 1rem;
`;

export const WeeklyTip = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  /* min-height: 20rem; */

  @media screen and (min-width: 800px) {
    /* @media screen and (min-width: 600px) { */
    width: 45%;
  }
`;

export const PurpleText = styled.span`
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  color: ${triiboPurple};

  @media screen and (min-width: 600px) {
    font-size: 3rem;
  }
`;

export const TipPara = styled.span`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: ${softBlack};
  /* min-height: 10rem; */

  @media screen and (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

export const TipButton = styled.a`
  background-color: ${triiboCyan};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  min-height: 2.5rem;
  border-radius: 6px;
  padding: 0.4rem 0.6rem;
  margin-top: 1rem;
`;

export const BannerRows = styled.div`
  /* background-color: green; */
  font-family: "Source Sans Pro";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  /* min-height: 10rem; */
  width: 100%;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

// export const BoxMyBusiness = styled(Box)`
//   max-width: 452px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 2rem;
//   padding: 0 1rem;
// `;

// export const TitleMyBusiness = styled(Box)`
//   text-align: center;

//   h1 {
//     font-weight: 600;
//     font-family: Source Sans Pro;
//     color: #383838;
//   }

//   h3 {
//     font-weight: 600;
//     font-family: Source Sans Pro;
//     color: #383838;
//     margin-bottom: 2rem;
//   }

//   h5 {
//     text-align: center;
//     font-weight: normal;
//     font-family: Source Sans Pro;
//     color: #383838;

//     span {
//       font-weight: 600;
//       font-family: Source Sans Pro;
//     }
//   }
// `;

// export const ButtonMyBusiness = styled(Box)`
//   width: 100%;

//   button {
//     width: 100%;
//     font-weight: normal;
//     font-family: Source Sans Pro;
//     text-transform: capitalize;
//     color: #ffffff;
//     padding: 0.8rem 0;
//     background-color: #08bad0;

//     &:hover {
//       background-color: #08bad0;

//       filter: brightness(0.9);
//     }
//   }
// `;
