import React, { useState } from 'react';

import * as S from '../styles';
import * as D from 'js/components/DesignSystem/styles';
import { FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material';
import PencilIcon from '../../../../../styles/assets/SearchUser/pencil.svg';
import { toast } from 'react-toastify';
import { findValueInArray, maskCEP, maskCPF2 } from 'js/library/utils/helpers';

import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import { cloneDeep } from 'lodash';
import SetUserMapfre from 'js/library/utils/API/SearchUser/apiSetUserMapfre';

export default function EditInfosMapfre({
  userUid,
  handleCloseModal,
  isModalEditInfosMapfre,
  partnerList,
}) {
  const [fieldsValues, setFieldsValues] = useState({
    fullName: partnerList.fullName || '',
    cpf: findValueInArray(partnerList.documentList, 'type', 'cpf').value || '',
    cellPhone: findValueInArray(partnerList.contactList, 'type', 'cellPhone').value || '',
    aliasName: partnerList.aliasName || '',
    birthDate: new Date(partnerList.birthDate).toLocaleDateString('pt-BR') || '',
    email: findValueInArray(partnerList.contactList, 'type', 'email')
      ? findValueInArray(partnerList.contactList, 'type', 'email').value
      : '',
    gender: partnerList.gender || '',
    addressList: {
      streetAve: partnerList.addressList ? partnerList.addressList[0].streetAve ?? '' : '',
      neighborhood: partnerList.addressList ? partnerList.addressList[0].neighborhood ?? '' : '',
      city: partnerList.addressList ? partnerList.addressList[0].city ?? '' : '',
      state: partnerList.addressList ? partnerList.addressList[0].state ?? '' : '',
      zipCode: partnerList.addressList ? partnerList.addressList[0].zipCode ?? '' : '',
      number: partnerList.addressList ? partnerList.addressList[0].number ?? '' : '',
      extra: partnerList.addressList ? partnerList.addressList[0].extra ?? '' : '',
    },
  });

  const handleSubmit = () => {
    if (
      !fieldsValues.fullName ||
      !fieldsValues.aliasName ||
      !fieldsValues.cellPhone ||
      !fieldsValues.email ||
      !fieldsValues.cpf
    ) {
      return toast.error('Preencha todos os dados obrigatórios');
    }

    let userInfo = {
      ...partnerList,
      fullName: fieldsValues.fullName,
      aliasName: fieldsValues.aliasName,
      contactList: [
        {
          type: 'cellPhone',
          value: fieldsValues.cellPhone,
        },
        {
          type: 'email',
          value: fieldsValues.email,
        },
      ],
      documentList: [
        {
          type: 'cpf',
          value: fieldsValues.cpf,
        },
      ],
    };

    Object.keys(fieldsValues.addressList).forEach((key) => {
      if (fieldsValues.addressList[key]) {
        userInfo.addressList = [
          userInfo.addressList
            ? {
                ...userInfo.addressList[0],
                [key]: fieldsValues.addressList[key],
              }
            : {
                [key]: fieldsValues.addressList[key],
              },
        ];
      }
    });

    userInfo.addressList[0].longitude = 0;
    userInfo.addressList[0].latitude = 0;
    userInfo.addressList[0].googleAddr = `${userInfo.addressList[0].streetAve} - ${userInfo.addressList[0].neighborhood}, ${userInfo.addressList[0].city} - ${userInfo.addressList[0].state}, ${userInfo.addressList[0].zipCode}, Brasil`;

    if (fieldsValues.gender) {
      userInfo.gender = fieldsValues.gender;
    }

    if (fieldsValues.birthDate) {
      userInfo.birthDate = new Date(fieldsValues.birthDate).getTime() + 1000 * 60 * 60 * 3;
    }

    delete userInfo.status;

    SetUserMapfre(userInfo, userUid)
      .then((res) => {
        toast.success('Alterado com sucesso');
        handleCloseModal('closeModalEditInfosMapfre');
      })
      .catch((err) => {
        toast.error('Erro ao alterar as informações do usuário.');
      });
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalEditInfosMapfre')}
      open={isModalEditInfosMapfre}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <S.ModalBox
          style={{
            msOverflowY: 'scroll',
            overflowY: 'scroll',
            maxHeight: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="header">
            <img src={PencilIcon} alt="" />
            <h2>Editar Informações Mapfre</h2>
          </div>
          <S.EditInfoUserModalBox>
            <div>
              <D.TriiboH4>Nome completo</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.fullName}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    fullName: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>CPF</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={maskCPF2(fieldsValues.cpf)}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    cpf: e.target.value.replace(/\D/g, ''),
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Apelido</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.aliasName}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    aliasName: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Data de nascimento</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.birthDate}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    birthDate: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Email</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.email}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Gênero</D.TriiboH4>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <FormControlLabel
                  checked={fieldsValues.gender === '0'}
                  onClick={(e) => setFieldsValues({ ...fieldsValues, gender: e.target.value })}
                  value="0"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6E3296',
                        },
                      }}
                    />
                  }
                  label="Masculino"
                />
                <FormControlLabel
                  checked={fieldsValues.gender === '1'}
                  onClick={(e) => setFieldsValues({ ...fieldsValues, gender: e.target.value })}
                  value="1"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6E3296',
                        },
                      }}
                    />
                  }
                  label="Feminino"
                />
                <FormControlLabel
                  checked={fieldsValues.gender === '2'}
                  onClick={(e) => setFieldsValues({ ...fieldsValues, gender: e.target.value })}
                  value="2"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6E3296',
                        },
                      }}
                    />
                  }
                  label="Não informar"
                />
              </RadioGroup>
            </div>
            <div>
              <D.TriiboH4>Rua</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.streetAve}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.streetAve = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Bairro</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.neighborhood}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.neighborhood = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Cidade</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.city}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.city = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Estado</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.state}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.state = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>CEP</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={maskCEP(fieldsValues.addressList.zipCode)}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.zipCode = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Número</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.number}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.number = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Complemento</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.extra}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.extra = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
          </S.EditInfoUserModalBox>

          <PrimaryButton style={{ margin: '1rem auto' }} onClick={handleSubmit}>
            Salvar
          </PrimaryButton>
        </S.ModalBox>
      </>
    </Modal>
  );
}
