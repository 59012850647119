import React, { useState, useEffect } from 'react';
import { GoBackHeader } from '../../RelationshipClub/GoBackHeader';

//Styles
import {
  CircularProgress,
  Container,
  Pagination,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import ReactSwitch from 'react-switch';
import { FaTrash } from 'react-icons/fa';
import {
  DeleteButtonContainer,
  DeleteUserButton,
  LoadingContainer,
  TableHeaderMui5,
  TbContainer,
  TbContent,
} from './EditWhitelistStyle';

//Components
import { HeaderTitle } from '../../RelationshipClub/HeaderTitle';
import usePagination from 'js/components/Establishment/RelationshipScreen/usePagination';

//Libs
import { toast } from 'react-toastify';

//Apis
import {
  deleteUserPartnerWhitelist,
  getUsersPartnerWhitelist,
  inactiveUserPartnerWhitelist,
} from 'js/library/utils/API/usersPartnerWhitelist';

export function EditWhitelist({}) {
  const partnerId = localStorage.getItem('partnerIdClube');
  const [whitelist, setWhitelist] = useState([]);
  const [loadingWhiteList, setLoadingWhiteList] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState({});
  const [loadingTrash, setLoadingTrash] = useState({});
  const PER_PAGE = 20;

  const count = Math.ceil(whitelist.length / PER_PAGE);
  const _DATA = usePagination(whitelist, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    setLoadingWhiteList(true);
    getUsersPartnerWhitelist(partnerId)
      .then((users) => {
        setWhitelist(users.response);
      })
      .catch((e) => {
        console.error('Ocorreu um erro na requisição da getUsersPartnerWhitelist: ', e);
        setLoadingWhiteList(false);
      })
      .finally(() => {
        setLoadingWhiteList(false);
      });
  }, [partnerId]);

  const handleChangeStatusUser = async (userPartnerId, status) => {
    setLoading((prevState) => ({
      ...prevState,
      [userPartnerId]: true, // Define o estado de carregamento como true para o usuário específico
    }));

    const updateInfo = {
      isEnabled: status,
    };
    const resultInactiveUser = await inactiveUserPartnerWhitelist(
      partnerId,
      userPartnerId,
      updateInfo
    );

    if (resultInactiveUser.data.response) {
      const rerrender = await getUsersPartnerWhitelist(partnerId);
      setLoading(false);
      setWhitelist(rerrender.response);
    }
  };

  const deleteUserWhitelist = async (userPartnerId) => {
    setLoadingTrash((prevState) => ({
      ...prevState,
      [userPartnerId]: true, // Define o estado de carregamento como true para o usuário específico
    }));

    const resultDeleteUser = await deleteUserPartnerWhitelist(partnerId, userPartnerId);

    if (resultDeleteUser.data.response) {
      const rerrender = await getUsersPartnerWhitelist(partnerId);
      setLoadingTrash(false);
      setWhitelist(rerrender.response);
      toast.success('Usuário deletado com sucesso.');
    }
  };

  return (
    <Container style={{ padding: '4rem 32px' }} maxWidth={false}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Gerenciar Whitelist" />
      {whitelist.length !== 0 ? (
        <TbContainer>
          <TbContent size="medium">
            <TableHeaderMui5>
              <TableRow>
                <TableCell align="left">
                  <strong>Chave de acesso</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Status</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Excluir</strong>
                </TableCell>
              </TableRow>
            </TableHeaderMui5>
            <TableBody>
              {_DATA.currentData().map((user) =>
                user.token === '' ? null : (
                  <TableRow
                    key={user.token}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align="left" style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                      {user.token}
                    </TableCell>
                    <TableCell align="left">
                      {loading[user.token] ? (
                        <CircularProgress style={{ color: '#6e3296' }} size="2rem" />
                      ) : (
                        <ReactSwitch
                          onColor="#6e3296"
                          checked={user.isEnabled}
                          onChange={() => {
                            handleChangeStatusUser(user.token, !user.isEnabled);
                          }}
                        />
                      )}
                    </TableCell>
                    {
                      <TableCell align="right">
                        {loadingTrash[user.token] ? (
                          <CircularProgress style={{ color: '#FF0000' }} size="2rem" />
                        ) : (
                          <DeleteButtonContainer>
                            <DeleteUserButton onClick={() => deleteUserWhitelist(user.token)}>
                              <FaTrash style={{ color: '#fff', fontSize: '0.9rem' }} />
                            </DeleteUserButton>
                          </DeleteButtonContainer>
                        )}
                      </TableCell>
                    }
                  </TableRow>
                )
              )}
            </TableBody>
          </TbContent>
        </TbContainer>
      ) : (
        loadingWhiteList && (
          <LoadingContainer>
            <CircularProgress size="100px" />
          </LoadingContainer>
        )
      )}
      <Pagination
        sx={{ mt: 3 }}
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChangePage}
      />
    </Container>
  );
}
