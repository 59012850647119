export default function reducer(state = {
  queryId: null,
  name: null,
  query: null,
  createdDate: null,
  creatorUId: null,
  status: null,
}, action) {

  switch (action.type) {

    case "BATCH_ACTIONS_PENDING":
      {
        return {
          ...state,
          queryId: null,
          name: null,
          query: null,
          createdDate: null,
          creatorUId: null,
          status: null,
        };
      }

    case "BATCH_ACTIONS_FULFILLED":
      {
        return {
          ...state,
          queryId: action.payload.queryId,
          name: action.payload.name,
          query: action.payload.query,
          createdDate: action.payload.createdDate,
          creatorUId: action.payload.creatorUId,
          status: action.payload.status,
        };
      }

    case "BATCH_ACTIONS_REJECTED":
      {
        return {
          ...state,
          queryId: null,
          name: null,
          query: null,
          createdDate: null,
          creatorUId: null,
          status: null,
        };
      }

    default:
      return state;
  }

}