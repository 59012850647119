import axios from 'axios';

import { decrypt, encrypt } from '../helpers';
import cfac22 from '../cfac22';

export default function getSecurityCode() {
  return new Promise(async (resolve, reject) => {
    const auth = window.btoa(cfac22('USERNAME') + ':' + cfac22('PASSWORD'));

    const config = {
      method: 'get',
      url: cfac22('API_HOST_V2') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
      },
    };
    axios(config)
      .then(function (sc) {
        let decry = JSON.parse(decrypt(sc.data.securityCode, cfac22('SC_KEY')));
        let result = { code: decry.code * 3 };
        result = encrypt(JSON.stringify(result), cfac22('SC_KEY'));
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
