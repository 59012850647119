import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Grid } from '@mui/material';
import DataTableStoreApproved from 'js/containers/DataTable/DataTableStoreApproved';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const StoreApprovedList = () => {
  const [reloadFlag, setReloadFlag] = useState(true);

  useEffect(() => {
    // Resetar o reloadFlag após a montagem do componente
    setReloadFlag(false);
  }, []);

  useEffect(() => {}, [reloadFlag]);

  return (
    <Container>
      <GoBackHeader />
      <HeaderTitle pageInfo="Curadoria de Promoções" />
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <div className="position-btn2">
            <Link
              to={{ pathname: '/admin/aprovar/promocoes', state: '' }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '31px',
                  fontWeight: '550',
                  textDecoration: 'none',
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                Ver promoções para avaliar
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
      <DataTableStoreApproved />
    </Container>
  );
};

export default StoreApprovedList;
