import React from 'react';
import * as S from './style';
import loginWallpaper from './login_wallpaper.png';
import loginWallpaperMobile from './login_wallpaper_mobile.6e04492c.webp';
import Logo from '../../../styles/assets/Artboard_2_copia.svg';
import { Box, Snackbar} from '@mui/material'

const templateModalLogin = ({
  children,
  handleSubmit,
  messageError,
  setMessageError,
  width,
  gap,
}) => {
  return (
    <S.Wallpaper img={loginWallpaper} imgMobile={loginWallpaperMobile}>
      <Box
        component={'form'}
        autoComplete='off'
        sx={{
          width: { xs: 313, sm: !width ? 666 : width },
          backgroundColor: 'white',
          borderRadius: '14px',
          boxShadow: '0px 3px 6px #00000029',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: gap || '0',
          padding: { xs: '35px 20px 20px 35px', md: '60px 45px 45px 45px' },
        }}
        onSubmit={(e) => handleSubmit(e)}>
        <Box
          sx={{
            width: { xs: '120px', sm: '155px' },
          }}
          component='img'
          alt='logo triibo'
          src={Logo}
        />

        {children}
      </Box>

      {messageError && setMessageError && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={messageError !== ''}
          onClose={() => setMessageError('')}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message-id'>{messageError}</span>}
        />
      )}
    </S.Wallpaper>
  );
};

export default templateModalLogin;
