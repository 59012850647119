import axios from 'axios';
import authToken from '../authToken';

import cfac22 from '../../cfac22';

export function DeleteUserPartner(uid, partnerId) {
  return new Promise((resolve, reject) => {
    authToken(uid).then((token) => {
      const config = {
        method: 'delete',
        url: cfac22('API_HOST_V2') + '/userPartner',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: {
          uid: uid,
          partnerId: partnerId,
        },
      };
      axios(config)
        .then(async (result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          console.log(error);
          return reject(error.response);
        });
    });
  });
}
