import styled from "styled-components/macro";

import * as D from "js/components/DesignSystem/styles";

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0;

  @media (min-width: 900px) {
    padding: 0 3rem;
  }
`;

export const TextContainer = styled.span`
  font-family: "Source Sans Pro", Semibold;
  text-transform: capitalize;
  color: ${D.triiboLightBlue};
`;

export const GoBackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.1rem;
  color: ${D.triiboLightBlue};
  background: transparent;
  border: 0;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 600;

  cursor: pointer;
`;
