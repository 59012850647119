import React from "react";

import * as D from "js/components/DesignSystem/styles";

import CropperTool from "./CropperTool";

const CropperModal = (props) => {
  const { open, setOpen, img, setImg } = props;

  return (
    <D.ModalContainer show={open}>
      <D.ModalBody>
        <D.ModalLabelContainer>
          <D.ModalTitle>Editar imagem</D.ModalTitle>
          <D.ModalSubtitle>
            Uma foto de qualidade ajuda as pessoas a reconhecerem o seu negócio
          </D.ModalSubtitle>
        </D.ModalLabelContainer>
        <CropperTool setOpen={setOpen} img={img} setImg={setImg} />
        
      </D.ModalBody>
      <D.ModalBackground
        onClick={(e) => {
          e.preventDefault();
          setImg("");
          setOpen(false);
        }}
      />
    </D.ModalContainer>
  );
};

export default CropperModal;
