import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import updateTrialActionsAction from 'js/core/actions/trialActions';
import RequisitionTrial from 'js/library/services/TrialRequisition';

import exampleExcelImg from 'styles/assets/estabelecimentos/listClientsExample.png';
import readXlsxFile from 'read-excel-file';
import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';
import usePagination from './usePagination';

import { toast } from 'react-toastify';

import {
  Typography,
  Button,
  Card,
  Grid,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
  Pagination,
} from '@mui/material';

import getEstablishmentId from '../../../library/utils/API/Establishment/apiReadEstablishment';

import { useStyles } from './useStyles';
import { Container } from './styles';
import { getUserInfo } from 'js/library/utils/helpers';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';
import { Redirect } from 'react-router';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';

export default function RegisterListUsers({ history, ...props }) {
  const dispatch = useDispatch();

  const [clientList, setClientList] = useState([]);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [establishmentName, setEstablishmentName] = useState('');
  const [establishmentId, setEstablishmentId] = useState('');
  const [page, setPage] = useState(1);
  const [modalStepIsOpen, setModalStepIsOpen] = useState(false);
  let state = useSelector((state) => state.authModel);
  let establishmentList = useSelector((state) => state.authModel.establishmentList);
  let currentEst = useSelector((state) => state.currentEstModel.currentEst);

  const fileUploader = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    let establishmentId = '';

    if (!establishmentList) {
      return;
    } else {
      if (Object.keys(currentEst).length !== 0) {
        setEstablishmentId(currentEst.id);
        establishmentId = currentEst.id;
      } else {
        setEstablishmentId(establishmentList[0].id);
        establishmentId = establishmentList[0].id;
      }
    }
    const getNameEstablishment = async () => {
      return getEstablishmentId(establishmentId).then((result) => {
        setEstablishmentName(result.nome);
      });
    };
    getNameEstablishment();
  }, []);

  const handleClick = (event) => {
    const { name, checked } = event.target;
    const optInList = [
      {
        accept: true,
        dateAcceptance: Date.now(),
        optInId: '-Kzj_F6wDcfHkLMO_q4c',
        type: 'Termos de Uso da Triibo',
        version: 1,
      },
      {
        accept: true,
        dateAcceptance: Date.now(),
        optInId: '-KzjMOHvzce-PUYpoWHU',
        type: 'Optin - Termos de consentimento de comunicações opcionais',
        version: 1,
      },
    ];

    if (name === 'allSelect') {
      let selected = clientList.map((user) => {
        return {
          ...user,
          optInList,
          isChecked: checked,
        };
      });
      setClientList(selected);
    } else {
      let selected = clientList.map((user) =>
        user.name === name
          ? {
              ...user,
              optInList,
              isChecked: checked,
            }
          : user
      );
      setClientList(selected);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const fileExt = file.name.split('.').find((item) => item === 'xlsx');

    if (fileExt === 'xlsx' && fileExt !== undefined) {
      const fileXlsx = await readXlsxFile(event.target.files[0]);

      fileXlsx.shift(); // Exclui o header do array (nome, telefone, email, data de nascimento, cpf)

      const userInfo = fileXlsx.map((row) => {
        let obj = {};

        if (row[0] !== undefined) {
          obj.name = row[0];
        }

        if (row[1] !== undefined) {
          obj.cellphone = row[1];
        }

        if (row[2] !== undefined) {
          obj.email = row[2];
        }

        if (row[3] !== undefined) {
          if (typeof row[3] === 'string') {
            const day = row[3].split('/')[0];
            const month = row[3].split('/')[1];
            const year = row[3].split('/')[2];
            const concat = year + '-' + month + '-' + day;

            obj.birthDate = row[3] ? Date.parse(concat) + 10800000 : null;
          } else {
            obj.birthDate = row[3] ? Date.parse(row[3]) + 10800000 : null;
          }
        }

        if (row[4] !== undefined && row[4] !== null) {
          obj.cpf = String(row[4]).replace(/\D/g, '');
        }

        return obj;
      });

      const filteredUserInfo = userInfo.filter((row) => row.cellphone !== null);
      // Filter para que venha apenas os usuários que possuem um número de telefone cadastrado.

      if (filteredUserInfo.length === 0) {
        return toast.error('O número de telefone é obrigatório!');
      }

      const validateUserData = filteredUserInfo.map((row) => {
        return {
          ...row,
          cellphone: row.cellphone,
          establishmentName,
          establishmentId,
        };
      });

      const copyValidateUserData = validateUserData;

      validateUserData.map((client, index) => {
        return Object.keys(client).map((item) => {
          if (validateUserData[index][item] === null) {
            delete copyValidateUserData[index][item];
          }
        });
      });

      setClientList(copyValidateUserData);
    } else {
      toast.error('Arquivo não é no formato .xlsx');
    }
  };

  const PER_PAGE = 10;

  const count = Math.ceil(clientList.length / PER_PAGE);
  const _DATA = usePagination(clientList, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChangeLoading = (value) => {
    setLoadingRegister(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingRegister(true);

    const clientListValid = clientList.filter((e) => {
      return e.cellphone.toString().includes('+');
    });

    if (clientListValid.length < 1) {
      handleChangeLoading(false);

      return toast.error('Nenhum cliente possuí número válido');
    }

    try {
      RegisterUsersBatch_v2(clientListValid).then(() => {
        const uid = getUserInfo().uId;
        getTrialStatus(uid).then((result) => {
          updateTrialStatus({
            establishmentStep: result.establishmentStep,
            storeStep: result.storeStep,
            relationshipStep: true,
            congrats: result.congrats,
          }).then((result) => {
            handleChangeLoading(false);
            if (
              window.pathname !== null &&
              JSON.parse(JSON.stringify(window.sessionStorage)).thirdStep === 'incomplete'
            ) {
              window.sessionStorage.setItem('thirdStep', 'complete');
              setModalStepIsOpen(true);
            }
          });
        });

        toast.success(
          'Usuários cadastrados com sucesso! Em breve você poderá consultá-los na aba "Ver meus clientes".'
        );
        setClientList([]);
      });
    } catch (error) {
      handleChangeLoading(false);
      toast.error('Ops, usuários não foram cadastrados.');
    }

    RequisitionTrial().then((result) => {
      dispatch(updateTrialActionsAction(result));
    });
  };

  if (modalStepIsOpen === true) {
    return (
      <Redirect
        to={{
          pathname: `/estabelecimento/${state.establishmentList[0].id}/thirdStep`,
          state: state.establishmentList[0],
        }}
      />
    );
  } else {
    return (
      <div style={{ padding: '4rem 32px' }}>
        <GoBackHeader />
        <Container>
          <HeaderTitle pageInfo="Cadastrar lista de clientes" />
          <Grid item xs={12} className={classes.boxInportFiles}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                  Carregue um arquivo no formato Excel com o seguinte padrão:
                  <br />
                  <strong>"Nome | Telefone | E-mail | Data de Nascimento | CPF".</strong>
                </Typography>{' '}
                <br />
                <Typography variant="subtitle1" style={{ fontWeight: 600, marginTop: '22px' }}>
                  <img
                    src={exampleExcelImg}
                    alt="Exemplo de envio"
                    style={{
                      maxWidth: '400px',
                      width: '100%',
                      marginBottom: '22px',
                    }}
                  />
                  <div>
                    Obs 1: Os dados "Nome e Telefone" são obrigatórios para realizar o cadastro do
                    cliente.
                  </div>
                  <div>
                    Obs 2: O número de telefone tem que ter o seguinte formato: código do pais +
                    (DDD) + telefone com 9 dígitos - exemplo: +5511970697706, caso contrário o mesmo
                    não será cadastrado.
                  </div>
                </Typography>{' '}
                <br />
                <div>
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => fileUploader.current.click()}
                  >
                    Carregar arquivo
                  </Button>
                  <input
                    type="file"
                    accept=".xlsx"
                    ref={fileUploader}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.boxListUsers}>
            {clientList.length > 0 && (
              <>
                <strong>Usuários Listados: </strong>
                {clientList.length}
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <strong>Nome</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>Telefone</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>E-mail</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>Data de nascimento</strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>CPF</strong>
                        </TableCell>
                        <TableCell align="center">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleClick}
                                  name="allSelect"
                                  color="primary"
                                  size="small"
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  <strong>Selecionar Todos</strong>
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_DATA.currentData().map((client, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{client.name}</TableCell>
                          <TableCell
                            style={{
                              backgroundColor: `${
                                !client.cellphone.toString().includes('+') ? 'red' : '#ffff'
                              }`,
                            }}
                            align="center"
                          >
                            {client.cellphone}
                          </TableCell>
                          <TableCell align="center">{client.email}</TableCell>
                          <TableCell align="center">
                            {client?.birthDate &&
                              new Intl.DateTimeFormat('pt-BR').format(client.birthDate)}
                          </TableCell>
                          <TableCell align="center">{client.cpf}</TableCell>
                          <TableCell width={220}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    key={index}
                                    name={client.name}
                                    onChange={handleClick}
                                    checked={client?.isChecked || false}
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label={
                                  <Typography variant="caption">
                                    Aceitar Comunicação e Marketing
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Pagination
                  sx={{ mt: 3 }}
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />

                <div align="center" style={{ marginTop: 15 }}>
                  <Button
                    style={{
                      fontWeight: '550',
                      color: loadingRegister ? '#383838' : '#fff',
                      textTransform: 'capitalize',
                      marginBottom: '2rem',
                      background: loadingRegister && '#dbdbdb',
                    }}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                    disabled={!establishmentId || loadingRegister}
                  >
                    {loadingRegister && (
                      <CircularProgress size="23px" style={{ marginRight: '0.5rem' }} />
                    )}
                    Cadastrar todos
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Container>
      </div>
    );
  }
}
