import React, { createContext, useContext, useState } from 'react';

export const MarketingContext = createContext();

export default function MarketingProvider({ children }) {
  const [templateList, setTemplateList] = useState({});
  const [establishmentData, setEstablishmentData] = useState(null);
  const [storeData, setStoreData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isImageDownloaded, setIsImageDownloaded] = useState(false);
  const [textStoreItem, setTextStoreItem] = useState([]);
  const [loadingElements, setLoadingElements] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [selectedColorTemplate, setSelectedColorTemplate] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);

  const [elementText, setElementText] = useState({});
  const [textValue, setTextValue] = useState('');
  const [fontColorValue, setFontColorValue] = useState('');
  const [fontStyleValue, setFontStyleValue] = useState('');
  const [widthSizeText, setWidthSizeText] = useState('');
  const [widthPositionText, setWidthPositionText] = useState('');
  const [heightSizeText, setHeightSizeText] = useState('');
  const [heightPositionText, setHeightPositionText] = useState('');
  const [selectedElementText, setSelectedElementText] = useState('');

  const [elementImage, setElementImage] = useState({});
  const [imageValue, setImageValue] = useState('');
  const [widthSizeImage, setWidthSizeImage] = useState('');
  const [widthPositionImage, setWidthPositionImage] = useState('');
  const [heightSizeImage, setHeightSizeImage] = useState('');
  const [heightPositionImage, setHeightPositionImage] = useState('');
  const [selectedElementImage, setSelectedElementImage] = useState('');

  const [elementQrcode, setElementQrcode] = useState({});
  const [qrcodeLink, setQrcodeLink] = useState('');
  const [loadingLink, setLoadingLink] = useState(false);

  const [listElementSelected, setListElementSelected] = useState([]);

  const [selectedFormat, setSelectedFormat] = useState('portrait');

  const handleSelectStore = (value, tabIndex) => {
    let text = '';
    const textValue = listElementSelected[tabIndex].initialText;

    const selectedStore = storeData.filter((store) => store.title === value).shift();

    setTextStoreItem([selectedStore?.title, selectedStore?.description]);

    if (textValue === 'description') {
      text = selectedStore?.description;
    } else if (textValue === 'title') {
      text = selectedStore?.title;
    }

    if (listElementSelected[tabIndex]?.blockText) {
      listElementSelected[tabIndex]['text'] = text;
      setListElementSelected([...listElementSelected]);
    } else if (!listElementSelected[tabIndex]?.text) {
      listElementSelected[tabIndex]['text'] = text;
      setListElementSelected([...listElementSelected]);
    } else if (listElementSelected[tabIndex]?.text === text) {
      listElementSelected[tabIndex]['text'] = text;
      setListElementSelected([...listElementSelected]);
    } else {
      // listElementSelected[tabIndex]['text'] = listElementSelected[tabIndex].text;
      setListElementSelected([...listElementSelected]);
    }
  };

  return (
    <MarketingContext.Provider
      value={{
        templateList,
        setTemplateList,
        establishmentData,
        setEstablishmentData,
        storeData,
        setStoreData,
        selectedTemplate,
        setSelectedTemplate,
        elementText,
        setElementText,
        elementImage,
        setElementImage,
        elementQrcode,
        setElementQrcode,
        textValue,
        setTextValue,
        fontColorValue,
        setFontColorValue,
        fontStyleValue,
        setFontStyleValue,
        imageValue,
        setImageValue,
        qrcodeLink,
        setQrcodeLink,
        loadingLink,
        setLoadingLink,
        selectedElementImage,
        setSelectedElementImage,
        selectedElementText,
        setSelectedElementText,
        handleSelectStore,
        widthSizeText,
        setWidthSizeText,
        widthPositionText,
        setWidthPositionText,
        heightSizeText,
        setHeightSizeText,
        heightPositionText,
        setHeightPositionText,
        listElementSelected,
        setListElementSelected,
        widthSizeImage,
        setWidthSizeImage,
        widthPositionImage,
        setWidthPositionImage,
        heightSizeImage,
        setHeightSizeImage,
        heightPositionImage,
        setHeightPositionImage,
        isImageDownloaded,
        setIsImageDownloaded,
        setTextStoreItem,
        textStoreItem,
        selectedFormat,
        setSelectedFormat,
        loadingElements,
        setLoadingElements,
        loadingPreview,
        setLoadingPreview,
        selectedColorTemplate,
        setSelectedColorTemplate,
        loadingImage,
        setLoadingImage,
      }}
    >
      {children}
    </MarketingContext.Provider>
  );
}

export const useTemplate = () => {
  const {
    templateList,
    setTemplateList,
    selectedTemplate,
    setSelectedTemplate,
    establishmentData,
    setEstablishmentData,
    storeData,
    setStoreData,
    listElementSelected,
    setListElementSelected,
    isImageDownloaded,
    setIsImageDownloaded,
    selectedFormat,
    setSelectedFormat,
    loadingElements,
    setLoadingElements,
    loadingPreview,
    setLoadingPreview,
    selectedColorTemplate,
    setSelectedColorTemplate,
    loadingImage,
    setLoadingImage,
  } = useContext(MarketingContext);

  return {
    templateList,
    setTemplateList,
    selectedTemplate,
    setSelectedTemplate,
    establishmentData,
    setEstablishmentData,
    storeData,
    setStoreData,
    listElementSelected,
    setListElementSelected,
    isImageDownloaded,
    setIsImageDownloaded,
    selectedFormat,
    setSelectedFormat,
    loadingElements,
    setLoadingElements,
    loadingPreview,
    setLoadingPreview,
    selectedColorTemplate,
    setSelectedColorTemplate,
    loadingImage,
    setLoadingImage,
  };
};

export const useElementText = () => {
  const {
    elementText,
    setElementText,
    textValue,
    setTextValue,
    fontColorValue,
    setFontColorValue,
    fontStyleValue,
    setFontStyleValue,
    selectedElementText,
    setSelectedElementText,
    handleSelectStore,
    widthSizeText,
    setWidthSizeText,
    widthPositionText,
    setWidthPositionText,
    heightSizeText,
    setHeightSizeText,
    heightPositionText,
    setHeightPositionText,
    setTextStoreItem,
    textStoreItem,
  } = useContext(MarketingContext);

  return {
    elementText,
    setElementText,
    textValue,
    setTextValue,
    fontColorValue,
    setFontColorValue,
    fontStyleValue,
    setFontStyleValue,
    selectedElementText,
    setSelectedElementText,
    handleSelectStore,
    widthSizeText,
    setWidthSizeText,
    widthPositionText,
    setWidthPositionText,
    heightSizeText,
    setHeightSizeText,
    heightPositionText,
    setHeightPositionText,
    setTextStoreItem,
    textStoreItem,
  };
};

export const useElementImage = () => {
  const {
    elementImage,
    setElementImage,
    imageValue,
    setImageValue,
    selectedElementImage,
    setSelectedElementImage,
    widthSizeImage,
    setWidthSizeImage,
    widthPositionImage,
    setWidthPositionImage,
    heightSizeImage,
    setHeightSizeImage,
    heightPositionImage,
    setHeightPositionImage,
  } = useContext(MarketingContext);

  return {
    elementImage,
    setElementImage,
    imageValue,
    setImageValue,
    selectedElementImage,
    setSelectedElementImage,
    widthSizeImage,
    setWidthSizeImage,
    widthPositionImage,
    setWidthPositionImage,
    heightSizeImage,
    setHeightSizeImage,
    heightPositionImage,
    setHeightPositionImage,
  };
};

export const useElementQrcode = () => {
  const {
    elementQrcode,
    setElementQrcode,
    qrcodeLink,
    setQrcodeLink,
    setLoadingLink,
    loadingLink,
  } = useContext(MarketingContext);

  return {
    elementQrcode,
    setElementQrcode,
    qrcodeLink,
    setQrcodeLink,
    setLoadingLink,
    loadingLink,
  };
};

