import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Typography,
  Grid,
  useMediaQuery,
  ButtonGroup,
  TextField,
} from '@mui/material';

// icons
import PublishIcon from '@mui/icons-material/Publish';

import useStyles, { useMediumStyles } from './styles';

export default function PromotionForm({ location }) {
  const [promotionImage, setPromotionImage] = useState('');
  const [promotion, setPromotion] = useState({ title: '', description: '' });
  const [coupons, setCoupons] = useState(1);

  // resolutions media queries config
  const styles = {
    small: useMediumStyles(),
    large: useStyles(),
  };

  const currentResolution = {
    small: useMediaQuery('(max-width: 1366px)'),
  };

  // styles config
  const classes = currentResolution.small ? styles.small : styles.large;

  const inputProps = {
    marginBottom: 10,
    fontSize: !currentResolution.small && 26,
  };

  const buttonProps = {
    width: currentResolution.small ? 3 : 3,
  };

  const getPromotionImage = () => {
    const file = document.querySelector('#promotionImageInput').files[0];
    const reader = new FileReader();

    reader.onloadend = () => setPromotionImage(reader.result);

    if (file) return reader.readAsDataURL(file);

    setPromotionImage('');
  };

  const handleSetPromotion = (type) => (event) =>
    setPromotion({
      ...promotion,
      [type]: event.target.value,
    });

  const handleSetCoupons = (type) => {
    if (type === 'decrement' && coupons > 1) return setCoupons(coupons - 1);

    if (type === 'increment') return setCoupons(coupons + 1);
  };

  // useEffect(() => {
  //   console.log(location?.state);
  // }, []);

  return (
    <Box
      classes={{ root: classes.root }}
      display='flex'
      flexDirection='column'
      justifyContent='center'>
      <Typography
        color='primary'
        component='h3'
        variant='subtitle1'
        className={classes.title}>
        Título do template
      </Typography>

      <Grid container classes={{ root: classes.contentWrapper }}>
        <Grid item md={4} sm={5} lg={3}>
          <Box classes={{ root: classes.imageContainer }}>
            <img src={promotionImage} alt='' />

            <Box p={2} classes={{ root: classes.cardImageBody }}>
              <Typography variant='subtitle2' className={classes.cardText}>
                {promotion.title || 'Nome da promoção'}
              </Typography>
              <Typography variant='subtitle2' className={classes.cardText}>
                {location?.state?.dataEstabelecimento?.nome ||
                  'Nome do estabelecimento'}
              </Typography>
              <Typography variant='subtitle2' className={classes.cardText}>
                {promotion.description || 'Descrição da promoção...'}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={6} md={7} lg={7}>
          <FormControl
            component='form'
            fullWidth
            className={classes.formContainer}>
            <Button
              variant='contained'
              component='label'
              color='primary'
              className={classes.addImageButton}
              startIcon={<PublishIcon className={classes.uploadIcon} />}>
              Adicione uma imagem
              <input
                type='file'
                id='promotionImageInput'
                onChange={getPromotionImage}
                hidden
                required
              />
            </Button>

            <Box>
              <TextField
                color='primary'
                placeholder='Título da promoção'
                value={promotion.title}
                onChange={handleSetPromotion('title')}
                fullWidth
                required
                style={inputProps}
              />

              <TextField
                color='primary'
                placeholder='Descrição da promoção'
                value={promotion.description}
                onChange={handleSetPromotion('description')}
                fullWidth
                required
                style={inputProps}
              />

              {location?.state?.type === 'loyalty' && (
                <>
                  <Typography variant='subtitle1'>
                    Quantidade de cupons
                  </Typography>
                  <ButtonGroup style={{ marginTop: 3 }}>
                    <Button
                      color='primary'
                      variant='contained'
                      classes={{ root: classes.counterButton }}
                      disableElevation
                      size='medium'
                      style={buttonProps}
                      onClick={() => handleSetCoupons('decrement')}>
                      -
                    </Button>
                    <div className={classes.counterBox}>{coupons}</div>
                    <Button
                      color='primary'
                      variant='contained'
                      classes={{ root: classes.counterButton }}
                      disableElevation
                      size='medium'
                      style={buttonProps}
                      onClick={() => handleSetCoupons('increment')}>
                      +
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </Box>

            <Box display='flex'>
              <Button
                variant='contained'
                color='secondary'
                size='medium'
                className={classes.backButton}
                onClick={() => window.history.back()}>
                Voltar
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='medium'
                className={classes.actionButton}
                type='submit'>
                Solicitar Aprovação
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
