import styled from "styled-components";

export const TextQueryContainer = styled.div`
  width: 100%;
  height: 244px;
  border: 1px solid #757575;
  border-radius: 10px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextQuery = styled.textarea`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  padding: 1rem;
  outline: none;
`;
