import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function getVoucherTemplate(templateId) {
  return new Promise(function (resolve, reject) {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + '/voucherTemplate/' + templateId, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
