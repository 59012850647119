import React from 'react';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from '../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useParams } from 'react-router';

export default function Campaigns() {
  const params = useParams();
  const concatId = params?.id ? `/${params?.id}` : '';
  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container>
        <D.TriiboH1
          style={{
            marginTop: '2rem',
            marginBottom: '3rem',
          }}
        >
          Campanhas
        </D.TriiboH1>

        <S.ButtonsContainer>
          <S.RegisterButton
            to={{
              pathname: `/admin/configuracoes/campanhas/adicionarCampanha${concatId}`,
              state: '',
            }}
          >
            <D.TriiboH4>Cadastrar Campanha</D.TriiboH4>
          </S.RegisterButton>
          <S.ListButton
            to={{
              pathname: `/admin/configuracoes/campanhas/listarCampanhas${concatId}`,
              state: '',
            }}
          >
            <D.TriiboH4>Lista de Campanhas</D.TriiboH4>
          </S.ListButton>
        </S.ButtonsContainer>
      </S.Container>
    </div>
  );
}
