import React from 'react';

//STYLES
import { Box, Button, CircularProgress, Modal } from '@mui/material';
import { BoxModal, ContentButton } from './segmentStyles';

//ICONS
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const ModalDelete = ({ close, deleteType, loading, open }) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={close}
    >
      <BoxModal sx={{ position: 'relative' }}>
        <Box
          sx={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }}
          onClick={close}
        >
          <CloseRoundedIcon fontSize="large" />
        </Box>

        <h3>Deseja prosseguir com a exclusão ?</h3>

        <ContentButton>
          <Button color="primary" variant="contained" onClick={deleteType}>
            {loading ? (
              <CircularProgress size={25} thickness={6} style={{ color: '#fff' }} />
            ) : (
              'Excluir'
            )}
          </Button>
          <Button color="secondary" variant="contained" onClick={close}>
            Cancelar
          </Button>
        </ContentButton>
      </BoxModal>
    </Modal>
  );
};
