import { Box } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

export default makeStyles((theme) => ({
    root: {
        padding: '1rem 1rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
            width: '90%',
            padding: '1rem 0rem',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '16px',
            width: '90%',
            margin: '0rem 5.8rem',
        },
    },

    title: {
        fontWeight: 'bold',
    },

    label: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },

    statusButtonActive: {
        width: '100%',
        borderRadius: '15px',

        color: 'white',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.main,
        },
        cursor: 'auto',
        [theme.breakpoints.only('md')]: {
            width: '80%',
            marginLeft: '2rem',
        },
    },

    statusButtonInactive: {
        width: '100%',
        borderRadius: '15px',

        color: 'white',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        },
        cursor: 'auto',
        [theme.breakpoints.only('md')]: {
            width: '80%',
            marginLeft: '2rem',
        },
    },

    boxContent: {
        marginTop: '-4rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '2rem',
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: '5rem',
            marginTop: '-0.5rem',
        },
    },

    boxStatusButton: {
        paddingTop: '2.7rem',
        paddingBottom: '0rem',
        width: '20rem',
    },

    boxGroupButton: { 
        [theme.breakpoints.down('xs')]: {
            marginLeft: '3rem',
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '3rem',
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '3rem',
        },
    },

    starIcon: {
        width: 64,
        height: 64,

        color: '#FFC921',
    },

    optionButton: {
        fontWeight: 'bold',
        fontSize: '20px',
        width: '200px',
        borderRadius: '10px',
        '&+a': {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '1rem',
        },
    },

    alert: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '1rem',
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: '0rem',
        },
    },

    // button colors
    warningButton: {
        backgroundColor: '#FFC921',
        '&:hover': {
            backgroundColor: '#FFC921',
        },
    },

    successButton: {
        backgroundColor: '#673AB8',
        '&:hover': {
            backgroundColor: '#673AB8',
        },
    },

    errorButton: {
        backgroundColor: '#9C28B1',
        '&:hover': {
            backgroundColor: '#9C28B1',
        },
    },

    infoButton: {
        backgroundColor: '#06BAD0',
        '&:hover': {
            backgroundColor: '#06BAD0',
        },
    },

    link: {
        textDecoration: 'none',
        marginLeft: '6px'
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '90%',
        height: '90%',
        maxWidth: '750px',
        maxHeight: '250px',
        transform: 'translate(-50%, -50%)',
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    dialogButton: {
        width: '300px',
        height: '86px',
        margin: '0 auto',
        paddingTop: '1.2rem',
        [theme.breakpoints.down('xs')]: {
            width: '180px',
            height: '86px',
            paddingTop: '1rem',
        },
    },

}));

export const Line = styled(Box)({
    width: 1,
    height: '70%',
    position: 'relative',
    left: '40%',
    alignSelf: 'center',
    backgroundColor: '#000',
});
