import React, { useState } from 'react';

import { Typography, Grid, TextField, Button } from '@mui/material';

import { sendEmail } from 'js/library/utils/API/apiSendEmail';
import { getUserInfo } from 'js/library/utils/helpers';
import { createDatabase } from 'js/library/services/DatabaseManager';
import { GoBackHeader } from '../Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';

export default function ContactTriiboExternal() {
  const uId = getUserInfo().uId;
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [messageSend, setMessageSend] = useState(false);

  const handleMessage = () => {
    createDatabase('TriiboB2BHelp', {
      uIdSender: uId,
      message: message,
      subject: subject,
      emailSender: email,
      createDate: Date.now(),
    });
    sendEmail(email, subject, message);

    setMessageSend(true);
  };

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Suporte" />

      {messageSend === false ? (
        <div>
          <div align="center">
            <Typography
              style={{ fontWeight: 'bold', marginTop: '20px' }}
              color="primary"
              variant="h6"
              gutterBottom
            >
              Envie suas dúvidas, comentários e sugestões para nossa equipe.
            </Typography>
          </div>

          <Grid container>
            <Grid item lg={2} md={1} sm={false} xs={false}></Grid>
            <Grid item lg={8} md={10} sm={12} xs={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleMessage();
                }}
              >
                <TextField
                  id="email"
                  autoFocus
                  required
                  fullWidth
                  label="E-mail de contato"
                  style={{ marginBottom: '15px' }}
                  variant="outlined"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  id="subject"
                  required
                  fullWidth
                  label="Assunto"
                  style={{ marginBottom: '15px' }}
                  variant="outlined"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />

                <TextField
                  id="message"
                  required
                  fullWidth
                  multiline
                  rows={5}
                  label="Mensagem"
                  variant="outlined"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{
                    width: '100px',
                    fontWeight: 'bold',
                    float: 'right',
                    color: 'white',
                    textTransform: 'capitalize',
                    marginTop: '10px',
                    fontSize: '16px',
                  }}
                >
                  Enviar
                </Button>
              </form>
            </Grid>
            <Grid item lg={2} md={1} sm={false} xs={false}></Grid>
          </Grid>
        </div>
      ) : (
        <div align="center">
          <Typography style={{ fontWeight: 'bold' }} color="primary" variant="h6" gutterBottom>
            Sua mensagem foi enviada com sucesso!
            <br />
            Alguém de nossa equipe entrará em contato com você nos próximos dias.
          </Typography>
        </div>
      )}
    </div>
  );
}

