import React, { useEffect, useState } from 'react';

import * as S from '../styles';
import * as D from '../../../DesignSystem/styles';

import { Modal, Typography, Button, AccordionSummary, AccordionDetails } from '@mui/material';

import BalanceItem from 'js/containers/Balance/BalanceItem.js';

import starIcon from '../../../../../styles/assets/SearchUser/starPoints.svg';
import WalletIcon from '../../../../../styles/assets/SearchUser/wallet.svg';
import ArrowDropdownIcon from '../../../../../styles/assets/SearchUser/arrow-dropdown.svg';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1';

export default function Wallet({ handleCloseModal, isModalWalletOpen, userv3 }) {
  const [activeCoupons, setActiveCoupons] = useState([]);
  const [inactiveCoupons, setInactiveCoupons] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    getWallet_v1(userv3.uId, userv3.triiboId).then((walletReceived) => {
      if (walletReceived != null) {
        let activeCoupons = [];
        let inactiveCoupons = [];
        if (walletReceived.coupons !== null) {
          Object.keys(walletReceived.coupons).map(function (item, i) {
            let element = Object.keys(walletReceived.coupons)[i];
            walletReceived.coupons[item].key = element;
            if (
              walletReceived.coupons[item].state === 'ongoing' ||
              walletReceived.coupons[item].status === 'enviado'
            ) {
              return activeCoupons.push(walletReceived.coupons[item]);
            } else {
              return inactiveCoupons.push(walletReceived.coupons[item]);
            }
          });
        }
        setActiveCoupons(activeCoupons);
        setInactiveCoupons(inactiveCoupons);
        setTotalPoints(walletReceived.totalPoints);
      }
    });
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalWallet')}
      open={isModalWalletOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.ModalBox>
        <S.WalletModalBox>
          <div className="walletHeader">
            <div className="walletTitle">
              <img src={WalletIcon} alt="icone-carteina" />
              <D.TriiboH2>Carteira</D.TriiboH2>
            </div>
            <div className="totalPoints">
              <img src={starIcon} alt="icone-pontos" />
              <D.TriiboH3>{totalPoints} Pontos</D.TriiboH3>
            </div>
          </div>

          <div className="containerSelect">
            <S.AccordionStyle
              noWrap
              className="dropdown"
              expanded={expanded === 'panel1'}
              TransitionProps={{ unmountOnExit: true }}
              disableGutters
              elevation={1}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<img src={ArrowDropdownIcon} alt="icone-seta" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography style={{ padding: 0 }}>Cupons ativos</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                {activeCoupons.length > 0 ? (
                  activeCoupons.map((item) => <BalanceItem voucher={item} key={item.key} />)
                ) : (
                  <Typography>Usuário não possui cupons ativos.</Typography>
                )}
              </AccordionDetails>
            </S.AccordionStyle>
            <S.AccordionStyle
              noWrap
              className="dropdown"
              expanded={expanded === 'panel2'}
              TransitionProps={{ unmountOnExit: true }}
              disableGutters
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<img src={ArrowDropdownIcon} alt="icone-seta" />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography style={{ padding: 0 }}>Cupons inativos</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                {inactiveCoupons.length > 0 ? (
                  inactiveCoupons.map((item) => <BalanceItem voucher={item} key={item.key} />)
                ) : (
                  <Typography>Usuário não possui cupons ativos.</Typography>
                )}
              </AccordionDetails>
            </S.AccordionStyle>
          </div>
          <div className="buttonModalWallet">
            <Button
              variant="contained"
              style={{
                margin: '1rem auto 0',
                width: '164px',
                background: '#DBDBDB',
                color: '#555555',
                textTransform: 'capitalize',
                fontWeight: 'bold',
              }}
              onClick={() => handleCloseModal('closeModalWallet')}
            >
              Voltar
            </Button>
          </div>
        </S.WalletModalBox>
      </S.ModalBox>
    </Modal>
  );
}

