import React from 'react';

import { Typography, Grid, Box } from '@mui/material';

const triiboPurple = '#6E3296';

const FAQ = () => {
  return (
    <>
      <Box sx={{ width: '100%', marginTop: { xs: '6rem', md: '36vh' } }}>
        <Grid container>
          <Grid container item xs={12} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={1} md={2} />
            <Grid
              item
              xs={10}
              md={8}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ gap: '3rem' }}
            >
              <Typography
                variant="h2"
                color="#383838"
                align="center"
                sx={{
                  fontFamily: 'Source Sans Pro, Roboto',
                  fontWeight: '600',
                }}
              >
                Dúvidas?
              </Typography>
              <Typography
                variant="h6"
                color="#383838"
                align="center"
                sx={{
                  fontFamily: 'Source Sans Pro, Roboto',
                  marginTop: '1rem',
                }}
              >
                Caso tenha qualquer dificuldade para gerenciar seus negócios, criar promoções ou
                queira dicas de como fortalecer sua rede de clientes, entre em contato com nossa
                equipe através dos contatos abaixo:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: { xs: '1rem', md: '5rem' },
                  marginTop: '1.6rem',
                }}
              >
                <a href="mailto:contato@triibo.com.br" style={{ color: triiboPurple }}>
                  <Typography
                    variant="subtitle1"
                    color={triiboPurple}
                    align="center"
                    sx={{
                      fontFamily: 'Source Sans Pro, Roboto',
                      fontWeight: '700',
                    }}
                  >
                    E-mail: contato@triibo.com.br
                  </Typography>
                </a>

                <a
                  href="https://api.whatsapp.com/send?phone=5511944965799"
                  style={{ color: triiboPurple }}
                >
                  <Typography
                    variant="subtitle1"
                    color={triiboPurple}
                    align="center"
                    sx={{
                      fontFamily: 'Source Sans Pro, Roboto',
                      fontWeight: '700',
                    }}
                  >
                    Whatsapp:(11)94496-5799
                  </Typography>
                </a>
              </Box>
            </Grid>

            <Grid item xs={1} md={2} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FAQ;

