import styled, { css, keyframes } from 'styled-components';
import TextField from '@mui/material/TextField';

export const Wallpaper = styled.div`
  ${({img, imgMobile}) => {
        return css`
      width: 100vw;
      height: 100vh;
      background-image: url(${img});
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 600px) {
        background-image: url(${imgMobile});
      }
    `;
    }}
`;

export const TextContainer = styled.div`
  ${({ width, widthMobile }) => {
        return css`
      width: ${width};

      @media (max-width: 600px) {
        width: ${widthMobile};
      }
    `;
    }}
`;

export const TextFieldS = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border-bottom: 3px solid #6e32964c;
  }
  /* hover (double-ampersand needed for specificity reasons. */
  && .MuiInput-underline:hover:before {
    border-bottom: 3px solid #6e329699;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 3px solid #6e3296;
  }
`;

export const NumbersContainer = styled.div`
    width: 72%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    cursor: text;

    @media (max-width: 600px) {
      // margin-top: 8rem;
      width: 84%;
      gap: 0.4rem;
    }
`;

const lineColor = keyframes`
  0% { opacity: 1 }
  60% { opacity: 0 }
  100% { opacity: 1 }
`;

export const CardNumberUnderline  = styled.div`${({focus})=>{
    return css`
  position: relative;
  width: 57px;
  height: 68px;
  color: #6E3296;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid ${focus? '#6e3296': '#E2E2E2'};
  border-radius: 14px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1.68rem;

  @media (max-width: 600px) {
    width: 53px;
    height: 44px;
    font-size: 1.2rem;
    border-radius: 10px;
  };

  &::after {
    position: absolute;
    scale: (0.25, 1);
    top: 0.9rem;
    content: "|";
    animation-name: ${lineColor};
    animation-duration: 2s;
    animation-iteration-count: infinite;

    @media (max-width: 600px) {
      top: 0.6rem;
    };

  }
`;}}
`;

export const CardNumber  = styled.div`${({focus})=>{
    return css`
    position: relative;
    width: 57px;
    height: 68px;
    color: #6E3296;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid ${focus? '#6e3296': '#E2E2E2'};
    border-radius: 14px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 1.68rem;

    @media (max-width: 600px) {
      width: 53px;
      height: 44px;
      font-size: 1.2rem;
      border-radius: 10px;
    };

    &:after {
      position: absolute;
      content: "";
    }
`;}}
`;
// export const Textfield  = styled.Textfield`${({focus})=>{
//     return css`
//   width: 57px;
//   height: 68px;
//   box-shadow: 0px 3px 6px #00000029;
//   border: 1px solid ${focus? '#6e3296': '#70707033'};
//   border-radius: 14px;
//   display:flex;
//   justify-content: center;
//   align-items: center;
// `;}}
// `;