import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";

import { cloneDeep } from "lodash";

import TriiboAutocomplete from "js/components/DesignSystem/TriiboAutocomplete";

const SelectKeywords = (props) => {
  const { form, setForm, keywordsArr } = props;
  const [current, setCurrent] = useState([]);

  let isSuperAdmin = useSelector((state) => state.authModel.superUser);

  useEffect(() => {
    const newForm = cloneDeep(form);
    setForm({ ...newForm, keywords: current });
  }, [current]);

  const objKey = "value";

  if (!isSuperAdmin) {
    return <></>;
  }

  return (
    <>
      <S.Container>
        <div>
          <D.TriiboH4>
            <b>Keywords</b>
          </D.TriiboH4>
          <D.TriiboH5>Selecione as keywords da postagem.</D.TriiboH5>
        </div>
        <S.ContentAudienceContainer>
          <TriiboAutocomplete
            arr={keywordsArr}
            setCurrent={setCurrent}
            objKey={objKey}
            label={"Keywords"}
            preSelected={form.keywords}
          />
        </S.ContentAudienceContainer>
      </S.Container>
    </>
  );
};

export default SelectKeywords;
