import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// STYLES
import * as S from './styles';
import { CircularProgress, Pagination } from '@mui/material';

// COMPONENTES
import SimpleTable from 'js/components/TriiboComponents/Tables/SimpleTable';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

// APIS
import { getAllPromotionOpen } from 'js/library/utils/API/Store/apiReadStore';
import { convertToDateDefault } from 'js/library/utils/helpers';
import { getStore } from 'js/library/utils/API/getStore';

const DataTableStoreApproval = () => {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const PER_PAGE = 10;
  const count = Math.ceil(dataTable.length / PER_PAGE);
  const _DATA = usePagination(dataTable, PER_PAGE);

  const history = useHistory();

  const handleChangePage = (e, p) => {
    _DATA.jump(p);
    setPage(p);
  };

  const column = [
    { heading: 'Nome do estabelecimento', value: 'establishmentName' },
    { heading: 'Nome da promoção', value: 'title' },
    { heading: 'Data de solicitação', value: 'requestDate' },
    { heading: 'Tipo', value: 'action' },
    { heading: 'Origem', value: 'origin' },
    { heading: 'Status', value: 'status' },
  ];

  const redirectOnClick = async (item) => {
    const store = await getStore(item.id);

    if (store.type === 'businessPartner') {
      history.push({
        pathname: `/estabelecimento/${item.establishmentId}/promocaoexterna/${item.id}/editar/`,
        state: item,
      });
    } else {
      history.push({
        pathname: `/estabelecimento/${item.establishmentId}/promocao/${item.id}/editar/`,
        state: item,
      });
    }

    localStorage.setItem('fromWherePromotion', 'curadoria-promo');
  };

  const tranformData = (data) => {
    const filteredData = data.filter((item) => {
      return item;
    });

    return filteredData.map((value) => {
      return {
        ...value,
        action: value.action === 'create' ? 'criação' : value.action === 'edit' ? 'edição' : '',
        requestDate: value.requestDate ? convertToDateDefault(value.requestDate) : 'Data',
        establishmentName: value.establishmentName ? value.establishmentName : '',
        title: value.title ? value.title : 'N/A',
        origin: value.origin ? value.origin : 'triibo',
        status: value.status ? value.status : '',
      };
    });
  };

  //trás os primeiros 20 registros
  useEffect(() => {
    setLoading(true);
    getAllPromotionOpen(0, 20)
      .then((dataReceived) => {
        setDataTable(tranformData(dataReceived));
        localStorage.removeItem('fromWherePromotion');
      })
      .catch(() => {
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //paginação: trás mais 20 quando o usuário clica na ultima página da tabela
  useEffect(() => {
    if (count === page && dataTable.length >= 20) {
      setLoading(true);
      getAllPromotionOpen(dataTable.length, 20)
        .then((dataReceived) => {
          setDataTable((prev) => [...prev, ...tranformData(dataReceived)]);
          localStorage.removeItem('fromWherePromotion');
        })
        .catch(() => {
          setLoading(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [page]);

  return (
    <S.Container>
      {loading ? (
        <div
          style={{
            margin: '0 auto',
            paddingTop: '10rem',
          }}
        >
          <CircularProgress size="60px" />
        </div>
      ) : (
        <>
          <SimpleTable
            data={_DATA.currentData()}
            column={column}
            usePaginate={false}
            redirectOnClick={redirectOnClick}
          />
          <Pagination
            sx={{ margin: '0 auto' }}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={handleChangePage}
          />
        </>
      )}
    </S.Container>
  );
};

export default DataTableStoreApproval;
