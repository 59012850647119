import React, { useEffect } from 'react';
import { Modal } from '@mui/material';

import * as C from '../styles';
import * as D from '../../../../DesignSystem/styles';
import * as S from './styles';

import { AiFillCloseCircle } from 'react-icons/ai';
import calendarIcon from '../../../../../../styles/assets/communications/calendarPurple.svg';
import clock from '../../../../../../styles/assets/communications/clock-fill.svg';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function ModalShedulePublication({
  handleCloseModal,
  isModalShedulePublicationOpen,
  setScheduledDate,
  setTypeSubmit,
  setIsModalConfirmSubmitOpen,
  scheduledDate,
}) {
  const [arrayHours, setArrayHours] = useState([]);
  const [dateSelected, setDateSelected] = useState('');
  const [hourSelected, setHourSelected] = useState('');

  const handleSubmit = () => {
    if (dateSelected === '') {
      toast.warn('Informe uma data.');
      return;
    }

    if (hourSelected === '') {
      toast.warn('Selecione um horário.');
      return;
    }

    setTypeSubmit('schedule');
    handleCloseModal('shedulePublication');
    setIsModalConfirmSubmitOpen(true);
  };

  useEffect(() => {
    const GenerateSchedulList = () => {
      let items = [];
      for (var hour = 0; hour < 24; hour++) {
        items.push([hour, 0]);
        items.push([hour, 30]);
      }

      const date = new Date();
      const formatter = new Intl.DateTimeFormat('pt-br', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });

      const range = items.map((time) => {
        const [hour, minute] = time;
        date.setHours(hour);
        date.setMinutes(minute);

        return formatter.format(date);
      });

      return range;
    };

    setArrayHours(GenerateSchedulList());
  }, []);

  useEffect(() => {
    const date = new Date(dateSelected);
    const dateMiliseconds = date.getTime() + 1000 * 60 * 60 * 3;
    const dateFormated = new Date(dateMiliseconds);

    const year = dateFormated.getFullYear();
    const month = dateFormated.getMonth();
    const day = dateFormated.getDate();

    const dateWithTime = new Date(
      year,
      month,
      day,
      hourSelected.split(':')[0],
      hourSelected.split(':')[1],
      0,
      0
    );

    setScheduledDate(dateWithTime.getTime());
  }, [dateSelected, hourSelected]);

  useEffect(() => {
    if (scheduledDate === 0) {
      setDateSelected('');
      setHourSelected('');
    }
  }, [scheduledDate]);

  return (
    <Modal
      onClose={() => handleCloseModal('shedulePublication')}
      open={isModalShedulePublicationOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={{ width: '100%', height: '100%' }}>
        <C.ModalContainer desk_width={'654px'}>
          <C.ModalBox>
            <C.CloseModalIcon onClick={() => handleCloseModal('shedulePublication')}>
              <AiFillCloseCircle size={20} />
            </C.CloseModalIcon>
            <S.Header>
              <D.TriiboH4>
                <strong>Agendar publicação</strong>
              </D.TriiboH4>
              <D.TriiboH5>
                Escolha o dia e a hora para sua publicação. Pode levar até 30 minutos para que sua
                comunicação seja publicada no feed e todas as notificações enviadas.
              </D.TriiboH5>
            </S.Header>

            <S.Container>
              <S.SelectDateContainer>
                <img src={calendarIcon} alt="" />
                <S.InputDate
                  type="date"
                  value={dateSelected}
                  onChange={(e) => setDateSelected(e.target.value)}
                />
              </S.SelectDateContainer>

              <S.SelectTimeContainer>
                <img src={clock} alt="" />
                <S.SelectTime
                  value={hourSelected}
                  onChange={(e) => setHourSelected(e.target.value)}
                >
                  {arrayHours.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </S.SelectTime>
              </S.SelectTimeContainer>

              <S.SheduleButton onClick={() => handleSubmit()}>Agendar</S.SheduleButton>
            </S.Container>
          </C.ModalBox>
        </C.ModalContainer>
      </div>
    </Modal>
  );
}
