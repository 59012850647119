import axios from 'axios';

import { decrypt } from '../../helpers';
import authTokenWithScUIDNull from '../authTokenWithScUIDNull';
import cfac22 from '../../cfac22';
export default async function validateUserMapfre(CPF) {
  const token = await authTokenWithScUIDNull();

  var config = {
    method: 'get',
    url: `${cfac22('API_HOST_V2')}/mapfre/validateUserMapfre?cpf=${CPF}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        let dataUser = JSON.parse(decrypt(response.data.response, cfac22('CRYPTO_KEY_MAPFRE')));
        // console.log("dataUser >>>>>", dataUser);
        resolve(dataUser);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
}
