import axios from 'axios';
import authToken from './authToken';

import cfac22 from '../cfac22';

export async function getStore(storeId) {
  const token = await authToken();
  const headers = {
    headers: { authorization: `Bearer ${token}` },
  };
  const endpoint = `${cfac22('API_HOST_V2')}/stores/${storeId}`;

  try {
    const response = await axios.get(endpoint, headers);

    if (response?.data?.success) {
      return response?.data?.storeInfo;
    }
  } catch (err) {
    const error = err;
    return error?.response?.data?.error;
  }
}
