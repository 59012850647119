import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@mui/styles';
import { Link, Redirect } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import Loading from 'js/containers/Loading/Loading';
import { getDataRedshift } from 'js/library/utils/API/apiRedshift.js';
import { updateBatchActionsAction } from 'js/core/actions/batchActionsActions';
import DataTableQueries from 'js/containers/DataTable/DataTableQueries';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
  buttonAddAction: {
    float: 'right',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
});

const BatchActions = (props) => {
  const { classes } = props;

  const [state, setState] = useState({
    id: '',
    fotoThumb: '',
    nome: '',
    endereco: '',
    data: [],
    rows: [
      { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
      { id: 'query', numeric: false, disablePadding: false, label: 'Query' },
    ],
    pathName: '/admin/configuracoes/acoeslote/',
    tableColumns: [],
    openSnackbar: false,
    snackMessage: '',
    userList: [],
    lastQuery: '',
    queryResult: [],
    queryId: '',
    name: '',
    creatorUId: '',
    status: '',
  });

  const handleCloseAlert = () => {
    setState((prevState) => ({ ...prevState, openSnackbar: false }));
  };

  const redshiftQuery = (query) => {
  //loading
    setState((prevState) => ({
      ...prevState,
      userList: false,
      lastQuery: '',
      queryResult: [],
    }));

    getDataRedshift(query)
      .then((result) => {
        if (result !== false) {
          if (result.length === 0) {
            setState((prevState) => ({
              ...prevState,
              openSnackbar: true,
              snackMessage: 'Nenhum resultado encontrado',
              userList: [],
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              userList: result,
              lastQuery: query,
              queryResult: result,
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            openSnackbar: true,
            snackMessage: 'Busca inválida',
            userList: [],
          }));
        }
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          openSnackbar: true,
          snackMessage: 'Erro ao realizar busca: ' + error.toString(),
          userList: [],
        }));
      });
  };

  const clearList = () => {
    setState((prevState) => ({
      ...prevState,
      userList: [],
      lastQuery: '',
      queryResult: [],
    }));
  };

 const  getExportList = () => {
    if (state.queryResult) {
      let finalList = '';
      Object.keys(state.queryResult).map((item, index) => {
        return (finalList =
          finalList + (index === 0 ? '' : ' \n ') + this.state.queryResult[item].linked_account);
      });
      finalList = finalList.replace(/[.]/gi, ',');
      return finalList;
    }
  };

  const SaveQuery = () => {
    setState((prevState) => ({
      ...prevState,
      openSnackbar: true,
      snackMessage: 
        `${prevState.name} ${prevState.status} ${prevState.lastQuery} ${Date.now()} ${prevState.creatorUId}`,
    }));

    let data = {
      queryId: state.queryId,
      name: state.name,
      query: state.lastQuery,
      createdDate: Date.now(),
      creatorUId: state.creatorUId,
      status: state.status,
    };

    props.updateBatchActionsComponent(data);
  };

  const handleChange = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  if (props.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  if (window.pathname) {
    return <Redirect to={window.pathname} />;
  } else {
  return (
    <div style={{ maxHeight: '100%', padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Queries" />
      <br />
      <Grid container spacing={5}>
        <Grid item md={6} xs={12}></Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.buttonAddAction}>
            <Link
              to={{
                pathname: '/admin/configuracoes/acoeslote/add',
                state: '',
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{
                  color: 'white',
                  textTransform: 'capitalize',
                  fontWeight: '550',
                }}
                title="Adicionar Query"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Add />}
              >
                Adicionar Query
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <DataTableQueries
        rows={state.rows}
        tableNode={'Queries'}
        pathName={state.pathName}
      />

    </div>
  );
};
};

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.batchActionsComponent.loading,
    success: state.batchActionsComponent.success,
    error: state.batchActionsComponent.error,
  };
}


//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateBatchActionsComponent: (value) =>
    updateBatchActionsAction(dispatch, value),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BatchActions);

