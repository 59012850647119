import React, { useState, useEffect } from 'react';

import { Cancel } from '@mui/icons-material';
import { Typography, IconButton, FormControl, Modal } from '@mui/material';

import { useStyles } from './useStyles';

import { getSeuClube } from 'js/library/utils/API/seuClube';
import { GetPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';

import RegisterUserModalForm from './RegisterUserModalForm';

export function RegisterUserModal({ isModalRegisterUserOpen, handleCloseModalRegisterUser }) {
  const [clubeId] = useState(window.location.href.split('/')[7]);

  const [clubInfo, setClubInfo] = useState({});
  const [clubForms, setClubForms] = useState({});

  const [formNotFound, setFormNotFound] = useState(false);

  useEffect(() => {
    getSeuClube(clubeId).then((res) => {
      setClubInfo(res);
      GetPartnerUserForms(res.partnerId)
        .then((result) => {
          setClubForms(result);
        })
        .catch(() => {
          setFormNotFound(true);
        });
    });
  }, [clubeId]);

  const classes = useStyles();

  return (
    <>
      <Modal
        open={isModalRegisterUserOpen}
        onClose={handleCloseModalRegisterUser}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FormControl className={classes.modalStyle}>
          <IconButton
            onClick={handleCloseModalRegisterUser}
            edge="end"
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              padding: '0',
              margin: '0.5em',
            }}
          >
            <Cancel />
          </IconButton>
          <Typography variant="h5" component="span" className={classes.titleModal}>
            Cadastrar individual
          </Typography>

          <RegisterUserModalForm
            formFields={clubForms.formFields}
            clubForms={clubForms}
            clubInfo={clubInfo}
            formNotFound={formNotFound}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              JustifyContent: 'center',
              paddingTop: '2rem',
              margin: '0 auto',
            }}
          >
            <small>* Usuários com número de celular inválido não serão cadastrados.</small>
          </div>
        </FormControl>
      </Modal>
    </>
  );
}
