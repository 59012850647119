import styled from "styled-components";

export const HeaderModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; ;
`;

export const Button = styled.button`
  background: ${({ type }) =>
    type === "cancel" ? "rgba(241, 58, 58, 0.05);" : "#328796"};
  border: 1px solid ${({ type }) => (type === "cancel" ? "#F13A3A" : "#328796")};
  border-radius: 10px;
  border: ${({ type }) => type !== "cancel" && "none"};
  outline: none;
  width: ${({ w }) => w || "160px"};
  height: 42px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    color: ${({ type }) => (type === "cancel" ? "#F13A3A" : "#ffffff")};
    font-weight: 600;
  }

  &:hover {
    background: ${({ type }) =>
      type === "cancel" ? "#F13A3A33" : "#328796e5"};
  }

  &:disabled {
    background: #ccc;
  }
`;
