import React from 'react';

import ImgAddBusiness from '../../../styles/assets/welcome/addBusiness.svg';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import setEst from 'js/library/services/currentEstSetter';
import currentEstActionsAction from 'js/core/actions/currentEstActions';

import * as S from './styles';

export default function CardCarousel({ addBusiness, title, img, link }) {
  const dispatch = useDispatch();
  return (
    <S.CardCarousel>
      <Link
        to={{
          pathname: '/estabelecimento/' + link.id,
          state: link,
        }}
        tile={link.nome}
        style={{ textDecoration: 'none' }}
      >
        <div
          className="imageBox"
          onClick={() => {
            setEst(link).then((result) => {
              dispatch(currentEstActionsAction(result));
            });
          }}
        >
          {addBusiness ? (
            <div className="addBusiness">
              <img src={ImgAddBusiness} alt="" />
            </div>
          ) : (
            <img src={img} alt="" />
          )}
        </div>
        <Typography variant="h5">{addBusiness ? 'Adicionar negócio' : title}</Typography>
      </Link>
    </S.CardCarousel>
  );
}

