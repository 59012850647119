import React, { useState } from 'react';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

function BlogModal({ isActiveModals, setPayload, closeModal, selectedModalInfos }) {
  const currentModal = ModelLPSignUp.modalsList.blog;
  const [selectedRadioValue, setSelectedRadioValue] = useState('last');

  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const handleSave = () => {
    setPayload((prevState) => {
      const modules = prevState?.campaignLandpage.modules;
      modules[selectedModalInfos.currentIndex] = {
        ...selectedModalInfos.modalInfos,
        post: selectedRadioValue,
      };
      return {
        campaignLandpage: {
          ...prevState?.campaignLandpage,
          modules,
        },
      };
    });

    toast.success('Blog salvo com sucesso!');
    closeModal(currentModal);
  };

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="last"
            name="radio-buttons-group"
            // value={selectedRadioValue}
            onChange={handleChange}
          >
            <FormControlLabel value="last" control={<Radio />} label="Post mais recente" />
            <Tooltip title="Opção em desenvolvimento">
              <FormControlLabel disabled value="id" control={<Radio />} label="Selecionar post" />
            </Tooltip>
          </RadioGroup>
        </FormControl>

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={() => closeModal(currentModal)}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default BlogModal;

