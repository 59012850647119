import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'styles/css/App.css';
import CodeInput from 'js/components/Login/CodeInput';
import SignIn from 'js/components/Login/SignIn';
import PhoneInput from 'js/components/Login/PhoneInput';
import NotPermission from 'js/components/ErrorPages/NotPermission';
import NotFound from 'js/components/ErrorPages/NotFound';
import Menu from 'js/components/Menu/Menu';
import { PopupContainer } from '../containers/Popup/PopupContainer';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#06BAD0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#383838',
    },
    warning: {
      main: '#FFC921',
    },
    default: {
      main: '#9a9a9a',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
  },
});

function App() {
  const [multipleWindowsOpened, setMultipleWindowsOpened] = useState(false);

  useEffect(() => {
    localStorage.setItem('open_page', Date.now());
    window.addEventListener('storage', (e) => {
      if (e.key === 'page_available' && window.location.href === e.url) {
        setMultipleWindowsOpened(true);
      }
      if (e.key === 'open_page' && window.location.href === e.url) {
        localStorage.setItem('page_available', Date.now());
        setMultipleWindowsOpened(false);
      }
    });

    window.addEventListener('unload', () => {
      localStorage.setItem('open_page', Date.now());
    });
  }, []);

  return multipleWindowsOpened ? (
    <PopupContainer
      title="Atenção!"
      description="Atualmente o Triibo web está aberto em mais de uma guia. Por favor feche uma das guias abertas."
    />
  ) : (
    <ThemeProvider theme={mainTheme}>
      <Switch>
        <Route exact path="/" component={PhoneInput} />

        <Route exact path="/login" component={CodeInput} />

        <Route exact path="/signIn" component={SignIn} />

        <Route path="/admin" component={Menu} />

        <Route path="/estabelecimento" component={Menu} />

        <Route path="/notpermission" component={NotPermission} />

        <Route path="*" component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
}

export default withRouter(App);

