import styled from "styled-components";

export const Container = styled.div`
  max-width: 1074px;
  max-height: 550px;
  width: 100%;
  background: #fff;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #f2f2f2;
  }
`;

export const Header = styled.header`
  text-align: center;
`;

export const ActionsTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  border-top: 1px solid #707070;
  padding-top: 1rem;
`;

export const ActionButtonTable = styled.button`
  width: 200px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;

  &:hover {
    h5 {
      font-weight: 600;
    }
  }
`;

export const BoxSearch = styled.div`
  max-width: 575px;
  width: 100%;
  margin: 0 auto;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 1rem;
  max-width: 400px;
  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  height: 39px;
  background: ${({ bg }) => bg || "#328796"};
  border-radius: 10px;
  border: ${({ border }) => (border ? `1px solid ${border}` : "none")};
  color: ${({ color }) => color || "#ffffff"};
  cursor: pointer;
  outline: none;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    background: ${({ bg }) => (!bg ? `#328796e5` : bg)};
    filter: ${({ bg }) => (!bg ? "none" : "brightness(0.9)")};
  }
`;
