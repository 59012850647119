import React, { useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import ModelBanner from './model';
import { toast } from 'react-toastify';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import { useParams } from 'react-router';

function BannerModal({ isActiveModals, closeModal, setPayload, selectedModalInfos }) {
  const [imgDesktop, setImgDesktop] = React.useState('');
  const [imgMobile, setImgMobile] = React.useState('');
  const [isActiveOverflow, setIsActiveOverflow] = React.useState(true);
  const [imagesLoading, setImagesLoading] = React.useState(false);
  const modalRef = useRef();

  const { id } = useParams();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    overflowY: isActiveOverflow ? 'scroll' : 'hidden',
  };

  const currentModal = ModelLPSignUp.modalsList.banner;

  const handleSave = async (partnerId) => {
    if (!imgDesktop || !imgMobile) {
      toast.error('Selecione as imagens!');
      return;
    }
    if (imgDesktop.includes('base64') || imgMobile.includes('base64')) {
      setImagesLoading(true);

      const [imgDesktopPath, imgMobilePath] = await Promise.all([
        imgDesktop.includes('base64') && ModelBanner.uploadBannerImg(imgDesktop, partnerId),
        imgMobile.includes('base64') && ModelBanner.uploadBannerImg(imgMobile, partnerId),
      ]);
      setImagesLoading(false);

      if (imgDesktopPath || imgMobilePath) {
        setPayload((prevState) => {
          const modules = prevState?.campaignLandpage.modules;
          modules[selectedModalInfos.currentIndex] = {
            ...selectedModalInfos.modalInfos,
            bannerDesktop: imgDesktopPath ? imgDesktopPath : imgDesktop,
            bannerMobile: imgMobilePath ? imgMobilePath : imgMobile,
          };

          return {
            campaignLandpage: {
              ...prevState?.campaignLandpage,
              modules,
            },
          };
        });
        toast.success('Imagens salvas com sucesso!');
        clearStates();
        closeModal(currentModal);
      }
    } else {
      clearStates();
      closeModal(currentModal);
    }
  };

  const setScrollPosition = (element, scroll) => {
    element.current.scrollTop = scroll;

    if (scroll === 0) setIsActiveOverflow(false);
    else setIsActiveOverflow(true);
  };

  const clearStates = () => {
    setImgDesktop('');
    setImgMobile('');
  };

  const handleCloseModal = () => {
    clearStates();
    closeModal(currentModal);
  };

  useEffect(() => {
    if (isActiveModals) {
      selectedModalInfos?.modalInfos?.bannerDesktop &&
        setImgDesktop(selectedModalInfos?.modalInfos?.bannerDesktop);
      selectedModalInfos?.modalInfos?.bannerMobile &&
        setImgMobile(selectedModalInfos?.modalInfos?.bannerMobile);
    }
  }, [isActiveModals, selectedModalInfos]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        ref={modalRef}
        sx={style}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          margin={'30px 0'}
          width={'100%'}
          columnGap={'30px'}
        >
          <Grid
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'18px'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              rowGap={'5px'}
              flexDirection={'column-reverse'}
            >
              <ImportImage
                imageDefault={null}
                autoWidth={true}
                image={imgDesktop}
                setImage={setImgDesktop}
                ratio={4.27}
                refScroll={modalRef}
                setIsActiveOverflow={setIsActiveOverflow}
                setScrollPosition={setScrollPosition}
              />
              <Typography paddingLeft={'3px'}>Desktop</Typography>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              rowGap={'5px'}
              flexDirection={'column-reverse'}
            >
              <ImportImage
                imageDefault={null}
                autoWidth={true}
                image={imgMobile}
                setImage={setImgMobile}
                ratio={2 / 3}
                refScroll={modalRef}
                setIsActiveOverflow={setIsActiveOverflow}
                setScrollPosition={setScrollPosition}
              />
              <Typography paddingLeft={'3px'}>Mobile</Typography>
            </Box>
          </Grid>
        </Box>

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          {imagesLoading ? (
            <Button variant="contained" color="primary">
              <CircularProgress style={{ color: '#fff' }} size={'30px'} />
            </Button>
          ) : (
            <>
              <Button variant="contained" color="primary" onClick={() => handleSave(id)}>
                salvar
              </Button>

              <Button variant="contained" color="primary" onClick={handleCloseModal}>
                cancelar
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default BannerModal;
