import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import 'react-responsive-ui/style.css';

import { withStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Chip,
  Paper,
  Button,
  FormLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Snackbar,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { Save, Delete, ExpandMore, ArrowBack } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';

import Loading from 'js/containers/Loading/Loading';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';

import { days } from 'js/library/utils/helpers';

import axios from 'axios';
import apiQRCode from 'js/library/utils/API/apiQRCode';
import { geocoding } from 'js/library/utils/API/apiGeocoding';

import { getUserInfo } from 'js/library/utils/helpers';
import QRCode from 'qrcode.react';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

let suggestions = [];

const app_screen_list = [
  { type: 'Home', value: 'screen_home' },
  { type: 'Carteira', value: 'screen_wallet' },
  { type: 'Extrato', value: 'screen_statement' },
  { type: 'Perfil', value: 'screen_profile' },
  { type: 'Notificações', value: 'screen_notifications' },
  { type: 'Chat', value: 'screen_chat' },
  { type: 'Comunidades', value: 'screen_recommended_orgs_list' },
];

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

class QRCodeInformation extends Component {
  constructor(props) {
    super(props);

    const uid = getUserInfo().uId;

    this.state = {
      oldTitle: props.location.state.title ? props.location.state.title : '',

      validatorId: 'qrvalidator@sms,triibo,com,br',

      title: props.location.state.title ? props.location.state.title : '',
      action: props.location.state.action ? props.location.state.action : 'add_triibo',
      data: props.location.state.data
        ? props.location.state.action === 'validate_promo'
          ? props.location.state.data.split('|')[0]
          : props.location.state.data
        : '',
      createDate: props.location.state.createDate ? props.location.state.createDate : Date.now(),
      dueDate: props.location.state.dueDate ? props.location.state.dueDate : Date.now() + 86400000,
      keyWordsSet: props.location.state.keyWordsSet ? props.location.state.keyWordsSet : [],
      lat: props.location.state.lat ? props.location.state.lat : 0.0,
      long: props.location.state.long ? props.location.state.long : 0.0,
      owner: props.location.state.owner ? props.location.state.owner : { name: { user: uid } },
      qrCodeId: props.location.state.qrCodeId
        ? props.location.state.qrCodeId
        : firebaseDatabase.ref('QRCodes').push().key,
      radius: props.location.state.radius ? props.location.state.radius : 200,
      availableHours: props.location.state.availableHours
        ? props.location.state.availableHours
        : [
            { type: '24h', shifts: [] },

            { type: '24h', shifts: [] },

            { type: '24h', shifts: [] },

            { type: '24h', shifts: [] },

            { type: '24h', shifts: [] },

            { type: '24h', shifts: [] },

            { type: '24h', shifts: [] },
          ],

      establishmentList: [],
      productList: [],
      promotionList: [],
      keywordsList: [],
      cardsList: [],

      useGeoInfo: false,
      keywords: '',

      loading: true,
      openModal: false,
      message: 'Erro ao tentar salvar informações.',

      active: props.location?.state?.active || false,
      autoCompleteInputValue: this.getValueName(),
      orgList: [],
    };

    //Baixa lista de estabelecimentos
    getDataListRestApi('Estabelecimento-v2').then((establishmentReceived) => {
      let establishmentList = [];

      if (establishmentReceived !== null && establishmentReceived !== undefined) {
        Object.keys(establishmentReceived).map(function (item) {
          // if(establishmentReceived[item].isPublished === true){
          establishmentReceived[item].key = item;
          return establishmentList.push(establishmentReceived[item]);
          // }else{
          // return null
          // }
        });
      } else {
        return null;
      }

      // this.setState({ establishmentList })

      //Baixa lista de promoções
      getDataListRestApi('Store').then((storeReceived) => {
        let productList = [];
        let promotionList = [];

        if (storeReceived !== null || storeReceived !== undefined) {
          Object.keys(storeReceived).map(function (item) {
            if (typeof storeReceived[item] === 'object') {
              storeReceived[item].key = item;

              if (
                storeReceived[item].type === 'promotion' ||
                storeReceived[item].type === 'businessPartner'
              )
                return promotionList.push(storeReceived[item]);

              if (storeReceived[item].type === 'product')
                return productList.push(storeReceived[item]);
            }
            return null;
          });
        } else {
          return null;
        }

        // this.setState({ promotionList, productList })

        //Baixa lista de keywords
        getDataListRestApi('Keywords', 'QrcodeInformation').then((keywordsReceived) => {
          let keywordsList = [];

          if (keywordsReceived !== null && keywordsReceived !== undefined) {
            Object.keys(keywordsReceived).map(function (item) {
              keywordsReceived[item].key = item;

              return keywordsList.push(keywordsReceived[item]);
            });
          } else {
            return null;
          }

          // this.setState({ keywordsList })

          //Baixa lista de cards
          getDataListRestApi('Cards').then((cardsReceived) => {
            let cardsList = [];

            if (cardsReceived !== null && cardsReceived !== undefined) {
              Object.keys(cardsReceived).map(function (item) {
                // if(cardsReceived[item].isPublished === true){
                cardsReceived[item].key = item;
                return cardsList.push(cardsReceived[item]);
                // }else{
                // return null
                // }
              });
            } else {
              return null;
            }

            establishmentList.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });

            keywordsList.sort((a, b) => {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              return 0;
            });

            cardsList.sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            });

            promotionList.sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            });

            productList.sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            });

            this.setState({
              establishmentList,
              keywordsList,
              cardsList,
              promotionList,
              productList,
              loading: false,
            });
          });
        });
      });
    });

    // Gera Link pro QRCode preview
    this.getLink(this.state.qrCodeId);

    window.pathname = null;
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    if (name === 'radius' && event.target.value < 0) {
      this.setState({
        openCheck: true,
        message: 'Valor não pode ser negativo.',
      });
    } else if (name === 'owner') {
      let aux = this.state.owner;
      aux.name.user = event.target.value;
      this.setState({ owner: aux });
    } else if (name === 'action') {
      // BUSCAR TODAS AS ORGS
      event.target.value === 'org_info' && this.getOrgsList();

      this.setState({
        [name]: event.target.value,
        data: '',
        autoCompleteInputValue: '',
      });
    } else if (name === 'cep' && event.target.value.length >= 8) {
      let newValue = event.target.value.replace(/[a-z]|[A-Z]|-/g, '');
      axios
        .get(`https://viacep.com.br/ws/${newValue}/json/`)
        .then((response) => {
          // handle success
          response = response.data;
          this.setState(
            {
              cep: newValue,
              logradouro: response.logradouro,
              bairro: response.bairro,
              cidade: response.localidade,
              estado: response.uf,
            },
            this.searchLatLong
          );
        })
        .catch((error) => {
          // handle error
        });
    } else if (name === 'logradouro' || name === 'numero' || name === 'bairro') {
      this.setState({ [name]: event.target.value }, this.searchLatLong);
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      goBack: true,
    });
  };

  handleCloseAlert = () => {
    this.setState({ openCheck: false });
  };

  //handle downshift
  handleChangeDownshift = (item) => {
    let { keyWordsSet } = this.state;

    if (keyWordsSet.indexOf(item) === -1) {
      keyWordsSet = [...keyWordsSet, item];
    }

    this.setState({
      keywords: '',
      keyWordsSet,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keyWordsSet = [...state.keyWordsSet];
      keyWordsSet.splice(keyWordsSet.indexOf(item), 1);
      return { keyWordsSet };
    });
  };
  //fim handle downshift

  handleSubmit = () => {
    const { data, action } = this.state;

    if (data === '') {
      this.setState({
        autoCompleteInputValue: '',
        openCheck: true,
        message:
          action === 'app_screen'
            ? 'Selecione a tela do app'
            : "Campo 'Pesquisar' deve ser preenchido.",
      });
      return;
    }

    this.setState({ loading: true });

    let qrCodeInfo = this.state;

    if (qrCodeInfo.action === 'validate_promo') {
      qrCodeInfo.data = `${qrCodeInfo.data}|${qrCodeInfo.validatorId}`;
    }

    if (qrCodeInfo.keyWordsSet.length < 1) {
      qrCodeInfo.keyWordsSet = undefined;
    }

    if (qrCodeInfo.useGeoInfo === false) {
      qrCodeInfo.lat = undefined;
      qrCodeInfo.long = undefined;
      qrCodeInfo.radius = undefined;
    }

    return apiQRCode(qrCodeInfo)
      .then((response) => {
        window.pathname = '/admin/configuracoes/qrcodes';
        return this.setState({
          openCheck: true,
          message: `Informação salva.`,
          loading: false,
        });
      })
      .catch((error) => {
        return this.setState({
          openCheck: true,
          message: 'Erro ao tentar salvar informação.',
          loading: false,
        });
      });
  };

  searchLatLong = () => {
    let address = encodeURIComponent(
      `${this.state.logradouro} ${this.state.numero} ${this.state.bairro} ${this.state.cidade} ${this.state.estado}`
    );

    geocoding(address)
      .then((result) => {
        if (result.status === 'OK' && result.results.length > 0) {
          this.setState({
            lat: result.results[0].geometry.location.lat,
            long: result.results[0].geometry.location.lng,
          });
        } else {
          this.setState({
            lat: 0,
            long: 0,
          });
        }
      })
      .catch((error) => {
        this.setState({ openCheck: true, loading: false, lat: 0, long: 0 });
      });
  };

  addShift(idx) {
    let newHour = this.state.availableHours;

    if (!newHour[idx].shifts) {
      newHour[idx].shifts = [];
    }

    newHour[idx].shifts.push({ starts: '00:00', ends: '23:59' });
    if (newHour[idx].type !== '24h') {
      newHour[idx].type = 'open';
    }
    this.setState({ availableHours: newHour });
  }

  changeShift = (idx, idx2, name) => (event) => {
    let changeHour = this.state.availableHours;

    changeHour[idx].shifts[idx2][name] = event.target.value;
    this.setState({ availableHours: changeHour });
  };

  deleteShift(idx, idx2) {
    let deleteHour = this.state.availableHours;

    deleteHour[idx].shifts.splice(idx2, 1);
    if (deleteHour[idx].shifts.length === 0 && deleteHour[idx].type !== '24h') {
      deleteHour[idx].type = 'closed';
    }
    this.setState({ availableHours: deleteHour });
  }

  changeShiftType = (idx) => (event) => {
    let changeHour = this.state.availableHours;
    if (event.target.checked === true) {
      changeHour[idx].type = '24h';
    } else {
      if (changeHour[idx].shifts && changeHour[idx].shifts.length > 0) {
        changeHour[idx].type = 'open';
      } else {
        changeHour[idx].type = 'closed';
      }
    }

    this.setState({ availableHours: changeHour });
  };

  getList = () => {
    switch (this.state.action) {
      case 'add_triibo':
        return this.state.keywordsList;

      case 'open_card':
        return this.state.cardsList;

      case 'establishment_info':
        return this.state.establishmentList;

      case 'validate_promo':
        return this.state.promotionList;

      case 'promotion_item':
        return this.state.promotionList;

      case 'product_info':
        return this.state.productList;
      case 'org_info':
        return this.state.orgList;
      case '':
        return this.state.productList;

      default:
        //Autocomplete espera um array
        return [];
    }
  };

  getOptionTitle = (option) => {
    switch (this.state.action) {
      case 'add_triibo':
        return option.value;

      case 'open_card':
        return `${option.title} (Keywords: ${option.keyWordsSet})`;

      case 'establishment_info':
        return option.nome ? option.nome : option.key;

      case 'validate_promo':
      case 'promotion_item':
        return `${option.title} (Estabelecimento: ${option.establishmentName})`;

      case 'product_info':
        return `${option.title} (Estabelecimento: ${option.establishmentName})`;
      case 'org_info':
        return `${option.name}`;
      default:
        return '';
    }
  };

  changeOption = (option) => {
    if (option !== null) {
      switch (this.state.action) {
        case 'add_triibo':
          this.setState({ data: option.value ? option.value : '' });
          break;
        case 'org_info':
          this.setState({ data: option?.orgID ? option?.orgID : '' });
          break;
        default:
          this.setState({ data: option.key ? option.key : '' });
      }
    } else {
      this.setState({ data: '' });
    }
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      goBack: true,
    });
  };

  getLink = (id) => {
    const dynamicLinkKey = 'AIzaSyDqzPWDKUb24RGQWGfLyNXsfBTukuwavRc';
    const dynamicLinkApi = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${dynamicLinkKey}`;
    const dynamicLinkDomain = 'mj8vs.app.goo.gl';
    const androidPackageName = 'br.com.sysmobil.triiboclient';
    const iosPackageName = 'br.com.triiboclient';
    const iosStoreId = '1118996431';
    const baseLink = 'https://triibo.com.br';
    const finalLink = `${baseLink}?qrCode=${id}`;

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain: dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName: androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    return new Promise(
      function async(resolve, reject) {
        axios.post(dynamicLinkApi, linkRequest).then((result) => {
          resolve(this.setState({ qrCodeShortLink: result.data.shortLink }));
          // resolve(result.data.shortLink)
        });
      }.bind(this)
    );
  };

  getValueName = () => {
    const action = this.props.location?.state?.action;
    const data = this.props.location?.state?.data;

    if (!action) {
      return '';
    }

    if (action === 'add_triibo' || action === 'webview' || action === 'browser') {
      return action;
    }

    switch (action) {
      case 'open_card':
        this.getCardsList(data);
        break;
      case 'promotion_item':
        this.getPromotionList(data);
        break;
      case 'product_info':
        this.getPromotionList(data);
        break;
      case 'validate_promo':
        this.getPromotionList(data.split('|')[0]);
        break;
      case 'establishment_info':
        this.getEstablishmentList(data);
        break;
      case 'org_info':
        this.getOrgsList(data);
        break;
      default:
        break;
    }
  };

  getOrgsList = (id = null) => {
    if (this.state?.orgList) {
      const { orgList } = this.state;

      if (orgList.length > 0) {
        return;
      }
    }

    getDataListRestApi('Organizations')
      .then((resp) => {
        let list = [];

        Object.keys(resp).map((item) => {
          return list.push(resp[item]);
        });

        if (id) {
          let title = '';

          list.forEach((org) => {
            if (org.orgID === id) {
              title = org.name;
            }
          });

          this.setState({ autoCompleteInputValue: title });

          return;
        }

        this.setState({
          orgList: list,
        });
      })
      .catch(() => {
        console.log('***Erro ao buscar as orgs...');
        this.setState({ autoCompleteInputValue: '' });
      });
  };

  getCardsList = (id) => {
    getDataListRestApi('Cards')
      .then((cardsReceived) => {
        let cardsList = [];

        if (cardsReceived !== null && cardsReceived !== undefined) {
          Object.keys(cardsReceived).map(function (item) {
            cardsReceived[item].key = item;
            return cardsList.push(cardsReceived[item]);
          });
        } else {
          return null;
        }

        let title = '';

        cardsList.forEach((card) => {
          if (card.key === id) {
            title = `${card.title} (Keywords: ${card.keyWordsSet})`;
          }
        });

        this.setState({ autoCompleteInputValue: title });
      })
      .catch(() => {
        console.log('Algo saiu errado...');
        this.setState({ autoCompleteInputValue: '' });
      });
  };

  getPromotionList = (id) => {
    const action = this.props.location?.state?.action;

    getDataListRestApi('Store')
      .then((storeReceived) => {
        let productList = [];
        let promotionList = [];

        if (storeReceived !== null || storeReceived !== undefined) {
          Object.keys(storeReceived).map(function (item) {
            if (typeof storeReceived[item] === 'object') {
              storeReceived[item].key = item;

              if (
                storeReceived[item].type === 'promotion' ||
                storeReceived[item].type === 'businessPartner'
              )
                return promotionList.push(storeReceived[item]);

              if (storeReceived[item].type === 'product')
                return productList.push(storeReceived[item]);
            }
            return null;
          });
        } else {
          return null;
        }

        let list = [];

        if (action === 'promotion_item' || action === 'validate_promo') {
          list = promotionList;
        } else {
          list = productList;
        }

        let title = '';

        list.forEach((item) => {
          if (item?.key === id) {
            title = `${item.title} (Estabelecimento: ${item.establishmentName})`;
          }
        });

        this.setState({ autoCompleteInputValue: title });
      })
      .catch(() => {
        console.log('Algo saiu errado...');
        this.setState({ autoCompleteInputValue: '' });
      });
  };

  getEstablishmentList = (id) => {
    getDataListRestApi('Estabelecimento-v2')
      .then((establishmentReceived) => {
        let establishmentList = [];

        if (establishmentReceived !== null && establishmentReceived !== undefined) {
          Object.keys(establishmentReceived).map(function (item) {
            establishmentReceived[item].key = item;
            return establishmentList.push(establishmentReceived[item]);
          });
        } else {
          return null;
        }

        let title = '';

        establishmentList.forEach((establishment) => {
          if (establishment?.key === id) {
            title = establishment.nome;
          }
        });

        this.setState({ autoCompleteInputValue: title });
      })
      .catch(() => {
        console.log('Algo saiu errado...');
        this.setState({ autoCompleteInputValue: '' });
      });
  };

  componentDidMount = () => {
    const action = this.props.location?.state?.action;

    if (action === 'org_info') {
      this.getOrgsList();
    }

    getDataListRestApi('Keywords', 'QrcodeInformation')
      .then((dataReceived) => {
        let keywordsDownload = [];

        Object.values(dataReceived).map(function (item) {
          return keywordsDownload.push({ label: item.value });
        });

        suggestions = keywordsDownload;
      })
      .catch((error) => {});
  };

  render = () => {
    const { classes } = this.props;
    const keywords = this.state.keywords;
    const selectedItem = this.state.keyWordsSet;

    if (window.pathname !== null) {
      return (
        <Redirect
          to={{
            pathname: window.pathname,
            state: this.props.location.state,
          }}
        />
      );
    } else {
      if (this.state.loading) {
        return (
          <div className="loading">
            <Loading />
          </div>
        );
      } else {
        return (
          <div style={{ padding: '4rem 32px', maxWidth: '100%' }}>
            <HeaderTitle pageInfo={this.state.qrCodeId ? `Editando QRCode` : 'Criar QRCode'} />
            <br />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSubmit();
              }}
            >
              <TextField
                value={this.state.title}
                required
                onChange={this.handleChange('title')}
                className="input-field"
                type="text"
                id="description"
                label="Descrição"
                style={{ marginBottom: '1em' }}
              />

              <FormLabel component="legend">Ação</FormLabel>
              <FormControl className="input-field">
                <Select
                  style={{ minWidth: '300px' }}
                  value={this.state.action}
                  onChange={this.handleChange('action')}
                  inputProps={{ id: 'action' }}
                >
                  <MenuItem value="add_triibo">Adicionar keyword</MenuItem>
                  <MenuItem value="open_card">Abrir card</MenuItem>
                  <MenuItem value="promotion_item">Abrir promoção</MenuItem>
                  <MenuItem value="validate_promo">Consumir promoção</MenuItem>
                  <MenuItem value="product_info">Abrir produto</MenuItem>
                  <MenuItem value="establishment_info">Abrir estabelecimento</MenuItem>
                  <MenuItem value="webview">Abrir URL na webview</MenuItem>
                  <MenuItem value="browser">Abrir URL no navegador</MenuItem>
                  <MenuItem value="org_info">Informação de Organização</MenuItem>
                  <MenuItem value="app_screen">Navegação no App</MenuItem>
                </Select>

                {this.state?.action === 'app_screen' && (
                  <div
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      marginBottom: '10px',
                    }}
                  >
                    <FormLabel component="legend">Selecione a tela do app</FormLabel>
                    <Select
                      style={{ minWidth: '300px', width: '100%' }}
                      value={this.state.data}
                      onChange={(e) => this.setState({ data: e.target.value })}
                      inputProps={{ id: 'list_screen' }}
                      required
                    >
                      {app_screen_list.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                {this.state?.action !== 'app_screen' && (
                  <Autocomplete
                    id="Busca"
                    options={this.getList()}
                    inputValue={this.state.autoCompleteInputValue}
                    getOptionLabel={(option) => this.getOptionTitle(option)}
                    style={{ width: '100%', marginTop: '15px' }}
                    onChange={(event, option) => this.changeOption(option)}
                    onInputChange={(_, value) => {
                      if (value !== '') {
                        this.setState({ autoCompleteInputValue: value });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} required label="Pesquisar" />}
                    disabled={this.state.action === 'webview' || this.state.action === 'browser'}
                  />
                )}

                {(this.state.action === 'webview' || this.state.action === 'browser') && (
                  <TextField
                    value={this.state.data}
                    required
                    onChange={this.handleChange('data')}
                    className="input-field"
                    type="text"
                    id="data"
                    label={
                      this.state.action === 'webview' || this.state.action === 'browser'
                        ? 'URL'
                        : 'ID'
                    }
                  />
                )}

                <Downshift
                  id="downshift-multiple"
                  inputValue={keywords}
                  onChange={this.handleChangeDownshift}
                  selectedItem={selectedItem}
                  style={{ width: '100%' }}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue: inputValue2,
                    selectedItem: selectedItem2,
                    highlightedIndex,
                  }) => (
                    <div className={classes.container} style={{ marginTop: '2vh', width: '100%' }}>
                      {selectedItem &&
                        renderInput({
                          fullWidth: true,
                          classes,
                          InputProps: getInputProps({
                            startAdornment: selectedItem.map((item) => (
                              <Chip
                                key={item}
                                tabIndex={-1}
                                label={item}
                                className={classes.chip}
                                onDelete={this.handleDeleteDownshift(item)}
                              />
                            )),
                            onChange: this.handleChange('keywords'),
                            onKeyDown: this.handleKeyDown,
                            placeholder: 'Selecione a keyword na lista abaixo',
                          }),
                          label: 'Keywords de limitação do QRCode',
                        })}
                      {isOpen ? (
                        <Paper className={classes.paper} square>
                          {getSuggestions(inputValue2).map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({
                                item: suggestion.label,
                              }),
                              highlightedIndex,
                              selectedItem: selectedItem2,
                            })
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  )}
                </Downshift>

                <FormControlLabel
                  style={{ marginTop: '5em' }}
                  control={
                    <Switch
                      checked={this.state.useGeoInfo}
                      onChange={this.handleChangeSwitch('useGeoInfo')}
                      color="primary"
                    />
                  }
                  label={'Limitar uso por localização?'}
                />

                <div>
                  <TextField
                    className="input-field"
                    type="text"
                    inputProps={{ maxLength: 8 }}
                    // value={this.state.localizacao ? this.state.localizacao.cep  : "" }
                    value={this.state.cep ? this.state.cep : ''}
                    id="endereco"
                    label="CEP do Estabelecimento"
                    placeholder="Ex.: 04024020"
                    onChange={this.handleChange('cep')}
                    required={this.state.useGeoInfo}
                    disabled={!this.state.useGeoInfo}
                  />
                </div>

                <Grid container spacing={2} alignItems="stretch">
                  <Grid item sm={12} md={4}>
                    <TextField
                      className="input-field"
                      type="text"
                      value={this.state.logradouro ? this.state.logradouro : ''}
                      id="logradouro"
                      label="Logradouro"
                      placeholder="Ex.: Rua Guapiaçu"
                      onChange={this.handleChange('logradouro')}
                      required={this.state.useGeoInfo}
                      disabled={!this.state.useGeoInfo}
                    />
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <TextField
                      className="input-field"
                      type="number"
                      value={this.state.numero ? this.state.numero : ''}
                      id="numero"
                      label="Número"
                      placeholder="Ex.: nº 5"
                      onChange={this.handleChange('numero')}
                      required={this.state.useGeoInfo}
                      disabled={!this.state.useGeoInfo}
                    />
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <TextField
                      className="input-field"
                      type="text"
                      value={this.state.complemento ? this.state.complemento : ''}
                      id="complemento"
                      label="Complemento"
                      placeholder="Ex.: apto. 15"
                      onChange={this.handleChange('complemento')}
                      disabled={!this.state.useGeoInfo}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="stretch">
                  <Grid item sm={12} md={4}>
                    <TextField
                      className="input-field"
                      type="text"
                      value={this.state.bairro ? this.state.bairro : ''}
                      id="bairro"
                      label="Bairro"
                      placeholder="Ex.: Vila da Saúde"
                      onChange={this.handleChange('bairro')}
                      required={this.state.useGeoInfo}
                      disabled={!this.state.useGeoInfo}
                    />
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <TextField
                      className="input-field"
                      type="text"
                      value={this.state.cidade ? this.state.cidade : ''}
                      id="cidade"
                      label="Cidade"
                      placeholder="Ex.: São Paulo"
                      onChange={this.handleChange('cidade')}
                      required={this.state.useGeoInfo}
                      disabled={!this.state.useGeoInfo}
                    />
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <TextField
                      className="input-field"
                      inputProps={{ maxLength: 2 }}
                      type="text"
                      value={this.state.estado ? this.state.estado : ''}
                      id="estado"
                      label="UF"
                      placeholder="Ex.: SP"
                      onChange={this.handleChange('estado')}
                      required={this.state.useGeoInfo}
                      disabled={!this.state.useGeoInfo}
                    />
                  </Grid>
                </Grid>

                {/* <TextField value={ this.state.lat } required={this.state.useGeoInfo} disabled={!this.state.useGeoInfo} onChange = { this.handleChange('lat') }  className = "input-field" type = "number" id="lat"
					label = "Latitude" />

					<TextField value={ this.state.long } required={this.state.useGeoInfo} disabled={!this.state.useGeoInfo} onChange = { this.handleChange('long') }  className = "input-field" type = "number" id="long"
					label = "Longitude" /> */}

                <TextField
                  value={this.state.radius}
                  required={this.state.useGeoInfo}
                  disabled={!this.state.useGeoInfo}
                  onChange={this.handleChange('radius')}
                  className="input-field"
                  type="number"
                  id="radius"
                  label="Raio de utilização em metros"
                />

                <div style={{ marginTop: '5em' }}>
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid item sm={12} md={6}>
                      {this.state.availableHours.map((item, i) => {
                        return (
                          <Accordion elevation={0} style={{ borderBottom: '2px solid #0000001f' }}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              IconButtonProps={{ edge: 'start' }}
                            >
                              <Typography>{days[i]}</Typography>

                              <Typography
                                style={{
                                  textAlign: 'right',
                                  width: '100%',
                                  marginRight: '1.0em',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {item.type}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <div>
                                {item.shifts && item.shifts.length > 0 && item.type !== '24h' && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      marginBottom: '1.5em',
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      gutterBottom
                                      color="secondary"
                                      style={{ fontWeight: '550' }}
                                    >
                                      Início do Turno
                                    </Typography>

                                    <Typography
                                      variant="subtitle2"
                                      gutterBottom
                                      color="secondary"
                                      style={{
                                        fontWeight: '550',
                                        marginLeft: '29px',
                                      }}
                                    >
                                      Fim do Turno
                                    </Typography>
                                  </div>
                                )}

                                {item.shifts && item.type !== '24h'
                                  ? item.shifts.map((item2, i2) => {
                                      return (
                                        <div
                                          style={{
                                            display: 'block',
                                            marginBottom: '1.5em',
                                          }}
                                        >
                                          <TextField
                                            id="time"
                                            // label="Início do Turno"
                                            type="time"
                                            style={{ marginRight: '55px' }}
                                            value={item2.starts}
                                            onChange={this.changeShift(i, i2, 'starts')}
                                            className={classes.textField}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />

                                          <TextField
                                            id="time"
                                            type="time"
                                            // style={{width: '195px', marginLeft: '1.5em'}}
                                            value={item2.ends}
                                            onChange={this.changeShift(i, i2, 'ends')}
                                            className={classes.textField}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                              this.deleteShift(i, i2);
                                            }}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </div>
                                      );
                                    })
                                  : ''}

                                {item.type !== '24h' && (
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      color: 'blue',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                      this.addShift(i);
                                    }}
                                  >
                                    Adicionar horário de funcionamento
                                    <br />
                                    <br />
                                  </span>
                                )}
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={this.changeShiftType(i)}
                                      checked={item.type === '24h'}
                                      color="primary"
                                    />
                                  }
                                  label={'Aberto 24 horas'}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </Grid>

                    <Grid item sm={12} md={6}>
                      {this.state.qrCodeShortLink ? (
                        <QRCode
                          id="qrcode-image"
                          size={1024}
                          style={{
                            display: 'block',
                            maxWidth: '300px',
                            maxHeight: '300px',
                            height: 'auto',
                            margin: '2em auto',
                          }}
                          level="L"
                          value={this.state.qrCodeShortLink}
                        />
                      ) : (
                        <Loading />
                      )}
                    </Grid>
                  </Grid>
                </div>

                <FormControlLabel
                  style={{ marginTop: '10px' }}
                  control={
                    <Switch
                      checked={this.state.active}
                      onChange={this.handleChangeSwitch('active')}
                      color="primary"
                    />
                  }
                  label={'Ativo'}
                />
              </FormControl>

              <Button
                onClick={() => window.history.back()}
                style={{
                  fontWeight: '550',
                  marginTop: '20px',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>

              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '20px',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
              >
                Salvar QRCode
              </Button>
            </form>

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openCheck}
              onClose={() => this.setState({ openCheck: false })}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.message}</span>}
            />
          </div>
        );
      }
    }
  };
}

export default withStyles(styles)(QRCodeInformation);

