import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function getUsersPartnerWhitelist(partnerId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') +
            `/partnerWhitelist/getUsersPartnerWhitelist?partnerId=${partnerId}`,
          null,
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO DA WHITE LIST ==>", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // console.log("ERROR DA WHITELIST ==>", error);
          return reject(error);
        });
    });
  });
}

export function inactiveUserPartnerWhitelist(partnerId, userPartnerId, updateInfo) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .put(
          cfac22('API_HOST_V2') + '/partnerWhitelist/inactiveUserPartnerWhitelist',
          {
            partnerId,
            userPartnerId,
            updateInfo,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO DA WHITE LIST ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR DA WHITELIST ==>", error);
          return reject(error);
        });
    });
  });
}

export function deleteUserPartnerWhitelist(partnerId, userPartnerId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .delete(cfac22('API_HOST_V2') + '/partnerWhitelist/deleteUserPartnerWhitelist', {
          data: {
            partnerId,
            userPartnerId,
          },
          headers: { authorization: `Bearer ${token}` },
        })
        .then((result) => {
          // console.log("RESULTADO DA WHITE LIST ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR DA WHITELIST ==>", error);
          return reject(error);
        });
    });
  });
}
