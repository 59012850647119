import styled from "styled-components/macro";
import * as D from "js/components/DesignSystem/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 0;
  /* padding: 2rem 0rem; */
`;

export const DragSpan = styled.span`
  color: white;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 50%;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgb(0 0 0 / 9%);
  border-radius: 5px;

  ${({ isDragging }) =>
    isDragging &&
    `
        background: lightgrey;
    `}

  transition: 0.3s ease-in-out;
`;

export const DragItem = styled.span`
  width: 100%;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: flex-start;
  user-select: "none";
  padding: 1.2rem;
  margin: 0 0 8 0;
  background: ${D.triiboBlue};
  border-radius: 5px;
  border: 0;
  box-shadow: 2px 4px 15px rgb(0 0 0 / 9%);
  margin: 0.8rem 0;
  gap: 1rem;

  ${({ isDragging }) =>
    isDragging &&
    `
        background: ${D.triiboPurple};
    `}

  ${({ isBanner }) =>
    isBanner &&
    `
        background: ${D.triiboGray};
    `}

  transition: 0.3s ease-in-out;
`;

export const DragItemButton = styled(D.TriiboFilledButton)`
  align-self: self-end;
`;

export const DraggableListItem = styled.span`
  border-radius: 10px;
  background-color: ${D.triiboBlue};
`;

export const TableAndUntrackContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const TableListUntrack = styled.li`
  width: 50%;
  list-style: none;
  max-height: 700px;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #f2f2f2;
  }
`;

export const ListItem = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  background: #f2f2f2;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  margin-bottom: 6px;

  h5 {
    padding-left: 1rem;
  }
`;

export const Actions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ExcludedColumnButtons = styled.button`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  min-height: 2rem;
  border: none;
  border-radius: 5px;
  background: #6e3296;
  cursor: pointer;
  color: #fff;
`;

export const EditButton = styled(ExcludedColumnButtons)`
  background: #6e3296;
`;

export const DeleteButton = styled(ExcludedColumnButtons)`
  background: #f13a3a;
`;
