import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Modal,
  TextField,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  List,
  CircularProgress,
} from '@mui/material';

import * as S from '../styles';

import keyIcon from '../../../../../styles/assets/SearchUser/key.svg';
import { BiCircle } from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import DeleteIcon from '@mui/icons-material/Delete';

import { GetKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';
import { setUserInfo_v1 } from 'js/library/utils/API/setUserInfo_v1';

export default function AddKeywords({
  isModalAddKeywordsOpen,
  handleCloseModal,
  userv3,
  setUserv3,
}) {
  const [allKeywords, setAllKeywords] = useState([]);
  const [keyLimitationCheck, setKeyLimitationCheck] = useState(true);
  const [keyCustomizationCheck, setKeyCustomizationCheck] = useState(true);
  const [inputSearchKeyword, setInputSearchKeyword] = useState('');
  const [keyWordsSetLimit, setKeyWordsSetLimit] = useState(userv3.keyWordsSetLimit ?? []);
  const [keyWordsSetCustom, setKeyWordsSetCustom] = useState(userv3.keyWordsSetCustom ?? []);
  const [keywordsSelected, setKeywordsSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const resGetKeywords = async () => {
      const result = await GetKeywords().then((data) => {
        setAllKeywords(data);
      });
      return result;
    };

    resGetKeywords();
  }, []);

  const filteredKeywordsSuggestions = inputSearchKeyword
    ? allKeywords.filter((key) => {
        return key.value.toLowerCase().includes(inputSearchKeyword.toLowerCase());
      })
    : [];

  const handleKeywords = (keyCustom, keyLimit) => {
    setKeyWordsSetCustom(keyCustom);
    setKeyWordsSetLimit(keyLimit);
  };

  const handleKeywordsListActions = (value, type, mode) => {
    setLoading(true);
    const uId = userv3?.uId;

    let userInfo = {
      updateDate: new Date().getTime(),
      syncType: 'override',
      uId: uId,
    };

    let keywordsCustom = keyWordsSetCustom ? [...keyWordsSetCustom] : [];

    let keywordsLimit = keyWordsSetLimit ? [...keyWordsSetLimit] : [];

    if (mode === 'add') {
      if (keyCustomizationCheck === true) {
        value.map(function (keyword) {
          let validation = 0;
          keywordsCustom.map(
            (item) => (validation = item === keyword.value ? validation + 1 : validation)
          );

          if (validation === 0) {
            return keywordsCustom.push(keyword.value);
          } else {
            return null;
          }
        });

        userInfo['keyWordsSetCustom'] = keywordsCustom;
      }

      if (keyLimitationCheck === true) {
        value.map(function (keyword) {
          let validation = 0;
          keywordsLimit.map(
            (item) => (validation = item === keyword.value ? validation + 1 : validation)
          );

          if (validation === 0) {
            return keywordsLimit.push(keyword.value);
          } else {
            return null;
          }
        });

        userInfo['keyWordsSetLimit'] = keywordsLimit;
      }
    }

    if (type === 'RemoveKeyCustomization') {
      keywordsCustom.splice(value, 1);
      userInfo['keyWordsSetCustom'] = keywordsCustom;
    }

    if (type === 'RemoveKeyLimitation') {
      keywordsLimit.splice(value, 1);
      userInfo['keyWordsSetLimit'] = keywordsLimit;
    }

    setUserInfo_v1(uId, ['setUserInfo'], userInfo).then((result) => {
      setLoading(false);
      setUserv3(result);
      handleKeywords(result.keyWordsSetCustom, result.keyWordsSetLimit);
      // setKeyCustomizationCheck(false); //comentado para manter os campos como estão
      // setKeyLimitationCheck(false); //comentado para manter os campos como estão
      setInputSearchKeyword('');
      setKeywordsSelected([]);

      setUserInfo_v1(uId, ['setUserInfo'], {
        updateDate: new Date().getTime(),
        syncType: 'merge',
        uId: uId,
      });
    });
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalAddKeywords')}
      open={isModalAddKeywordsOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.ModalBox>
        <div className="header">
          <img src={keyIcon} alt="" />
          <Typography variant="h2" component="h2" id="modal-title">
            Cadastro de Keywords
          </Typography>
        </div>
        <S.AddKeywordsModalBox>
          <div className="inputSearchBox">
            <Typography variant="h4" component="h4" id="modal-description">
              Selecione a Keyword na lista abaixo.
            </Typography>
            <div className="inputSearch">
              <Autocomplete
                className="input"
                multiple
                id="tags-outlined"
                options={filteredKeywordsSuggestions}
                getOptionLabel={(option) => option.value}
                value={keywordsSelected}
                onChange={(event, value) => setKeywordsSelected(value)}
                filterSelectedOptions
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.id}>
                      {option.value}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    value={inputSearchKeyword}
                    onChange={(e) => setInputSearchKeyword(e.target.value)}
                  />
                )}
              />
              <Button
                className="submitButton"
                variant="contained"
                size="medium"
                disabled={loading || keywordsSelected.length <= 0}
                onClick={() => handleKeywordsListActions(keywordsSelected, null, 'add')}
              >
                {loading && <CircularProgress style={{ marginRight: '0.5rem' }} size="23px" />}
                Adicionar
              </Button>
            </div>
            <FormGroup sx={{ flexDirection: 'row' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keyLimitationCheck === true ? true : false}
                    onChange={(e) => setKeyLimitationCheck(e.target.checked)}
                    icon={<BiCircle fontSize="25px" />}
                    checkedIcon={<BsFillCheckCircleFill fontSize="25px" color="#5A287C" />}
                  />
                }
                label="Limitação"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keyCustomizationCheck === true ? true : false}
                    onChange={(e) => setKeyCustomizationCheck(e.target.checked)}
                    icon={<BiCircle fontSize="25px" />}
                    checkedIcon={<BsFillCheckCircleFill fontSize="25px" color="#5A287C" />}
                  />
                }
                label="Customização"
              />
            </FormGroup>
          </div>

          <div
            className="linkedKeywordsLists"
            style={{ display: 'flex', width: '100%', gap: '1rem' }}
          >
            <Box
              sx={{
                maxWidth: 360,
                width: '100%',
                maxHeight: 200,
                height: '100%',
              }}
            >
              <Typography variant="h6" component="h6" className="titles">
                Limitação:
              </Typography>
              {keyWordsSetLimit ? (
                <List
                  sx={{
                    width: 230,
                    maxHeight: 195,
                    height: '100%',
                    overflow: 'auto',
                    border: '1px solid #333',
                    bgcolor: 'background.paper',
                    borderRadius: '5px',
                    padding: '0 0.3rem',
                  }}
                  className="list"
                >
                  {keyWordsSetLimit.map((item, index) => (
                    <ListItem
                      className="listItem"
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleKeywordsListActions(index, 'RemoveKeyLimitation', 'rem')
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="h6" component="h6" className="messageNoKey">
                  Nenhuma keyword cadastrada.
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                maxWidth: 360,
                width: '100%',
                maxHeight: 200,
                height: '100%',
              }}
            >
              <Typography variant="h6" component="h6" className="titles">
                Customização:
              </Typography>
              {keyWordsSetCustom ? (
                <List
                  sx={{
                    width: 230,
                    maxHeight: 195,
                    height: '100%',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    border: '1px solid #333',
                    borderRadius: '5px',
                    padding: '0 0.3rem',
                  }}
                  className="list"
                >
                  {keyWordsSetCustom.map((item, index) => (
                    <ListItem
                      className="listItem"
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleKeywordsListActions(index, 'RemoveKeyCustomization', 'rem')
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="h6" component="h6" className="messageNoKey">
                  Nenhuma keyword cadastrada.
                </Typography>
              )}
            </Box>
          </div>

          <Button
            variant="contained"
            style={{
              marginTop: '2rem',
              maxWidth: '164px',
              margin: '2rem auto 0',
              width: '100%',
              background: '#DBDBDB',
              color: '#555555',
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
            onClick={() => handleCloseModal('closeModalAddKeywords')}
          >
            Voltar
          </Button>
        </S.AddKeywordsModalBox>
      </S.ModalBox>
    </Modal>
  );
}

