import * as React from "react"

const IconClub = (props) => (
  <svg
    id="Camada_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <path className="cls-3" d="M18 30h64v46H18z" />
      </clipPath>
      <style>{".cls-1{fill:#6e3296}.cls-3{fill:none}"}</style>
    </defs>
    <g id="Camada_1-2">
      <path className="cls-3" d="M0 0h100v100H0z" id="Ret\xE2ngulo_1518" />
      <g id="Elipse_565">
        <circle className="cls-3" cx={50} cy={50} r={50} />
        <path
          className="cls-1"
          d="M50 100C22.43 100 0 77.57 0 50S22.43 0 50 0s50 22.43 50 50-22.43 50-50 50Zm0-92C26.84 8 8 26.84 8 50s18.84 42 42 42 42-18.84 42-42S73.16 8 50 8Z"
        />
      </g>
      <g
        style={{
          clipPath: "url(#clippath)",
        }}
        id="Grupo_12336"
      >
        <g id="Grupo_12333">
          <path
            id="Caminho_16202"
            className="cls-1"
            d="M68.27 59.75a2.996 2.996 0 0 0-2.95 3.05 2.996 2.996 0 0 0 3.05 2.95c1.65-.03 2.98-1.39 2.95-3.04a2.981 2.981 0 0 0-3-2.96h-.05"
          />
          <path
            id="Caminho_16203"
            className="cls-1"
            d="M80.35 30H19.64c-.89-.01-1.63.7-1.64 1.59v2.8c.01.89.75 1.61 1.64 1.6h10.17v2.56h-1.49c-1.1 0-2 .9-2 2v6.06c.01.07.02.13.04.2A15.745 15.745 0 0 0 18.99 60c.28 9.11 7.89 16.27 17 16h28c9.11.27 16.71-6.89 17-16-.05-5.43-2.9-10.45-7.53-13.29 0-.04.02-.06.02-.1v-6.06c0-1.1-.9-2-2-2h-1.5v-2.56h10.37c.89.01 1.63-.7 1.65-1.59v-2.8c0-.89-.74-1.61-1.64-1.6h-.01M30.32 42.56h2.98v1.66c-1.01.15-2.01.39-2.98.71v-2.38Zm4.34 23.04c1.61.02 3.13-.7 4.14-1.96l2.12 1.52a8.114 8.114 0 0 1-6.32 2.91c-4.37.09-7.98-3.37-8.09-7.74.1-4.37 3.71-7.84 8.09-7.76a8.134 8.134 0 0 1 6.33 2.91L38.81 57c-1.82-2.29-5.15-2.68-7.44-.86-2.29 1.82-2.68 5.15-.86 7.44a5.314 5.314 0 0 0 4.16 2.01m11.16 2.22h-2.68V52.13h2.68v15.68Zm13.4 0h-2.68v-1.59a3.81 3.81 0 0 1-3.35 1.85 4.13 4.13 0 0 1-4.36-3.89c-.02-.28 0-.56.04-.83v-5.7h2.68v5.59c-.13 1.23.75 2.33 1.98 2.47.09 0 .17.01.26.01 1.4.12 2.62-.92 2.74-2.31 0-.11.01-.21 0-.32v-5.43h2.68v10.15Zm9.45.26c-1.38.04-2.7-.56-3.58-1.63v1.37h-2.68V52.13h2.68V59a4.405 4.405 0 0 1 3.6-1.6c2.95 0 5.33 2.4 5.33 5.35 0 2.95-2.4 5.33-5.35 5.33m-2.17-23.89v-1.64h2.98v2.31c-.97-.31-1.97-.54-2.98-.68m-.52-5.64h-1.48c-1.1 0-2 .9-2 2v3.44h-25.2v-3.44c0-1.1-.9-2-2-2h-1.49v-2.56h32.17v2.56Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default IconClub;
