import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';
import { MdModeEdit } from 'react-icons/md';
import InputMask from 'react-input-mask';

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0;
    padding: 2rem 1rem;

    &::before {
      z-index: -2;
      position: absolute;
      width: 100%;
      height: 100%;
      content: ' ';
      background: white;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);

      ${({ stepTwo }) =>
        stepTwo &&
        `
          border: 1px solid ${D.triiboBlue};
          background: ${D.triiboBlue};
        
    `}
    }

    &::after {
      z-index: -1;
      content: ' ';
      position: absolute;
      width: 50%;
      height: 100%;
      border-radius: 10px 0 0 10px;
      border: 1px solid ${D.triiboGradient};
      background: ${D.triiboPurple};
      background: linear-gradient(0deg, ${D.triiboGradient} 0%, ${D.triiboPurple} 100%);

      ${({ stepTwo }) =>
        stepTwo &&
        `
          border: 1px solid rgba(0, 0, 0, 0.20);
          background: white;
        
    `}
    }
  }
`;

export const SideContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (min-width: 900px) {
    &::before {
      z-index: -2;
      position: absolute;
      width: 100%;
      height: 100%;
      content: ' ';
      background: white;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);
    }

    &::after {
      z-index: -1;
      content: ' ';
      position: absolute;
      width: 100%;
      height: 44%;
      border-radius: 10px 10px 0 0;
      border: 1px solid ${D.triiboBlue};
      background: ${D.triiboBlue};
    }
  }
`;

export const AdminContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);
  background: ${D.triiboBlue};
  border-radius: 10px;

  @media (min-width: 900px) {
    width: 50%;
  }
`;

export const BarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: transparent;
  width: 100%;
  min-height: 5rem;
  padding: 0rem 1rem;

  @media (min-width: 900px) {
    padding: 0;
    justify-content: space-between;
  }
`;

export const BarsSeparators = styled.span`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: transparent;
  width: 20%;
  height: 5rem;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;

  @media (min-width: 900px) {
    align-items: flex-start;
    width: 30%;
    gap: 1rem;
  }
`;

export const StepsName = styled.span`
  display: flex;
  font-size: 15px;
  color: ${D.triiboGray};
  transition: all 1s ease-in-out;

  @media (min-width: 900px) {
    font-size: 15px;
  }

  ${({ active }) =>
    !active &&
    `
        color: ${D.triiboGray};
    `}

  ${({ active }) =>
    active &&
    `
        color: ${D.triiboBlack};
    `}
`;

export const StepsNameDisplay = styled.span`
  display: none;

  @media (min-width: 900px) {
    display: block;
  }
`;

export const FormElement = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.6rem;
  width: 100%;
`;

export const FormLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  color: white;

  ${({ black }) =>
    black &&
    `
      color: ${D.triiboBlack}
    `}
`;

export const Logradouro = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  width: 100%;

  @media (min-width: 900px) {
    gap: 1.8rem;
  }
`;

export const Icon = styled(MdModeEdit)`
  color: #fff;

  ${({ $purple }) =>
    $purple &&
    `
      color: #6e3296;
  `}

  @media (max-width: 900px) {
    color: #6e3296;
    margin-top: 0.4rem;
  }
`;

export const MoveIconMobile = styled.div`
  display: block;

  @media (max-width: 900px) {
    position: absolute;
    right: 1.3rem;

    ${({ right }) =>
      right &&
      `
      right: 0.3rem;
  `}
    ${({ left }) =>
      left &&
      `
      left: 15rem;
  `}
  }
`;

export const LogradouroNumber = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;

export const ModalEditText = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.6rem;
  background: linear-gradient(0deg, ${D.triiboGradient} 0%, ${D.triiboPurple} 100%);
  min-width: 55vw;
  min-height: 80vh;
  border-radius: 10px;
  padding: 3rem 1.8rem;
  font-family: 'Source Sans Pro', sans-serif;

  @media (max-width: 900px) {
    padding: 2rem 4rem;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const CardContainer = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.2rem;
  padding: 2rem 1rem;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  &::before {
    content: ' ';
    z-index: -1;
    border-radius: 10px;
    position: absolute;
    background: ${D.triiboPurple};
    background: linear-gradient(0deg, ${D.triiboGradient} 0%, ${D.triiboPurple} 100%);
    width: 100%;
    height: 100%;
    box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);

    ${({ white }) =>
      white &&
      `
          border: 1px solid rgba(0, 0, 0, 0.20);
          background: white;
        
    `}

    ${({ blue }) =>
      blue &&
      `
          border: 1px solid rgba(0, 0, 0, 0.20);
          background: ${D.triiboBlue};
        
    `}

    ${({ modal }) =>
      modal &&
      `
          @media (max-width: 900px) {
            box-shadow: none;
            background: none;
            
          }
          
      `}

    @media (min-width: 900px) {
      opacity: 0;
    }
  }

  ${({ imageContainer }) =>
    imageContainer &&
    `
      gap: 2rem;

      @media (min-width: 900px) {
        flex-direction:row;
      }
      
  `}

  @media (min-width: 900px) {
    padding: 2rem 4rem;
    justify-content: flex-start;
  }
`;

export const TextCountContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const TextCounter = styled.div`
  position: absolute;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
  color: ${D.triiboGray};
  bottom: 0.8rem;
  right: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
  margin-top: 1.6rem;

  @media (min-width: 900px) {
    flex-direction: row;
    margin-top: 3.8rem;

    ${({ modal }) =>
      modal &&
      `
      margin-top: 0;
    `}
  }

  ${({ modal }) =>
    modal &&
    `
      margin-top: 0;
    `}
`;

export const PhotoButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 900px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const OpenTimesLabels = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
`;

export const TimesContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
`;

export const OpenTime = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const OpenTimeElements = styled.span`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
  color: ${D.triiboBlack};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 22rem;

  ${({ open }) =>
    !open &&
    `
      color: ${D.triiboGray};
    `}

  ${({ invalid }) =>
    invalid &&
    `
      color: red;
    `}

  @media (min-width: 1200px) {
    padding: 0 1rem;
  }

  @media (max-width: 900px) {
    width: 16rem;
  }

  @media (max-width: 600px) {
    width: 15rem;
  }
`;

export const OpenTimeFields = styled.label`
  position: relative;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ activity }) =>
    activity &&
    `
        justify-content: center;
    `}

  ${({ times }) =>
    times &&
    `
        justify-content: flex-end;
    `}
`;

export const TextJustifier = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;

export const OpenBullet = styled.span`
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${D.triiboBlue};
  left: -1rem;

  ${({ open }) =>
    !open &&
    `
      opacity: 0;
    `}

  ${({ invalid }) =>
    invalid &&
    `
      background-color: red;
    `}
`;

export const DividerContainer = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
`;

export const Divider = styled.span`
  content: '';
  align-self: right;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 1px;
`;

export const EditTimesButton = styled.span`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

export const TagsLabelContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${D.triiboBlack};
`;

export const TagsBigLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
`;

export const TagsSmallLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
`;

export const TagsContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const TagsAddContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  ${({ width }) =>
    width &&
    `
      width: 100%;
    `}

  @media (min-width: 900px) {
    gap: 1.8rem;
  }
`;

export const TagsAddButton = styled.button`
  border: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: white;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) top/100% 800%;
  background-color: ${D.triiboBlue};
  padding: 0.4rem;
  border-radius: 10px;
  min-width: 7rem;
  transition: 0.35 ease-in-out;
  cursor: pointer;

  &:hover {
    background-position: bottom;
  }
`;

export const SectionLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  color: ${D.triiboBlack};

  ${({ white }) =>
    white &&
    `
      color: white;
    `}

  ${({ flexStart }) =>
    flexStart &&
    `
      align-items: flex-start;
    `}
`;

export const SectionLabelBig = styled.label`
  font-weight: 600;

  ${({ white }) =>
    white &&
    `
    color: white;
    `}
`;

export const SectionLabelSmall = styled.label`
  font-weight: 500;
  font-size: 0.8rem;

  ${({ white }) =>
    white &&
    `
    color: white;
    `}
`;

// servem para controlar ordem em que o preview aparece no desktop e mobile
export const MainPhotoContainerMobile = styled.span`
  width: 100%;
  display: block;

  @media (min-width: 900px) {
    display: none;
  }
`;

// servem para controlar ordem em que o preview aparece no desktop e mobile
export const MainPhotoContainerDesktop = styled.span`
  width: 100%;
  display: flex;

  @media (min-width: 900px) {
    display: flex;
    padding: 2rem 5rem;
  }
`;

export const MainPhotoContainerEditDesktop = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  padding-left: 5rem;
`;

export const MainPhotoTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0;

  @media (min-width: 900px) {
    flex-direction: column;
    padding: 0 4rem;

    ${({ top }) =>
      top &&
      `
    padding: 0 6rem 0 0;
    `}

    ${({ bottom }) =>
      bottom &&
      `
    padding: 0 0 0 4rem;
    `}
  }
`;

export const GalleryContainer = styled.span`
  width: 100%;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-height: 14rem;
  width: 100%;
`;

export const ContactRemoveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  border-color: gray;
  background-color: white;
`;

export const ContactFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
`;

export const ContactRemoveFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
`;

export const ModalLabel = styled.label`
  align-self: center;
  font-size: 0.8rem;

  @media (min-width: 900px) {
    font-size: 1rem;
  }
`;

export const WeekdayContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 3.2rem;
`;

export const TimesEditContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  color: ${D.triiboBlack};
`;

export const TimesEditElements = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const TimesEditRow = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.4rem;
  font-size: 0.8rem;

  @media (min-width: 900px) {
    gap: 2rem;
  }
`;

export const At = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputHours = styled.input.attrs({
  type: 'time',
})`
  border: 1px solid ${D.triiboGray};
  border-radius: 10px;
  padding: 0 0.4rem;
  box-shadow: 0px 3px 6px #00000029;

  &:disabled {
    border: 1px solid #cccccc;
    background: #e3e3e3;
  }
`;

export const ScreenSwitcherContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const WarningContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
`;

export const ButtonSaveContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  @media (min-width: 900px) {
    width: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  gap: 2rem;
`;

export const GalleryDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;

export const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  gap: 2rem;

  @media (max-width: 900px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
`;

export const BorderGiver = styled.span`
  position: relative;
  width: 100%;
  border: 1px solid ${D.triiboGray};
  border-radius: 10px;
  overflow: hidden;

  ${({ red }) =>
    red &&
    `
    border: 1px solid ${'#ee0c0c'};
  `}

  ${({ green }) =>
    green &&
    `
    border: 1px solid ${'#1bdd14'};
  `}

@media (max-width: 900px) {
    width: 45%;
  }
`;

export const DeleteButton = styled.span`
  font-size: 2rem;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  z-index: 2;
  cursor: pointer;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    `
    visibility: visible;
  `}
`;

export const SaveButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;
`;
export const GoBack = styled.span`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 1.4rem;
  border-radius: 10px;
  border: 1px solid ${D.triiboGray};
  padding: 0.6rem;
  background: white;
`;

export const ContactCountryField = styled.select`
  font-family: NotoColorEmojiLimited, 'Source Sans Pro', sans-serif;
  border: 0;
  outline-width: 0;
  display: none;
  background: white;

  ${({ isPhone }) =>
    isPhone &&
    `
          display: block;
        
    `}
`;

export const ContactTextField = styled(InputMask)`
  border: 0;
  outline-width: 0;
  width: 90%;
  text-transform: lowercase;

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none
    `}
`;

export const PlaceholderContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgba(0, 0, 0, 0.3);
`;

export const LeftContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 1rem;
`;

export const Options = styled.option`
  font-family: NotoColorEmojiLimited, 'Source Sans Pro', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const contactFieldMask = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${D.triiboGray};
  width: 100%;
  resize: none;
  background-color: white;
  transition: 0.3s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }
`;
