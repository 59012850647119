import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Form = styled.form`
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const BoxInput = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 42px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
`;

export const Adornment = styled.div`
  margin-left: 1rem;
  color: #383838;

  @media screen and (max-width: 600px) {
    div .gIvpzy {
      overflow: auto;
    }
  }
`;

export const SearchInput = styled(InputMask)`
  flex: 1;
  padding-left: 1rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #383838d6;
  border-radius: 10px;
`;

export const SearchIcon = styled.div`
  button {
    background: none;
    border-left: 1px solid #ccc;
    border-radius: 0;

    img {
      width: 24px;
    }
  }
`;

export const KeyType = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 17px;
  margin: 0 0.3rem;
  color: #383838d6;
`;

export const Input = styled(InputMask)`
  flex: 1;
  border: none;
  height: 100%;
  outline: none;
  font-size: 17px;
  color: #383838d6;
`;
