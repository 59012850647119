import { makeStyles } from "@mui/styles";

export default makeStyles({
  container: {
    width: "100%",
    height: "100%",
    direction: "row",
    marginTop: "70px",
    marginLeft: "64px",
  },

  buttonBase: {
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px #ffffff",
    color: "white",
    height: 48,
    padding: "0 30px",
  },

  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "40px",
  },

  paper: {
    display: "flex",
    height: "469px",
    width: "263px",
    flexDirection: "column",
    cursor: "pointer",
    background: "url(https://t2.tudocdn.net/550828?w=540&h=1200) no-repeat",
    backgroundSize: "100%",
    justifyContent: "flex-end",
  },

  cardBody: {
    display: "flex",
    width: "100%",
    height: "25%",
    flexDirection: "column-reverse",
    background: "white",
  },

  gradient: {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 9%, rgba(255, 255, 255, 0.304132) 0.01%, rgba(255, 255, 255, 0.581818) 0.01%, rgba(255, 255, 255, 0.938223) 14.06%, #FFFFFF 104.71%)",
    height: "100px",
    width: "100%",
  },

  title: {
    color: "#06BAD0",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "45px",
    fontSize: "25px",
    padding: "0px",
    marginLeft: "0px",
    letterSpacing: "0px",
  },
});

export const useMediumStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    paddingTop: "70px",
    paddingLeft: "40px",
  },

  paper: {
    display: "flex",
    height: 300,
    width: 194,
    flexDirection: "column",
    cursor: "pointer",
    background: "url(https://t2.tudocdn.net/550828?w=540&h=1200) no-repeat",
    backgroundSize: "100%",
    justifyContent: "flex-end",
  },

  cardBody: {
    height: "40%",
    width: "100%",

    paddingLeft: theme.spacing(2.5),

    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 0.304132) 0.01%, rgba(255, 255, 255, 0.581818) 0.01%, rgba(255, 255, 255, 0.938223) 14.06%, #FFFFFF 104.71%)",
  },

  title: {
    color: "#06BAD0",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: theme.spacing(4.5),
    fontSize: 18,
    padding: "0px",
    marginLeft: "0px",
    letterSpacing: "0px",
  },

  linkSpacing: {
    marginLeft: 100,
  },

  text: {
    "&:first-child": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
  },
}));
