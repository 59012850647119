import React, { useEffect, useState } from 'react';

import { getClientsEstablishment } from '../../../library/utils/API/apiGetClients';
import usePagination from './usePagination';

import {
  findValueInArray,
  convertToDateDefault,
  randomizeNumbersString,
} from 'js/library/utils/helpers';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

// css personalizado
import { stylesMui5 } from './useStyles';

//icones
import searchIcon from 'styles/assets/SearchUser/search.svg';

// componentes do material ui 5
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Button,
} from '@mui/material/';

import * as S from './styles';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';

export default function ListUsers({ history, ...props }) {
  const [inputSearchCell, setInputSearchCell] = useState('');
  const [clientList, setClientList] = useState([]);
  const [page, setPage] = useState(1);

  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [cellphone, setCellphone] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputSearchCell('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setCellphone(currentDDD + inputSearchCell.replace(/\D/g, ''));
  }, [inputSearchCell]);

  const PER_PAGE = 20;

  useEffect(() => {
    async function getClientList() {
      const url = window.location.href;
      const establishmentId = url.split('/').find((e) => e.startsWith('-'));

      const response = await getClientsEstablishment(establishmentId);

      const clientesFiltradosSemNull = response.registered.filter((e) => e !== null);

      const newClintList = (arr) => {
        return arr.reduce((acc, cunt) => {
          let newCurrent = { ...cunt };
          const contatos = cunt.contactList.findIndex((e) => e.type === 'email');

          if (contatos < 0) {
            newCurrent.contactList.push({
              type: 'email',
              value: 'Não infornado',
            });
          }

          if (cunt.documentList === undefined) {
            newCurrent.documentList = [{ type: 'cpf', value: 'Não infornado' }];
          }

          if (cunt.birthDate === undefined) {
            newCurrent.birthDate = 'Não informado';
          } else {
            newCurrent.birthDate = convertToDateDefault(newCurrent.birthDate);
          }

          acc.push(newCurrent);

          return acc;
        }, []);
      };

      setClientList(newClintList(clientesFiltradosSemNull));
    }

    getClientList();
  }, []);

  const filteredClients = cellphone
    ? clientList.filter((client) => {
        const Cellphone = findValueInArray(client.contactList, 'type', 'cellPhone').value;
        return Cellphone.toLowerCase().includes(cellphone.toLowerCase());
      })
    : clientList;

  const count = Math.ceil(filteredClients.length / PER_PAGE);
  const _DATA = usePagination(filteredClients, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <Container fixed>
        <HeaderTitle pageInfo="Seus clientes" />
        <Box>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            style={{
              color: '#6E3296',
              fontSize: '1.5rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }}
          >
            Procurar cliente
          </Typography>

          <S.BoxSearchInput style={{ marginBottom: '2rem' }}>
            <S.Adornment>
              '
              <select
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  flex: '1',
                  height: '100%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '17px',
                  color: '#383838d6',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                value={currentCountry}
                onChange={handleCountryChange}
              >
                {phoneMaskArray.map((country, index) => (
                  <option
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.emoji} {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
            </S.Adornment>

            <S.InputSearch
              mask={inputMask}
              value={inputSearchCell}
              placeholder={randomizeNumbersString(inputMask)}
              onChange={(e) => setInputSearchCell(e.target.value)}
            />

            <div className="searchIcon">
              <Button disabled>
                <img src={searchIcon} alt="" />
              </Button>
            </div>
          </S.BoxSearchInput>
        </Box>

        <S.Adornment>
          <Typography variant="subtitle1" ccomponent="h3" style={{ marginBottom: '2rem' }}>
            Membros recém-adicionados podem levar alguns minutos para serem exibidos na tabela de
            clientes. Caso não consiga visualizar o novo membro após alguns minutos, por favor,
            entre em contato com nossa equipe.
          </Typography>
        </S.Adornment>

        <br />

        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium" aria-label="">
            <TableHead sx={stylesMui5.tableHead}>
              <TableRow>
                <TableCell align="left">
                  <strong>Nome</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Telefone</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Email</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Data de nascimento</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>CPF</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_DATA.currentData().map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left" component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {findValueInArray(row.contactList, 'type', 'cellPhone').value}
                  </TableCell>
                  <TableCell align="left">
                    {findValueInArray(row.contactList, 'type', 'email').value}
                  </TableCell>
                  <TableCell align="left">{row.birthDate}</TableCell>
                  <TableCell align="left">
                    {findValueInArray(row.documentList, 'type', 'cpf').value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          sx={{ mt: 3 }}
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
        <br />
        <br />
      </Container>
    </div>
  );
}

