import React from 'react';

// STYLES
import { Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import { BoxModal } from 'js/components/Clubs/Segmentation/segmentStyles';

export const ModalValidationList = ({ close, loading, isOpen, setRefreshPage, validateApi }) => {
  const closeAndRefreshApi = () => {
    close();
    setRefreshPage((prev) => !prev);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={closeAndRefreshApi}
    >
      <BoxModal style={{ textAlign: 'center', padding: '1.5rem 1.5rem', minHeight: '14rem' }}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
            <button
              onClick={closeAndRefreshApi}
              style={{
                background: 'transparent',
                border: 0,
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
            >
              X
            </button>
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography sx={{ fontWeight: 600 }} variant="h5">
              Deseja validar este arquivo ?
            </Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}
          >
            <Button
              onClick={validateApi}
              sx={{ fontWeight: 700, minWidth: '8rem' }}
              variant="contained"
            >
              {loading ? <CircularProgress size={25} thickness={6} /> : 'Validar'}
            </Button>

            <Button
              color="warning"
              onClick={closeAndRefreshApi}
              sx={{ fontWeight: 700, minWidth: '8rem' }}
              variant="contained"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </BoxModal>
    </Modal>
  );
};
