import dispatcher from "js/core/dispatcher";
import { firebaseDatabase } from "js/library/utils/firebaseUtils";

import { updateOrg } from "../../library/utils/API/updateOrg";
import { getUserInfo } from "../../library/utils/helpers";

export const organizationInformationAction = (dispatch, stateData) => {
  const {
    coverImageFile,
    oldCoverImage,
    oldThumbnail,
    thumbnailFile,
    oldLogoImage,
    logoImageFile,
  } = stateData;

  const orgID =
    stateData.orgID || firebaseDatabase.ref("Organizations").push().key;

  const { uId } = getUserInfo();

  const currentDate = Date.now();

  const newInfo = {
    orgID, // required - const mode - check if has
    accessMethod: stateData.accessMethod,
    coverImage: stateData.coverImage
      ? `organizations/storage:${orgID}:cover:${currentDate}`
      : oldCoverImage,
    description: stateData.description,
    lockdown: stateData.lockdown,
    thumbnail: stateData.thumbnail
      ? `organizations/storage:${orgID}:thumbnail:${currentDate}`
      : oldThumbnail,
    logoImage: stateData.logoImage
      ? `organizations/storage:${orgID}:logo:${currentDate}`
      : oldLogoImage,
    name: stateData.name,
    owner: stateData.owner || uId,
    tags: stateData.tags.length > 0 ? stateData.tags : ["org"],
    type: stateData.type,
    visible: stateData.visible,
  };

  return dispatcher(
    dispatch,
    "ORGANIZATION_INFORMATION",
    updateOrg(
      uId, // required
      orgID, // required - const mode - check if has
      newInfo, // org info
      stateData.coverImage,
      coverImageFile,
      oldCoverImage,
      stateData.thumbnail,
      thumbnailFile,
      oldThumbnail,
      logoImageFile,
      stateData.logoImage,
      oldLogoImage
    )
  );
};
