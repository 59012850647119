export default function reducer(state = {
	id: null,
	showQRCode: null,
	cost: null,
	cooldown: null,
	qtdSimultaneo: null,
	address: null,
	neiborhood: null,
	titulo: null,
	detailsTitle: null,
	descricao: null,
	detailsDescription: null,
	plural: null,
	singular: null,
	region: null,
	consumoUnico: null,
	generator: null,
	active: null,
	title: null,
	description: null,
	data: null,
	nomeTemplate: null,
	// dados template
	foto: null,
	thumbnail: null,
	oldFoto: null,
	oldThumbnail: null,
	fileFoto: null,
	fileThumbnail: null,

	//dados Store
	storeFoto: null,
	storeThumbnail: null,
	storeOldFoto: null,
	storeOldThumbnail: null,
	storeFileFoto: null,
	storeFileThumbnail: null
}, action) {

	switch (action.type) {

		case "STORE_PRODUCTS_INFORMATION_PENDING":
			{
				return {
					...state,
					id: null,
					showQRCode: null,
					cost: null,
					cooldown: null,
					qtdSimultaneo: null,
					address: null,
					neiborhood: null,
					titulo: null,
					detailsTitle: null,
					descricao: null,
					detailsDescription: null,
					plural: null,
					singular: null,
					region: null,
					consumoUnico: null,
					generator: null,
					active: null,
					title: null,
					description: null,
					data: null,
					nomeTemplate: null,
					// dados template
					foto: null,
					thumbnail: null,
					oldFoto: null,
					oldThumbnail: null,
					fileFoto: null,
					fileThumbnail: null,

					//dados Store
					storeFoto: null,
					storeThumbnail: null,
					storeOldFoto: null,
					storeOldThumbnail: null,
					storeFileFoto: null,
					storeFileThumbnail: null
				};
			}

		case "STORE_PRODUCTS_INFORMATION_FULFILLED":
			{

				return {
					...state,
					id: action.payload.id,
					showQRCode: action.payload.showQRCode,
					cost: action.payload.cost,
					cooldown: action.payload.cooldown,
					qtdSimultaneo: action.payload.qtdSimultaneo,
					address: action.payload.address,
					neiborhood: action.payload.neiborhood,
					titulo: action.payload.titulo,
					detailsTitle: action.payload.detailsTitle,
					descricao: action.payload.descricao,
					detailsDescription: action.payload.detailsDescription,
					plural: action.payload.plural,
					singular: action.payload.singular,
					region: action.payload.region,
					consumoUnico: action.payload.consumoUnico,
					generator: action.payload.generator,
					active: action.payload.active,
					title: action.payload.title,
					description: action.payload.description,
					data: action.payload.data,
					nomeTemplate: action.payload.nomeTemplate,
					// dados template
					foto: action.payload.foto,
					thumbnail: action.payload.thumbnail,
					oldFoto: action.payload.oldFoto,
					oldThumbnail: action.payload.oldThumbnail,
					fileFoto: action.payload.fileFoto,
					fileThumbnail: action.payload.fileThumbnail,

					//dados Store
					storeFoto: action.payload.storeFoto,
					storeThumbnail: action.payload.storeThumbnail,
					storeOldFoto: action.payload.storeOldFoto,
					storeOldThumbnail: action.payload.storeOldThumbnail,
					storeFileFoto: action.payload.storeFileFoto,
					storeFileThumbnail: action.payload.storeFileThumbnail,
				};
			}

		case "STORE_PRODUCTS_INFORMATION_REJECTED":
			{
				return {
					...state,
					id: null,
					showQRCode: null,
					cost: null,
					cooldown: null,
					qtdSimultaneo: null,
					address: null,
					neiborhood: null,
					titulo: null,
					detailsTitle: null,
					descricao: null,
					detailsDescription: null,
					plural: null,
					singular: null,
					region: null,
					consumoUnico: null,
					generator: null,
					active: null,
					title: null,
					description: null,
					data: null,
					nomeTemplate: null,
					// dados template
					foto: null,
					thumbnail: null,
					oldFoto: null,
					oldThumbnail: null,
					fileFoto: null,
					fileThumbnail: null,

					//dados Store
					storeFoto: null,
					storeThumbnail: null,
					storeOldFoto: null,
					storeOldThumbnail: null,
					storeFileFoto: null,
					storeFileThumbnail: null
				};
			}

		default:
			return state;

	}

}