import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import TemplateModalLogin from './templateModalLogin';

import { Typography, Button, CircularProgress, Box } from '@mui/material';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import * as S from './style';
import { store } from 'js/core/configureStore';
import InputMask from 'react-input-mask';

//arquivo com as actions
import { phoneInputAction } from 'js/core/actions/phoneInputActions.js';
import { checkUserStatus } from 'js/library/services/AuthenticationManager.js';
import { getUserInfo } from 'js/library/utils/helpers.js';
import AnimationLoading from 'js/containers/Loading/AnimationLoading';
import dddNotValid from 'styles/assets/login/dddNotValid.json';
import { randomizeNumbersString } from '../../library/utils//helpers';

function PhoneInput(props) {
  const [cellphone, setCellPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [messageError, setMessageError] = useState('');

  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [input, setInput] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');
  const [fullMask, setFullMask] = useState('+55(99)99999-9999');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setCellPhone('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMask(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setInput(currentDDD + cellphone.replace(/\D/g, ''));
  }, [cellphone]);

  const validatioCodeError = store.getState().phoneInputModel.error;

  // VERIFICA SE O USUSARIO ESTA AUTENTICADO
  useEffect(() => {
    checkUserStatus()
      .then((resp) => {
        if (getUserInfo() !== null) {
          setAuthenticated(resp);
          setAuthLoading(false);
        } else {
          setAuthenticated(null);
          setAuthLoading(false);
        }
      })
      .catch(() => {
        setAuthenticated(null);
        setAuthLoading(false);
        setMessageError('Ops, erro ao verificar informações.');
      });

    window.pathname = null;
  }, []);

  const handleChange = (event) => {
    setCellPhone(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const fullMaskFormated = '+' + fullMask.replace(/\D/g, '');

    if (input.length !== fullMaskFormated.length) {
      setMessageError('Número do telefone inválido!');
    } else if (dddNotValid[cellphone.substr(5, 2)]) {
      setMessageError('DDD inválido!');
    } else {
      setMessageError('');
      setLoading(true);

      props.phoneInputComponent(input);
    }
  };

  // MENSSAGEM DE ERRO...
  useEffect(() => {
    if (props.error !== null && loading) {
      setLoading(false);
      setMessageError('Algo saiu errado, tente novamente.');
    }
  }, [props.error]);

  useEffect(() => {
    if (validatioCodeError !== null && loading) {
      handleErrorAuth();
    }
  }, [props.success]);

  const handleErrorAuth = () => {
    setLoading(false);

    if (validatioCodeError === 1005) {
      setMessageError('Quantidade de tentativas excedidas.');
    } else {
      setMessageError('Algo saiu errado, tente novamente.');
    }
  };

  if (authLoading) {
    return (
      <div className="containerLoading">
        <AnimationLoading className="itemLoading" />
      </div>
    );
  }

  if (authenticated !== null) {
    return <Redirect to="/admin" />;
  }

  if (window.pathname !== null) {
    return <Redirect to={window.pathname} />;
  }

  return (
    <TemplateModalLogin
      handleSubmit={handleSubmit}
      messageError={messageError}
      gap="1.8rem"
      setMessageError={setMessageError}
    >
      <S.TextContainer width={'63%'} widthMobile={'98%'}>
        {/* <S.TextContainer width={'63%'}> */}
        <Typography
          variant="subtitle1"
          fontSize={{ xs: '17px', sm: '24px' }}
          textAlign={'center'}
          color={'#383838'}
          lineHeight={'33px'}
        >
          Insira o número do seu telefone para acessar a plataforma Triibo.
        </Typography>
      </S.TextContainer>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            borderBottom: '3px solid rgba(110, 50, 150, 0.298)',
            ':focus-within': {
              borderBottom: '3px solid rgb(110, 50, 150)',
            },
            width: '100%',
            maxWidth: '450px',
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <select
            style={{
              fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
              height: '100%',
              border: 'none',
              outline: 'none',
              fontSize: '17px',
              color: '#383838d6',
              borderRadius: '10px',
              cursor: 'pointer',
              marginLeft: '5px',
            }}
            value={currentCountry}
            onChange={handleCountryChange}
          >
            {phoneMaskArray.map((country, index) => (
              <option
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                }}
                key={country.id}
                value={[country.ddd, country.mask, country.fullMask]}
              >
                {country.emoji} {country.initials} {country.ddd}
              </option>
            ))}
          </select>

          <InputMask
            style={{
              fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
              alignContent: 'center',
              height: '100%',
              border: 'none',
              outline: 'none',
              fontSize: '17px',
              color: '#383838d6',
              borderRadius: '0px',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '117px',
            }}
            mask={inputMask}
            value={cellphone}
            placeholder={randomizeNumbersString(inputMask)}
            onChange={(e) => handleChange(e)}
          />
        </Box>
      </Box>

      {/* <Box
                sx={{
                    width: '63%'
                }}
            > */}
      <Button
        sx={{
          padding: '2',
          width: { xs: '160px', sm: '360px' },
          backgroundColor: '#08bad0',
          '&:hover': {
            backgroundColor: '#08bad0',
            filter: 'brightness(0.9)',
          },
        }}
        type="submit"
        fullWidth
        variant="contained"
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size="2rem" style={{ color: 'white' }} type="submit" />
        ) : (
          <>
            <Typography variant="h6" style={{ textTransform: 'capitalize', color: 'white' }}>
              Continuar
            </Typography>
          </>
        )}
      </Button>

      {/* </Box> */}
    </TemplateModalLogin>
  );
}

//recebe as props dos Reducersx
function mapStateToProps(state) {
  return {
    cellphone: state.phoneInputModel.cellphone,

    loading: state.phoneInputComponent.loading,
    success: state.phoneInputComponent.success,
    error: state.phoneInputComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  phoneInputComponent: (cellphone) => phoneInputAction(dispatch, cellphone),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInput);

