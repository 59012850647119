import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  /* padding: 3rem 1rem 4rem 1rem; */
  /* max-width: 1900px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 1rem;

  @media screen and (min-width: 600px) {
    /* padding: 3rem 3rem 4rem 3rem; */
    max-width: 800px;
  }

  @media screen and (min-width: 900px) {
    /* padding: 3rem 5rem 4rem 5rem; */

    max-width: 1200px;
  }

  @media screen and (min-width: 1340px) {
    /* padding: 3rem 6rem 4rem 6rem; */
    max-width: 1500px;
  }

  @media screen and (min-width: 1600px) {
    /* padding: 3rem 10rem 4rem 10rem; */
    max-width: 1600px;
  }
`;

export const Header = styled.header`
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    max-width: 582px;
  }

  @media (max-width: 640px) {
    h4 {
      padding: 0 1rem;
    }
  }
`;

export const Content = styled.main`
  .desktop {
    width: 100%;
    display: flex;
    gap: 16px;

    @media (max-width: 600px) {
      display: none;
    }

    @media (max-width: 1150px) {
      .previewContainer {
        display: none;
      }
    }
  }

  .mobile {
    display: none;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const cols = styled.div`
  width: 33.33%;
  max-height: 1030px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1150px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  > div {
    margin-bottom: 16px;
    background: #fff;
    padding: 15px 30px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #70707033;
    border-radius: 5px;
    width: 100%;
  }

  .preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-height: 1030px;
    /* height: 100%; */
    padding: 3.75rem 2.5rem;
    background: transparent linear-gradient(180deg, #6e3296 0%, #37194b 100%) 0%
      0% no-repeat padding-box;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 48px !important;
  border-radius: 10px;
  border: 1px solid #757575;
  background-color: #fff;
  padding: 0 10px;
  font-size: 15px;
`;

export const SwitchView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 280px;
  width: 100%;
  margin-left: auto;

  h3 {
    color: #fff;
  }

  @media (max-width: 1300px) {
    /* h3 {
      font-size: 22px;
    } */
  }
`;

export const SwitchViewButton = styled.button`
  border: none;
  border-radius: 10px;
  background: #fff;
  padding: 0.4rem;
  cursor: pointer;
`;

export const PreviewContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  max-height: 701px;
  /* height: 100%; */
  width: 100%;
  margin: 1rem auto;

  .post {
    width: 100%;
  }
`;

export const PreviewHeader = styled.div`
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 30px;
    height: 29px;
  }

  h5 {
    color: #4a494a;
    font-weight: 600;
  }
`;

export const ModalContainer = styled.div`
  background: ${({ background }) => background || "#fff"};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  outline: none;
  max-width: ${({ desk_width }) => desk_width || "500px"};
  max-height: ${({ desk_height }) => desk_height || "80%"};
  width: 100%;

  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 10px;
    border: 3px solid #f2f2f2;
  }

  @media (max-width: 500px) {
    max-width: ${({ m_width }) => m_width || "500px"};
    height: ${({ height }) => height || "auto"};
    border-radius: none;
  }
`;

export const ModalBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: ${({ padding }) => padding || "4rem"};

  .titleQuery strong span {
    color: #328796;
    font-weight: 600;
  }
`;

export const CloseModalIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
  width: ${(props) => (props.width ? props.width : "100%")};

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CancelButton = styled.button`
  height: 40px;
  width: 100%;
  border: 1px solid #328796;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;

  h5 {
    color: #328796;
    font-weight: 600;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ContinueButton = styled.button`
  height: 40px;
  width: 100%;
  border-radius: 10px;
  background: #328796;
  cursor: pointer;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }

  h5 {
    color: #fff;
    font-weight: 600;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  max-width: 620px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 700px) {
    flex-direction: column;
    max-width: 300px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    max-width: 500px;
  }

  @media (min-width: 1150px) {
    max-width: 420px;
  }
`;

export const ButtonPreview = styled.button`
  width: 100%;
  height: 42px;
  border: 1px solid #328796;
  border-radius: 10px;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
  padding: 1rem;
  color: #328796;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    filter: brightness(0.9);
  }

  @media (min-width: 1150px) {
    display: none;
  }

  @media (max-width: 700px) {
    justify-content: center;
    gap: 1rem;
  }

  @media (max-width: 600px) {
    display: ${({ currentPageMobile }) =>
      currentPageMobile === 1 ? "flex" : "none"};
  }
`;

export const ButtonSchedulePublication = styled.button`
  /* max-width: 170px; */
  width: 100%;
  height: 42px;
  border: 1px solid #328796;
  border-radius: 10px;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: #328796;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 700px) {
    justify-content: center;
    gap: 1rem;
  }

  @media (max-width: 600px) {
    display: ${({ currentPageMobile }) =>
      currentPageMobile === 1 ? "flex" : "none"};
  }
`;

export const ButtonPublish = styled.button`
  /* max-width: 170px; */
  width: 100%;
  height: 42px;
  border: 1px solid #328796;
  border-radius: 10px;
  outline: none;
  background: #328796;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    background: #328796e5;
  }

  @media (max-width: 700px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    display: ${({ currentPageMobile }) =>
      currentPageMobile === 1 ? "flex" : "none"};
  }
`;

export const ButtonGoBack = styled.button`
  display: none;
  border: none;
  border-radius: 10px;
  background-color: #f2f2f2;
  color: #555555;
  cursor: pointer;
  width: 100%;
  height: 42px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

export const NextPageButton = styled.button`
  display: none;
  border: none;
  border-radius: 10px;
  background-color: #328796;
  color: #fff;
  cursor: pointer;
  width: 100%;
  height: 42px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background: #328796e5;
  }

  @media (max-width: 600px) {
    display: ${({ currentPageMobile }) =>
      currentPageMobile === 0 ? "block" : "none"};
  }
`;
