import React from 'react';

import * as S from './styles';

import logoPlaceholder from 'styles/assets/placeholder/placeholder_card.png';
import bannersPlaceholder from 'styles/assets/placeholder/placeholder_triibo.png';

import PreviewClub from './PreviewClub';

import cfac22 from 'js/library/utils/cfac22';

const PreviewData = (props) => {
  const { clubForm, setClubForm, filesForUpload } = props;
  return (
    <>
      <S.PreviewCard>
        <PreviewClub clubForm={clubForm} filesForUpload={filesForUpload} />
        <S.FormDisplayContainer>
          <S.InfoKey style={{ alignItems: 'center' }}>
            <S.ClubFormLabel>Status:</S.ClubFormLabel>{' '}
            <S.StatusClub>
              <S.RadioBox
                type="button"
                onClick={() => setClubForm({ ...clubForm, status: true })}
                isActive={clubForm.status === true}
                activeColor="green"
              >
                <span>Ativo</span>
              </S.RadioBox>

              <S.RadioBox
                type="button"
                onClick={() => setClubForm({ ...clubForm, status: false })}
                isActive={clubForm.status === false}
                activeColor="red"
              >
                <span>Inativo</span>
              </S.RadioBox>
            </S.StatusClub>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Nome do clube:</S.ClubFormLabel> <span>{clubForm.name}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Clube:</S.ClubFormLabel> <span>{clubForm.clubeId + clubForm.url}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Push:</S.ClubFormLabel> <span>{clubForm.push}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Org:</S.ClubFormLabel> <span>{clubForm.orgs}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Keyword:</S.ClubFormLabel> <span>{clubForm.keywords}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Cor primária:</S.ClubFormLabel>{' '}
            <S.ColorPreview>
              <S.ColorDiv color={clubForm.colorPrimary} />
              <span>{clubForm.colorPrimary}</span>
            </S.ColorPreview>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Cor secundária:</S.ClubFormLabel>{' '}
            <S.ColorPreview>
              <S.ColorDiv color={clubForm.colorSecondary} />
              <span>{clubForm.colorSecondary}</span>
            </S.ColorPreview>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Data de criação:</S.ClubFormLabel>{' '}
            <span>{new Date(clubForm.createDate).toLocaleDateString('pt-BR')}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Data de update:</S.ClubFormLabel>{' '}
            <span>{new Date(clubForm.updateDate).toLocaleDateString('pt-BR')}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Arquivo favicon:</S.ClubFormLabel>{' '}
            <S.ImgGroup>
              <span>{clubForm.favicon}</span>
              <S.ImgPreview
                style={{ maxWidth: '128px', maxHeight: '128px' }}
                src={
                  filesForUpload.favicon !== ''
                    ? filesForUpload.favicon
                    : cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.favicon + '?alt=media' !==
                      cfac22('STORAGE_URL') + 'seuClube%2Fundefined?alt=media'
                    ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.favicon + '?alt=media'
                    : logoPlaceholder
                }
              />
            </S.ImgGroup>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Arquivo logo:</S.ClubFormLabel>{' '}
            <S.ImgGroup>
              <span>{clubForm.logo}</span>
              <S.ImgPreview
                src={
                  filesForUpload.logo !== ''
                    ? filesForUpload.logo
                    : cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.logo + '?alt=media' !==
                      cfac22('STORAGE_URL') + 'seuClube%2Fundefined?alt=media'
                    ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.logo + '?alt=media'
                    : logoPlaceholder
                }
              />
            </S.ImgGroup>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Link do banner esquerdo:</S.ClubFormLabel>{' '}
            <span>{clubForm.linkBannerLeft}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Banner esquerdo:</S.ClubFormLabel>{' '}
            <S.ImgGroup>
              <span>{clubForm.bannerLeft}</span>
              <S.ImgPreview
                src={
                  filesForUpload.bannerLeft !== ''
                    ? filesForUpload.bannerLeft
                    : cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.bannerLeft + '?alt=media' !==
                      cfac22('STORAGE_URL') + 'seuClube%2Fundefined?alt=media'
                    ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.bannerLeft + '?alt=media'
                    : bannersPlaceholder
                }
              />
            </S.ImgGroup>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Link do banner direito:</S.ClubFormLabel>{' '}
            <span>{clubForm.linkBannerRight}</span>
          </S.InfoKey>
          <S.InfoKey>
            <S.ClubFormLabel>Banner direito:</S.ClubFormLabel>{' '}
            <S.ImgGroup>
              <span>{clubForm.bannerRight}</span>
              <S.ImgPreview
                src={
                  filesForUpload.bannerRight !== ''
                    ? filesForUpload.bannerRight
                    : cfac22('STORAGE_URL') +
                        'seuClube%2F' +
                        clubForm.bannerRight +
                        '?alt=media' !==
                      cfac22('STORAGE_URL') + 'seuClube%2Fundefined?alt=media'
                    ? cfac22('STORAGE_URL') + 'seuClube%2F' + clubForm.bannerRight + '?alt=media'
                    : bannersPlaceholder
                }
              />
            </S.ImgGroup>
          </S.InfoKey>
        </S.FormDisplayContainer>
      </S.PreviewCard>
    </>
  );
};

export default PreviewData;
