import React from 'react';
import { useSelector } from 'react-redux';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

const EstablishmentEditMenu = (props) => {
  const { setCurrentScreen } = props;

  const isSuperAdmin = useSelector((state) => state.authModel.superUser);

  return (
    <>
      <S.ScreenSwitcherContainer>
        <D.TriiboFilledButton
          purple={true}
          onClick={(e) => {
            e.preventDefault();
            setCurrentScreen(0);
          }}
        >
          1. Informações básicas
        </D.TriiboFilledButton>
        <D.TriiboFilledButton
          blue={true}
          onClick={(e) => {
            e.preventDefault();
            setCurrentScreen(1);
          }}
        >
          2. Dados de contato
        </D.TriiboFilledButton>
        <D.TriiboFilledButton
          purple={true}
          onClick={(e) => {
            e.preventDefault();
            setCurrentScreen(2);
          }}
        >
          3. Imagens
        </D.TriiboFilledButton>
        {isSuperAdmin ? (
          <D.TriiboFilledButton
            blue={true}
            onClick={(e) => {
              e.preventDefault();
              setCurrentScreen(3);
            }}
          >
            4. Admin
          </D.TriiboFilledButton>
        ) : (
          <></>
        )}
      </S.ScreenSwitcherContainer>
    </>
  );
};

export default EstablishmentEditMenu;
