import React from 'react';
import * as S from './styles';

export default function Radio({ label, size, ...rest }) {
  return (
    <S.Label>
      <S.InputRadio {...rest} size={size} />
      {label}
    </S.Label>
  );
}
