import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { BsArrowUpShort } from 'react-icons/bs';

const triiboPurple = '#6E3296';

export const Drawer = styled.aside`
  z-index: 500;
  position: fixed;
  /* overflow-X: hidden; */
  /* overflow-Y: hidden; */
  background: white;
  width: 18rem;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    width: 32rem;
    // left: '-16.2rem',
  }

  ${({ open }) =>
    !open &&
    `
    
    width: 1.8rem;

    @media (min-width: 600px) {
        width: 5rem;
    }

    transition: 0.25s ease-in-out;

  `}

  ${({ open }) =>
    open &&
    `

  transition: 0.35s ease-in-out;

`}
`;

export const MenuHolder = styled.div`
  width: 100%;
  min-height: 3rem;
  display: flex;
  flex-direction: column;
`;

export const ArrowButtonDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  z-index: 2000;
`;

export const StickyArrow = styled.span`
  position: sticky;
  left: 4rem;
  cursor: pointer;
`;

export const ArrowCircle = styled.span`
  position: relative;
  left: 1.15rem;
  top: 1rem;
  transform: rotate(90deg);
`;

export const BsArrow = styled(BsArrowUpShort)`
  display: block;
  fill: white;
  background-color: #6e3296;
  border-radius: 50%;
  font-size: 2rem;
  box-shadow: 0px 0px 6px 3px #6e32964d;
  /* transform: rotate(90deg); */
  transition: 0.35s ease-in-out;

  @media (min-width: 600px) {
    font-size: 2.2rem;
  }

  ${({ open }) =>
    !open &&
    `
        transform: rotate(90deg);
        transition: 0.35s ease-in-out;

    `}

  ${({ open }) =>
    open &&
    `
        transform: rotate(270deg);
        transition: 0.35s ease-in-out;

    `}
`;

export const DisplayToggle = styled.div`
  display: block;

  ${({ status }) =>
    !status &&
    `
            display: none;
        `}
`;

export const SidebarButton = styled.button`
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;

  width: 100%;
  height: 4rem;
  background-color: transparent;
  padding: 0rem;
  border: none;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  color: #383838;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.25); */
  cursor: pointer;

  ${({ exit }) =>
    exit &&
    `
        border-top: 1px solid #6E3296;
    `}

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: 0.15s ease-in-out;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.05s ease-in-out;
  }

  @media (min-width: 600px) {
    height: 5rem;
  }

  ${({ open }) =>
    !open &&
    `

        display: block;
        visibility: hidden;

        @media (min-width: 600px) {
            display: flex;
            visibility: visible;
        }
    `}
`;

export const SidebarLink = styled(Link)`
  text-decoration: none;
  color: #383838;

  ${({ open }) =>
    !open &&
    `

        display: block;
        visibility: hidden;

        @media (min-width: 600px) {
            display: flex;
            visibility: visible;
        }
    `}

  ${({ purple }) =>
    purple &&
    `

        background-color: ${triiboPurple};
        width: 100%;
    `}
`;

export const SidebarLinkExit = styled.div`
  ${({ open }) =>
    !open &&
    `
        background-color: ${triiboPurple};
        transition: 0.35s ease-in-out;

    `}

  ${({ open }) =>
    open &&
    `
        background-color: white;
        transition: 0.35s ease-in-out;

    `}
`;

export const SidebarButtonContents = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  margin-left: 0.85rem;

  ${({ exit }) =>
    exit &&
    `
        margin-right: 1rem;

    `}
`;

export const SidebarButtonIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: ${triiboPurple};

  @media (min-width: 600px) {
    font-size: 3rem;

    ${({ open }) =>
      !open &&
      `

        display: block;
        opacity: 1;
    `}
  }

  ${({ white }) =>
    white &&
    `
    color: white;

    `}
`;

export const SidebarIconContainer = styled.span`
  position: relative;
  display: flex;
`;

export const SidebarButtonChildren = styled.div`
  font-size: 1rem;
  white-space: nowrap;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }

  // display: ${({ open }) => (open ? 'initial' : 'none')};

  ${({ open }) =>
    !open &&
    `
    // display: none;
    opacity: 0;
    transition: 0.35s ease-in-out;

    height: 100;
    overflow: hidden;
    position: absolute;
    left: -100rem;

    `}

  ${({ open }) =>
    open &&
    `
    // display: 'block';
    opacity: 1;
    transition: 0.35s ease-in-out;

    height: auto;

    `}

    ${({ white }) =>
    white &&
    `
    color: white;

    `}

    ${({ purple }) =>
    purple &&
    `
    
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 600px) {
        max-width: 5rem;
        }

    `}
`;

export const SvgBadge = styled.div`
  font-size: 0.6rem;
  position: absolute;
  left: 1.7rem;
  top: -0.3rem;

  @media (min-width: 600px) {
    font-size: 0.8rem;
    left: 2.7rem;
    top: -0.5rem;
  }
`;

export const ExitContainer = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow-x: hidden;
`;

export const DividerContainer = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: flex-end;
  opacity: 1;

  transition: 0.25s ease-in-out;

  ${({ open }) =>
    !open &&
    `
    
    opacity: 0;

    transition: 0.25s ease-in-out;

  `}
`;

export const Divider = styled.span`
  content: '';
  align-self: right;
  background: rgba(0, 0, 0, 0.25);
  width: 87%;
  height: 1px;
`;
