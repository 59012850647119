import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { separateBase64String } from 'js/library/utils/helpers';

const uploadBannerImg = async (image, clubeId) => {
  const firstPart = image.split('/');
  const [typeImg] = firstPart[1].split(';');
  const newImage = separateBase64String(image);

  const response = await uploadFiles('campaignLp', {
    mimeType: newImage.mimeType,
    fileName: `bannerModule_${typeImg}_${clubeId}`,
    buffer: newImage.buffer,
  });

  if (response) return response;
};

export default { uploadBannerImg };

