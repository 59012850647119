import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';

import { updateCampaingTriiboAction } from 'js/core/actions/campaingTriiboActions';
import { convertToDate } from 'js/library/utils/helpers';
import { downloadImage } from 'js/library/services/StorageManager';

import {
  TextField,
  Typography,
  Grid,
  Button,
  IconButton,
  FormControl,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormGroup,
  Modal,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { Save, Delete, Help, AddPhotoAlternate, CropFree, ArrowBack } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';

import Cropper from 'react-image-cropper/lib/Cropper';
import 'styles/css/App.css';

import Loading from 'js/containers/Loading/Loading';
import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import thumbnail from 'styles/assets/promocao/thumbnail-campanhaTriibo.png';
import detailPhoto from 'styles/assets/promocao/photoDetails-campanhaTriibo.png';
import { debounce } from 'lodash';
import { getAllEvents } from 'js/library/utils/API/apiGetAllEvents';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },

  containerAddEvent: {
    display: 'flex',
    marginBottom: '2rem',
    gap: '3rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },

  inputAddEvent: {
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  buttonAddEvent: {
    width: '20%',
    height: '50px',
    fontWeight: '550',
    color: 'white',
    marginLeft: '10px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
});

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

const eventList = [
  'Store',
  'Chat',
  'Compra_Cashback',
  'Compra_Marketplace',
  'Access',
  'Pesquisa',
  'Compra_Marketplace_Gestor',
  'Assinatura_Estabelecimento',
  'Store_Gestor',
  'Pesquisa_Gestor',
  'Invitation',
  'Compartilhar_Promo_5_Cliques',
  'Compartilhar_Promo_10_Cliques',
  'Quiz',
];

class LotteryEdit extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      //id da notificação
      key: props.history.location.state.key === undefined ? null : props.history.location.state.key,

      isNewCampaing: props.history.location.state.key === undefined ? true : false,

      walletTitle:
        props.history.location.state.walletTitle === undefined
          ? ''
          : props.history.location.state.walletTitle,

      walletDetailsTitle:
        props.history.location.state.walletDetailsTitle === undefined
          ? ''
          : props.history.location.state.walletDetailsTitle,

      walletDescription:
        props.history.location.state.walletDescription === undefined
          ? ''
          : props.history.location.state.walletDescription,

      walletDetailsDescription:
        props.history.location.state.walletDetailsDescription === undefined
          ? ''
          : props.history.location.state.walletDetailsDescription,

      downloadedThumb: props.history.location.state.downloadedThumb ? placeholder : null,

      downloadedPhoto: props.history.location.state.downloadedPhoto ? placeholder : null,

      walletThumbnail:
        props.history.location.state.walletThumbnail === undefined
          ? null
          : props.history.location.state.walletThumbnail,

      walletPhoto:
        props.history.location.state.walletPhoto === undefined
          ? null
          : props.history.location.state.walletPhoto,

      numbersLucky:
        props.history.location.state.numbersLucky === undefined
          ? null
          : props.history.location.state.numbersLucky,

      lotterySerie:
        props.history.location.state.lotterySerie === undefined
          ? ''
          : props.history.location.state.lotterySerie,

      notificationMessage:
        props.history.location.state.notificationMessage === undefined
          ? {}
          : props.history.location.state.notificationMessage,

      quizzes:
        props.history.location.state.quizzes === undefined
          ? []
          : props.history.location.state.quizzes,

      events:
        props.history.location.state.events === undefined
          ? []
          : props.history.location.state.events,

      voucherQuantity:
        props.history.location.state.voucherQuantity === undefined
          ? []
          : props.history.location.state.voucherQuantity,

      activationDate:
        props.history.location.state.activationDate === undefined
          ? convertToDate(Date.now())
          : convertToDate(props.history.location.state.activationDate),

      dueDate:
        props.history.location.state.dueDate === undefined
          ? convertToDate(Date.now() + 31536000000)
          : convertToDate(props.history.location.state.dueDate),

      establishmentId:
        props.history.location.state.establishmentId === undefined
          ? ''
          : props.history.location.state.establishmentId,

      establishmentName: !props.history.location.state.establishmentName
        ? ''
        : props.history.location.state.establishmentName,

      campaingId:
        props.history.location.state.campaingId === undefined
          ? ''
          : props.history.location.state.campaingId,

      campaingName:
        props.history.location.state.campaingName === undefined
          ? ''
          : props.history.location.state.campaingName,

      data:
        props.history.location.state.data === undefined ? '' : props.history.location.state.data,

      eventMergeList: [],
      eventListMerge: [],
      establishmentList: [],

      each: 1,
      voucherEach:
        props.history.location.state.voucherEach === undefined
          ? null
          : props.history.location.state.voucherEach,

      quizKey: '',
      quizValue: '',

      eventName: '',
      eventValue: 1,

      openModal: false,
      indexCrop: 0,

      oldThumbnail: null,
      oldPhoto: null,

      openCheck: false,
      messageAlert: '',
      errorVerification: false,

      openSelect: false,
      nameValue: '',

      loading: false,
      textOpen: '',

      saveButtonIsDisabled: false,

      currentEvents: eventList,

      isUpdateCampaign: props.history.location.state.numbersLucky !== undefined ? true : false,
    };

    window.pathname = null;

    if (this.state.downloadedThumb !== null || this.state.downloadedThumb === placeholder) {
      downloadImage('voucher', this.state.walletThumbnail).then((downloadedImage) => {
        this.setState({ downloadedThumb: downloadedImage });
      });
    }

    if (this.state.downloadedPhoto !== null || this.state.downloadedPhoto === placeholder) {
      downloadImage('voucher', this.state.walletPhoto).then((downloadedImage) => {
        this.setState({ downloadedPhoto: downloadedImage });
      });
    }

    this.state.nameValue =
      this.state.establishmentName === ''
        ? ''
        : {
            nome: this.state.establishmentName,
            id: this.state.establishmentId,
          };
  }

  checkInformation = () => {
    const checkInformation =
      this.state.lotterySerie === '' ||
      this.state.walletTitle === '' ||
      this.state.walletDetailsTitle === '' ||
      this.state.walletDescription === '' ||
      this.state.walletDetailsDescription === '' ||
      this.state.activationDate === null ||
      this.state.dueDate === null ||
      this.state.establishmentName === '' ||
      this.state.campaingId === '' ||
      this.state.campaingName === '' ||
      this.state.numbersLucky === ''
        ? true
        : false;

    if (!this.state.walletThumbnail && !this.state.thumbnailFile) {
      this.setState({
        openCheck: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail.',
      });
    } else if (!this.state.walletPhoto && !this.state.photoFile) {
      this.setState({
        openCheck: true,
        messageAlert: 'É necessário adicionar uma imagem de foto de detalhe.',
      });
    } else if (Object.keys(this.state.events).length < 1) {
      this.setState({
        openCheck: true,
        messageAlert: 'É necessário adicionar um valor na lista de Eventos.',
      });
    } else if (checkInformation === true) {
      this.setState({
        openCheck: true,
        messageAlert: 'Todas as informações obrigatórias devem estar preenchidas.',
      });
    } else {
      this.setState({ loading: true });

      this.props.updateCampaingTriiboComponent(
        this.state.walletTitle,
        this.state.walletDetailsTitle,
        this.state.walletDescription,
        this.state.walletDetailsDescription,
        this.state.walletThumbnail,
        this.state.walletPhoto,
        this.state.thumbnailFile,
        this.state.photoFile,
        this.state.lotterySerie,
        this.state.notificationMessage,
        this.state.quizzes,
        this.state.events,
        this.state.voucherEach,
        this.state.voucherQuantity,
        this.state.activationDate,
        this.state.dueDate,
        this.state.establishmentId,
        this.state.establishmentName,
        this.state.campaingId,
        this.state.campaingName,
        this.state.numbersLucky,
        this.state.isUpdateCampaign
      );
    }
  };

  handleOpenSelect = () => {
    this.setState({ openSelect: true, textOpen: 'Pesquise o estabelecimento' });
  };

  handleCloseSelect = () => {
    this.setState({ openSelect: false });
  };

  handleSearch = debounce((event) => {
    this.setState({
      establishmentName: event.target.value,
      textOpen: 'Carregando...',
    });

    if (event.target.value.length <= 0) {
      this.setState({ textOpen: 'Pesquise o estabelecimento' });
    }

    apiListEstablishment(event.target.value.trim(), 0, 10).then((dataEstablishiment) => {
      this.setState({ establishmentList: dataEstablishiment });
    });
  }, 100);

  handleSelect = (value) => {
    this.setState({
      nameValue: value,
      establishmentName: value.nome,
      establishmentId: value.id,
    });
  };

  // Trata o campo série impedindo o uso de espaços e caracteres especiais
  validateSpecialCharacterSerie = (serieName) => {
    let serieChecked = '';

    serieChecked = serieName.replace(/\s/g, '').replace(/[^a-zA-Z0-9]+/g, '');
    return serieChecked;
  };

  // Verifica se o valor do campo série já existe.
  ValidateSerieName = (serieName) => {
    if (this.state.isNewCampaing) {
      const dataLottery = this.state.data
        .filter((item) => {
          return item.lotterySerie === serieName;
        })
        .map((item) => item.lotterySerie)
        .includes(serieName);

      if (dataLottery) {
        this.setState({
          openCheck: true,
          messageAlert: 'O número de série já existe!',
          errorVerification: true,
          saveButtonIsDisabled: true,
        });
      } else {
        this.setState({
          saveButtonIsDisabled: false,
        });
      }
    }
  };

  //Altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    if (name === 'lotterySerie') {
      const cleanName = this.validateSpecialCharacterSerie(event.target.value);
      this.setState({ [name]: cleanName, errorVerification: false });
      return;
    }

    if (name === 'numbersLucky') {
      const regex = event.target.value.replace(/[^0-9]/g, '');
      this.setState({ [name]: regex, errorVerification: false });
      return;
    }

    if (name === 'title' || name === 'message' || name === 'url') {
      this.setState({
        notificationMessage: {
          ...this.state.notificationMessage,
          [name]: event.target.value,
        },
      });
    }

    this.setState({ [name]: event.target.value, errorVerification: false });
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleCloseAlert = () => {
    this.setState({ openCheck: false });
  };

  addQuiz = () => {
    let oldQuiz = this.state.quizzes;
    let newQuiz = {};
    newQuiz = { ...oldQuiz, [this.state.quizKey]: this.state.quizValue };
    this.setState({ quizzes: newQuiz, quizKey: '', quizValue: '' });
  };

  removeQuiz = (item) => {
    let aux = this.state.quizzes;
    delete aux[item];
    this.setState({ quizzes: aux });
  };

  addEvent = () => {
    let newEvent = this.state.events;

    let oldVoucher = this.state.voucherQuantity;
    let newVoucher = {};

    let oldListEvent = this.state.eventListMerge;
    let newListEvent = [];

    let newVoucherEach = {};

    if (this.state.events.includes(this.state.eventName)) {
      this.setState({
        openCheck: true,
        messageAlert: 'Evento selecionado já contém na lista',
      });
      return;
    }

    if (this.state.eventValue <= 1) {
      newEvent.push(this.state.eventName);
      newListEvent = [
        ...oldListEvent,
        {
          eventName: this.state.eventName,
          eventValue: this.state.eventValue,
          voucherEach: this.state.each,
        },
      ];

      newVoucherEach = {
        ...this.state.voucherEach,
        [this.state.eventName]: parseInt(this.state.each),
      };
      this.setState({
        events: newEvent,
        eventListMerge: newListEvent,
        oldVoucher: {
          ...oldVoucher,
          [this.state.eventName]: parseInt(this.state.each),
        },
        voucherEach: newVoucherEach,
        each: 0,
        eventName: '',
        eventValue: 1,
      });
    } else {
      newEvent.push(this.state.eventName);
      newVoucher = {
        ...oldVoucher,
        [this.state.eventName]: parseInt(this.state.eventValue),
      };
      newListEvent = [
        ...oldListEvent,
        {
          eventName: this.state.eventName,
          eventValue: this.state.eventValue,
          voucherEach: this.state.each,
        },
      ];

      newVoucherEach = {
        ...this.state.voucherEach,
        [this.state.eventName]: parseInt(this.state.each),
      };

      this.setState({
        events: newEvent,
        voucherQuantity: newVoucher,
        eventListMerge: newListEvent,
        voucherEach: newVoucherEach,
        eventName: '',
        eventValue: 1,
        each: 0,
      });
    }
  };

  removeEvent = (item) => {
    const newEvents = this.state.events.filter((event) => event !== item);
    const newEventList = this.state.eventListMerge.filter((event) => event.eventName !== item);
    // const newVoucherEach = delete this.state.voucherEach[item];
    let aux = this.state.voucherEach;
    delete aux[item];
    // aux = aux.filter((i) => {
    //   return i != null;
    // });
    this.setState({
      events: newEvents,
      eventListMerge: newEventList,
      voucherEach: aux,
    });
  };

  fileChangedHandler = (name) => (event) => {
    if (name === 'fotoThumb') {
      this.setState({ oldThumbnail: this.state.walletThumbnail });

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        this.setState(
          {
            imageToCrop: data,
            imageCroped: 'thumbnailFile',
          },
          this.handleOpenModal
        );
      }
    }

    if (name === 'detailPhoto') {
      this.setState({ oldPhoto: this.state.walletPhoto });

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        this.setState(
          {
            imageToCrop: data,
            imageCroped: 'photoFile',
          },
          this.handleOpenModal
        );
      }
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    let newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    let endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    this.setState(
      {
        [newCrop]: this.state.imageCroped === undefined ? null : node,
        indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
        goBack: true,
        imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
      },
      () => {
        if (endArray === undefined) {
          this.handleCloseModal();
        }
      }
    );
  }

  async componentDidMount(prevProps, prevState) {
    if (this.state.events && this.state.voucherQuantity) {
      if (this.state.voucherEach) {
        const eventsFormated = this.state.events.map((event) => {
          let eventName = event;
          let eventValue = 1;
          let voucherEach = 1;

          const voucherQuantityArr = Object.entries(this.state.voucherQuantity).map((item) => {
            const [key, value] = item;
            return { key, value };
          });

          const voucherEachArr = Object.entries(this.state.voucherEach).map((item) => {
            const [key, value] = item;
            return { key, value };
          });

          voucherQuantityArr.map((item) => {
            if (item.key === event) {
              eventValue = item.value;
            }
          });

          voucherEachArr.map((item) => {
            if (item.key === event) {
              voucherEach = item.value;
            }
          });

          return {
            eventName,
            eventValue,
            voucherEach,
          };
        });

        this.setState({
          eventListMerge: eventsFormated,
        });
      } else {
        const eventsFormated = this.state.events.map((event) => {
          let eventName = event;
          let eventValue = 1;
          let voucherEach = 1;

          const voucherQuantityArr = Object.entries(this.state.voucherQuantity).map((item) => {
            const [key, value] = item;
            return { key, value };
          });

          voucherQuantityArr.map((item) => {
            if (item.key === event) {
              eventValue = item.value;
            }
          });

          return {
            eventName,
            eventValue,
            voucherEach,
          };
        });

        this.setState({
          eventListMerge: eventsFormated,
        });
      }

      await getAllEvents()
        .then((res) => {
          const newArr = [];
          res.forEach((element) => {
            newArr.push(element.eventName);
          });
          this.setState({
            currentEvents: newArr,
          });
        })
        .catch(() => {
          this.setState({
            currentEvents: eventList,
          });
        });

      // console.log("evento formatado: ", eventsFormated);
    }
  }

  goBack = () => {
    window.history.back();
  };

  render = () => {
    const { classes } = this.props;
    const selectEstablishiment = this.state.establishmentList;
    const loadingSelect = this.state.openSelect && selectEstablishiment.length === 0;

    if (this.props.loading && this.state.loading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    } else if (window.pathname !== null) {
      return (
        <Redirect
          to={{
            pathname: window.pathname,
          }}
        />
      );
    } else {
      return (
        <div style={{ padding: '4rem 32px' }}>
          <HeaderTitle pageInfo="Campanha Triibo" />
          <Typography variant="h6" color="primary" style={{ fontWeight: '600', marginTop: '2rem' }}>
            {' '}
            Informações da Campanha
          </Typography>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.checkInformation();
            }}
          >
            <FormControl className="input-field">
              <TextField
                required
                className="input-field"
                type="text"
                value={this.state.walletTitle === null ? '' : this.state.walletTitle}
                id="walletTitle"
                label="Título:"
                onChange={this.handleChange('walletTitle')}
              />

              <TextField
                required
                className="input-field"
                type="text"
                value={this.state.walletDetailsTitle === null ? '' : this.state.walletDetailsTitle}
                id="walletDetailsTitle"
                label="Detalhe do Título:"
                onChange={this.handleChange('walletDetailsTitle')}
              />

              <TextField
                required
                className="input-field"
                type="text"
                value={this.state.walletDescription === null ? '' : this.state.walletDescription}
                id="walletDescription"
                label="Descrição:"
                onChange={this.handleChange('walletDescription')}
              />

              <TextField
                required
                className="input-field"
                type="text"
                value={
                  this.state.walletDetailsDescription === null
                    ? ''
                    : this.state.walletDetailsDescription
                }
                id="walletDetailsDescription"
                label="Detalhe da Descrição:"
                onChange={this.handleChange('walletDetailsDescription')}
              />

              <TextField
                required
                error={this.state.errorVerification}
                className="input-field"
                type="text"
                disabled={this.props.history.location.state.lotterySerie && true}
                value={this.state.lotterySerie === null ? '' : this.state.lotterySerie}
                id="lotterySerie"
                label="Série:"
                onChange={this.handleChange('lotterySerie')}
                onBlur={() =>
                  this.ValidateSerieName(
                    this.state.lotterySerie === null ? '' : this.state.lotterySerie
                  )
                }
              />

              <Autocomplete
                id="establishmentName"
                value={this.state.nameValue || null}
                onChange={(_, newValue) => {
                  if (newValue !== null) {
                    this.handleSelect(newValue);
                  }
                }}
                open={this.state.openSelect}
                onOpen={this.handleOpenSelect}
                onClose={this.handleCloseSelect}
                disableClearable
                getOptionLabel={(option) => option?.nome}
                options={selectEstablishiment}
                noOptionsText="Nenhum estabelecimento encontrado"
                loadingText={this.state.textOpen}
                loading={loadingSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nome do Estabelecimento"
                    className="input-field"
                    value={this.state.establishmentName}
                    onChange={this.handleSearch}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />

              <TextField
                required
                className="input-field"
                type="text"
                value={this.state.campaingId === null ? '' : this.state.campaingId}
                id="campaingId"
                label="Id da Campanha"
                onChange={this.handleChange('campaingId')}
              />

              <TextField
                required
                className="input-field"
                type="text"
                value={this.state.campaingName === null ? '' : this.state.campaingName}
                id="campaingName"
                label="Nome da Campanha"
                onChange={this.handleChange('campaingName')}
              />

              <TextField
                required
                className="input-field"
                type="text"
                disabled={this.props.history.location.state.numbersLucky && true}
                value={this.state.numbersLucky === null ? '' : this.state.numbersLucky}
                id="numbersLucky"
                label="Quantidade de cupons da sorte"
                onChange={this.handleChange('numbersLucky')}
              />
              <FormGroup>
                {/* IMAGENS */}
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ fontWeight: '600', marginTop: '2rem' }}
                >
                  {' '}
                  Imagens da Campanha
                </Typography>
                <div className="flex-external">
                  {/* Fotos thumbnail */}
                  <div
                    className="img-thumb"
                    style={{
                      maxWidth: '400px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      className="style-text"
                      style={{ fontWeight: '550', textAlign: 'center' }}
                    >
                      Thumbnail da Campanha (Visualização na Carteira)
                    </Typography>

                    {/* Placeholder do thumbnail */}
                    <img
                      alt="Placeholder thumbnail"
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        maxHeight: '447px',
                        position: 'relative',
                        top: '0px',
                        left: '0px',
                      }}
                      src={thumbnail}
                    />
                    {/* Thumbnail miniatura */}
                    <img
                      alt="Thumbnail miniatura"
                      className="img-position-triibo"
                      src={
                        this.state.thumbnailFile
                          ? this.state.thumbnailFile
                          : this.state.downloadedThumb
                          ? this.state.downloadedThumb
                          : placeholder
                      }
                    />

                    {/* Botão add thumbnail */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        value=""
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={this.fileChangedHandler('fotoThumb')}
                        id="thumbnail"
                      />

                      <label htmlFor="thumbnail">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            marginTop: '15px',
                            fontWeight: '550',
                            marginLeft: '20px',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          startIcon={<AddPhotoAlternate />}
                        >
                          Adicionar Foto
                        </Button>

                        <Tooltip title="As imagens devem ser 4x3.">
                          <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                        </Tooltip>
                      </label>
                    </div>
                  </div>

                  {/* Fotos Detalhes */}
                  <div
                    className="Foto-detalhe"
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      className="style-text"
                      style={{ fontWeight: '550', textAlign: 'center' }}
                    >
                      Foto de Detalhe da Campanha (Visualização na Carteira)
                    </Typography>

                    {/* Placeholder Fotos Detalhe */}
                    <img
                      alt="Placeholder foto de detalhe "
                      style={{
                        width: '100%',
                        maxWidth: '392px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                      src={detailPhoto}
                    />
                    {/* Fotos Detalhe */}
                    <img
                      alt="Foto de Detalhe"
                      src={
                        this.state.photoFile
                          ? this.state.photoFile
                          : this.state.downloadedPhoto
                          ? this.state.downloadedPhoto
                          : placeholder
                      }
                      width="100%"
                      style={{
                        maxWidth: '393px',
                        position: 'absolute',
                        left: '0',
                        maxHeight: '295px',
                        objectFit: 'cover',
                      }}
                    />

                    {/* Botão add foto detalhe */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        value=""
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={this.fileChangedHandler('detailPhoto')}
                        id="detailPhoto"
                      />

                      <label htmlFor="detailPhoto">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            marginTop: '15px',
                            fontWeight: '550',
                            marginLeft: '20px',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          startIcon={<AddPhotoAlternate />}
                        >
                          Adicionar Foto de Detalhe
                        </Button>

                        <Tooltip title="As imagens devem ser 4x3.">
                          <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                        </Tooltip>
                      </label>
                    </div>
                  </div>
                  {/* Fim das imgs */}
                </div>
              </FormGroup>

              {/* INICIO EVENTOS */}
              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: '600', marginTop: '2rem' }}
              >
                Eventos
              </Typography>

              <div className={classes.containerAddEvent}>
                <FormControl className={classes.inputAddEvent}>
                  <InputLabel id="eventName-select-label">Nome do Evento</InputLabel>
                  <Select
                    labelId="eventName-select"
                    id="eventName-select"
                    value={this.state.eventName}
                    onChange={this.handleChange('eventName')}
                  >
                    {this.state.currentEvents.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  type="number"
                  value={this.state.eventValue === null ? '' : this.state.eventValue}
                  id="eventValue"
                  label="Valor do Evento:"
                  onChange={this.handleChange('eventValue')}
                  className={classes.inputAddEvent}
                  disabled={!this.state.eventName}
                />

                <TextField
                  type="number"
                  value={this.state.each}
                  id="each"
                  label="A cada"
                  onChange={this.handleChange('each')}
                  // className={classes.inputAddEvent}
                  disabled={!this.state.eventName}
                />

                <Button
                  onClick={() => this.addEvent()}
                  disabled={this.state.eventName.length === 0 || this.state.eventValue.length === 0}
                  className={classes.buttonAddEvent}
                  variant="contained"
                  color="primary"
                >
                  Adicionar evento
                </Button>
              </div>

              <Typography
                variant="subtitle2"
                gutterBottom
                color="secondary"
                className="style-text"
                style={{ fontWeight: '550' }}
              >
                Lista de Eventos
              </Typography>

              <Grid
                md={12}
                sm={12}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  maxHeight: '125px',
                  overflowY: 'scroll',
                }}
              >
                {this.state.eventListMerge.length === 0 ? (
                  <List>
                    <ListItem dense button>
                      <ListItemText primary={`Nenhum valor encontrado`} />
                      {/* {console.log(this.state.eventMergeList)} */}
                    </ListItem>
                  </List>
                ) : (
                  <List>
                    {this.state.eventListMerge.map((item, index) => (
                      <ListItem key={index} role={undefined} dense button>
                        <ListItemText
                          primary={`${item.eventName} | Valor: ${item.eventValue} | A cada: ${item.voucherEach}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => this.removeEvent(item.eventName)}
                            edge="end"
                            aria-label="Comments"
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>

              {/* INICIO DA NOTIFICACAO */}
              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: '600', marginTop: '2rem' }}
              >
                {' '}
                Notificação
              </Typography>
              <div className={classes.containerAddEvent}>
                <TextField
                  type="text"
                  value={
                    this.state.notificationMessage.title === null
                      ? ''
                      : this.state.notificationMessage.title
                  }
                  id="titleNotification"
                  label="Título da notificação:"
                  onChange={this.handleChange('title')}
                  className={classes.inputAddEvent}
                />
                <TextField
                  type="text"
                  value={
                    this.state.notificationMessage.message === null
                      ? ''
                      : this.state.notificationMessage.message
                  }
                  id="messageNotification"
                  label="Mensagem da notificação:"
                  onChange={this.handleChange('message')}
                  className={classes.inputAddEvent}
                />
                <TextField
                  type="text"
                  value={
                    this.state.notificationMessage.url === null
                      ? ''
                      : this.state.notificationMessage.url
                  }
                  id="urlNotification"
                  label="URL da notificação:"
                  onChange={this.handleChange('url')}
                  className={classes.inputAddEvent}
                />
              </div>

              {/* INICIO QUIZ */}
              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: '600', marginTop: '2rem' }}
              >
                {' '}
                Informações do Quiz
              </Typography>

              <div className={classes.containerAddEvent}>
                <TextField
                  type="text"
                  value={this.state.quizKey === null ? '' : this.state.quizKey}
                  id="quizKey"
                  label="ID do Typeform:"
                  onChange={this.handleChange('quizKey')}
                  className={classes.inputAddEvent}
                />

                <TextField
                  type="text"
                  value={this.state.quizValue === null ? '' : this.state.quizValue}
                  id="quizValue"
                  label="ID da promoção:"
                  onChange={this.handleChange('quizValue')}
                  className={classes.inputAddEvent}
                  disabled={!this.state.quizKey}
                />

                <Button
                  onClick={() => this.addQuiz()}
                  disabled={this.state.quizKey.length === 0 || this.state.quizValue.length === 0}
                  className={classes.buttonAddEvent}
                  variant="contained"
                  color="primary"
                >
                  Adicionar quiz
                </Button>
              </div>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="secondary"
                className="style-text"
                style={{ fontWeight: '550' }}
              >
                Lista de Quizzes
              </Typography>

              <Grid
                md={12}
                sm={12}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '2rem',
                  maxHeight: '125px',
                  overflowY: 'scroll',
                }}
              >
                {Object.keys(this.state.quizzes).length < 1 ? (
                  <List>
                    <ListItem dense button>
                      <ListItemText primary={`Nenhum valor encontrado`} />
                    </ListItem>
                  </List>
                ) : (
                  <List>
                    {Object.keys(this.state.quizzes).map((item) => (
                      <ListItem key={item} role={undefined} dense button>
                        <ListItemText primary={`${item} : ${this.state.quizzes[item]}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => this.removeQuiz(item)}
                            edge="end"
                            aria-label="Comments"
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>

              <TextField
                id="datetime-local"
                label="Data de ativação"
                type="datetime-local"
                value={this.state.activationDate}
                onChange={this.handleChange('activationDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                color="primary"
                required={true}
              />

              <br />

              <TextField
                id="datetime-local"
                label="Data de desativação"
                type="datetime-local"
                value={this.state.dueDate}
                onChange={this.handleChange('dueDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                color="primary"
                required={true}
              />
            </FormControl>

            <Button
              style={{
                fontWeight: '550',
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="secondary"
              onClick={() => this.goBack()}
              startIcon={<ArrowBack />}
            >
              Voltar
            </Button>

            <Button
              style={{
                fontWeight: '550',
                marginTop: '20px',
                marginLeft: '10px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
              type="submit"
              id="buttonSave"
              disabled={this.state.saveButtonIsDisabled}
              startIcon={<Save />}
            >
              Salvar
            </Button>
          </form>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.openCheck}
            autoHideDuration={4000}
            onClose={() => this.setState({ openCheck: false })}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.messageAlert}</span>}
          />

          {/* MODAL PARA CROPAR IMAGEM */}
          <Modal open={this.state.openModal}>
            <div style={getModalStyle()}>
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                style={{ fontWeight: '600', textAlign: 'center' }}
              >
                Editar Imagem
              </Typography>

              <div className="style-modalCrop">
                {this.state.imageToCrop === undefined || this.state.imageToCrop === null ? null : (
                  <Cropper
                    style={{ overflow: 'hidden' }}
                    src={this.state.imageToCrop[this.state.indexCrop]}
                    ref={(ref) => {
                      this.image = ref;
                    }}
                    ratio={4 / 3}
                  />
                )}
              </div>

              <div
                style={{
                  position: 'fixed',
                  bottom: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: '18px',
                  marginLeft: '-6',
                }}
              >
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleCloseModal()}
                  startIcon={<ArrowBack />}
                >
                  Cancelar
                </Button>

                <Button
                  style={{
                    fontWeight: '550',
                    marginLeft: '10px',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.cropImage('image')}
                  startIcon={<CropFree />}
                >
                  Cortar Imagem
                </Button>
              </div>
            </div>
          </Modal>
          {/* FIM MODAL PARA CROPAR IMAGEM */}
        </div>
      );
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.campaingTriiboComponent.loading,
    success: state.campaingTriiboComponent.success,
    error: state.campaingTriiboComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateCampaingTriiboComponent: (
    walletTitle,
    walletDetailsTitle,
    walletDescription,
    walletDetailsDescription,
    walletThumbnail,
    walletPhoto,
    thumbnailFile,
    photoFile,
    lotterySerie,
    notificationMessage,
    quizzes,
    events,
    voucherEach,
    voucherQuantity,
    activationDate,
    dueDate,
    establishmentId,
    establishmentName,
    campaingId,
    campaingName,
    numbersLucky,
    isUpdateCampaign
  ) =>
    updateCampaingTriiboAction(
      dispatch,
      walletTitle,
      walletDetailsTitle,
      walletDescription,
      walletDetailsDescription,
      walletThumbnail,
      walletPhoto,
      thumbnailFile,
      photoFile,
      lotterySerie,
      notificationMessage,
      quizzes,
      events,
      voucherEach,
      voucherQuantity,
      activationDate,
      dueDate,
      establishmentId,
      establishmentName,
      campaingId,
      campaingName,
      numbersLucky,
      isUpdateCampaign
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(LotteryEdit);

