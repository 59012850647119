import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export function updatePost(id, data) {
  return new Promise(function (resolve, reject) {
    authToken().then((token) => {
      const config = {
        method: 'put',
        url: `${cfac22('API_HOST_V2')}/feedPosts/${id}`, //id do post
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
