import React, { useState, useEffect, useCallback } from 'react';
import { Route } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Checkbox,
  Snackbar,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { MdContentCopy } from 'react-icons/md';

import placeholder from 'styles/assets/placeholder/placeholder_card.png';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { updateDatabase } from 'js/library/services/DatabaseManager';
import { downloadImage } from 'js/library/services/StorageManager';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { stableSort, getSorting } from 'js/library/utils/helpers';

const DataTableCards = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('priority');
  const [dataTotal, setDataTotal] = useState([]);
  const [dataPesquisa, setDataPesquisa] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pesquisa, setPesquisa] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const [organizations, setOrganizations] = useState({});
  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {
    getDataListRestApi('Cards')
      .then((dataReceived) => {
        const data = Object.keys(dataReceived).map((item) => {
          dataReceived[item].key = item;
          dataReceived[item].downloadedThumb = placeholder;
          return dataReceived[item];
        });
        setDataTotal(data);
        setDataPesquisa(data);
      })
      .catch((error) => {});

    fetchOrganizations();
  }, []);

  const fetchOrganizations = useCallback(async () => {
    const result = await getDataListRestApi('Organizations/');
    if (result) {
      setOrganizations(result);
    }
  }, []);

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadImageFunction = (fotoThumb, key, downloadedThumb) => {
    if (fotoThumb !== undefined && downloadedThumb === placeholder) {
      let dataCopy = [...dataTotal];
      const indexTotal = dataCopy.findIndex((x) => x.key === key);

      let storageInfo = fotoThumb.split('/');
      downloadImage(storageInfo[0], storageInfo[1])
        .then((downloadedImage) => {
          dataCopy[indexTotal].downloadedThumb = downloadedImage;
          setDataTotal(dataCopy);
          setDataPesquisa(dataCopy);
        })
        .catch(() => {
          // console.log("Foto não encontrada:", fotoThumb);
        });
    }
  };

  const handleChange = (name) => (event) => {
    cardFilter(event.target.value, activeOnly);
    if (name === 'pesquisa') setPesquisa(event.target.value);
  };

  const handleCheck = (name) => (event) => {
    cardFilter(pesquisa, event.target.checked);
    if (name === 'activeOnly') setActiveOnly(event.target.checked);
  };

  const cardFilter = (pesquisa, ativos) => {
    let dataTodos = [...dataTotal];
    let dataFiltrada = [];

    if (pesquisa.length === 0) {
      dataFiltrada = ativos ? dataTodos.filter((card) => card.isPublished) : dataTodos;
    } else {
      dataFiltrada = dataTodos.filter((card) => {
        const match =
          card.title?.toLowerCase().includes(pesquisa.toLowerCase()) ||
          card.subtitle?.toLowerCase().includes(pesquisa.toLowerCase()) ||
          card.keyWordsSet?.toString().toLowerCase().includes(pesquisa.toLowerCase());
        return (
          match &&
          (!ativos ||
            (card.isPublished && card.enableDate < Date.now() && card.disableDate > Date.now()))
        );
      });
    }

    setDataPesquisa(dataFiltrada);
  };

  const copyCards = async (cardKey) => {
    try {
      const card = await getDataListRestApi(`Cards/${cardKey}`);
      const copyCardKey = firebaseDatabase.ref('Cards').push().key;

      card.cardId = copyCardKey;
      card.title += ' - Cópia';

      await updateDatabase('Cards', card, copyCardKey, null);
      window.location.reload();
    } catch (err) {
      setMessageAlert('❌ Erro ao duplicar card.');
    }
  };

  const renderOrgs = (orgs) =>
    orgs.map((org, index) => `${organizations[org]?.name}${index === orgs.length - 1 ? '' : ','}`);

  return (
    <Route
      render={({ history }) => (
        <div style={{ maxHeight: '100%', width: '100%', overflowY: 'auto' }}>
          <FormControl fullWidth style={{ marginBottom: '20px', marginTop: '20px' }}>
            <InputLabel htmlFor="adornment-amount">Pesquise seu card:</InputLabel>
            <Input
              id="adornment-amount"
              value={pesquisa}
              onChange={handleChange('pesquisa')}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
          <Checkbox
            checked={activeOnly}
            onChange={handleCheck('activeOnly')}
            value={'activeOnly'}
          />
          Mostrar apenas cards ativos
          <div style={{ maxHeight: '100%', width: '100%', overflowY: 'auto' }}>
            <Table stickyHeader aria-labelledby="tableTitle" style={{ marginTop: '1rem' }}>
              <TableHead>
                <TableRow>
                  {props.rows.map((row) => (
                    <TableCell
                      key={row.id}
                      numeric={row.numeric}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === row.id ? order : false}
                      style={{ fontWeight: '550' }}
                    >
                      <Tooltip
                        title={
                          ['thumbnail', 'platform', 'keyWordsSet', 'duplicate'].includes(row.id)
                            ? row.label
                            : 'Ordenar'
                        }
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={
                            !['thumbnail', 'platform', 'keyWordsSet', 'duplicate'].includes(row.id)
                              ? handleRequestSort(row.id)
                              : null
                          }
                          style={{
                            cursor: !['thumbnail', 'platform', 'keyWordsSet', 'duplicate'].includes(
                              row.id
                            )
                              ? 'pointer'
                              : 'default',
                          }}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(dataPesquisa, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, i) => (
                    <TableRow id={data.key} key={data.key} hover>
                      <TableCell scope="row" padding="none">
                        <img
                          src={data.downloadedThumb}
                          onLoad={() =>
                            downloadImageFunction(
                              data.images.mobile,
                              data.key,
                              data.downloadedThumb
                            )
                          }
                          alt="Thumb do Card"
                          className="thumbnail"
                          width="65px"
                        />
                      </TableCell>
                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({ pathname: `${props.pathName}${data.key}`, state: data })
                        }
                      >
                        {data.title}
                      </TableCell>
                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({ pathname: `${props.pathName}${data.key}`, state: data })
                        }
                      >
                        {data.subtitle}
                      </TableCell>
                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({ pathname: `${props.pathName}${data.key}`, state: data })
                        }
                      >
                        {data.keyWordsSet ? Object.values(data.keyWordsSet).join(', ') : '---'}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 200,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        scope="row"
                        onClick={() =>
                          history.push({ pathname: `${props.pathName}${data.key}`, state: data })
                        }
                      >
                        {data.orgs ? renderOrgs(data.orgs) : '---'}
                      </TableCell>
                      <TableCell
                        scope="row"
                        style={{ fontWeight: 500, color: data.isPublished ? 'green' : 'red' }}
                      >
                        {data.isPublished ? 'Ativo' : 'Inativo'}
                      </TableCell>
                      <TableCell
                        scope="row"
                        style={{ textAlign: 'center' }}
                        padding="none"
                        onClick={() => copyCards(data.key)}
                      >
                        <MdContentCopy style={{ cursor: 'pointer' }} size={20} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={dataPesquisa.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <Snackbar
            open={!!messageAlert}
            autoHideDuration={3000}
            message={messageAlert}
            onClose={() => setMessageAlert('')}
          />
        </div>
      )}
    />
  );
};

export default DataTableCards;

