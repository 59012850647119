import React, { useState } from 'react';
import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';

import { MdKeyboardArrowDown } from 'react-icons/md';

const Main = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  cursor: pointer;
`;

const DropDownContainer = styled.div`
  /* width: 10.5em; */
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  position: relative;
  transition: 0.35s ease-in-out;
`;

const DropDownHeader = styled.div`
  z-index: 3;
  width: 100%;
  margin-bottom: 0.8em;
  padding: 0.4rem 0.6rem;
  /* padding: 0.4em 2em 0.4em 1em; */
  border: 1px solid ${D.triiboGray};
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 0.8rem;
  color: ${D.triiboGray};
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowIcon = styled(MdKeyboardArrowDown)`
  font-size: 2rem;
  line-height: 0;
  color: ${D.triiboPurple};

  ${({ open }) =>
    !open &&
    `
        transform: rotate(0deg);
        transition: 0.35s ease-in-out;

    `}

  ${({ open }) =>
    open &&
    `
        transform: rotate(-180deg);
        transition: 0.35s ease-in-out;

    `}
`;

const DropDownListContainer = styled('div')`
  z-index: -1;
  position: absolute;
  width: 100%;
  top: 1rem;
`;

const DropDownList = styled.ul`
  padding: 0;
  padding-top: 1.8rem;
  margin: 0;
  background: #ffffff;
  border-radius: 10px;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: ${D.triiboBlack};
  font-size: 1rem;
  font-weight: 500;
`;

const Choice = styled.li`
  list-style: none;
  padding: 0.8rem;
  gap: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
  color: ${D.triiboGray};
  transition: 0.2s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const ChoiceIcon = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionHolder = (props) => {
  const { onClick } = props;

  return (
    <div onClick={onClick}>
      <Choice>
        <ChoiceIcon>{props.icon}</ChoiceIcon>
        {props.placeholdeText}
      </Choice>
    </div>
  );
};

const Dropdown = (props) => {
  const { formState, setFormState, setFinalFormState, socialsProps, numbersProps } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Main>
      <DropDownContainer>
        {formState.status && formState.status !== 'aberto' ? (
          <DropDownHeader>
            {props.placeholder}
            <ArrowIcon open={isOpen} />
          </DropDownHeader>
        ) : (
          <DropDownHeader onClick={toggling}>
            {props.placeholder}
            <ArrowIcon open={isOpen} />
          </DropDownHeader>
        )}
        {isOpen && (
          <DropDownListContainer>
            {!props.socials ? (
              <DropDownList>
                {numbersProps.map(({ icon, placeholdeText, type }, index) => (
                  <OptionHolder
                    key={index}
                    icon={icon}
                    placeholdeText={placeholdeText}
                    onClick={(e) => {
                      e.preventDefault();
                      let newArr = setFinalFormState ? formState.contato : formState.contatoTriibo;
                      newArr.push({
                        type: type,
                        value: '',
                      });

                      setFormState({
                        ...formState,
                        contatoTriibo: newArr,
                        contato: newArr,
                      });

                      // setFinalFormState &&
                      //   setFinalFormState((prevState) => {
                      //     return {
                      //       ...prevState,
                      //       contatos: newArr,
                      //     };
                      //   });
                      setIsOpen(false);
                    }}
                  ></OptionHolder>
                ))}
              </DropDownList>
            ) : (
              <DropDownList>
                {socialsProps.map(({ icon, placeholdeText, type }, index) => (
                  <OptionHolder
                    key={index}
                    icon={icon}
                    placeholdeText={placeholdeText}
                    onClick={(e) => {
                      e.preventDefault();
                      let newArr = formState.redesSociais;
                      newArr.push({
                        type: type,
                        value: '',
                      });
                      setFormState({
                        ...formState,
                        redesSociais: newArr,
                      });
                      // console.log("***redesSociais", formState.redesSociais);
                      setIsOpen(false);
                    }}
                  ></OptionHolder>
                ))}
              </DropDownList>
            )}
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
};

export default Dropdown;

