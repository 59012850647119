import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// context import
import { EstablishmentContext } from '../Context/Establishment';

// modal animations config
import { useSpring, animated } from 'react-spring/web.cjs';

import {
  Box,
  Button,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

// icons
import { Cancel as CancelIcon } from '@mui/icons-material';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'center',

    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),

    textTransform: 'capitalize',
    fontWeight: 600,
  },

  content: {
    maxWidth: '100%',
    overflow: 'auto',
  },

  iconButton: {
    float: 'right',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

    alignSelf: 'center',

    [theme.breakpoints.down('xs')]: {
      alignSelf: 'auto',
      margin: '22px',
    },
  },
}));

const options = [
  {
    title: 'Quantidade de cupons consumidos',
    value: 'rep.portal_estabelecimento',
  },
  { title: 'Quantidade de clientes com consumo', value: 'rep.consumo_voucher' },
  {
    title: 'Quantidade de registros de visitas',
    value: 'rep.voucher_registro_visita',
  },
  { title: 'Quantidade de clientes novos', value: 'rep.clientes_novos' },
];

function SelectChartsInput({ closeModal }) {
  const [chartValue, setChartValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [favGraphics, setFavGraphics] = useState([]);

  const location = useLocation();

  // context state config
  const context = useContext(EstablishmentContext);
  const { selectedModalType } = context;

  const classes = useStyles();

  const handleChange = (event) => {
    const valueSelect = event.target.value;
    setChartValue(valueSelect);

    if (!favGraphics.includes(valueSelect)) {
      setFavGraphics([valueSelect]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSetDataType = () => {
    if (favGraphics) {
      switch (selectedModalType) {
        case 'pie':
          const favPieGraphics = JSON.stringify(favGraphics);
          localStorage.setItem(
            `${location.state.id}_favPieGraphics`,
            favPieGraphics
          );
          break;

        case 'firstBar':
          const favFirstGraphics = JSON.stringify(favGraphics);
          localStorage.setItem(
            `${location.state.id}_favFirstGraphics`,
            favFirstGraphics
          );
          break;

        default:
          const favSecondGraphics = JSON.stringify(favGraphics);
          localStorage.setItem(
            `${location.state.id}_favSecondGraphics`,
            favSecondGraphics
          );
          break;
      }

      closeModal();
    }
  };

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel id='select-graphic'>
        Selecione o tipo de informação
      </InputLabel>
      <Select
        labelId='select-graphic'
        id='select-graphic-open'
        variant='outlined'
        fullWidth
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={chartValue}
        onChange={handleChange}>
        {options.map((item) => (
          <MenuItem value={item}>{item.title}</MenuItem>
        ))}
      </Select>

      <br />

      <Button
        variant='contained'
        color='primary'
        classes={{ contained: classes.button }}
        onClick={handleSetDataType}>
        Alterar
      </Button>
    </FormControl>
  );
}

export const ChartDataModal = ({ closeModal }) => {
  const classes = useStyles();

  return (
    <Box width='100%'>
      <IconButton
        color='secondary'
        classes={{ colorSecondary: classes.iconButton }}
        onClick={closeModal}>
        <CancelIcon />
      </IconButton>

      <br />

      <Box display='flex' flexDirection='column'>
        <Typography variant='h5' component='span' align='center'>
          Alterar gráfico
        </Typography>

        <br />

        <SelectChartsInput {...{ closeModal }} />
      </Box>
    </Box>
  );
};

// modal animation config
export const Fade = React.memo(
  React.forwardRef((props, ref) => {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });

    return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
    );
  })
);

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};
