import styled from 'styled-components';

export const DragContainer = styled.div`
  padding: 1rem;
  border: 1px solid #ccc;
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 5px;
`;

export const DragContainerList = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DragItem = styled.li`
  width: 100%;
  padding: 1rem 1rem 2rem 2.5rem;
  background: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

export const CardItemInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddFiledButton = styled.button`
  max-width: 535px;
  width: 100%;
  padding: 2.5rem 0;
  background: rgba(110, 50, 150, 0.3);
  border: 1px dashed #6e3296;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  cursor: pointer;

  h4 {
    font-weight: 700;
    color: #fff;
  }
`;

export const Actions = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 42px;
  gap: 0.5rem;
`;

export const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${({ bgColor }) => bgColor};
`;

export const FieldIndexKey = styled.div`
  width: 100%;
  padding: 1rem 1rem 2rem 2.5rem;
  background: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  > h4 {
    font-weight: 700;
    max-width: 335px;
    /* width: 100%; */
  }

  :first-child() {
    flex: 1;
  }
`;

export const FieldCard = styled.div`
  width: 100%;
  padding: 1rem 1rem 2rem 2.5rem;
  background: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  > h4 {
    font-weight: 700;
    max-width: 335px;
    /* width: 100%; */
  }

  :first-child() {
    flex: 1;
  }
`;

export const AddNewFieldContainer = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: end;
`;
