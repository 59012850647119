import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { getStoreChangeRequest } from '../utils/API/Store/apiGetStoreChangeRequest';

//CRIAR TABELAS NO FIREBASE
export function createDatabase(node, data, pathname) {
  return new Promise(async (resolve, reject) => {
    await firebaseDatabase
      .ref(node)
      .push(data)
      .then(function (result) {
        window.pathname = pathname;

        if (node === 'Estabelecimento-v2') {
          data.id = result.path.pieces_[1];
        }

        if (node.indexOf('Promotions-QRCodes') >= 0) {
          data.key = result.path.pieces_[2];
        }

        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//EDITAR TABELAS NO FIREBASE
export function updateDatabase(node, data, id, pathname) {
  return new Promise((resolve, reject) => {
    // return reject
    if (id !== null) {
      firebaseDatabase
        .ref(node)
        .child(id)
        .update(data, function (error) {
          if (!error) {
            if (pathname !== null) {
              window.pathname = pathname;
            }

            if (node === 'Estabelecimento-v2') {
              data.id = id;
            }
            resolve(data);
          } else {
            reject(error);
          }
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

//BUSCAR DADOS DO BANCO
export function getDataList(nodePath, size = 10) {
  return new Promise((resolve, reject) => {
    firebaseDatabase
      .ref(nodePath)
      .limitToFirst(size)
      .once('value', function (snapshot) {
        let items = [];
        snapshot.forEach((childSnapshot) => {
          let item = childSnapshot.val();
          item['key'] = childSnapshot.key;
          items.push(item);
        });
        resolve(items);
      })
      .catch(function (error) {
        reject(null);
      });
  });
}

//CRIAR OU ATUALIZAR TABELAS NO FIREBASE COM PUSH
export function pushDatabase(node, key, data, pathname) {
  return new Promise((resolve, reject) => {
    if (key !== null) {
      firebaseDatabase
        .ref(node)
        .child(key)
        .push(data)
        .then(function (result) {
          window.pathname = pathname;
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

//REMOVE UM DADO DO BANCO
export function removeDatabase(node, id) {
  return new Promise((resolve, reject) => {
    if (id !== null) {
      firebaseDatabase
        .ref(node + '/' + id)
        .remove()
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    }
  });
}

/* ------------------------------------------------------------------------------ */

//FAZER UPDATE NO STORE
export function updateEstablishmentStore(data) {
  return new Promise((resolve) => {
    resolve(data);
  });
}

/* ------------------------------------------------------------------------------ */


export function updateDatabaseArray(node, data, id) {
  return new Promise((resolve, reject) => {
    Object(data).map((item, i) =>
      firebaseDatabase
        .ref(node)
        .child(id[i])
        .update(item, function (error) {
          if (!error) {
            resolve(data);
          } else {
            reject(error);
          }
        })
    );
  });
}

export function verificarAprovacaoPromocao(id, tipo, estId, estNome) {
  return new Promise((resolve, reject) => {
    if (id !== null) {
      getStoreChangeRequest(id)
        .then((result) => {
          const dataApprovals = result.response.storeApprovals;
          let storeApprovals = null;
          if (dataApprovals && Object.keys(dataApprovals).length > 0) {
            const firstApprovalKey = Object.keys(dataApprovals)[0];
            storeApprovals = dataApprovals[firstApprovalKey];
          }
          if (storeApprovals !== null) {
            resolve(storeApprovals);
          } else {
            // Provável promoção antiga. Envia como aprovado.
            const fakeApproval = {
              approvalDate: 0,
              approverId: '',
              establishmentId: estId, //Pegar informação
              establishmentName: estNome, // Pegar informação
              id: id,
              lastModified: 0,
              requestDate: 0,
              requesterId: '',
              status: 'aprovado',
              type: tipo, // Pegar informação
            };
            resolve(fakeApproval);
          }
        })
        .catch((e) => {
          reject(null);
        });
    } else {
      reject(null);
    }
  });
}

export function pushDatabase_v1(node, data) {
  return new Promise((resolve, reject) => {
    firebaseDatabase
      .ref(node)
      .push(data)
      .then(function (result) {
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
