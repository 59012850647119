import React from "react";
import * as S from "./styles";
import * as D from "../../../../DesignSystem/styles";
import { cloneDeep } from "lodash";

export default function TitleAndMessage({
  formCommunication,
  setFormCommunication,
}) {
  const handleChangeTitle = (event) => {
    let cloneForm = cloneDeep(formCommunication);
    cloneForm.title = event.target.value;
    setFormCommunication(cloneForm);
  };

  const handleChangeMessage = (event) => {
    let cloneForm = cloneDeep(formCommunication);
    cloneForm.message = event.target.value;
    setFormCommunication(cloneForm);
  };

  return (
    <S.Container>
      <div>
        <D.TriiboH4>
          <b>Título</b>
        </D.TriiboH4>
        <D.TriiboH5>
          Será exibido na caixa de notificações dos dispositivos de seus
          clientes.
        </D.TriiboH5>
      </div>
      <S.TitleContainer>
        <S.Input
          maxLength="70"
          value={formCommunication.title}
          onChange={(e) => handleChangeTitle(e)}
        />
        <S.TextCounter>{formCommunication.title.length}/70</S.TextCounter>
      </S.TitleContainer>
      <div>
        <D.TriiboH4>
          <b>Mensagem</b>
        </D.TriiboH4>
        <D.TriiboH5>
          Deve conter a mensagem que você deseja exibir dentro do aplicativo.
        </D.TriiboH5>
      </div>
      <S.TextCountContainer>
        <S.TextArea
          placeholder={"Escreva aqui"}
          maxLength="1400"
          value={formCommunication.message}
          onChange={(e) => handleChangeMessage(e)}
        />
        <S.TextCounter>{formCommunication.message.length}/1400</S.TextCounter>
      </S.TextCountContainer>
    </S.Container>
  );
}
