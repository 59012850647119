import React, { useEffect, useState } from 'react';
import { cellPhoneMask, cpfMask, maskCnpj } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';

//STYLES
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { BoxInfo, BoxModal, ContentInfoModalAdd } from './segmentStyles';

//ICONS
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { HiDocumentSearch } from 'react-icons/hi';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/Search';

//API
import { userSegmentWhiteList } from 'js/library/utils/API/Segmentation/userSegmentWhiteList';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';

export const ModalNewMember = ({
  setOpenModalAdd,
  open,
  userSegmentId,
  attApi,
  isChangeApi,
  data,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  // states para os possiveis tipos de chave ativa do clube
  const [cellPhone, setCellPhone] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [email, setEmail] = useState('');

  const [dataValidate, setDataValidate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [clubKey, setClubKey] = useState('');
  const [token, setToken] = useState('');
  const [verifyEmail, setVerifyEmail] = useState(false);

  const partnerId = localStorage.getItem('partnerIdClube');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const partner = await GetPartnerUserForms(partnerId);

        // Filtra os campos em que indexKey é true
        const fieldsWithIndexKeyTrue = partner.formFields.filter(
          (field) => field.indexKey === true
        );

        // Verifica se há algum resultado no array antes de acessar o campo fieldType
        if (fieldsWithIndexKeyTrue.length > 0) {
          setClubKey(fieldsWithIndexKeyTrue[0].fieldType);
        }
      } catch (error) {
        console.error('Ocorreu um erro:', error);
      }
    };

    fetchData();
  }, [partnerId]);

  // remover máscaras
  const removeMask = (value) => value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número

  // removendo a máscara e adicionando +55
  const formatPhoneNumber = (phone) => {
    const cleanPhone = removeMask(phone);
    return `+55${cleanPhone}`;
  };

  useEffect(() => {
    const tokenMap = {
      cellPhone: formatPhoneNumber(cellPhone),
      cpf: removeMask(cpf),
      cnpj: removeMask(cnpj),
      email: email,
    };

    // Atualiza o token sem a máscara e com +55 para celular
    setToken(tokenMap[clubKey]);
  }, [clubKey, cellPhone, cpf, cnpj, email]);

  useEffect(() => {
    const validate = async () => {
      try {
        if (
          (clubKey === 'cellPhone' && token?.length === 14) ||
          (clubKey === 'cpf' && token?.length === 11) ||
          (clubKey === 'cnpj' && token?.length === 14) ||
          verifyEmail
        ) {
          setLoading(true);
          const validateUserInfos = await ValidateUserPartner(token, partnerId);
          setDataValidate(validateUserInfos);
          setVerifyEmail(false);
        }
      } catch (e) {
        console.error('Ocorreu um erro na validateUserPartner', e);
      } finally {
        setLoading(false);
      }
    };
    validate();
  }, [token, clubKey, partnerId, verifyEmail]);

  // se a validateUserPartner, retornar com dados null, seta a mensagem de erro
  useEffect(() => {
    if (dataValidate && dataValidate.uId === null) {
      setErrorMessage(
        `o ${
          clubKey === 'cellPhone' ? 'telefone' : clubKey
        } preenchido não corresponde a nenhum membro do clube.`
      );
    } else {
      setErrorMessage('');
    }
  }, [dataValidate]);

  useEffect(() => {
    setErrorMessage('');
    setDataValidate('');
  }, [token]);

  const addNewMember = async () => {
    try {
      setLoadingAdd(true);

      const payload = {
        userSegmentId,
        uid: dataValidate?.uId,
      };

      const checkIfMemberExists = data.some((item) => item.uid === dataValidate.uId);

      if (checkIfMemberExists) {
        toast.error('Esse membro já foi adicionado.', { autoClose: 2500 });
        setLoadingAdd(false);
      } else {
        await userSegmentWhiteList(payload);
        toast.success('Membro adicionado com sucesso.', { autoClose: 2000 });
        setCellPhone('');
        setCpf('');
        setCnpj('');
        setEmail('');
        attApi(!isChangeApi);
        handleClose();
      }
    } catch (error) {
      console.error('Erro ao adicionar membro: ', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleClose = () => {
    setOpenModalAdd(false);
    setCellPhone('');
    setCpf('');
    setCnpj('');
    setEmail('');
    setErrorMessage('');
    setToken('');
  };

  const renderInputByClubKey = (clubKey) => {
    switch (clubKey) {
      case 'cellPhone':
        return (
          <>
            <InputLabel required>Telefone:</InputLabel>
            <TextField
              placeholder="(99) 99999-9999"
              variant="outlined"
              required
              value={cellPhone}
              onChange={(e) => setCellPhone(cellPhoneMask(e.target.value))}
              sx={{ width: 300 }}
            />
          </>
        );

      case 'cpf':
        return (
          <>
            <InputLabel required>CPF:</InputLabel>
            <TextField
              placeholder="000.000.000-00"
              variant="outlined"
              required
              value={cpf}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
              sx={{ width: 300 }}
            />
          </>
        );

      case 'cnpj':
        return (
          <>
            <InputLabel required>CNPJ:</InputLabel>
            <TextField
              placeholder="00.000.000/0000-00"
              variant="outlined"
              required
              value={cnpj}
              onChange={(e) => setCnpj(maskCnpj(e.target.value))}
              sx={{ width: 300 }}
            />
          </>
        );

      case 'email':
        return (
          <>
            <InputLabel required>Email:</InputLabel>
            <TextField
              placeholder="example@example.com.br"
              variant="outlined"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                width: { xs: 300, sm: 330, md: 400 },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={email === ''}
                      variant="contained"
                      onClick={() => setVerifyEmail(true)}
                    >
                      <SearchIcon />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </>
        );

      default:
        return <Typography>Tipo de chave do clube inválida.</Typography>;
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <BoxModal sx={{ position: 'relative' }}>
        <Box
          sx={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }}
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="large" />
        </Box>

        {!clubKey ? (
          <Typography
            variant="body1"
            sx={{
              color: '#dd2424',
              paddingLeft: '0px',
              fontWeight: '600',
              mb: '20px',
            }}
          >
            Este clube não possuí nenhuma chave ativa.
          </Typography>
        ) : (
          <>
            <h3>Adicionar um novo membro</h3>

            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {clubKey && renderInputByClubKey(clubKey)}
              {errorMessage !== '' && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#dd2424',
                    paddingLeft: '0px',
                    marginTop: '5px',
                    fontWeight: '600',
                    width: 300,
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </span>

            {loading ? (
              <CircularProgress size={25} thickness={6} />
            ) : dataValidate.uId === null || dataValidate === '' ? (
              ''
            ) : (
              <ContentInfoModalAdd>
                {clubKey !== 'cellPhone' && (
                  <BoxInfo>
                    <LocalPhoneIcon size={20} style={{ color: '#08bad0' }} />
                    <p>
                      <span>Telefone: </span>
                      {dataValidate.cellPhone
                        ? dataValidate.cellPhone.replace('+55', '')
                        : 'Não cadastrado'}
                    </p>
                  </BoxInfo>
                )}
                <BoxInfo>
                  <HiDocumentSearch size={20} style={{ color: '#08bad0' }} />
                  <p>
                    <span>Uid: </span> {dataValidate.uId}
                  </p>
                </BoxInfo>
              </ContentInfoModalAdd>
            )}

            <Button
              color="primary"
              disabled={dataValidate === '' || errorMessage !== ''}
              variant="contained"
              onClick={addNewMember}
            >
              {loadingAdd ? (
                <CircularProgress size={25} thickness={6} style={{ color: '#fff' }} />
              ) : (
                'Adicionar'
              )}
            </Button>
          </>
        )}
      </BoxModal>
    </Modal>
  );
};
