import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  TextField,
  Typography,
  Snackbar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import {
  AddPhotoAlternate,
  Delete,
  Edit as EditIcon,
  Search,
} from "@mui/icons-material";
import Transition from "js/containers/Transition.js";

import Loading from "js/containers/Loading/Loading";

import {
  updateDatabase,
  removeDatabase,
  createDatabase,
} from "js/library/services/DatabaseManager.js";
import { getDataListRestApi } from "js/library/utils/API/restApiFirebase";

import { b64toBlob } from 'js/library/utils/helpers';

import {
  uploadImage,
  deleteImage,
  downloadImage,
} from "js/library/services/StorageManager";
import placeholder from "styles/assets/placeholder/placeholder_card.png";

import placeholderThumbnail from "styles/assets/placeholder/placeholder_thumbnail.png";

import deburr from "lodash/deburr";
import { GoBackHeader } from "./CreateClub/RelationshipClub/GoBackHeader";
import { HeaderTitle } from "./CreateClub/RelationshipClub/HeaderTitle";

class KeywordsList extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      value: "",
      keywordsList: false,
      keywordsImage: null,

      openSnackbar: false,
      snackbarMessage: "Keyword já existente!",
      authenticated: false,

      inputSearch: "",

      imageFile: placeholderThumbnail,
      loadedImageFile: null,
      loadedDarkImageFile: null,
    };

    getDataListRestApi("Keywords").then((dataReceived) => {
      let data = [];

      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map(function (item) {
          dataReceived[item].key = item;

          return data.push(dataReceived[item]);
        });
      } else {
        return null;
      }

      this.setState({ keywordsList: data });
    });

    // Imagens das keywords
    getDataListRestApi("logo-keyword").then((dataReceived) => {
      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map(function (item) {
          if (
            dataReceived[item].image !== undefined &&
            dataReceived[item].image !== null
          ) {
            let storageInfo = dataReceived[item].image.split("/");

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImage) => {
                dataReceived[item].downloadedThumb = downloadedImage;
              })
              .catch(() => {
                // console.log("Foto não encontrada:", dataReceived[item].image);
              });
          }

          if (
            dataReceived[item].imageLight !== undefined &&
            dataReceived[item].imageLight !== null
          ) {
            let storageInfo = dataReceived[item].imageLight.split("/");

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImageLight) => {
                dataReceived[item].downloadedThumbLight = downloadedImageLight;
              })
              .catch(() => {
                // console.log(
                //   "Foto não encontrada:",
                //   dataReceived[item].imageLight
                // );
              });
          }

          if (
            dataReceived[item].imageDark !== undefined &&
            dataReceived[item].imageDark !== null
          ) {
            let storageInfo = dataReceived[item].imageDark.split("/");

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImageDark) => {
                dataReceived[item].downloadedThumbDark = downloadedImageDark;
              })
              .catch(() => {
                // console.log(
                //   "Foto não encontrada:",
                //   dataReceived[item].imageDark
                // );
              });
          }

          return null;
        });
        window.setTimeout(() => {
          this.setState({ keywordsImage: dataReceived });
        }, 500);
      } else {
        return null;
      }
    });

    window.pathname = null;
  }

  downloadImages = () => {
    getDataListRestApi("logo-keyword").then((dataReceived) => {
      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map(function (item) {
          let storageInfo = dataReceived[item].image.split("/");

          if (
            dataReceived[item].imageLight !== undefined &&
            dataReceived[item].imageLight !== null
          ) {
            let storageInfo = dataReceived[item].imageLight.split("/");

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImageLight) => {
                dataReceived[item].downloadedThumbLight = downloadedImageLight;
              })
              .catch(() => {
                // console.log(
                //   "Foto não encontrada:",
                //   dataReceived[item].imageLight
                // );
              });
          }

          if (
            dataReceived[item].imageDark !== undefined &&
            dataReceived[item].imageDark !== null
          ) {
            let storageInfo = dataReceived[item].imageDark.split("/");

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImageDark) => {
                dataReceived[item].downloadedThumbDark = downloadedImageDark;
              })
              .catch(() => {
                // console.log(
                //   "Foto não encontrada:",
                //   dataReceived[item].imageDark
                // );
              });
          }

          return downloadImage(storageInfo[0], storageInfo[1]).then(
            (downloadedImage) => {
              dataReceived[item].downloadedThumb = downloadedImage;
            }
          );
        });
        window.setTimeout(() => {
          this.setState({ keywordsImage: dataReceived });
        }, 1500);
      } else {
        return null;
      }
    });
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
        .replace(/[&#,/+()$~%.'":*?<>{}\\]/g, "")
        .replace(" ", "_"),
    });
  };

  handleImage = (name) => (event) => {
    if (event.target.files[0]) {
      var image = new FileReader();

      image.onloadend = function () {
        if (name === "light") {
          this.setState({ loadedImageFile: image.result });
          document.getElementById("lightPreview").src = image.result;
        } else if (name === "dark") {
          this.setState({ loadedDarkImageFile: image.result });
          document.getElementById("darkPreview").src = image.result;
        } else if (name === "edit") {
          this.setState({ loadedEditImageFile: image.result });
          document.getElementById("editPreview").src = image.result;
        }
      }.bind(this);

      image.readAsDataURL(event.target.files[0]);
    }
  };

  handleCloseAlert = () => {
    this.setState({ openSnackbar: false });
  };

  openEditImage = (item, type) => {
    document.getElementById("editPreview").src = "";
    this.setState({
      openEditDialog: true,
      loadedEditImageFile: null,
      editItem: item,
      editType: type,
    });
  };

  updateImage = () => {
    let item = this.state.editItem;

    if (this.state.editType === "light") {
      deleteImage("logo-keyword", item.value + ".png");

      uploadImage(
        "logo-keyword",
        b64toBlob(this.state.loadedEditImageFile),
        item.value + ".png"
      );

      let logoKeyword = { image: "logo-keyword/" + item.value + ".png" };
      updateDatabase("logo-keyword", logoKeyword, item.value, null);
    } else {
      deleteImage("logo-keyword", item.value + "_dark.png");

      uploadImage(
        "logo-keyword",
        b64toBlob(this.state.loadedEditImageFile),
        item.value + "_dark.png"
      );

      let logoKeyword = {
        imageDark: "logo-keyword/" + item.value + "_dark.png",
      };
      updateDatabase("logo-keyword", logoKeyword, item.value, null);
    }

    window.location.reload();
  };

  closeEditImage = () => {
    this.setState({
      openEditDialog: false,
      editItem: null,
      editType: null,
      editImage: null,
      editImageFile: null,
    });
  };

  addKeyword = (value) => {
    let exists = false;

    if (
      this.state.keywordsList !== null &&
      this.state.keywordsList !== undefined
    ) {
      this.state.keywordsList.map((item) => {
        if (item.value === value && item.type === "limitação") {
          exists = true;
        }
        return 0;
      });
    }

    if (exists) {
      this.setState({
        snackbarMessage: "Keyword já existente!",
        openSnackbar: true,
      });
      return;
    } else {
      if (
        (this.state.loadedImageFile !== null &&
          this.state.loadedDarkImageFile === null) ||
        (this.state.loadedImageFile === null &&
          this.state.loadedDarkImageFile !== null)
      ) {
        this.setState({
          snackbarMessage: "Necessário adicionar ambas as imagens",
          openSnackbar: true,
        });
        return;
      }

      this.setState({
        snackbarMessage: "Criando Keyword...",
        openSnackbar: true,
      });
      let keywordInfo = {
        value,
        status: 0,
        createDate: Date.now(),
      };

      createDatabase("Keywords", keywordInfo, null).then((response) => {
        this.setState({ value: "", keywordsList: null });

        getDataListRestApi("Keywords").then((dataReceived) => {
          let data = [];


          Object.keys(dataReceived).map(function (item) {
            dataReceived[item].key = item;

            return data.push(dataReceived[item]);
          });

          this.downloadImages();

          this.setState({ keywordsList: data });
        });
      });

      if (
        this.state.loadedImageFile !== null &&
        this.state.DarkImageFile !== null
      ) {
        let logoKeyword = {
          image: "logo-keyword/" + value + ".png",
          imageDark: "logo-keyword/" + value + "_dark.png",
        };

        deleteImage("logo-keyword", logoKeyword.image);
        deleteImage("logo-keyword", logoKeyword.imageDark);

        uploadImage(
          "logo-keyword",
          b64toBlob(this.state.loadedImageFile),
          value + ".png"
        );
        uploadImage(
          "logo-keyword",
          b64toBlob(this.state.loadedDarkImageFile),
          value + "_dark.png"
        );

        updateDatabase("logo-keyword", logoKeyword, value, null);
        this.setState({ loadedImageFile: null, loadedDarkImageFile: null });
      } else {
        this.setState({
          snackbarMessage: "Criando Keyword sem imagens",
          openSnackbar: true,
        });
      }
    }
  };

  removeKeyword = (key, value, type) => {
    removeDatabase("Keywords", key).then((response) => {
      if (type !== "limitação") {
        deleteImage("logo-keyword", value + ".png");
        deleteImage("logo-keyword", value + "_dark.png");
        removeDatabase("logo-keyword", value);
      }

      this.setState({ value: "", keywordsList: null });


      getDataListRestApi("Keywords").then((dataReceived) => {
        let data = [];
        if (dataReceived !== null && dataReceived !== undefined) {
          Object.keys(dataReceived).map(function (item) {
            dataReceived[item].key = item;

            return data.push(dataReceived[item]);
          });
        } else {
          return null;
        }

        this.setState({ keywordsList: data });
      });

    });
  };

  
  getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? this.state.keywordsList
      : this.state.keywordsList.filter((suggestion) => {
          const keep =
            count < 5 &&
            suggestion.value.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  };

  render = () => {
    if (this.props.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return <Redirect to={window.pathname} />;
      } else {
        return (
          <div
            style={{ maxHeight: "100%", overflowY: "auto", padding: "4rem 32px" }}
          >
            <GoBackHeader />
            <HeaderTitle pageInfo='Cadastro de Keywords'/>

            <form onSubmit={(e) => {
              e.preventDefault();
              this.addKeyword(this.state.value);
              }}>
              <TextField
                id="value"
                label="Keyword"
                value={this.state.value}
                onChange={this.handleChange("value")}
                margin="normal"
                required
              />

              <Button
                type="submit"
                disabled={this.state.value.length === 0}
                style={{
                  fontWeight: "550",
                  color: "white",
                  marginLeft: "10px",
                  marginTop: "25px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                color="primary"
              >
                Adicionar
              </Button>
            </form>

            <Grid container spacing={5}>
              {/* imagem */}

              <Grid item md={2} sm={12}>
                {this.state.loadedImageFile !== null ? (
                  <img
                    id="lightPreview"
                    alt="Imagem"
                    style={{
                      width: "100%",
                      height: "auto",
                      margin: "12px 0px",
                    }}
                    src={this.state.lightImageFile}
                  />
                ) : null}

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="lightImage"
                  type="file"
                  onChange={this.handleImage("light")}
                />
                <br />
                <label htmlFor="lightImage" style={{ width: "100%" }}>
                  <Button
                    style={{
                      fontWeight: "550",
                      color: "white",
                      width: "100%",
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<AddPhotoAlternate />}
                  >
                    Imagem para fundo claro
                  </Button>
                </label>
              </Grid>

              <Grid item md={2} sm={12}>
                {this.state.loadedDarkImageFile !== null ? (
                  <img
                    id="darkPreview"
                    alt="Fundo claro"
                    style={{
                      width: "100%",
                      height: "auto",
                      margin: "12px 0px",
                    }}
                    src={this.state.darkImageFile}
                  />
                ) : null}

                <input
                  accept="image/*"
                  style={{ display: "none", marginLeft: "24px" }}
                  id="darkImage"
                  type="file"
                  onChange={this.handleImage("dark")}
                />
                <br />
                <label htmlFor="darkImage" style={{ width: "100%" }}>
                  <Button
                    style={{
                      fontWeight: "550",
                      color: "white",
                      width: "100%",
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<AddPhotoAlternate />}
                  >
                    Imagem para fundo escuro
                  </Button>
                </label>
              </Grid>

              <Grid item md={8} sm={false}></Grid>

              <Grid item md={8} sm={12}>
                <Divider />

                <TextField
                  id="inputSearch"
                  value={this.state.inputSearch}
                  onChange={this.handleChange("inputSearch")}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                {this.state.keywordsList === false ? (
                  <div align="center">
                    <CircularProgress style={{ padding: "20px" }} />
                  </div>
                ) : this.state.keywordsList === null ? (
                  <div>
                    <br />
                    <br />
                    <Typography variant="body2">
                      Nenhuma keyword cadastrada.
                    </Typography>
                  </div>
                ) : (
                  <List
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        fontWeight: "bolder",
                        width: "128px",
                        marginLeft: "48px",
                        marginRight: "48px",
                      }}
                    >
                      Logo
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        fontWeight: "bolder",
                        width: "128px",
                        marginLeft: "24px",
                        marginRight: "48px",
                      }}
                    >
                      Dark
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        fontWeight: "bolder",
                        width: "auto",
                        marginLeft: "24px",
                      }}
                    >
                      Keyword
                    </span>
                    {this.getSuggestions(this.state.inputSearch).map((item) => (
                      <ListItem key={item.key} role={undefined} dense button>
                        {this.state.keywordsImage &&
                        this.state.keywordsImage[item.value] ? (
                          <div style={{ display: "inline-flex" }}>
                            <img
                              width="128px"
                              style={{ marginLeft: "24px" }}
                              alt={item.value}
                              src={
                                this.state.keywordsImage[item.value]
                                  .downloadedThumb
                                  ? this.state.keywordsImage[item.value]
                                      .downloadedThumb
                                  : placeholder
                              }
                            />
                            <IconButton
                              style={{ width: "48px", height: "48px" }}
                              onClick={() => this.openEditImage(item, "light")}
                              aria-label="Comments"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div style={{ display: "inline-flex" }}>
                            <div
                              style={{
                                width: "128px",
                                height: "46px",
                                marginLeft: "24px",
                              }}
                            ></div>
                            <IconButton
                              style={{ width: "48px", height: "48px" }}
                              onClick={() => this.openEditImage(item, "light")}
                              aria-label="Comments"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        )}

                        {this.state.keywordsImage &&
                        this.state.keywordsImage[item.value] ? (
                          <div style={{ display: "inline-flex" }}>
                            <img
                              width="128px"
                              style={{ marginLeft: "24px" }}
                              alt={item.value}
                              src={
                                this.state.keywordsImage[item.value]
                                  .downloadedThumbDark
                                  ? this.state.keywordsImage[item.value]
                                      .downloadedThumbDark
                                  : placeholder
                              }
                            />
                            <IconButton
                              style={{ width: "48px", height: "48px" }}
                              onClick={() => this.openEditImage(item, "dark")}
                              aria-label="Comments"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div style={{ display: "inline-flex" }}>
                            <div
                              style={{
                                width: "128px",
                                height: "46px",
                                marginLeft: "24px",
                              }}
                            />
                            <IconButton
                              style={{ width: "48px", height: "48px" }}
                              onClick={() => this.openEditImage(item, "dark")}
                              aria-label="Comments"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        )}

                        <ListItemText
                          style={{ marginLeft: "24px" }}
                          primary={`${item.value}`}
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() =>
                              this.removeKeyword(
                                item.key,
                                item.value,
                                item.type
                              )
                            }
                            edge="end"
                            aria-label="Comments"
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>

            <Dialog
              open={this.state.openEditDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.closeEditImage}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {`Atualizar imagem ${
                  this.state.editType ? this.state.editType : ""
                } da keyword ${
                  this.state.editItem ? this.state.editItem.value : ""
                }?`}
              </DialogTitle>

              <DialogContent>
                <img
                  id="editPreview"
                  alt="imagem"
                  style={{ width: "100%", height: "auto", margin: "12px 0px" }}
                  src={this.state.editImageFile}
                />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="editImage"
                  type="file"
                  onChange={this.handleImage("edit")}
                />
                <br />
                <label htmlFor="editImage" style={{ width: "100%" }}>
                  <Button
                    style={{
                      fontWeight: "550",
                      color: "white",
                      width: "100%",
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<AddPhotoAlternate />}
                  >
                    Nova Imagem
                  </Button>
                </label>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.closeEditImage} color="primary">
                  Cancelar
                </Button>
                <Button onClick={this.updateImage} color="primary">
                  Atualizar
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.openSnackbar}
              onClose={this.handleCloseAlert}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={
                <span id="message-id">{this.state.snackbarMessage}</span>
              }
            />
          </div>
        );
      }
    }
  };
}

export default KeywordsList;
