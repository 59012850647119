import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import * as E from 'js/components/DesignSystem/Table/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { DeleteUserButton } from './segmentStyles';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

//iCONS
import { MdArrowBackIosNew } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { Add } from '@mui/icons-material';

//APIS
import { segmentSearchFields } from 'js/library/utils/API/Segmentation/segmentSearchField';
import { getEstablishmentSegment } from 'js/library/utils/API/Segmentation/getEstablishmentSegment';
import { getStoreSegment } from 'js/library/utils/API/Segmentation/getStoreSegment';
import { getCardSegment } from 'js/library/utils/API/Segmentation/getCardSegment';
import { getPostSegment } from 'js/library/utils/API/Segmentation/getPostSegment';
import { addResourceSegmentBlackList } from 'js/library/utils/API/Segmentation/addResourceSegmentBlackList';

//COMPONENTES
import ResourceTable from './ResourceTable';
import { ModalNewResource } from './ModalNewResource';
import { ModalDelete } from './ModalDelete';

export default function ResourceManagement(props) {
  const segmentId = props.match.params.id;

  const [value, setValue] = useState(0);
  const [listFields, setListFields] = useState([]);
  const [columnsFiltered, setColumnsFiltered] = useState([]);
  const [data, setData] = useState([]);
  const [prevValue, setPrevValue] = useState(value);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  //(loading) para passar como prop para a tabela sempre que selecionar uma opção do select
  useEffect(() => {
    if (value !== prevValue) {
      setPrevValue(value);
    }
  }, [prevValue, value]);

  const dataListFields = {
    from: 0,
    size: 1000,
  };

  const handleInitialData = async () => {
    try {
      const res = await segmentSearchFields(dataListFields);
      if (Array.isArray(res.result)) {
        setListFields(res.result);
        applyFilters(value, res.result);
      }
    } catch (error) {
      console.error('erro:', error);
    }
  };

  const applyFilters = (value, data) => {
    let filteredFields = [];
    let filteredColumns = [];

    switch (value) {
      case 0: // Estabelecimento
        filteredFields = data.filter((field) => field.tableOrigin === 'Estabelecimento-v2');

        // colunas 'Estabelecimento-v2'
        filteredColumns = filteredFields
          .filter((item) =>
            ['Id do Estabelecimento', 'Nome estabelecimento', 'origem', 'endereço'].includes(
              item.field
            )
          )
          .map((item) => ({
            heading: item.field,
            value: item.field,
            origin: item.origin,
          }));
        break;
      case 1: // Promoção
        filteredFields = data.filter((field) => field.tableOrigin === 'Store');

        // Mapeamento da ordem dos campos
        const fieldOrderStore = {
          id: 1,
          Titulo: 2,
          Tipo: 3,
        };
        // colunas 'Store'
        filteredColumns = filteredFields
          .filter((item) => ['id', 'Titulo', 'Tipo'].includes(item.field))
          .sort((a, b) => fieldOrderStore[a.field] - fieldOrderStore[b.field]) // Ordena os campos conforme o mapeamento
          .map((item) => ({
            heading: item.field,
            value: item.field,
            origin: item.origin,
          }));
        break;
      case 2: // Card
        filteredFields = data.filter((field) => field.tableOrigin === 'Cards');

        // Mapeamento da ordem dos campos
        const fieldOrderCard = {
          cardId: 1,
          Titulo: 2,
          Tipo: 3,
        };
        // colunas 'Cards'
        filteredColumns = filteredFields
          .filter((item) => ['cardId', 'Titulo', 'Tipo'].includes(item.field))
          .sort((a, b) => fieldOrderCard[a.field] - fieldOrderCard[b.field]) // Ordena os campos conforme o mapeamento
          .map((item) => ({
            heading: item.field,
            value: item.field,
            origin: item.origin,
          }));
        break;
      case 3: // Post
        filteredFields = data.filter((field) => field.tableOrigin === 'feed-posts');

        // Mapeamento da ordem dos campos
        const fieldOrderPost = {
          Id: 1,
          Título: 2,
          'Data de Publicação': 3,
        };

        // Cria um Set para armazenar os campos únicos
        const uniqueFields = new Set();

        // colunas 'feed-posts'
        filteredColumns = filteredFields
          .filter((item) => ['Id', 'Título', 'Data de Publicação'].includes(item.field))
          .sort((a, b) => fieldOrderPost[a.field] - fieldOrderPost[b.field]) // Ordena os campos conforme o mapeamento
          .filter((item) => {
            // Tenta adicionar o campo ao Set
            if (uniqueFields.has(item.field)) {
              // Se o campo já existe no Set, retorna false para filtrá-lo
              return false;
            } else {
              // Se o campo não existe no Set, adiciona e retorna true para mantê-lo
              uniqueFields.add(item.field);
              return true;
            }
          })
          .map((item) => ({
            heading: item.field,
            value: item.field,
            origin: item.origin,
          }));
        break;
      default:
        filteredFields = data;
        break;
    }

    setColumnsFiltered(filteredColumns);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    handleInitialData();
  }, []);

  useEffect(() => {
    if (listFields.length > 0) {
      applyFilters(value, listFields);
    }
  }, [value, listFields]);

  // dados das tabelas
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let res;
        if (value === 0) {
          res = await getEstablishmentSegment(segmentId);
          setData(res.establishment.map(formatDataEstablishment));
          setLoading(false);
        } else if (value === 1) {
          res = await getStoreSegment(segmentId);
          setData(res.stores.map(formatDataStore));
          setLoading(false);
        } else if (value === 2) {
          res = await getCardSegment(segmentId);
          setData(res.cards.map(formatDataCard));
          setLoading(false);
        } else if (value === 3) {
          res = await getPostSegment(segmentId);
          setData(res.posts.map(formatDataPost));
          setLoading(false);
        }
      } catch (error) {
        console.error('Erro:', error);
        setLoading(false);
      }
    };

    fetchData();

    if (data?.length === 0) {
      setIsEmpty(true);
    }
  }, [segmentId, value, isChange]);

  const formatDataEstablishment = (data) => ({
    partnerEstablishmentId: data.id,
    nome: data.nome,
    origin: data.origin || 'Triibo',
    endereco: data.endereco,
  });

  const formatDataStore = (data) => ({
    id: data.id,
    title: data.title,
    type:
      data.type === 'promotion'
        ? 'Promoção simples'
        : data.type === 'businessPartner'
        ? 'Promoção externa'
        : 'Produto',
  });

  const formatDataCard = (data) => ({
    cardId: data.cardId,
    title: data.title,
    type:
      data.type === 'card_url'
        ? 'Url'
        : data.type === 'card_list'
        ? 'Lista'
        : data.type === 'card_item'
        ? 'Item'
        : 'N/A',
  });

  const formatDataPost = (data) => ({
    id: data.id,
    title: data.title,
    publishDate: new Date(data.publishDate).toLocaleDateString('pt-BR').replace(/\//g, '/'),
  });

  const handleOpenModalAdd = () => {
    setOpenModalAdd(!openModalAdd);
  };

  const handleOpenModalDelete = (uId) => {
    setSelectedData(uId);
    setOpenModalDelete(!openModalDelete);
  };

  const deleteResource = async () => {
    try {
      setLoadingDelete(true);

      // estabelecimento
      if (value === 0) {
        const payload = {
          [segmentId]: {
            [selectedData.partnerEstablishmentId]: 'establishment',
          },
        };

        await addResourceSegmentBlackList(payload);
        toast.success('Estabelecimento removido da lista.', { autoClose: 2000 });
        setOpenModalDelete(false);
        setIsChange(!isChange); // Atualiza a tabela.
      }

      // promoção
      if (value === 1) {
        const payload = {
          [segmentId]: {
            [selectedData.id]: 'store',
          },
        };

        await addResourceSegmentBlackList(payload);
        toast.success('Promoção removida da lista.', { autoClose: 2000 });
        setOpenModalDelete(false);
        setIsChange(!isChange); // Atualiza a tabela.
      }

      //cards
      if (value === 2) {
        const payload = {
          [segmentId]: {
            [selectedData.cardId]: 'cards',
          },
        };

        await addResourceSegmentBlackList(payload);
        toast.success('Card removido da lista.', { autoClose: 2000 });
        setOpenModalDelete(false);
        setIsChange(!isChange); // Atualiza a tabela.
      }

      //posts
      if (value === 3) {
        const payload = {
          [segmentId]: {
            [selectedData.id]: 'posts',
          },
        };

        await addResourceSegmentBlackList(payload);
        toast.success('Post removido da lista.', { autoClose: 2000 });
        setOpenModalDelete(false);
        setIsChange(!isChange); // Atualiza a tabela.
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao tentar remover o recurso!', { autoClose: 2500 });
      console.log('Ocorreu um erro ao tentar remover o recurso, ERRO:', err);
    } finally {
      setLoadingDelete(false);
    }
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <E.TableTH>Excluir</E.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <E.TableTD>
              <DeleteUserButton onClick={() => handleOpenModalDelete(childrens)}>
                <FaTrash style={{ color: 'white' }} />
              </DeleteUserButton>
            </E.TableTD>
          </>
        ),
    };
  };

  return (
    <S.Container>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        Recursos
      </D.TriiboH1>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <FormControl sx={{ width: 300 }}>
          <InputLabel id="demo-simple-select-label">Selecione um tipo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Selecione um tipo"
            onChange={handleChange}
          >
            <MenuItem value={0}>Estabelecimentos</MenuItem>
            <MenuItem value={1}>Promoções</MenuItem>
            <MenuItem value={2}>Cards</MenuItem>
            <MenuItem value={3}>Posts</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          sx={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
          onClick={handleOpenModalAdd}
        >
          Novo Recurso
        </Button>
      </Box>

      <ResourceTable
        data={data}
        column={columnsFiltered}
        perPage={10}
        componentReceiver={componentReceiver}
        isEmpty={isEmpty}
        loading={loading}
      />

      <ModalDelete
        close={handleOpenModalDelete}
        open={openModalDelete}
        deleteType={deleteResource}
        loading={loadingDelete}
      />

      <ModalNewResource
        open={openModalAdd}
        value={value}
        setOpenModalAdd={setOpenModalAdd}
        segmentId={segmentId}
        isChange={isChange}
        setIsChange={setIsChange}
        data={data}
      />
    </S.Container>
  );
}
