import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",

    paddingLeft: theme.spacing(5),
  },

  title: {
    fontWeight: "bold",

    position: "relative",
    bottom: theme.spacing(10),
  },

  contentWrapper: {
    height: 487,
  },

  imageContainer: {
    height: "100%",

    display: "flex",
    flexDirection: "column",

    marginRight: theme.spacing(11),

    "&>img": {
      height: 326,
      border: "2px solid black",
    },

    "&>div": {
      height: 161,
    },
  },

  formContainer: {
    height: "100%",

    display: "flex",
    flexDirection: "column",

    justifyContent: "space-between",
  },

  addImageButton: {
    width: 323,

    marginTop: theme.spacing(7),

    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    borderRadius: 40,

    fontSize: 20,
    fontWeight: "bold",

    textTransform: "capitalize",
  },

  uploadIcon: {
    marginBottom: 2,
  },

  backButton: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),

    fontSize: 20,
    fontWeight: "bold",

    textTransform: "capitalize",
  },

  actionButton: {
    marginLeft: 30,

    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),

    fontSize: 20,
    fontWeight: "bold",

    textTransform: "capitalize",
  },

  cardImageBody: {
    backgroundColor: theme.palette.default.main,
  },

  cardText: {
    maxWidth: 200,

    fontSize: 16,
    fontWeight: "bold",

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:first-child": {
      marginTop: theme.spacing(2),
    },

    "&:nth-child(2)": {
      marginTop: theme.spacing(1),
    },

    "&:last-child": {
      fontWeight: 500,
      marginTop: theme.spacing(1),
    },
  },
}));

export const useMediumStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",

    paddingLeft: theme.spacing(4),
  },

  title: {
    fontWeight: "bold",

    position: "relative",
    bottom: theme.spacing(10),
  },

  contentWrapper: {
    height: "50vh",
    width: "100%",

    display: "flex",
  },

  imageContainer: {
    height: "100%",

    display: "flex",
    flexDirection: "column",

    marginRight: theme.spacing(5),

    "&>img": {
      height: "70%",
      border: "1px solid black",
    },
  },

  formContainer: {
    height: "100%",

    display: "flex",
    flexDirection: "column",

    justifyContent: "space-between",
  },

  addImageButton: {
    width: 260,

    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    fontSize: 15,
    fontWeight: "bold",

    textTransform: "capitalize",
  },

  uploadIcon: {
    marginBottom: 2,
  },

  backButton: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),

    fontSize: 15,
    fontWeight: "bold",

    textTransform: "capitalize",
  },

  actionButton: {
    marginLeft: 30,

    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),

    fontSize: 15,
    fontWeight: "bold",

    textTransform: "capitalize",
  },

  cardImageBody: {
    height: "30%",
    backgroundColor: theme.palette.default.main,
  },

  cardText: {
    maxWidth: 200,

    fontSize: 10,
    fontWeight: 600,
    opacity: 0.8,

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:first-child": {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.7),
    },

    "&:nth-child(2)": {
      opacity: 0.6,
    },

    "&:last-child": {
      marginTop: theme.spacing(0.7),
      fontWeight: 500,
      opacity: 0.5,
    },
  },

  counterBox: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center",

    border: "2px solid",
    borderColor: theme.palette.primary.main,
  },

  counterButton: {
    "&:first-child": {
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
    },
    "&:last-child": {
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
    },
  },
}));
