import React, { useState } from "react";

import { Modal } from "@mui/material";
import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";

import * as C from "../../../styles";
import { AiFillCloseCircle } from "react-icons/ai";
import copyImg from "../../../../../../../../styles/assets/communications/copy.svg";

export default function ModalQuery({
  isModalQueryOpen,
  handleCloseModal,
  queryText,
  setQueryText,
  setTargetAudienceIsSelected,
}) {
  const [textArea, setTextArea] = useState("");

  return (
    <Modal
      onClose={() => handleCloseModal("query")}
      open={isModalQueryOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <C.ModalContainer>
        <C.ModalBox>
          <C.CloseModalIcon onClick={() => handleCloseModal("query")}>
            <AiFillCloseCircle size={20} />
          </C.CloseModalIcon>

          <D.TriiboH4 style={{ textAlign: "center" }} className="titleQuery">
            <strong>
              Adicione no campo abaixo a <span>query.</span>
            </strong>
          </D.TriiboH4>

          <S.TextQueryContainer>
            <img src={copyImg} alt="" />
            <S.TextQuery
              value={textArea}
              onChange={(e) => setTextArea(e.target.value)}
            />
          </S.TextQueryContainer>

          <C.ActionButtons width={"85%"}>
            <C.CancelButton onClick={() => handleCloseModal("query")}>
              <D.TriiboH5>Cancelar</D.TriiboH5>
            </C.CancelButton>
            <C.ContinueButton
              onClick={() => {
                setQueryText(textArea);
                handleCloseModal("query");
                setTargetAudienceIsSelected(true);
              }}>
              <D.TriiboH5>Continuar</D.TriiboH5>
            </C.ContinueButton>
          </C.ActionButtons>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
