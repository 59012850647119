const textAlignOptions = [
  {
    option: 'Esquerda',
    align: 'left',
  },
  {
    option: 'Centro',
    align: 'center',
  },
  {
    option: 'Direita',
    align: 'right',
  },
];

const textTypes = [
  { option: 'Título', type: 'h2' },
  { option: 'Subtítulo', type: 'h6' },
  { option: 'Parágrafo', type: 'p' },
];

export default { textAlignOptions, textTypes };
