import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Modal,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search, ArrowBack, CropFree, Save } from '@mui/icons-material';

import { Route } from 'react-router-dom';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';

import axios from 'axios';
import QRCode from 'qrcode.react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    // overflowX: "auto",
  },
}));

const larguraJanela = document.body.clientWidth;

const DataTableQrcode = (props) => {
  const classes = useStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [dataTotal, setDataTotal] = useState([]);
  const [dataPesquisa, setDataPesquisa] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pesquisa, setPesquisa] = useState('');
  const [qrCodeContent, setQrCodeContent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [qrCodeType, setQrCodeType] = useState('');
  const [qrCodeShortLink, setQrCodeShortLink] = useState('');

  useEffect(() => {
    getDataListRestApi('QRCodes')
      .then((dataReceived) => {
        let data = [];
        Object.keys(dataReceived).forEach((item) => {
          if (dataReceived[item].title === undefined) {
            dataReceived[item].title = 'Sem título';
          }
          data.push(dataReceived[item]);
        });

        setDataTotal(data);
        setDataPesquisa(data);
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    const value = event.target.value.toLowerCase();
    const filteredData = dataTotal.filter(
      (item) =>
        item.title?.toLowerCase().includes(value) ||
        item.keyWordsSet?.some((keyword) => keyword.toLowerCase().includes(value)) ||
        item.qrCodeId.includes(value)
    );
    setDataPesquisa(filteredData);
    setPesquisa(event.target.value);
  };

  const getLink = (id) => {
    const dynamicLinkKey = 'AIzaSyDqzPWDKUb24RGQWGfLyNXsfBTukuwavRc';
    const dynamicLinkApi = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${dynamicLinkKey}`;
    const dynamicLinkDomain = 'mj8vs.app.goo.gl';
    const androidPackageName = 'br.com.sysmobil.triiboclient';
    const iosPackageName = 'br.com.triiboclient';
    const iosStoreId = '1118996431';
    const baseLink = 'https://triibo.com.br';
    const finalLink = `${baseLink}?qrCode=${id}`;

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    axios.post(dynamicLinkApi, linkRequest).then((result) => {
      setQrCodeType('dynamic_link');
      setQrCodeShortLink(result.data.shortLink);
      setOpenModal(true);
    });
  };

  const getModalStyle = () => ({
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    height: '70%',
    width: '60%',
    backgroundColor: 'white',
    padding: '30px',
  });

  const { rows, pathName } = props;

  return (
    <Route
      render={({ history }) => (
        <div className="scrollbar4" style={{ maxHeight: '100%', width: '100%' }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="adornment-amount">Code:</InputLabel>
            <Input
              id="adornment-amount"
              value={pesquisa}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
          <span className="scrollbar" style={{ marginLeft: '20rem' }}></span>
          <div>
            <Table stickyHeader aria-labelledby="tableTitle" style={{ marginTop: '1rem' }}>
              <TableHead>
                <TableRow>
                  {rows.map((row) => (
                    <TableCell
                      key={row.id}
                      sortDirection={orderBy === row.id ? order : false}
                      style={{ fontWeight: '550' }}
                    >
                      <Tooltip
                        title={row.id === 'thumbnail' ? 'Thumbnail' : 'Ordenar'}
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={() => row.id !== 'thumbnail' && handleRequestSort(row.id)}
                          style={{ cursor: row.id === 'thumbnail' ? 'default' : 'pointer' }}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(dataPesquisa, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => (
                    <TableRow key={n.qrCodeId} hover tabIndex={-1}>
                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({
                            pathname: pathName + n.qrCodeId,
                            state: n,
                          })
                        }
                      >
                        {n.title}
                      </TableCell>

                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({
                            pathname: pathName + n.qrCodeId,
                            state: n,
                          })
                        }
                      >
                        {n.action}
                      </TableCell>

                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({
                            pathname: pathName + n.qrCodeId,
                            state: n,
                          })
                        }
                      >
                        {n.keyWordsSet ? n.keyWordsSet.join() : 'Sem Keywords'}
                      </TableCell>

                      <TableCell
                        scope="row"
                        onClick={() =>
                          history.push({
                            pathname: pathName + n.qrCodeId,
                            state: n,
                          })
                        }
                      >
                        {n.active ? 'Sim' : 'Não'}
                      </TableCell>

                      <TableCell scope="row">
                        <Button
                          style={{
                            fontWeight: '550',
                            marginTop: '20px',
                            marginLeft: '10px',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setOpenModal(true);
                            setQrCodeContent(n);
                            setQrCodeType('full_info');
                          }}
                          startIcon={<CropFree />}
                        >
                          QRCode Completo
                        </Button>

                        <Button
                          style={{
                            fontWeight: '550',
                            marginTop: '20px',
                            marginLeft: '10px',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          color="primary"
                          onClick={() => getLink(n.qrCodeId)}
                          startIcon={<CropFree />}
                        >
                          Link Dinâmico
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>

          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={dataPesquisa.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página Anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <Button
                onClick={() => setOpenModal(false)}
                startIcon={<ArrowBack />}
                variant="contained"
                color="primary"
                style={{
                  fontWeight: '550',
                  marginBottom: '20px',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
              >
                Voltar
              </Button>

              <Typography
                component="h1"
                variant="h5"
                style={{
                  marginBottom: '30px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                QR Code de: {qrCodeContent.title}
              </Typography>

              <div style={{ textAlign: 'center' }}>
                {qrCodeType === 'dynamic_link' ? (
                  <>
                    <QRCode value={qrCodeShortLink} size={larguraJanela * 0.3} />
                    <Typography
                      component="h2"
                      variant="h5"
                      style={{
                        marginBottom: '30px',
                        marginTop: '30px',
                        textAlign: 'center',
                      }}
                    >
                      {qrCodeShortLink}
                    </Typography>
                  </>
                ) : (
                  <QRCode value={JSON.stringify(qrCodeContent)} size={larguraJanela * 0.3} />
                )}

                <br />
                <Button
                  onClick={() =>
                    qrCodeType === 'dynamic_link' ? window.open(qrCodeShortLink, '_blank') : ''
                  }
                  startIcon={<Save />}
                  variant="contained"
                  color="primary"
                  style={{
                    fontWeight: '550',
                    marginBottom: '20px',
                    marginTop: '10px',
                    marginLeft: '10px',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                >
                  Salvar QRCode
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    />
  );
};

DataTableQrcode.propTypes = {
  rows: PropTypes.array.isRequired,
  pathName: PropTypes.string.isRequired,
};

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default DataTableQrcode;

