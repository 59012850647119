import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import React, { useState } from 'react';

export const PublishDates = ({
  enableDate,
  setEnableDate,
  disableDate,
  setDisableDate,
  formattedFuture,
}) => {
  const [isDisableDateChecked, setIsDisableDateChecked] = useState(formattedFuture);

  return (
    <S.Container>
      <S.DateGroup>
        <D.TriiboH4>
          <b>Data de Publicação</b>
        </D.TriiboH4>
        <D.TriiboH5>Defina a data de ativação e desativação da postagem.</D.TriiboH5>
        <TextField
          style={{ marginRight: '1rem', borderRadius: '0.5rem' }}
          id="datetime-local"
          format="dd/MM/yyyy HH:mm"
          label="Data de ativação"
          type="datetime-local"
          value={enableDate}
          onChange={(event) => setEnableDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          color="primary"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isDisableDateChecked}
              onChange={(event) => setIsDisableDateChecked(event.target.checked)}
              name="disableDateCheckbox"
              color="primary"
            />
          }
          label="Adicionar data de desativação"
        />
        <TextField
          style={{ borderRadius: '0.5rem' }}
          id="datetime-local"
          format="dd/MM/yyyy HH:mm"
          label="Data de desativação"
          type="datetime-local"
          value={disableDate}
          onChange={(event) => setDisableDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          color="primary"
          disabled={!isDisableDateChecked}
        />
      </S.DateGroup>
    </S.Container>
  );
};
