import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { getUserInfo } from '../../helpers';
import cfac22 from '../../cfac22';

export default function addSmsDispatch(admin, establishmentId) {
  return new Promise((resolve, reject) => {
    if (establishmentId !== null) {
      const userInfo = getUserInfo();

      authToken(userInfo.uId)
        .then((token) => {
          const payload = {
            cellphone: admin.telefone,
            uid: admin.uid,
          };

          axios
            .post(
              `${cfac22('API_HOST_V2')}/sms/smsDispatch?establishmentId=${establishmentId}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((result) => {
              resolve(result.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject(new Error('Invalid establishmentId'));
    }
  });
}
