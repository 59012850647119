import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

//icons
import { MdArrowBackIosNew } from 'react-icons/md';

//styles
import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from '../styles';

//components
import FieldsList from './FieldsList';
import ModalAddField from './ModalAddField';
import { CircularProgress } from '@mui/material';

//APIs
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22';

const data = [
  {
    id: '0',
    fieldName: 'aliasName',
    label: 'Insira seu apelido',
    fieldType: 'text',
    inputType: 'text',
    // inputMask: '',
    allowEdition: true,
    required: true,
    type: 'others',
  },
];

export default function NpsFormSignUp() {
  const [seuClubeInfos, setSeuClubeInfos] = useState({});
  const [formsFields, setFormsFields] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldIndexKey, setFieldIndexKey] = useState(null);
  const [indexKeyAlreadyExists, setIndexKeyAlreadyExists] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState('');
  const [loadingForm, setLoadingForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState({
    estimatedMinimumTime: false,
    npsFormAnswered: true,
    postphoneCheck: false,
    isActive: false,
  });
  const [questions, setQuestions] = useState([]);

  const [hasNps, setHasNps] = useState(false);

  const fieldTypes = [
    {
      name: 'Avaliação',
      type: 'rating',
    },
    {
      name: 'Texto',
      type: 'text',
    },
  ];

  useEffect(() => {
    const clubeIdUrl = document.location.pathname.split('/')[4];

    const getForm = async () => {
      const seuClubeInfo = await getSeuClube(clubeIdUrl);
      setSeuClubeInfos(seuClubeInfo);
      try {
        const response = await GetPartnerUserForms(seuClubeInfo.partnerId);

        const addIdIntoFields = response.formFields.map((field, index) => {
          return {
            ...field,
            id: index.toString(),
          };
        });

        setFormsFields(new Set(addIdIntoFields));
        setIndexKeyAlreadyExists(true);
        setLoadingForm(false);
      } catch (error) {
        setFormsFields(new Set(data));
        setLoadingForm(false);
      }
    };

    getForm();
  }, []);

  useEffect(() => {
    if (!seuClubeInfos.partnerId) return;

    const getNpsForm = async () => {
      const token = await authToken();
      const headers = {
        headers: { authorization: `Bearer ${token}` },
      };
      try {
        const response = (
          await axios.get(
            `${cfac22('API_HOST_V2')}/nps/getNpsFormForAdmin/${seuClubeInfos.partnerId}`,
            headers
          )
        )?.data?.response;

        if (response.clubId) {
          setRules(response.rules);
          setQuestions(response.questions);
          setHasNps(true);
        }
      } catch (err) {
        const error = err.response.data.error;
        if (error === 'O club de id: nebuloza, não possui formulário NPS') setHasNps(false);
      }
    };

    getNpsForm();
  }, [seuClubeInfos]);

  useEffect(() => {
    const field = Array.from(formsFields).find((field) => field.indexKey);
    if (field) {
      setFieldIndexKey(field);
    } else {
      setFieldIndexKey(null);
    }
  }, [formsFields]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveNpsForm = async () => {
    const token = await authToken();
    const partnerId = seuClubeInfos?.partnerId;
    let endpoint = 'nps/editNpsForm';
    const headers = {
      headers: { authorization: `Bearer ${token}` },
    };
    let body = {
      clubId: partnerId,
      changedFields: {
        questions,
        rules,
      },
    };

    if (!hasNps) {
      body = {
        payload: {
          clubId: partnerId,
          rules,
          questions,
        },
      };

      endpoint = 'nps/addNpsForm';
    }

    const response = (await axios.post(`${cfac22('API_HOST_V2')}/${endpoint}`, body, headers))?.data
      .response;
    if (response) {
      setLoading(false);
      setHasNps(true);
      toast.success('Formulário adicionado com sucesso!');
    } else {
      setLoading(false);
      toast.error('Erro ao cadastrar formulário.');
    }
  };

  const handleSubmit = () => {
    if (!questions.length) {
      toast.error('Adicione campos do formulário!');
      return;
    }
    setLoading(true);

    saveNpsForm();
  };

  const handleRemoveField = (currentIndex) => {
    const newQuestions = questions?.filter((_, index) => index !== currentIndex);
    setQuestions(newQuestions);
  };

  if (loadingForm) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <S.Container>
      <AreaHeader style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem' }}>
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>

      <T.TriiboH1>Formulário NPS</T.TriiboH1>

      <>
        <FieldsList
          formsFields={questions}
          setQuestions={setQuestions}
          rules={rules}
          setRules={setRules}
          setFormsFields={setFormsFields}
          fieldIndexKey={fieldIndexKey}
          loading={loading}
          handleSubmit={handleSubmit}
          setFieldToEdit={setFieldToEdit}
          setIsModalOpen={setIsModalOpen}
          indexKeyAlreadyExists={indexKeyAlreadyExists}
          fieldTypes={fieldTypes}
          handleRemoveField={handleRemoveField}
        />
      </>

      {isModalOpen && (
        <ModalAddField
          questions={questions}
          setQuestions={setQuestions}
          open={isModalOpen}
          handleClose={handleCloseModal}
          formsFields={formsFields}
          setFormsFields={setFormsFields}
          fieldToEdit={fieldToEdit}
          setFieldToEdit={setFieldToEdit}
          fieldTypes={fieldTypes}
        />
      )}
    </S.Container>
  );
}
