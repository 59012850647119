import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 1100px;
  padding: 1rem;
  margin: 5rem auto;

  h1 {
    text-align: center;
  }
`;

export const Module = styled.article`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Cols = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  height: 100%;
  width: 100%;
  max-width: 518px;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Input = styled.input`
  border-radius: 10px;
  border: 1px solid #757575;
  height: 42px;
  padding: 0 1rem;
`;

export const TextCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 1rem;
  max-height: 407px;
  min-height: 200px;
  height: 100%;
  flex: 1;
`;

export const TextArea = styled.textarea`
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  background-color: #fff;
  font-size: 15px;
  border: none;
  resize: none;
  outline: none;
  flex: 1;

  ::placeholder {
    font-style: italic;
    color: #707070;
  }
`;

export const TextCounter = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  color: #707070;
`;

export const PeriodsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button`
  background: ${({ type }) => (type === 'cancel' ? 'rgba(241, 58, 58, 0.05);' : '#328796')};
  border: 1px solid ${({ type }) => (type === 'cancel' ? '#F13A3A' : '#328796')};
  border-radius: 10px;
  border: ${({ type }) => type !== 'cancel' && 'none'};
  outline: none;
  width: ${({ w }) => w || '160px'};
  height: 42px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    color: ${({ type }) => (type === 'cancel' ? '#F13A3A' : '#ffffff')};
    font-weight: 600;
  }

  &:hover {
    background: ${({ type }) => (type === 'cancel' ? '#F13A3A33' : '#328796e5')};
  }

  &:disabled {
    background: #ccc;
  }
`;

export const Periods = styled.ul`
  list-style: none;
  display: flex;
  gap: 0.5rem;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 4px solid #f2f2f2;
  }
`;

export const PeriodItem = styled.li`
  background: rgba(50, 135, 150, 0.45);
  border-radius: 10px;
  width: 25.55px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  flex: none;

  &:hover {
    background: rgba(50, 135, 150, 0.6);
  }
`;

export const AddPeriod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
`;

export const DatesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Date = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SelectDateContainer = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
`;

export const InputDate = styled.input`
  border: none;
  outline: none;
  height: 100%;
  font-size: 16px;
  color: #383838;
  flex: 1;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  max-width: 337px;
  width: 100%;
  gap: 1rem;
  margin: 0 auto;
`;

export const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
