import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  h5 {
    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 550px) {
    flex-direction: column-reverse;
  }
`;

export const GoBackButton = styled.button`
  width: 100%;
  height: 39px;
  border: 1px solid #328796;
  border-radius: 10px;
  background-color: #fff;
  font-weight: 600;
  color: #328796;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const PublishButton = styled.button`
  width: 100%;
  height: 39px;
  border-radius: 10px;
  border: none;
  background: #328796;
  color: #fff;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: #328796e5;
  }
`;
