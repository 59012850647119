import { makeStyles } from "@mui/styles";
import styled from 'styled-components';

export default makeStyles((theme) => ({
  searchButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },

  backButton: {
    textTransform: 'capitalize',

    marginRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },
  },

  backButtonIcon: {
    height: 35,
    width: 35,
  },

  radioOption: {
    minWidth: '50%',
    whiteSpace: 'nowrap',
  },

  title: {
    fontWeight: 'bold',
  },
}));

export const searchCellBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: ${({ height }) => height || '40px'};
  border: 1px solid #ccc;
  border-radius: 5px;

  select {
    border: none;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }

  .searchInput {
    flex: 1;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }
`;
