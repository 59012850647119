import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import { Box } from "@mui/material";

const COLORS = [
  "#00BCD5",
  "#FFC921",
  "#001DFF",
  "#D01313",
  "#4DB417",
  "#EF534F",
  "#FFCD39",
  "#BDBDBD",
  "#5D6BC0",
  "#A3E0FB",
  "#42A5F5",
  "#AB47BC",
  "#76C27A",
];

export default ({ data }) => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const formatData = () => {
      const formatedData = [];
  
      data.forEach((item) =>
        formatedData.push(
          ...Object.keys(item).map((key) => ({ name: key, value: item[key] }))
        )
      );
      setInfo(formatedData);
    };

    if (data) {
      setInfo(data);
      if (!data[0]?.name && !data[0]?.value) formatData();
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      {info.length === 0 ? (
          <Box
          height={250}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          Não há Dados
        </Box>
      ) : (
        <PieChart>
        <Legend />
        <Pie
          data={info}
          innerRadius={50}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {info.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      )}
    </ResponsiveContainer>
  );
};
