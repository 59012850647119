import React, { useEffect, useState } from 'react';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import * as E from 'js/components/DesignSystem/Table/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { Button, Tooltip } from '@mui/material';
import {
  BoxSearch,
  ContainerSearch,
  Input,
  InputBox,
  InvisibleButton,
  InvisibleLink,
  SearchIconButton,
} from './styles';

// iCONES
import { Add } from '@mui/icons-material';
import { MdArrowBackIosNew } from 'react-icons/md';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import searchIcon from 'styles/assets/SearchUser/search.svg';

// COMPONENTES
import CustomButtonsTableApiPaginated from 'js/components/TriiboComponents/Tables/CustomButtonsTable/CustomButtonsTableApiPaginated';

// APIS
import { getAllCampaignLogins } from 'js/library/utils/API/CustomLogin/getAllCampaignLogins';

import { getUserInfo } from 'js/library/utils/helpers';
import cfac22 from 'js/library/utils/cfac22';

export default function ListLogin() {
  const [list, setList] = useState([]);
  const [listTable, setListTable] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState({
    size: 20,
    from: 0,
  });
  const [itemsPerPage, setItemsPerPage] = useState(0);

  const column = [
    {
      heading: 'Id da campanha',
      value: 'id',
    },
    {
      heading: 'Participação automática',
      value: 'autoParticipate',
    },
    {
      heading: 'Data de criação',
      value: 'createDate',
    },
  ];

  useEffect(() => {
    const uId = getUserInfo().uId;

    const listLogin = async () => {
      try {
        const res = await getAllCampaignLogins(uId, data);
        setList((prevState) => [...prevState, ...res]);
        setItemsPerPage(res.length);

        const formattedFields = res?.map(format);
        setListTable((prevState) => [...prevState, ...formattedFields]);
      } catch (error) {
        console.error('Erro:', error);
      }
    };
    listLogin();
  }, [data]);

  const format = (dataLogin) => {
    const dateInMillis = dataLogin.createDate;
    const formattedDate = new Date(dateInMillis).toLocaleDateString('pt-BR').replace(/\//g, '/');

    const formatted = {
      id: dataLogin.id,
      autoParticipate: dataLogin.autoParticipate ? 'Sim' : 'Não',
      createDate: formattedDate,
    };

    return formatted;
  };

  const handleSearch = (e) => {
    const term = e.target.value.trim();
    setSearchTerm(term);

    const filtered = listTable.filter((campaign) => campaign.id.startsWith(term));
    setFilteredCampaigns(filtered);
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <E.TableTH>Ações</E.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <E.TableTD>
              <Tooltip title="Editar" placement="top" style={{ marginRight: '1.2rem' }}>
                <InvisibleButton
                  $color="#08bad0"
                  to={{
                    pathname: `/admin/configuracoes/loginPersonalizado/editar/${childrens.id}`,
                    state: list.find((item) => item.id === childrens.id),
                  }}
                >
                  <EditRoundedIcon size={25} />
                </InvisibleButton>
              </Tooltip>
              <Tooltip title="Acessar" placement="top" style={{ marginRight: '1.2rem' }}>
                <InvisibleLink
                  href={`${cfac22('LINK_MAPFRE')}login/?campaignId=${childrens.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  $color="#08bad0"
                >
                  <LaunchRoundedIcon size={25} />
                </InvisibleLink>
              </Tooltip>
            </E.TableTD>
          </>
        ),
    };
  };

  return (
    <S.Container>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        Lista de Logins
      </D.TriiboH1>

      <ContainerSearch>
        <BoxSearch>
          <InputBox>
            <Input placeholder="Pesquise pelo id da campanha..." onChange={handleSearch} />
            <SearchIconButton onClick={(e) => e.preventDefault()}>
              <img src={searchIcon} alt="" />
            </SearchIconButton>
          </InputBox>
        </BoxSearch>

        <InvisibleButton to="/admin/configuracoes/loginPersonalizado/adicionar">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Adicionar Novo
          </Button>
        </InvisibleButton>
      </ContainerSearch>

      {searchTerm && filteredCampaigns.length === 0 ? (
        <p>Nenhuma campanha encontrada.</p>
      ) : (
        <CustomButtonsTableApiPaginated
          data={searchTerm ? filteredCampaigns : listTable}
          column={column}
          perPage={10}
          setData={setData}
          itemsPerPage={itemsPerPage}
          size={data.size}
          componentReceiver={componentReceiver}
        />
      )}
    </S.Container>
  );
}
