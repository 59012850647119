import React from 'react';
import * as S from './styles';

export default function PrimaryButton({
  children,
  width,
  height,
  bgColor,
  color,
  ...props
}) {
  return (
    <S.Button
      width={width}
      height={height}
      bgColor={bgColor}
      color={color}
      {...props}>
      {children}
    </S.Button>
  );
}
