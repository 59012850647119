import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 1rem 4rem 1rem; //top right bottom left
  @media screen and (min-width: 600px) {
    padding: 3rem 3rem 4rem 3rem;
  }
  @media screen and (min-width: 900px) {
    padding: 3rem 5rem 4rem 5rem;
  }
  @media screen and (min-width: 1340px) {
    padding: 3rem 6rem 4rem 6rem;
  }
  @media screen and (min-width: 1600px) {
    padding: 3rem 10rem 4rem 10rem;
  }
`;

export const Content = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707033;
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Cols = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 3rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
`;

export const TextFild = styled.input`
  width: 100%;
  height: 42px;
  padding: 0 1rem;
  border: 1px solid #757575;
  border-radius: 10px;
`;

export const TextCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 1rem;
`;

export const TextArea = styled.textarea`
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
  background-color: #fff;
  font-size: 15px;
  border: none;
  resize: none;
  outline: none;
  ::placeholder {
    font-style: italic;
    color: #707070;
  }
`;

export const TextCounter = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  color: #707070;
`;

export const ImportImageBox = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #757575;
  border-radius: 10px;

  h5{
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ImportImageButton = styled.button`
  width: 150px;
  height: 100%;
  background: #328796;
  padding: 0 1rem;
  margin-left: -2px;
  border-radius: 10px;
  border: none;
  height: 42px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  h5 {
    color: #fff;
    font-weight: 600;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonOpenModal = styled.button`
  width: 100%;
  height: 132px;
  border-radius: 10px;
  background: #6e3296;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 6px #00000029;
  h4 {
    color: #fff;
  }
  &:hover {
    filter: brightness(0.9);
    scale: 1.1;
  }
`;

export const RegisterButton = styled.button`
  margin: 0 auto;
  width: 150px;
  padding: 0 1rem;
  height: 42px;
  background: #328796;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    filter: brightness(0.8);
  }
  &:disabled {
    display: flex;
    align-items: center;
    background: #dbdbdb;
  }
`;

export const SelectCampaignId = styled.div`
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #757575;
  }
`;

export const SelectCampaignPeriod = styled.select`
  height: 42px;
  border-radius: 10px;
  border: 1px solid #757575;
  padding: 0 1rem;
  outline: none;
`;

export const BoxContent = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* justify-content: start; */
`;

export const ContentInfo = styled.div`
  border: 1px solid #757575;
  padding: 0 1rem;
  border-radius: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ButtonEdit = styled.button`
  height: 100%;
  background: #6e3296;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: opacity(0.9);
  }

  img {
    width: 24px;
  }
`;
