export default function reducer(
  state = {
    walletTitle: null,
    walletDetailsTitle: null,
    walletDescription: null,
    walletDetailsDescription: null,
    walletThumbnail: null,
    walletPhoto: null,
    thumbnailFile: null,
    photoFile: null,
    lotterySerie: null,
    quizzes: null,
    events: null,
    voucherEach: null,
    voucherQuantity: null,
    activationDate: null,
    dueDate: null,
    establishmentId: null,
    establishmentName: null,
    campaingId: null,
    campaingName: null,
  },
  action
) {
  switch (action.type) {
    case "CAMPAING_TRIIBO_PENDING": {
      return {
        ...state,
        walletTitle: null,
        walletDetailsTitle: null,
        walletDescription: null,
        walletDetailsDescription: null,
        walletThumbnail: null,
        walletPhoto: null,
        thumbnailFile: null,
        photoFile: null,
        lotterySerie: null,
        quizzes: null,
        events: null,
        voucherEach: null,
        voucherQuantity: null,
        activationDate: null,
        dueDate: null,
        establishmentId: null,
        establishmentName: null,
        campaingId: null,
        campaingName: null,
      };
    }

    case "CAMPAING_TRIIBO_FULFILLED": {
      return {
        ...state,
        walletTitle: action.payload.walletTitle,
        walletDetailsTitle: action.payload.walletDetailsTitle,
        walletDescription: action.payload.walletDescription,
        walletDetailsDescription: action.payload.walletDetailsDescription,
        walletThumbnail: action.payload.walletThumbnail,
        walletPhoto: action.payload.walletPhoto,
        thumbnailFile: action.payload.thumbnailFile,
        photoFile: action.payload.photoFile,
        lotterySerie: action.payload.lotterySerie,
        quizzes: action.payload.quizzes,
        events: action.payload.events,
        voucherEach: action.payload.voucherEach,
        voucherQuantity: action.payload.voucherQuantity,
        activationDate: action.payload.activationDate,
        dueDate: action.payload.dueDate,
        establishmentId: action.payload.establishmentId,
        establishmentName: action.payload.establishmentName,
        campaingId: action.payload.campaingId,
        campaingName: action.payload.campaingName,
      };
    }

    case "CAMPAING_TRIIBO_REJECTED": {
      return {
        ...state,
        walletTitle: null,
        walletDetailsTitle: null,
        walletDescription: null,
        walletDetailsDescription: null,
        walletThumbnail: null,
        walletPhoto: null,
        thumbnailFile: null,
        photoFile: null,
        lotterySerie: null,
        quizzes: null,
        events: null,
        voucherEach: null,
        voucherQuantity: null,
        activationDate: null,
        dueDate: null,
        establishmentId: null,
        establishmentName: null,
        campaingId: null,
        campaingName: null,
      };
    }

    default:
      return state;
  }
}
