import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import TemplateModalLogin from './templateModalLogin';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, CircularProgress, Button, Typography, TextField } from '@mui/material';
import { cpfMask } from 'js/library/utils/helpers';

//arquivo com as actions
import { signInAction } from 'js/core/actions/signInActions.js';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';

import { toast } from 'react-toastify';

function SignIn(props) {
  const [cpf, setCpf] = useState('');
  const [aliasname, setAliasname] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [cpfIsInvalid, setCpfIsInvalid] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  useEffect(() => {
    window.pathname = null;
  }, []);

  const handleChangeCPF = (event) => {
    setCpf(cpfMask(event.target.value));

    if (event.target.value !== cpf) {
      const strCPF = String(cpfMask(event.target.value)).replace(/[^\d]/g, '');

      if (strCPF.length !== 11) {
        setCpfIsInvalid(true);
      } else {
        setCpfIsInvalid(false);
      }
    }
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);

    const regex = /\S+@\S+\.\S+/;

    if (regex.test(event.target.value)) {
      setEmailIsInvalid(false);
    } else {
      setEmailIsInvalid(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    //PESQUISANDO SE USUARIO EXISTE PELO CPF
    const userInfo = {
      documentList: [
        {
          type: 'cpf',
          value: cpf,
        },
      ],
    };

    getUserInfo_v1(['getUserInfo'], userInfo).then((result) => {
      if (result.data.success.userInfo === false) {
        props.signInActionComponent(cpf, aliasname, name, email);
      } else {
        toast.error('Já existe um usuário com esse CPF');

        setLoading(false);
        setCpfIsInvalid(false);
      }
    });
  };

  if (window.pathname) {
    return <Redirect to={window.pathname} />;
  }

  return (
    <>
      <TemplateModalLogin width="660px" handleSubmit={(e) => handleSubmit(e)}>
        <Typography
          sx={{ width: '80%' }}
          variant="subtitle1"
          fontSize={{ xs: '14px', md: '17px', lg: '22px' }}
          textAlign={'center'}
          color={'#383838'}
          fontWeight="regular"
        >
          Notamos que esta é sua primeira vez aqui. Diga-nos seus dados antes de começarmos.
        </Typography>
        <Box
          component="form"
          sx={{
            width: '100%',
            '& .MuiTextField-root': { m: 1, width: '95%' },
            display: 'flex',
            flexDirection: 'column',
          }}
          autoComplete="off"
          suggestions="off"
        >
          <TextField
            required
            id="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">Nome* </InputAdornment>,
            }}
            sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: '14px',
                boxShadow: '0px 3px 6px #00000029',
                borderColor: '#70707033',
              },
            }}
          />
          <TextField
            required
            id="outlined"
            value={aliasname}
            onChange={(e) => setAliasname(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">Apelido* </InputAdornment>,
            }}
            sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: '14px',
                boxShadow: '0px 3px 6px #00000029',
                borderColor: '#70707033',
              },
            }}
          />
          <TextField
            required
            id="outlined"
            error={cpfIsInvalid ? true : false}
            helperText={cpfIsInvalid ? 'CPF inválido.' : ''}
            // label="CPF"
            value={cpf}
            onChange={(e) => handleChangeCPF(e)}
            InputProps={{
              startAdornment: <InputAdornment position="start">CPF* </InputAdornment>,
            }}
            sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: '14px',
                boxShadow: '0px 3px 6px #00000029',
                borderColor: '#70707033',
              },
            }}
          />
          <TextField
            required
            error={emailIsInvalid && email !== '' ? true : false}
            helperText={emailIsInvalid && email !== '' ? 'Email inválido' : ''}
            id="outlined"
            value={email}
            onChange={(e) => handleChangeEmail(e)}
            inputProps={{ type: 'email' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">E-mail* </InputAdornment>,
            }}
            sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: '14px',
                boxShadow: '0px 3px 6px #00000029',
                borderColor: '#70707033',
              },
            }}
          />
        </Box>
        <Button
          sx={{
            width: { xs: '160px', sm: '360px' },
            padding: '2',
            backgroundColor: '#08bad0',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#08bad0',
              filter: 'brightness(0.9)',
            },
          }}
          disabled={
            cpf.length < 14 ||
            aliasname === '' ||
            name === '' ||
            emailIsInvalid ||
            email.length < 1 ||
            loading ||
            cpfIsInvalid
          }
          type="submit"
          fullWidth
          variant="contained"
        >
          {loading ? <CircularProgress size="2rem" style={{ color: 'white' }} /> : 'continuar'}
        </Button>
      </TemplateModalLogin>
    </>
  );
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.signInComponent.loading,
    success: state.signInComponent.success,
    error: state.signInComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  signInActionComponent: (cpf, aliasname, name, email) =>
    signInAction(dispatch, cpf, aliasname, name, email),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

