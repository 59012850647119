import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useStyles } from './useStyles';
import { TriiboH1 } from 'js/components/DesignSystem/styles';

export function Header({ history, pageInfo }) {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <TriiboH1>{pageInfo}</TriiboH1>
      <Button
        color='primary'
        className={classes.link}
        onClick={() => history.goBack()}>
        <Typography
          style={{ marginLeft: 'auto' }}
          color='primary'
          className={classes.arrowBackButton}
          variant='h5'
          gutterBottom
          component='span'>
          <ArrowBackIos fontSize='small' />
          Voltar
        </Typography>
      </Button>
    </Box>
  );
}
