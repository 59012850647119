import React, { useEffect, useState, useRef } from 'react';
import { getImage, getStoreImage } from './MarketingServices';
import { useElementImage, useTemplate } from '../Context/Marketing';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { AddPhotoAlternate } from '@mui/icons-material';

const TabImage = ({ classes, tabIndex }) => {
  const {
    selectedTemplate,
    storeData,
    establishmentData,
    listElementSelected,
    setListElementSelected,
    setLoadingImage,
    loadingImage,
  } = useTemplate();

  const {
    elementImage,
    setElementImage,
    setImageValue,
    setWidthSizeImage,
    setWidthPositionImage,
    setHeightSizeImage,
    setHeightPositionImage,
  } = useElementImage();

  const [titleStoreSelected, setTitleStoreSelected] = useState(
    listElementSelected[tabIndex]?.title === undefined
      ? ''
      : listElementSelected[tabIndex]?.title
  );

  const [thumbnailStore, setThumbnailStore] = useState(
    listElementSelected[tabIndex]?.image === undefined
      ? placeholder
      : listElementSelected[tabIndex]?.image
  );
  const [photoListStore, setPhotoListStore] = useState(
    []
    // listElementSelected[tabIndex]?.image === undefined ?
    // [] : [listElementSelected[tabIndex]?.image]
  );
  const [thumbnailEstablishment, setThumbnailEstablishment] =
    useState(placeholder);
  const [photoListEstablishment, setPhotoListEstablishment] = useState([]);
  const [positionImage, setPositionImage] = useState(0);

  const [localImage, setLocalImage] = useState([]);
  // const [scrollX, setScrollX] = useState(0);

  const currentElement = selectedTemplate.elements[tabIndex];

  // retorna nome das promoções, sendo utilizado para options do select
  const storeName = storeData.map((store) => store.title);

  const selectedStore = storeData
    .filter((store) => store.title === titleStoreSelected)
    .shift();

  const elementRef = useRef(null);

  // Baixar imagens de Promoção ao iniciar o componente
  useEffect(() => {
    if (
      selectedStore !== undefined &&
      listElementSelected[tabIndex].type === 'store'
    ) {
      if (currentElement.initialImage === 'thumbnail') {
        getStoreImage(
          selectedStore.thumbnail,
          setThumbnailStore,
          'thumbnail',
          null,
          'promocao',
          tabIndex,
          setListElementSelected,
          listElementSelected,
          setLoadingImage
        );
      } else {
        getStoreImage(
          selectedStore.photoList,
          setPhotoListStore,
          'photoList',
          photoListStore,
          'promocao',
          tabIndex,
          setListElementSelected,
          listElementSelected,
          setLoadingImage
        );
      }
    }
  }, [selectedStore]);

  // Baixar imagens de Estabelecimento ao iniciar o componente
  useEffect(() => {
    if (
      listElementSelected.length > 0 &&
      listElementSelected[tabIndex].type === 'establishment'
    ) {
      if (currentElement.initialImage === 'thumbnail') {
        getImage(
          establishmentData.thumbnail,
          setThumbnailEstablishment,
          'thumbnail',
          null,
          'estabelecimento',
          tabIndex,
          setListElementSelected,
          listElementSelected,
          setLoadingImage
        );
      } else {
        getImage(
          establishmentData.photoList,
          setPhotoListEstablishment,
          'photoList',
          photoListEstablishment,
          'estabelecimento',
          tabIndex,
          setListElementSelected,
          listElementSelected,
          setLoadingImage
        );
      }
    }
  }, [establishmentData]);

  useEffect(() => {
    const filterElement =
      Object.values(selectedTemplate).length > 0 &&
      selectedTemplate?.elements.filter(
        (element) => element.entity === 'image'
      );

    setElementImage(filterElement[0]);
    setImageValue(
      filterElement[0]?.initialText === 'thumbnail' ? 'thumbnail' : 'photoList'
    );
    setHeightPositionImage(filterElement[0]?.heightPosition);
    setHeightSizeImage(filterElement[0]?.heightSize);
    setWidthPositionImage(filterElement[0]?.widthPosition);
    setWidthSizeImage(filterElement[0]?.widthSize);

    return () => {
      setElementImage({});
    };
  }, [selectedTemplate]);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;

        resolve(base64data);
      };
    });
  };

  function handleUptadeTemplate(image) {
    setLoadingImage(true);

    getBase64FromUrl(image).then((img) => {
      setLoadingImage(false);

      listElementSelected[tabIndex]['image'] = img;
      setListElementSelected([...listElementSelected]);
    });
  }

  function handleUptadeTamplateFree(image) {
    listElementSelected[tabIndex]['image'] = image;
    setListElementSelected([...listElementSelected]);
  }

  // SETA UM VALOR DEFAULT PARA O SELECT
  useEffect(() => {
    if (
      storeData.length > 0 &&
      listElementSelected[tabIndex]?.title === undefined
    ) {
      setTitleStoreSelected(storeData[0].title);

      listElementSelected[tabIndex]['title'] = storeData[0].title;
      setListElementSelected([...listElementSelected]);
    }
  }, []);

  function handleFileChange(event) {
    if (event.target.files[0]) {
      const image = event.target.files[0];

      if (image.type === 'image/jpeg' || image.type === 'image/png') {
        const url = URL.createObjectURL(image);

        listElementSelected[tabIndex]['image'] = url;
        setListElementSelected([...listElementSelected]);

        setLocalImage([url]);
      } else {
        alert('Envie uma imagem do tipo PNG ou JPEG');
        return;
      }
    }
  }

  function handleTitleStore(title) {
    setTitleStoreSelected(title);

    listElementSelected[tabIndex]['title'] = title;
    setListElementSelected([...listElementSelected]);
  }

  // function handleLeftArrow(){
  //   let valueX = scrollX + Math.round(window.innerWidth / 4);

  //   if(valueX > 0){
  //     valueX = 0
  //   }

  //   setScrollX(valueX);
  // }

  // function handleRightArrow(){
  //   const containerWidth = elementRef.current.getBoundingClientRect().width;

  //   let valueX = scrollX - Math.round(containerWidth);
  //   let listWidth = localImage.length * 150;

  //   if((containerWidth - listWidth) > valueX){
  //     valueX = (containerWidth - listWidth)
  //   }

  //   if(listWidth > containerWidth){
  //     setScrollX(valueX);
  //   }
  // }

  // useEffect(() => {
  //   if(localImage.length > 0){

  //     handleRightArrow();
  //   }
  // }, [localImage])

  // console.log("***Image: ", imageValue);
  // console.log("***Element-selected: ", selectedTemplate);

  return (
    <>
      {elementImage === undefined && (
        <Typography variant='subtitle2' className={classes.elementTypeTitle}>
          Escolha um Template
        </Typography>
      )}
      {elementImage?.type === 'establishment' && (
        <Box className={classes.boxElement}>
          <Typography variant='subtitle2' className={classes.elementTypeTitle}>
            Tipo estabelecimento
          </Typography>

          <Box>
            {currentElement.initialImage === 'thumbnail' ? (
              <img
                className={classes.elementImage}
                src={thumbnailEstablishment}
                alt='Elemento Imagem'
                onClick={() => handleUptadeTemplate(thumbnailEstablishment)}
                style={{
                  border:
                    listElementSelected[tabIndex].image ===
                    thumbnailEstablishment
                      ? '5px solid #08BAD0'
                      : 'none',
                  cursor: 'pointer',
                }}
              />
            ) : (
              photoListEstablishment.map((photoReceived, i) => (
                <img
                  key={i}
                  className={classes.elementImage}
                  src={photoReceived}
                  alt='Elemento Imagem'
                  onClick={() => {
                    handleUptadeTemplate(photoReceived);
                    setPositionImage(i);
                  }}
                  style={{
                    border: positionImage === i ? '5px solid #08BAD0' : 'none',
                    cursor: 'pointer',
                  }}
                />
              ))
            )}
          </Box>
        </Box>
      )}
      {elementImage?.type === 'store' && (
        <Box className={classes.boxElement}>
          <Typography variant='subtitle2' className={classes.elementTypeTitle}>
            Tipo promoção
          </Typography>

          <FormControl className={classes.inputElementText}>
            <InputLabel id='store-select-label'>
              Selecione a Promoção
            </InputLabel>
            <Select
              labelId='store-select'
              id='store-select'
              value={listElementSelected[tabIndex]?.title || titleStoreSelected}
              onChange={(e) => handleTitleStore(e.target.value)}>
              {storeName.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            {currentElement.initialImage === 'thumbnail' ? (
              loadingImage ? (
                <CircularProgress size={25} style={{ marginLeft: '20px' }} />
              ) : (
                <img
                  className={classes.elementImage}
                  src={thumbnailStore}
                  alt='Elemento Imagem'
                />
              )
            ) : selectedStore?.photoList === undefined ? (
              <Typography
                variant='subtitle2'
                className={classes.elementTypeTitle}>
                Promoção não possuí Lista de Imagens
              </Typography>
            ) : (
              photoListStore.map((photoReceived, i) => {
                return loadingImage ? (
                  <CircularProgress size={25} style={{ marginLeft: '20px' }} />
                ) : (
                  <img
                    key={i}
                    className={classes.elementImage}
                    src={photoReceived}
                    alt='Elemento Imagem'
                    onClick={() => {
                      handleUptadeTemplate(photoReceived);
                      setPositionImage(i);
                    }}
                    style={{
                      border:
                        positionImage === i ? '5px solid #08BAD0' : 'none',
                      cursor: 'pointer',
                    }}
                  />
                );
              })
            )}
          </Box>
        </Box>
      )}
      {elementImage?.type === 'free' && (
        <Box className={classes.boxElement}>
          <Typography variant='subtitle2' className={classes.elementTypeTitle}>
            Tipo livre
          </Typography>

          {/* LISTA DE IMAGENS ADICIONADA PELO USUÁRIO */}
          <div ref={elementRef} className={classes.imageContainerFree}>
            {/* 
            <div id="arrow-image" className={classes.arrowLeft} onClick={handleLeftArrow} >
              <NavigateBeforeIcon />
            </div>

            <div id="arrow-image" className={classes.arrowRight} onClick={handleRightArrow} >
              <NavigateNextIcon />
            </div> */}

            <div className={classes.imageListContent}>
              <div
                style={{
                  // marginLeft: scrollX,
                  width: localImage.length * 150,
                }}
                className={classes.imageList}>
                {localImage.length > 0 &&
                  localImage.map((img, key) => (
                    <div key={key} className={classes.imageItem}>
                      <img
                        src={img}
                        alt='Elemento Imagem'
                        onClick={() => handleUptadeTamplateFree(img)}
                        style={{
                          border:
                            listElementSelected[tabIndex]?.image === img
                              ? '5px solid #08BAD0'
                              : '',
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* //BUTTON FILE IMAGE*/}
          <div className={classes.contentAreaButtonFile}>
            <input
              style={{ display: 'none' }}
              type='file'
              accept='image/*'
              onChange={handleFileChange}
              id='thumbnail'
              disabled={localImage.length > 5}
            />

            <label htmlFor='thumbnail'>
              <Button
                variant='contained'
                color='primary'
                component='span'
                className={classes.buttonFile}
                startIcon={<AddPhotoAlternate />}>
                Adicionar Fotos
              </Button>
            </label>
          </div>
        </Box>
      )}
    </>
  );
};

export default TabImage;
