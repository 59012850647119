import React, { useState } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';

// STYLES
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { BoxModal } from 'js/components/Clubs/Segmentation/segmentStyles';

// APIS
import { createPartnerFilesType } from 'js/library/utils/API/createPartnerFilesType';

export const ModalCreateFileType = ({ close, isOpen, partnersList, setCallPage }) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [rows, setRows] = useState([{ name: '', required: true }]);

  const [isActive, setIsActive] = useState(true);
  const [filePrefix, setFilePrefix] = useState('');
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [processName, setProcessName] = useState('');

  const handleAddRow = () => {
    setRows([...rows, { name: '', required: true }]);
  };

  const handleRemoveRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  // Trata os inputs
  const handleChange = (index, event) => {
    const newRows = [...rows];
    const { name, value } = event.target;

    // Se o campo for 'name', remova espaços
    if (name === 'name') {
      newRows[index][name] = value.replace(/\s+/g, ''); // Remove todos os espaços
    } else if (name === 'required') {
      // Se o campo for 'required', converta para booleano
      newRows[index][name] = value === 'true';
    } else {
      newRows[index][name] = value; // Para outros inputs
    }

    setRows(newRows);
  };

  const handleSelectPartnerId = (event, newValue) => {
    if (newValue) {
      setSelectedPartnerId(newValue.partnerId);
    } else {
      setSelectedPartnerId(null);
    }
  };

  const createPartnerFileType = async () => {
    setLoading(true);
    try {
      if (filePrefix === '') return toast.warning('O prefixo do tipo deve ser informado.');

      if (!selectedPartnerId) return toast.warning('É necessário selecionar um parceiro.');

      if (processName === '') return toast.warning('O nome do processo deve ser informado.');

      // Verifica se todos os nomes das colunas estão informados
      const emptyNames = rows.some((row) => row.name === '');
      if (emptyNames) {
        toast.warning('Todos os nomes das colunas devem ser informados.');
        return;
      }

      const payload = {
        active: isActive,
        partnerId: selectedPartnerId,
        filePrefix,
        processName,
        columns: rows.reduce((acc, row) => {
          if (row.name) {
            acc[row.name] = {
              name: row.name,
              required: row.required,
            };
          }
          return acc;
        }, {}),
      };

      await createPartnerFilesType(payload);
      toast.success('Tipo de arquivo criado com sucesso.');
      setCallPage((prev) => !prev);
      close();
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={close}
    >
      <BoxModal style={{ textAlign: 'center', padding: '1.5rem 1.5rem', minHeight: '14rem' }}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
            <button
              onClick={close}
              style={{
                background: 'transparent',
                border: 0,
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
            >
              X
            </button>
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              Criar tipo de arquivo
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label="Prefixo do tipo"
              variant="outlined"
              fullWidth
              onChange={(event) => setFilePrefix(event.target.value)}
              required
            />
            <Autocomplete
              options={partnersList.filter((partner) =>
                partner.name.toLowerCase().includes(inputValue.toLowerCase())
              )}
              getOptionLabel={(option) => option.name}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onChange={handleSelectPartnerId}
              renderInput={(params) => (
                <TextField {...params} label="Parceiro" variant="outlined" fullWidth required />
              )}
              renderOption={(props, option) => <li {...props}>{option.name}</li>}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label="Nome do processo"
              variant="outlined"
              fullWidth
              onChange={(event) => setProcessName(event.target.value)}
              required
            />
          </Grid>

          {rows.map((row, index) => (
            <Grid item md={12} xs={12} key={index} sx={{ display: 'flex', gap: '1rem' }}>
              <TextField
                label="Nome da coluna"
                variant="outlined"
                name="name"
                value={row.name}
                onChange={(event) => handleChange(index, event)}
                sx={{ width: '50%' }}
                required
              />
              <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                <FormControl variant="outlined" sx={{ flex: 8, marginRight: '0.5rem' }}>
                  <InputLabel>Obrigatório?</InputLabel>
                  <Select
                    name="required"
                    label="Obrigatório?"
                    value={row.required ? 'true' : 'false'}
                    onChange={(event) => handleChange(index, event)}
                    sx={{ fontWeight: 700 }}
                  >
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddRow}
                  sx={{ flex: 1, fontSize: '1.5rem', fontWeight: 700, marginRight: '0.5rem' }}
                >
                  +
                </Button>
                {index === 0 ? null : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRemoveRow(index)}
                    sx={{ flex: 1, fontSize: '1.5rem', fontWeight: 700 }}
                  >
                    -
                  </Button>
                )}
              </Box>
            </Grid>
          ))}
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.6rem',
                fontSize: '0.9rem',
              }}
            >
              <strong>Inativo</strong>
              <Switch
                onColor="#06bad0"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              <strong>Ativo</strong>
            </span>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto',
            }}
          >
            <Button
              fullWidth
              onClick={createPartnerFileType}
              sx={{ fontWeight: 700, height: '50px' }}
              variant="contained"
            >
              {loading ? <CircularProgress size={25} thickness={6} /> : 'Criar tipo'}
            </Button>
          </Grid>
        </Grid>
      </BoxModal>
    </Modal>
  );
};
