import React, { useState } from "react";
import * as D from "js/components/DesignSystem/styles";
import { Container, Grid  } from "./styles";
import { Snackbar } from "@mui/material";
import { StyledButton } from "js/components/Configurations/AdminFunctions/styledButton";

import { RegisterUserModal } from './RegisterUserModal';
import { GoBackHeader } from "js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader";
import { HeaderTitle } from "js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle";

export default function RelationshipScreen({ history, ...props }) {

  const [openAlert, setOpenAlert] = useState(false);
  const [snackMessage] = useState("Usuário não existe.");
  const [isModalRegisterUserOpen, setIsModalRegisterUserOpen] = useState(false);

  const handleOpenModalRegisterUser = () => {
    setIsModalRegisterUserOpen(true);
  };

  const handleCloseModalRegisterUser = () => {
    setIsModalRegisterUserOpen(false);
  };

  const pathnameRegisterListUser = `/estabelecimento/${props.match.params.id}/relacionamento/cadastra-lista-usuarios`;

  return (
    <div style={{padding: '4rem 32px'}}>
    <GoBackHeader />
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
      />
      <HeaderTitle pageInfo="Relacionamento" />
      <Grid>
        <D.CardButton
          onClick={handleOpenModalRegisterUser}
          newColor={"#6E3296"}>
          Cadastrar um novo cliente
        </D.CardButton>
        <StyledButton
          url={pathnameRegisterListUser}
          value={'Anexar lista de clientes'}
          index={1}
        />
        <StyledButton
          url={`/estabelecimento/${props.match.params.id}/relacionamento/lista-usuarios`}
          value={'Ver meus clientes'}
          index={1}
        />
      </Grid>
        {/* MODAL COM O FORMULÁRIO DE CADASTRO DE NOVO CLIENTE */}
      <RegisterUserModal
        isModalRegisterUserOpen={isModalRegisterUserOpen}
        handleCloseModalRegisterUser={handleCloseModalRegisterUser}
      />
    </Container>
    </div>
  );
}
