import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router';

import { TextField, Button, FormLabel, FormControl, Snackbar } from '@mui/material';
import { AddPhotoAlternate, Save } from '@mui/icons-material';

import { financialCredit_v1 } from 'js/library/utils/API/apiFinancialCredit';
import { toast } from 'react-toastify';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const styles = () => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

export function FinancialCredits(props) {
  const [value, setValue] = useState(0);
  const [eventType, setEventType] = useState(null);
  const [eventDescription, setEventDescription] = useState(null);
  const [clientList, setClientList] = useState(null);

  const [openCheck, setOpenCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();

  const fileChangedHandler = (name) => (event) => {
    if (event.target.files.length !== 0) {
      setClientList(event.target.files[0]);
    }
  };

  function readTxt(txtFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let dataArray = [];

      reader.onload = function () {
        const data = this.result.split('\n');
        for (let index = 0; index < data.length; index++) {
          if (
            data[index] !== '' &&
            data[index] !== '\n' &&
            data[index] !== '\r' &&
            data[index] !== '\r\n'
          ) {
            dataArray.push(data[index].trim().replace(/[.]/gi, ','));
          }
        }

        resolve(dataArray);
      };
      reader.readAsText(txtFile);
    });
  }

  const checkInformation = async (event) => {
    event.preventDefault();

    if (clientList === null) {
      setOpenCheck(true);
    } else {
      setLoading(true);

      readTxt(clientList).then(async (clientList) => {
        try {
          const promises = clientList.map((client, index) => {
            return financialCredit_v1(client, value, eventType, eventDescription);
          });

          await Promise.all(promises);

          toast.success('Pontos creditados com sucesso!');
          setTimeout(() => {
            setLoading(false);
            navigate.push('/admin/configuracoes');
          }, 2000);
        } catch (error) {
          toast.error('Erro ao creditar pontos.');
          setLoading(false);
        }
      });
    }
  };

  const { classes } = props;

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Creditar Pontos" />
      <form onSubmit={checkInformation}>
        <FormControl className="input-field">
          <TextField
            style={{ width: '300px' }}
            className="input-field"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            type="number"
            id="value"
            required
            label="Quantidade de Pontos"
          />

          <TextField
            required
            className="input-field"
            type="text"
            value={eventType === null ? '' : eventType}
            id="eventType"
            label="Tipo do Evento "
            onChange={(e) => setEventType(e.target.value)}
          />

          <TextField
            required
            className="input-field"
            multiline
            type="text"
            value={eventDescription === null ? '' : eventDescription}
            id="eventDescription"
            label="Descrição do Evento"
            onChange={(e) => setEventDescription(e.target.value)}
          />

          <FormLabel style={{ marginTop: '20px' }} component="legend">
            Arquivo para o envio da notificação
          </FormLabel>

          <input
            id="thubnailStore"
            accept=".txt"
            style={{ display: 'none' }}
            type="file"
            onChange={fileChangedHandler('clientList')}
          />

          <label htmlFor="thubnailStore">
            <Button
              style={{
                textTransform: 'capitalize',
                marginTop: '20px',
                fontWeight: '550',
                color: '#6c6c6c',
              }}
              variant="contained"
              component="span"
              classes={{ root: classes.root, disabled: classes.disabled }}
              startIcon={<AddPhotoAlternate color="secondary" />}
            >
              Adicionar Arquivo
            </Button>{' '}
            {clientList === null ? '' : clientList.name}
          </label>
        </FormControl>

        <Button
          style={{
            marginTop: '20px',
            color: 'white',
            textTransform: 'capitalize',
            fontWeight: '550',
          }}
          variant="contained"
          color="primary"
          disabled={loading}
          type="submit"
          startIcon={<Save />}
        >
          Salvar
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openCheck}
        onClose={() => setOpenCheck(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            Insira o arquivo com os e-mails dos usuários que serão creditados.
          </span>
        }
      />
    </div>
  );
}

export default withStyles(styles)(FinancialCredits);
