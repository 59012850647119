import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  gap: 3rem;
  padding: 1rem;

  > h3 {
    max-width: 900px;
    text-align: center;
  }
`;
