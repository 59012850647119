import React, { useEffect, useState } from 'react';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  List,
  ListItemText,
  Pagination,
} from '@mui/material';

// ICONES
import { MdArrowBackIosNew } from 'react-icons/md';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// COMPONENTS
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

// APIS
import { getLogsByGroupId } from 'js/library/utils/API/Points/getLogsByGroupId';
import CollapsibleTable from 'js/components/TriiboComponents/Tables/CollapsibleTable';

// traduzindo os dados da mother
function translateMotherData(motherData) {
  const translationMap = {
    id: 'Identificação',
    debitId: 'ID de débito',
    status: 'Status',
    creationDate: 'Data de criação',
    updateDate: 'Data de atualização',
    isUnitary: 'É unitário',
    filePath: 'Caminho do arquivo',
    value: 'Pontos',
    transactionTotalPoints: 'Pontos totais da transação',
    type: 'Tipo',
    establishmentId: 'ID do estabelecimento',
    clubId: 'ID do clube',
    description: 'Descrição',
  };

  const translatedData = {};
  Object.keys(motherData).forEach((key) => {
    const value = motherData[key];
    if (value != null && key !== 'points' && key !== 'resumedDescription') {
      translatedData[translationMap[key] || key] = value;
    }
  });
  return translatedData;
}

// traduzindo os dados do filho
function translateChildData(childDataArray) {
  const translationMap = {
    id: 'Identificação',
    movementGroupId: 'ID do grupo de movimento',
    processDate: 'Data do processo',
    eventName: 'Nome do evento',
    value: 'Pontos',
    typeOfReceiver: 'Tipo do destinatário',
    receiverAccount: 'Conta do destinatário',
    receiverUid: 'UID do destinatário',
    receiverEstablishmentId: 'ID do estabelecimento do destinatário',
    receiverClubeId: 'ID do clube do destinatário',
    typeOfSender: 'Tipo do remetente',
    senderUserAccount: 'Conta do usuário remetente',
    senderUid: 'UID do remetente',
    senderEstablishmentId: 'ID do estabelecimento do remetente',
    senderClubeId: 'ID do clube do remetente',
  };

  return childDataArray.map((childData) => {
    const translatedData = {};
    Object.keys(childData).forEach((key) => {
      const value = childData[key];
      if (value != null) {
        translatedData[translationMap[key] || key] = value;
      }
    });
    return translatedData;
  });
}

export default function PointsDetails(props) {
  const motherData = props.location.state;
  const motherId = motherData.id;

  const [childData, setChildData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const translatedData = translateMotherData(motherData);
  const translatedChildData = translateChildData(childData);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(childData.length / PER_PAGE);
  const _DATA = usePagination(childData, PER_PAGE);

  const handleChangePage = (e, p) => {
    _DATA.jump(p);
    setPage(p);
  };

  const columns = [
    { heading: 'Data de processamento', value: 'processDate' },
    { heading: 'Nome do evento', value: 'eventName' },
    { heading: 'Recebedor', value: 'typeOfReceiver' },
    { heading: 'Remetente', value: 'typeOfSender' },
  ];

  const tranformData = (data) => {
    const filteredData = data.filter((item) => {
      return item;
    });

    return filteredData.map((item) => {
      return {
        ...item,
        processDate: new Date(item.processDate).toLocaleDateString('pt-BR'),
        typeOfReceiver:
          item.typeOfReceiver === 'user'
            ? 'Usuário'
            : item.typeOfReceiver === 'establishment'
            ? 'Estabelecimento'
            : 'Seu clube',
        typeOfSender:
          item.typeOfSender === 'establishment'
            ? 'Estabelecimento'
            : item.typeOfSender === 'club'
            ? 'Seu clube'
            : 'Usuário',
      };
    });
  };

  // trás os primeiros 20 registros
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLogsByGroupId(motherId, 0, 20);

        setChildData(tranformData(response));
      } catch (error) {
        console.error('Ocorreu um erro na requisição:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // paginação: trás mais 20 quando o usuário clica na ultima página da tabela
  useEffect(() => {
    if (count === page && childData.length >= 20) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await getLogsByGroupId(motherId, childData.length, 20);

          setChildData((prev) => [...prev, ...tranformData(response)]);
          setLoading(false);
        } catch (error) {
          console.error('Ocorreu um erro na requisição:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [count === page]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <S.Container style={{ paddingBottom: '200px' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
          marginBottom: '40px',
        }}
      >
        Detalhes do Evento
      </D.TriiboH1>

      <Box sx={{ width: '100%' }}>
        <Accordion elevation={1} expanded={expanded} onChange={handleAccordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontWeight: 'bold' }}
          >
            Informações do evento
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {Object.entries(translatedData).map(([key, value]) => (
                <ListItemText
                  key={key}
                  primary={
                    <>
                      <strong>{key}:</strong> {value}
                    </>
                  }
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>

      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          {childData.length > 0 ? (
            <>
              <CollapsibleTable
                columns={columns}
                data={_DATA.currentData()}
                translatedChildData={translatedChildData}
                perPage={10}
                isPagination
              />
              <Pagination
                sx={{ margin: '0 auto' }}
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                color="secondary"
                onChange={handleChangePage}
              />
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '40px 0px' }}>
              <strong>Nenhum resultado encontrado.</strong>
            </Box>
          )}
        </>
      )}
    </S.Container>
  );
}
