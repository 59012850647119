import React, { useState, useEffect } from 'react';

//Styles
import * as S from './AccessPermissionStyle';

//Components
import { StyledButton } from '../../../Configurations/AdminFunctions/styledButton/index';

//Libs
import Switch from 'react-switch';

//Apis
import { getSeuClube, updateSeuClube } from 'js/library/utils/API/seuClube';
import { ModalSingleRegistration } from './ModalSingleRegistration';
import { GoBackHeader } from '../RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../RelationshipClub/HeaderTitle';

export function AccessPermission() {
  const [accessMethod, setAccessMethod] = useState(false);
  const [onLoadClube, setOnLoadClube] = useState([]);
  const [isModalRegisterUserOpen, setIsModalRegisterUserOpen] = useState(false);
  const clubeId = document.location.pathname.split('/')[4];

  const cardsList = [
    {
      value: 'Editar lista de permissões',
      url: `/admin/seu-clube/permissoes-acesso/editar-whitelist/${clubeId}`,
    },
    {
      value: 'Cadastro em lote de permissões',
      url: `/admin/seu-clube/permissoes-acesso/cadastrar-lote-whitelist/${clubeId}`,
    },
    {
      value: 'Cadastro individual de permissão',
      url: undefined,
    },
  ];

  const cardsListOrdered = cardsList.sort((a, b) => {
    let x = a.value.toUpperCase(),
      y = b.value.toUpperCase();

    return x === y ? 0 : x > y ? 1 : -1;
  });

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId).then((result) => {
        localStorage.setItem('partnerIdClube', result.partnerId);
        setOnLoadClube(result);
        if (result.accessMethod === 'privateList') {
          setAccessMethod(true);
        } else {
          setAccessMethod(false);
        }
      });
    }
  }, [clubeId]);

  const handleOpenModalRegisterUser = () => {
    setIsModalRegisterUserOpen(true);
  };

  const handleCloseModalRegisterUser = () => {
    setIsModalRegisterUserOpen(false);
  };

  const handleAccessMethod = () => {
    const payload = {
      clubeId,
      accessMethod: !accessMethod === true ? 'privateList' : 'public',
    };
    updateSeuClube(payload);
    setAccessMethod(!accessMethod);
  };

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <S.Container style={{ marginTop: '-2rem' }}>
        <HeaderTitle pageInfo="Permissões de acesso" />
        <S.TextDescription>
          Escolha a privacidade do seu clube: <b>público ou privado.</b>
          <br />
          <br />
          Clubes <b>públicos</b> permitem que qualquer usuário se cadastre, enquanto clubes{' '}
          <b>privados</b> requerem que o usuário faça parte de uma lista de permissão previamente
          definida. Torne o clube privado para habilitar o gerenciamento da lista de permissão.
        </S.TextDescription>
        {onLoadClube.length === 0 ? (
          'Carregando...'
        ) : (
          <S.ContainerToogle direction="row" spacing={1} alignItems="center">
            <S.LabelToogle>Público</S.LabelToogle>
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#6e3296"
              checked={accessMethod}
              onChange={handleAccessMethod}
            />
            <S.LabelToogle>Privado</S.LabelToogle>
          </S.ContainerToogle>
        )}
        <S.Grid>
          {accessMethod
            ? cardsListOrdered.map((card, index) => (
                <StyledButton
                  value={card.value}
                  url={card.url === undefined ? '#' : card.url}
                  index={index}
                  key={index}
                  onClick={
                    card.value === 'Cadastro individual de permissão'
                      ? handleOpenModalRegisterUser
                      : undefined
                  }
                />
              ))
            : null}
        </S.Grid>

        <ModalSingleRegistration
          isModalRegisterUserOpen={isModalRegisterUserOpen}
          handleCloseModalRegisterUser={handleCloseModalRegisterUser}
          propsClube={onLoadClube}
        />
      </S.Container>
    </div>
  );
}

