import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Add, List } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import DataTableCards from 'js/containers/DataTable/DataTableCards';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const useStyles = makeStyles((theme) => ({
  buttonAddCard: {
    float: 'right',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
}));

const ListCards = () => {
  const classes = useStyles();

  const state = {
    id: '',
    fotoThumb: '',
    nome: '',
    endereco: '',
    data: [],
    rows: [
      {
        id: 'thumbnail',
        disablePadding: false,
        label: 'Thumbnail',
      },
      { id: 'title', disablePadding: false, label: 'Título' },
      {
        id: 'subtitle',
        disablePadding: false,
        label: 'Subtítulo',
      },
      {
        id: 'keyWordsSet',
        disablePadding: false,
        label: 'Keywords',
      },
      {
        id: 'org',
        disablePadding: false,
        label: 'Organizações',
      },
      {
        id: 'priority',
        disablePadding: false,
        label: 'Prioridade',
      },
      {
        id: 'duplicate',
        disablePadding: false,
        label: 'Duplicar',
      },
    ],
    pathName: '/admin/configuracoes/cards/',
    tableColumns: ['fotoThumb'],
  };

  return (
    <div style={{ maxHeight: '100%', padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cards" />
      <br />
      <Grid container spacing={5}>
        <Grid item md={6} xs={12} />

        <Grid item md={6} xs={12}>
          <div className={classes.buttonAddCard}>
            <Link
              to={{
                pathname: '/admin/configuracoes/organizarCards/',
                state: '',
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                title="Organizar Cards"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<List />}
              >
                Organizar Cards
              </Button>
            </Link>

            <Link
              to={{
                pathname: '/admin/configuracoes/cadastrarCards/',
                state: '',
              }}
              style={{ textDecoration: 'none', marginLeft: '10px' }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                title="Editar Card"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Add />}
              >
                Adicionar Card
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <DataTableCards rows={state.rows} tableNode={'Cards'} pathName={state.pathName} />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ListCards;

