import React, { useEffect, useState } from 'react';
import { store } from 'js/core/configureStore';
//Styles
import {
  ContainerSearch,
  ContentAddClube,
  InputSearch,
  LabelSearch,
  LoadingTableClube,
  MainContainer,
  RedirectAddClube,
} from './clubeTableStyles';
import { Grid, Button, CircularProgress } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import * as D from 'js/components/DesignSystem/Table/styles';
import * as W from '../DesignSystem/styles';
import { EditButton } from '../TriiboComponents/Tables/EditButtomTable/styles';
import cogIcon from 'styles/assets/communications/cog.svg';
import Switch from 'react-switch';

//Components
import CustomButtonsTable from '../TriiboComponents/Tables/CustomButtonsTable';

//Apis
import { getSeuClubList, updateSeuClube } from 'js/library/utils/API/seuClube';
import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from '../Configurations/CreateClub/RelationshipClub/GoBackHeader';

export function ClubTable() {
  const userInfo = store.getState().codeInputModel.userInfo;
  const pathList = document.location.pathname;

  const [listClubs, setListClubs] = useState([]);
  const [inputSearchClub, setInputSearchClub] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    async function getListClubs() {
      try {
        const list = await getSeuClubList(userInfo.uId);

        list.map((item) => {
          item.urlRedirect = `/admin/seu-clube/${item.clubeId}`;
          item.urlRelations = `/admin/seu-clube/relacionamento/${item.clubeId}`;
          item.urlCards = `/admin/seu-clube/gerenciar-cards/${item.clubeId}`;
          return item;
        });
        setListClubs(list);
        setLoadingPage(false);
      } catch (error) {
        setLoadingPage(false);
      }
    }
    getListClubs();
  }, [userInfo]);

  const handleChangeInputSearchCell = (event) => {
    setInputSearchClub(event.target.value);
  };

  const handleChangeToggleStatus = (clubeId, status) => {
    let payload = {
      clubeId: clubeId,
      status: !status,
    };

    if (payload.clubeId !== '' && payload.status !== '') {
      updateSeuClube(payload).then((result) => {
        if (result.data.success) {
          getSeuClubList(userInfo.uId).then((list) => {
            list.map((item) => {
              item.urlRedirect = `/admin/seu-clube/${item.clubeId}`;
              return item;
            });
            setListClubs(list);
          });
        }
      });
    }
  };

  const filteredClubs = inputSearchClub
    ? listClubs.filter((club) => {
        return club.clubeId.toLowerCase().includes(inputSearchClub.toLowerCase());
      })
    : listClubs;

  const column = [{ heading: 'Subdomínio', value: 'clubeId' }];

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <D.TableTH>Ir para o site</D.TableTH>
          <D.TableTH>Configurações</D.TableTH>
          <D.TableTH>Status</D.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <D.TableTD>
              <a
                href={'https://' + childrens.clubeId + childrens.url}
                target="new"
                rel="noreferrer"
              >
                <W.TriiboFilledButton blue={true}>Ir</W.TriiboFilledButton>
              </a>
            </D.TableTD>
            <D.TableTD>
              <EditButton to={'/admin/seu-clube/configuracoes/' + childrens.clubeId}>
                <img width="18px" src={cogIcon} alt="" />
              </EditButton>
            </D.TableTD>
            <D.TableTD>
              <Switch
                onColor="#6e3296"
                checked={childrens.status}
                onChange={() => handleChangeToggleStatus(childrens.clubeId, childrens.status)}
              />
            </D.TableTD>
          </>
        ),
    };
  };

  if (loadingPage) {
    return (
      <>
        <LoadingTableClube>
          <CircularProgress size="100px" />
        </LoadingTableClube>
      </>
    );
  }

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Clubes" />
      <MainContainer>
        <Grid container>
          <Grid item md={6} />
          <Grid item sm={6} md={6} xs={12}>
            <ContentAddClube>
              {pathList === '/admin/clube/seu-clube' ? null : (
                <RedirectAddClube
                  to={{
                    pathname: '/admin/configuracoes/cadastro-clube',
                    state: ',',
                  }}
                >
                  <Button variant="contained" color="primary" startIcon={<Add />}>
                    <strong>Criar Clube</strong>
                  </Button>
                </RedirectAddClube>
              )}
            </ContentAddClube>
          </Grid>
        </Grid>

        <ContainerSearch>
          <LabelSearch>Pesquise seu clube:</LabelSearch>
          <Search className="searchIcon" />
          <InputSearch minLength={2} debounceTimeout={0} onChange={handleChangeInputSearchCell} />
        </ContainerSearch>
        {filteredClubs.length === 0 ? (
          <div>
            <W.TriiboH3 style={{ textAlign: 'center' }}>Nenhum clube encontrado</W.TriiboH3>
          </div>
        ) : (
          <main>
            <CustomButtonsTable
              data={filteredClubs}
              column={column}
              perPage={10}
              componentReceiver={componentReceiver}
            />
          </main>
        )}
      </MainContainer>
    </div>
  );
}

export default ClubTable;

