import styled from 'styled-components'

import { Box } from '@mui/material'

export const TextDescription = styled.h4`
  font-size: 0.9rem;
  margin-bottom: 3rem;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
  line-height: 1.4rem;
`

export const AddWords = styled(Box)`
  width: 100%;
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  align-items: flex-end;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ListWords = styled(Box)`
  border: 1px solid #c5c5c5;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  max-height: 330px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CDA8E0;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
`
