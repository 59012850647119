import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0.5rem;
  background: #f2f2f2;
  margin-bottom: 2rem;
`;

export const DefaultCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.3rem;
  padding: 1rem;
  background: #f2f2f2;
  box-shadow: 8px 10px 15px #00000029;
  border-radius: 20px;

  .div-1 {
    width: 54px;
    height: 54px;
    background: #dadada;
    border-radius: 17px;
  }

  .div-2 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;

    > div {
      background: #dadada;
      width: 100%;
      height: 33px;
      border-radius: 8px;
    }
  }
`;

export const NotificationCard = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 0.3rem;
  padding: 1rem;
  /* background: #f2f2f2; */
  box-shadow: 8px 10px 15px #00000029;
  border-radius: 20px;
  transform: scale(1.2);

  .div-1 {
    width: 54px;
    height: 54px;
    background: #dadada;
    border-radius: 17px;
  }

  .div-2 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;

    > div {
      background: #dadada;
      width: 100%;
      height: 33px;
      border-radius: 8px;
    }
  }
`;

export const NotificationCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  width: 100%;

  h5 {
    white-space: nowrap;
    max-width: 300px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h6 {
    color: #bbbbbb;
  }
`;

export const NotificationCardContent = styled.div`
  padding-left: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .boxMessage {
    display: flex;
    flex-direction: column;
    max-height: 50px;
    height: 100%;
    max-width: 300px;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    h4 {
      color: #757575;
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;
