import React, { useState, useRef } from 'react';

import * as S from './style';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import logoTriibo from 'styles/assets/Logo_Triibo_svg.svg';

import { useEffect } from 'react';

import currentEstActionsAction from 'js/core/actions/currentEstActions';

import setEst from 'js/library/services/currentEstSetter';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getEstablishmentChangesRequest } from 'js/library/utils/API/Curation/getEstablishmentChangesRequest';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { TextField } from '@mui/material';
import cfac22 from 'js/library/utils/cfac22';

const Header = (props) => {
  const { state } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const establishmentArr = state.establishmentList;

  let currentEst = useSelector((state) => state.currentEstModel.currentEst);
  const anchorRef = useRef(null);

  const [currentEstablishment, setCurrentEstablishment] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentThumb, setCurrentThumb] = useState('');

  const handleClick = (data) => {
    setStablishmentAndThumb(data.id);
  };

  const handleToggle = (e) => {
    if (state.establishmentList === null || state.establishmentList === undefined) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  const location = useLocation();
  const setStablishmentAndThumb = (currentId) => {
    let id = currentId;
    if (!id) return;
    const promises = [
      localStorage.getItem('isChangedEstablishment') && getEstablishmentChangesRequest(id),
      getEstablishmentId(id),
    ];

    Promise.allSettled(promises)
      .then((res) => {
        if (res[0]?.value?.response?.establishmentEdits) {
          localStorage.removeItem('isChangedEstablishment');
          setCurrentEstablishment(res[0].value.response?.establishmentEdits.changedFields.nome);
          setCurrentThumb(res[0].value.response?.establishmentEdits.changedFields.fotoThumb);
        } else if (res[1].value) {
          setCurrentEstablishment(res[1].value.nome);
          setCurrentThumb(res[1].value.fotoThumb);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    let id;
    if (location.pathname === '/admin') {
      setCurrentEstablishment(currentEst.nome);
      setCurrentThumb(currentEst.fotoThumb);
      id = currentEst?.id || state.establishmentList[0]?.id;
    } else {
      id = params.id;
    }
    setStablishmentAndThumb(id);
  }, [location]);

  return (
    <S.Header>
      <S.HeaderContents>
        <S.HeaderLink to="/admin">
          <S.ImgContainer src={logoTriibo} />
        </S.HeaderLink>
        <div>
          {!establishmentArr?.length ? (
            ''
          ) : (
            <S.InterfaceContainer onClick={handleToggle}>
              <S.AvatarContainer
                currentThumb={
                  currentThumb
                    ? cfac22('.STORAGE_URL') + 'estabelecimento%2F' + currentThumb + '?alt=media'
                    : ''
                }
              />
              <S.EstName open={open} onClick={() => setOpen((prevOpen) => !prevOpen)}>
                {currentEstablishment}
              </S.EstName>
              <S.ArrowIcon open={open} onClick={() => setOpen((prevOpen) => !prevOpen)} />

              <ClickAwayListener onClickAway={handleClose}>
                <S.EstablishmentPopper open={open}>
                  <TextField
                    label="Pesquisar..."
                    variant="outlined"
                    sx={{ marginTop: '4px' }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  {Object.keys(establishmentArr)
                    .sort((a, b) =>
                      establishmentArr[a].nome.localeCompare(establishmentArr[b].nome)
                    )
                    .filter((index) =>
                      establishmentArr[index].nome.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((index, i) => (
                      <S.HeaderLink
                        key={establishmentArr[index].id + i}
                        to={{
                          pathname: '/estabelecimento/' + establishmentArr[index].id,
                          state: establishmentArr[index],
                        }}
                        tile="establishmentArr[index].nome"
                      >
                        <S.InterfaceButton
                          onClick={() => {
                            handleClick(establishmentArr[index]);
                            setEst(establishmentArr[index]).then((result) => {
                              dispatch(currentEstActionsAction(result));
                            });
                            setOpen(false);
                          }}
                        >
                          <S.IconEstablishment />
                          <S.EstNameButton>{establishmentArr[index].nome}</S.EstNameButton>
                        </S.InterfaceButton>
                      </S.HeaderLink>
                    ))}
                  {establishmentArr ? (
                    <S.HeaderLink
                      to={{
                        pathname: '/estabelecimento/novo/adicionar',
                        state: '',
                      }}
                      tile="establishmentArr[index].nome"
                    >
                      <S.InterfaceButton purple={true} style={{ cursor: 'pointer' }}>
                        <S.AddIcon />
                        <S.EstNameButton $purple={true} onClick={() => setOpen(false)}>
                          Criar negócio
                        </S.EstNameButton>
                      </S.InterfaceButton>
                    </S.HeaderLink>
                  ) : (
                    ''
                  )}
                </S.EstablishmentPopper>
              </ClickAwayListener>
            </S.InterfaceContainer>
          )}
        </div>
      </S.HeaderContents>
    </S.Header>
  );
};

export default Header;

