import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

import * as S from './styles';

function extractValues(arr, key) {
  return arr.map((obj) => obj[key]);
}

function filterObjectsByValue(objects, key, values) {
  return objects.filter((obj) => values.includes(obj[key]));
}

const TriiboAutocomplete = (props) => {
  const { arr, setCurrent, objKey, label, preSelected, setDisabledButton } = props;
  const [preValue, setPreValue] = useState([]);

  useEffect(() => {
    if (preSelected) {
      setPreValue(filterObjectsByValue(arr, objKey, preSelected));
    }
  }, [arr]);

  useEffect(() => {
    setCurrent(extractValues(preValue, objKey));
  }, [preValue]);

  return (
    <S.Container>
      <S.TriiboComplete
        multiple
        id="tags-outlined"
        options={arr}
        getOptionLabel={(option) => {
          return option[objKey];
        }}
        value={preValue}
        onChange={(e, newValue) => {
          e.preventDefault();
          setDisabledButton && setDisabledButton(false);
          setPreValue(newValue);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : 'Valor'}
            placeholder={label ? label : 'Valor'}
            key={`${arr.indexOf()}-arr`}
          />
        )}
        sx={{ border: 'black' }}
        key={`${arr.indexOf()}-arr`}
      />
    </S.Container>
  );
};

export default TriiboAutocomplete;

