import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';

import { BsXCircleFill } from 'react-icons/bs';

import { useSelector } from 'react-redux';

const StepConcluded = () => {
  let currentEst = useSelector((state) => state.currentEstModel.currentEst);

  const style = {
    fontFamily: 'Source Sans Pro',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: { xs: '100%', sm: '30rem', md: '30rem' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
  };

  const styleButton = {
    fontFamily: 'Source Sans Pro',
    fontWeight: '700',
    textTransform: 'none',
    borderRadius: '0.8rem',
  };

  const styleButtonPainel = {
    backgroundColor: '#fff',
    border: '1px solid #383838',
    color: '#383838',
    '&:hover': {
      backgroundColor: '#fff',
      opacity: 0.7,
    },
  };

  const styleButtonTeal = {
    backgroundColor: '#08BAD0',
    ' &:hover': {
      backgroundColor: '#0799ab',
    },
  };

  return (
    <div>
      <Modal open={true} BackdropProps={{ transitionDuration: '0' }}>
        <Box sx={style}>
          <a href="/" style={{ textDecoration: 'none' }}>
            <BsXCircleFill
              style={{
                fontSize: '1.4rem',
                position: 'absolute',
                top: '0.8rem',
                right: '0.8rem',
                cursor: 'pointer',
                color: '#383838',
              }}
            />
          </a>
          {/* </Link> */}
          <Grid container direction="column" justifyContent="center" alignItems="center" xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Source Sans Pro',
                fontWeight: '700',
              }}
            >
              Solicitação de Aprovação
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              sx={{
                mt: 1.4,
                fontFamily: 'Source Sans Pro',
                fontWeight: '400',
                maxWidth: '23.5rem',
              }}
            >
              Em até 48h nossa equipe entrará em contato para informar a situação do cadastro.
              Aproveite para cadastrar novas promoções.
            </Typography>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              justifyContent="center"
              xs={12}
              gap={2.4}
              sx={{ mt: 2.4 }}
            >
              <Link
                to={{
                  pathname: `/estabelecimento/${currentEst.id}/promocoes`,
                  state: currentEst,
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" disableElevation sx={[styleButton, styleButtonTeal]}>
                  Próximo Passo
                </Button>
              </Link>
              <Link
                to={{
                  pathname: `/estabelecimento/${currentEst.id}/`,
                  state: currentEst,
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" disableElevation sx={[styleButton, styleButtonPainel]}>
                  Painel Administrativo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default StepConcluded;

