import { removeDatabase } from 'js/library/services/DatabaseManager';
import { uploadImage, downloadImageAsBlob } from 'js/library/services/StorageManager';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { convertToMillis, getUserInfo, isAdminPage, b64toBlob } from 'js/library/utils/helpers';
import dispatcher from 'js/core/dispatcher';
import createStore from 'js/library/utils/API/Store/apiCreateStore';
import { updateStore } from 'js/library/utils/API/Store/apiUpdateStore';
import { updateHasPromo } from 'js/library/utils/API/Store/apiUpdateHasPromo';

export const updateStoreBusinessPartnerAction = async (
  dispatch,
  id,
  isPublished,
  level,
  keywords,
  keywordsSetConsumptionList,
  messageBlockedKw,
  tags,
  detailsTitle,
  detailsDescription,
  title,
  description,
  establishmentId,
  establishmentName,
  lat,
  long,
  dueDate,
  enableDate,
  disableDate,
  url,
  photo,
  thumbnail,
  type,
  region,
  droidWebView,
  iOSWebView,
  newImgThumbnail,
  oldImageNameThumbnail,
  newImgPhoto,
  oldImageNamePhoto,
  buttonLabel,
  buttonDescription,
  showButtonDescription,
  goToPlaceLabel,
  showGoToPlace,
  oldPhotoList,
  photoListFile,
  campanhaTriibo,
  checkoutCode
) => {
  let newPromotion = id ? false : true;

  id = id !== null ? id : firebaseDatabase.ref('Store').push().key;

  //convertendo a data para milisegundos
  enableDate = enableDate !== null ? convertToMillis(enableDate) : null;
  disableDate = disableDate !== null ? convertToMillis(disableDate) : null;

  //checando a prioridade da região
  region = region === 'local' ? null : region;

  let currentDate = Date.now();

  //nomeando corretamente e convertendo as imagens
  if (newImgThumbnail !== null) {
    thumbnail = 'storage:' + id + ':thumbnail:' + currentDate;
    //newImgThumbnail = b64toBlob(newImgThumbnail);

    if (newImgThumbnail.indexOf('https') === 0) {
      newImgThumbnail = await downloadImageAsBlob(newImgThumbnail);
    } else {
      newImgThumbnail = b64toBlob(newImgThumbnail);
    }
  }

  if (newImgPhoto !== null) {
    photo = 'storage:' + id + ':photo:' + currentDate;
    //newImgPhoto = b64toBlob(newImgPhoto);

    if (newImgPhoto.indexOf('https') === 0) {
      newImgPhoto = await downloadImageAsBlob(newImgPhoto);
    } else {
      newImgPhoto = b64toBlob(newImgPhoto);
    }
  }

  let listaFotos = [];
  if (photoListFile !== null) {
    for (const key in photoListFile) {
      listaFotos[key] = 'gal' + key + '_' + id + ':' + currentDate;

      if (photoListFile[key].indexOf('https') === 0) {
        photoListFile[key] = await downloadImageAsBlob(photoListFile[key]);
      } else {
        photoListFile[key] = b64toBlob(photoListFile[key]);
      }
    }
  } else {
    listaFotos = oldPhotoList;
  }

  //tratamento de string
  if (buttonLabel === '') buttonLabel = null;
  if (buttonDescription === '') buttonDescription = null;
  if (goToPlaceLabel === '') goToPlaceLabel = null;

  const photoList = photoListFile === null ? oldPhotoList : listaFotos;

  const dataStore = {
    id,
    isPublished,
    level: parseInt(level, 10),
    keyWordsSet: keywords,
    keyWordsSetConsumption: keywordsSetConsumptionList,
    message: messageBlockedKw,
    tags,
    detailsTitle,
    detailsDescription,
    title,
    description,
    establishmentId,
    establishmentName,
    lat: lat === undefined ? 0 : lat,
    long: long === undefined ? 0 : long,
    dueDate: disableDate,
    enableDate,
    disableDate,
    url,
    photo,
    thumbnail,
    type,
    region: region === null ? '' : region,
    droidWebView,
    iOSWebView,
    photoList: photoList === null ? '' : photoList,
    buttonLabel: buttonLabel === null ? '' : buttonLabel,
    buttonDescription: buttonDescription === null ? '' : buttonDescription,
    showButtonDescription,
    goToPlaceLabel: goToPlaceLabel === null ? '' : goToPlaceLabel,
    showGoToPlace,
    campanhaTriibo,
    checkoutCode,
  };

  const storageFolder = 'promocao';

  const userInfo = getUserInfo();

  const isAdmin = userInfo?.admin === true;

  const pathname = isAdmin
    ? `/admin/estabelecimento/${establishmentId}/ofertas`
    : `/estabelecimento/${establishmentId}/promocoes`;

  const uId = userInfo?.uId;

  return dispatcher(
    dispatch,
    'STORE_BUSINESS_PARTNER',
    newPromotion
      ? createStore(uId, id, dataStore, pathname).then(() => {
          updateHasPromo(establishmentId);
        })
      : updateStore(id, dataStore, pathname).then(() => {
          updateHasPromo(establishmentId);
        }),
    uploadImage(storageFolder, photoListFile, listaFotos),
    uploadImage(storageFolder, newImgThumbnail, thumbnail),

    // deleteImage(storageFolder, oldImageNameThumbnail),
    uploadImage(storageFolder, newImgPhoto, photo),
    // deleteImage(storageFolder, oldImageNamePhoto),
    removeDatabase('Promocao', id)
  );
};
