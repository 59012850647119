import React from 'react';
import styled from 'styled-components';

const TextCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 1rem;
  max-height: 407px;
  min-height: 200px;
  width: 100%;
  height: 100%;
`;

const TextArea = styled.textarea`
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  background-color: #fff;
  font-size: 15px;
  border: none;
  resize: none;
  outline: none;
  flex: 1;

  ::placeholder {
    font-style: italic;
    color: #707070;
  }
`;

const TextCounter = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  color: #707070;
`;

export default function TextAreaComponent({
  value,
  setValue,
  maxLength,
  placeholder,
}) {
  return (
    <TextCountContainer>
      <TextArea
        placeholder={placeholder || 'Escreva aqui'}
        maxLength={maxLength || '1400'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <TextCounter>
        {value.length}/{maxLength || 1400}
      </TextCounter>
    </TextCountContainer>
  );
}
