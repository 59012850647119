import axios from 'axios';

import cfac22 from '../cfac22';

export function geocoding(address) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${cfac22(
          'FIREBASE_APIKEY'
        )}`
      )
      .then((result) => {
        if (result.data.status === 'OK') {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
