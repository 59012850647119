import React, { useEffect, useState } from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DropDown from 'js/components/DesignSystem/DropDown';
import ModalFileTxt from './Modals/ModalFileTxt';
import ModalQuery from './Modals/ModalQuery';
import ModalClients from './Modals/ModalClients';

export default function TargetAudience({
  fileTxt,
  setFileTxt,
  queryText,
  setQueryText,
  clientsList,
  setClientsList,
  selectedTargetAudience,
  setSelectedTargetAudience,
}) {
  const [isSelectedTargetAudienceOpen, setIsSelectedTargetAudienceOpen] =
    useState(false);
  const [targetAudienceIsSelected, setTargetAudienceIsSelected] = useState(
    Array.from(clientsList).length > 0 || fileTxt || queryText ? true : false
  );
  const [isModalFileTxtOpen, setIsModalFileTxtOpen] = useState(false);
  const [isModalQueryOpen, setIsModalQueryOpen] = useState(false);
  const [isModalClientsOpen, setIsModalClientsOpen] = useState(false);

  const isGestorPage = window.location.href.split('/').includes('admin')
    ? false
    : true;

  const [targetAudience] = useState(
    isGestorPage
      ? [
          // "Negócio",
          'Meus clientes',
        ]
      : [
          // "Negócio",
          'Subir arquivo .txt',
          // "Query Redshift",
        ]
  );

  const handleCloseModal = (modal) => {
    if (modal === 'fileTxt') {
      setIsModalFileTxtOpen(false);

      return;
    }

    if (modal === 'query') {
      setIsModalQueryOpen(false);

      return;
    }

    if (modal === 'clients') {
      setIsModalClientsOpen(false);

      return;
    }


  };

  useEffect(() => {
    if (selectedTargetAudience) {
      if (
        selectedTargetAudience === 'Subir arquivo .txt' &&
        !isModalFileTxtOpen
      ) {
        setIsModalFileTxtOpen(true);
        setQueryText('');
        setClientsList([]);
        return;
      }
      if (selectedTargetAudience === 'Query Redshift' && !isModalQueryOpen) {
        setIsModalQueryOpen(true);
        setFileTxt('');
        setClientsList([]);
        return;
      }
      if (selectedTargetAudience === 'Meus clientes' && !isModalClientsOpen) {
        setIsModalClientsOpen(true);
        setFileTxt('');
        setQueryText('');
        return;
      }
      return;
    }
  }, [selectedTargetAudience]);

  return (
    <>
      <S.Container>
        <div>
          <D.TriiboH4>
            <b>Público-alvo</b>
          </D.TriiboH4>
          <D.TriiboH5>
            Selecione o público que receberá esta comunicação.
          </D.TriiboH5>
        </div>
        <S.ContentAudienceContainer>
          <div style={{ flex: '1', width: '100%' }}>
            <DropDown
              className='dropdown'
              height={'48px'}
              width={'100%'}
              top={'3.5rem'}
              selectedOption={selectedTargetAudience}
              setSelectedOption={setSelectedTargetAudience}
              isSelectOpen={isSelectedTargetAudienceOpen}
              setIsSelectOpen={setIsSelectedTargetAudienceOpen}
              data={targetAudience}
            />
          </div>

          <S.ContentAudience visible={targetAudienceIsSelected}>
            {queryText ? (
              <>
                <D.TriiboH5>
                  <strong>Query carregada</strong>
                </D.TriiboH5>
                <S.EditInfoButton onClick={() => setIsModalQueryOpen(true)}>
                  clique para editar
                </S.EditInfoButton>
              </>
            ) : fileTxt ? (
              <>
                <D.TriiboH5>
                  <strong>Arquivo: {fileTxt.name}</strong>
                </D.TriiboH5>

                <S.EditInfoButton onClick={() => setIsModalFileTxtOpen(true)}>
                  clique para editar
                </S.EditInfoButton>
              </>
            ) : (
              Array.from(clientsList).length > 0 && (
                <>
                  <D.TriiboH5>
                    <strong>{clientsList.size} clientes</strong>
                  </D.TriiboH5>

                  <S.EditInfoButton onClick={() => setIsModalClientsOpen(true)}>
                    clique para editar
                  </S.EditInfoButton>
                </>
              )
            )}
          </S.ContentAudience>
        </S.ContentAudienceContainer>
      </S.Container>

      <ModalFileTxt
        fileTxt={fileTxt}
        setFileTxt={setFileTxt}
        isModalFileTxtOpen={isModalFileTxtOpen}
        handleCloseModal={handleCloseModal}
        setTargetAudienceIsSelected={setTargetAudienceIsSelected}
        setSelectedTargetAudience={setSelectedTargetAudience}
      />

      <ModalQuery
        queryText={queryText}
        setQueryText={setQueryText}
        isModalQueryOpen={isModalQueryOpen}
        handleCloseModal={handleCloseModal}
        setTargetAudienceIsSelected={setTargetAudienceIsSelected}
      />

      <ModalClients
        handleCloseModal={handleCloseModal}
        isModalClientsOpen={isModalClientsOpen}
        setClientsList={setClientsList}
        clientsList={clientsList}
        setTargetAudienceIsSelected={setTargetAudienceIsSelected}
        setSelectedTargetAudience={setSelectedTargetAudience}
      />
    </>
  );
}
