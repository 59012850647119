import React from 'react';
import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';

const Button = styled.button`
  background: ${D.triiboPurple};
  width: 3rem;
  height: 3rem;
  border: 0;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PurpleButton = (props) => {
  const { name, icon, index, newLinks, setNewLinks } = props;

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          let newArr = [...newLinks];
          newArr[index].icon = name;
          setNewLinks(newArr);
        }}
      >
        <img src={icon} alt="icone" style={{ width: '2.3rem' }} />
      </Button>
    </>
  );
};

export default PurpleButton;

