import React, { useEffect, useRef, useState } from 'react';

import { downloadImageAsBlob } from 'js/library/services/StorageManager';
import { CreateCampaignMapfre } from 'js/library/utils/API/CampaignMapfre/apiRegisterCampaignMapfre';
import { firebaseStorage } from 'js/library/utils/firebaseUtils';
import { toast } from 'react-toastify';

import ModalTemplate from '../modalTemplate';
import * as D from '../../../DesignSystem/styles';
import * as S from './styles';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { GetAllCampaigns } from 'js/library/utils/API/Campaigns/apiGetAllCampaigns';
import { convertToDateDefault, b64toBlob } from 'js/library/utils/helpers';
import { GetCampaignMapfre } from 'js/library/utils/API/CampaignMapfre/apiGetCampaignMapfre';
import { UpdateCampaignMapfre } from 'js/library/utils/API/CampaignMapfre/apiUpdateCampaign';

import editIcon from 'styles/assets/communications/edit.svg';
import { IoIosClose } from 'react-icons/io';
import { useHistory } from 'react-router';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';

export default function RegisterCampaignMapfre() {
  const [title, setTitle] = useState('');
  const [campaignTag, setCampaignTag] = useState('');
  const [text, setText] = useState('');

  const [allCampaigns, setAllCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState('');
  const [campaignPeriod, setCampaignPeriod] = useState('');
  const [campaignPeriodId, setCampaignPeriodId] = useState('');
  const [campaignIdSelected, setCampaignIdSelected] = useState('');
  const [inputSearchCampaignId, setInputSearchCampaignId] = useState('');
  const [campaignPeriodSelected, setCampaignPeriodSelected] = useState('');

  const [backgroundImage, setBackgroundImage] = useState('');
  const [bannerDesktop, setBannerDesktop] = useState('');
  const [bannerMobile, setBannerMobile] = useState('');
  const [backgroundImageBlob, setBackgroundImageBlob] = useState('');
  const [bannerDesktopBlob, setBannerDesktopBlob] = useState('');
  const [bannerMobileBlob, setBannerMobileBlob] = useState('');
  const [changeBackgroundImage, setChangeBackgroundImage] = useState(false);
  const [changeBannerDesktop, setChangeBannerDesktop] = useState(false);
  const [changeBannerMobile, setChangeBannerMobile] = useState(false);

  const [promotions, setPromotions] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [cashbacks, setCashbacks] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const [loading, setLoading] = useState(false);

  const [isUpdatePath, setIsUpdatePath] = useState(false);
  const [campaignIdUrl, setCampaignIdUrl] = useState('');
  const [campaignIdEditIsOpen, setCampaignIdEditIsOpen] = useState(false);
  const [campaignPeriodEditIsOpen, setCampaignPeriodEditIsOpen] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const history = useHistory();

  const hiddenBackgroundFileInput = useRef(null);
  const hiddenDesktopFileInput = useRef(null);
  const hiddenMobileFileInput = useRef(null);

  useEffect(() => {
    setLoadingPage(true);
    const url = window.location.href;
    const updatePath = url.split('/').includes('atualizarCampanhaMapfre');

    if (updatePath) {
      setIsUpdatePath(true);
      const campaignId = url.split('/')[7].split('=')[1];
      setCampaignIdUrl(campaignId);
      GetCampaignMapfre(campaignId)
        .then((response) => {
          setTitle(response.title);
          setText(response.text);
          setCampaignId(response.campaignsId);
          setCampaignPeriodId(response.periodsId);
          setCampaignPeriod(response.periodsLp);
          setBackgroundImage(response.background);
          setBannerDesktop(response.banner_desktop_top);
          setBannerMobile(response.banner_mobile_top);
          setCampaignTag(response.campaignTag);
          setCashbacks(response?.cashback);
          setEstablishments(response?.establishments);
          setPromotions(response?.promotions);

          setTimeout(() => {
            setLoadingPage(false);
          }, 3000);
        })
        .catch((error) => {
          setLoadingPage(false);
          console.log(error);
        });
    } else {
      setLoadingPage(false);
    }
  }, []);

  useEffect(() => {
    GetAllCampaigns().then((response) => {
      // console.log(response);
      const arr = Object.entries(response.result).map((item) => {
        const [key, value] = item;
        return { key, value };
      });
      setAllCampaigns(arr);
    });
  }, []);

  const handleClickInputImage = (type) => {
    if (type === 'Background') {
      // setBackgroundImage("");
      hiddenBackgroundFileInput.current.click();
    }

    if (type === 'Desktop') {
      setBannerDesktop('');
      hiddenDesktopFileInput.current.click();
    }

    if (type === 'Mobile') {
      setBannerMobile('');
      hiddenMobileFileInput.current.click();
    }
  };

  useEffect(() => {
    if (
      backgroundImage !== '' &&
      backgroundImage !== null &&
      backgroundImage !== undefined &&
      changeBackgroundImage
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(backgroundImage);
      reader.onloadend = async () => {
        const background = reader.result;
        if (background.indexOf('https') === 0) {
          const image = await downloadImageAsBlob(background);
          setBackgroundImageBlob(image);
        } else {
          setBackgroundImageBlob(b64toBlob(background));
        }
      };
    }
  }, [backgroundImage]);

  useEffect(() => {
    if (
      bannerDesktop !== '' &&
      bannerDesktop !== null &&
      bannerDesktop !== undefined &&
      changeBannerDesktop
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(bannerDesktop);
      reader.onloadend = async () => {
        const background = reader.result;
        if (background.indexOf('https') === 0) {
          const image = await downloadImageAsBlob(background);
          setBannerDesktopBlob(image);
        } else {
          setBannerDesktopBlob(b64toBlob(background));
        }
      };
    }
  }, [bannerDesktop]);

  useEffect(() => {
    if (
      bannerMobile !== '' &&
      bannerMobile !== null &&
      bannerMobile !== undefined &&
      changeBannerMobile
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(bannerMobile);
      reader.onloadend = async () => {
        const background = reader.result;
        if (background.indexOf('https') === 0) {
          const image = await downloadImageAsBlob(background);
          setBannerMobileBlob(image);
        } else {
          setBannerMobileBlob(b64toBlob(background));
        }
      };
    }
  }, [bannerMobile]);

  const uploadingImagesCampaign = async (image, prefixCampaign, type) => {
    let imagePath = '';

    const newStorageRef = firebaseStorage
      .ref('campaignLp')
      .child(prefixCampaign + '_' + type + '_' + Date.now());

    await newStorageRef.put(image).then((result) => {
      imagePath = result._delegate.ref._location.path_;
    });

    return imagePath;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      isUpdatePath
        ? campaignIdEditIsOpen && campaignPeriodEditIsOpen
          ? campaignIdSelected && campaignPeriodSelected
          : campaignId && campaignPeriodId
        : bannerDesktop !== '' &&
          bannerDesktop !== null &&
          bannerDesktop !== undefined &&
          bannerMobile !== '' &&
          bannerMobile !== null &&
          bannerMobile !== undefined &&
          text &&
          title &&
          campaignTag
    ) {
      if (changeBannerDesktop) {
        if (
          bannerDesktopBlob === '' ||
          bannerDesktopBlob === null ||
          bannerDesktopBlob === undefined
        ) {
          toast.error('Preencha todos os campos');
          setLoading(false);
          return;
        }
      }

      if (changeBannerMobile) {
        if (
          bannerMobileBlob === '' ||
          bannerMobileBlob === null ||
          bannerMobileBlob === undefined
        ) {
          toast.error('Preencha todos os campos');
          setLoading(false);
          return;
        }
      }

      const obj = {
        banner_desktop_top: isUpdatePath
          ? !changeBannerDesktop
            ? bannerDesktop
            : await uploadingImagesCampaign(bannerDesktopBlob, campaignTag, 'bannerDesktop')
          : await uploadingImagesCampaign(bannerDesktopBlob, campaignTag, 'bannerDesktop'),
        banner_mobile_top: isUpdatePath
          ? !changeBannerMobile
            ? bannerMobile
            : await uploadingImagesCampaign(bannerMobileBlob, campaignTag, 'bannerMobile')
          : await uploadingImagesCampaign(bannerMobileBlob, campaignTag, 'bannerMobile'),

        campaignsId:
          isUpdatePath && campaignIdEditIsOpen
            ? campaignIdSelected.key
            : isUpdatePath && !campaignIdEditIsOpen
            ? campaignId
            : campaignIdSelected.key,
        periodsId: !isUpdatePath
          ? campaignPeriodSelected
          : isUpdatePath && campaignPeriodEditIsOpen && campaignIdEditIsOpen
          ? campaignPeriodSelected
          : isUpdatePath && campaignPeriodEditIsOpen && !campaignIdEditIsOpen
          ? campaignPeriodId
          : isUpdatePath && !campaignPeriodEditIsOpen
          ? campaignPeriodId
          : campaignPeriodSelected,
        text: text,
        title: title,
        campaignTag: campaignTag,
        promotions: promotions,
        establishments: establishments,
        cashback: cashbacks,
      };

      if (backgroundImage) {
        obj.background = isUpdatePath
          ? !changeBackgroundImage
            ? backgroundImage
            : await uploadingImagesCampaign(backgroundImageBlob, campaignTag, 'background')
          : await uploadingImagesCampaign(backgroundImageBlob, campaignTag, 'background');
      }

      if (isUpdatePath) {
        UpdateCampaignMapfre(obj, campaignIdUrl)
          .then((response) => {
            setBackgroundImage('');
            setBannerDesktop('');
            setBannerMobile('');
            setCampaignIdSelected('');
            setCampaignPeriodSelected('');
            setText('');
            setTitle('');
            setCampaignTag('');
            setPromotions([]);
            setEstablishments([]);
            setCashbacks([]);
            setBackgroundImageBlob('');
            setBannerDesktopBlob('');
            setBannerMobileBlob('');
            setCampaignId('');
            setCampaignPeriod('');
            setLoading(false);
            toast.success('Campanha atualizada com sucesso!');
            setTimeout(() => {
              history.push('/admin/configuracoes/campanhaMapfre/listarCampanhas');
            }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            toast.error('Erro ao atualizar campanha');
          });
      }

      if (!isUpdatePath) {
        CreateCampaignMapfre(obj)
          .then((response) => {
            setBackgroundImage('');
            setBannerDesktop('');
            setBannerMobile('');
            setCampaignIdSelected('');
            setCampaignPeriodSelected('');
            setText('');
            setTitle('');
            setCampaignTag('');
            setPromotions([]);
            setEstablishments([]);
            setCashbacks([]);
            setBackgroundImageBlob('');
            setBannerDesktopBlob('');
            setBannerMobileBlob('');
            setLoading(false);

            toast.success('Campanha cadastrada com sucesso!');
          })
          .catch((error) => {
            setLoading(false);
            toast.error('Erro ao cadastrar campanha');
          });
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      toast.error('Preencha todos os campos');
    }
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeDataToModal = (type) => {
    setModalData(type);
    handleOpenModal();
  };

  const FilteredCampaignsIds = inputSearchCampaignId
    ? allCampaigns.filter((campaign) => {
        const { key } = campaign;
        return key.toLowerCase().includes(inputSearchCampaignId.toLowerCase());
      })
    : [];

  const FilteredCampaignPeriod = campaignId
    ? allCampaigns.filter((campaign) => {
        const { key } = campaign;
        return key.toLowerCase().includes(campaignId.toLowerCase());
      })
    : [];

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <S.Container>
        <input
          type="file"
          ref={hiddenBackgroundFileInput}
          accept="image/*"
          onChange={(e) => {
            if (isUpdatePath) {
              setChangeBackgroundImage(true);
            }
            setBackgroundImage(e.target.files[0]);
          }}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={hiddenDesktopFileInput}
          accept="image/*"
          onChange={(e) => {
            if (isUpdatePath) {
              setChangeBannerDesktop(true);
            }
            setBannerDesktop(e.target.files[0]);
          }}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={hiddenMobileFileInput}
          accept="image/*"
          onChange={(e) => {
            if (isUpdatePath) {
              setChangeBannerMobile(true);
            }
            setBannerMobile(e.target.files[0]);
          }}
          style={{ display: 'none' }}
        />

        <D.TriiboH1 style={{ textAlign: 'center' }}>
          {isUpdatePath ? 'Atualizar Campanha Mapfre' : 'Cadastrar Campanha Mapfre'}
        </D.TriiboH1>

        <S.Content>
          <S.Cols>
            <S.Col>
              <D.TriiboH4>
                <b>Título</b>
              </D.TriiboH4>
              <S.TextFild
                placeholder="Título da campanha"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <D.TriiboH4>
                <b>Tag</b>
              </D.TriiboH4>
              <S.TextFild
                placeholder="Tag da campanha"
                value={campaignTag}
                onChange={(e) => setCampaignTag(e.target.value)}
              />
              <D.TriiboH4>
                <b>Texto</b>
              </D.TriiboH4>
              <S.TextCountContainer>
                <S.TextArea
                  rows={10}
                  placeholder={'Escreva aqui'}
                  maxLength="1400"
                  value={text}
                  onChange={(e) => handleChangeText(e)}
                />
                <S.TextCounter>{text.length}/1400</S.TextCounter>
              </S.TextCountContainer>
            </S.Col>
            <S.Col>
              <D.TriiboH4>
                <b>Background</b>
              </D.TriiboH4>
              <S.ImportImageBox>
                <S.ImportImageButton onClick={() => handleClickInputImage('Background')}>
                  <D.TriiboH5>Carregar Arquivo</D.TriiboH5>
                </S.ImportImageButton>
                <D.TriiboH5>
                  {backgroundImage !== '' &&
                  backgroundImage !== null &&
                  backgroundImage !== undefined
                    ? backgroundImage.name || backgroundImage.split('/')[1]
                    : 'Nenhum arquivo selecionado'}
                </D.TriiboH5>
              </S.ImportImageBox>
              <D.TriiboH4>
                <b>Banner Desktop - top</b>
              </D.TriiboH4>
              <S.ImportImageBox>
                <S.ImportImageButton onClick={() => handleClickInputImage('Desktop')}>
                  <D.TriiboH5>Carregar Arquivo</D.TriiboH5>
                </S.ImportImageButton>
                <D.TriiboH5>
                  {bannerDesktop !== '' && bannerDesktop !== null && bannerDesktop !== undefined
                    ? bannerDesktop.name || bannerDesktop.split('/')[1]
                    : 'Nenhum arquivo selecionado'}
                </D.TriiboH5>
              </S.ImportImageBox>
              <D.TriiboH4>
                <b>Banner Mobile - top</b>
              </D.TriiboH4>
              <S.ImportImageBox>
                <S.ImportImageButton onClick={() => handleClickInputImage('Mobile')}>
                  <D.TriiboH5>Carregar Arquivo</D.TriiboH5>
                </S.ImportImageButton>
                <D.TriiboH5>
                  {bannerMobile !== '' && bannerMobile !== null && bannerMobile !== undefined
                    ? bannerMobile.name || bannerMobile.split('/')[1]
                    : 'Nenhum arquivo selecionado'}
                </D.TriiboH5>
              </S.ImportImageBox>
              <D.TriiboH4>
                {isUpdatePath ? <b>ID da campanha</b> : <b>Selecione o ID da campanha</b>}
              </D.TriiboH4>
              <S.SelectCampaignId>
                {isUpdatePath ? (
                  <>
                    {campaignIdEditIsOpen ? (
                      <S.BoxContent>
                        <Autocomplete
                          style={{ flex: 1 }}
                          id="tags-outlined"
                          options={FilteredCampaignsIds}
                          getOptionLabel={(option) => option.key}
                          value={undefined}
                          onChange={(event, value) => setCampaignIdSelected(value)}
                          filterSelectedOptions
                          renderOption={(props, option) => {
                            return <li {...props}>{option.key}</li>;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              style={{ borderRadius: '10px' }}
                              value={inputSearchCampaignId}
                              onChange={(e) => setInputSearchCampaignId(e.target.value)}
                            />
                          )}
                        />
                        <S.ButtonEdit
                          style={{ background: '#f13a3a' }}
                          onClick={() => {
                            setCampaignIdEditIsOpen(false);
                            setCampaignPeriodEditIsOpen(false);
                          }}
                        >
                          <IoIosClose size={24} color="#fff" />
                        </S.ButtonEdit>
                      </S.BoxContent>
                    ) : (
                      <S.BoxContent>
                        <S.ContentInfo>
                          <D.TriiboH5>{campaignId}</D.TriiboH5>
                        </S.ContentInfo>
                        <S.ButtonEdit
                          onClick={() => {
                            setCampaignIdEditIsOpen(true);
                            setCampaignPeriodEditIsOpen(true);
                          }}
                        >
                          <img alt="icone-edicao" src={editIcon} />
                        </S.ButtonEdit>
                      </S.BoxContent>
                    )}
                  </>
                ) : (
                  <Autocomplete
                    id="tags-outlined"
                    options={FilteredCampaignsIds}
                    getOptionLabel={(option) => option.key}
                    value={undefined}
                    onChange={(event, value) => setCampaignIdSelected(value)}
                    filterSelectedOptions
                    renderOption={(props, option) => {
                      return <li {...props}>{option.key}</li>;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        style={{ borderRadius: '10px' }}
                        value={inputSearchCampaignId}
                        onChange={(e) => setInputSearchCampaignId(e.target.value)}
                      />
                    )}
                  />
                )}
              </S.SelectCampaignId>
              <D.TriiboH4>
                {isUpdatePath ? <b>Periodo da campanha</b> : <b>Selecione o Periodo</b>}
              </D.TriiboH4>
              {isUpdatePath ? (
                <>
                  {campaignPeriodEditIsOpen ? (
                    <S.BoxContent>
                      <S.SelectCampaignPeriod
                        style={{ flex: '1' }}
                        value={campaignIdEditIsOpen ? campaignPeriodSelected : campaignPeriodId}
                        onChange={(e) =>
                          campaignIdEditIsOpen
                            ? setCampaignPeriodSelected(e.target.value)
                            : setCampaignPeriodId(e.target.value)
                        }
                        disabled={campaignIdEditIsOpen ? !campaignIdSelected : !campaignId}
                      >
                        <option disabled value="">
                          Selecione...
                        </option>
                        {campaignId && !campaignIdEditIsOpen
                          ? FilteredCampaignPeriod[0].value.periods.map((period, index) => (
                              <option value={period.id} key={index}>
                                {period.id} -{' '}
                                {convertToDateDefault(period.startsAt || period.startAt)} |{' '}
                                {convertToDateDefault(period.endsAt)}
                              </option>
                            ))
                          : campaignIdSelected &&
                            campaignIdSelected.value.periods.map((period, index) => (
                              <option value={period.id} key={index}>
                                {period.id} -{' '}
                                {convertToDateDefault(period.startsAt || period.startAt)} |{' '}
                                {convertToDateDefault(period.endsAt)}
                              </option>
                            ))}
                      </S.SelectCampaignPeriod>
                      <S.ButtonEdit
                        disabled={campaignIdEditIsOpen}
                        style={{ background: '#f13a3a' }}
                        onClick={() => setCampaignPeriodEditIsOpen(false)}
                      >
                        <IoIosClose size={24} color="#fff" />
                      </S.ButtonEdit>
                    </S.BoxContent>
                  ) : (
                    <S.BoxContent>
                      <S.ContentInfo>
                        <D.TriiboH5>
                          {convertToDateDefault(campaignPeriod.startAt || campaignPeriod.startsAt)}{' '}
                          - {convertToDateDefault(campaignPeriod.endsAt)}
                        </D.TriiboH5>
                      </S.ContentInfo>
                      <S.ButtonEdit onClick={() => setCampaignPeriodEditIsOpen(true)}>
                        <img alt="icone-adicao" src={editIcon} />
                      </S.ButtonEdit>
                    </S.BoxContent>
                  )}
                </>
              ) : (
                <S.SelectCampaignPeriod
                  value={campaignPeriodSelected}
                  onChange={(e) => setCampaignPeriodSelected(e.target.value)}
                  disabled={!campaignIdSelected}
                >
                  <option disabled value="">
                    Selecione...
                  </option>
                  {campaignIdSelected &&
                    campaignIdSelected.value.periods.map((period, index) => (
                      <option value={period.id} key={index}>
                        {period.id} - {convertToDateDefault(period.startsAt || period.startAt)} |{' '}
                        {convertToDateDefault(period.endsAt)}
                      </option>
                    ))}
                </S.SelectCampaignPeriod>
              )}
            </S.Col>
          </S.Cols>

          <S.Cols>
            <S.ButtonOpenModal onClick={() => handleChangeDataToModal('promotions')}>
              <D.TriiboH4>
                <b>Promoções</b>
              </D.TriiboH4>
            </S.ButtonOpenModal>
            <S.ButtonOpenModal onClick={() => handleChangeDataToModal('establishments')}>
              <D.TriiboH4>
                <b>Estabelecimentos</b>
              </D.TriiboH4>
            </S.ButtonOpenModal>
            <S.ButtonOpenModal onClick={() => handleChangeDataToModal('cashbacks')}>
              <D.TriiboH4>
                <b>Cashbacks</b>
              </D.TriiboH4>
            </S.ButtonOpenModal>
          </S.Cols>
          <S.RegisterButton onClick={handleSubmit} disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '0.5rem' }} size="23px" />}
            {isUpdatePath ? 'Atualizar' : 'Cadastrar'}
          </S.RegisterButton>
        </S.Content>

        <ModalTemplate
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          data={
            modalData === 'promotions'
              ? promotions
              : modalData === 'establishments'
              ? establishments
              : cashbacks
          }
          setData={
            modalData === 'promotions'
              ? setPromotions
              : modalData === 'establishments'
              ? setEstablishments
              : setCashbacks
          }
          title={
            modalData === 'promotions'
              ? 'Cadastrar Promoções'
              : modalData === 'establishments'
              ? 'Cadastrar Estabelecimentos'
              : 'Cadastrar Cashbacks'
          }
          placeholder={
            modalData === 'promotions'
              ? 'Id da promoção'
              : modalData === 'establishments'
              ? 'Id do estabelecimento'
              : 'Id do cashback'
          }
        />
      </S.Container>
    </div>
  );
}
