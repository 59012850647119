import React from "react";

import * as C from "../styles";
import * as D from "../../../../DesignSystem/styles";
import * as S from "./styles";
import { CircularProgress, Modal } from "@mui/material";

import { AiFillCloseCircle } from "react-icons/ai";

export default function ModalConfirmSubmit({
  handleCloseModal,
  isModalConfirmSubmitOpen,
  typeSubmit,
  submitCommunication,
  loading,
}) {
  const handleSubmit = () => {
    submitCommunication(typeSubmit);
  };

  return (
    <Modal
      onClose={() => handleCloseModal("confirmSubmit")}
      open={isModalConfirmSubmitOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <C.ModalContainer desk_width={"514px"}>
        <C.ModalBox>
          <C.CloseModalIcon onClick={() => handleCloseModal("confirmSubmit")}>
            <AiFillCloseCircle size={20} />
          </C.CloseModalIcon>
          <S.Container>
            <D.TriiboH4>
              <strong>Sua comunicação está quase pronta.</strong>
            </D.TriiboH4>
            <D.TriiboH5>
              Caso esteja tudo certo, publique sua comunicação. Para revisar a
              comunicação, clique em voltar.
            </D.TriiboH5>
            <S.ButtonsContainer>
              <S.GoBackButton onClick={() => handleCloseModal("confirmSubmit")}>
                Voltar
              </S.GoBackButton>
              <S.PublishButton onClick={handleSubmit}>
                {loading ? (
                  <CircularProgress size="23px" style={{ color: "#fff" }} />
                ) : (
                  "Publicar!"
                )}
              </S.PublishButton>
            </S.ButtonsContainer>
          </S.Container>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
