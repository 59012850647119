import React, { useEffect } from 'react';
import FontPicker from 'font-picker-react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTemplate } from '../Context/Marketing';
import { useElementText } from '../Context/Marketing';
import './font-picker.css';
import cfac22 from 'js/library/utils/cfac22';

const TabText = ({ classes, elementIndex, tabIndex }) => {
  const {
    selectedTemplate,
    establishmentData,
    storeData,
    listElementSelected,
    setListElementSelected,
  } = useTemplate();
  const {
    setElementText,
    setFontColorValue,
    setFontStyleValue,
    setSelectedElementText,
    handleSelectStore,
    setTextStoreItem,
    textStoreItem,
  } = useElementText();

  const storeName = storeData.length === 0 ? null : storeData.map((store) => store.title);

  const textEstablishmentItem = [
    establishmentData.description,
    establishmentData.name,
    establishmentData.address,
  ];

  const currentElement = selectedTemplate.elements[tabIndex];

  useEffect(() => {
    if (listElementSelected.length === 0) {
      return;
    }

    if (
      listElementSelected[tabIndex]?.type === 'establishment' &&
      !listElementSelected[tabIndex]?.text
    ) {
      const typeText = listElementSelected[tabIndex].initialText;

      listElementSelected[tabIndex].text = establishmentData[typeText];
      setListElementSelected([...listElementSelected]);
    }

    if (listElementSelected[tabIndex]?.type === 'free' && !listElementSelected[tabIndex]?.text) {
      listElementSelected[tabIndex].text = listElementSelected[tabIndex].initialText;
      setListElementSelected([...listElementSelected]);
    }

    listElementSelected.forEach((elements, index) => {
      if (
        listElementSelected[index]?.type === 'establishment' &&
        listElementSelected[index]?.entity === 'text' &&
        !listElementSelected[index]?.text
      ) {
        let text = '';

        if (elements.initialText === 'description') {
          text = establishmentData?.description;
        } else if (elements.initialText === 'name') {
          text = establishmentData?.name;
        } else {
          text = establishmentData?.address;
        }

        listElementSelected[index].text = text;
        setListElementSelected([...listElementSelected]);
      }

      if (
        listElementSelected[index]?.type === 'store' &&
        listElementSelected[index]?.entity === 'text' &&
        storeData.length > 0 &&
        !listElementSelected[index]?.text
      ) {
        let text = '';

        if (elements.initialText === 'title') {
          text = storeData[0].title;
        } else {
          text = storeData[0].description;
        }
        setTextStoreItem([storeData[0].title, storeData[0].description]);

        listElementSelected[index].text = text;
        listElementSelected[index].storeName = storeData[0].title;

        setListElementSelected([...listElementSelected]);
      }
    });

    return () => {
      setElementText({});
      setFontColorValue('');
      setFontStyleValue('');
      setSelectedElementText('');
    };
  }, [selectedTemplate]);

  useEffect(() => {
    if (
      storeData.length > 0 &&
      listElementSelected[tabIndex]?.storeName &&
      currentElement?.type === 'store'
    ) {
      setTextStoreItem([]);
      handleSelectStore(listElementSelected[tabIndex].storeName, tabIndex);
    }
  }, [tabIndex]);

  function handleSelectFont(value) {
    setFontStyleValue(value);
    handleUptadePreview('fontStyle', value);
  }

  function handleUptadePreview(name, value) {
    listElementSelected[tabIndex][name] = value;
    setListElementSelected([...listElementSelected]);
  }

  return (
    <>
      {currentElement?.type === 'establishment' && (
        <Box className={classes.boxElement}>
          <Typography variant="subtitle2" className={classes.elementTypeTitle}>
            Tipo estabelecimento
          </Typography>

          <FormControl className={classes.inputElementText}>
            <InputLabel id="text-select-label">Selecione o texto</InputLabel>
            <Select
              labelId="text-select"
              id="text-select"
              value={listElementSelected[tabIndex]?.text || textEstablishmentItem[0]}
              disabled={listElementSelected[tabIndex].blockText}
              onChange={(e) => {
                setSelectedElementText(e.target.value);
                handleUptadePreview('text', e.target.value);
              }}
            >
              {textEstablishmentItem.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            className={classes.inputElementText}
            type="color"
            label="Cor do texto"
            disabled={listElementSelected[tabIndex].blockColor}
            value={listElementSelected[tabIndex].fontColor}
            onChange={(e) => {
              setFontColorValue(e.target.value);
              handleUptadePreview('fontColor', e.target.value);
            }}
          />
          <div>
            <label className="label-picker-font-mkt">Selecione a fonte</label>
            <FontPicker
              pickerId="main"
              apiKey={cfac22('FIREBASE_APIKEY')}
              activeFontFamily={listElementSelected[tabIndex].fontStyle}
              onChange={(nextFont) => handleSelectFont(nextFont.family)}
              // sort="popularity"
            />
          </div>
        </Box>
      )}
      {currentElement?.type === 'store' &&
        (storeData.length === 0 ? (
          <Box className={classes.boxElement}>
            <Typography variant="subtitle2" className={classes.elementTypeTitle}>
              Estabelecimento ainda não possui promoções ativas.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.boxElement}>
            <Typography variant="subtitle2" className={classes.elementTypeTitle}>
              Tipo promoção
            </Typography>

            <FormControl className={classes.inputElementText}>
              <InputLabel id="store-select-label">Selecione a Promoção</InputLabel>
              <Select
                labelId="store-select"
                id="store-select"
                value={listElementSelected[tabIndex]?.storeName || ''}
                onChange={(e) => {
                  handleSelectStore(e.target.value, tabIndex);
                  handleUptadePreview('storeName', e.target.value);
                }}
              >
                {storeName.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.inputElementText}>
              <InputLabel id="text-select-label">Selecione o texto</InputLabel>
              <Select
                labelId="text-select"
                id="text-select"
                value={listElementSelected[tabIndex]?.text || ''}
                onChange={(e) => {
                  handleUptadePreview('text', e.target.value);
                }}
                disabled={
                  !listElementSelected[tabIndex]?.storeName ||
                  listElementSelected[tabIndex].blockText
                }
              >
                {textStoreItem.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className={classes.inputElementColor}
              type="color"
              label="Cor do texto"
              disabled={currentElement?.blockColor}
              value={listElementSelected[tabIndex].fontColor}
              onChange={(e) => {
                setFontColorValue(e.target.value);
                handleUptadePreview('fontColor', e.target.value);
              }}
            />
            <div style={{ marginTop: '10px' }}>
              <label className="label-picker-font-mkt">Selecione a fonte</label>
              <FontPicker
                pickerId="main"
                apiKey={cfac22('FIREBASE_APIKEY')}
                activeFontFamily={listElementSelected[tabIndex].fontStyle}
                onChange={(nextFont) => handleSelectFont(nextFont.family)}
                // sort="popularity"
              />
            </div>
          </Box>
        ))}
      {currentElement?.type === 'free' && (
        <Box className={classes.boxElement}>
          <Typography variant="subtitle2" className={classes.elementTypeTitle}>
            Tipo livre
          </Typography>

          <TextField
            className={classes.inputElementText}
            type="text"
            label="Texto"
            disabled={listElementSelected[tabIndex].blockText}
            value={listElementSelected[tabIndex]?.text}
            onChange={(e) => {
              // setSelectedElementText(e.target.value);
              handleUptadePreview('text', e.target.value);
            }}
          />

          <TextField
            className={classes.inputElementColor}
            type="color"
            label="Cor do texto"
            disabled={listElementSelected[tabIndex].blockColor}
            value={listElementSelected[tabIndex].fontColor}
            onChange={(e) => {
              setFontColorValue(e.target.value);
              handleUptadePreview('fontColor', e.target.value);
            }}
          />

          <div style={{ marginTop: '10px' }}>
            <label className="label-picker-font-mkt">Selecione a fonte</label>
            <FontPicker
              pickerId="main"
              apiKey={cfac22('FIREBASE_APIKEY')}
              activeFontFamily={listElementSelected[tabIndex].fontStyle}
              onChange={(nextFont) => handleSelectFont(nextFont.family)}
              // sort="popularity"
            />
          </div>
        </Box>
      )}
    </>
  );
};

export default TabText;
