import { Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 1rem 4rem 1rem;
  @media (min-width: 1020px) {
    max-width: 1000px;
    margin: 100px auto 150px auto;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TextDescription = styled.h4`
  font-size: 0.9rem;
  margin-bottom: 3rem;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
  line-height: 1.4rem;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
`;

export const LabelToogle = styled(Typography)`
  padding: 0 0.2rem !important;
  font-size: 1.2rem;
  font-weight: 700;
`;

export const ContainerToogle = styled(Stack)`
  margin-bottom: 2rem;
`;
