import React from "react";
import { Link } from "react-router-dom";
import { BsXCircleFill } from "react-icons/bs";

import { Modal, Box, Typography, Button, Grid } from "@mui/material";

const InterruptLeaveModal = (props) => {
  const { status, setStatus } = props;

  const style = {
    fontFamily: "Source Sans Pro",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: { xs: "100%", sm: "30rem", md: "30rem" },
    bgcolor: "background.paper",
    boxShadow: 24,
    px: 10,
    py: 5,
  };

  const styleButton = {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "10px",
    minWidth: "6.4rem",
  };

  const styleButtonGray = {
    backgroundColor: "#38383880",
    " &:hover": {
      backgroundColor: "#3d3d3d",
    },
  };

  const styleButtonTeal = {
    backgroundColor: "#08BAD0",
    " &:hover": {
      backgroundColor: "#0799ab",
    },
  };

  const handleClose = () => {
    setStatus(false);
  };

  return (
    <div>
      <Modal open={status} onClose={handleClose}>
        <Box sx={style}>
          <BsXCircleFill
            onClick={handleClose}
            style={{
              fontSize: "1.4rem",
              position: "absolute",
              top: "0.8rem",
              right: "0.8rem",
              cursor: "pointer",
            }}
          />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          // gap={2}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Source Sans Pro",
                fontWeight: "600",
                color: "#383838",
              }}
            >
              Tem certeza que deseja sair?
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontFamily: "Source Sans Pro",
                fontWeight: "500",
                color: "#383838",
              }}
            >
              Você perderá as informações já adicionadas
            </Typography>

            <Grid
              container
              // item
              direction="row"
              alignItems="center"
              justifyContent="center"
              // xs={12}
              gap={2.4}
              sx={{ mt: 2 }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                disableElevation
                color="secondary"
                sx={[styleButton, styleButtonGray]}
              >
                Voltar
              </Button>
              <Link
                to={{
                  pathname: "/admin",
                  state: "",
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  //   onClick={() => logoutUser()}
                  variant="contained"
                  disableElevation
                  sx={[styleButton, styleButtonTeal]}
                >
                  Sim
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default InterruptLeaveModal;
