import React, { useEffect, useRef, useState } from 'react';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import readXlsxFile from 'read-excel-file';
import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';
import { HeaderTitle } from '../../RelationshipClub/HeaderTitle';
import usePagination from '../../RelationshipClub/usePagination';

import { toast } from 'react-toastify';

import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
} from '@mui/material';

import { Pagination } from '@mui/material';

import { useStyles } from '../../RelationshipClub/useStyles';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { GetPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';
import { GoBackHeader } from '../../RelationshipClub/GoBackHeader';

export default function AttachWhitelist({ history, ...props }) {
  const [clubeId] = useState(window.location.href.split('/')[7]);
  const [clientList, setClientList] = useState([]);
  const [columnsTable, setColumnsTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [formInfos, setFormInfos] = useState([]);
  const [seuClubeInfos, setSeuClubeInfos] = useState({});
  const [page, setPage] = useState(1);

  const fileUploader = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId)
        .then((response) => {
          setSeuClubeInfos(response);
          GetPartnerUserForms(response.partnerId).then((result) => {
            setFormInfos(result);
            setLoading(false);

            setColumnsTable([result.tokenType]);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [clubeId]);

  const handleExportExcelFile = () => {
    const data = [[formInfos.tokenType]];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

    worksheet['!cols'] = [{ width: 15 }];

    // Conversão do arquivo para o formato binário
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Criação do Blob para salvar o arquivo
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Nome do arquivo
    const fileName = 'template.xlsx';

    // Salva o arquivo
    saveAs(blob, fileName);
  };

  const formatUserList = (formValues) => {
    let userInfoObj = {};
    let contactList = [];
    let documentList = [];
    let addressList = {};

    formInfos.formFields.forEach((field, index) => {
      switch (field.type) {
        case 'contactList':
          if (formValues[field.fieldName]) {
            contactList.push({
              type: field.fieldName,
              value: formValues[field.fieldName].toString(),
            });
          }
          userInfoObj.contactList = contactList;
          break;

        case 'documentList':
          if (formValues[field.fieldName]) {
            documentList.push({
              type: field.fieldName,
              value: formValues[field.fieldName].toString().replace(/\D+/g, ''),
            });
            userInfoObj.documentList = documentList;
          }

          break;

        case 'addressList':
          if (formValues[field.fieldName]) {
            addressList[field.fieldName] = formValues[field.fieldName];

            userInfoObj.addressList = addressList;
          }
          break;

        case 'others':
          if (formValues[field.fieldName]) {
            if (field.inputType === 'date') {
              userInfoObj[field.fieldName] = new Date(formValues[field.fieldName]).getTime();
            } else {
              userInfoObj[field.fieldName] = formValues[field.fieldName];
            }
          }
          break;
        default:
          break;
      }
    });

    userInfoObj.partnerId = seuClubeInfos.partnerId;
    userInfoObj.partnerName = seuClubeInfos.clubeId;

    const data = {
      partnerInfo: { ...userInfoObj },
      clubeId: seuClubeInfos.clubeId,
      token: formValues[formInfos.tokenType],
      cellphone: formValues.cellPhone,
      name: formValues.name,
    };

    return data;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const fileExt = file.name.split('.').find((item) => item === 'xlsx');

    if (fileExt === 'xlsx' && fileExt !== undefined) {
      const fileXlsx = await readXlsxFile(event.target.files[0]);

      const columns = fileXlsx[0];
      const data = fileXlsx.slice(1); // Exclui o header do array (nome, telefone, email, data de nascimento, cpf)

      const formattedData = data.map((row) => {
        const obj = {};
        columns.forEach((column, columnIndex) => {
          obj[column] = row[columnIndex] ? row[columnIndex].toString() : null;
        });
        return obj;
      });
      // console.log(formattedData);

      const validateUserData = formattedData.map((row) => {
        return {
          ...row,
          clubeId,
        };
      });

      const copyValidateUserData = validateUserData;

      validateUserData.map((client, index) => {
        return Object.keys(client).map((item) => {
          if (validateUserData[index][item] === null) {
            delete copyValidateUserData[index][item];
          }
        });
      });

      // console.log(copyValidateUserData);

      setClientList(copyValidateUserData);
    } else {
      toast.error('Arquivo não é no formato .xlsx');
    }
  };

  const PER_PAGE = 10;

  const count = Math.ceil(clientList.length / PER_PAGE);
  const _DATA = usePagination(clientList, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChangeLoading = (value) => {
    setLoadingRegister(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const filteredUserInfo = clientList.filter((row) => {
      if (row[formInfos.tokenType]) {
        return row;
      }
    }); // Filter para que venha apenas os usuários que possuem tokenType informados.

    if (filteredUserInfo.length === 0) {
      return toast.error(
        `O ${
          formInfos.tokenType === 'cellPhone' ? 'número de celular' : formInfos.tokenType
        } é obrigatório`
      );
    }

    const formattedUsers = filteredUserInfo.map((client, index) => {
      return formatUserList(client);
    });

    try {
      RegisterUsersBatch_v2(formattedUsers)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Permissões cadastradas com sucesso.');
            setClientList([]);
          }
        })
        .catch((error) => {
          toast.error('Erro ao cadastrar usuários.');
        });
    } catch (error) {
      toast.error('Ops, usuários não foram cadastrados.');
    } finally {
      handleChangeLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          height: '50vh',
          width: '100%',
        }}
      >
        <CircularProgress size="6rem" />
      </div>
    );
  }
  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <Container maxWidth={false} className={classes.root}>
        <HeaderTitle pageInfo="Cadastro em lote de permissões" />

        <Grid item xs={12} className={classes.boxInportFiles}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                Carregue um arquivo no formato Excel com o seguinte padrão:
                <br />
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleExportExcelFile}
                >
                  Exportar template
                </Button>
              </Typography>
              <br />
              <Typography variant="subtitle1" style={{ fontWeight: 600, marginTop: '22px' }}>
                <div>
                  {formInfos.tokenType === 'cellPhone'
                    ? '1: O dado "cellphone" é obrigatório para inclusão do usuário na lista de permissão.'
                    : `1: O dado "Nome e ${formInfos.tokenType}" é obrigatório para inclusão do usuário na lista de permissão.`}
                </div>
                <div>
                  2: O número de telefone deve seguir obrigatoriamente o formato: (DDD) + telefone
                  (exemplo: +5511970697706)
                </div>
              </Typography>{' '}
              <br />
              <div>
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => fileUploader.current.click()}
                >
                  Carregar arquivo
                </Button>
                <input
                  type="file"
                  accept=".xlsx"
                  ref={fileUploader}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.boxListUsers}>
          {clientList.length > 0 && (
            <>
              <strong>Usuários Listados: </strong>
              {clientList.length}
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columnsTable.map((column, index) => (
                        <TableCell align="left" key={index}>
                          <strong>{column}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_DATA.currentData().map((client, index) => (
                      <TableRow key={index}>
                        {columnsTable.map((item, indexColumns) => (
                          <TableCell
                            key={indexColumns}
                            style={{
                              backgroundColor: `${!client[formInfos.tokenType] ? 'red' : '#ffff'}`,
                            }}
                            align="left"
                          >
                            {client[item]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Pagination
                sx={{ mt: 3 }}
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />

              <div align="center" style={{ marginTop: 15 }}>
                <Button
                  style={{
                    fontWeight: '550',
                    color: loadingRegister ? '#383838' : '#fff',
                    textTransform: 'capitalize',
                    marginBottom: '2rem',
                    background: loadingRegister && '#dbdbdb',
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={(event) => {
                    handleSubmit(event);
                    setLoadingRegister(true);
                  }}
                  disabled={clubeId === '' || loadingRegister === true}
                >
                  {loadingRegister && (
                    <CircularProgress size="23px" style={{ marginRight: '0.5rem' }} />
                  )}
                  Cadastrar todos
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
