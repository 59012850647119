import styled from "styled-components/macro";

import * as D from "js/components/DesignSystem/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  /* gap: 2rem; */
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  padding-top: 3rem;

  @media (max-width: 1220px) {
    padding: 0 2rem;
  }
`;

export const Header = styled.header`
  padding-top: 30px;
  text-align: center;
`;

export const SearchBox = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

export const AddNotificationButton = styled.button`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 200px;
  background-color: #6e32960c;
  color: #6e3296;
  border: 1px dashed #6e3296;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;

  span {
    font-size: 24px;
  }

  &:hover {
    background-color: #6e329633;
  }

  ${({ active }) =>
    active &&
    `
      border: 1px dashed ${D.triiboPurple};
      color: white;
      background-color: ${D.triiboPurple};

      &:hover {
        background-color: ${D.triiboPurple};
      }

      cursor: default;
    `}
`;

export const StatusButton = styled.button`
  height: 100%;
  width: auto;
  background: #328796;
  border: none;
  color: #fff;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 15px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;

  &:hover {
    background-color: #328796cc;
  }
`;

export const DialogueContentsContainer = styled.div`
  width: 330px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-family: "Source Sans Pro", sans-serif;
`;

export const DialogueTitle = styled.span`
  width: 100%;
  font-weight: 600;
  text-align: center;
`;

export const DialogueButtonContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const EditOrDeleteCOntainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
