import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { getUserInfo } from '../../helpers';
import cfac22 from '../../cfac22';

export function updateTrialStatus(objSteps) {
  return new Promise(function (resolve, reject) {
    const uid = getUserInfo().uId;

    authToken(uid).then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/users/stepsOnboarding`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: objSteps,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
