import styled from "styled-components/macro";

import * as D from "js/components/DesignSystem/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.6rem;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  padding: 3rem 0;

  @media (max-width: 1220px) {
    padding: 2rem;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const AddNotificationButton = styled.button`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 200px;
  background-color: #6e32960c;
  color: #6e3296;
  border: 1px dashed #6e3296;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;

  span {
    font-size: 24px;
  }

  &:hover {
    background-color: #6e329633;
  }

  ${({ active }) =>
    active &&
    `
      border: 1px dashed ${D.triiboPurple};
      color: white;
      background-color: ${D.triiboPurple};

      &:hover {
        background-color: ${D.triiboPurple};
      }

      cursor: default;
    `}
`;

export const EditOrDeleteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const StatusButton = styled.button`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #328796;
  border: none;
  color: #fff;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 15px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;

  &:hover {
    background-color: #328796cc;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const FormField = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.8rem;
`;

export const SwitcherContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
`;

export const SwitcherButton = styled.button`
  width: 50%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  background: ${D.triiboGray};
  border: 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:disabled {
    background: ${D.triiboPurple};
    cursor: default;
  }
`;

export const BatchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const InputContainer = styled.span`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const PreviewCard = styled.div`
  width: 100%;
  min-height: 200px;
  border-radius: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.15);
  gap: 1rem;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 450px;
  }

  .fieldName {
    font-weight: 600;
  }
`;
