export default function reducer(state = {
	id: null,
  fotoThumb: null,
  nome: null,
  endereco: null
}, action) {

  switch (action.type) {

    case "ESTABLISHMENT_LIST_PENDING": {
      return {
        ...state,
        id: null,
		    fotoThumb: null,
		    nome: null,
		    endereco: null
      };
    }

    case "ESTABLISHMENT_LIST_FULFILLED": {
      return {
        ...state,
        id: action.payload.id,
        fotoThumb: action.payload.code,
		    nome: action.payload.nome,
        endereco: action.payload.endereco
      };
    }

    case "ESTABLISHMENT_LIST_REJECTED": {
      return {
        ...state,
        id: null,
		    fotoThumb: null,
	    	nome: null,
		    endereco: null
      };
    }

    default: return state;

  }

}