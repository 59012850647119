import { Box } from "@mui/material";
import { Select } from "@mui/material";
import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const InputWithMask = styled(ReactInputMask)`
  background-color: #fff;
  border: 1px solid #afafaf;
  border-radius: 10px;
  font-family: Source Sans Pro;
  min-height: 1.5rem;
  outline: none;
  padding: 0.7rem;
  resize: none;
  width: 100%;

  transition: 0.3s ease-in-out;
`;

export const AddManagerButton = styled.button`
  background: #06bad0;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
`;

export const InfoManager = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const ContainerAddManager = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`;

export const ContentModalClube = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    margin: 1rem;
  }
`;

export const ModalBoxClube = styled(Box)`
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  max-height: 90%;
  max-width: 90%;
  min-width: 40rem;

  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px 20px 30px 20px;
  overflow-y: ${({ isActiveOverflow }) =>
    isActiveOverflow ? "auto" : "hidden"};
  width: 800;

  transform: translate(-50%, -50%);

  @media screen and (max-width: 800px) {
    min-width: 40rem;
  }

  @media screen and (max-width: 700px) {
    min-width: 30rem;
  }

  @media screen and (max-width: 500px) {
    min-width: 20rem;
  }
`;

// EditClub

export const Display = styled.span`
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: ${(props) => (props.content ? props.content : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};

  @media screen and (max-width: 1140px) {
    flex-direction: column;
    button {
      margin: 0.1rem 0;
    }
  }
`;

export const Selector = styled(Select)`
  border-radius: 10px;
  height: 2.6rem;
  width: 100%;
`;
