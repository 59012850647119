import React from 'react';
import { cloneDeep } from 'lodash';

import { Modal } from '@mui/material';
import { toast } from 'react-toastify';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { BsXCircleFill } from 'react-icons/bs';
import { DeleteUserPartner } from 'js/library/utils/API/apiDeleteUserPartner';

const DeleteUserModal = (props) => {
  const { open, setOpen, uId, list, setList, partnerId } = props;

  const filterByKey = (arr, key, value) => {
    return arr.filter((obj) => obj[key].value !== value);
  };

  return (
    <>
      <Modal open={open}>
        <S.DeleteUserModalContainer>
          <BsXCircleFill
            onClick={() => setOpen(false)}
            style={{
              fontSize: '1.4rem',
              position: 'absolute',
              top: '0.8rem',
              right: '0.8rem',
              cursor: 'pointer',
            }}
          />
          <D.TriiboH4>{'Tem certeza que deseja deletar cliente?'}</D.TriiboH4>
          <S.DeleteUserModalButtonContainer>
            <D.TriiboFilledButton
              blue={true}
              onClick={() => {
                DeleteUserPartner(uId, partnerId)
                  .then(() => {
                    toast.success('Cliente deletado com sucesso');
                    let newArr = cloneDeep(list);
                    const filteredArr = filterByKey(newArr, 'uId', uId);
                    setList(filteredArr);
                    setOpen(false);
                  })
                  .catch(() => {
                    toast.error('Ocorreu erro');
                  });
              }}
            >
              Proceder
            </D.TriiboFilledButton>
            <D.TriiboFilledButton black={true} onClick={() => setOpen(false)}>
              Não
            </D.TriiboFilledButton>
          </S.DeleteUserModalButtonContainer>
        </S.DeleteUserModalContainer>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
