import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

export function userReadCPF_v3(cpf, mapfre) {
  return new Promise(function async(resolve, reject) {
    let userInfo = {
      documentList: [
        {
          type: 'cpf',
          value: cpf,
        },
      ],
    };

    let queryPartnerAPI = ['getUserInfo'];

    userInfo.partnerList = [];

    if (mapfre === true) {
      userInfo.partnerList.push({
        partnerName: 'Mapfre',
        partnerId: cfac22('PARTNER_ID_MAPFRE'),
        password: 123456,
        apiMode: 'REMOTE',
        apiModeEnv: cfac22('API_MODE_ENV'),
        documentList: [
          {
            type: 'cpf',
            value: cpf,
          },
        ],
      });
    }

    // if (cofry === true) {
    //   queryPartnerAPI.push("getUserInfoCofry");

    //   userInfo.partnerList.push({
    //     documentList: [
    //       {
    //         type: "cpf",
    //         value: cpf,
    //       },
    //     ],
    //     partnerName: "Cofry",
    //     partnerId: cfac22('PARTNER_ID_COFRY'),
    //   });
    // }

    getChannelTokenUidNull()
      .then((token) => {
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            if (
              result.data.error === null &&
              (result.data.success.userInfo !== null ||
                result.data.success.partnerList.length !== 0)
            ) {
              return resolve(result.data.success);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}

export function userReadCellphone_v3(cellPhone) {
  return new Promise(function (resolve, reject) {
    let queryPartnerAPI = ['getUserInfo'];

    let userInfo = {
      contactList: [
        {
          type: 'cellPhone',
          value: cellPhone.toString(),
        },
      ],
    };

    getChannelTokenUidNull()
      .then((token) => {
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            if (result.data.error === null && result.data.success.userInfo !== null) {
              resolve(result.data.success);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}

export function userReadUid_v3(userUid) {
  return new Promise(function (resolve, reject) {
    let queryPartnerAPI = ['getUserInfo'];

    let userInfo = {
      uId: userUid,
    };

    getChannelTokenUidNull()
      .then((token) => {
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            if (result.data.error === null && result.data.success.userInfo !== null) {
              resolve(result.data.success);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}
