import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';

import * as S from '../../../components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';

import { getVoucherGroup } from 'js/library/utils/API/getVoucherGroup';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Typography,
  FormGroup,
  Switch,
  FormLabel,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Chip,
  Paper,
  Tooltip,
  Badge,
  colors,
  Box,
} from '@mui/material';

import { withStyles } from '@mui/styles';

import {
  Save,
  ArrowBack,
  AddPhotoAlternate,
  ExpandMore,
  Close,
  Help,
  FileCopy,
} from '@mui/icons-material';

import { Cropper } from 'react-image-cropper';
import Downshift from 'downshift';
import PropTypes from 'prop-types';

import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import Loading from 'js/containers/Loading/Loading';
import { updateStoreGroupInformationAction } from 'js/core/actions/storeGroupInformationActions';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { downloadImage } from 'js/library/services/StorageManager';
import deburr from 'lodash/deburr';
import { convertToDate, msToTime } from 'js/library/utils/helpers.js';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { BsXLg } from 'react-icons/bs';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

// Estilo da notificação - Badge
const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',

    '&:hover': {
      backgroundColor: colors['red'][700],
      color: 'white',
      cursor: 'pointer',
      border: 'none',
    },
  },
}))(Badge);

const StyledBadge2 = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',
  },
}))(Badge);

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

let suggestions = [];

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function convertIsoToDate(date) {
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const dateDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const dateHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const dateMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  return (date = dateYear + '-' + dateMonth + '-' + dateDay + 'T' + dateHour + ':' + dateMinute);
}
class StoreGroupInformation extends Component {
  constructor(props, context) {
    super(props, context);

    const dataEstabelecimento = props.location.state.dataEstabelecimento;

    //convertendo enable date
    let enableDate = new Date(Date.now());
    if (props.location.state.enableDate !== undefined) {
      enableDate = new Date(props.location.state.enableDate);
      /* 
      const year = enableDate.getFullYear();
      const month =
        enableDate.getMonth() + 1 < 10
          ? "0" + (enableDate.getMonth() + 1)
          : enableDate.getMonth() + 1;
      const day =
        enableDate.getDate() < 10
          ? "0" + enableDate.getDate()
          : enableDate.getDate();
      const hour =
        enableDate.getHours() < 10
          ? "0" + enableDate.getHours()
          : enableDate.getHours();
      const minute =
        enableDate.getMinutes() < 10
          ? "0" + enableDate.getMinutes()
          : enableDate.getMinutes();

      enableDate = year + "-" + month + "-" + day + "T" + hour + ":" + minute; */
    }
    enableDate = convertIsoToDate(enableDate);

    //convertendo disable date
    let disableDate = new Date(Date.now() + 86400000 * 365);
    if (props.location.state.disableDate !== undefined) {
      disableDate = new Date(props.location.state.disableDate);
    }
    disableDate = convertIsoToDate(disableDate);

    this.state = {
      //dados do estabelecimento
      idEstabelecimento: props.match.params.id,
      idGestor: dataEstabelecimento.idGestor,
      nomeEstabelecimento: dataEstabelecimento.nome,
      lat: dataEstabelecimento.lat,
      long: dataEstabelecimento.long,
      address: dataEstabelecimento.endereco ? dataEstabelecimento.endereco : null,
      neighborhood: dataEstabelecimento.endereco
        ? dataEstabelecimento.endereco.split('-')[1]
          ? dataEstabelecimento.endereco.split('-')[1].split(',')[0]
            ? dataEstabelecimento.endereco.split('-')[1].split(',')[0]
            : null
          : dataEstabelecimento.endereco
        : null,
      dataEstabelecimento,
      expirationType: 'fixed',
      disableDateCoupon: null,
      onSendExpiration: 1,

      //dados do template
      templates:
        props.location.state.key === undefined
          ? [
              {
                id:
                  props.location.state.templateId === undefined
                    ? firebaseDatabase.ref('Voucher-Template-v2').push().key
                    : props.location.state.templateId,
                titulo: null,
                campanhaTriibo: false,
                consumoUnico: false,
                cooldown: null,
                cooldownDays: 0,
                cooldownHours: 0,
                cooldownMinutes: 0,
                dataGeracao: null,
                generator: false,
                nomeTemplate: null,
                qtdDisponivel: null,
                hasSimultaneo: false,
                qtdSimultaneo: 1,
                type: 'store',
                value: 1,
              },
            ]
          : [],
      copyTemplateMessage: 'Copiar ID do template',

      //dados do template	Counter Match
      templateCounterMatch: {
        id: null,
        titulo: null,
        detailsTitle: null,
        descricao: null,
        detailsDescription: null,
        plural: null,
        singular: null,
        consumoUnico: false,
        cooldown: null,
        cooldownDays: 0,
        cooldownHours: 0,
        cooldownMinutes: 0,
        dataGeracao: null,
        generator: false,
        nomeTemplate: null,
        qtdDisponivel: null,
        hasSimultaneo: false,
        qtdSimultaneo: 1,
        type: 'store',
        disableDateHours: 0,
        disableDateMinutes: 0,
        hasDisableDate: false,

        equalImagePhotoFile: null,
        equalImageThumbnailFile: null,

        equalImagePhoto: false,
        equalImageThumbnail: false,

        thumbnail: null,
        foto: null,

        loadedThumbnail: null,
        loadedFoto: null,

        oldThumbnail: null,
        oldPhoto: null,

        fileFoto: null,
        fileThumbnail: null,
        sendSMS: false,
      },

      //dados da store
      idStore: props.location.state.key === undefined ? null : props.location.state.key,
      level: props.location.state.level === undefined ? 99 : props.location.state.level,
      region: props.location.state.region === undefined ? 'local' : props.location.state.region,
      description:
        props.location.state.description === undefined ? null : props.location.state.description,
      showQRCode:
        props.location.state.key === undefined
          ? null
          : props.location.state.showQRCode === undefined
          ? null
          : props.location.state.showQRCode,
      title: props.location.state.title === undefined ? null : props.location.state.title,
      autoValidate:
        props.location.state?.autoValidate === undefined
          ? 'estabelecimento'
          : props.location.state?.autoValidate
          ? 'cliente'
          : 'estabelecimento',
      isPublished:
        props.location.state.isPublished === undefined ? false : props.location.state.isPublished,
      enableDate,
      disableDate,
      buttonLabel:
        props.location.state.buttonLabel === undefined ? '' : props.location.state.buttonLabel,
      buttonDescription:
        props.location.state.buttonDescription === undefined
          ? ''
          : props.location.state.buttonDescription,
      showButtonDescription:
        props.location.state.showButtonDescription === undefined
          ? false
          : props.location.state.showButtonDescription,
      goToPlaceLabel:
        props.location.state.goToPlaceLabel === undefined
          ? ''
          : props.location.state.goToPlaceLabel,
      showGoToPlace:
        props.location.state.showGoToPlace === undefined
          ? false
          : props.location.state.showGoToPlace,
      //se consumo for posterior ==>showQRCode: null
      //se consumo for imediato showQRCode: true; qntSimultanea:1
      consumo:
        props.location.state.showQRCode === undefined || props.location.state.showQRCode === null
          ? 'posterior'
          : 'imediato',
      hasVoucher:
        props.location.state.hasVoucher === undefined ? null : props.location.state.hasVoucher,

      keywordsSetConsumption: props.location.state.keyWordsSetConsumption || null,
      keywords: '',
      keywordsDownload: null,
      keywordsSetConsumptionList: [],
      messageBlockedKw: props.location.state.message || null,
      keywordsList:
        props.location.state.keyWordsSet === undefined ? [] : props.location.state.keyWordsSet,
      tags: props.location.state.tags === undefined ? [] : props.location.state.tags,

      storeThumbnail:
        props.location.state.thumbnail === undefined ? null : props.location.state.thumbnail,
      storePhoto: props.location.state.photo === undefined ? null : props.location.state.photo,

      loadedStoreThumbnail:
        props.location.state.downloadedThumb === undefined
          ? null
          : props.location.state.downloadedThumb,
      loadedStorePhoto: props.location.state.photo === undefined ? null : placeholder,

      oldStoreThumbnail: null,
      oldStorePhoto: null,

      fileStoreThumbnail: null,
      fileStorePhoto: null,

      //agrupamento
      idGroup: 'newGroup',
      state: 'ongoing',
      created: null,
      groupName: null,
      plural: null,
      singular: null,
      groupTitle: null,
      groupDetailsTitle: null,
      groupDescription: null,
      groupDetailsDescription: null,
      groupType: 'limitada',
      counterRoof: 0,

      foto: null,
      thumbnail: null,

      loadedFoto: null,
      loadedThumbnail: null,

      oldFoto: null,
      oldThumbnail: null,

      equalImageThumbnailFile: null,
      equalImagePhotoFile: null,

      fileFoto: null,
      fileThumbnail: null,

      //Botões de adicionar imagem
      disableButtonImageCarteira: false,
      disableButtonImagePremio: false,

      //states de validação local
      oldGroup: null,
      templatesGroup: null,
      equalImageThumbnail: false,
      equalImagePhoto: false,
      open: false,
      openModal: false,
      goBack: false,
      indexCrop: 0,
      openAlert: false,
      messageAlert: '',
      expanded: null,

      voucherGroup: [{}],
      chosenGroup: null,
      loading: false,

      oldPhotoList:
        props.location.state.photoList === undefined ? null : props.location.state.photoList,
      photoListFile: null,
      loadedPhotoList: null,

      unlimitedDisableDate:
        Math.abs(Date.parse(disableDate) - Date.parse(enableDate)) <= 1000 * 60 * 60 * 24 * 365 * 5
          ? false
          : true,

      tagCapture: '',
      disableButtonUpdate: true,
    };

    if (props.location.state.photoList !== undefined) {
      let photoListFile = [];

      Object.keys(props.location.state.photoList).map((item, i) =>
        downloadImage('promocao', props.location.state.photoList[i]).then((downloadedImage) => {
          photoListFile[i] = downloadedImage;
          this.setState({ photoListFile });
        })
      );
    }

    const folderThumb =
      props.location.state.type === 'product' && props.location.state.thumbnail !== undefined
        ? props.location.state.thumbnail.split('/')[0]
        : 'promocao';

    const thumbImg =
      props.location.state.type === 'product' && props.location.state.thumbnail !== undefined
        ? props.location.state.thumbnail.split('/')[1]
        : props.location.state.thumbnail;

    const folderPhoto =
      props.location.state.type === 'product' && props.location.state.photo !== undefined
        ? props.location.state.photo.split('/')[0]
        : 'promocao';

    const photoImg =
      props.location.state.type === 'product' && props.location.state.photo !== undefined
        ? props.location.state.photo.split('/')[1]
        : props.location.state.photo;

    if (
      props.location.state.thumbnail !== undefined &&
      this.state.loadedStoreThumbnail === placeholderThumbnail
    ) {
      downloadImage(folderThumb, thumbImg)
        .then((downloadedImage) => {
          this.setState({ loadedStoreThumbnail: downloadedImage });
        })
        .catch(() => {
          // console.log("Foto não encontrada:", props.location.state.thumbnail);
        });
    }

    if (props.location.state.photo !== undefined) {
      downloadImage(folderPhoto, photoImg)
        .then((downloadedImage) => {
          this.setState({ loadedStorePhoto: downloadedImage });
        })
        .catch(() => {
          // console.log("Foto não encontrada:", props.location.state.photo);
        });
    }

    //baixando dados da Voucher-Template-v2, caso seja edição
    if (this.state.idStore !== null) {
      //baixando o Id da Voucher Group
      getVoucherTemplate(props.location.state.templateId)
        .then((dataReceived) => {
          const templates = [];

          //lidando com a informação baixada
          dataReceived.id = props.location.state.templateId;

          let cooldownTemplate = msToTime(dataReceived.cooldown);
          dataReceived.cooldownDays = cooldownTemplate.days;
          dataReceived.cooldownHours = cooldownTemplate.hours;
          dataReceived.cooldownMinutes = cooldownTemplate.minutes;

          dataReceived.hasSimultaneo = dataReceived.qtdSimultaneo === 1 ? false : true;
          dataReceived.nomeEstabelecimento = this.state.nomeEstabelecimento;
          dataReceived.onSendExpiration = dataReceived.onSendExpiration / 86400000 || null;
          dataReceived.disableDateCoupon =
            dataReceived.disableDate || convertIsoToDate(new Date(Date.now() + 86400000 * 365));
          dataReceived.expirationType = dataReceived.expirationType || 'fixed';
          delete dataReceived.disableDate;

          //inserindo no array
          templates.push(dataReceived);
          this.setState({
            // ...dataReceived,
            idGroup: dataReceived.groupId,
            oldGroup: dataReceived.groupId,
            disableDateCoupon:
              dataReceived?.disableDateCoupon ||
              convertIsoToDate(new Date(Date.now() + 86400000 * 365)),
            onSendExpiration: dataReceived?.onSendExpiration || null,
            expirationType: dataReceived?.expirationType || 'fixed',
            templates,
          });

          //baixando os dados da Voucher Group
          getVoucherGroup(dataReceived.result.groupId)
            .then((dataReceived) => {
              if (dataReceived.thumbnail !== undefined) {
                this.setState({ loadedThumbnail: placeholder });
                downloadImage('voucher', dataReceived.thumbnail)
                  .then((downloadedImage) => {
                    this.setState({
                      loadedThumbnail: downloadedImage,
                    });
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }
              if (dataReceived.photo !== undefined) {
                this.setState({ loadedFoto: placeholder });
                downloadImage('voucher', dataReceived.photo)
                  .then((downloadedImage) => {
                    this.setState({
                      loadedFoto: downloadedImage,
                    });
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }

              //baixando os dados do template counter match
              getVoucherTemplate(dataReceived.counterMatch)
                .then((dataReceived) => {
                  if (dataReceived.thumbnail !== undefined) {
                    dataReceived.loadedThumbnail = placeholder;
                    downloadImage('voucher', dataReceived.thumbnail)
                      .then((downloadedImage) => {
                        const templateCounterMatch = this.state.templateCounterMatch;
                        templateCounterMatch.loadedThumbnail = downloadedImage;

                        this.setState({
                          templateCounterMatch,
                        });
                      })
                      .catch(() => {
                        // console.log(
                        //   "Foto não encontrada:",
                        //   dataReceived.thumbnail
                        // );
                      });
                  }

                  if (dataReceived.foto !== undefined) {
                    dataReceived.loadedFoto = placeholder;
                    downloadImage('voucher', dataReceived.foto)
                      .then((downloadedImage) => {
                        const templateCounterMatch = this.state.templateCounterMatch;
                        templateCounterMatch.loadedFoto = downloadedImage;

                        this.setState({
                          templateCounterMatch,
                        });
                      })
                      .catch(() => {
                        // console.log(
                        //   "Foto não encontrada:",
                        //   dataReceived.thumbnail
                        // );
                      });
                  }

                  //
                  dataReceived.hasSimultaneo = dataReceived.qtdSimultaneo === 1 ? false : true;
                  dataReceived.hasDisableDate =
                    dataReceived.disableDate !== undefined && dataReceived.disableDate !== null
                      ? true
                      : false;

                  //convertendo disable date
                  let disableDateSeconds = dataReceived.disableDate / 100 / 36000;

                  disableDateSeconds = disableDateSeconds.toString().split('.');
                  dataReceived.disableDateHours =
                    disableDateSeconds[0] !== undefined &&
                    disableDateSeconds !== null &&
                    isNaN(disableDateSeconds) === false
                      ? parseInt(disableDateSeconds[0], 10)
                      : 0;
                  dataReceived.disableDateMinutes =
                    disableDateSeconds[1] !== undefined &&
                    disableDateSeconds !== null &&
                    isNaN(disableDateSeconds) === false
                      ? parseInt(disableDateSeconds[1], 10)
                      : 0;

                  let disableDateTemplateCounterMatch = msToTime(dataReceived.disableDate);
                  dataReceived.disableDateHours =
                    disableDateTemplateCounterMatch.hours !== undefined &&
                    disableDateTemplateCounterMatch.hours !== null &&
                    isNaN(disableDateTemplateCounterMatch.hours) === false
                      ? parseInt(disableDateTemplateCounterMatch.hours, 10)
                      : 0;
                  dataReceived.disableDateMinutes =
                    disableDateTemplateCounterMatch.minutes !== undefined &&
                    disableDateTemplateCounterMatch.minutes !== null &&
                    isNaN(disableDateTemplateCounterMatch.minutes) === false
                      ? parseInt(disableDateTemplateCounterMatch.minutes, 10)
                      : 0;

                  let cooldownTemplateCounterMatch = msToTime(dataReceived.cooldown);
                  dataReceived.cooldownDays =
                    cooldownTemplateCounterMatch.days !== undefined &&
                    cooldownTemplateCounterMatch.days !== null &&
                    isNaN(cooldownTemplateCounterMatch.days) === false
                      ? parseInt(cooldownTemplateCounterMatch.days, 10)
                      : 0;
                  dataReceived.cooldownHours =
                    cooldownTemplateCounterMatch.hours !== undefined &&
                    cooldownTemplateCounterMatch.hours !== null &&
                    isNaN(cooldownTemplateCounterMatch.hours) === false
                      ? parseInt(cooldownTemplateCounterMatch.hours, 10)
                      : 0;
                  dataReceived.cooldownMinutes =
                    cooldownTemplateCounterMatch.minutes !== undefined &&
                    cooldownTemplateCounterMatch.minutes !== null &&
                    isNaN(cooldownTemplateCounterMatch.minutes) === false
                      ? parseInt(cooldownTemplateCounterMatch.minutes, 10)
                      : 0;
                  dataReceived.nomeEstabelecimento = this.state.nomeEstabelecimento;
                  dataReceived.sendSMS =
                    dataReceived.sendSMS !== undefined ? dataReceived.sendSMS : false;

                  this.setState({
                    templateCounterMatch: {
                      ...this.state.templateCounterMatch,
                      ...dataReceived,
                    },
                  });
                })
                .catch((error) => {
                  // console.log("Erro na data:", error);
                });

              //gravando id do Template Counter Match
              const templateCounterMatch = this.state.templateCounterMatch;
              templateCounterMatch.id = dataReceived.counterMatch;

              this.setState({
                state: dataReceived.state,
                groupName: dataReceived.groupName === undefined ? null : dataReceived.groupName,
                created: dataReceived.created === undefined ? null : dataReceived.created,
                plural: dataReceived.plural === undefined ? null : dataReceived.plural,
                singular: dataReceived.singular === undefined ? null : dataReceived.singular,
                groupTitle: dataReceived.title === undefined ? null : dataReceived.title,
                groupDetailsTitle:
                  dataReceived.detailsTitle === undefined ? null : dataReceived.detailsTitle,
                groupDescription:
                  dataReceived.description === undefined ? null : dataReceived.description,
                groupDetailsDescription:
                  dataReceived.detailsDescription === undefined
                    ? null
                    : dataReceived.detailsDescription,
                groupType:
                  dataReceived.counterMatch === undefined || dataReceived.counterMatch === null
                    ? 'ilimitada'
                    : 'limitada',
                counterRoof:
                  dataReceived.counterRoof === undefined ? null : Number(dataReceived.counterRoof),
                foto: dataReceived.photo === undefined ? null : dataReceived.photo,
                thumbnail: dataReceived.thumbnail === undefined ? null : dataReceived.thumbnail,
                templateCounterMatch,

                templatesGroup: dataReceived.templates,
              });
            })
            .catch((error) => {
              // console.log("Erro na data:", error);
            });
        })
        .catch((error) => {
          // console.log("Erro na data:", error);
        });
    }

    //baixando grupos de cupom
    getDataListRestApi('Voucher-Group/')
      .then((dataReceived) => {
        this.setState({ voucherGroup: dataReceived });
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });

    window.pathname = null;
  }

  removeImage = (idx) => {
    if (this.state.photoListFile && idx < this.state.photoListFile.length) {
      let newPhotoList = this.state.photoListFile;
      newPhotoList.splice(idx, 1);
      this.setState({
        photoListFile: newPhotoList,
        disableButtonUpdate: false,
      });
    } else {
      // console.log("Imagem não existe");
    }
  };

  mainImage = (idx) => {
    let currentFoto = this.state.fileStorePhoto
      ? this.state.fileStorePhoto
      : this.state.loadedStorePhoto;
    let newPhotoListFile = this.state.photoListFile;
    let newFoto = this.state.photoListFile[idx];

    newPhotoListFile.splice(idx, 1);
    newPhotoListFile.unshift(currentFoto);

    this.setState({ photoListFile: newPhotoListFile, fileStorePhoto: newFoto });
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    //tratando casos especiais
    //não permitir que qtdSimultaneo seja menor que 1
    if (name === 'qtdSimultaneo') {
      this.setState({
        [name]: event.target.value < 1 ? 1 : event.target.value,
        goBack: true,
        openAlert: event.target.value < 1 ? true : false,
        messageAlert: event.target.value < 1 ? 'Este valor não pode ser menor que 1.' : '',
      });
    } else if (name === 'level') {
      return this.setState({
        [name]:
          event.target.value > 99 || event.target.value < 1 ? this.state.level : event.target.value,
        openAlert: event.target.value > 99 || event.target.value < 1 ? true : false,
        messageAlert:
          event.target.value > 99 || event.target.value < 1
            ? 'O nível da hierarquia deve ser entre 1 e 99.'
            : null,
      });
    } else if (name === 'cooldownHours' || name === 'cooldownMinutes' || name === 'counterRoof') {
      if (name === 'cooldownMinutes' && event.target.value > 59) {
        this.setState({
          [name]: 59,
          goBack: true,
        });
      } else if (name === 'cooldownHours' && event.target.value > 23) {
        this.setState({
          [name]: 23,
          goBack: true,
        });
      } else {
        this.setState({
          [name]: Number(event.target.value) < 0 ? 0 : Number(event.target.value),
          goBack: true,
          openAlert: event.target.value < 0 ? true : false,
          messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
        });
      }
    } else if (event.target.value === 'imediato') {
      this.setState({
        [name]: event.target.value,
        showQRCode: true,
        goBack: true,
        isAdditionalCouponsOptionsVisible: false,
        onSendExpiration: null,
        disableDateCoupon: null,
        expirationType: null,
      });

      //ADICIONAL
    } else if (event.target.value === 'posterior') {
      this.setState({
        [name]: event.target.value,
        goBack: true,
        showQRCode: null,
        isAdditionalCouponsOptionsVisible: this.state.autoValidate === 'estabelecimento' && true,
        disableDateCoupon: convertIsoToDate(new Date(Date.now() + 86400000 * 365)),
        expirationType: 'fixed',
      });
    }

    //casos normais
    else {
      this.setState({
        [name]: event.target.value,
        goBack: true,
        isAdditionalCouponsOptionsVisible:
          name === 'autoValidate' &&
          event.target.value === 'estabelecimento' &&
          +this.state.consumo === 'posterior' &&
          true,
      });
    }
    if (event.target.value === 'validateDays')
      return this.setState({
        disableDateCoupon: null,
      });
  };

  handleChangeTemplate = (index, name) => (event) => {
    let templates = this.state.templates;
    let templateCounterMatch = this.state.templateCounterMatch;

    if (index !== null) {
      if (name === 'cooldownHours' || name === 'cooldownMinutes' || name === 'value') {
        if (name === 'cooldownMinutes' && event.target.value > 59) {
          templates[index][name] = 59;
        } else if (name === 'cooldownHours' && event.target.value > 23) {
          templates[index][name] = 23;
        } else {
          templates[index][name] = event.target.value < 0 ? 0 : event.target.value;

          this.setState({
            openAlert: event.target.value < 0 ? true : false,
            messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
          });
        }
      } else {
        templates[index][name] = event.target.value;
      }
    } else {
      if (name === 'cooldownHours' || name === 'cooldownMinutes' || name === 'value') {
        if (name === 'cooldownMinutes' && event.target.value > 59) {
          templateCounterMatch[name] = 59;
        } else if (name === 'cooldownHours' && event.target.value > 23) {
          templateCounterMatch[name] = 23;
        } else {
          templateCounterMatch[name] = event.target.value < 0 ? 0 : event.target.value;

          this.setState({
            openAlert: event.target.value < 0 ? true : false,
            messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
          });
        }
      } else {
        templateCounterMatch[name] = event.target.value;
      }
    }

    this.setState({
      templates,
      templateCounterMatch,
      goBack: true,
    });
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      goBack: true,
    });

    if (name === 'unlimitedDisableDate') {
      this.setState({
        disableDate: convertIsoToDate(new Date(Date.now() + 86400000 * 365 * 60)),
      });
    }
  };

  handleChangeSwitchImage = (name) => (event) => {
    let { disableButtonImageCarteira, disableButtonImagePremio } = this.state;

    if (name === 'equalImagePhoto') {
      if (disableButtonImageCarteira) {
        disableButtonImageCarteira = false;
      } else {
        disableButtonImageCarteira = true;
      }
    }

    if (name === 'equalImagePhotoCupom') {
      if (disableButtonImagePremio) {
        disableButtonImagePremio = false;
      } else {
        disableButtonImagePremio = true;
      }
    }

    this.setState({ disableButtonImageCarteira, disableButtonImagePremio });
  };

  handleChangeSwitchTemplate = (index, name) => (event) => {
    let templates = this.state.templates;
    let templateCounterMatch = this.state.templateCounterMatch;

    if (index !== null) {
      templates[index][name] = event.target.checked;
    } else {
      templateCounterMatch[name] = event.target.checked;
    }

    this.setState({
      templates,
      templateCounterMatch,
      goBack: true,
    });
  };

  //handle Modal
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  fileChangedHandler = (name, oldName) => (event) => {
    this.setState({ [oldName]: this.state[name] });

    if (event.target.files[0] !== undefined) {
      const data = [URL.createObjectURL(event.target.files[0])];
      this.setState({ imageToCrop: data, imageCroped: name }, this.handleOpenModal);
    }
  };

  photoListChangedHandler = (event) => {
    let listImages = [];

    for (let index = 0; index < event.target.files.length; index++) {
      listImages[index] = URL.createObjectURL(event.target.files[index]);
    }

    if (event.target.files !== undefined) {
      this.setState({
        openModal: true,
        imageToCrop: listImages,
        imageCroped: 'photoListFile',
      });
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;

    if (newCrop.indexOf('.') >= 0) {
      const templateCounterMatch = this.state.templateCounterMatch;
      const splitName = newCrop.split('.')[1];

      templateCounterMatch[splitName] = this[state].crop();

      this.setState({
        templateCounterMatch,
        openModal: false,
        imageToCrop: null,
        goBack: true,
      });
    } else {
      //imagem recebida do crop
      let node = this[state].crop();

      this.setState({
        [newCrop]: node,
        openModal: false,
        imageToCrop: null,
        goBack: true,
      });
    }
  }

  photoListCropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    //verificando se é um array de imagens
    let data = [];
    if (this.state[newCrop] !== null) {
      let a = this.state[newCrop];
      data = a.concat(node);
    } else {
      data = [node];
    }

    this.setState(
      {
        [newCrop]: this.state.imageCroped === 'photoListFile' ? data.slice(0, 4) : node,
        indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
        goBack: true,
        imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
      },
      () => {
        if (endArray === undefined) {
          this.handleCloseModal();
        }
      }
    );
  }

  handleEqualImages = (name) => (event) => {
    if (name === 'templateCounterMatch') {
      let templateCounterMatch = this.state.templateCounterMatch;

      if (event.target.checked === true) {
        templateCounterMatch.equalImagePhotoFile =
          this.state.fileStorePhoto === null
            ? this.state.loadedStorePhoto
            : this.state.fileStorePhoto;
        templateCounterMatch.equalImageThumbnailFile =
          this.state.fileStoreThumbnail === null
            ? this.state.loadedStoreThumbnail
            : this.state.fileStoreThumbnail;
      } else {
        templateCounterMatch.equalImagePhotoFile = null;
        templateCounterMatch.equalImageThumbnailFile = null;
      }

      this.setState({ templateCounterMatch });
    } else {
      if (event.target.checked === true) {
        this.setState({
          equalImageThumbnailFile:
            this.state.fileStoreThumbnail === null
              ? this.state.loadedStoreThumbnail
              : this.state.fileStoreThumbnail,
          equalImagePhotoFile:
            this.state.fileStorePhoto === null
              ? this.state.loadedStorePhoto
              : this.state.fileStorePhoto,
        });
      } else {
        this.setState({
          equalImageThumbnailFile: null,
          equalImagePhotoFile: null,
        });
      }
    }
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleChangePanel = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  //HANDLE ALERT
  goBack = () => {
    if (this.state.goBack === false) {
      window.history.back();
    } else {
      this.handleClickOpen();
    }
  };

  checkInformation = (e) => {
    e.preventDefault();

    const checkInformation =
      (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) ||
      (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) ||
      (this.state.fileThumbnail === null &&
        this.state.loadedThumbnail === null &&
        this.state.equalImageThumbnailFile === null) ||
      (this.state.equalImagePhotoFile === null &&
        this.state.fileFoto === null &&
        this.state.loadedFoto === null) ||
      (this.state.templateCounterMatch.equalImageThumbnailFile === null &&
        this.state.templateCounterMatch.fileThumbnail === null &&
        this.state.templateCounterMatch.loadedThumbnail === null) ||
      (this.state.templateCounterMatch.equalImagePhotoFile === null &&
        this.state.templateCounterMatch.fileFoto === null &&
        this.state.templateCounterMatch.loadedFoto === null) ||
      this.state.templatesLoaded === null;

    if (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail.',
      });
    } else if (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar ao menos uma imagem na lista de fotos.',
      });
    } else if (
      this.state.loadedFoto === null &&
      this.state.fileFoto === null &&
      this.state.equalImagePhotoFile === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de detalhe para o cupom.',
      });
    } else if (
      this.state.idGroup &&
      typeof this.state.idGroup === 'string' &&
      this.state.idGroup.toLowerCase() !== 'newgroup'
    ) {
      if (
        this.state.loadedThumbnail === null &&
        this.state.fileThumbnail === null &&
        this.state.equalImageThumbnailFile === null
      ) {
        this.setState({
          openAlert: true,
          messageAlert: 'É necessário adicionar uma imagem de thumbnail para o cupom.',
        });
      } else if (
        this.state.templateCounterMatch.equalImageThumbnailFile === null &&
        this.state.templateCounterMatch.fileThumbnail === null &&
        this.state.templateCounterMatch.loadedThumbnail === null
      ) {
        this.setState({
          openAlert: true,
          messageAlert: 'É necessário adicionar uma imagem de thumbnail para o cupom do prêmio.',
        });
      } else if (
        this.state.templateCounterMatch.equalImagePhotoFile === null &&
        this.state.templateCounterMatch.fileFoto === null &&
        this.state.templateCounterMatch.loadedFoto === null
      ) {
        this.setState({
          openAlert: true,
          messageAlert: 'É necessário adicionar uma imagem de detalhe para o cupom do prêmio.',
        });
      } else if (checkInformation === true) {
        this.setState({
          openAlert: true,
          messageAlert: 'Todas as informações obrigatórias devem estar preenchidas.',
        });
      }
    } else {
      this.setState({ loading: true });

      let auxTemplates = this.state.templates;
      this.state.templates.map((item, index) => {
        auxTemplates[index]['cooldownHours'] =
          parseInt(this.state.templates[index]['cooldownHours'], 10) +
          24 * parseInt(this.state.templates[index]['cooldownDays'], 10);

        return (auxTemplates[index]['cooldownDays'] = null);
      });

      let auxTemplateCounterMatch = this.state.templateCounterMatch;
      auxTemplateCounterMatch['cooldownHours'] =
        parseInt(this.state.templateCounterMatch['cooldownHours'], 10) +
        24 * parseInt(this.state.templateCounterMatch['cooldownDays'], 10);
      auxTemplateCounterMatch['cooldownDays'] = null;

      this.props.updateStoreGroupInformationComponent(
        this.state.idStore,
        this.state.active,
        this.state.level,
        this.state.region,
        this.state.description,
        this.state.keywordsSetConsumptionList,
        this.state.messageBlockedKw,
        this.state.showQRCode,
        this.state.title,
        this.state.autoValidate,
        this.state.hasVoucher,
        this.state.enableDate,
        this.state.disableDate,
        this.state.isPublished,
        this.state.keywordsList,
        this.state.tags,
        this.state.fileStoreThumbnail,
        this.state.oldStoreThumbnail,
        this.state.storeThumbnail,
        this.state.fileStorePhoto,
        this.state.oldStorePhoto,
        this.state.storePhoto,
        this.state.idEstabelecimento,
        this.state.idGestor,
        this.state.nomeEstabelecimento,
        this.state.lat,
        this.state.long,
        this.state.address,
        this.state.neighborhood,

        this.state.buttonLabel,
        this.state.buttonDescription,
        this.state.showButtonDescription,
        this.state.goToPlaceLabel,
        this.state.showGoToPlace,

        this.state.idGroup,
        this.state.state,
        this.state.created,
        this.state.groupName,
        this.state.plural,
        this.state.singular,
        this.state.groupTitle,
        this.state.groupDetailsTitle,
        this.state.groupDescription,
        this.state.groupDetailsDescription,
        this.state.groupType,
        Number(this.state.counterRoof),
        this.state.equalImageThumbnailFile,
        this.state.equalImagePhotoFile,
        this.state.fileThumbnail,
        this.state.oldThumbnail,
        this.state.thumbnail,
        this.state.fileFoto,
        this.state.oldFoto,
        this.state.foto,
        this.state.oldGroup,
        this.state.templatesGroup,

        auxTemplates,
        auxTemplateCounterMatch,
        this.state.oldPhotoList,
        this.state.photoListFile,
        this.state.messageApproval,
        this.state.newStatus,
        this.state.expirationType,
        this.state.onSendExpiration,
        this.state.disableDateCoupon
      );
    }
  };

  //HANDLE DIALOGS
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  //handle alerts
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleChangeGroup = (name) => (event) => {
    if (event.target.value === 'newGroup') {
      this.setState({
        idGroup: 'newGroup',
        state: 'ongoing',
        created: null,
        groupName: null,
        plural: null,
        singular: null,
        groupTitle: null,
        groupDetailsTitle: null,
        groupDescription: null,
        groupDetailsDescription: null,
        counterRoof: 0,
        groupType: 'limitada',
        foto: null,
        thumbnail: null,

        loadedFoto: null,
        loadedThumbnail: null,

        oldFoto: null,
        oldThumbnail: null,

        equalImageThumbnailFile: null,
        equalImagePhotoFile: null,

        fileFoto: null,
        fileThumbnail: null,

        equalImageThumbnail: false,
        equalImagePhoto: false,

        [name]: null,
        goBack: true,
      });
      const templateCounterMatch = {
        id: null,
        titulo: null,
        detailsTitle: null,
        descricao: null,
        detailsDescription: null,
        plural: null,
        singular: null,
        consumoUnico: false,
        cooldown: null,
        cooldownHours: 0,
        cooldownMinutes: 0,
        dataGeracao: null,
        generator: false,
        nomeTemplate: null,
        qtdDisponivel: null,
        hasSimultaneo: false,
        qtdSimultaneo: 1,
        type: 'store',
        equalImagePhotoFile: null,
        equalImageThumbnailFile: null,
        equalImagePhoto: false,
        equalImageThumbnail: false,
        thumbnail: null,
        foto: null,
        loadedThumbnail: null,
        loadedFoto: null,
        oldThumbnail: null,
        oldPhoto: null,
        fileFoto: null,
        fileThumbnail: null,
        sendSMS: false,
      };

      this.setState({ templateCounterMatch });
    } else {
      const chosenGroup = this.state.voucherGroup[event.target.value];

      this.setState({
        oldGroup: this.state.idGroup !== event.target.value ? this.state.idGroup : null,
      });

      this.setState({
        idGroup: event.target.value,
        state: chosenGroup.state,
        created: chosenGroup.created,
        groupName: chosenGroup.groupName,
        plural: chosenGroup.plural,
        singular: chosenGroup.singular,
        groupTitle: chosenGroup.title,
        groupDetailsTitle: chosenGroup.detailsTitle,
        groupDescription: chosenGroup.description,
        groupDetailsDescription: chosenGroup.detailsDescription,
        counterRoof: chosenGroup.counterRoof !== undefined ? Number(chosenGroup.counterRoof) : 0,
        groupType:
          chosenGroup.counterMatch === undefined && chosenGroup.counterRoof === undefined
            ? 'ilimitada'
            : 'limitada',
        foto: chosenGroup.photo,
        thumbnail: chosenGroup.thumbnail,

        loadedThumbnail: chosenGroup.thumbnail !== undefined ? placeholder : null,
        loadedFoto: chosenGroup.photo !== undefined ? placeholder : null,

        oldFoto: null,
        oldThumbnail: null,

        equalImageThumbnailFile: null,
        equalImagePhotoFile: null,

        fileFoto: null,
        fileThumbnail: null,

        equalImageThumbnail: false,
        equalImagePhoto: false,

        [name]: event.target.value,
        goBack: true,
      });

      if (chosenGroup.thumbnail !== undefined) {
        downloadImage('voucher', chosenGroup.thumbnail)
          .then((downloadedImage) => {
            this.setState({ loadedThumbnail: downloadedImage });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", chosenGroup.thumbnail);
          });
      }

      if (chosenGroup.photo !== undefined) {
        downloadImage('voucher', chosenGroup.photo)
          .then((downloadedImage) => {
            this.setState({ loadedFoto: downloadedImage });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", chosenGroup.photo);
          });
      }

      this.downloadTemplateCounterMatch(chosenGroup.counterMatch);
    }
  };

  downloadTemplateCounterMatch = (counterMatchId) => {
    getVoucherTemplate(counterMatchId)
      .then((dataReceived) => {
        dataReceived.id = counterMatchId;
        dataReceived.fileFoto = null;
        dataReceived.fileThumbnail = null;
        dataReceived.equalImagePhoto = false;
        dataReceived.equalImagePhotoFile = null;
        dataReceived.equalImageThumbnail = false;
        dataReceived.equalImageThumbnailFile = null;
        dataReceived.loadedThumbnail = dataReceived.thumbnail !== undefined ? placeholder : null;
        dataReceived.loadedFoto = dataReceived.photo !== undefined ? placeholder : null;

        dataReceived.generator =
          dataReceived.generator === undefined ? false : dataReceived.generator;

        let seconds = dataReceived.cooldown / 100 / 36000;
        seconds = seconds.toString().split('.');
        dataReceived.cooldownHours = seconds[0] !== undefined ? parseInt(seconds[0], 10) : 0;
        dataReceived.cooldownMinutes = seconds[1] !== undefined ? parseInt(seconds[1], 10) : 0;

        dataReceived.hasSimultaneo = dataReceived.qtdSimultaneo === 1 ? false : true;

        this.setState({
          templateCounterMatch: dataReceived,
        });

        if (dataReceived.thumbnail !== undefined) {
          downloadImage('voucher', dataReceived.thumbnail)
            .then((downloadedImage) => {
              const templateCounterMatch = this.state.counterMatch;
              templateCounterMatch.loadedThumbnail = downloadedImage;
              this.setState({ templateCounterMatch });
            })
            .catch(() => {
              // console.log("Foto não encontrada:", dataReceived.thumbnail);
            });
        }

        if (dataReceived.photo !== undefined) {
          downloadImage('voucher', dataReceived.photo)
            .then((downloadedImage) => {
              const templateCounterMatch = this.state.counterMatch;
              templateCounterMatch.loadedFoto = downloadedImage;
              this.setState({ templateCounterMatch });
            })
            .catch(() => {
              // console.log("Foto não encontrada:", dataReceived.photo);
            });
        }
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  };

  //handle downshift

  handleChangeDownshiftConsume = (item) => {
    let { keywordsSetConsumptionList } = this.state;

    if (keywordsSetConsumptionList.indexOf(item) === -1) {
      keywordsSetConsumptionList = [...keywordsSetConsumptionList, item];
    }

    this.setState({
      keywordsSetConsumption: '',
      keywordsSetConsumptionList,
    });
  };

  handleDeleteDownshiftConsume = (item) => () => {
    this.setState((state) => {
      const keywordsSetConsumptionList = [...state.keywordsSetConsumptionList];
      keywordsSetConsumptionList.splice(keywordsSetConsumptionList.indexOf(item), 1);
      return { keywordsSetConsumptionList };
    });
  };

  handleChangeDownshift = (item) => {
    let { keywordsList } = this.state;

    if (keywordsList.indexOf(item) === -1) {
      keywordsList = [...keywordsList, item];
    }

    this.setState({
      keywords: '',
      keywordsList,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keywordsList = [...state.keywordsList];
      keywordsList.splice(keywordsList.indexOf(item), 1);
      return { keywordsList };
    });
  };
  //fim handle downshift

  handleChangeRadio = (event) => {
    this.setState({
      expirationType: event.target.value,
    });

    if (event.target.name === 'validateDays') {
      this.setState({
        disableDateCoupon: null,
        onSendExpiration: 1,
      });
    }
    if (event.target.name === 'disableDate') {
      this.setState({
        disableDateCoupon: convertIsoToDate(new Date(Date.now() + 86400000 * 365)),
      });
    }
  };

  handleSwitchSMS = (event) => {
    this.setState({
      sendSMS: event.target.checked,
    });
  };

  handleTagCapture = (e) => {
    this.setState({ tagCapture: e.target.value });
  };

  handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handlePushTag();
    }
  };

  handlePushTag = (e) => {
    let tagHolder = this.state.tags;
    if (
      tagHolder.indexOf(this.state.tagCapture.toLocaleLowerCase()) === -1 &&
      this.state.tagCapture.length !== 0
    ) {
      tagHolder.push(this.state.tagCapture.toLowerCase());
    }
    this.setState({
      tags: [...tagHolder],
      tagCapture: '',
      disableButtonUpdate: false,
    });
  };

  componentDidMount() {
    getDataListRestApi('Keywords', 'StoreGroupInformation')
      .then((dataReceived) => {
        let keywordsDownload = [];

        Object.values(dataReceived).map(function (item) {
          return keywordsDownload.push({ label: item.value });
        });

        suggestions = keywordsDownload;
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  }

  copyTemplateIdToClipboard = () => {
    navigator.clipboard.writeText(this.props.location.state.templateId);
    this.setState({ copyTemplateMessage: 'ID copiado' });
  };

  render = () => {
    const { classes } = this.props;
    const keywords = this.state.keywords;
    const keywordsConsume = this.state.keywordsSetConsumption;
    const selectedItem = this.state.keywordsList;
    const selectedItemConsume = this.state.keywordsSetConsumptionList;

    let fotos = [];
    for (let i = 0; i < 4; i++) {
      let exists =
        (this.state.photoListFile && this.state.photoListFile[i]) ||
        (this.state.loadedPhotoList && this.state.loadedPhotoList[i]);
      fotos.push(
        <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
          <StyledBadge
            badgeContent={
              exists ? (
                <Tooltip title="Excluir">
                  <Close
                    style={{ width: '100%', height: '100%' }}
                    onClick={() => this.removeImage(i)}
                  />
                </Tooltip>
              ) : (
                ''
              )
            }
            invisible={!exists}
          >
            <Tooltip title={exists ? 'Tornar principal' : 'Utilize o botão "Adicionar fotos"'}>
              <img
                style={{ cursor: exists ? 'pointer' : 'default' }}
                onClick={exists ? () => this.mainImage(i) : null}
                src={
                  this.state.photoListFile && this.state.photoListFile[i]
                    ? this.state.photoListFile[i]
                    : this.state.loadedPhotoList && this.state.loadedPhotoList[i]
                    ? this.state.loadedPhotoList[i]
                    : placeholder
                }
                width="100%"
                height="100%"
                alt="Lista de Fotos"
              />
            </Tooltip>
          </StyledBadge>
        </Grid>
      );
    }

    if (this.props.loading && this.state.loading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    } else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      } else {
        return (
          <div
            style={{
              maxHeight: '100%',
              overflowY: 'auto',
              padding: '40px 25px',
            }}
          >
            <form name="validatorForm" onSubmit={this.checkInformation}>
              <FormGroup name="1f">
                {this.state.idStore === null ? (
                  <Typography
                    variant="h5"
                    gutterBottom
                    color="primary"
                    style={{ fontWeight: '700', fontSize: '25px' }}
                  >
                    Cadastro da oferta
                  </Typography>
                ) : (
                  <EditShowId text="Editar Oferta" id={this.state.idStore} />
                )}

                <TextField
                  className="input-field"
                  type="text"
                  value={this.state.title === null ? '' : this.state.title}
                  id="title"
                  label="Título da Oferta"
                  onChange={this.handleChange('title')}
                  required={true}
                  name="1t"
                />

                <TextField
                  className="input-field"
                  multiline
                  type="text"
                  value={this.state.description === null ? '' : this.state.description}
                  id="description"
                  name="2t"
                  required={true}
                  label="Descrição da Oferta"
                  onChange={this.handleChange('description')}
                />

                <TextField
                  style={{ width: '300px' }}
                  className="input-field"
                  onChange={this.handleChange('level')}
                  value={this.state.level}
                  type="number"
                  id="level"
                  required={this.state.isPublished}
                  name="3t"
                  label="Hierarquia da Oferta"
                />
                <br />

                <Downshift
                  id="downshift-multiple"
                  inputValue={keywords}
                  onChange={this.handleChangeDownshift}
                  selectedItem={selectedItem}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue: inputValue2,
                    selectedItem: selectedItem2,
                    highlightedIndex,
                  }) => (
                    <div className={classes.container}>
                      {renderInput({
                        fullWidth: true,
                        classes,
                        InputProps: getInputProps({
                          startAdornment: selectedItem.map((item) => (
                            <Chip
                              key={item}
                              tabIndex={-1}
                              label={item}
                              className={classes.chip}
                              onDelete={this.handleDeleteDownshift(item)}
                            />
                          )),
                          onChange: this.handleChange('keywords'),
                          onKeyDown: this.handleKeyDown,
                          placeholder: 'Selecione a keyword na lista abaixo',
                        }),
                        label: 'Keywords',
                      })}
                      {isOpen ? (
                        <Paper className={classes.paper} square>
                          {getSuggestions(inputValue2).map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({
                                item: suggestion.label,
                              }),
                              highlightedIndex,
                              selectedItem: selectedItem2,
                            })
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  )}
                </Downshift>

                <div style={{ paddingTop: '30px' }}>
                  <FormLabel name="2f">Tags da Oferta*</FormLabel>
                  <div>
                    <S.TagsContainer>
                      <S.TagsAddContainer>
                        <D.TriiboTextField
                          rows={1}
                          placeholder={'Insira o tipo e aperte enter'}
                          value={this.state.tagCapture}
                          onChange={(e) => {
                            this.handleTagCapture(e);
                          }}
                          onKeyDown={(e) => {
                            this.handlePressEnter(e);
                          }}
                        />
                        <S.TagsAddButton
                          blue={true}
                          onClick={(e) => {
                            this.handlePushTag(e);
                          }}
                        >
                          Adicionar tag
                        </S.TagsAddButton>
                      </S.TagsAddContainer>
                      <D.TriiboTagsField>
                        <D.TriiboTagsPlaceholder
                          visibility={this.state.tags.length === 0 ? false : true}
                        >
                          Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                        </D.TriiboTagsPlaceholder>
                        {this.state.tags.length > 0 &&
                          this.state.tags.map((body, index) => (
                            <>
                              <D.UserTags key={index}>
                                <D.TagText>{body}</D.TagText>
                                <D.TagDelete
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let newArr = this.state.tags;
                                    newArr.splice(index, 1);
                                    this.setState({
                                      tags: [...newArr],
                                    });
                                  }}
                                >
                                  {<BsXLg style={{ color: 'white' }} />}
                                </D.TagDelete>
                              </D.UserTags>
                            </>
                          ))}
                      </D.TriiboTagsField>
                    </S.TagsContainer>
                  </div>
                </div>

                <br />
                <br />

                <Typography
                  style={{
                    marginTop: '10px',
                    marginBottom: '-15px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Fotos da promoção
                </Typography>
                <br />
                <br />

                <div className="flex-external">
                  <div
                    className="img-thumb"
                    style={{
                      maxWidth: '400px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: '550', textAlign: 'center' }}
                    >
                      Thumbnail (aparecerá em "Lista de Promoções")
                    </Typography>

                    <img
                      src={thumbnailPromocao}
                      alt="Thumbnail"
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        maxHeight: '447px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    />

                    <img
                      className="img-promocao-fidelidade1"
                      alt="thumbnail"
                      src={
                        this.state.fileStoreThumbnail
                          ? this.state.fileStoreThumbnail
                          : this.state.loadedStoreThumbnail
                          ? this.state.loadedStoreThumbnail
                          : placeholder
                      }
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        value=""
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={this.fileChangedHandler('fileStoreThumbnail', 'storeThumbnail')}
                        id="thumbnail"
                      />

                      <label htmlFor="thumbnail">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            marginTop: '15px',
                            fontWeight: '550',
                            marginLeft: '20px',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          startIcon={<AddPhotoAlternate color="#fff" />}
                        >
                          Adicionar Foto
                        </Button>

                        <Tooltip title="As imagens devem ser 4x3.">
                          <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                        </Tooltip>
                      </label>
                    </div>
                  </div>

                  {/* Lista de fotos */}
                  <div>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      className="style-text"
                      style={{ fontWeight: '550' }}
                    >
                      Lista de Fotos (aparecerá em "Detalhe da Promoção")
                    </Typography>

                    <section className="grid-style">
                      <div
                        className="Foto-detalhe"
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <img
                          src={listaFotos}
                          alt="thumbnail"
                          style={{
                            width: '100%',
                            maxWidth: '392px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <StyledBadge2
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%',
                          }}
                          invisible={
                            (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                            (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                              ? false
                              : true
                          }
                          badgeContent={
                            <Tooltip
                              title={
                                (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                                (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                                  ? 'Para trocar clique em uma imagem ao lado'
                                  : 'Utilize o botão "Adicionar fotos"'
                              }
                            >
                              <Help color="secondary" style={{ fontSize: 18 }} />
                            </Tooltip>
                          }
                          color="primary"
                        >
                          <img
                            alt="foto da promoção"
                            src={
                              this.state.fileStorePhoto
                                ? this.state.fileStorePhoto
                                : this.state.loadedStorePhoto
                                ? this.state.loadedStorePhoto
                                : placeholder
                            }
                            width="100%"
                            style={{
                              maxWidth: '393px',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                            }}
                          />
                        </StyledBadge2>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {this.state.loadedStorePhoto || this.state.fileStorePhoto ? (
                            <div>
                              <input
                                value=""
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={this.photoListChangedHandler}
                                id="listaFotos"
                              />

                              <label htmlFor="listaFotos">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={{
                                    marginTop: '15px',
                                    fontWeight: '550',
                                    color: '#fff',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={<AddPhotoAlternate color="#fff" />}
                                >
                                  Adicionar Foto na Lista
                                </Button>

                                <Tooltip
                                  title="As imagens devem ser 4x3."
                                  style={{ marginTop: '36px' }}
                                >
                                  <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                                </Tooltip>
                              </label>
                            </div>
                          ) : (
                            <div>
                              <input
                                value=""
                                id="photoStore"
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={this.fileChangedHandler('fileStorePhoto', 'storePhoto')}
                              />
                              <label htmlFor="photoStore">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={{
                                    marginTop: '15px',
                                    fontWeight: '550',
                                    color: '#fff',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={<AddPhotoAlternate color="#fff" />}
                                >
                                  Adicionar Foto De Detalhe
                                </Button>
                              </label>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="lista-detalhes" id="photoList">
                        {fotos}
                      </div>
                    </section>
                  </div>
                </div>

                <FormLabel style={{ marginTop: '20px' }} component="legend">
                  Processo de Validação
                </FormLabel>

                <RadioGroup
                  aria-label="autoValidate"
                  name="autoValidate"
                  value={this.state.autoValidate}
                  onChange={this.handleChange('autoValidate')}
                >
                  <FormControlLabel
                    value="estabelecimento"
                    control={<Radio color="primary" />}
                    label="Celular do Estabelecimento"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="cliente"
                    control={<Radio color="primary" />}
                    label="Celular do Cliente"
                    labelPlacement="end"
                  />
                </RadioGroup>

                <FormLabel style={{ marginTop: '20px' }} component="legend">
                  Selecione a região da promoção (ordenação na lista)
                </FormLabel>
                <FormControl className="input-field">
                  <Select
                    className="input-field"
                    value={this.state.region}
                    onChange={this.handleChange('region')}
                    displayEmpty
                    name="region"
                  >
                    <MenuItem value="regional">Regional</MenuItem>
                    <MenuItem value="national">Nacional</MenuItem>
                    <MenuItem value="local">Local</MenuItem>
                  </Select>
                </FormControl>

                <FormLabel style={{ marginTop: '20px' }} component="legend">
                  Tipo de Consumo
                </FormLabel>
                <RadioGroup
                  aria-label="consumo"
                  name="consumo"
                  value={this.state.consumo}
                  onChange={this.handleChange('consumo')}
                >
                  <FormControlLabel
                    value="imediato"
                    control={<Radio color="primary" />}
                    label="Imediato"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="posterior"
                    control={<Radio color="primary" />}
                    label="Posterior"
                    labelPlacement="end"
                  />
                </RadioGroup>
                <FormLabel style={{ marginTop: '20px', marginBottom: '10px' }} component="legend">
                  Validade do cupom
                </FormLabel>
                <RadioGroup
                  aria-label="validade do cupom"
                  name="validateCuopon"
                  value={this.state.expirationType}
                  onChange={this.handleChangeRadio}
                >
                  <FormControlLabel
                    name="disableDate"
                    value="fixed"
                    control={<Radio color="primary" />}
                    label="Data de desativação"
                    disabled={this.state.consumo === 'imediato'}
                  />
                  <FormControlLabel
                    name="validateDays"
                    value="onSend"
                    control={<Radio color="primary" />}
                    label="Validade em dias (após recebimento na carteira)"
                    disabled={this.state.consumo === 'imediato'}
                  />
                </RadioGroup>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      id="validateDate"
                      label="Data de desativação"
                      type="datetime-local"
                      value={
                        this.state.disableDateCoupon === null
                          ? ''
                          : convertToDate(this.state.disableDateCoupon)
                      }
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange('disableDateCoupon')}
                      // required={this.state.isPublished}
                      disabled={
                        this.state.expirationType === 'onSend' || this.state.consumo === 'imediato'
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="validateDays"
                      label="Validade em dias"
                      type="number"
                      style={{ width: '75%' }}
                      InputProps={{ inputProps: { min: 1, max: 365 } }}
                      onInput={(e) =>
                        (e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3))
                      }
                      value={this.state.onSendExpiration}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange('onSendExpiration')}
                      // required={this.state.isPublished}
                      disabled={
                        this.state.expirationType === 'fixed' || this.state.consumo === 'imediato'
                      }
                    />
                  </Grid>
                </Grid>
                <Typography style={{ marginTop: '20px' }} variant="h5" gutterBottom color="primary">
                  Agrupamento da Oferta
                </Typography>

                <FormControl className="input-field">
                  <FormLabel style={{ marginTop: '20px' }} component="legend">
                    Selecione o grupo a da Oferta
                  </FormLabel>

                  <Select
                    className="input-field"
                    value={this.state.idGroup}
                    onChange={this.handleChangeGroup()}
                    displayEmpty
                    name="idGroup"
                    required
                  >
                    <MenuItem key="newGroup" value="newGroup">
                      Criar Novo Grupo
                    </MenuItem>
                    {Object.keys(this.state.voucherGroup).map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {this.state.voucherGroup[item].groupName}
                      </MenuItem>
                    ))}
                  </Select>
                  <br />

                  <TextField
                    className="input-field"
                    value={this.state.groupName === null ? '' : this.state.groupName}
                    onChange={this.handleChange('groupName')}
                    type="text"
                    id="groupName"
                    required={this.state.isPublished}
                    name="4t"
                    label="Nome do Grupo"
                  />

                  <TextField
                    className="input-field"
                    value={this.state.singular === null ? '' : this.state.singular}
                    onChange={this.handleChange('singular')}
                    type="text"
                    id="singular"
                    required={this.state.isPublished}
                    name="5t"
                    label="Objeto da Carteira no singular"
                  />

                  <TextField
                    className="input-field"
                    value={this.state.plural === null ? '' : this.state.plural}
                    onChange={this.handleChange('plural')}
                    type="text"
                    id="plural"
                    required={this.state.isPublished}
                    name="6t"
                    label="Objeto da Carteira no plural"
                  />

                  <TextField
                    className="input-field"
                    value={this.state.groupTitle === null ? '' : this.state.groupTitle}
                    type="text"
                    id="groupTitle"
                    required={this.state.isPublished}
                    name="7t"
                    label="Título"
                    onChange={this.handleChange('groupTitle')}
                  />

                  <TextField
                    className="input-field"
                    value={
                      this.state.groupDetailsTitle === null ? '' : this.state.groupDetailsTitle
                    }
                    onChange={this.handleChange('groupDetailsTitle')}
                    type="text"
                    id="groupDetailsTitle"
                    label="Detalhe do Título"
                  />

                  <TextField
                    className="input-field"
                    value={this.state.groupDescription === null ? '' : this.state.groupDescription}
                    multiline
                    onChange={this.handleChange('groupDescription')}
                    type="text"
                    id="groupDescription"
                    required={this.state.isPublished}
                    name="8t"
                    label="Descrição"
                  />

                  <TextField
                    className="input-field"
                    value={
                      this.state.groupDetailsDescription === null
                        ? ''
                        : this.state.groupDetailsDescription
                    }
                    multiline
                    onChange={this.handleChange('groupDetailsDescription')}
                    type="text"
                    id="detailsDescription"
                    label="Detalhe da Descrição"
                  />
                  <br />

                  <Downshift
                    id="downshift-multiple"
                    inputValue={keywordsConsume}
                    onChange={this.handleChangeDownshiftConsume}
                    selectedItem={selectedItemConsume}
                  >
                    {({
                      getInputProps,
                      getItemProps,
                      isOpen,
                      inputValue: inputValue3,
                      selectedItem: selectedItem3,
                      highlightedIndex,
                    }) => (
                      <div className={classes.container}>
                        {renderInput({
                          fullWidth: true,
                          classes,
                          InputProps: getInputProps({
                            startAdornment: selectedItemConsume.map((item) => (
                              <Chip
                                key={item}
                                tabIndex={-1}
                                label={item}
                                className={classes.chip}
                                onDelete={this.handleDeleteDownshiftConsume(item)}
                              />
                            )),
                            onChange: this.handleChange('keywordsSetConsumption'),
                            onKeyDown: this.handleKeyDown,
                            placeholder: 'Selecione as keywords de consumo',
                          }),
                          label: 'Keywords de consumo',
                        })}
                        {isOpen ? (
                          <Paper className={classes.paper} square>
                            {getSuggestions(inputValue3).map((suggestion, index) =>
                              renderSuggestion({
                                suggestion,
                                index,
                                itemProps: getItemProps({
                                  item: suggestion.label,
                                }),
                                highlightedIndex,
                                selectedItem: selectedItem3,
                              })
                            )}
                          </Paper>
                        ) : null}
                      </div>
                    )}
                  </Downshift>

                  <TextField
                    style={{ width: '100%' }}
                    placeholder="Você não tem essa permissão !"
                    className="input-field"
                    disabled={this.state.keywordsSetConsumptionList.length <= 0}
                    value={this.state.messageBlockedKw === null ? '' : this.state.messageBlockedKw}
                    multiline
                    onChange={this.handleChange('messageBlockedKw')}
                    type="text"
                    id="messageBlockedKw"
                    required={this.state.keywordsSetConsumptionList}
                    label="Mensagem de bloqueio por keyword"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.disableButtonImageCarteira}
                        onChange={this.handleChangeSwitchImage('equalImagePhoto')}
                        color="primary"
                        onClick={this.handleEqualImages('equalImagePhotoFile')}
                      />
                    }
                    label={'Utilizar as mesmas imagens da promoção'}
                  />

                  <br />
                  {!this.state.disableButtonImageCarteira && (
                    <Typography
                      style={{
                        marginTop: '10px',
                        marginBottom: '-15px',
                        fontWeight: '700',
                        fontSize: '20px',
                      }}
                      variant="h5"
                      gutterBottom
                      color="primary"
                    >
                      Fotos do cupom na carteira
                    </Typography>
                  )}

                  <br />
                  {this.state.idStore !== null ? (
                    <Box display="flex">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{
                          fontWeight: '550',
                          color: '#fff',
                          textTransform: 'capitalize',
                        }}
                        startIcon={<FileCopy />}
                        onClick={() => this.copyTemplateIdToClipboard()}
                      >
                        {this.state.copyTemplateMessage}
                      </Button>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <br />

                  {!this.state.disableButtonImageCarteira && (
                    <div className="flex-external">
                      <div
                        className="img-thumb"
                        style={{
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="secondary"
                          style={{ fontWeight: '550', textAlign: 'center' }}
                        >
                          Thumbnail do Cupom (aparecerá na "Carteira")
                        </Typography>

                        <img
                          alt="Thumbnail"
                          src={thumbnailPromocao}
                          style={{
                            width: '100%',
                            maxWidth: '400px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <img
                          alt="thumbnail"
                          className="img-promocao-fidelidade2"
                          src={
                            this.state.equalImageThumbnailFile !== null
                              ? this.state.equalImageThumbnailFile
                              : this.state.fileThumbnail !== null
                              ? this.state.fileThumbnail
                              : this.state.loadedThumbnail !== null
                              ? this.state.loadedThumbnail
                              : placeholder
                          }
                        />

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            value=""
                            disabled={this.state.equalImageThumbnail === true ? true : false}
                            id="thumbailTemplate"
                            accept="image/*"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={this.fileChangedHandler('fileThumbnail', 'thumbnail')}
                          />

                          <label htmlFor="thumbailTemplate">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                marginTop: '15px',
                                fontWeight: '550',
                                marginLeft: '20px',
                                color: '#fff',
                                textTransform: 'capitalize',
                              }}
                              disabled={this.state.disableButtonImageCarteira}
                              startIcon={<AddPhotoAlternate color="#fff" />}
                            >
                              Adicionar Foto
                            </Button>

                            <Tooltip title="As imagens devem ser 4x3.">
                              <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                            </Tooltip>
                          </label>
                        </div>
                      </div>

                      <div
                        className="img-thumb"
                        style={{
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="secondary"
                          style={{ fontWeight: '550', textAlign: 'center' }}
                        >
                          Foto Detalhada (aparecerá em "Detalhe do Cupom")
                        </Typography>

                        <img
                          src={listaFotos}
                          alt="Foto em detalhe"
                          style={{
                            maxWidth: '100%',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <img
                          width="100%"
                          style={{ position: 'absolute', left: '0' }}
                          alt="Foto em detalhe"
                          src={
                            this.state.equalImagePhotoFile !== null
                              ? this.state.equalImagePhotoFile
                              : this.state.fileFoto !== null
                              ? this.state.fileFoto
                              : this.state.loadedFoto !== null
                              ? this.state.loadedFoto
                              : placeholder
                          }
                        />

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            value=""
                            disabled={this.state.equalImagePhoto === true ? true : false}
                            id="photoTemplate"
                            accept="image/*"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.fileChangedHandler('fileFoto', 'foto')}
                          />

                          <label htmlFor="photoTemplate">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                marginTop: '15px',
                                fontWeight: '550',
                                marginLeft: '20px',
                                color: '#fff',
                                textTransform: 'capitalize',
                              }}
                              disabled={this.state.disableButtonImageCarteira}
                              startIcon={<AddPhotoAlternate color="#fff" />}
                            >
                              Adicionar Foto
                            </Button>

                            <Tooltip title="As imagens devem ser 4x3.">
                              <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <FormLabel style={{ marginTop: '20px' }} component="legend">
                    Selecione o tipo da Promoção Agrupada
                  </FormLabel>

                  <Select
                    className="input-field"
                    value={this.state.groupType}
                    onChange={this.handleChange('groupType')}
                    displayEmpty
                    name="groupType"
                  >
                    <MenuItem value="limitada">Limitada</MenuItem>
                    <MenuItem value="ilimitada">Ilimitada</MenuItem>
                  </Select>

                  <TextField
                    disabled={this.state.groupType === 'ilimitada' ? true : false}
                    style={{ width: '100px' }}
                    className="input-field"
                    onChange={this.handleChange('counterRoof')}
                    value={Number(this.state.counterRoof)}
                    type="number"
                    id="qtdSimultaneo"
                    required={this.state.isPublished}
                    name="9t"
                    label="Limite de pontos"
                  />

                  <div style={{ marginTop: '20px' }}>
                    {/* ÁREA DE CADASTRO DOS TEMPLATES */}
                    {Object(this.state.templates).map((item, i) => (
                      <Accordion
                        key={i}
                        expanded={this.state.expanded === this.state.templates[i].id}
                        onChange={this.handleChangePanel(this.state.templates[i].id)}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>Cupom da Oferta</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <FormControl className="input-field" style={{ marginTop: '30px' }}>
                            <TextField
                              className="input-field"
                              value={
                                this.state.templates[i].nomeTemplate === null
                                  ? ''
                                  : this.state.templates[i].nomeTemplate
                              }
                              onChange={this.handleChangeTemplate(i, 'nomeTemplate')}
                              type="text"
                              id="templateName"
                              name="10t"
                              label="Nome do Template"
                            />

                            <TextField
                              className="input-field"
                              value={
                                this.state.templates[i].titulo === null
                                  ? ''
                                  : this.state.templates[i].titulo
                              }
                              onChange={this.handleChangeTemplate(i, 'titulo')}
                              type="text"
                              id="templateTitulo"
                              name="11t"
                              label="Título do Template"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.templates[i].campanhaTriibo}
                                  onChange={this.handleChangeSwitchTemplate(i, 'campanhaTriibo')}
                                  color="primary"
                                />
                              }
                              label={'Campanha Triibo?'}
                            />

                            <FormControl className="input-field">
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={this.state.consumo === 'imediato' ? true : false}
                                    checked={
                                      this.state.consumo === 'imediato'
                                        ? false
                                        : this.state.templates[i].hasSimultaneo
                                    }
                                    onChange={this.handleChangeSwitchTemplate(i, 'hasSimultaneo')}
                                    color="primary"
                                  />
                                }
                                label={'Permite resgate simultâneo?'}
                              />

                              <TextField
                                style={{ width: '100px' }}
                                className="input-field"
                                disabled={
                                  this.state.templates[i].consumo === 'imediato' ||
                                  this.state.templates[i].hasSimultaneo === false
                                }
                                onChange={this.handleChangeTemplate(i, 'qtdSimultaneo')}
                                value={
                                  this.state.templates[i].qtdSimultaneo === null
                                    ? ''
                                    : this.state.templates[i].qtdSimultaneo
                                }
                                type="number"
                                id="qtdSimultaneo"
                                required={this.state.isPublished}
                                name="12t"
                                label="Quantidade Simultaneo"
                              />

                              <FormControlLabel
                                style={{
                                  marginTop: '10px',
                                  marginBottom: '-20px',
                                }}
                                control={
                                  <Switch
                                    checked={this.state.templates[i].consumoUnico}
                                    onChange={this.handleChangeSwitchTemplate(i, 'consumoUnico')}
                                    color="primary"
                                  />
                                }
                                label={'Voucher de Consumo único?'}
                              />

                              <FormLabel style={{ marginTop: '40px' }} component="legend">
                                Tempo de espera para pegar outro cupom
                              </FormLabel>

                              <Grid container spacing={4}>
                                <Grid item lg={1}>
                                  <TextField
                                    style={{ width: '70px' }}
                                    className="input-field"
                                    value={
                                      this.state.templates[i].cooldownDays === null
                                        ? 0
                                        : this.state.templates[i].cooldownDays
                                    }
                                    onChange={this.handleChangeTemplate(i, 'cooldownDays')}
                                    type="number"
                                    id="cooldownDays"
                                    label="Dias"
                                  />
                                </Grid>
                                <Grid item lg={1}>
                                  <TextField
                                    style={{ width: '70px' }}
                                    className="input-field"
                                    value={
                                      this.state.templates[i].cooldownHours === null
                                        ? 0
                                        : this.state.templates[i].cooldownHours
                                    }
                                    onChange={this.handleChangeTemplate(i, 'cooldownHours')}
                                    type="number"
                                    id="cooldownHours"
                                    label="Horas"
                                  />
                                </Grid>
                                <Grid item lg={1}>
                                  <TextField
                                    style={{ width: '70px' }}
                                    className="input-field"
                                    value={
                                      this.state.templates[i].cooldownMinutes === null
                                        ? 0
                                        : this.state.templates[i].cooldownMinutes
                                    }
                                    onChange={this.handleChangeTemplate(i, 'cooldownMinutes')}
                                    type="number"
                                    id="cooldownMinutes"
                                    label="Minutos"
                                  />
                                </Grid>
                              </Grid>

                              <FormControlLabel
                                style={{ marginTop: '10px' }}
                                control={
                                  <Switch
                                    checked={this.state.templates[i].generator}
                                    onChange={this.handleChangeSwitchTemplate(i, 'generator')}
                                    color="primary"
                                  />
                                }
                                label={'Estoque ilimitado?'}
                              />
                            </FormControl>

                            <TextField
                              style={{ width: '100px' }}
                              className="input-field"
                              onChange={this.handleChangeTemplate(i, 'value')}
                              value={
                                this.state.templates[i].value === null
                                  ? '1'
                                  : this.state.templates[i].value
                              }
                              type="number"
                              id="value"
                              required={this.state.isPublished}
                              name="13t"
                              label="Valor do Template"
                            />
                          </FormControl>
                        </AccordionDetails>
                      </Accordion>
                    ))}

                    {/* CADASTRO DO TEMPLATE FINAL */}
                    <Accordion
                      disabled={this.state.groupType === 'ilimitada' ? true : false}
                      key="templateFinal"
                      expanded={this.state.expanded === 'templateFinal'}
                      onChange={this.handleChangePanel('templateFinal')}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Cupom para o resgate do Prêmio</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <FormControl className="input-field" style={{ marginTop: '30px' }}>
                          <Typography
                            style={{
                              marginTop: '50px',
                              marginBottom: '-15px',
                              fontWeight: '700',
                              fontSize: '20px',
                            }}
                            placeholder="Placeholder"
                            variant="h5"
                            gutterBottom
                            color="primary"
                          >
                            Cupom de prêmio
                          </Typography>
                          <br />
                          <br />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.disableButtonImagePremio}
                                onChange={this.handleChangeSwitchImage('equalImagePhotoCupom')}
                                color="primary"
                                onClick={this.handleEqualImages('templateCounterMatch')}
                              />
                            }
                            label={'Utilizar as mesmas imagens da promoção'}
                          />

                          <br />
                          <br />
                          <br />
                          {!this.state.disableButtonImagePremio && (
                            <div className="flex-external">
                              <div
                                className="img-thumb"
                                style={{
                                  maxWidth: '400px',
                                  position: 'relative',
                                  top: '0',
                                  left: '0',
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  color="secondary"
                                  style={{
                                    fontWeight: '550',
                                    textAlign: 'center',
                                  }}
                                >
                                  Thumbnail do Cupom (aparecerá na "Carteira")
                                </Typography>

                                <img
                                  alt="thumbnail promocao"
                                  src={thumbnailPromocao}
                                  style={{
                                    width: '100%',
                                    maxWidth: '400px',
                                    maxHeight: '447px',
                                    position: 'relative',
                                    top: '0',
                                    left: '0',
                                  }}
                                />

                                <img
                                  alt="thumbnail"
                                  className="img-promocao-fidelidade2"
                                  src={
                                    this.state.templateCounterMatch.equalImageThumbnailFile !== null
                                      ? this.state.templateCounterMatch.equalImageThumbnailFile
                                      : this.state.templateCounterMatch.fileThumbnail !== null
                                      ? this.state.templateCounterMatch.fileThumbnail
                                      : this.state.templateCounterMatch.loadedThumbnail !== null
                                      ? this.state.templateCounterMatch.loadedThumbnail
                                      : placeholder
                                  }
                                />

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <input
                                    value=""
                                    disabled={
                                      this.state.templateCounterMatch.equalImageThumbnail === true
                                        ? true
                                        : false
                                    }
                                    id="thumbailTemplateCounterMatch"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={this.fileChangedHandler(
                                      'templateCounterMatch.fileThumbnail',
                                      'templateCounterMatch.thumbnail'
                                    )}
                                  />

                                  <label htmlFor="thumbailTemplateCounterMatch">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      style={{
                                        marginTop: '15px',
                                        fontWeight: '550',
                                        marginLeft: '20px',
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                      }}
                                      disabled={this.state.disableButtonImagePremio}
                                      startIcon={<AddPhotoAlternate />}
                                    >
                                      Adicionar Foto
                                    </Button>

                                    <Tooltip title="As imagens devem ser 4x3.">
                                      <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                                    </Tooltip>
                                  </label>
                                </div>
                              </div>

                              <div
                                className="img-thumb"
                                style={{
                                  maxWidth: '400px',
                                  position: 'relative',
                                  top: '0',
                                  left: '0',
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  color="secondary"
                                  style={{
                                    fontWeight: '550',
                                    textAlign: 'center',
                                  }}
                                >
                                  Foto Detalhada (aparecerá em "Detalhe do Cupom")
                                </Typography>

                                <img
                                  src={listaFotos}
                                  alt="Foto detalhada"
                                  style={{
                                    maxWidth: '100%',
                                    position: 'relative',
                                    top: '0',
                                    left: '0',
                                  }}
                                />

                                <img
                                  width="100%"
                                  alt="Foto detalhe"
                                  style={{ position: 'absolute', left: '0' }}
                                  src={
                                    this.state.templateCounterMatch.equalImagePhotoFile !== null
                                      ? this.state.templateCounterMatch.equalImagePhotoFile
                                      : this.state.templateCounterMatch.fileFoto !== null
                                      ? this.state.templateCounterMatch.fileFoto
                                      : this.state.templateCounterMatch.loadedFoto !== null
                                      ? this.state.templateCounterMatch.loadedFoto
                                      : placeholder
                                  }
                                />

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <input
                                    value=""
                                    disabled={
                                      this.state.templateCounterMatch.equalImagePhoto === true
                                        ? true
                                        : false
                                    }
                                    id="photoTemplateCounterMatch"
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={this.fileChangedHandler(
                                      'templateCounterMatch.fileFoto',
                                      'templateCounterMatchfoto'
                                    )}
                                  />

                                  <label htmlFor="photoTemplateCounterMatch">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      style={{
                                        marginTop: '15px',
                                        fontWeight: '550',
                                        marginLeft: '20px',
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                      }}
                                      disabled={this.state.disableButtonImagePremio}
                                      startIcon={<AddPhotoAlternate />}
                                    >
                                      Adicionar Foto
                                    </Button>

                                    <Tooltip title="As imagens devem ser 4x3.">
                                      <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                                    </Tooltip>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.nomeTemplate === null
                                ? ''
                                : this.state.templateCounterMatch.nomeTemplate
                            }
                            onChange={this.handleChangeTemplate(null, 'nomeTemplate')}
                            type="text"
                            id="templateName"
                            required={this.state.isPublished && this.state.groupType === 'Limitada'}
                            name="14t"
                            label="Nome do Template"
                          />

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.titulo === null
                                ? ''
                                : this.state.templateCounterMatch.titulo
                            }
                            onChange={this.handleChangeTemplate(null, 'titulo')}
                            type="text"
                            id="templateTitulo"
                            required={this.state.isPublished && this.state.groupType === 'Limitada'}
                            name="15t"
                            label="Título do Template"
                          />

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.detailsTitle === null
                                ? ''
                                : this.state.templateCounterMatch.detailsTitle
                            }
                            onChange={this.handleChangeTemplate(null, 'detailsTitle')}
                            type="text"
                            id="templateDetailsTitle"
                            label="Detalhe do Título do Template"
                          />

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.descricao === null
                                ? ''
                                : this.state.templateCounterMatch.descricao
                            }
                            onChange={this.handleChangeTemplate(null, 'descricao')}
                            type="text"
                            id="templateDescricao"
                            required={this.state.isPublished && this.state.groupType === 'Limitada'}
                            name="16t"
                            label="Descrição do Template"
                          />

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.detailsDescription === null
                                ? ''
                                : this.state.templateCounterMatch.detailsDescription
                            }
                            onChange={this.handleChangeTemplate(null, 'detailsDescription')}
                            type="text"
                            id="templateDetailsTitle"
                            label="Detalhe da Descrição do Template"
                          />

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.singular === null
                                ? ''
                                : this.state.templateCounterMatch.singular
                            }
                            onChange={this.handleChangeTemplate(null, 'singular')}
                            type="text"
                            id="templateSingular"
                            required={this.state.isPublished && this.state.groupType === 'Limitada'}
                            name="18t"
                            label="Objeto do Template no singular"
                          />

                          <TextField
                            className="input-field"
                            value={
                              this.state.templateCounterMatch.plural === null
                                ? ''
                                : this.state.templateCounterMatch.plural
                            }
                            onChange={this.handleChangeTemplate(null, 'plural')}
                            type="text"
                            id="templatePlural"
                            required={this.state.isPublished && this.state.groupType === 'Limitada'}
                            name="19t"
                            label="Objeto do Template no plural"
                          />

                          <FormControl className="input-field">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.templateCounterMatch.sendSMS}
                                  onChange={this.handleChangeSwitchTemplate(null, 'sendSMS')}
                                  color="primary"
                                />
                              }
                              label={'Enviar SMS ao resgatar a promoção?'}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={this.state.consumo === 'imediato' ? true : false}
                                  checked={
                                    this.state.consumo === 'imediato'
                                      ? false
                                      : this.state.templateCounterMatch.hasSimultaneo
                                  }
                                  onChange={this.handleChangeSwitchTemplate(null, 'hasSimultaneo')}
                                  color="primary"
                                />
                              }
                              label={'Permite resgate simultâneo?'}
                            />

                            <TextField
                              style={{ width: '100px' }}
                              className="input-field"
                              disabled={
                                this.state.consumo === 'imediato' ||
                                this.state.templateCounterMatch.hasSimultaneo === false
                              }
                              onChange={this.handleChangeTemplate(null, 'qtdSimultaneo')}
                              value={
                                this.state.templateCounterMatch.qtdSimultaneo === null
                                  ? ''
                                  : this.state.templateCounterMatch.qtdSimultaneo
                              }
                              type="number"
                              id="qtdSimultaneo"
                              required={
                                this.state.isPublished && this.state.groupType === 'Limitada'
                              }
                              name="20t"
                              label="Quantidade Simultaneo"
                            />

                            <FormControlLabel
                              style={{
                                marginTop: '10px',
                                marginBottom: '-20px',
                              }}
                              control={
                                <Switch
                                  checked={this.state.templateCounterMatch.consumoUnico}
                                  onChange={this.handleChangeSwitchTemplate(null, 'consumoUnico')}
                                  color="primary"
                                />
                              }
                              label={'Voucher de Consumo único?'}
                            />

                            <FormLabel style={{ marginTop: '40px' }} component="legend">
                              Tempo de espera para pegar outro cupom
                            </FormLabel>

                            <Grid container spacing={4}>
                              <Grid item lg={1}>
                                <TextField
                                  style={{ width: '70px' }}
                                  className="input-field"
                                  value={
                                    this.state.templateCounterMatch.cooldownDays === null
                                      ? 0
                                      : this.state.templateCounterMatch.cooldownDays
                                  }
                                  onChange={this.handleChangeTemplate(null, 'cooldownDays')}
                                  type="number"
                                  id="cooldownDays"
                                  required={
                                    this.state.isPublished && this.state.groupType === 'Limitada'
                                  }
                                  name="21t"
                                  label="Dias"
                                />
                              </Grid>
                              <Grid item lg={1}>
                                <TextField
                                  style={{ width: '70px' }}
                                  className="input-field"
                                  value={
                                    this.state.templateCounterMatch.cooldownHours === null
                                      ? 0
                                      : this.state.templateCounterMatch.cooldownHours
                                  }
                                  onChange={this.handleChangeTemplate(null, 'cooldownHours')}
                                  type="number"
                                  id="cooldownHours"
                                  required={
                                    this.state.isPublished && this.state.groupType === 'Limitada'
                                  }
                                  name="22t"
                                  label="Horas"
                                />
                              </Grid>
                              <Grid item lg={1}>
                                <TextField
                                  style={{ width: '70px' }}
                                  className="input-field"
                                  value={
                                    this.state.templateCounterMatch.cooldownMinutes === null
                                      ? 0
                                      : this.state.templateCounterMatch.cooldownMinutes
                                  }
                                  onChange={this.handleChangeTemplate(null, 'cooldownMinutes')}
                                  type="number"
                                  id="cooldownMinutes"
                                  required={
                                    this.state.isPublished && this.state.groupType === 'Limitada'
                                  }
                                  name="23t"
                                  label="Minutos"
                                />
                              </Grid>
                            </Grid>

                            <FormControlLabel
                              style={{ marginTop: '10px' }}
                              control={
                                <Switch
                                  checked={this.state.templateCounterMatch.generator}
                                  onChange={this.handleChangeSwitchTemplate(null, 'generator')}
                                  color="primary"
                                />
                              }
                              label={'Estoque ilimitado?'}
                            />
                          </FormControl>

                          <FormControlLabel
                            style={{ marginTop: '10px' }}
                            control={
                              <Switch
                                checked={this.state.templateCounterMatch.hasDisableDate}
                                onChange={this.handleChangeSwitchTemplate(null, 'hasDisableDate')}
                                color="primary"
                              />
                            }
                            label={'Definir data de validade?'}
                          />

                          <FormLabel style={{ marginTop: '20px' }} component="legend">
                            Tempo de validade do cupom
                          </FormLabel>
                          <Grid container spacing={4}>
                            <Grid item lg={1}>
                              <TextField
                                disabled={!this.state.templateCounterMatch.hasDisableDate}
                                style={{ width: '70px' }}
                                className="input-field"
                                value={this.state.templateCounterMatch.disableDateHours}
                                onChange={this.handleChangeTemplate(null, 'disableDateHours')}
                                type="number"
                                id="disableDateHours"
                                label="Horas"
                              />
                            </Grid>
                            <Grid item lg={1}>
                              <TextField
                                disabled={!this.state.templateCounterMatch.hasDisableDate}
                                style={{ width: '70px' }}
                                className="input-field"
                                value={this.state.templateCounterMatch.disableDateMinutes}
                                onChange={this.handleChangeTemplate(null, 'disableDateMinutes')}
                                type="number"
                                id="disableDateMinutes"
                                label="Minutos"
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </FormControl>

                <TextField
                  className="input-field"
                  value={this.state.buttonLabel === null ? '' : this.state.buttonLabel}
                  multiline
                  onChange={this.handleChange('buttonLabel')}
                  type="text"
                  id="buttonLabel"
                  label="Texto do botão"
                />

                <TextField
                  className="input-field"
                  value={this.state.buttonDescription === null ? '' : this.state.buttonDescription}
                  multiline
                  onChange={this.handleChange('buttonDescription')}
                  type="text"
                  id="buttonDescription"
                  label="Descrição do botão"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showButtonDescription}
                      onChange={this.handleChangeSwitch('showButtonDescription')}
                      color="primary"
                    />
                  }
                  label={'Mostrar descrição do botão?'}
                />

                <TextField
                  className="input-field"
                  value={this.state.goToPlaceLabel === null ? '' : this.state.goToPlaceLabel}
                  multiline
                  onChange={this.handleChange('goToPlaceLabel')}
                  type="text"
                  id="goToPlaceLabel"
                  label="Texto do botão para o estabelecimento"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showGoToPlace}
                      onChange={this.handleChangeSwitch('showGoToPlace')}
                      color="primary"
                    />
                  }
                  label={'Mostrar botão de ver estabelecimento?'}
                />

                <Grid container spacing={1}>
                  <Typography
                    style={{ marginTop: '50px' }}
                    variant="h5"
                    gutterBottom
                    color="primary"
                  >
                    Publicação da Oferta
                  </Typography>

                  <FormControl className="input-field">
                    <FormControlLabel
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      control={
                        <Switch
                          checked={this.state.isPublished}
                          onChange={this.handleChangeSwitch('isPublished')}
                          color="primary"
                        />
                      }
                      label={'Publicar Oferta?'}
                    />

                    <TextField
                      id="enableDate"
                      label="Data de Ativação"
                      type="datetime-local"
                      value={this.state.enableDate !== null ? this.state.enableDate : ''}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange('enableDate')}
                      required={this.state.isPublished}
                      name="24t"
                    />

                    <FormControlLabel
                      style={{ marginTop: '10px', marginBottom: '-10px' }}
                      control={
                        <Switch
                          checked={this.state.unlimitedDisableDate}
                          onChange={this.handleChangeSwitch('unlimitedDisableDate')}
                          color="primary"
                        />
                      }
                      label={'Desabilitar data de desativação programada'}
                    />
                    <p
                      style={{
                        color: 'red',
                        fontSize: '0.7rem',
                        fontWeight: 400,
                        marginBottom: '1rem',
                        padding: 0,
                      }}
                    >
                      *Se a data de desativação for maior que 5 anos esse botão será habilitado
                      automaticamente.
                    </p>

                    <br />
                    <TextField
                      id="disableDate"
                      label="Data de Desativação"
                      type="datetime-local"
                      value={this.state.disableDate !== null ? this.state.disableDate : ''}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange('disableDate')}
                      required={this.state.isPublished}
                      name="25t"
                      style={{
                        display: this.state.unlimitedDisableDate ? 'none' : 'block',
                      }}
                    />
                  </FormControl>
                </Grid>
              </FormGroup>

              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '50px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => this.goBack()}
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>

              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '50px',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
              >
                Salvar
              </Button>
            </form>

            {/* ALERTS */}
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {'Deseja voltar a página anterior?'}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  As alterações realizadas não serão salvas.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  cancelar
                </Button>
                <Button onClick={() => window.history.back()} color="primary">
                  voltar
                </Button>
              </DialogActions>
            </Dialog>
            {/* FIM ALERTS */}
            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openModal}
            >
              <div style={getModalStyle()}>
                <Typography variant="h5" gutterBottom color="primary" style={{ fontWeight: '600' }}>
                  Editar Imagem
                </Typography>

                <div
                  style={{
                    maxWidth: '40%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop[this.state.indexCrop]}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={4 / 3}
                    />
                  )}
                </div>

                <div style={{ position: 'fixed', bottom: 10 }}>
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleCloseModal()}
                    startIcon={<ArrowBack />}
                  >
                    Cancelar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.state.imageCroped === 'photoListFile') {
                        this.photoListCropImage('image');
                      } else {
                        this.cropImage('image');
                      }
                    }}
                    startIcon={<Save />}
                  >
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}

            {/* ALERT */}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messageAlert}</span>}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.storePromotionComponent.loading,
    success: state.storePromotionComponent.success,
    error: state.storePromotionComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateStoreGroupInformationComponent: (
    idStore,
    active,
    level,
    region,
    description,
    keywordsSetConsumptionList,
    messageBlockedKw,
    showQRCode,
    title,
    autoValidate,
    hasVoucher,
    enableDate,
    disableDate,
    isPublished,
    keywords,
    tags,
    fileStoreThumbnail,
    oldStoreThumbnail,
    storeThumbnail,
    fileStorePhoto,
    oldStorePhoto,
    storePhoto,

    idEstabelecimento,
    idGestor,
    nomeEstabelecimento,
    lat,
    long,
    address,
    neighborhood,

    buttonLabel,
    buttonDescription,
    showButtonDescription,
    goToPlaceLabel,
    showGoToPlace,

    idGroup,
    state,
    created,
    groupName,
    plural,
    singular,
    groupTitle,
    groupDetailsTitle,
    groupDescription,
    groupDetailsDescription,
    groupType,
    counterRoof,
    equalImageThumbnailFile,
    equalImagePhotoFile,
    fileThumbnail,
    oldThumbnail,
    thumbnail,
    fileFoto,
    oldFoto,
    foto,
    oldGroup,
    templatesGroup,

    templates,
    templateCounterMatch,
    oldPhotoList,
    photoListFile,
    messageApproval,
    newStatus,
    expirationType,
    onSendExpiration,
    disableDateCoupon
  ) =>
    updateStoreGroupInformationAction(
      dispatch,
      idStore,
      active,
      level,
      region,
      description,
      keywordsSetConsumptionList,
      messageBlockedKw,
      showQRCode,
      title,
      autoValidate,
      hasVoucher,
      enableDate,
      disableDate,
      isPublished,
      keywords,
      tags,
      fileStoreThumbnail,
      oldStoreThumbnail,
      storeThumbnail,
      fileStorePhoto,
      oldStorePhoto,
      storePhoto,

      idEstabelecimento,
      idGestor,
      nomeEstabelecimento,
      lat,
      long,
      address,
      neighborhood,

      buttonLabel,
      buttonDescription,
      showButtonDescription,
      goToPlaceLabel,
      showGoToPlace,

      idGroup,
      state,
      created,
      groupName,
      plural,
      singular,
      groupTitle,
      groupDetailsTitle,
      groupDescription,
      groupDetailsDescription,
      groupType,
      counterRoof,
      equalImageThumbnailFile,
      equalImagePhotoFile,
      fileThumbnail,
      oldThumbnail,
      thumbnail,
      fileFoto,
      oldFoto,
      foto,
      oldGroup,
      templatesGroup,

      templates,
      templateCounterMatch,
      oldPhotoList,
      photoListFile,
      messageApproval,
      newStatus,
      expirationType,
      onSendExpiration,
      disableDateCoupon
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StoreGroupInformation);

