import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//components
import headerComponent from 'js/core/reducers/components/headerReducer';
import sendNotificationComponent from 'js/core/reducers/components/sendNotificationReducer';
import financialCreditComponent from 'js/core/reducers/components/financialCreditReducer';

//login
import phoneInputComponent from 'js/core/reducers/components/phoneInputReducer';
import codeInputComponent from 'js/core/reducers/components/codeInputReducer';
import signInComponent from 'js/core/reducers/components/signInReducer';

//estabelecimentos
import establishmentListComponent from 'js/core/reducers/components/establishmentListReducer';
import establishmentInformationComponent from 'js/core/reducers/components/establishmentInformationReducer';

//cards
import listCardsComponent from 'js/core/reducers/components/listCardsReducer';

//Queries
import batchActionsComponent from 'js/core/reducers/components/batchActionsReducer';

//store
import storeBusinessPartnerComponent from 'js/core/reducers/components/storeBusinessPartnerReducer';
import storePromotionComponent from 'js/core/reducers/components/storePromotionReducer';

//campanhaTriibo
import campaingTriiboComponent from 'js/core/reducers/components/campaingTriiboReducer';

//Geração de Voucher
import generationInformationComponent from 'js/core/reducers/components/generationInformationReducer';

//Envio de Voucher
import voucherSendComponent from 'js/core/reducers/components/voucherSendReducer';

//validadores
import validatorsInformationComponent from 'js/core/reducers/components/validatorsInformationReducer';

import storeProductsInformationComponent from 'js/core/reducers/components/storeProductsInformationReducer';

//add pagamento
import paymentInformationComponent from 'js/core/reducers/components/paymentInformationReducer';

// QR Code
import generateQRCodeComponent from 'js/core/reducers/components/generateQRCodeReducer';
import listQRCodeComponent from 'js/core/reducers/components/listQRCodeReducer';
import deleteQRCodeComponent from 'js/core/reducers/components/deleteQRCodeReducer';

// Duplicar promoção
import duplicatePromotionComponent from 'js/core/reducers/components/duplicatePromotionReducer';

//Marketing Template
import marketingTemplateComponent from 'js/core/reducers/components/marketingTemplateReducer';

//models
import generationInformationModel from 'js/core/reducers/models/generationInformationModel';
import phoneInputModel from 'js/core/reducers/models/phoneInputModel';
import codeInputModel from 'js/core/reducers/models/codeInputModel';
import signInModel from 'js/core/reducers/models/signInModel';
import sendNotificationsModel from 'js/core/reducers/models/sendNotifications';
import establishmentListModel from 'js/core/reducers/models/establishmentListModel';
import establishmentInformationModel from 'js/core/reducers/models/establishmentInformationModel';
import storeBusinessPartnerModel from 'js/core/reducers/models/storeBusinessPartnerModel';
import validatorsInformationModel from 'js/core/reducers/models/validatorsInformationModel';
import storeProductsInformationModel from 'js/core/reducers/models/storeProductsInformationModel';
import listCardsModel from 'js/core/reducers/models/listCardsModel';
import batchActionsModel from 'js/core/reducers/models/batchActionsModel';
import campaingTriiboModel from 'js/core/reducers/models/campaingTriiboModel';
import marketingTemplateModel from 'js/core/reducers/models/marketingTemplateModel';
import generateQRCodeModel from 'js/core/reducers/models/generateQRCodeModel';
import authModel from 'js/core/reducers/models/authModel';
import trialModel from 'js/core/reducers/models/trialModel';
import currentEstModel from 'js/core/reducers/models/currentEstModel';

export default combineReducers({
  phoneInputModel: createReducer('phoneInput', phoneInputModel),
  codeInputModel: createReducer('codeInput', codeInputModel),
  signInModel: createReducer('signIn', signInModel),
  establishmentListModel: createReducer('establishmentList', establishmentListModel),
  establishmentInformationModel: createReducer(
    'establishmentInformation',
    establishmentInformationModel
  ),
  storeBusinessPartnerModel: createReducer('storeBusinessPartner', storeBusinessPartnerModel),
  validatorsInformationModel: createReducer('validatorsInformation', validatorsInformationModel),
  storeProductsInformationModel: createReducer(
    'storeProductsInformation',
    storeProductsInformationModel
  ),
  sendNotificationsModel: createReducer('establishmentInformation', sendNotificationsModel),
  listCardsModel: createReducer('listCards', listCardsModel),
  authModel: createReducer('auth', authModel),
  trialModel: createReducer('trial', trialModel),
  currentEstModel: createReducer('currentEst', currentEstModel),

  batchActionsModel: createReducer('batchActions', batchActionsModel),

  generationInformationModel: createReducer('generationInformation', generationInformationModel),
  campaingTriiboModel: createReducer('campaingTriibo', campaingTriiboModel),
  marketingTemplateModel: createReducer('marketingTemplate', marketingTemplateModel),
  generateQRCodeModel: createReducer('generationQRCode', generateQRCodeModel),

  phoneInputComponent: phoneInputComponent,
  codeInputComponent: codeInputComponent,
  signInComponent: signInComponent,
  establishmentListComponent: establishmentListComponent,
  establishmentInformationComponent: establishmentInformationComponent,
  headerComponent: headerComponent,
  storeBusinessPartnerComponent: storeBusinessPartnerComponent,
  storePromotionComponent: storePromotionComponent,
  voucherSendComponent: voucherSendComponent,
  validatorsInformationComponent: validatorsInformationComponent,
  sendNotificationComponent: sendNotificationComponent,
  financialCreditComponent: financialCreditComponent,
  storeProductsInformationComponent: storeProductsInformationComponent,
  generationInformationComponent: generationInformationComponent,
  listCardsComponent: listCardsComponent,
  paymentInformationComponent: paymentInformationComponent,
  batchActionsComponent: batchActionsComponent,
  listQRCodeComponent: listQRCodeComponent,
  deleteQRCodeComponent: deleteQRCodeComponent,
  duplicatePromotionComponent: duplicatePromotionComponent,
  marketingTemplateComponent: marketingTemplateComponent,
  campaingTriiboComponent: campaingTriiboComponent,
  generateQRCodeComponent: generateQRCodeComponent,
});

function createReducer(key, reducer) {
  return persistReducer(createConfig(key), reducer);
}

function createConfig(key) {
  return {
    key: key,
    storage,
  };
}
