import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-bottom: solid 1px #707070;
  display: table;
`;

export const TableTR = styled.tr`
  border-bottom: solid 1px #70707033;
  text-align: left;
  display: table-row;
`;

export const TableTH = styled.th`
  padding: 1rem 0.5rem;
  display: table-cell;
`;

export const TableTD = styled.td`
  padding: 1rem 0.5rem;
  display: table-cell;
`;

export const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    width: 100%;
    overflow-x: auto;
  }
`;
