import React, { useState } from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import DropDown from 'js/components/DesignSystem/DropDown';

export default function OriginPartners({
  formCommunication,
  selectedOptionPartners,
  setSelectedOptionPartners,
}) {
  const [isPartnersSelectOpen, setIsPartnersSelectOpen] = useState(
    formCommunication.originPartners ? true : false
  );

  const isGestorPage = window.location.href.split('/').includes('admin') ? false : true;

  const [originPartners] = useState(
    isGestorPage ? ['triibo'] : ['triibo', 'seuClube', 'clubMapfre', 'sortesaude']
  );

  return (
    <S.Container>
      <div>
        <D.TriiboH4>
          <b>Parceiros de origem</b>
        </D.TriiboH4>
        <D.TriiboH5>Escolha qual parceiro é a fonte de dados.</D.TriiboH5>
      </div>
      <DropDown
        height={'48px'}
        width={'100%'}
        top={'3.5rem'}
        selectedOption={selectedOptionPartners}
        setSelectedOption={setSelectedOptionPartners}
        isSelectOpen={isPartnersSelectOpen}
        setIsSelectOpen={setIsPartnersSelectOpen}
        data={originPartners}
      />
    </S.Container>
  );
}

