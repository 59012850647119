import React, { useRef, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import EstablishmentProvider from '../Establishment/Context/Establishment';
import MarketingProvider from '../Establishment/Context/Marketing';
import AttendanceProvider from '../Establishment/Context/Attendance';

import Header from 'js/components/Header/Header';
import Sidebar from 'js/components/Sidebar/Sidebar';

import UserRouter from 'js/library/utils/Routes/UserRouter';

import updateAuthActionsAction from 'js/core/actions/authActions';
import updateTrialActionsAction from 'js/core/actions/trialActions';
// import currentEstActionsAction from 'js/core/actions/currentEstActions';

import { Box } from '@mui/material';

import AnimationLoading from 'js/containers/Loading/AnimationLoading';

import Requisition from 'js/library/services/AuthRequisition';
import RequisitionTrial from 'js/library/services/TrialRequisition';
import AdminRouter from 'js/library/utils/Routes/AdminRouter';
import { logout } from 'js/library/services/AuthenticationManager';

const Menu = () => {
  const state = useSelector((state) => state.authModel);
  // console.log(useSelector((state) => state));
  const phoneInput = useSelector((state) => state.phoneInputModel.cellPhone);
  const dispatch = useDispatch();
  const requisitionRef = useRef(false);

  // let currentEst = store.getState().currentEstModel.currentEst;

  // console.log('***',currentEst);
  // console.log('***', Object.keys(currentEst).length);
  // console.log(state);
  const Requisitions = () => {
    Requisition().then((result) => {
      // console.log(result);
      dispatch(updateAuthActionsAction(result));
    });
    if (!requisitionRef.current) {
      requisitionRef.current = true;
      return;
    }
    RequisitionTrial().then((result) => {
      dispatch(updateTrialActionsAction(result));
    });
  };

  useEffect(() => {
    if (!phoneInput) {
      return;
    } else {
      Requisitions();
    }
  }, []);

  if (state.checkLogin === 'deslogado' || state.authenticated === null) {
    logout();
    return <Redirect to="/" />;
  }

  if (state.authenticated === false || state.loading) {
    if (phoneInput === null) {
      logout();
      return <Redirect to="/" />;
    }
    return (
      <div className="containerLoading">
        <AnimationLoading className="itemLoading" />
      </div>
    );
  } else {
    return (
      <>
        <EstablishmentProvider>
          <AttendanceProvider>
            <MarketingProvider>
              <Box
                sx={{
                  overflowY: 'hidden',
                }}
              >
                <Header state={state} />
                <Sidebar state={state} />
              </Box>
              <Box
                sx={{
                  marginTop: '3.6rem',
                  marginLeft: { xs: '1.2rem', sm: '5rem' },
                }}
              >
                {state.superUser ? <AdminRouter /> : <UserRouter state={state} />}
              </Box>
            </MarketingProvider>
          </AttendanceProvider>
        </EstablishmentProvider>
      </>
    );
  }
};

export default Menu;

