import * as React from 'react';

const IconEstablishments = (props) => (
    <svg
    // width="2.4rem"
    // height="2.4rem"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        {...props}
    >
        <g data-name="Grupo 10629">
            <path data-name="Ret\xE2ngulo 1518" fill="none" d="M0 0h100v100H0z" />
            <g
                data-name="Elipse 565"
                transform="matrix(1.9 0 0 1.9 2.5 2.5)"
                fill="none"
                stroke="#6e3296"
                strokeWidth={4}
            >
                <circle cx={25} cy={25} r={25} stroke="none" />
                <circle cx={25} cy={25} r={23} />
            </g>
            <path
                data-name="Icon awesome-store"
                d="M76.48 42.512 70.7 33.28a2.85 2.85 0 0 0-2.412-1.33H32.305a2.85 2.85 0 0 0-2.413 1.33l-5.78 9.232A7.946 7.946 0 0 0 29.35 54.66a9.257 9.257 0 0 0 1.22.09A8.795 8.795 0 0 0 37.14 51.8a8.8 8.8 0 0 0 13.146 0 8.8 8.8 0 0 0 13.146 0 8.824 8.824 0 0 0 6.572 2.949 9.156 9.156 0 0 0 1.22-.08 7.938 7.938 0 0 0 5.255-12.158zM70.02 57.6a11.301 11.301 0 0 1-2.627-.338v8.888h-34.2v-8.888a11.799 11.799 0 0 1-2.628.338 12.018 12.018 0 0 1-1.604-.107 11.21 11.21 0 0 1-1.46-.32V74.7a2.85 2.85 0 0 0 2.85 2.85h39.9a2.85 2.85 0 0 0 2.85-2.85V57.172a9.093 9.093 0 0 1-1.462.321 12.386 12.386 0 0 1-1.615.107z"
                fill="#6e3296"
                style={{
                    strokeWidth: 1.9,
                }}
                transform="translate(0 -.49)"
            />
        </g>
    </svg>
);

export default IconEstablishments;
