import { makeStyles } from "@mui/styles";

export const useModalsStyles = makeStyles((theme) => ({
  card: {
    width: "100%",

    marginRight: theme.spacing(9),
    marginBottom: theme.spacing(5),

    "&:last-child": {
      marginRight: 0,
    },
  },

  cardTitle: {
    fontWeight: 600,

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  cardText: {
    overflow: "hidden",

    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
  },

  button: {
    alignSelf: "center",

    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),

    textTransform: "capitalize",
    fontWeight: 600,
  },

  content: {
    maxWidth: "100%",
    overflow: "auto",
  },

  walletContainer: {
    height: 160,
    overflow: "auto",

    boxShadow: theme.shadows[10],

    borderRadius: 15,

    "&+div": {
      marginLeft: theme.spacing(2),
    },

    "&>img": {
      borderRadius: "15px 0 0 15px",
      width: "140px",
    },
  },

  walletTextContainer: {
    width: 186,

    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: 0,

    marginLeft: theme.spacing(3),
  },

  walletDescriptionText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
  },

  iconButton: {
    float: "right",
  },

  searchInput: {
    width: "90%",

    marginTop: theme.spacing(5),

    alignSelf: "center",
  },

  loadingIcon: {
    margin: '0 auto'
  },

  sadIcon: {
    height: 150,
    width: 150,
  },
}));
