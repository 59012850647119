import React from 'react';
import Lottie from 'react-lottie';
import LoadingAnimation from 'styles/assets/animations/Loading_Circle.json';

const AnimationLoading = (props) => {
    const bodyMovinOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className={props.className}>
            <Lottie options={bodyMovinOptions} height={props.height} width={props.width}/>
        </div>
    );
};

export default AnimationLoading;
