import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import * as D from "js/components/DesignSystem/styles";
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import { cloneDeep } from "lodash";

export default function Platform({ form, setForm }) {
  const [checkboxes, setCheckboxes] = useState({
    web: true,
    android: true,
    iOS: true,
  });

  let isSuperAdmin = useSelector((state) => state.authModel.superUser);

  const handleChangeTypeCommunication = (event) => {
    let cloneCheckboxes = cloneDeep(checkboxes);
    cloneCheckboxes[event.target.value] = !cloneCheckboxes[event.target.value];
    setCheckboxes(cloneCheckboxes);
  };

  useEffect(() => {
    let platformArr = [];
    for (const [key, value] of Object.entries(checkboxes)) {
      if (value) {
        platformArr.push(key);
      }
    }
    let cloneForm = cloneDeep(form);
    cloneForm.platform = platformArr;
    setForm(cloneForm);
  }, [checkboxes]);

  if (!isSuperAdmin) {
    return <></>;
  }

  return (
    <div>
      <D.TriiboH4>
        <b>Escolha a plataforma</b>
      </D.TriiboH4>
      <FormGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          checked={checkboxes.web}
          onClick={handleChangeTypeCommunication}
          value="web"
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "#6E3296",
                },
              }}
            />
          }
          label="Web"
        />
        <FormControlLabel
          value="android"
          checked={checkboxes.android}
          onClick={handleChangeTypeCommunication}
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "#6E3296",
                },
              }}
            />
          }
          label="Android"
        />
        <FormControlLabel
          value="iOS"
          checked={checkboxes.iOS}
          onClick={handleChangeTypeCommunication}
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "#6E3296",
                },
              }}
            />
          }
          label="iOS"
        />
      </FormGroup>
    </div>
  );
}
