import axios from 'axios';

import cfac22 from '../cfac22';

export function apiGenerator(templateId, voucherAmmount, expiration, batchName, pathname) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        cfac22('API_HOST_V1') + 'api_voucher_generate',
        {
          templateId,
          voucherAmmount,
          expiration,
          batchName,
        },
        {
          headers: { access_token: cfac22('ACCESS_TOKEN') },
        }
      )
      .then((result) => {
        window.history.back();
        resolve(result.data.success);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
