import React, { useState } from 'react';

import * as S from '../styles';
import * as D from 'js/components/DesignSystem/styles';
import { Modal } from '@mui/material';
import PencilIcon from '../../../../../styles/assets/SearchUser/pencil.svg';
import { toast } from 'react-toastify';
import { findValueInArray, maskCEP, maskCPF2 } from 'js/library/utils/helpers';

import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { BrasilCap_createUser } from 'js/library/utils/API/SearchUser/apiBrasilCap_createUser';

export default function EditInfosSorteSaude({
  userUid,
  handleCloseModal,
  isModalEditInfosSorteSaude,
  partnerList,
}) {
  const [fieldsValues, setFieldsValues] = useState({
    name: partnerList.name || '',
    cpf: findValueInArray(partnerList.documentList, 'type', 'cpf').value || '',
    cellPhone: findValueInArray(partnerList.contactList, 'type', 'cellPhone').value || '',
    birthDate: new Date(partnerList.birthDate).toLocaleDateString('pt-BR') || '',
    email: findValueInArray(partnerList.contactList, 'type', 'email')
      ? findValueInArray(partnerList.contactList, 'type', 'email').value || ''
      : '',
    addressList: {
      streetAve: partnerList.addressList ? partnerList.addressList[0].streetAve || '' : '',
      neighborhood: partnerList.addressList ? partnerList.addressList[0].neighborhood || '' : '',
      city: partnerList.addressList ? partnerList.addressList[0].city || '' : '',
      state: partnerList.addressList ? partnerList.addressList[0].state || '' : '',
      zipCode: partnerList.addressList ? partnerList.addressList[0].zipCode || '' : '',
      number: partnerList.addressList ? partnerList.addressList[0].number || '' : '',
      extra: partnerList.addressList ? partnerList.addressList[0].extra || '' : '',
    },
  });

  const handleChangeCpf = (e) => {
    const copy = cloneDeep(fieldsValues);
    copy.addressList.zipCode = e.target.value;
    setFieldsValues(copy);

    if (e.target.value !== '') {
      const cep = e.target.value.replace('-', '');

      if (cep.length > 7) {
        axios.get(`https://viacep.com.br/ws/${cep}/json/`).then((result) => {
          if (result.data.erro !== undefined) {
            toast.error('CEP não encontrado');
          } else {
            const newFullAddress = {
              type: 'principal',
              googleAddr:
                result.data.logradouro +
                ' - ' +
                result.data.bairro +
                ', ' +
                result.data.localidade +
                ' - ' +
                result.data.uf +
                ', ' +
                result.data.cep +
                ', Brasil',
              latitude: 0,
              longitude: 0,
              streetAve: result.data.logradouro,
              number: '',
              state: result.data.uf,
              city: result.data.localidade,
              neighborhood: result.data.bairro,
              country: 'Brasil',
              zipCode: result.data.cep,
            };

            const copy = cloneDeep(fieldsValues);
            copy.addressList = { ...newFullAddress };
            setFieldsValues(copy);
          }
        });
      }
    }
  };

  const handleSubmit = () => {
    if (!fieldsValues.name || !fieldsValues.cellPhone || !fieldsValues.email || !fieldsValues.cpf) {
      return toast.error('Preencha todos os dados obrigatórios');
    }

    let userInfo = {
      ...partnerList,
      name: fieldsValues.name,
      contactList: [
        {
          type: 'cellPhone',
          value: fieldsValues.cellPhone,
        },
        {
          type: 'email',
          value: fieldsValues.email,
        },
      ],
      documentList: [
        {
          type: 'cpf',
          value: fieldsValues.cpf,
        },
      ],
    };

    Object.keys(fieldsValues.addressList).forEach((key) => {
      if (fieldsValues.addressList[key]) {
        userInfo.addressList = [
          userInfo.addressList
            ? {
                ...userInfo.addressList[0],
                [key]: fieldsValues.addressList[key],
              }
            : {
                [key]: fieldsValues.addressList[key],
              },
        ];
      }
    });

    userInfo.addressList[0].longitude = 0;
    userInfo.addressList[0].latitude = 0;
    userInfo.addressList[0].googleAddr = `${userInfo.addressList[0].streetAve} - ${userInfo.addressList[0].neighborhood}, ${userInfo.addressList[0].city} - ${userInfo.addressList[0].state}, ${userInfo.addressList[0].zipCode}, Brasil`;

    if (fieldsValues.birthDate) {
      // REMOVE OS / TRANSFORMANDO EM UM ARRAY COM DD, MM, YYYY
      const parts = fieldsValues.birthDate.split('/');

      // CRIA UM NEW DATE COM UM FORMATO QUE PODE SER CONVERTIDO EM TIMESTAMP (Thu Aug 11 1994 00:00:00 GMT-0300 (Horário Padrão de Brasília))
      var birthDate = new Date(parts[2], parts[1] - 1, parts[0]);

      // TRANSFORMA A DATA ANTERIOR EM TIMESTAMP
      userInfo.birthDate = birthDate.getTime() + 1000 * 60 * 60 * 3;
    }

    delete userInfo.status;

    BrasilCap_createUser(userInfo, userUid)
      .then((res) => {
        toast.success('Informações alteradas com sucesso.');
        handleCloseModal('closeModalEditInfosSorteSaude');
      })
      .catch((err) => {
        toast.error('Erro ao alterar informações.');
      });
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalEditInfosSorteSaude')}
      open={isModalEditInfosSorteSaude}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <S.ModalBox
          style={{
            msOverflowY: 'scroll',
            overflowY: 'scroll',
            maxHeight: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="header">
            <img src={PencilIcon} alt="" />
            <h2>Editar Informações Sorte Saúde</h2>
          </div>
          <S.EditInfoUserModalBox>
            <div>
              <D.TriiboH4>Nome completo</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.name}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>CPF</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={maskCPF2(fieldsValues.cpf)}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    cpf: e.target.value.replace(/\D/g, ''),
                  });
                }}
              />
            </div>

            <div>
              <D.TriiboH4>Data de nascimento</D.TriiboH4>
              <D.BirthDateMask
                mask="99/99/9999"
                rows={'1'}
                value={fieldsValues.birthDate}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    birthDate: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Email</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.email}
                onChange={(e) => {
                  setFieldsValues({
                    ...fieldsValues,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Rua</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.streetAve}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.streetAve = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Bairro</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.neighborhood}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.neighborhood = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Cidade</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.city}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.city = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Estado</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.state}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.state = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>CEP</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={maskCEP(fieldsValues.addressList.zipCode)}
                onChange={handleChangeCpf}
              />
            </div>
            <div>
              <D.TriiboH4>Número</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.number}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.number = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
            <div>
              <D.TriiboH4>Complemento</D.TriiboH4>
              <D.TriiboTextField
                rows={'1'}
                value={fieldsValues.addressList.extra}
                onChange={(e) => {
                  const copyValues = cloneDeep(fieldsValues);
                  copyValues.addressList.extra = e.target.value;
                  setFieldsValues(copyValues);
                }}
              />
            </div>
          </S.EditInfoUserModalBox>

          <PrimaryButton style={{ margin: '1rem auto' }} onClick={handleSubmit}>
            Salvar
          </PrimaryButton>
        </S.ModalBox>
      </>
    </Modal>
  );
}
