import React from "react";
import { Modal } from "@mui/material";

import * as C from "../styles";
import * as D from "../../../../DesignSystem/styles";
import * as S from "./styles";

import { AiFillCloseCircle } from "react-icons/ai";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import PostPreview from "../PostPreview";
import NotificationPreview from "../NotificationPreview";

export default function ModalPreview({
  handleCloseModal,
  isModalImageCropOpen,
  togglePreview,
  formCommunication,
  image,
  handleTogglePreview,
}) {
  return (
    <Modal
      onClose={() => handleCloseModal("preview")}
      open={isModalImageCropOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <C.ModalContainer
        m_width={"100%"}
        height={"100%"}
        background={
          "transparent linear-gradient(180deg, #6E3296 0%, #37194B 100%)"
        }>
        <C.ModalBox padding={"2.5rem"}>
          <C.CloseModalIcon onClick={() => handleCloseModal("preview")}>
            <AiFillCloseCircle size={25} color="#fff" />
          </C.CloseModalIcon>

          <S.SwitchView onClick={handleTogglePreview}>
            <HiOutlineSwitchHorizontal size={24} color="#6E3296" />

            <D.TriiboH3>Alternar tipo de comunicação</D.TriiboH3>
          </S.SwitchView>

          <C.PreviewContainer>
            {togglePreview ? (
              <PostPreview
                formCommunication={formCommunication}
                image={image}
              />
            ) : (
              <NotificationPreview formCommunication={formCommunication} />
            )}
          </C.PreviewContainer>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
