import axios from 'axios';
import firebase from 'firebase/app';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

//buscando usuario p/ login
export function getUserInfo_v1(queryPartnerAPI, userInfo) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', token);

        //TODO capturar sessionId e transactionId
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            // console.log('ERROR GET USER INFO ===>', error);

            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}

//verificando se o usuario existe com o cpf e cadastrando no authentication
export function getUserInfo_v1WithDocument(userInfo, cellPhone) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);
        let queryPartnerAPI = ['getUserInfo'];

        //TODO capturar sessionId e transactionId
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            if (result.data.success.userInfo === null) {
              //TODO: verificar cellphone
              let authEmail =
                cellPhone
                  .replace(/[(]/gi, '')
                  .replace(/[)]/gi, '')
                  .replace(/[-]/gi, '')
                  .replace(/\s/g, '') + '@sms.triibo.com.br';

              firebase
                .auth()
                .createUserWithEmailAndPassword(authEmail, 'triibo18')
                .then((result) => {
                  //console.log('USUARIO CRIADO NO AUTH ===>', result);
                  resolve({ uId: result.user.uid, isTriibo: false });
                })
                .catch(function (error) {
                  //console.log('ERRO AO CRIAR USUÁRIO NO AUTH ===>', error);
                });
            } else {
              resolve({ uId: result.data.success.userInfo.uId, isTriibo: true });
            }
          })
          .catch((error) => {
            //console.log('ERROR GET USER INFO WITH CELLPHONE===>', error);

            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}

//busca simples
export function getUserInfoBasic_v1(queryPartnerAPI, userInfo) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        //TODO capturar sessionId e transactionId
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            resolve(result.data.success.userInfo);
          })
          .catch((error) => {
            // console.log('ERROR GET USER INFO ===>', error);

            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}
