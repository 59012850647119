import {
  verificaPerfil,
  checkUserStatus,
} from 'js/library/services/AuthenticationManager';
import {
  getUserInfo,
  findValueInArray,
  sendUidMatomo,
} from 'js/library/utils/helpers';
import { CheckEstAdmin } from '../utils/API/SearchUser/apiCheckEstAdmin';

const Requisition = () => {
  return new Promise(async (resolve, reject) => {
    let payload = {};

    const checkUser = await checkUserStatus();

    const cellPhone =
      getUserInfo() !== null
        ? findValueInArray(getUserInfo().contactList, 'type', 'cellPhone').value
        : null;

    if (!checkUser) {
      payload.checkLogin = 'deslogado';
      resolve(payload);
    } else {
      let promiseEstablishment;

      try {
        promiseEstablishment = await CheckEstAdmin(getUserInfo().uId, 'all');
      } catch (error) {
        promiseEstablishment = []; // Array vazio para error
      }

      try {
        const promiseProfile = await verificaPerfil(cellPhone);

        payload.authenticated = checkUser;
        payload.superUser = promiseProfile;
        payload.establishmentList = promiseEstablishment;
        payload.loading = false;
        payload.checkLogin = 'logado';

        const loggedUid = getUserInfo().uId;
        sendUidMatomo(loggedUid);

        resolve(payload);
      } catch (error) {
        payload.checkLogin = 'deslogado';
        reject(payload);
      }
    }
  });
};

export default Requisition;
