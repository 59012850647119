import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CircularProgress, Pagination } from '@mui/material';
import SimpleTable from 'js/components/TriiboComponents/Tables/SimpleTable';
import { convertToDateDefault } from 'js/library/utils/helpers';
import * as S from './styles';
import { resultEvaluationStablishment } from 'js/library/utils/API/Curation/resultEvaluationStablishment';
import { getEstablishmentChangesRequest } from 'js/library/utils/API/Curation/getEstablishmentChangesRequest';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

const DataTableEstablishmentApproved = (props) => {
  const [column] = useState([
    { heading: 'Nome do estabelecimento', value: 'establishmentName' },
    { heading: 'Data de Avaliação', value: 'approvalDate' },
    { heading: 'Tipo', value: 'action' },
    { heading: 'Status', value: 'status' },
    { heading: 'Origem', value: 'origin' },
  ]);
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(dataTable.length / PER_PAGE);

  const _DATA = usePagination(dataTable, PER_PAGE);

  const handleChangePage = (e, p) => {
    _DATA.jump(p);
    setPage(p);
  };

  const history = useHistory();

  const redirectOnClick = (item) => {
    const promise = [getEstablishmentChangesRequest(item.id), getEstablishmentId(item.id)];

    Promise.allSettled(promise)
      .then((res) => {
        res[0].value && res[0].value.response.establishmentEdits
          ? history.push({
              pathname: props.pathName,
              state: res[0].value.response.establishmentEdits,
            })
          : history.push({
              pathname: props.pathName,
              state: res[1].value,
            });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const tranformData = (data) => {
    const dataFilter = data.filter((item) => {
      return item;
    });

    return dataFilter.map((value) => {
      return {
        ...value,
        action: value.action === 'edit' ? 'edição' : value.action === 'create' ? 'criação' : '',
        approvalDate: value.approvalDate ? convertToDateDefault(value.approvalDate) : 'Data',
        establishmentName: value.establishmentName ? value.establishmentName : '',
        origin: value.origin ? value.origin : 'Triibo',
        status: value.status ? value.status : '',
      };
    });
  };

  useEffect(() => {
    resultEvaluationStablishment(0, 20)
      .then((dataReceived) => {
        setDataTable(tranformData(dataReceived));
        setLoading(true);
      })
      .catch(() => {
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    if (count === page) {
      resultEvaluationStablishment(dataTable.length, 20)
        .then((dataReceived) => {
          setDataTable((prev) => [...prev, ...tranformData(dataReceived)]);
        })
        .catch(() => {});
    }
  }, [page]);

  return (
    <S.Container>
      {!loading ? (
        <div
          style={{
            margin: '0 auto',
            paddingTop: '10rem',
          }}
        >
          <CircularProgress size="60px" />
        </div>
      ) : (
        <>
          <SimpleTable
            data={_DATA.currentData()}
            column={column}
            usePaginate={false}
            redirectOnClick={redirectOnClick}
          />
          <Pagination
            sx={{ margin: '0 auto', marginBottom: '2rem' }}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={handleChangePage}
          />
        </>
      )}
    </S.Container>
  );
};

export default DataTableEstablishmentApproved;
