import React, { useState } from 'react';

import Banner from '../ModelsComponents/Banner';
import Highlights from '../ModelsComponents/Highlights';
import Carousels from '../ModelsComponents/Carousels';

import * as S from './styles';

const EditModules = (props) => {
  const [currentModel] = useState(props.location.state ? props.location.state : { type: 'banner' });

  const ConditionalRendering = () => {
    const renderModels = {
      banner: <Banner preForm={props.location.state} />,
      highlights: <Highlights preForm={props.location.state} />,
      carousels: <Carousels preForm={props.location.state} />,
    };

    return renderModels[currentModel.type];
  };

  return <S.Container>{ConditionalRendering()}</S.Container>;
};

export default EditModules;

