import React, { useEffect } from 'react';
import * as S from './styles';
import { TriiboH1 } from '../../DesignSystem/styles.js';
import { StyledButton } from '../AdminFunctions/styledButton';
import { MdArrowBackIosNew } from 'react-icons/md';
import { getSeuClube } from 'js/library/utils/API/seuClube';

const ClubMenu = ({ match }) => {
  const clubeId = document.location.pathname.split('/')[4];

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId).then((result) => {
        localStorage.setItem('partnerIdClube', result.partnerId);
      });
    }
  }, [clubeId]);

  const cardsList = [
    {
      value: 'Gerenciar membros',
      url: '/admin/seu-clube/relacionamento/cadastro-usuarios/',
    },
    {
      value: 'Gerenciar cards',
      url: '/admin/seu-clube/gerenciar-cards/',
    },
    {
      value: 'Estilizar clube',
      url: '/admin/seu-clube/',
    },
    {
      value: 'Gerenciar administradores',
      url: '/admin/seu-clube/gerir-admin/',
    },
    {
      value: 'Gerenciar postagens',
      url: '/admin/seu-clube/relacionamento/lista-postagem/',
    },
    {
      value: 'Gerenciar formulario de cadastro',
      url: '/admin/seu-clube/formulario/',
    },
    {
      value: 'Permissões de acesso',
      url: '/admin/seu-clube/permissoes-acesso/',
    },
    {
      value: 'Gerenciar palavras proibidas',
      url: '/admin/seu-clube/palavras-proibidas/',
    },
    {
      value: 'Segmentação',
      url: '/admin/seu-clube/segmentacao/',
    },
    {
      value: 'Listagem e upload de arquivos',
      url: `/admin/seu-clube/listagem-arquivos/`,
    },
    {
      value: 'Gerenciar Formulário NPS',
      url: '/admin/seu-clube/formulario-nps/',
    },
    {
      value: 'Campanhas',
      url: '/admin/seu-clube/campanhas/',
    },
    {
      value: 'Landing Page',
      url: '/admin/seu-clube/landing-page/',
    },
    {
      value: 'Gestão de pontos',
      url: '/admin/seu-clube/gestao-pontos/',
    },
  ];

  const cardsListOrdered = cardsList.sort((a, b) => {
    let x = a.value.toUpperCase(),
      y = b.value.toUpperCase();

    return x === y ? 0 : x > y ? 1 : -1;
  });
  return (
    <div style={{ padding: '4rem' }}>
      <S.AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <S.GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </S.GoBack>
      </S.AreaHeader>
      <S.Container>
        <TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3rem',
            marginTop: '-5rem',
          }}
        >
          Painel - Seu Clube
        </TriiboH1>
        <S.Grid>
          {cardsListOrdered.map((card, index) => (
            <StyledButton
              value={card.value}
              url={card.url + match.params.id}
              index={index}
              key={index}
            />
          ))}
        </S.Grid>
      </S.Container>
    </div>
  );
};

export default ClubMenu;
