import React, { useState, useRef, useEffect } from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';

import { getUserInfo } from 'js/library/utils/helpers';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';

import currentEstActionsAction from 'js/core/actions/currentEstActions';
import setEst from 'js/library/services/currentEstSetter';
import registerEstToLocal from 'js/library/services/registerEst';

import ImageDisplay from 'js/components/TriiboComponents/UploadImage/ImageDisplay';

import ImageGallery from 'js/components/TriiboComponents/UploadImage/ImageGallery';

import CropperModal from 'js/components/TriiboComponents/Cropper/CropperModal';
import CropperModalArr from 'js/components/TriiboComponents/Cropper/CropperModalArr';

import { SubmitNewEst } from './createFunctions';

import registerEstablishmentActionsAction from 'js/core/actions/authActionsRegisterEstab';

import { toast } from 'react-toastify';

const StepThree = (props) => {
  const {
    formState,
    progress,
    setProgress,
    setCurrentScreen,
    estThumb,
    setEstThumb,
    galleryArr,
    setGalleryArr,
    editPage,
    setDisabledButton,
  } = props;

  const [cropModalOne, setCropModalOne] = useState(false);
  const [cropModalTwo, setCropModalTwo] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(galleryArr.length);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const hiddenFileInput = useRef(null);

  const [imgPreview, setImgPreview] = useState(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setCurrentIndex(galleryArr[0] ? galleryArr.length : 0);
    // props.handleFile(fileUploaded);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (cropModalOne === true) {
        setEstThumb(reader.result);
      }
      //aqui seta a imagem quando abre o cropped
      if (cropModalTwo === true) {
        setImgPreview(reader.result);
      }
    };
    reader.readAsDataURL(files[0]);
    e.target.value = null;
  };

  useEffect(() => {
    const hasThumb = estThumb.length > 0 ? 50 : 0;
    const hasGallery = galleryArr.length > 0 ? 50 : 0;

    setProgress(() => 0 + hasThumb + hasGallery);
  }, [estThumb, galleryArr]);

  return (
    <>
      <CropperModal
        open={cropModalOne}
        setOpen={setCropModalOne}
        img={estThumb}
        setImg={setEstThumb}
      />
      <CropperModalArr
        open={cropModalTwo}
        setOpen={setCropModalTwo}
        img={galleryArr}
        imgPreview={imgPreview}
        setImgPreview={setImgPreview}
        setImg={setGalleryArr}
        index={currentIndex}
        push={false}
      />
      <S.SideContainer>
        <S.CardContainer id="card-01" blue={true} imageContainer={true}>
          <S.MainPhotoContainerDesktop>
            <ImageDisplay srcImg={estThumb} />
          </S.MainPhotoContainerDesktop>
          <S.MainPhotoTextContainer top={true}>
            <S.SectionLabelContainer white={true} flexStart={true}>
              <S.SectionLabelBig>Foto principal do seu negócio</S.SectionLabelBig>
              <S.SectionLabelSmall>
                Recomendamos usar uma imagem de até 10MB com pelo menos 400x300 pixels. Use um
                arquivo no formato PNG ou JPEG. E não se esqueça: as imagens precisam seguir as
                diretrizes da comunidade Triibo.
              </S.SectionLabelSmall>
            </S.SectionLabelContainer>
            <S.MainPhotoContainerMobile>
              <ImageDisplay srcImg={estThumb} />
            </S.MainPhotoContainerMobile>
            <S.PhotoButtons>
              <D.TriiboFilledButton
                disabled={formState.status && formState.status !== 'aberto'}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(e);
                  setCropModalOne(true);
                  setDisabledButton && setDisabledButton(false);
                }}
                white={true}
              >
                Adicionar foto
              </D.TriiboFilledButton>
              <D.TriiboWhiteButton
                disabled={formState.status && formState.status !== 'aberto'}
                onClick={(e) => {
                  e.preventDefault();
                  setEstThumb('');
                  setDisabledButton && setDisabledButton(false);
                }}
                transparent={true}
              >
                Remover
              </D.TriiboWhiteButton>
            </S.PhotoButtons>
          </S.MainPhotoTextContainer>
        </S.CardContainer>
        <S.CardContainer id="card-02" white={true} imageContainer={true}>
          <S.MainPhotoTextContainer bottom={true}>
            <S.SectionLabelContainer flexStart={true}>
              <S.SectionLabelBig>Lista de fotos</S.SectionLabelBig>
              <S.SectionLabelSmall>
                Essas imagens aparecerão na galeria do seu negócio dentro do App/Webb APP.
                Recomendamos usar uma imagem de até 10MB com pelo menos 400x300 pixels. Use um
                arquivo no fomato PNG ou JPEG. E não se esqueça: as imagens precisam seguir as
                diretrizes da comunidade Triibo.
              </S.SectionLabelSmall>
            </S.SectionLabelContainer>
            <S.PhotoButtons>
              <D.TriiboFilledButton
                disabled={formState.status && formState.status !== 'aberto'}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(e);
                  setCropModalTwo(true);
                  setDisabledButton && setDisabledButton(false);
                }}
                blue={true}
              >
                Adicionar fotos
              </D.TriiboFilledButton>
            </S.PhotoButtons>
          </S.MainPhotoTextContainer>
          <S.MainPhotoContainerDesktop>
            <S.GalleryContainer>
              <ImageGallery
                formState={formState}
                arrSrcImg={galleryArr}
                setArrSrcImg={setGalleryArr}
                setDisabledButton={setDisabledButton}
              />
            </S.GalleryContainer>
          </S.MainPhotoContainerDesktop>
        </S.CardContainer>
      </S.SideContainer>
      {!editPage ? (
        <S.ButtonContainer>
          <D.TriiboWhiteButton
            onClick={(e) => {
              e.preventDefault();
              setCurrentScreen(1);
            }}
            blue={true}
          >
            Etapa anterior
          </D.TriiboWhiteButton>

          <D.TriiboFilledButton
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();

              if (progress < 100) {
                toast.error(`Os seguintes campos ainda precisam ser preenchidos:
                ${!estThumb ? 'FOTO PRINCIPAL,' : ''}
                ${galleryArr.length <= 1 ? 'LISTA DE FOTOS' : ''}
                `);
                return;
              }

              setLoading(true);

              const uid = getUserInfo().uId;
              getTrialStatus(uid).then((result) => {
                updateTrialStatus({
                  establishmentStep: true,
                  storeStep: result.storeStep,
                  relationshipStep: result.relationshipStep,
                  congrats: result.congrats,
                });
                sessionStorage.setItem('modalSteps', 'true');
              });

              delete formState.contato;
              SubmitNewEst(formState, estThumb, galleryArr).then((payload) => {
                let establishment = { ...payload };
                establishment.key = payload.id;
                establishment.downloadedThumb = `https://firebasestorage.googleapis.com/v0/b/triibo-homol.appspot.com/o/estabelecimento%2Fthumb_${payload.id}?alt=media`;
                establishment.customHotspot = true;
                establishment.updateDate = Date.now();
                establishment.useFullLinks = [{}];
                establishment.aprovacao = null;
                establishment.addBusiness = false;

                const establishmentOrdered = Object.keys(establishment)
                  .sort()
                  .reduce((obj, key) => {
                    obj[key] = establishment[key];
                    return obj;
                  }, {});

                setEst(establishmentOrdered).then((result) => {
                  dispatch(currentEstActionsAction(result));
                });
                registerEstToLocal(establishmentOrdered).then((result) => {
                  dispatch(registerEstablishmentActionsAction(result));
                });
                history.push({
                  pathname: `/estabelecimento/${payload.id}/passos`,
                  state: payload,
                });
                setLoading(false);
              });
            }}
            blue={true}
            // disabled={progress !== 100 ? true : false}
          >
            Solicitar aprovação
          </D.TriiboFilledButton>
        </S.ButtonContainer>
      ) : (
        <></>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            alert('Arquivo excedeu 10MB');
            return;
          }
          handleChange(e);
        }}
        style={{ visibility: 'hidden' }}
      />
    </>
  );
};

export default StepThree;
