import styled from 'styled-components';

export const InputRadio = styled.input.attrs((props) => ({
  type: 'radio',
}))`
  margin-right: 1rem;
  width: ${({ size }) => size || '15px'};
  height: ${({ size }) => size || '15px'};
`;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
