import styled from 'styled-components';

import { Button } from '@mui/material';

export const ButtonDetails = styled(Button)`
  padding: 3px 6px;
  min-width: 30px;
  background: #6e3296;

  &:hover {
    background-color: #6e3296;
  }
`;
