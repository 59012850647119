import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { ListResourcesLinked } from './segmentStyles';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Chip,
  useMediaQuery,
} from '@mui/material';

// ICONES
import { MdArrowBackIosNew } from 'react-icons/md';
import { Add } from '@mui/icons-material';

// COMPONENTS
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { ModalDelete } from './ModalDelete';

// APIS
import { getAllResourceSegment } from 'js/library/utils/API/Segmentation/getAllResourceSegment';
import { linkResource } from 'js/library/utils/API/Segmentation/linkResource';
import { unLinkResource } from 'js/library/utils/API/Segmentation/unLinkResource';
import { getSegmentById } from 'js/library/utils/API/Segmentation/getSegmentById';

export default function LinkType() {
  const partnerId = localStorage.getItem('partnerIdClube');
  const id = document.location.pathname.split('/')[5];

  const [resourceSegmentList, setResourceSegmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const [segment, setSegment] = useState([]);
  const [resourceSelected, setResourceSelected] = useState(null);
  const [linkedResources, setLinkedResources] = useState([]);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(630));

  useEffect(() => {
    if (id) {
      setLoadingResults(true);
      getSegmentById(id)
        .then((res) => {
          setSegment(res);
        })
        .catch((e) => {
          console.error('Ocorreu um erro: ', e);
          setLoadingResults(false);
        })
        .finally(() => {
          setLoadingResults(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const payload = {
      partnerId: partnerId,
    };

    getAllResourceSegment(payload).then((res) => {
      const resourceSegment = Object.keys(res).map((id) => {
        const resource = res[id];
        const quantity = resource.id && Object.values(resource.id).length;
        const numberOfMembers = Object.keys(resource.partnerId).length;
        return {
          ...resource,
          id: id,
          active: resource.active ? 'Ativo' : 'Inativo',
          quantity,
          numberOfMembers,
        };
      });

      setResourceSegmentList(resourceSegment);
    });
  }, []);

  // filtra os recursos linkados a um segmento de usuário e compara com
  // o array que possui todos os segmentos de resursos e monta um novo
  // array de objetos com todas as informações de cada recurso
  const filterResourceSegments = () => {
    if (segment.resourceSegment) {
      const linkedIds = Object.keys(segment.resourceSegment);

      const filteredSegments = resourceSegmentList.filter((resource) =>
        linkedIds.includes(resource.id)
      );

      setLinkedResources(filteredSegments);
    }
  };

  useEffect(() => {
    filterResourceSegments();
  }, [resourceSegmentList, segment?.resourceSegment]);

  const handleChange = (event, value) => {
    setResourceSelected(value);
  };

  const handleAddResource = () => {
    const payload = {
      userSegmentId: segment?.id ?? id,
      resourceSegmentId: resourceSelected?.id,
    };

    setLoading(true);

    if (
      resourceSelected &&
      !linkedResources.some((resource) => resource.id === resourceSelected.id)
    ) {
      linkResource(payload)
        .then((res) => {
          setLinkedResources((prev) => [...prev, resourceSelected]);
          toast.success('Recurso incluído à lista.', { autoClose: 2000 });
          setResourceSelected(null);
        })
        .catch((e) => {
          console.error('Erro da API:', e);
          toast.error('Erro ao incluir o recurso. Tente novamente.', { autoClose: 2000 });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.warning('Recurso já incluído ou inválido.', { autoClose: 2000 });
    }
  };

  const handleDeleteChip = (resourceToDelete) => {
    const payload = {
      userSegmentId: segment?.id ?? id,
      resourceSegmentId: resourceToDelete?.id,
    };

    setLoadingDelete(true);

    unLinkResource(payload)
      .then((res) => {
        setLinkedResources((prev) => {
          const updatedList = prev.filter((resource) => resource.id !== resourceToDelete.id);
          return updatedList;
        });
        toast.success('Resurso removido da lista', { autoClose: 2000 });
        setResourceSelected(null);
        setOpenModalDelete(false);
      })
      .catch((e) => {
        console.error('Erro da API:', e);
        toast.error('Erro ao remover o recurso. Tente novamente.', { autoClose: 2000 });
        setLoadingDelete(false);
        setOpenModalDelete(false);
      })
      .finally(() => {
        setLoadingDelete(false);
        setOpenModalDelete(false);
      });
  };

  const isOptionDisabled = (option) => {
    return linkedResources.some((resource) => resource.id === option.id); // Desabilita no autocomplete se já foi adicionado
  };

  const handleOpenModalDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  return (
    <>
      <S.Container style={{ paddingBottom: '200px' }}>
        <AreaHeader
          style={{
            color: '#08bad0',
            justifyContent: 'end',
            padding: '1rem',
            marginLeft: '3rem',
          }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>
        <D.TriiboH1
          style={{
            textAlign: 'center',
          }}
        >
          Linkar recursos
        </D.TriiboH1>

        <Box sx={{ width: '70%' }}>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            width="100%"
            marginBottom="20px"
            sx={{ flexDirection: isMobile && 'column' }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={resourceSegmentList}
              getOptionLabel={(option) => option.name}
              sx={{ width: '100%' }}
              blurOnSelect
              noOptionsText={'Nenhum resultado encontrado...'}
              renderOption={(props, option) => (
                <span
                  key={option.id}
                  style={{
                    ...(isOptionDisabled(option) && { color: '#b9b9b9', cursor: 'not-allowed' }),
                  }}
                >
                  <li {...props}>{option.name}</li>
                </span>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionDisabled={isOptionDisabled} // Desabilita opções já adicionadas
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione um recurso"
                  onClick={() => setOptionsOpen(true)}
                  onFocus={() => setOptionsOpen(true)}
                  onBlur={() => setOptionsOpen(false)}
                />
              )}
              ListboxProps={{ style: { maxHeight: '200px' } }}
              open={optionsOpen}
              onClose={() => setOptionsOpen(false)}
              onChange={handleChange}
              value={resourceSelected}
            />
            <Button
              variant="contained"
              startIcon={!loading && <Add />}
              sx={{ padding: '8px 40px' }}
              onClick={handleAddResource}
              disabled={!resourceSelected || loading}
            >
              {loading ? (
                <CircularProgress size={30} style={{ color: '#fff', height: 'none' }} />
              ) : (
                'Adicionar'
              )}
            </Button>
          </Box>

          <Typography variant="subtitle1" sx={{ marginLeft: '2px' }}>
            Lista de recursos linkados:
          </Typography>
          <ListResourcesLinked>
            {linkedResources.length > 0 ? (
              linkedResources.map((resource, index) => (
                <Box key={index}>
                  <Chip
                    label={resource?.name}
                    onDelete={handleOpenModalDelete}
                    style={{
                      color: '#fff',
                      backgroundColor: '#CDA8E0',
                      margin: '4px',
                    }}
                  />
                  <ModalDelete
                    close={handleOpenModalDelete}
                    open={openModalDelete}
                    deleteType={() => handleDeleteChip(resource)}
                    loading={loadingDelete}
                  />
                </Box>
              ))
            ) : loadingResults ? (
              <CircularProgress size={30} />
            ) : (
              <Typography variant="subtitle1" style={{ color: '#b9b9b9' }}>
                Nenhum recurso linkado...
              </Typography>
            )}
          </ListResourcesLinked>
        </Box>
      </S.Container>
    </>
  );
}
