import React, { useState, useEffect } from 'react';
import previewPhoneInterfaceImg from 'styles/assets/previewPhoneInterface.png';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Button, Divider } from '@mui/material';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { downloadImage } from 'js/library/services/StorageManager.js';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
    position: 'relative',
    right: '1rem',
    top: '2rem',
  },

  content: {
    position: 'absolute',
    top: '13px',
    left: '8px',
    right: '0px',
    height: '244px',
    width: '212px',
    maxWidth: '212px',
    maxHeight: '244px',
    padding: '0px 8px ',
  },

  device: {
    position: 'relative',
    zIndex: '9',
    height: '446px',
    width: '227px',
  },

  img: {
    height: 'auto',
    display: 'block',
    maxWidth: 420,
    overflow: 'hidden',
    width: '100%',
  },

  title: {
    fontWeight: 'bold',
    marginTop: '7px',
  },

  subtitle: {
    fontSize: '13px',
  },

  button: {
    alignItems: 'end',
    marginLeft: '-6px',
    fontSize: '11px',
    textTransform: 'capitalize',
    zIndex: '10',
  },

  icons: {
    display: 'flex',
    flexDirection: 'row',
    alingItem: 'center',
    fontSize: '11px',
    marginTop: '5px',
  },

  divisor: {
    marginTop: '4px',
    backgroundColor: '#000',
  },

  divisor2: {
    backgroundColor: '#000',
  },

  textContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: ' 0px 6px ',
    overflow: 'hidden',
  },
}));

const imgLoading = [
  {
    label: 'Loading',
    imgPath:
      'https://www.iariv.com/public/main_assets/three_layout/icons/loader-info.gif',
  },
];

function PreviewMobalEstablishment({
  title,
  description,
  imagesList,
  ...props
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [estabImages, setEstabImages] = useState([]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const currentEstabImages = estabImages.length > 0 ? estabImages : imgLoading;

  useEffect(() => {
    const downloadThumb = () => {
      if (imagesList?.length > 0) {
        imagesList.forEach((imgPath) => {
          downloadImage('estabelecimento', imgPath)
            .then((downloaded) => {
              return setEstabImages((oldList) => [
                ...oldList,
                { label: 'thumbnail', imgPath: downloaded },
              ]);
            })
            .catch((error) => {
              // console.log('----->', error);
            });
        });
      }
    };

    setEstabImages([]);
    setActiveStep(0);
    downloadThumb();
  }, [imagesList]);

  return (
    <div className={classes.container}>
      <img alt='' className={classes.device} src={previewPhoneInterfaceImg} />
      <div className={classes.content}>
        <AutoPlaySwipeableViews
          autoplay={currentEstabImages.length <= 1 ? false : true}
          index={activeStep}
          onChangeIndex={handleStepChange}>
          {currentEstabImages.map((step, i) => (
            <div key={step.label + i}>
              <img
                className={classes.img}
                src={step.imgPath}
                alt={step.label}
              />
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <div className={classes.textContent}>
          <Box style={{ overflow: 'hidden' }}>
            <span className={classes.icons}>
              <ScheduleOutlinedIcon
                style={{ fontSize: 'small', marginRight: '3px' }}
              />
              Aberto agora
              <ExpandMoreOutlinedIcon
                style={{ fontSize: 'small', marginLeft: '3px' }}
              />
            </span>
            <Divider className={classes.divisor} />
            <Typography variant='subtitle2' className={classes.title}>
              {title || 'Nome do Estabelecimento'}
            </Typography>
            <Typography variant='caption' className={classes.subtitle}>
              {description || 'Descrição do estabelecimento...'}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <div>
              <Button
                className={classes.button}
                to={{ pathname: '/estabelecimento/:id' }}>
                Visite nosso site
              </Button>
            </div>
            <Divider className={classes.divisor2} />
            <span className={classes.icons}>
              <LocalOfferOutlinedIcon
                style={{ fontSize: 'small', marginRight: '3px' }}
              />
              Ofertas
            </span>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default PreviewMobalEstablishment;
