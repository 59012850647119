import React, { useState, useEffect } from 'react';

import {
    Box,
    Typography,
    Grid,
    CircularProgress,
} from '@mui/material';

import { MultiAxialBarChart } from '../EstablishmentCharts/BarChart';

//utils
import dataGraphics from 'js/library/utils/API/apiGraphics';
import { parseDate } from 'js/library/utils/helpers';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';

export default function ResultsScreen({ history, location }) {
    const [dataCoupons, setDataCoupons] = useState([]);
    const [dataConsume, setDataConsume] = useState([]);
    const [dataVisit, setDataVisit] = useState([]);
    const [dataClient, setDataClient] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const chartData = async () => {
            setLoading(true);
            try {
                const responseCoupons = await dataGraphics(
                    location.state.id,
                    'rep.portal_estabelecimento'
                );
                const responseConsume = await dataGraphics(
                    location.state.id,
                    'rep.consumo_voucher'
                );
                const responseVisit = await dataGraphics(
                    location.state.id,
                    'rep.voucher_registro_visita'
                );
                const responseClient = await dataGraphics(
                    location.state.id,
                    'rep.clientes_novos'
                );

                setDataCoupons(responseCoupons);
                setDataConsume(responseConsume);
                setDataVisit(responseVisit);
                setDataClient(responseClient);
                setLoading(false);
            } catch (error) {
                // console.log("ERROR_RESPONSE_GRAPHICS===>", error);
            }
        };
        chartData();
    }, [location.state.id]);

    const chartCoupons = dataCoupons.map((coupon) => ({
        [`dia ${parseDate(coupon.data_consumido)}`]: coupon.cupons_consumidos_dia,
    }));

    const chartConsume = dataConsume.map((coupon) => ({
        [`dia ${parseDate(coupon.dia_consumido)}`]: coupon.qtd_clientes,
    }));

    const chartVisit = dataVisit.map((coupon) => ({
        [`dia ${parseDate(coupon.dia_consumido)}`]: coupon.qtd_clientes,
    }));

    const chartClient = dataClient.map((coupon) => ({
        [`dia ${parseDate(coupon.primeiro_consumo)}`]: coupon.qtd_clientes,
    }));

    return (
        <Box style={{ padding: '4rem 32px' }}>
            <GoBackHeader />
            <HeaderTitle pageInfo='Resultados'/> 
            <Box pr={3} pt={4}>
                <Grid container>
                    <Grid item sm={6}>
                        <Box display="flex" flexDirection="column">
                            <Typography
                                variant="h6"
                                component="span"
                                align="center"
                                // classes={{ h6: classes.chartText }}
                                sx={{fontWeight: 'bold'}}
                            >
                Quantidade de cupons consumidos
                            </Typography>

                            {!loading ? (
                                <MultiAxialBarChart
                                    data={chartCoupons.slice(
                                        Math.max(chartCoupons.length - 7, 0)
                                    )}
                                />
                            ) : (
                                <Box
                                    height={250}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress size={70} />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box display="flex" flexDirection="column">
                            <Typography
                                variant="h6"
                                component="span"
                                align="center"
                                // classes={{ h6: classes.chartText }}
                                sx={{fontWeight: 'bold'}}
                            >
                Quantidade de clientes com consumo
                            </Typography>
                            {!loading ? (
                                <MultiAxialBarChart
                                    data={chartConsume.slice(
                                        Math.max(chartConsume.length - 7, 0)
                                    )}
                                />
                            ) : (
                                <Box
                                    height={250}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress size={70} />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <br />

                <Grid container>
                    <Grid item sm={6}>
                        <Box display="flex" flexDirection="column">
                            <Typography
                                variant="h6"
                                component="span"
                                align="center"
                                // classes={{ h6: classes.chartText }}
                                sx={{fontWeight: 'bold'}}
                            >
                Quantidade de registros de visitas
                            </Typography>
                            {!loading ? (
                                <MultiAxialBarChart
                                    data={chartVisit.slice(Math.max(chartVisit.length - 7, 0))}
                                />
                            ) : (
                                <Box
                                    height={250}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress size={70} />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box display="flex" flexDirection="column">
                            <Typography
                                variant="h6"
                                component="span"
                                align="center"
                                // classes={{ h6: classes.chartText }}
                                sx={{fontWeight: 'bold'}}
                            >
                Quantidade de clientes novos
                            </Typography>
                            {!loading ? (
                                <MultiAxialBarChart
                                    data={chartClient.slice(Math.max(chartClient.length - 7, 0))}
                                />
                            ) : (
                                <Box
                                    height={250}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    
                                >
                                    <CircularProgress size={70} />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <br />

                {/* <Grid container>
          <Grid item sm={6}>
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h6"
                component="span"
                align="center"
                classes={{ h6: classes.chartText }}
              >
                Usuários recorrentes
              </Typography>

              <MultiAxialBarChart data={USERS_DATA} />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h6"
                component="span"
                align="center"
                classes={{ h6: classes.chartText }}
              >
                Usuários novos
              </Typography>

              <MultiAxialBarChart data={USERS_DATA} />
            </Box>
          </Grid>
        </Grid> */}

                <br />

                {/* <Grid container>
          <Grid item sm={6}>
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h6"
                component="span"
                align="center"
                classes={{ h6: classes.chartText }}
              >
                Usuários que consumiram promoções
              </Typography>

              <Table />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h6"
                component="span"
                align="center"
                classes={{ h6: classes.chartText }}
              >
                Avaliação dos clientes
              </Typography>

              <br />
              <br />

              <span>
                <StarIcon classes={{ root: classes.star }} />
                <StarIcon classes={{ root: classes.star }} />
                <StarIcon classes={{ root: classes.star }} />
                <StarIcon classes={{ root: classes.star }} />
                <StarIcon classes={{ root: classes.star }} />
              </span>

              <br />
              <br />
              <br />
            </Box>
          </Grid>
        </Grid> */}
            </Box>
        </Box>
    );
}
