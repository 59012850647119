import React, { useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import { RadiosEnum } from '../types';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import ModelBG from './model';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ModelLPSignUp from '../../LandingPageSignUp/model';

function BackgroundModal({ closeModal, isActiveModals, setPayload, selectedModalInfos }) {
  const [selectedRadio, setSelectedRadio] = React.useState(RadiosEnum.noBg);
  const [imageLayout, setImageLayout] = React.useState('fixed');
  const [value, setValue] = React.useState('');
  const [imgDesktop, setImgDesktop] = React.useState('');
  const [imgMobile, setImgMobile] = React.useState('');
  const [isActiveOverflow, setIsActiveOverflow] = React.useState(true);
  const [imagesLoading, setImagesLoading] = React.useState(false);

  const modalRef = useRef();
  const params = useParams();

  const isSelectedNoBgOption = selectedRadio === RadiosEnum.noBg;
  const isSelectedColorOption = selectedRadio === RadiosEnum.color;
  const isSelectedImageOption = selectedRadio === RadiosEnum.img;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    overflowY: isActiveOverflow ? 'scroll' : 'hidden',
  };

  const currentModal = ModelLPSignUp.modalsList.background;

  const handleChange = ({ target }) => {
    setSelectedRadio(target?.value);
  };

  const selectColor = (newValue) => {
    setValue(newValue);
  };

  const clearStates = () => {
    setImgMobile('');
    setImgDesktop('');
    setValue('');
  };

  const handleSave = async (partnerId) => {
    if (isSelectedNoBgOption) {
      setPayload((prevState) => ({
        campaignLandpage: {
          ...prevState.campaignLandpage,
          background: {
            type: null,
            value: null,
            desktopImage: null,
            mobileImage: null,
            imageLayout: null,
          },
        },
      }));

      toast.success('Background salvo com sucesso!');
      return closeModal(currentModal);
    }

    if (isSelectedColorOption) {
      if (!value) {
        toast.error('Escolha uma cor!');
        return;
      }

      setPayload((prevState) => ({
        campaignLandpage: {
          ...prevState.campaignLandpage,
          background: {
            type: 'color',
            value,
          },
        },
      }));

      toast.success('Background salvo com sucesso!');

      return closeModal(currentModal);
    }

    if (isSelectedImageOption) {
      if (!imageLayout) {
        toast.error('Preencha todos os campos!');
        return;
      }

      if (!imgDesktop || !imgMobile) {
        toast.error('Selecione as imagens!');
        return;
      }

      if (imgDesktop.includes('base64') || imgMobile.includes('base64')) {
        setImagesLoading(true);
        const [imgDesktopPath, imgMobilePath] = await Promise.all([
          imgDesktop.includes('base64') && ModelBG.uploadBgImage(imgDesktop, partnerId),
          imgMobile.includes('base64') && ModelBG.uploadBgImage(imgMobile, partnerId),
        ]);

        if (imgDesktopPath || imgMobilePath) {
          setPayload((prevState) => ({
            campaignLandpage: {
              ...prevState.campaignLandpage,
              background: {
                type: 'image',
                desktopImage: imgDesktopPath ? imgDesktopPath : imgDesktop,
                mobileImage: imgMobilePath ? imgMobilePath : imgMobile,
                imageLayout: imageLayout,
              },
            },
          }));
          toast.success('Background salvo com sucesso!');
          setImagesLoading(false);
          clearStates();
          closeModal(currentModal);
        }
      } else {
        clearStates();
        closeModal(currentModal);
      }
    }
  };

  const setScrollPosition = (element, scroll) => {
    element.current.scrollTop = scroll;

    if (scroll === 0) setIsActiveOverflow(false);
    else setIsActiveOverflow(true);
  };

  const renderColorInput = isSelectedColorOption && (
    <SketchPicker color={value} onChange={(event) => selectColor(event.hex)} />
  );

  const renderImgLayoutOptions = ModelBG.typeLayoutImg.map(({ option, value }) => (
    <MenuItem key={value} value={value}>
      {option}
    </MenuItem>
  ));

  const renderUploadImage = isSelectedImageOption && (
    <Grid
      flexWrap={'wrap'}
      alignItems={'center'}
      columnGap={'10px'}
      display={'flex'}
      rowGap={'15px'}
      justifyContent={'center'}
    >
      <FormControl fullWidth margin="normal">
        <InputLabel id="imgLayout">Selecione o layout da imagem</InputLabel>
        <Select
          inputProps={{
            name: 'imageLayout',
          }}
          onChange={(event) => setImageLayout(event.target.value)}
          label="Selecione o layout da imagem"
          value={imageLayout}
          margin="dense"
          size="20"
        >
          {renderImgLayoutOptions}
        </Select>
      </FormControl>

      <Box display={'flex'} alignItems={'center'} rowGap={'5px'} flexDirection={'column-reverse'}>
        <ImportImage
          imageDefault={null}
          autoWidth={true}
          image={imgDesktop}
          setImage={setImgDesktop}
          ratio={imageLayout === 'repeat' ? false : 16 / 9}
          refScroll={modalRef}
          setIsActiveOverflow={setIsActiveOverflow}
          setScrollPosition={setScrollPosition}
        />
        <Typography paddingLeft={'3px'}>Desktop</Typography>
      </Box>

      <Box display={'flex'} alignItems={'center'} rowGap={'5px'} flexDirection={'column-reverse'}>
        <ImportImage
          imageDefault={null}
          autoWidth={true}
          image={imgMobile}
          setImage={setImgMobile}
          ratio={imageLayout === 'repeat' ? false : 9 / 19}
          refScroll={modalRef}
          setIsActiveOverflow={setIsActiveOverflow}
          setScrollPosition={setScrollPosition}
        />
        <Typography paddingLeft={'3px'}>Mobile</Typography>
      </Box>
    </Grid>
  );

  useEffect(() => {
    if (isActiveModals) {
      const { modalInfos } = selectedModalInfos;

      if (modalInfos?.type) setSelectedRadio(modalInfos.type);

      if (modalInfos?.type === RadiosEnum?.color) {
        setValue(modalInfos?.value);
      }

      if (modalInfos?.type === RadiosEnum?.img) {
        setImageLayout(modalInfos?.imageLayout);
        setImgDesktop(modalInfos?.desktopImage);
        setImgMobile(modalInfos?.mobileImage);
      }
    }
  }, [isActiveModals, selectedModalInfos]);

  return (
    <Modal defaultValue={RadiosEnum.noBg} open={isActiveModals}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        ref={modalRef}
        sx={style}
      >
        <FormControl>
          <RadioGroup row>
            <FormControlLabel
              value={RadiosEnum.noBg}
              control={<Radio onChange={handleChange} checked={isSelectedNoBgOption} />}
              label="Sem backgound"
            />
            <FormControlLabel
              value={RadiosEnum.color}
              control={<Radio onChange={handleChange} checked={isSelectedColorOption} />}
              label="Cor sólida"
            />
            <FormControlLabel
              value={RadiosEnum.img}
              control={<Radio onChange={handleChange} checked={isSelectedImageOption} />}
              label="Imagem"
            />
          </RadioGroup>
        </FormControl>

        {!isSelectedNoBgOption && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            margin={'30px 0'}
            width={'100%'}
          >
            {renderColorInput}
            {renderUploadImage}
          </Box>
        )}

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          columnGap={'12px'}
          width={'100%'}
        >
          {imagesLoading ? (
            <Button variant="contained" color="primary">
              <CircularProgress style={{ color: '#fff' }} size={'30px'} />
            </Button>
          ) : (
            <>
              <Button variant="contained" color="primary" onClick={() => handleSave(params?.id)}>
                salvar
              </Button>

              <Button variant="contained" color="primary" onClick={() => closeModal(currentModal)}>
                cancelar
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default BackgroundModal;
