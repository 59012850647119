import React from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { AreaHeader, GoBack } from '../styles';

export function GoBackHeader({history}) {

  return (
   <>
   <AreaHeader
   style={{ 
    color: '#08bad0', 
    justifyContent: "end", 
    padding: "1rem",
    marginLeft: "1rem",

     }}>
   <GoBack
          onClick={() => {
            window.history.back();
          }}>
          <MdArrowBackIosNew style={{justifyContent: 'center'}} /> Voltar
        </GoBack>
      </AreaHeader>
    </>
  );
}