import { firebaseAuth } from 'js/library/utils/firebaseUtils';

import { getUserInfo } from 'js/library/utils/helpers.js';

import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';

import { downloadImage } from 'js/library/services/StorageManager';
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';
import { CheckEstAdmin } from '../utils/API/SearchUser/apiCheckEstAdmin';
import { getEstablishmentChangesRequest } from '../utils/API/Curation/getEstablishmentChangesRequest';

//função de login do usuário
export function loginUser(email, password) {
  return new Promise((resolve, reject) => {
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(function (login) {
        return resolve(login);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
}

//função de logout
export function logout() {
  window.localStorage.clear();
  return new Promise((resolve) => {
    firebaseAuth.signOut().then(function (logout) {
      resolve(logout);
    });
  });
}

export function checkUserStatus() {
  return new Promise((resolve) => {
    firebaseAuth.onAuthStateChanged(function (user) {
      return resolve(user);
    });
  });
}

export function verificaPerfil(cellphone) {
  return new Promise((resolve, reject) => {
    userReadCellphone_v3(cellphone)
      .then((result) => {
        if (result.userInfo.admin === true) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      })
      .catch((e) => reject(e));
  });
}

export function listarEstabelecimentos() {
  return new Promise(async (resolve) => {
    const result = await CheckEstAdmin(getUserInfo().uId, 'all');
    if (result && result.length > 0) {
      let establishmentListInfo = [];

      result.map(async (establishment, i) => {
        const res = await getEstablishmentChangesRequest(establishment.id);
        const { establishmentApprovals } = res.response;
        const promiseEstablishmentApprovals = Object.values(establishmentApprovals).map((res) => {
          return res;
        });

        let establishmentItem = establishment;
        establishmentItem.key = establishment.id;
        establishmentItem.aprovacao = promiseEstablishmentApprovals;

        downloadImage('estabelecimento', establishmentItem.fotoThumb)
          .then(async (downloadedImage) => {
            establishmentItem.downloadedThumb = downloadedImage;
            establishmentListInfo[i] = establishmentItem;

            if (establishmentListInfo.length >= result.length) {
              return resolve(establishmentListInfo);
            }
          })
          .catch(() => {
            establishmentItem.downloadedThumb = placeholder;
            establishmentListInfo[i] = establishmentItem;

            if (establishmentListInfo.length >= result.length) {
              return resolve(establishmentListInfo);
            }
          });
      });
    } else {
      return resolve([]);
    }
  });
}

