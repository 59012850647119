import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoTriibo from 'styles/assets/Logo_Triibo_svg.svg';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IoIosAddCircleOutline } from 'react-icons/io';

import IconEstablishmentNegative from '../Establishment/Header/IconEstablishmentNegative';

export const Header = styled.header`
  z-index: 501;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  justify-content: center;
  padding-left: 1.8rem;
  padding-right: 1rem;
  height: 3.65rem;
  width: 100%;
  top: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);

  @media (min-width: 600px) {
    padding-left: 4rem;
    padding-right: 2rem;
  }

  @media (min-width: 900px) {
    padding-left: 6rem;
    padding-right: 5rem;
  }
`;

export const HeaderContents = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const InterfaceContainer = styled.span`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 0.5rem;
  min-width: 5rem;
  cursor: pointer;
`;

export const EstablishmentPopper = styled.nav`
  position: absolute;
  background-color: white;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  top: 2.95rem;
  min-height: 2rem;
  height: 60vh;
  max-width: 30rem;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.25);
  transform: none;
  transform-origin: left top;
  transition: opacity 247ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 165ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${({ open }) =>
    !open &&
    `
        opacity: 0;
        visibility: hidden;
    `}

  ${({ open }) =>
    open &&
    `
        opacity: 1;
        visibility: visible;
    `}
`;

export const AvatarContainer = styled.div.attrs((props) => ({
  alt: props.Alt || 'avatar estabelecimento',
}))`
  background: ${(props) => `url(${props.currentThumb}) no-repeat top center`};
  background-size: cover;

  width: 30px;
  height: 30px;
  border-radius: 50%;

  @media (min-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

export const EstName = styled.h6`
  display: flex;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 1rem;
  transition: color 0.5s ease-in-out 0s;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.6;
  margin: 0;
  color: gray;
  overflow: hidden;
  max-width: 6rem;

  @media (min-width: 600px) {
    font-size: 1.25rem;
    max-width: 8rem;
  }

  ${({ open }) =>
    open &&
    `
        color: #6E3296;
    `}
`;

export const ArrowIcon = styled(MdKeyboardArrowDown)`
  font-size: 2rem;
  line-height: 0;
  color: #6e3296;

  ${({ open }) =>
    !open &&
    `
        transform: rotate(0deg);
        transition: 0.35s ease-in-out;

    `}

  ${({ open }) =>
    open &&
    `
        transform: rotate(-180deg);
        transition: 0.35s ease-in-out;

    `}
`;

export const AddIcon = styled(IoIosAddCircleOutline)`
  font-size: 1.2rem;
  line-height: 0;
  color: white;
`;

export const InterfaceButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  background-color: white;
  border: none;
  padding: 6px 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: 0.15s ease-in-out;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.05s ease-in-out;
  }

  ${({ purple }) =>
    purple &&
    `
        background-color: #6E3296;


        &:hover {
        background-color: #6E3296;
        transition: 0.15s ease-in-out;
    }

        &:active {
        background-color: #6E3296;
        transition: 0.05s ease-in-out;
    }
    `}
`;

export const IconEstablishment = styled(IconEstablishmentNegative)`
  width: 1.2rem;
  height: 1.2rem;
`;

export const EstNameButton = styled.h6`
  line-height: 1.75;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  max-width: 8.6rem;
  font-weight: 500;
  color: gray;
  margin: 0.6rem;

  ${({ $purple }) =>
    $purple &&
    `
        color: white;
    `}
`;

export const HeaderLink = styled(Link)`
  text-decoration: none;
  color: gray;
  cursor: pointer;
`;

export const ImgContainer = styled.img.attrs((props) => ({
  src: props.Img || logoTriibo,
  alt: props.Alt || 'logo Triibo',
}))`
  width: 98px;

  @media (min-width: 600px) {
    width: 112px;
  }
`;

