import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function createCard(data) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/cards/new',
          {
            ...data,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log('RESULTADO DA CRIACAO DE CARD ==>', result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR NA CRIACAO DE CARD ==>", error);
          return reject(error);
        });
    });
  });
}

export function updateCard(cardId, data) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .put(
          cfac22('API_HOST_V2') + '/cards/' + cardId,
          {
            ...data,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log('RESULTADO DA ATUALIZACÃO DO CARD ==>', result);
          resolve(result);
        })
        .catch((error) => {
          // console.log('ERROR NA ATUALIZACÃO DO CARD ==>', error);
          return reject(error);
        });
    });
  });
}
