import React, { useEffect, useState } from 'react';

// STYLES
import { MenuItem } from '@mui/material';
import * as D from 'js/components/DesignSystem/styles';
import * as S from '../styles';
import { Selector } from './styles';

//OTHERS
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { createDatabase } from 'js/library/services/DatabaseManager';
import { firebaseStorage } from 'js/library/utils/firebaseUtils';
import { downloadImageAsBlob } from 'js/library/services/StorageManager';
import { b64toBlob } from 'js/library/utils/helpers';

//APIS
import { setSeuClube, updateSeuClube } from 'js/library/utils/API/seuClube';
import { updateOrg } from 'js/library/utils/API/updateOrg';
import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';

//Components
import { ModalCreateClub } from '../ModalCreateClub';
import TextAreaComponent from 'js/components/DesignSystem/TextArea';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import Switch from 'react-switch';
import { GoBackHeader } from '../RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../RelationshipClub/HeaderTitle';

export default function BasicRegistration() {
  const userInfo = useSelector((state) => state.codeInputModel.userInfo);
  const [clubName, setClubName] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [url, setUrl] = useState('');
  const [org, setOrg] = useState([]);
  const [keywordApi, setKeywordApi] = useState('');
  const [status, setStatus] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [orgDescription, setOrgDescription] = useState('');
  const [orgThumbnailImage, setOrgThumbnailImage] = useState('');
  const [orgCoverImage, setOrgCoverImage] = useState('');
  const [orgLogoImage, setOrgLogoImage] = useState('');
  const [orgVisible, setOrgVisible] = useState(false);

  const uploadingFile = async (file, pushId, type) => {
    let imageFormated;

    if (file.indexOf('https') === 0) {
      const imageBlob = await downloadImageAsBlob(file);
      imageFormated = imageBlob;
    } else {
      imageFormated = b64toBlob(file);
    }

    let imagePath = '';
    const currentDate = new Date().getTime();

    const newStorageRef = firebaseStorage
      .ref('organizations')
      .child(`storage:${pushId}:${type}:${currentDate}`);

    await newStorageRef.put(imageFormated).then((result) => {
      imagePath = result._delegate.ref._location.path_;
    });

    return imagePath;
  };

  const addOrgAndKeywords = async (push) => {
    let keywordsInfo = {
      value: subdomain + '_seuclube',
      status: 0,
      createDate: Date.now(),
    };

    if (push !== '' && push !== undefined && push !== null) {
      createDatabase('Keywords', keywordsInfo, null);

      const newInfo = {
        orgID: push,
        accessMethod: 'private',
        coverImage: await uploadingFile(orgCoverImage, push, 'cover'),
        bannerImage: 'image',

        description: orgDescription,
        lockdown: false,
        thumbnail: await uploadingFile(orgThumbnailImage, push, 'thumbnail'),

        // logoImage: await uploadingFile(orgLogoImage, push, 'logo'),

        name: subdomain,
        owner: userInfo.uId,
        tags: ['seuClube'],
        type: 'triibo',
        visible: orgVisible,
      };

      updateOrg(
        userInfo.uId,
        newInfo.orgID, // required - const mode - check if has
        newInfo // org info
      );
    }
  };

  async function registerClub() {
    if (subdomain === '' || url === '') {
      toast.warn('⛔ O domínio e o subdomínio devem ser informados');
      return;
    }

    if (!orgDescription) {
      toast.warn('⛔ A descrição da organização deve ser informada.');
      return;
    }

    if (!orgThumbnailImage || !orgCoverImage) {
      toast.warn('⛔ As imagens da organização devem ser informadas');
      return;
    }

    try {
      const whatsapp = {
        active: false,
      };
      const pushReceived = await setSeuClube(subdomain, url, clubName, status, whatsapp);
      setOrg(pushReceived.data.result.id);
      setKeywordApi(subdomain + '_seuclube');

      addOrgAndKeywords(pushReceived.data.result.id);
      setOpenModal(true);
    } catch (e) {
      toast.warn('⛔ Subdomínio do clube já existe !');
    }
  }

  // Adiciona a org e a Kw no super admin que criou o clube e adiciona KW e org padrão do seu clube
  useEffect(() => {
    const cellphone = userInfo.contactList.map((item) => {
      if (item.type === 'cellPhone') {
        return item.value;
      }
    });

    const bunch = [{ cellphone: cellphone[0], clubeId: subdomain, name: userInfo.name }];

    if (keywordApi !== '' && org !== '') {
      const payload = {
        clubeId: subdomain,
        orgs: [org],
        keywords: [keywordApi],
      };

      updateSeuClube(payload);
      RegisterUsersBatch_v2(bunch);
    } else {
      return 0;
    }
  }, [keywordApi, org]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleDomain(e) {
    setUrl(e.target.value);
  }

  return (
    <S.CreateClubContainer style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cadastro de clube" />
      <S.EditContainer>
        <ModalCreateClub close={handleCloseModal} openModal={openModal} subdomain={subdomain} />
        <S.FormCard>
          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Nome do clube:</S.FormLabel>
            <S.SearchContainer></S.SearchContainer>
            <D.TriiboTextField
              rows={1}
              placeholder={'Nome do clube'}
              maxLength="25"
              value={clubName}
              onChange={(e) => setClubName(e.target.value)}
              onBlur={(e) => e.preventDefault()}
              onFocus={(e) => e.preventDefault()}
            />
          </S.FormGroup>
          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Subdomínio:</S.FormLabel>
            <S.SearchContainer></S.SearchContainer>
            <D.TriiboTextField
              rows={1}
              placeholder={'Subdomínio'}
              maxLength="25"
              value={subdomain}
              onChange={(e) => setSubdomain(e.target.value.toLowerCase().replace(/\s/g, ''))}
              onBlur={(e) => e.preventDefault()}
              onFocus={(e) => e.preventDefault()}
            />
          </S.FormGroup>
          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Domínio:</S.FormLabel>
            <Selector required value={url} label="Dominio" onChange={handleDomain}>
              <MenuItem disabled value="">
                <em>Selecione um domínio</em>
              </MenuItem>
              <MenuItem value=".seuclube.org">seuclube.org</MenuItem>
              <MenuItem value=".nossoclube.org">nossoclube.org</MenuItem>
            </Selector>
          </S.FormGroup>

          <S.StatusClub>
            <S.RadioBox
              type="button"
              onClick={() => setStatus(true)}
              isActive={status === true}
              activeColor="green"
            >
              <span>Ativo</span>
            </S.RadioBox>

            <S.RadioBox
              type="button"
              onClick={() => setStatus(false)}
              isActive={status === false}
              activeColor="red"
            >
              <span>Inativo</span>
            </S.RadioBox>
          </S.StatusClub>
          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Descrição da organização:</S.FormLabel>
            <TextAreaComponent
              value={orgDescription}
              setValue={setOrgDescription}
              placeholder={'Descrição da organização'}
            />
          </S.FormGroup>
          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Foto thumbnail da organização (4x4):</S.FormLabel>
            <ImportImage image={orgThumbnailImage} setImage={setOrgThumbnailImage} ratio={4 / 4} />
          </S.FormGroup>

          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Foto de capa da organização(4x3):</S.FormLabel>
            <ImportImage image={orgCoverImage} setImage={setOrgCoverImage} ratio={4 / 3} />
          </S.FormGroup>

          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Foto de logo da organização(95x12):</S.FormLabel>
            <ImportImage image={orgLogoImage} setImage={setOrgLogoImage} ratio={95 / 12} />
          </S.FormGroup>

          <S.FormGroup spacing="0.5rem">
            <S.FormLabel>Organização será visivel?</S.FormLabel>
            <Switch
              onColor="#6e3296"
              checked={orgVisible}
              onChange={() => setOrgVisible(!orgVisible)}
            />
          </S.FormGroup>

          <D.TriiboFilledButton style={{ background: '#06BAD0' }} onClick={registerClub}>
            Gerar Clube
          </D.TriiboFilledButton>
        </S.FormCard>
      </S.EditContainer>
    </S.CreateClubContainer>
  );
}
