import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  generateQRCodeAction,
  listQRCodeAction,
  deleteQRCodeAction,
} from "js/core/actions/QRCodeActions";
import { downloadImage } from "js/library/services/StorageManager";
import { getUserInfo } from "js/library/utils/helpers";
import { firebaseDatabase } from "js/library/utils/firebaseUtils";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  Modal,
  Paper,
} from "@mui/material";
import { Cropper } from "react-image-cropper";
import promoValidateHeader from "styles/assets/cracha/promoValidateHeader.jpg";
import QRCode from "qrcode.react";
import QRCodeInformation from "js/components/Store/QRCode/QRCodeInformation";
import QRCodeList from "js/components/Store/QRCode/QRCodeList";
import Loading from "js/containers/Loading/Loading";

import useStyles from "./styles";
import { getDataList } from "js/library/services/DatabaseManager";

const QRCodeGenerate = ({
  location,
  listQRCodeComponent,
  history,
  generate,
  list,
  deleteState,
  generateQRCodeComponent,
  deleteQRCodeComponent,
}) => {
  const [loading, setLoading] = useState(false);

  const [promoName, setPromoName] = useState("");
  const [promoDescription, setPromoDescription] = useState("");
  const [promoAction, setPromoAction] = useState(0);
  const [openQRCodeInformation, setOpenQRCodeInformation] = useState(false);
  const [promoId, setPromoId] = useState(null);
  const [qrCodeId, setQRCodeId] = useState(null);
  const [qrCodeShortLink, setQRCodeShortLink] = useState(
    "https://triibo.com.br/"
  );

  const fileUploader = useRef(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [imageToCropRef, setImageToCropRef] = useState(null);
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const [imageCropped, setImageCropped] = useState(promoValidateHeader);

  const [qrCodeList, setQRCodeList] = useState([]);
  const [currentQRCode, setCurrentQRCode] = useState(null);

  const imageContainerRef = useRef(null);
  const imageCroppedRef = useRef(null);
  const imageTitleRef = useRef(null);
  const imageDescriptionRef = useRef(null);

  const getLink = (id) => {
    const dynamicLinkKey = "AIzaSyDqzPWDKUb24RGQWGfLyNXsfBTukuwavRc";
    const dynamicLinkApi = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${dynamicLinkKey}`;
    const dynamicLinkDomain = "mj8vs.app.goo.gl";
    const androidPackageName = "br.com.sysmobil.triiboclient";
    const iosPackageName = "br.com.triiboclient";
    const iosStoreId = "1118996431";
    const baseLink = "https://triibo.com.br";
    const finalLink = `${baseLink}?qrCode=${id}`;

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain: dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName: androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    return new Promise(function async(resolve, reject) {
      axios
        .post(dynamicLinkApi, linkRequest)
        .then((result) => {
          resolve(result.data.shortLink);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const toggleQRCodeInformation = (open) => {
    setOpenQRCodeInformation(open);
  };

  const handleValueChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0] !== undefined) {
      const data = URL.createObjectURL(e.target.files[0]);
      setImageToCrop(data);
      setOpenImageCropper(true);
    }
  };

  const handleCropImage = () => {
    const newImage = imageToCropRef.crop();
    setImageCropped(newImage);
    setOpenImageCropper(false);
  };

  const handleSubmit = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const containerRef = imageContainerRef.current;
    const imgCroppedRef = imageCroppedRef.current;
    const titleRef = imageTitleRef.current;
    const descriptionRef = imageDescriptionRef.current;
    const qrCodeRef = document.querySelector("#QRCode");

    // con = Math.ceil(1300 / containerRef.clientWidth);
    let marginTop = 0;

    containerRef.style.width = 1240;
    containerRef.style.height = 1748;

    const containerWidth = 1240;
    const containerHeight = 1748;

    canvas.width = containerWidth;
    canvas.height = containerHeight;

    imgCroppedRef.width = imgCroppedRef.clientWidth;

    titleRef.style.width = `${titleRef.clientWidth}px`;
    titleRef.style.height = `${titleRef.clientHeight}px`;

    descriptionRef.style.width = `${descriptionRef.clientWidth}px`;
    descriptionRef.style.height = `${descriptionRef.clientHeight}px`;

    qrCodeRef.style.width = `${qrCodeRef.clientWidth}px`;

    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, 1240, 1748);

    ctx.drawImage(
      imgCroppedRef,
      0,
      marginTop,
      1240,
      700,
    );
    marginTop += 700;

    const titleCanvas = document.createElement("canvas");
    const titleCtx = titleCanvas.getContext("2d");
    titleCanvas.width = 1240;
    titleCanvas.height = 100;
    titleCtx.fillStyle = "#ffa500";
    titleCtx.fillRect(0, 0, 1240, 100);
    titleCtx.font = `bold ${54}px sans-serif`;
    titleCtx.fillStyle = "#fff";
    titleCtx.textAlign = "center";
    titleCtx.textBaseline = "middle";
    titleCtx.fillText(
      promoName,
      parseInt(1240 / 2),
      parseInt(100 / 2),
      1240
    );
    ctx.drawImage(
      titleCanvas,
      0,
      marginTop,
      1240,
      100
    );
    marginTop += 110;

    const descriptionCanvas = document.createElement("canvas");
    const descriptionCtx = descriptionCanvas.getContext("2d");
    descriptionCanvas.width = 1240;
    descriptionCanvas.height = 80;
    descriptionCtx.fillStyle = "transparent";
    descriptionCtx.fillRect(
      0,
      0,
      1240,
      80
    );
    descriptionCtx.font = `bold ${40}px sans-serif`;
    descriptionCtx.fillStyle = "#000";
    descriptionCtx.textAlign = "center";
    descriptionCtx.textBaseline = "middle";
    descriptionCtx.fillText(
      "Escaneie o QR Code abaixo:",
      parseInt(1240 / 2),
      parseInt(80 / 2),
      descriptionRef.clientWidth
    );
    ctx.drawImage(
      descriptionCanvas,
      0,
      marginTop,
      1240,
      80
    );
    marginTop += 150;

    ctx.fillStyle = "transparent";
    ctx.drawImage(
      qrCodeRef,
      270,
      marginTop,
      700,
      650
    );

    containerRef.style.display = "none";

    setLoading(true);

    const qrCodeInfo = {
      title: promoName,
      action: promoAction,
      active: true,
      availableHours: [
        { type: "24h", shifts: [] },
        { type: "24h", shifts: [] },
        { type: "24h", shifts: [] },
        { type: "24h", shifts: [] },
        { type: "24h", shifts: [] },
        { type: "24h", shifts: [] },
        { type: "24h", shifts: [] },
      ],
      data: promoId,
      dueDate: Date.now() + 86400000,
      keyWordsSet: undefined,
      owner: {
        name: {
          user: getUserInfo().uId,
        },
      },
      qrCodeId,
      qrCodeShortLink,
      lat: undefined,
      long: undefined,
      radius: undefined,
      validatorId: "qrvalidator@sms,triibo,com,br",
    };

    if (qrCodeInfo.action === "validate_promo") {
      qrCodeInfo.data = `${qrCodeInfo.data}|${qrCodeInfo.validatorId}`;
    }

    canvas.toBlob((blob) => {
      generateQRCodeComponent(
        promoId,
        qrCodeId,
        promoName,
        promoAction,
        promoDescription,
        blob,
        qrCodeInfo
      );
    });

    clearForm();
  };

  const handleClickQRCode = (qrCode) => {
    setOpenQRCodeInformation(true);
    setCurrentQRCode(qrCode);
  };

  const handleDeleteQRCode = (qrCode) => {
    setLoading(true);

    deleteQRCodeComponent(promoId, qrCode);
    toggleQRCodeInformation(false);

    const newQRCodeList = qrCodeList.filter((qrCodeFiltered) => {
      return qrCodeFiltered.key !== qrCode.key;
    });

    setQRCodeList(newQRCodeList);
    setLoading(false);
  };

  const goBack = () => {
    history.goBack();
  };

  const clearForm = useCallback(() => {
    setPromoName(location.state.title);
    setPromoDescription(location.state.description);
    setPromoAction(0);
    setImageCropped(promoValidateHeader);
  }, [location]);

  useEffect(() => {
    try {
      setLoading(true);
      setPromoId(location.state.id);
      setPromoName(location.state.title);
      setPromoDescription(location.state.description);
      listQRCodeComponent(location.state.id);

      const newQRCodeId = firebaseDatabase.ref("QRCodes").push().key;
      setQRCodeId(newQRCodeId);

      getLink(newQRCodeId).then((result) => {
        setQRCodeShortLink(result);
      });
    } catch (err) {
      history.push({ pathname: "/" });
    }
  }, [
    history,
    listQRCodeComponent,
    location.state.description,
    location.state.id,
    location.state.title,
  ]);

  useEffect(() => {
    if (
      list.success ||
      generate.success 
    ) {
      getDataList(`Promotions-QRCodes/${promoId}`, 999).then((dataReceived) => {
        setLoading(true);
        const newQRCodeList = [...dataReceived];
        newQRCodeList.map((qrCode, index) => {
          return getLink(qrCode.qrCodeId).then((result) => {
            newQRCodeList[index].link = result;
            downloadImage("promotions-qrcodes", qrCode.image).then(
              (imageURL) => {
                newQRCodeList[index].imageURL = imageURL;
                setQRCodeList([...newQRCodeList]);
              }
            ).catch(() => {
              setTimeout(async () => {
                return downloadImage("promotions-qrcodes", qrCode.image).then(
                  (imageURL) => {
                    newQRCodeList[index].imageURL = imageURL;
                    setQRCodeList([...newQRCodeList]);
                  })
               }, 800);
            });
          });
        });
      });
    }
    return () => {
      setLoading(false);
    }
  }, [
    list.qrCode,
    list.success,
    list.loading,
    generate.loading,
    generate.success,
    promoId
  ]);

  const classes = useStyles();

  if ((generate.loading || list.loading || deleteState.loading) && loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  return (
    <div className={classes.main}>
      <Typography
        variant="h5"
        gutterBottom
        color="primary"
        style={{ fontWeight: "600" }}
      >
        Crie seu material
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {promoId !== null && promoId !== undefined && (
              <QRCodeList
                qrCodeList={qrCodeList}
                handleClickQRCode={handleClickQRCode}
              />
            )}
          </Paper>
          <Button
            onClick={goBack}
            className={classes.button}
            style={{ marginTop: 16 }}
            variant="contained"
            color="secondary"
            type="button"
          >
            Voltar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary" style={{ marginBottom: 16 }}>
            Display de mesa A6
          </Typography>
          <div ref={imageContainerRef} className={classes.imageContainer}>
            <img
              ref={imageCroppedRef}
              src={imageCropped}
              alt="Display de mesa A6"
              width="100%"
              style={{ display: "block" }}
            />
            <div ref={imageTitleRef} className={classes.imageTitle}>
              {promoName}
            </div>
            <div ref={imageDescriptionRef} className={classes.imageDescription}>
              Escaneie o QR Code abaixo:
            </div>
            <QRCode
              value={qrCodeShortLink}
              size={300}
              bgColor="#ffffff"
              fgColor="#000000"
              level="M"
              className={classes.QRCode}
              id="QRCode"
            />
          </div>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              }}>
              <TextField
                fullWidth
                value={promoName}
                label="Nome da promo"
                required
                onChange={(e) => handleValueChange(e, setPromoName)}
              />
              <TextField
                fullWidth
                multiline
                value={promoDescription}
                label="Descrição"
                required
                onChange={(e) => handleValueChange(e, setPromoDescription)}
              />
              <Select
                fullWidth
                value={promoAction}
                required
                onChange={(e) => handleValueChange(e, setPromoAction)}
                style={{ marginTop: 16 }}
              >
                <MenuItem disabled value={0}>
                  Ação do QR code *
                </MenuItem>
                <MenuItem value="promotion_item">Abrir promoção</MenuItem>
                <MenuItem value="validate_promo">Consumir promoção</MenuItem>
              </Select>
              <div align="center" style={{ marginTop: 16 }}>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileUploader}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Button
                  onClick={() => fileUploader.current.click()}
                  className={classes.button}
                  style={{ margin: "0 5px" }}
                  variant="contained"
                  color="primary"
                  type="button"
                >
                  Alterar imagem
                </Button>
                <Button
                  className={classes.button}
                  style={{ margin: "0 5px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={promoAction === 0}
                >
                  Salvar
                </Button>
              </div>
            </form>
          </FormControl>
        </Grid>
      </Grid>

      {currentQRCode !== undefined && currentQRCode !== null && (
        <QRCodeInformation
          toggleQRCodeInformation={toggleQRCodeInformation}
          openQRCodeInformation={openQRCodeInformation}
          qrCode={currentQRCode}
          handleDeleteQRCode={handleDeleteQRCode}
        />
      )}

      <Modal
        open={openImageCropper}
        onClose={() => setOpenImageCropper(false)}
        className={classes.modal}
      >
        <Paper className={classes.modalPaper}>
          {imageToCrop !== undefined && imageToCrop !== null && (
            <React.Fragment>
              <div style={{ width: "50%", margin: "0 auto" }}>
                <Cropper
                  src={imageToCrop}
                  ref={(ref) => setImageToCropRef(ref)}
                  ratio={2 / 1}
                />
              </div>
              <div align="center" style={{ marginTop: 16 }}>
                <Button
                  onClick={handleCropImage}
                  className={classes.button}
                  style={{ margin: "0 5px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Recortar imagem
                </Button>
              </div>
            </React.Fragment>
          )}
        </Paper>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    generate: {
      loading: state.generateQRCodeComponent.loading,
      success: state.generateQRCodeComponent.success,
      error: state.generateQRCodeComponent.error,
    },
    list: {
      loading: state.listQRCodeComponent.loading,
      success: state.listQRCodeComponent.success,
      error: state.listQRCodeComponent.error,
      qrCodes: state.listQRCodeComponent.qrCodes,
    },
    deleteState: {
      loading: state.deleteQRCodeComponent.loading,
      success: state.deleteQRCodeComponent.success,
      error: state.deleteQRCodeComponent.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  generateQRCodeComponent: (
    promoId,
    qrCodeId,
    name,
    action,
    description,
    image,
    qrCodeInfo
  ) =>
    generateQRCodeAction(
      dispatch,
      promoId,
      qrCodeId,
      name,
      action,
      description,
      image,
      qrCodeInfo
    ),
  listQRCodeComponent: (promoId) => listQRCodeAction(dispatch, promoId),
  deleteQRCodeComponent: (promoId, qrCode) =>
    deleteQRCodeAction(dispatch, promoId, qrCode),
});

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerate);
