import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  background: #fff;
  padding: 15px 30px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707033;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  > h4 {
    text-align: center;
  }

  > h5 {
    width: 70%;
    text-align: center;
  }

  @media (max-width: 750px) {
    > h5 {
      width: 100%;
    }
  }
`;

export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
