import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

import { verificarAprovacaoPromocao } from 'js/library/services/DatabaseManager';

import { getStoreByEstablishment } from 'js/library/utils/API/Store/apiGetStoreByEstablishment';

import { downloadImage } from 'js/library/services/StorageManager';
import { CreateCard } from 'js/library/utils/API/apiCreateOrder';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

import { getVoucherGroup } from 'js/library/utils/API/getVoucherGroup';

export class AttendanceServices {
  dataEstabelecimento = {};

  constructor(dataEstabelecimento) {
    if (!dataEstabelecimento)
      throw new Error('[AttendanceServices]: dataEstabelecimento is required!');

    this.dataEstabelecimento = dataEstabelecimento;
  }

  getPromotions(setPromotionsData) {
    if (!setPromotionsData)
      throw new Error('[AttendanceServices]: setPromotionsData function is required!');

    getStoreByEstablishment(this.dataEstabelecimento.id, 0, 1000, true).then(
      async (dataReceived) => {
        let listActivePromotions = [];

        dataReceived.forEach((element, i) => {
          const id = Object.keys(element)[0];

          //insere o placeholder nas imagens
          element.id = id;
          element['downloadedThumb'] = placeholder;
          element['aprovacao'] = null;
          verificarAprovacaoPromocao(
            element.id,
            element.type,
            this.dataEstabelecimento.id,
            this.dataEstabelecimento.nome
          ).then((approval) => {
            element['aprovacao'] = approval;
            const isActive =
              element[id].storeInfo.isPublished === true &&
              element[id].storeInfo.enableDate <= Date.now() &&
              element[id].storeInfo.disableDate >= Date.now();
            const isAwaiting = element.aprovacao?.status === 'aberto';
            if (isActive || isAwaiting) {
              listActivePromotions.push(element[id].storeInfo);
            }
            // setPromotionsData(dataReceived);
          });
        });

        setPromotionsData(listActivePromotions);
      }
    );
  }

  getPromotionImage(fotoThumb, key, downloadedThumb, type, promotionData, setThumbnail) {
    if (!promotionData)
      throw new Error('[AttendanceServices]: promotionData param function is required!');

    if (fotoThumb && (downloadedThumb === placeholder || downloadedThumb === undefined)) {
      let data = promotionData;
      const image = fotoThumb.split('/');

      const index = data.findIndex((x) => x.key === key);

      if (type === 'product') {
        downloadImage(image[0], image[1]).then((downloadedImage) => {
          data[index].downloadedThumb = downloadedImage;
          setThumbnail(downloadedImage);
          return downloadedImage;
        });
      } else {
        downloadImage('promocao', fotoThumb).then((downloadedImage) => {
          data[index].downloadedThumb = downloadedImage;
          setThumbnail(downloadedImage);
          return downloadedImage;
        });
      }
    }
  }

  getVouchers(templateId) {
    if (!templateId) throw new Error('[AttendanceServices]: templateId param is required!');

    return getVoucherTemplate(templateId).then((dataReceived) => {
      if (dataReceived) {
        const batchId = dataReceived.generator ? 'AUTO' : null;

        return { ...dataReceived, batchId };
      }
    });
  }

  getVoucherGroup(groupId) {
    if (!groupId) throw new Error('[AttendanceServices]: groupId param is required!');

    return getVoucherGroup(groupId)
      .then((dataReceived) => {
        return dataReceived;
      })
      .catch((error) => {
        return error;
      });
  }

  validateVoucher(uId, sender, itemId, latitude, longitude, type, receiver) {
    if ((!sender, !itemId)) {
      throw new Error('[AttendanceServices]: sender, itemId are required!');
    }

    const status = 'requested';

    return CreateCard(uId, sender, itemId, latitude, longitude, type, receiver, status);
  }
}
