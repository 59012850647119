import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22'; 

export function getVoucherGroup(voucherId) {
    return new Promise((resolve, reject) => {
      authToken().then((token) => {
        axios
          .get(cfac22('API_HOST_V2') + `/voucherGroup/getVoucherGroup/?id=${voucherId}`, {
            headers: { authorization: 'Bearer ' + token },
          })
          .then((result) => {
            resolve(result.data.response);
          })
          .catch((error) => {
            return reject(error.response.data);
          });
      });
    });
  }