import React from 'react';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from '../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

export default function CampaignMapfre() {
  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container>
        <D.TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3rem',
            marginTop: '2rem',
          }}
        >
          Campanha Mapfre
        </D.TriiboH1>
        <S.ButtonsContainer>
          <S.RegisterButton
            to={{
              pathname: '/admin/configuracoes/campanhaMapfre/adicionarCampanha',
              state: '',
            }}
          >
            <D.TriiboH4>Cadastrar Campanha</D.TriiboH4>
          </S.RegisterButton>
          <S.ListButton
            to={{
              pathname: '/admin/configuracoes/campanhaMapfre/listarCampanhas',
              state: '',
            }}
          >
            <D.TriiboH4>Lista de Campanhas</D.TriiboH4>
          </S.ListButton>
        </S.ButtonsContainer>
      </S.Container>
    </div>
  );
}
