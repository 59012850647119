import React from 'react';

import { Grid, Button, Modal, Paper } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

const styles = {
  modal: {
    padding: 15,
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  paper: {
    width: 700,
    maxWidth: '90%',
    maxHeight: '90%',
    padding: '50px 20px 30px 20px',
    boxSizing: 'border-box',
    borderRadius: 0,
    overflowY: 'auto',
    outline: 'none',
    position: 'relative',
  },
  closeButton: {
    cursor: 'pointer',
    top: 20,
    right: 20,
    position: 'absolute',
  },
  img: {
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
  },
  button: {
    fontWeight: '550',
    color: 'white',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
};

const QRCodeInformation = ({
  toggleQRCodeInformation,
  openQRCodeInformation,
  qrCode,
  handleDeleteQRCode,
}) => {
  const print = () => {
    const win = window.open('', 'PRINT', 'width=700,height=700');
    win.document.write(`<body style='margin: 0;padding: 0;'>`);
    win.document.write(`<style type='text/css' media='print'>
             @page {
                size: auto;
                margin: 0;
            }           
            body, html {
                margin: 0;
                padding: 0;
                border: 0;
                background: #fff;
            }
            img {
                width: 397px;
                height: auto;
                max-width: 100%;
                margin: 0 auto;
                display: block;
            }
        </style>`);
    win.document.write(
      `<img src='${qrCode.imageURL}' onload='window.print()' />`
    );
    win.document.write('</body>');
    win.onfocus = () => {
      setTimeout(() => {
        win.close();
      }, 1000);
    };
  };

  const handleClickDownload = (url) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        const result = reader.result;
        const a = document.createElement('a');
        a.setAttribute('download', '');
        a.href = result;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
        a.remove();
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  return (
    <Modal
      open={openQRCodeInformation}
      onClose={() => toggleQRCodeInformation(false)}
      style={styles.modal}>
      <Paper style={styles.paper}>
        <Cancel
          color='secondary'
          onClick={() => toggleQRCodeInformation(false)}
          style={styles.closeButton}
        />

        <React.Fragment>
          <img
            alt={qrCode.name}
            src={
              qrCode.imageURL !== undefined && qrCode.imageURL !== null
                ? qrCode.imageURL
                : placeholderThumbnail
            }
            style={{
              ...styles.img,
              maxHeight: '40vh',
              outline: '1px solid #eee',
            }}
          />
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item sm={4} xs={12} align='center'>
              <Button
                onClick={print}
                style={styles.button}
                variant='contained'
                color='primary'
                type='button'>
                Imprimir
              </Button>
            </Grid>
            <Grid item sm={4} xs={12} align='center'>
              <Button
                onClick={() => {
                  handleClickDownload(qrCode.imageURL);
                }}
                style={styles.button}
                variant='contained'
                color='primary'
                type='button'>
                Baixar imagem
              </Button>
            </Grid>
            <Grid item sm={4} xs={12} align='center'>
              <Button
                onClick={() => handleDeleteQRCode(qrCode)}
                style={styles.button}
                variant='contained'
                color='secondary'
                type='button'>
                Apagar
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>
    </Modal>
  );
};

export default QRCodeInformation;
