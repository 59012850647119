import styled, { keyframes, css } from 'styled-components';
import { transparentize, darken } from 'polished';

import * as D from 'js/components/DesignSystem/styles';
import ReactInputMask from 'react-input-mask';
import { Button, TextField } from '@mui/material';

export const CreateClubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.4rem;
`;

export const AreaHeader = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${D.triiboBlue};
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 1.334;

  @media (max-width: 900px) {
    font-size: 1rem;
  }
`;

export const AreaSubtitle = styled.span`
  color: ${D.triiboGray};
`;

export const GoBack = styled.span`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
`;

export const EditContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.8rem;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  border-radius: 10px;
  box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.15);
  padding: 2rem;
`;

export const FormCard = styled(Card)`
  max-width: 500px;
  width: 100%;

  @media (max-width: 700px) {
    padding: 1rem;
  }
`;

export const PreviewCard = styled(Card)`
  width: 100%;

  @media (min-width: 900px) {
    width: 62%;
  }
`;

export const PreviewGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`;

export const ClubPreviewContainer = styled.div`
  overflow-y: hidden;
  display: flex;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 70.25%;
  background-color: transparent;
`;

export const ClubPreview = styled.span`
  position: absolute;
  overflow-y: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(234, 239, 242, 1);
  background-size: cover;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ClubHeader = styled.span`
  width: 100%;
  height: 5rem;
  background: ${(props) =>
    props.previewColor ? props.previewColor : '#6E3296'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

export const ClubBody = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 85%;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
`;

export const PreviewCol = styled.div`
  width: 30%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const PreviewCardBorder = styled.div`
  padding: 1rem;
  border-radius: 10px;
  background: white;
  /* height: 50rem; */
  min-height: 10rem;
`;

export const PreviewBanner = styled.img.attrs((props) => ({
  alt: props.Alt || 'preview da imagem para upload',
}))`
  border-radius: 10px;
  background: white;
  width: 100%;
`;

export const LogoPreview = styled.img.attrs((props) => ({
  alt: props.Alt || 'preview da imagem para upload',
  // src: props.Src || "",
}))`
  background: transparent;

  max-height: 70px;
  max-width: 120px;
  width: 100%;
  height: 100%;
`;

export const MockSearchBar = styled.div`
  min-width: 40%;
  height: 50%;
  border-radius: 15px;
  background: white;
  border: 1px solid ${D.triiboGray};
`;

export const SearchGroup = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 30%;
  width: 100%;
  padding: 1rem 0;
`;

export const ShortcutHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  height: 100%;
  width: 100%;

  @media (min-width: 900px) {
    font-size: 1.8rem;
    gap: 2rem;
  }
`;

export const IconMenu = styled.div`
  width: 100%;
  font-size: 1rem;
  color: white;

  @media (min-width: 900px) {
    font-size: 1.8rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 3%;
  min-width: 35%;
  width: 100%;

  ${({ highlight }) =>
    highlight &&
    `
  border-bottom: 0.3rem solid;
    `}

  border-color: ${(props) =>
    props.previewColor ? props.previewColor : 'transparent'};
`;

export const PreviewCardDesktop = styled.img.attrs((props) => ({
  alt: props.Alt,
}))`
  background: white;
  width: 100%;
  height: 13rem;
  margin-top: ${(props) => (props.spacing === '' ? '1.5rem' : props.spacing)};
`;

export const PreviewCardMobile = styled.img.attrs((props) => ({
  alt: props.Alt,
}))`
  background: white;
  width: 13rem;
  height: 13rem;
  margin-top: ${(props) => (props.spacing === '' ? '1.5rem' : props.spacing)};
`;

export const FormDisplayContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const InfoKey = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ClubFormLabel = styled.label`
  font-weight: 600;
`;

export const ColorPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-family: monospace, sans-serif;
`;

export const ColorDiv = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid ${D.triiboBlack};
  background: white;

  ${({ color }) =>
    `
  background: ${color};
    `}
`;

export const CardsDisplay = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

export const PickerGroup = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const PickerContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`;

export const FormGroup = styled.div.attrs((props) => ({
  spacing: props.spacing || '1rem',
}))`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${(props) => props.spacing};
`;

export const FormGroupRow = styled.div.attrs((props) => ({
  distance: props.distance || '0rem',
}))`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.distance} 0;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const NameGroup = styled(FormGroup).attrs((props) => ({
  distance: props.distance || '0rem',
}))`
  position: relative;
  margin-top: ${(props) => props.distance};
`;

const rotate = keyframes`
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
`;

const animation = (props) =>
  css`
    ${rotate} ${props.animationLength} infinite;
  `;

export const SearchContainer = styled.span`
  position: absolute;
  top: 3rem;
  right: 1rem;
  color: ${D.triiboBlue};
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ isSearching }) =>
    isSearching &&
    `
  animation: ${animation};

    `}
`;

export const FormLabel = styled.span`
  font-family: 'Source Sans Pro', sans-serif;

  @media screen and (max-width: 900px) {
    font-size: 0.8rem;
  }
`;

export const RecGroup = styled.div`
  z-index: 1;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  min-height: 0.8rem;
  max-height: 13rem;
  /* padding: 0.8rem; */
  background: white;
  box-shadow: 3px 0px 10px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid ${D.triiboGray};
  /* border-radius: 10px; */
  top: 6rem;
  visibility: hidden;

  ${({ hasSuggestion }) =>
    hasSuggestion &&
    `
  visibility: visible;
    `}
`;

export const RecButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 3rem;
  background: transparent;
  padding: 0.8rem;
  transition: 0.15s ease-in-out;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const CardsContainer = styled.div`
  border-radius: 10px;
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
`;

export const ImgPreview = styled.img.attrs((props) => ({
  alt: props.Alt || 'preview da imagem para upload',
  // src: props.Src || "",
}))`
  width: 100%;
  border: 1px solid ${D.triiboGray};
  background: ${D.triiboGray};
`;

export const ImgGroup = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.8rem;
`;

export const StatusClub = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const colors = {
  green: '#33cc95',
  red: '#E62E4D',
};

export const RadioBox = styled.button`
  background: ${(props) =>
    props.isActive
      ? transparentize(0.3, colors[props.activeColor])
      : 'transparent'};
  border: 1px solid #d7d7d7;
  border-radius: 0.25rem;
  color: #000;
  height: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: border-color 0.2s;

  &:hover {
    border-color: ${darken(0.1, '#d7d7d7')};
    filter: brightness(0.9);
  }

  span {
    display: inline-block;
    width: 6rem;
  }

  @media screen and (max-width: 900px) {
    width: 4.8rem;
  }
`;

export const inputMaskStyle = styled(ReactInputMask)`
  height: 2rem;
  border-radius: 10px;
  font-family: Source Sans Pro;
  padding: 0.7rem;
  outline: none;
  border: 1px solid gray;
  resize: none;
  background-color: #fff;
  margin-left: 4px;
`;

export const inputPhoneMuiu = styled(inputMaskStyle)`
  border-radius: 0.3rem;
  height: 4rem;
  width: 100%;
  border: 1px solid #aaa;
  font-size: 1.2rem;

  @media screen and (max-width: 900px) {
    height: 2.5rem;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 400px) {
    height: 2.5rem;
    font-size: 0.7rem;
  }
`;

export const ButtonSave = styled(Button)`
  font-weight: 700 !important;
  margin-top: 2rem !important;
  color: #fff;
  text-transform: capitalize;
  border-radius: 10px !important;
  &:disabled {
    display: flex;
    align-items: center;
    background-color: gray;
  }
`;

export const ButtonSaves = styled(Button)`
  font-weight: 700 !important;
  text-transform: capitalize;
  border-radius: 10px !important;
`;

export const TextArea = styled(TextField)`
  width: 100%;
`;

export const InputContainerWithSearchButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SearchButtonContainer = styled.span`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 0.8rem;

  @media screen and (max-width: 900px) {
    right: 0.2rem;
  }
`;

export const SearchButton = styled.button`
  align-items: center;
  background: ${D.triiboBlue};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 2.2rem;
  justify-content: center;
  width: 2.2rem;

  @media screen and (max-width: 900px) {
    height: 1.8rem;
    width: 1.8rem;
  }

  @media screen and (max-width: 400px) {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const ManagerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const FormDivider = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: 25%;

  @media screen and (max-width: 900px) {
    width: 50%;
  }
`;

export const RadioContainer = styled.span`
  display: flex;
  flex-directon: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
`;

export const inputMaskStyleEdit = styled(inputMaskStyle)`
  margin-left: 0;
`;

export const Container = styled.div`
  padding: 2rem 1rem 4rem 1rem;
  @media (min-width: 1020px) {
    max-width: 1000px;
    margin: 100px auto 150px auto;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
`;

export const AdminManagerContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
`;

export const AdminManagerListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const AdminManagerTable = styled.table`
  width: 80%;
`;

export const AdminManagerTableRow = styled.tr``;

export const AdminManagerTableHeader = styled.th`
  text-align: left;
  padding: 1rem;
`;

export const AdminManagerTableDiv = styled.td`
  padding: 1rem;
  text-align: left;
  text-transform: capitalize;
`;

export const AdminManagerRow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const RemoveButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: red;
  border: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  transition: 0.2s ease-in-out;

  &:active {
    background: black;
  }
`;
