import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { debounce } from 'lodash';

//STYLES
import { Box, Button, CircularProgress, Modal, TextField, Autocomplete } from '@mui/material';
import { BoxAutocomplete, BoxInfo, BoxModal, ContentInfoModalAdd } from './segmentStyles';

//ICONS
import { MdOutlineApartment, MdOutlineGpsFixed, MdAdsClick } from 'react-icons/md';
import { HiDocumentSearch } from 'react-icons/hi';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

//API
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { findStoreByProximity } from 'js/library/utils/API/Store/findStoreByProximity';
import { addResourceSegmentWhiteList } from 'js/library/utils/API/Segmentation/addResourceSegmentWhiteList';
import { getCardByTitle } from 'js/library/utils/API/getCardByTitle';
import { getOrgById } from 'js/library/utils/API/Org/apiGetOrgById';
import { getPostByTitle } from 'js/library/utils/API/getPostByTitle';

export const ModalNewResource = ({
  open,
  value,
  setOpenModalAdd,
  segmentId,
  isChange,
  setIsChange,
  data,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [dataEstablishment, setDataEstablishment] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);

  const [dataStore, setDataStore] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  const [dataCard, setDataCard] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const [dataPost, setDataPost] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');

  const [orgCard, setOrgCard] = useState([]);
  const [nameOrgCard, setNameOrgCard] = useState('');

  const [orgPost, setOrgPost] = useState([]);
  const [nameOrgPost, setNameOrgPost] = useState('');

  // dados dos tipos para o autoComplete
  const fetchData = async (query = '', from = 0, size = 10) => {
    const payloadStore = {
      title: query,
      isPublished: '',
      from: from,
      size: size,
    };

    const payloadCardAndPost = {
      from: from,
      size: size,
    };

    try {
      let establishmentData;
      let storeData;
      let cardData;
      let postData;

      switch (value) {
        case 0:
          // estabelecimentos
          establishmentData = await apiListEstablishment(query, from, size);
          setDataEstablishment(establishmentData);
          break;
        case 1:
          // promoçoões
          storeData = await findStoreByProximity(payloadStore);
          setDataStore(storeData);
          break;
        case 2:
          // cards
          cardData = await getCardByTitle(payloadCardAndPost, '');
          setDataCard(cardData);
          break;
        case 3:
          // posts
          postData = await getPostByTitle(payloadCardAndPost, '');
          setDataPost(postData);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Ocorreu um erro na requisição:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  // se tiver card/post selecionado, pega o id da org pra usar em outra requisição
  useEffect(() => {
    //cards
    if (selectedCard && selectedCard.orgs) {
      setOrgCard(selectedCard.orgs[0]);
    } else {
      setOrgCard([]);
    }

    //posts
    if (selectedPost && selectedPost.orgs) {
      setOrgPost(selectedPost.orgs[0]);
    } else {
      setOrgPost([]);
    }
  }, [selectedCard, selectedPost]);

  // (cards) se tiver id da org, faz a req pra trazer o nome da org
  useEffect(() => {
    async function fetchOrgCard() {
      if (orgCard) {
        setLoading(true);
        await getOrgById(orgCard)
          .then((res) => {
            setNameOrgCard(res.orgInfo.name);
          })
          .catch((error) => console.error('Erro getOrgById:', error))
          .finally(() =>
            setTimeout(() => {
              setLoading(false);
            }, 1000)
          );
      }
    }
    fetchOrgCard();
  }, [orgCard]);

  // (posts) se tiver id da org, faz a req pra trazer o nome da org
  useEffect(() => {
    async function fetchOrgPost() {
      if (orgPost) {
        setLoading(true);
        await getOrgById(orgPost)
          .then((res) => {
            setNameOrgPost(res.orgInfo.name);
          })
          .catch((error) => console.error('Erro getOrgById:', error))
          .finally(() =>
            setTimeout(() => {
              setLoading(false);
            }, 1000)
          );
      }
    }
    fetchOrgPost();
  }, [orgPost]);

  const labelType = () => {
    switch (value) {
      case 0:
        return 'Nome do estabelecimento';
      case 1:
        return 'Título da promoção';
      case 2:
        return 'Título do card';
      case 3:
        return 'Título do post';
      default:
        break;
    }
  };

  const optionsAutoComplete = () => {
    switch (value) {
      case 0:
        return dataEstablishment;
      case 1:
        return dataStore;
      case 2:
        return dataCard;
      case 3:
        return dataPost;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpenModalAdd(false);

    // limpa os valores selecionados
    setSelectedEstablishment(null);
    setSelectedStore(null);
    setSelectedCard(null);
    setSelectedPost(null);
  };

  const handleChange = (event, value) => {
    if (value) {
      const selectedId = value.id;

      // Filtrando os dados do estabelecimento com base no id selecionado
      const filteredDataEstablishment = dataEstablishment.filter((item) => item.id === selectedId);
      setSelectedEstablishment(
        filteredDataEstablishment.length > 0 ? filteredDataEstablishment[0] : null
      );

      // Filtrando os dados da promoção com base no id selecionado
      const filteredDataStore = dataStore.filter((item) => item.id === selectedId);
      setSelectedStore(filteredDataStore.length > 0 ? filteredDataStore[0] : null);

      // Filtrando os dados do card com base no id selecionado
      const filteredDataCard = dataCard.filter((item) => item.id === selectedId);
      setSelectedCard(filteredDataCard.length > 0 ? filteredDataCard[0] : null);

      // Filtrando os dados do post com base no id selecionado
      const filteredDataPost = dataPost.filter((item) => item.id === selectedId);
      setSelectedPost(filteredDataPost.length > 0 ? filteredDataPost[0] : null);
    } else {
      // Se nada for selecionado
      setSelectedEstablishment(null);
      setSelectedStore(null);
      setSelectedCard(null);
      setSelectedPost(null);
    }
  };

  const performFetch = async (query) => {
    setLoading(true);

    let cardData;
    let postData;

    const payloadCardAndPost = {
      from: 0,
      size: 10,
    };

    // Se o valor digitado não estiver na lista inicial, fazer nova requisição (estabelecimento e promoção)
    if (
      (!dataEstablishment.some(
        (establishment) => establishment.nome.toLowerCase() === query.toLowerCase()
      ) &&
        query.length >= 2) ||
      (!dataStore.some((store) => store.title.toLowerCase() === query.toLowerCase()) &&
        query.length >= 2)
    ) {
      await fetchData(query, 0, 10);
      setLoading(false);
    }

    // para cards
    if (
      value === 2 &&
      !dataCard.some((card) => card.title.toLowerCase() === query.toLowerCase()) &&
      query.length >= 2
    ) {
      cardData = await getCardByTitle(payloadCardAndPost, query);
      setDataCard(cardData);
      setLoading(false);
    }

    // para posts
    if (
      value === 3 &&
      !dataPost.some((post) => post.title.toLowerCase() === query.toLowerCase()) &&
      query.length >= 2
    ) {
      postData = await getPostByTitle(payloadCardAndPost, query);
      setDataPost(postData);
      setLoading(false);
    }
  };

  // Função debounce utilizando lodash
  const debouncedFetch = useCallback(
    debounce(async (query) => {
      await performFetch(query);
    }, 1000),
    [dataEstablishment, dataStore, dataCard, dataPost]
  );

  useEffect(() => {
    debouncedFetch(textFieldValue.trim());
  }, [textFieldValue]);

  const addNewResource = async (e) => {
    e.preventDefault();

    try {
      setLoadingAdd(true);

      //estabelecimento
      if (value === 0) {
        const payload = {
          [segmentId]: {
            [selectedEstablishment.id]: 'establishment',
          },
        };

        const checkIfEstablishmentExists = data.some(
          (item) => item.partnerEstablishmentId === selectedEstablishment.id
        );

        if (checkIfEstablishmentExists) {
          toast.error('Esse recurso já foi adicionado.', { autoClose: 2000 });
          setLoadingAdd(false);
        } else {
          try {
            await addResourceSegmentWhiteList(payload);
            setIsChange(!isChange);
            setLoadingAdd(false);
            toast.success('Estabelecimento adicionado com sucesso.', { autoClose: 2000 });
            setSelectedEstablishment(null);
            setOpenModalAdd(false);
          } catch (error) {
            console.error('Erro ao adicionar estabelecimento:', error);
            toast.error('Erro ao adicionar estabelecimento.', { autoClose: 2000 });
            setLoadingAdd(false);
          }
        }
      }

      //promoção
      if (value === 1) {
        const payload = {
          [segmentId]: {
            [selectedStore.id]: 'store',
          },
        };

        const checkIfStoreExists = data.some((item) => item.id === selectedStore.id);

        if (checkIfStoreExists) {
          toast.error('Esse recurso já foi adicionado.', { autoClose: 2500 });
          setLoadingAdd(false);
        } else {
          try {
            await addResourceSegmentWhiteList(payload);
            setIsChange(!isChange);
            setLoadingAdd(false);
            toast.success('Promoção adicionada com sucesso.', { autoClose: 2000 });
            setSelectedStore(null);
            setOpenModalAdd(false);
          } catch (error) {
            console.error('Erro ao adicionar promoção:', error);
            toast.error('Erro ao adicionar promoção.', { autoClose: 2000 });
            setLoadingAdd(false);
          }
        }
      }

      //cards
      if (value === 2) {
        const payload = {
          [segmentId]: {
            [selectedCard.id]: 'cards',
          },
        };

        const checkIfCardExists = data.some((item) => item.cardId === selectedCard.id);

        if (checkIfCardExists) {
          toast.error('Esse recurso já foi adicionado.', { autoClose: 2000 });
          setLoadingAdd(false);
        } else {
          try {
            await addResourceSegmentWhiteList(payload);
            setIsChange(!isChange);
            setLoadingAdd(false);
            toast.success('Card adicionado com sucesso.', { autoClose: 2000 });
            setSelectedCard(null);
            setOpenModalAdd(false);
          } catch (error) {
            console.error('Erro ao adicionar card:', error);
            toast.error('Erro ao adicionar card.', { autoClose: 2000 });
            setLoadingAdd(false);
          }
        }
      }

      //posts
      if (value === 3) {
        const payload = {
          [segmentId]: {
            [selectedPost.id]: 'posts',
          },
        };

        const checkIfPostExists = data.some((item) => item.id === selectedPost.id);

        if (checkIfPostExists) {
          toast.error('Esse recurso já foi adicionado.', { autoClose: 2000 });
          setLoadingAdd(false);
        } else {
          try {
            await addResourceSegmentWhiteList(payload);
            setIsChange(!isChange);
            setLoadingAdd(false);
            toast.success('Post adicionado com sucesso.', { autoClose: 2000 });
            setSelectedPost(null);
            setOpenModalAdd(false);
          } catch (error) {
            console.error('Erro ao adicionar post:', error);
            toast.error('Erro ao adicionar post.', { autoClose: 2000 });
            setLoadingAdd(false);
          }
        }
      }
    } catch (error) {
      console.log('Erro:', error);
      toast.error('Ocorreu um erro ao tentar adicionar o recurso.', { autoClose: 2500 });
    }
  };

  let actionPost = 'N/A';

  if (selectedPost) {
    switch (selectedPost.action.type) {
      case 'promotion_list':
        if (selectedPost.appFilter.query.includes('Awin')) {
          actionPost = 'Promoções com cashback';
        } else if (selectedPost.appFilter.query.includes('Brasil')) {
          actionPost = 'Promoções próximas';
        } else if (selectedPost.appFilter.query.includes('TAG')) {
          actionPost = 'Promoções selecionadas (por # ou TAG)';
        }
        break;
      case 'establishment_list':
        if (selectedPost.appFilter.query.includes('Awin')) {
          actionPost = 'Estabelecimentos com cashback';
        } else if (selectedPost.appFilter.query.includes('Brasil')) {
          actionPost = 'Estabelecimentos próximos';
        } else if (selectedPost.appFilter.query.includes('TAG')) {
          actionPost = 'Estabelecimentos selecionados (por # ou TAG)';
        }
        break;
      case 'promotion_item':
        actionPost = 'Acessar Promoção';
        break;
      case 'establishment_info':
        actionPost = 'Informação de estabelecimento';
        break;
      case 'wifi_list':
        actionPost = 'Lista de WiFi';
        break;
      case 'browser':
        actionPost = 'Browser';
      default:
        actionPost = 'N/A';
    }
  }

  let actionCard = 'N/A';

  if (selectedCard) {
    switch (selectedCard.action) {
      case 'promotion_list':
        actionCard = 'Lista de promoções';
        break;
      case 'establishment_list':
        actionCard = 'Lista de estabelecimentos';
        break;
      case 'product_list':
        actionCard = 'Lista de produtos';
        break;
      case 'wifi_list':
        actionCard = 'Lista de WiFi';
        break;
      case 'promotion_businessPartner':
        actionCard = 'Promoção de parceiro';
        break;
      case 'promotion_qrCode':
        actionCard = 'Promoção com QR Code';
        break;
      case 'promotion_selfValidation':
        actionCard = 'Promoção com validação pelo usuário';
        break;
      case 'promotion_takeVoucher':
        actionCard = 'Promoção de voucher';
        break;
      case 'product_qrCode':
        actionCard = 'Produto com QR Code';
        break;
      case 'product_selfValidation':
        actionCard = 'Produto com validação pelo usuário';
        break;
      case 'product_takeVoucher':
        actionCard = 'Produto de voucher';
        break;
      case 'establishment_info':
        actionCard = 'Informação de estabelecimento';
        break;
      case 'app_screen':
        actionCard = 'Navegação no App';
        break;
      case 'browser':
        actionCard = 'Browser';
        break;
      case 'webview':
        actionCard = 'Webview';
        break;
      default:
        actionCard = 'N/A';
    }
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <BoxModal sx={{ position: 'relative', height: optionsOpen ? '400px' : 'max-content' }}>
        <Box
          sx={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }}
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="large" />
        </Box>

        <h3>Adicionar um novo recurso</h3>

        <BoxAutocomplete>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={optionsAutoComplete()}
            getOptionLabel={(option) => option.nome || option.title}
            sx={{ width: 400 }}
            blurOnSelect
            noOptionsText={
              loading ? <CircularProgress size={15} /> : 'Nenhum resultado encontrado...'
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={labelType()}
                onClick={() => setOptionsOpen(true)}
                onFocus={() => setOptionsOpen(true)}
                onBlur={() => setOptionsOpen(false)}
              />
            )}
            ListboxProps={{ style: { maxHeight: '200px' } }}
            open={optionsOpen}
            onClose={() => setOptionsOpen(false)}
            onChange={handleChange}
            onInputChange={(event, newInputValue) => {
              setTextFieldValue(newInputValue);
              if (newInputValue === '') {
                fetchData('', 0, 10);
              }
            }}
          />
        </BoxAutocomplete>

        {/* dados do estabelecimento selecionado: */}
        {selectedEstablishment !== null && (
          <ContentInfoModalAdd>
            <BoxInfo>
              <HiDocumentSearch size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Status: </span>
                {selectedEstablishment
                  ? selectedEstablishment.isPublished
                    ? 'Ativo'
                    : 'Inativo'
                  : 'N/A'}
              </p>
            </BoxInfo>
            <BoxInfo>
              <MdOutlineGpsFixed size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Origem: </span>
                {selectedEstablishment.origin ?? 'Triibo'}
              </p>
            </BoxInfo>
          </ContentInfoModalAdd>
        )}

        {/* dados da promoção selecionada: */}
        {selectedStore !== null && (
          <ContentInfoModalAdd>
            <BoxInfo>
              <MdOutlineApartment size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Nome do estabelecimento: </span> {selectedStore.establishmentName ?? 'N/A'}
              </p>
            </BoxInfo>
            <BoxInfo>
              <HiDocumentSearch size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Status: </span>
                {selectedStore ? (selectedStore.isPublished ? 'Ativo' : 'Inativo') : 'N/A'}
              </p>
            </BoxInfo>
            <BoxInfo>
              <MdOutlineGpsFixed size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Origem: </span>
                {selectedStore.origin ?? 'Triibo'}
              </p>
            </BoxInfo>
          </ContentInfoModalAdd>
        )}

        {/* dados do card selecionado: */}
        {selectedCard !== null &&
          (loading ? (
            <CircularProgress size={25} thickness={6} />
          ) : (
            <ContentInfoModalAdd>
              <BoxInfo>
                <MdOutlineApartment size={20} style={{ color: '#08bad0' }} />
                <p>
                  <span>Organização: </span>
                  {nameOrgCard ?? 'N/A'}
                </p>
              </BoxInfo>
              <BoxInfo>
                <MdAdsClick size={20} style={{ color: '#08bad0' }} />
                <p>
                  <span>Ação: </span>
                  {actionCard ?? 'N/A'}
                </p>
              </BoxInfo>
              <BoxInfo>
                <HiDocumentSearch size={20} style={{ color: '#08bad0' }} />
                <p>
                  <span>Status: </span>
                  {selectedCard ? (selectedCard.isPublished ? 'Ativo' : 'Inativo') : 'N/A'}
                </p>
              </BoxInfo>
              <BoxInfo>
                <MdOutlineGpsFixed size={20} style={{ color: '#08bad0' }} />
                <p>
                  <span>Origem: </span>
                  {selectedCard.origin ?? 'Triibo'}
                </p>
              </BoxInfo>
            </ContentInfoModalAdd>
          ))}

        {/* dados do post selecionado: */}
        {selectedPost !== null &&
          (loading ? (
            <CircularProgress size={25} thickness={6} />
          ) : (
            <ContentInfoModalAdd>
              <BoxInfo>
                <MdOutlineApartment size={20} style={{ color: '#08bad0' }} />
                <p>
                  <span>Organização: </span>
                  {nameOrgPost ?? 'N/A'}
                </p>
              </BoxInfo>
              <BoxInfo>
                <MdAdsClick size={20} style={{ color: '#08bad0' }} />
                <p>
                  <span>Ação: </span>
                  {actionPost ?? 'N/A'}
                </p>
              </BoxInfo>
            </ContentInfoModalAdd>
          ))}

        <Button
          color="primary"
          disabled={
            (value === 0 && !selectedEstablishment) ||
            (value === 1 && !selectedStore) ||
            (value === 2 && !selectedCard) ||
            (value === 3 && !selectedPost)
          }
          variant="contained"
          onClick={addNewResource}
        >
          {loadingAdd ? (
            <CircularProgress size={25} thickness={6} style={{ color: '#fff' }} />
          ) : (
            'Adicionar'
          )}
        </Button>
      </BoxModal>
    </Modal>
  );
};
