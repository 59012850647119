import React from 'react';

import * as C from 'js/components/Configurations/Communications/SendCommunication/styles';
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import { Modal } from '@mui/material';

import { AiFillCloseCircle } from 'react-icons/ai';

export default function ModalStatus({
  handleCloseModalStatus,
  isModalStatusOpen,
  status,
}) {
  return (
    <Modal
      onClose={handleCloseModalStatus}
      open={isModalStatusOpen}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'>
      <C.ModalContainer desk_width={'700px'}>
        <C.ModalBox>
          <C.CloseModalIcon onClick={handleCloseModalStatus}>
            <AiFillCloseCircle size={20} />
          </C.CloseModalIcon>
          <S.Container>
            <D.TriiboH3>Status da notificação</D.TriiboH3>
            <S.CardsContainer>
              <S.Card bg={'rgba(87, 215, 83, 0.75)'}>
                <D.TriiboH4>Enviados com sucesso.</D.TriiboH4>
                <D.TriiboH4>{status ? status.sucesso : '0'}</D.TriiboH4>
              </S.Card>
              <S.Card bg={'rgba(240, 197, 87, 0.89)'}>
                <D.TriiboH4>Usuários não existem.</D.TriiboH4>
                <D.TriiboH4>{status ? status.semToken : '0'}</D.TriiboH4>
              </S.Card>
              <S.Card bg={'rgba(241, 58, 58, 0.81)'}>
                <D.TriiboH4>Erro no envio.</D.TriiboH4>
                <D.TriiboH4>{status ? status.erro : '0'}</D.TriiboH4>
              </S.Card>
            </S.CardsContainer>
          </S.Container>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
