import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';

import cfac22 from '../../cfac22';

export function editCampaignLogin(uId, data) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      const config = {
        method: 'put',
        url: `${cfac22('API_HOST_V2')}/mapfre/editCampaignLogin`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          payload: { ...data },
        },
      };
      axios(config)
        .then((result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  });
}
