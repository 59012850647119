import React from 'react';
import { Modal } from '@mui/material';

import * as C from '../styles';

import { AiFillCloseCircle } from 'react-icons/ai';
import PostPreview from '../PostPreview';

export default function ModalPreview({
  handleCloseModal,
  isModalImageCropOpen,
  formCommunication,
  image,
}) {
  return (
    <Modal
      onClose={() => handleCloseModal('preview')}
      open={isModalImageCropOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <C.ModalContainer
        m_width={'100%'}
        height={'100%'}
        background={'transparent linear-gradient(180deg, #6E3296 0%, #37194B 100%)'}
      >
        <C.ModalBox padding={'2.5rem'}>
          <C.CloseModalIcon onClick={() => handleCloseModal('preview')}>
            <AiFillCloseCircle size={25} color="#fff" />
          </C.CloseModalIcon>

          <C.PreviewContainer>
            <PostPreview formCommunication={formCommunication} image={image} />
          </C.PreviewContainer>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}

