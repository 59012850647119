import * as React from 'react';

const IconClients = (props) => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Grupo 10571">
            <path data-name="Ret\xE2ngulo 1518" fill="none" d="M0 0h100v100H0z" />
            <g data-name="Grupo 10427">
                <g
                    data-name="Elipse 565"
                    transform="translate(25 25)"
                    fill="none"
                    stroke="#6e3296"
                    strokeWidth={4}
                >
                    <circle cx={25} cy={25} r={25} stroke="none" />
                    <circle
                        cx={25}
                        cy={25}
                        style={{
                            strokeWidth: 7.6,
                        }}
                        r={43.7}
                    />
                </g>
            </g>
            <path
                data-name="Icon material-people"
                d="M61.696 414.171a8.772 8.772 0 1 0-8.772-8.772 8.74 8.74 0 0 0 8.772 8.772zm-23.392 0a8.772 8.772 0 1 0-8.773-8.772 8.74 8.74 0 0 0 8.77 8.772zm0 5.849c-6.816 0-20.471 3.422-20.471 10.237v7.311h40.94v-7.311c0-6.816-13.658-10.237-20.471-10.237zm23.392 0c-.847 0-1.812.059-2.836.146a12.35 12.35 0 0 1 5.76 10.089v7.311h17.545v-7.31c0-6.815-13.655-10.236-20.469-10.236z"
                fill="#6e3296"
                style={{
                    strokeWidth: 1.89999,
                }}
                transform="translate(0 -371)"
            />
        </g>
    </svg>
);

export default IconClients;