import React, { useState, useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';

import { Route } from 'react-router-dom';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const DataTableTemplates = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [dataTotal, setDataTotal] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    getDataListRestApi('Marketing-Content-Templates').then((dataReceived) => {
      if (dataReceived === null) return;
      let data = Object.keys(dataReceived).map((item) => {
        dataReceived[item].key = item;
        return dataReceived[item];
      });

      setDataTotal(data);
    });
  }, []);

  const handleRequestSort = (property) => (event) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Route
      render={({ history }) => (
        <div className="scrollbar4" style={{ maxHeight: '100%', width: '100%' }}>
          <div>
            <Table stickyHeader aria-labelledby="tableTitle" style={{ marginTop: '1rem' }}>
              <TableHead>
                <TableRow>
                  {props.rows.map((row) => (
                    <TableCell
                      key={row.id}
                      numeric={row.numeric}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === row.id ? order : false}
                      style={{ fontWeight: '550' }}
                    >
                      <Tooltip
                        title={row.id === 'thumbnail' ? 'Thumbnail' : 'Ordenar'}
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={row.id === 'thumbnail' ? null : handleRequestSort(row.id)}
                          style={{
                            cursor: row.id === 'thumbnail' ? 'default' : 'pointer',
                          }}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(dataTotal, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((template) => (
                    <TableRow
                      key={template.key}
                      hover
                      tabIndex={-1}
                      onClick={() =>
                        history.push({
                          pathname: props.pathName + template.key,
                          state: template,
                        })
                      }
                    >
                      <TableCell scope="row">{template.name}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>

          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={dataTotal.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <br />
          <br />
          <br />
        </div>
      )}
    />
  );
};

export default DataTableTemplates;

