import React from 'react';
import loading from 'styles/assets/ii-spin-asset.png';
import './style.scss'

export const Loading = () => {
    return  <div className='flex-center'>
                <img alt="" src={ loading} />
            </div>
};

export default Loading;