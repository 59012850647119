import React from 'react';
import * as S from './styles';
import { TriiboH1 } from 'js/components/DesignSystem/styles';
import { StyledButton } from '../AdminFunctions/styledButton';
import { AreaHeader, GoBack } from '../../Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

const cardsList = [
  {
    url: '/admin/configuracoes/campanhaMapfre',
    value: 'Campanha MAPFRE',
  },
  {
    url: '/admin/configuracoes/configHomeMapfre',
    value: 'Home MAPFRE',
  },
  {
    url: '/admin/configuracoes/loginPersonalizado',
    value: 'Login Personalizado',
  },
];

const cardsListOrdered = cardsList.sort((a, b) => {
  let x = a.value.toUpperCase(),
    y = b.value.toUpperCase();

  return x === y ? 0 : x > y ? 1 : -1;
});

const MapfreMenu = () => {
  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container style={{ marginTop: '1rem' }}>
        <TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3rem',
          }}
        >
          Funções MAPFRE
        </TriiboH1>

        <S.Grid>
          {cardsListOrdered.map((card, index) => (
            <StyledButton value={card.value} url={card.url} index={index} key={index} />
          ))}
        </S.Grid>
      </S.Container>
    </div>
  );
};

export default MapfreMenu;

