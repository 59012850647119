import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function WidgetWhatsapp() {
  return (
    <a
      href='https://api.whatsapp.com/send?phone=5511944965799'
      target='new'
      style={{
        position: 'fixed',
        bottom: 10,
        right: 20,
        zIndex: 99999999,
        textTransform: 'none',
        backgroundColor: '#4bc858',
        padding: '10px',
        borderRadius: '38%',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      }}>
      <WhatsAppIcon style={{ fontSize: '40px', color: 'white' }} />
    </a>
  );
}

export default WidgetWhatsapp;
