import { Button, Snackbar, Input, Grid, Checkbox, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Search } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';

import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { cellPhoneMask2 } from 'js/library/utils/helpers';
import { updateDatabase, removeDatabase } from 'js/library/services/DatabaseManager';

const AdminConfiguration = () => {
  const [administrador, setAdministrador] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [listaPerfis, setListaPerfis] = useState([]);
  const [userCreated, setUserCreated] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.pathname = null;
  }, []);

  const searchUser = (name) => {
    userReadCellphone_v3('+' + name.replace(/\D+/g, '')).then((verification) => {
      if (verification !== false) {
        getDataListRestApi(
          'Perfil-Users/' + verification.userInfo.triiboId.replace('+', '%2b')
        ).then((dataReceived) => {
          setListaPerfis(dataReceived === null ? [] : dataReceived.listaPerfis);
          setUserCreated(dataReceived === null ? true : null);
        });
        setNome(verification.userInfo.name);
        setEmail(verification.userInfo.triiboId);
      } else {
        setNome(false);
        setOpenAlert(true);
      }
    });
  };

  const handleChangeCheckBox = (perfil) => () => {
    let updatedPerfis = [...listaPerfis];
    const index = updatedPerfis.indexOf(perfil);

    if (index >= 0) {
      updatedPerfis.splice(index, 1);
    } else {
      updatedPerfis.push(perfil);
    }

    setListaPerfis(updatedPerfis);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      let emailRemovido = null;
      if (
        listaPerfis.indexOf('Usuario') < 0 &&
        listaPerfis.indexOf('Prestador') < 0 &&
        listaPerfis.indexOf('Administrador') < 0 &&
        listaPerfis.indexOf('Administrador-Triibo') < 0
      ) {
        emailRemovido = email;
        email = null;
      }

      const data =
        userCreated !== false
          ? { dataCriacao: Date.now(), listaPerfis: listaPerfis }
          : { listaPerfis: listaPerfis };

      console.log('caiu aqui');

      await updateDatabase('Perfil-Users', data, email);
      removeDatabase('Perfil-Users', emailRemovido);

      console.log('caiu aqui');

      setLoading(false);
      history.push('/admin/configuracoes');
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="loading">
        <Loading />
      </div>
    );

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cadastro de Administradores da Triibo" />
      <br />
      <br />

      <Grid container>
        <Grid key="administrador" item md={6} sm={12} xs={12}>
          <Input
            fullWidth={true}
            value={administrador}
            id="formatted-text-mask-input"
            onChange={(e) => setAdministrador(cellPhoneMask2(e.target.value))}
            endAdornment={
              <Search style={{ cursor: 'pointer' }} onClick={() => searchUser(administrador)} />
            }
          />
        </Grid>
      </Grid>

      {nome !== '' && nome !== false && (
        <Grid container>
          <Grid item md={3} sm={6} xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="subtitle2" color="secondary" style={{ fontWeight: '500' }}>
              Nome:
            </Typography>
            {nome}
          </Grid>

          <Grid item md={3} sm={6} xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="subtitle2" color="secondary" style={{ fontWeight: '500' }}>
              Email:
            </Typography>
            {email.replace(/[,]/gi, '.')}
          </Grid>

          <Grid item md={12} style={{ marginTop: '20px' }}>
            Usuário
            <Checkbox
              onClick={handleChangeCheckBox('Usuario')}
              checked={listaPerfis.includes('Usuario')}
            />{' '}
            | Prestador{' '}
            <Checkbox
              onClick={handleChangeCheckBox('Prestador')}
              checked={listaPerfis.includes('Prestador')}
            />{' '}
            | Administrador{' '}
            <Checkbox
              onClick={handleChangeCheckBox('Administrador')}
              checked={listaPerfis.includes('Administrador')}
            />{' '}
            | Administrador Triibo{' '}
            <Checkbox
              onClick={handleChangeCheckBox('Administrador-Triibo')}
              checked={listaPerfis.includes('Administrador-Triibo')}
            />{' '}
            | Dono de Estabelecimento{' '}
            <Checkbox
              onClick={handleChangeCheckBox('Dono-Estabelecimento')}
              checked={listaPerfis.includes('Dono-Estabelecimento')}
            />{' '}
            | Administrador de Estabelecimento{' '}
            <Checkbox
              onClick={handleChangeCheckBox('Administrador-Estabelecimento')}
              checked={listaPerfis.includes('Administrador-Estabelecimento')}
            />
          </Grid>
        </Grid>
      )}

      <Button
        disabled={nome === '' || nome === false}
        style={{
          marginTop: '20px',
          color: 'white',
          textTransform: 'capitalize',
          fontWeight: '550',
        }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Salvar
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Este usuário não existe</span>}
      />
    </div>
  );
};

export default AdminConfiguration;

