import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../../cfac22';

export function preGenVouchers({
  fileName,
  batchName,
  forceConsumed,
  prefix,
  expirationDate,
  skip,
  origin,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await authToken();
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/vouchers/batch/preGenVouchers`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Origin: origin,
        },
        data: {
          fileName,
          batchName,
          forceConsumed,
          prefix,
          expirationDate,
          skip,
        },
      };

      const response = await axios(config);
      console.log(response.data, 'resposta aqui');
      resolve(response.data);
    } catch (error) {
      reject(error.response ? error.response.data : error.message);
    }
  });
}

