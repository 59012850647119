import { Typography, Box, Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React, { useState } from 'react';

export default function EditShowId(props) {
  const [copySuccess, setCopySuccess] = useState('Copiar ID');

  const copyIdToClipboard = () => {
    navigator.clipboard.writeText(props.id);
    setCopySuccess('ID copiado');
  };

  return (
    <div>
      <Typography
        variant='h5'
        gutterBottom
        color='primary'
        marginBottom='10px'
        style={{ fontWeight: '700', fontSize: '25px' }}>
        {props.text}
      </Typography>
      <Box display='flex'>
        <Button
          variant='contained'
          color='primary'
          component='span'
          style={{
            fontWeight: '550',
            color: '#fff',
            textTransform: 'capitalize',
          }}
          startIcon={<FileCopyIcon />}
          onClick={() => copyIdToClipboard()}>
          {copySuccess}
        </Button>
      </Box>
    </div>
  );
}
