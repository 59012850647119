import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import * as D from 'js/components/DesignSystem/styles';
import * as S from 'js/components/Establishment/styles';

import { CircularProgress, Box } from '@mui/material';

import { downloadImage } from 'js/library/services/StorageManager';

import StepOne from './EstablishmentSteps/StepOne';
import StepTwo from './EstablishmentSteps/StepTwo';
import StepThree from './EstablishmentSteps/StepThree';
import { getBase64FromUrl, getUserInfo, isAdminPage } from 'js/library/utils/helpers';
import { updateEstablishmentAction } from 'js/core/actions/establishmentInformationActions';
import { AreaHeader, GoBack } from '../Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { getEstablishmentChangesRequest } from 'js/library/utils/API/Curation/getEstablishmentChangesRequest';
import { establishmentApproval } from 'js/library/utils/API/Curation/establishmentApproval';

const EstablishmentEditApproval = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let cellphone = useSelector((state) => state.phoneInputModel);

  let [estThumb, setEstThumb] = useState('');
  let [galleryArr, setGalleryArr] = useState(['']);

  const origin = { ...props.location.state };

  const idEstabLocalStorage = localStorage.getItem('idEstablishment');

  const idEstablishment = origin.id ?? idEstabLocalStorage;

  const [progressOne, setProgressOne] = useState(100);
  const [progressTwo, setProgressTwo] = useState(100);
  const [progressThree, setProgressThree] = useState(100);
  const [formEdit, setFormEdit] = useState({
    id: props.location.state.id ? props.location.state.id : '',

    idGestor: props.location.state.idGestor ? props.location.state.idGestor : '',

    nome: props.location.state.nome ? props.location.state.nome : '',

    keywords: props?.location?.state?.keywords ? props?.location?.state?.keywords : [],

    descricao: props.location.state.descricao ? props.location.state.descricao : '',

    tags: props?.location?.state?.tags ? props?.location?.state?.tags : [],

    vitrineOrdem: props.location.state.vitrineOrdem ? props.location.state.vitrineOrdem : '',

    dataInclusao: props?.location?.state?.dataInclusao
      ? props?.location?.state?.dataInclusao
      : Date.now(), // + 31536000000,

    dataAlteracao: Date.now(), // + 31536000000,

    endereco: props.location.state.endereco ? props.location.state.endereco : '',

    lat: props?.location?.state?.lat ? props?.location?.state?.lat : 0,

    long: props?.location?.state?.long ? props?.location?.state?.long : 0,

    complemento: props.location.state.complemento ? props.location.state.complemento : '',

    site: props.location.state.site ? props.location.state.site : '',

    email: props.location.state.email ? props.location.state.email : '',

    contatos: props.location.state.contatos ? props.location.state.contatos : [],

    aprovacao: props.location.state.aprovacao ? props.location.state.aprovacao : null,

    fotoThumb: props.location.state.fotoThumb ? props.location.state.fotoThumb : '',

    listaFotos: props.location.state.listaFotos ? props.location.state.listaFotos : [],

    funcionamento: props.location.state.funcionamento
      ? props.location.state.funcionamento
      : [
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: false,
          },
          {
            ativo: false,
          },
        ],

    thumbnailFile: [],
    photoListFile: [],
    OldThumbnail: [],
    OldPhotoList: [],

    isPublished:
      props.location.state.isPublished === undefined
        ? isAdminPage()
        : props.location.state.isPublished,

    enableDate: props.location.state.enableDate ? props.location.state.enableDate : Date.now(), // + 31536000000,

    disableDate: props.location.state.disableDate
      ? props.location.state.disableDate
      : Date.now() + 31556926 * 1000 * 20, //TODO TRATAR DAS DATAS DEPOIS

    contatoComercial: props.location.state.contatoComercial
      ? props.location.state.contatoComercial
      : [
          {
            type: 'telefone',
            value: '',
          },
        ],

    contatoTriibo: props.location.state.contatoTriibo ? props.location.state.contatoTriibo : [],

    horarios: props.location.state.horarios
      ? props.location.state.horarios
      : [
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'fechado',
          },
          {
            tipo: 'fechado',
          },
        ],

    redesSociais: props.location.state.redesSociais ? props.location.state.redesSociais : [],

    cep: props.location.state.cep ? props.location.state.cep : '',

    logradouro: props.location.state.logradouro ? props.location.state.logradouro : '',

    cidade: props.location.state.cidade ? props.location.state.cidade : '',

    estado: props.location.state.estado ? props.location.state.estado : '',

    bairro: props.location.state.bairro ? props.location.state.bairro : '',

    numero: props.location.state.numero ? props.location.state.numero : '',
    messageApproval: '',

    newStatus: null,
    status: '',

    goBack: false,

    open: false,

    disableButtonUpdate: true,

    signatureId: '',
    useFullLinks: [],
  });

  useEffect(() => {}, []);

  const changeApproval = async (newStatus) => {
    let newImg = await getBase64FromUrl(estThumb);
    let newImgArr = [];

    if (galleryArr !== null) {
      for (const key in galleryArr) {
        if (galleryArr[key].indexOf('https') === 0) {
          newImgArr[key] = await getBase64FromUrl(galleryArr[key]);
        }
      }
    }

    updateEstablishmentAction(
      dispatch,
      formEdit.id,
      formEdit.idGestor,
      formEdit.nome,
      formEdit.keywords,
      formEdit.tags,
      formEdit.vitrineOrdem,
      formEdit.dataInclusao,
      formEdit.dataAlteracao,
      formEdit.endereco,
      formEdit.lat,
      formEdit.long,
      formEdit.complemento,
      formEdit.site,
      formEdit.email,
      formEdit.contatos,
      formEdit.descricao,
      formEdit.fotoThumb,
      formEdit.listaFotos,
      formEdit.funcionamento,
      newImg,
      newImgArr,
      formEdit.OldThumbnail,
      formEdit.OldPhotoList,
      formEdit.isPublished,
      new Date(formEdit.enableDate).getTime(),
      new Date(formEdit.disableDate).getTime(),

      formEdit.contatoComercial,
      formEdit.contatoTriibo,
      formEdit.horarios,
      formEdit.redesSociais,
      formEdit.cep,
      formEdit.logradouro,
      formEdit.cidade,
      formEdit.estado,
      formEdit.bairro,
      formEdit.numero,
      formEdit.messageApproval,
      newStatus,
      formEdit.signatureId,
      formEdit.useFullLinks
    );
    history.push({
      pathname: `/admin/aprovado/estabelecimentos`,
    });
  };

  const checkChange = (e, status) => {
    e.preventDefault();
    const formCopy = { ...formEdit };
    if (
      formCopy.nome.length < 4 ||
      formCopy.descricao.length < 5 ||
      !formCopy.tags[0] ||
      !formCopy.email ||
      !formCopy.contatos[0] ||
      !formCopy.contatoTriibo[0] ||
      !estThumb ||
      !galleryArr.length
    ) {
      toast.error(`Os seguintes campos ainda precisam ser preenchidos:
        ${formCopy.nome.length < 4 ? 'NOME DO NEGÓCIO PRECISA TER NO MÍNIMO 4 CARACTERES,' : ''}
        ${
          formCopy.descricao.length < 5
            ? 'DESCRIÇÃO DO NEGÓCIO PRECISA TER NO MÍNIMO 5 CARACTERES,'
            : ''
        }
        ${!formCopy.tags[0] ? 'TAGS DO SEU NEGÓCIO,' : ''}
        ${!formCopy.email ? 'EMAIL DO ATENDIMENTO,' : ''}
        ${!formCopy.contatos[0] ? 'TELEFONE PRIVADO,' : ''}
        ${!formCopy.contatoTriibo[0] ? 'CONTATOS PARA OS CLIENTES' : ''}
        ${!estThumb ? 'FOTO PRINCIPAL,' : ''}
        ${!galleryArr.length ? 'LISTA DE FOTOS' : ''}
        `);
      return;
    }

    const uId = getUserInfo().uId;

    if (formEdit.action === 'create') {
      establishmentApproval(uId, idEstablishment, formEdit.messageApproval, status)
        .then(() => {
          changeApproval(status);
        })
        .catch(() => {
          toast.error(
            `Erro ao ${status === 'aprovado' ? 'aprovar' : 'reprovar'} o estabelecimento!`
          );
        });
    }
  };

  useEffect(() => {
    getEstablishmentChangesRequest(idEstablishment)
      .then((result) => {
        if (result.success) {
          const { establishmentApprovals } = result.response;
          setFormEdit({
            ...formEdit,
            status: Object.values(establishmentApprovals)[0].status,
            comment: Object.values(establishmentApprovals)[0].comment,
            action: Object.values(establishmentApprovals)[0].action,
          });
        }
      })
      .catch((err) => {});
  }, [props.location.state]);

  useEffect(() => {
    downloadImage('estabelecimento', props.location.state.fotoThumb)
      .then((downloadedImage) => {
        setEstThumb(downloadedImage);
      })
      .catch(() => {
        // console.log("Foto não encontrada:", props.location.state.fotoThumb);
      });

    if (Array.isArray(props.location.state.listaFotos)) {
      Promise.all(
        props.location.state.listaFotos.map((photo) => downloadImage('estabelecimento', photo))
      ).then((downloadedPhotos) => {
        setGalleryArr(downloadedPhotos);
      });
    }
  }, [props.location.state]);

  if (!props.location.state) {
    return <CircularProgress size={50} color={'white'} />;
  }
  return (
    <>
      <AreaHeader style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem' }}>
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <Box sx={{ py: '3.6rem', px: { xs: '1.4rem', md: '6rem' } }}>
        {!formEdit.approverName && !formEdit.isPublished ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HeaderTitle pageInfo="Avaliar informações do negócio" />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HeaderTitle pageInfo="Informações do negócio" />
          </Box>
        )}
        <Box sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <StepOne
            formState={formEdit}
            setFormState={setFormEdit}
            cellphone={cellphone}
            progress={progressOne}
            setProgress={setProgressOne}
            editPage={true}
          />

          <br />

          <StepTwo
            formState={formEdit}
            setFormState={setFormEdit}
            progress={progressTwo}
            setProgress={setProgressTwo}
            editPage={true}
          />

          <br />

          <StepThree
            formState={formEdit}
            progress={progressThree}
            setProgress={setProgressThree}
            estThumb={estThumb}
            setEstThumb={setEstThumb}
            galleryArr={galleryArr}
            setGalleryArr={setGalleryArr}
            editPage={true}
          />
        </Box>
        <S.CardContainer>
          <S.SideContainer>
            <S.SectionLabelContainer style={{ padding: '0rem 2rem 2rem 2rem' }}>
              <Box sx={{ paddingBottom: '5rem' }}>
                <S.TitleH2>Campo de Mensagem</S.TitleH2>
              </Box>

              <Box>
                <form onSubmit={(e) => e.preventDefault()}>
                  {formEdit.status === 'aberto' ? (
                    <S.TagsLabelContainer>
                      Em caso de reprova, este campo é obrigatório
                    </S.TagsLabelContainer>
                  ) : (
                    <S.TagsLabelContainer style={{ marginTop: '0rem' }}>
                      Mensagem de Avaliação !
                    </S.TagsLabelContainer>
                  )}
                  <br />
                  <D.TriiboTextField
                    disabled={formEdit.comment}
                    rows={1}
                    placeholder={'Mensagem...'}
                    maxLength="40"
                    onChange={(e) =>
                      setFormEdit({
                        ...formEdit,
                        messageApproval: e.target.value,
                      })
                    }
                    value={
                      formEdit.status !== 'aberto' && formEdit.comment
                        ? formEdit.comment
                        : formEdit.messageApproval
                    }
                  />
                  <div>
                    {formEdit.status === 'aberto' && (
                      <>
                        <S.TagsAddButton
                          onClick={(e) => {
                            checkChange(e, 'aprovado');
                          }}
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          Aprovar
                        </S.TagsAddButton>

                        <S.FailButton
                          onClick={(e) => {
                            e.preventDefault();
                            checkChange(e, 'negado');
                          }}
                          style={{
                            marginLeft: '10px',
                            marginTop: '20px',
                          }}
                          disabled={!formEdit.messageApproval}
                        >
                          Reprovar
                        </S.FailButton>
                      </>
                    )}
                  </div>
                </form>
              </Box>
            </S.SectionLabelContainer>
          </S.SideContainer>
        </S.CardContainer>
      </Box>
    </>
  );
};

export default EstablishmentEditApproval;
