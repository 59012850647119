import {
  verificaPerfil,
  listarEstabelecimentos,
  checkUserStatus,
} from "js/library/services/AuthenticationManager";

import { getUserInfo, findValueInArray } from "js/library/utils/helpers";

import { store } from "js/core/configureStore";

const registerEstToLocal = async (establishment) => {
  // const currentState = store.getState().authModel;
  // let payload = {};
  // payload = currentState;
  // payload.establishmentList = [];
  // payload.establishmentList.push(establishment);

  const currentState = store.getState().authModel;
  let payload = {};
  payload = currentState;

  const CheckUser = await checkUserStatus();

  const cellPhone =
    getUserInfo() !== null
      ? findValueInArray(getUserInfo().contactList, "type", "cellPhone").value
      : null;

  if (!CheckUser) {
    payload.checkLogin = "deslogado";
  } else {
    const promiseProfile = await verificaPerfil(cellPhone);
    const promiseEstablishment = await listarEstabelecimentos();

    payload.authenticated = CheckUser;
    payload.superUser = promiseProfile;
    payload.loading = false;
    payload.checkLogin = "logado";

    // payload.establishmentList = [];

    payload.establishmentList = promiseEstablishment ? promiseEstablishment : [];
    payload.establishmentList.push(establishment);

    const seen = new Set();

    const filteredArr = payload.establishmentList.filter(el => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });

    payload.establishmentList = filteredArr;

    return { ...payload };
  }
};

export default registerEstToLocal;