import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { validateRegistrationDate } from '../../utils/helpers';
import cfac22 from '../cfac22';

// API QUE VERIFICA ASSINATURA DO ESTABELECIMENTO
export default function signatureStatus(uId, signatureId) {
  return new Promise((resolve, reject) => {
    // PEGAR DADOS DA ASSINATURA NO LOCAL STORAGE
    const signature = JSON.parse(localStorage.getItem(`signature_${uId}`));

    //VERIFICA SE O TEMPO DA ASSINATURA AINDA É VALIDO
    const valideDate = validateRegistrationDate(signature?.uptadeDate, 20);
    if (
      signature === null ||
      signature === undefined ||
      signatureId !== signature?.id ||
      !valideDate
    ) {
      authToken()
        .then((token) => {
          axios
            .get(`${cfac22('API_HOST_V2')}/redshiftGraphs/signature`, {
              headers: { authorization: `Bearer ${token}` },
              params: {
                signatureId: parseInt(signatureId),
              },
            })
            .then((result) => {
              const status =
                result.data.data.length <= 0 ? 'inactive' : result.data.data[0].status_pagamento;
              // SALVA ASSINATURA, DATA ATUAL E IDSIGNATURE NO LOCAL_STORAGE
              localStorage.setItem(
                `signature_${uId}`,
                JSON.stringify({
                  id: signatureId,
                  signature: status,
                  uptadeDate: Date.now(),
                })
              );
              resolve(status);
            })
            .catch((error) => {
              // console.log("SIGNATURE_ERROR: ", error);
              return reject(error);
            });
        })
        .catch((error) => {
          // console.log("ERROR_TOKEN===>", error);
          reject(error);
        });
    } else {
      resolve(signature.signature);
    }
  });
}
