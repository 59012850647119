import React, { useState } from 'react';

import { Typography, Grid, Button, Snackbar } from '@mui/material';
import { FileCopyOutlined } from '@mui/icons-material';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

const styles = {
  img: {
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
  },
  linkContent: {
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    display: 'flex',
  },
  linkLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 11,
    lineHeight: '11px',
  },
  copyButton: {
    minWidth: 'auto',
    padding: 2,
    background: 'initial',
  },
  copyButtonContent: {
    textTransform: 'initial',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column wrap',
    display: 'flex',
  },
  copyButtonLabel: {
    fontSize: 10,
    lineHeight: '10px',
  },
};

const QRCodeList = ({ qrCodeList, handleClickQRCode }) => {
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleCopy = (link) => {
    const el = document.createElement('textarea');
    el.value = link;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setSnackBarMessage('Link copiado');
  };

  return (
    <React.Fragment>
      <Typography
        variant='h5'
        gutterBottom
        color='primary'
        style={{ fontWeight: '600' }}>
        Meus materiais
      </Typography>
      <Grid container spacing={2}>
        {qrCodeList.length > 0 ? (
          qrCodeList.map((qrCode, index) => (
            <Grid key={index} item sm={3} xs={12}>
              <img
                alt={qrCode.name}
                src={
                  qrCode.imageURL !== undefined && qrCode.imageURL !== null
                    ? qrCode.imageURL
                    : placeholderThumbnail
                }
                onClick={() => handleClickQRCode(qrCode)}
                style={{
                  ...styles.img,
                  cursor: 'pointer',
                  outline: '1px solid #eee',
                }}
              />
              <Typography variant='subtitle2'>{qrCode.name}</Typography>
              {qrCode.action === 'promotion_item' && (
                <div style={styles.linkContent}>
                  <Typography variant='caption' style={styles.linkLabel}>
                    Link para divulgação: {qrCode.link}
                  </Typography>
                  <Button
                    type='button'
                    style={styles.copyButton}
                    onClick={() => handleCopy(qrCode.link)}>
                    <div style={styles.copyButtonContent}>
                      <span style={styles.copyButtonLabel}>Copiar link</span>
                      <FileCopyOutlined style={{ fontSize: 15 }} />
                    </div>
                  </Button>
                </div>
              )}
              <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                Ação:{' '}
                {qrCode.action === 'promotion_item' ? 'Abrir' : 'Consumir'}
              </Typography>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Nenhum material cadastrado.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarMessage.length > 0}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </React.Fragment>
  );
};

export default QRCodeList;
