import React, { useState } from 'react';

import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';
import Radio from 'js/components/TriiboComponents/Radio';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import { toast } from 'react-toastify';

export default function SelectAccessKey({ setFormsFields, formsFields }) {
  const [optionSelected, setOptionSelected] = useState('');

  const handleSubmit = () => {
    if (!optionSelected) {
      return toast.warn('Escolha uma opção.');
    }

    const field = {
      id: '1',
      fieldName: '',
      label: '',
      inputType: 'text',
      fieldType: optionSelected,
      inputMask: '',
      allowEdition: false,
      required: true,
      type: '',
      indexKey: true,
    };

    if (optionSelected === 'cellPhone') {
      field.fieldName = 'cellPhone';
      field.label = 'Insira seu celular';
      field.inputMask = '(99) 99999-9999';
      field.type = 'contactList';
    }

    if (optionSelected === 'cpf') {
      field.fieldName = 'cpf';
      field.label = 'Insira seu CPF';
      field.inputMask = '999.999.999-99';
      field.type = 'documentList';
    }

    if (optionSelected === 'cnpj') {
      field.fieldName = 'cnpj';
      field.label = 'Insira seu CNPJ';
      field.inputMask = '99.999.999/9999-99';
      field.type = 'documentList';
    }

    if (optionSelected === 'email') {
      field.fieldName = 'email';
      field.label = 'Insira seu Email';
      field.inputMask = '';
      field.type = 'contactList';
      field.inputType = 'email';
    }

    const copyFields = Array.from(formsFields).map((field) => {
      return {
        ...field,
        indexKey: false,
      };
    });

    const findCellPhone = Array.from(formsFields).find((field) => field.fieldName === 'cellPhone');

    if (optionSelected !== 'cellPhone' && !findCellPhone) {
      setFormsFields(
        new Set([
          ...copyFields,
          field,
          {
            id: '2',
            fieldName: 'cellPhone',
            label: 'Insira seu celular',
            inputType: 'text',
            fieldType: 'cellPhone',
            inputMask: '(99) 99999-9999',
            allowEdition: false,
            required: true,
            type: 'contactList',
            indexKey: false,
          },
        ])
      );
    } else {
      setFormsFields(new Set([...copyFields, field]));
    }
  };

  return (
    <S.Container>
      <T.TriiboH4>
        Escolha a opção que deseja utilizar como chave única de acesso (não pode se repetir). Essa
        opção não poderá ser alterada posteriormente.
      </T.TriiboH4>

      <S.RadiosContainer>
        <Radio
          label={'Celular de validação'}
          size={'20px'}
          value={'cellPhone'}
          checked={optionSelected === 'cellPhone'}
          onChange={(e) => setOptionSelected(e.target.value)}
        />
        <Radio
          label={'CPF'}
          size={'20px'}
          value={'cpf'}
          checked={optionSelected === 'cpf'}
          onChange={(e) => setOptionSelected(e.target.value)}
        />
        <Radio
          label={'CNPJ'}
          size={'20px'}
          value={'cnpj'}
          checked={optionSelected === 'cnpj'}
          onChange={(e) => setOptionSelected(e.target.value)}
        />
        <Radio
          label={'Email'}
          size={'20px'}
          value={'email'}
          checked={optionSelected === 'email'}
          onChange={(e) => setOptionSelected(e.target.value)}
        />
      </S.RadiosContainer>

      <T.TriiboH5>
        Caso deseje utilizar outro tipo de chave, entre em contato com nosso suporte para verificar
        a disponibilidade.
      </T.TriiboH5>

      <PrimaryButton onClick={handleSubmit}>Salvar</PrimaryButton>
    </S.Container>
  );
}
