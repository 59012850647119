import React, { useCallback, useState, useEffect } from 'react';

// services
import { HeaderServices } from './HeaderServices';

import {
  Box,
  Grid,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  Radio,
} from '@mui/material';
import RegisterModal from './RegisterModal';

// input mask
import InputMask from 'react-input-mask';
import useStyles from './styles';
import * as S from './styles';
// icons
import { useModal } from '../Context/Attendance';
import { randomizeNumbersString } from 'js/library/utils/helpers';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import { Search as SearchIcon } from '@mui/icons-material';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';

function Header({ title, action, isModalHeader, establishmentName }) {
  const [userData, setUserData] = useState({
    userv3: {},
    partnerList: [],
  });
  const [walletData, setWalletData] = useState({
    activeCoupons: null,
    inactiveCoupons: null,
    totalPoints: null,
  });
  const { setIsUserRegister, setCurrentCellphone } = useModal();

  const [searchInputText, setSearchInputText] = useState('');
  const [searchType, setSearchType] = useState('phone');

  const [status, setStatus] = useState({
    snackMessage: '',
    openModal: false,
    loading: false,
  });

  const [inputSearchCell, setInputSearchCell] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputSearchCell('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    if (searchType === 'phone') {
      setSearchInputText(currentDDD + inputSearchCell.replace(/\D/g, ''));
      setCurrentCellphone(currentDDD + inputSearchCell.replace(/\D/g, ''));
    }
  }, [inputSearchCell]);

  useEffect(() => {
    setSearchInputText('');
    setInputSearchCell('');
  }, [searchType]);

  const [redirect, setRedirect] = useState(false);

  const headerServices = new HeaderServices();

  const classes = useStyles();

  const userTypeInputOptions = {
    mapfreCheck: null,
    cofryCheck: null,
  };

  const inputMaskFormat = searchType === 'phone' ? '(99) 9 9999-9999' : '999.999.999-99';

  const handleChangeSearchType = (event) => setSearchType(event.target.value);

  const handleSearchInput = (event) => {
    setCurrentCellphone(event.target.value);
    setSearchInputText(event.target.value);
  };
  const specialCharCellphone = searchInputText.replace(/\D/g, '');

  const handleSearchSubmit = useCallback(
    (event) => {
      event.preventDefault();

      headerServices.getUser({
        setUserData,
        searchInputText,
        searchType,
        userTypeInputOptions,
        setRedirect,
        setWalletData,
        setStatus,
        setIsUserRegister,
      });
    },
    [searchInputText, searchType, userTypeInputOptions, headerServices]
  );

  const handleChangeModalRegister = () => {
    setIsUserRegister(true);
    setStatus({
      snackMessage: 'Usuário não encontrado!',
      openModal: true,
    });
  };

  useEffect(() => {
    const walletValues = Object.values(walletData).filter((value) => value !== null);

    if (redirect) {
      walletValues.length > 0 && userData.userv3
        ? action(userData, walletData)
        : handleChangeModalRegister();
    }
  }, [redirect, walletData, userData]);

  return (
    <div style={{ marginTop: '0', marginRight: '1rem' }}>
      <GoBackHeader />
      <Box pt={4} style={{ width: '100%' }}>
        <RegisterModal
          setSearchInputText={setSearchInputText}
          setInputSearchCell={setInputSearchCell}
          establishmentName={establishmentName}
          userCellphone={specialCharCellphone}
          open={status.openModal}
          setStatus={setStatus}
        />

        {!isModalHeader && (
          <div>
            <HeaderTitle pageInfo={title} />
            <br />
          </div>
        )}

        <form onSubmit={handleSearchSubmit}>
          <Grid container>
            <Grid item lg={isModalHeader ? 9 : 4} sm={isModalHeader ? 9 : 6} xs={12}>
              {searchType === 'phone' ? (
                <S.searchCellBox>
                  <select
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    value={currentCountry}
                    onChange={handleCountryChange}
                  >
                    {phoneMaskArray.map((country, index) => (
                      <option
                        style={{
                          fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                        }}
                        key={country.id}
                        value={[country.ddd, country.mask, country.fullMask]}
                      >
                        {country.emoji} {country.initials} {country.ddd}
                      </option>
                    ))}
                  </select>

                  <InputMask
                    mask={inputMask}
                    value={inputSearchCell}
                    placeholder={randomizeNumbersString(inputMask)}
                    onChange={(e) => setInputSearchCell(e.target.value)}
                    className="searchInput"
                  />
                </S.searchCellBox>
              ) : (
                <InputMask
                  mask={inputMaskFormat}
                  value={searchInputText}
                  onChange={handleSearchInput}
                  disabled={false}
                  maskChar=" "
                >
                  {() => (
                    <TextField
                      id="outlined-basic"
                      label="Pesquisa de usuário"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={
                        searchType === 'phone'
                          ? 'Insira o número do usuário'
                          : 'Insira o CPF do usuário'
                      }
                    />
                  )}
                </InputMask>
              )}

              <Box display="flex" justifyContent="space-between" textAlign="left">
                <Box component="span">
                  <span className={classes.radioOption}>
                    <Radio
                      checked={searchType === 'phone'}
                      onChange={handleChangeSearchType}
                      value="phone"
                      id="radio-button-phone"
                    />
                    <label htmlFor="radio-button-phone">Celular</label>
                  </span>
                  <span className={classes.radioOption}>
                    <Radio
                      checked={searchType === 'cpf'}
                      onChange={handleChangeSearchType}
                      value="cpf"
                      id="radio-button-cpf"
                    />
                    <label htmlFor="radio-button-cpf">CPF</label>
                  </span>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={isModalHeader ? 2 : 1} sm={2} xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                classes={{ root: classes.searchButton }}
                disabled={status.loading || !searchInputText}
                textTransform="capitalize"
              >
                {status.loading ? <CircularProgress color="default" size={25} /> : 'Pesquisar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

export default React.memo(Header);

