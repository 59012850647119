import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { separateBase64String } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';

const uploadBgImage = async (image, clubeId) => {
  if (image.split(',').length !== 2) {
    toast.error('Selecione uma imagem!');
    return;
  }
  const newImage = separateBase64String(image);

  const firstPart = image.split('/');
  const [typeImg] = firstPart[1].split(';');

  const response = await uploadFiles('campaignLp', {
    mimeType: newImage.mimeType,
    fileName: `bgModalModule_${typeImg}_${clubeId}`,
    buffer: newImage.buffer,
  });

  if (response) return response;
};

const typeLayoutImg = [
  {
    option: 'Repetida',
    value: 'repeat',
  },
  {
    option: 'Fixa',
    value: 'fixed',
  },
];

export default {
  uploadBgImage,
  typeLayoutImg,
};
