import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 2rem;
`;

export const ImageImportContainer = styled.div`
  border: 1px
    ${(props) =>
      props.imageIsImported === true ? "solid #C1C1C1" : "dashed #707070"};
  border-radius: ${(props) =>
    props.imageIsImported === true ? "20px" : "10px"};
  background: #f2f2f2;
  padding: 1.5rem;
  max-width: ${(props) => (props.imageIsImported === true ? "420px" : "260px")};
  width: 100%;
  margin: auto;
`;

export const ImageImportBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h5 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    font-family: "Source Sans Pro", sans-serif;
    color: #757575;

    span {
      color: #4994a1;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;

    h6 {
      color: #757575;
      font-weight: normal;
    }
  }
`;

export const ButtonImportImage = styled.button`
  height: 42px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  background: #328796;
  border: none;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  cursor: pointer;
`;

export const PreviewImageBox = styled.div`
  position: relative;
  border-radius: 10px;
  height: 100%;

  .image {
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
    border: 1px solid #c1c1c1;
  }
`;

export const ResetImage = styled.button`
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;

  border-radius: 50%;
  width: 29px;
  height: 29px;
  border: 1px solid #fff;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

export const editImage = styled.button`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  background: #328796;
  height: 42px;
  padding: 0 1rem;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 830px) {
    position: initial;
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
  }
`;
