import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    main: {
        minHeight: '100vh',
        margin: '0 auto',
        padding: '35px 22px',
        backgroundColor: '#F2F2F2',
        boxSizing: 'border-box'
    },
    paper: {
        width: '100%',
        padding: '10px 30px',
        boxSizing: 'border-box'
    },
    img: {
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block'
    },
    button: {
        fontWeight: '550', 
        color: 'white', 
        textTransform: 'capitalize', 
        whiteSpace: 'nowrap'
    },
    imageContainer: {
        width: '50%',
        maxWidth: '1240px',
        margin: '0 auto',
        paddingBottom: 10,
        backgroundColor: '#fff',
        overflow: 'hidden',
        outline: '1px solid #eee',
        [theme.breakpoints.down('sm')]:{
            width: '100%',
        }
    },
    imageTitle: {
        lineHeight: '20px',
        padding: 5,
        textAlign: 'center',
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff',
        backgroundColor: '#ffa500',
        whiteSpace: 'nowrap'
    },
    imageDescription: {
        lineHeight: '15px',
        padding: 5,
        textAlign: 'center',
        fontSize: 13,
        fontWeight: 'bold',
        color: '#000',
        whiteSpace: 'nowrap'
    },
    QRCode: {
        width: '50%',
        height: 'auto',
        margin: '0 auto',
        display: 'block'
    },
    modal: {
        padding: 15,
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    modalPaper: {
        width: 700,
        maxWidth: '90%',
        maxHeight: '90%',
        padding: '50px 20px 30px 20px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        position: 'relative'
    },
}))