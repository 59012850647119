import React, { useState } from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { cloneDeep } from 'lodash';

import { toast } from 'react-toastify';

import ToggleSwitch from 'js/components/TriiboComponents/ToggleSwitch/ToggleSwitch';

import { compareDatesFromStr, convertDatesToNewFormat } from 'js/library/utils/helpers';

const EditTimesTableModal = (props) => {
  const {
    open,
    setOpen,
    formState,
    setFormState,
    finalFormState,
    setFinalFormState,
    setDisabledButton,
  } = props;
  const times = cloneDeep(formState.funcionamento);
  const [newTimes, setNewTimes] = useState(times);

  const backup = [
    {
      ativo: true,
      horarioFim: '18:00',
      horarioInicio: '09:00',
    },
    {
      ativo: true,
      horarioFim: '18:00',
      horarioInicio: '09:00',
    },
    {
      ativo: true,
      horarioFim: '18:00',
      horarioInicio: '09:00',
    },
    {
      ativo: true,
      horarioFim: '18:00',
      horarioInicio: '09:00',
    },
    {
      ativo: true,
      horarioFim: '18:00',
      horarioInicio: '09:00',
    },
    {
      ativo: false,
    },
    {
      ativo: false,
    },
  ];

  const weekArr = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

  return (
    <>
      <D.ModalContainer show={open}>
        <D.ModalBody>
          <S.ModalLabel>
            Configure os horários de <b>funcionamento</b>
          </S.ModalLabel>
          <S.TimesEditContainer>
            <S.TimesEditElements>
              {newTimes.map(({ ativo, horarioFim, horarioInicio }, index) => (
                <S.TimesEditRow key={index}>
                  <S.WeekdayContainer>{weekArr[index]}</S.WeekdayContainer>
                  <div
                    onClick={() => {
                      let tempData = [...newTimes];

                      if (tempData[index].ativo === true) {
                        delete tempData[index].horarioFim;
                        delete tempData[index].horarioInicio;
                        tempData[index].ativo = false;
                      } else {
                        tempData[index].ativo = true;
                        tempData[index].horarioFim = '18:00';
                        tempData[index].horarioInicio = '09:00';
                      }

                      setNewTimes([...tempData]);
                    }}
                  >
                    <ToggleSwitch checked={newTimes[index].ativo} />
                  </div>
                  <S.InputHours
                    value={newTimes[index].horarioInicio ? newTimes[index].horarioInicio : '  :  '}
                    onChange={(e) => {
                      let tempData = [...newTimes];

                      tempData[index].horarioInicio = e.target.value;

                      setNewTimes([...tempData]);

                      if (
                        compareDatesFromStr(
                          tempData[index].horarioFim,
                          tempData[index].horarioInicio
                        )
                      ) {
                        toast.error(
                          'Horário inválido: data de fechamento deve ser posterior à data de abertura.'
                        );
                      }
                    }}
                    disabled={!newTimes[index].ativo}
                  />
                  <S.At>às</S.At>
                  <S.InputHours
                    value={newTimes[index].horarioFim ? newTimes[index].horarioFim : '  :  '}
                    onChange={(e) => {
                      let tempData = [...newTimes];

                      tempData[index].horarioFim = e.target.value;

                      setNewTimes([...tempData]);
                    }}
                    disabled={!newTimes[index].ativo}
                  />
                </S.TimesEditRow>
              ))}
            </S.TimesEditElements>
          </S.TimesEditContainer>
          <S.ButtonContainer modal={true}>
            <D.TriiboWhiteButton
              blue={true}
              onClick={() => {
                setFinalFormState
                  ? setFinalFormState({
                      ...finalFormState,
                      funcionamento: backup,
                    })
                  : setFormState({ ...formState, funcionamento: backup });
                setOpen(false);
              }}
            >
              Cancelar
            </D.TriiboWhiteButton>
            <D.TriiboFilledButton
              blue={true}
              onClick={(e) => {
                e.preventDefault();
                setDisabledButton && setDisabledButton(false);
                if (setFinalFormState) {
                  setFinalFormState({
                    ...finalFormState,
                    funcionamento: newTimes,
                  });
                } else {
                  setFormState({
                    ...formState,
                    funcionamento: newTimes,
                    horarios: convertDatesToNewFormat(newTimes),
                  });
                }

                setOpen(false);
              }}
            >
              Salvar
            </D.TriiboFilledButton>
          </S.ButtonContainer>
        </D.ModalBody>
        <D.ModalBackground
          onClick={() => {
            if (setFinalFormState) {
              setFinalFormState({
                ...finalFormState,
                funcionamento: backup,
              });
            } else {
              setFormState({
                ...formState,
                funcionamento: backup,
              });
            }
            setOpen(false);
          }}
        />
      </D.ModalContainer>
    </>
  );
};

export default EditTimesTableModal;
