import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  contentNotFound:{
    overflowY: 'hidden',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },

  content:{
    overflowY: 'hidden',
  },

  root: {
    height: "100%",
    padding: "2rem 7rem 9.375rem 7rem",
    [theme.breakpoints.down('md')]: {
      padding: "1rem",
      flexDirection: 'column-reverse',
    },
  },

  header: {
    height: "64px",
    padding: "1rem 6rem",
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('md')]: {
      padding: "1rem 2rem",
    },
  },

  info: {
    height: "70vh",
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    paddingTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      height: "50vh",
      width: "80vw",
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.only('md')]: {
      height: "50vh",
      width: "100vw",
      justifyContent: 'center',
      paddingLeft: '2rem'
    },
  },

  animation:{
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    },
  },

  logo: {
    width: "90px",
  },

  title: {
    fontWeight: "bold",
    color: "#06BAD0",
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '4rem'
    },
  },

  subtitle: {
    width: "600px",
    fontSize: "2rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      width: "100vw",
      marginTop: "3rem",
      marginBottom: "3rem",
      paddingRight: '1rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '2rem',
      width: "100vw",
      marginTop: "3rem",
      marginBottom: "3rem",
      paddingRight: '1rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.75rem',
      marginTop: "3rem",
      marginBottom: "3rem",
    },
  },

  link: {
    textDecoration: "none",
    width: '250px',
  },

  btnBack:{
    fontSize: '1.25rem',
    padding: '8px 20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      padding: '8px 20px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.875rem',
      padding: '10px 48px',
    },
  }
}));
