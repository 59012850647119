import React from 'react';

import * as D from 'js/components/DesignSystem/styles';

import CropperToolArr from './CropperToolArr';

const CropperModalArr = (props) => {
  const { open, setOpen, img, imgPreview, setImgPreview, setImg } = props;

  return (
    <D.ModalContainer show={open}>
      <D.ModalBody>
        <D.ModalLabelContainer>
          <D.ModalTitle>Editar imagem</D.ModalTitle>
          <D.ModalSubtitle>
            Uma foto de qualidade ajuda as pessoas a reconhecerem o seu negócio
          </D.ModalSubtitle>
        </D.ModalLabelContainer>
        <CropperToolArr
          setOpen={setOpen}
          img={img}
          imgPreview={imgPreview}
          setImgPreview={setImgPreview}
          setImg={setImg}
        />
      </D.ModalBody>
    </D.ModalContainer>
  );
};

export default CropperModalArr;
