/*
  COMO UTILIZAR

  Recebe 5 parametros: data, column, usePaginate, perPage e redirectOnClick.

  data: Array com os dados a serem renderizados,

  column: Array de objetos, onde cada objeto terá um heading (titulo da coluna) e um value (rota para acessar o valor dentro do data)

    exemplo
  [
    {heading: "Nome", value: "name"}, 
    {heading: "Cidade", value: "address.city"},
  ]

  usePaginate: Booleando para identificar se vai usar paginação ou não.

  perPage: Quantos itens vão aparecer por página,

  redirectOnClick: Função que serve para redirecionar ou efetuar alguma ação ao clicar em algum dado da tabela,

  exemplo
  [
   const redirectOnClick = (item) => {
    history.push({
      pathname: `/admin/estabelecimento/${item.id}`,
      state: item.establishment,
    });
  };
  ]

*/

import React, { useEffect, useState } from 'react';

import * as D from 'js/components/DesignSystem/Table/styles';
import usePagination from '../usePagination';
import { Pagination } from '@mui/material';

export default function SimpleTable({ data, column, usePaginate, perPage, redirectOnClick }) {
  const [page, setPage] = useState(1);

  const PER_PAGE = perPage;
  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  useEffect(() => {}, [data]);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div style={{ width: '100%' }}>
      {data.length === 0 || !data ? (
        <p style={{ textAlign: 'center' }}>Nenhum item encontrado...</p>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <D.TableContainer>
            <D.Table>
              <thead>
                <D.TableTR>
                  {column.map((item, index) => (
                    <TableHeadItem key={'column' + index} item={item} />
                  ))}
                </D.TableTR>
              </thead>
              <tbody>
                {usePaginate
                  ? _DATA
                      .currentData()
                      .map((item, index) => (
                        <TableRow
                          key={index}
                          item={item}
                          column={column}
                          redirectOnClick={redirectOnClick}
                        />
                      ))
                  : data.map((item, index) => (
                      <TableRow
                        key={index}
                        item={item}
                        column={column}
                        redirectOnClick={redirectOnClick}
                      />
                    ))}
              </tbody>
            </D.Table>
          </D.TableContainer>
          {usePaginate && (
            <Pagination
              sx={{ margin: '0 auto' }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={handleChangePage}
            />
          )}
        </div>
      )}
    </div>
  );
}

const TableHeadItem = ({ item }) => <D.TableTH>{item.heading}</D.TableTH>;
const TableRow = ({ item, column, redirectOnClick }) => {
  return (
    <D.TableTR
      // key={item.id}
      cursor={redirectOnClick !== null ? 'pointer' : null}
      onClick={() => redirectOnClick !== null && redirectOnClick(item)}
    >
      {column.map((columnItem, index) => {
        if (columnItem.value.includes('.')) {
          const itemSplit = columnItem.value.split('.');
          const tableItem = itemSplit.reduce((prev, next, index) => {
            if (index === 0) {
              prev = item[next];
            } else {
              prev = prev[next];
            }
            return prev;
          }, '');

          return <D.TableTD key={index}>{tableItem}</D.TableTD>;
        } else {
          return <D.TableTD key={index}>{item[columnItem.value]}</D.TableTD>;
        }
      })}
    </D.TableTR>
  );
};
