import axios from 'axios';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import cfac22 from '../../cfac22';

export function getBalance_v1(uId, triiboId) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1(uId)
      .then((result) => {
        axios
          .post(cfac22('API_HOST_V1') + 'getBalance_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: uId,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: 'react',
            },
            queryPartnerAPI: ['getBalance'],
            triiboId: triiboId,
          })
          .then((result) => {
            let userBalance =
              result.data.error === null
                ? {
                    balance: result.data.success.balance,
                    partnerCofry: result.data.success.partnerCofry,
                  }
                : null;

            resolve(userBalance);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
