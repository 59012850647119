import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: 1rem;
  background: ${({ bg }) => bg};
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  h4 {
    font-weight: 600;
    color: #fff;
  }
`;
