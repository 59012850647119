import React, { useState } from 'react';

//STYLES
import * as S from './styles';
import { StyledButton } from '../../../../components/Configurations/AdminFunctions/styledButton/index';
import { TriiboH1 } from '../../../../components/DesignSystem/styles';
import { RegisterUserModal } from './RegisterUserModal';
import { GoBackHeader } from './GoBackHeader';

export default function RegistrationOption({ history, ...props }) {
  const [isModalRegisterUserOpen, setIsModalRegisterUserOpen] = useState(false);

  const cardsList = [
    {
      value: 'Cadastrar individual',
      url: undefined,
    },
    {
      value: 'Cadastrar em lote',
      url: `/admin/seu-clube/relacionamento/cadastra-lista-usuarios/${props.match.params.id}`,
    },
    {
      value: 'Ver membros',
      url: `/admin/seu-clube/relacionamento/lista-usuarios/${props.match.params.id}`,
    },
  ];

  const cardsListOrdered = cardsList.sort((a, b) => {
    let x = a.value.toUpperCase(),
      y = b.value.toUpperCase();

    return x === y ? 0 : x > y ? 1 : -1;
  });

  const handleOpenModalRegisterUser = () => {
    setIsModalRegisterUserOpen(true);
  };

  const handleCloseModalRegisterUser = () => {
    setIsModalRegisterUserOpen(false);
  };

  return (
    // <Container maxWidth={false} className={classes.root}>
    //   <Header history={history} pageInfo="Gerenciar membros" />

    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <S.Container>
        <TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Gerenciar membros
        </TriiboH1>

        <S.Grid>
          {cardsListOrdered.map((card, index) => (
            <StyledButton
              value={card.value}
              url={card.url === undefined ? '#' : card.url}
              index={index}
              key={index}
              onClick={
                card.value === 'Cadastrar individual' ? handleOpenModalRegisterUser : undefined
              }
            />
          ))}
        </S.Grid>

        <RegisterUserModal
          isModalRegisterUserOpen={isModalRegisterUserOpen}
          handleCloseModalRegisterUser={handleCloseModalRegisterUser}
        />
      </S.Container>
    </div>
    // </Container>
  );
}

