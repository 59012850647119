export default function reducer(state = {
  id: null, 
  templateId: null,
  voucherAmmount: null,
  expiration: null,
  batchName: null    
  }, action) {

    switch (action.type) {

      case "GENERATION_INFORMATION_PENDING": {
        return {
          ...state,
          id: null, 
          templateId: null,
          voucherAmmount: null,
          expiration: null,
          batchName: null  
        };
      }
  
      case "GENERATION_INFORMATION_FULFILLED": {
        return {
          ...state,
          id: action.payload.id,
          templateId: action.payload.templateId,
          voucherAmmount: action.payload.voucherAmmount,
          expiration: action.payload.expiration,
          batchName: action.payload.batchName
        };
      }
  
      case "GENERATION_INFORMATION_REJECTED": {
        return {
          ...state,
          id: null, 
          templateId: null,
          voucherAmmount: null,
          expiration: null,
          batchName: null  
        };
      }
  
      default: return state;
  
    }
  
  }