import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 1.5rem;
`;

export const TextCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 1rem;
  max-height: 407px;
  min-height: 200px;
  height: 100%;
  flex: 1;
`;

export const TitleCountContainer = styled(TextCountContainer)`
  min-height: 100px;
  max-height: 407px;
`;

export const TextArea = styled.textarea`
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
  background-color: #fff;
  font-size: 15px;
  border: none;
  resize: none;
  outline: none;
  flex: 1;

  ::placeholder {
    font-style: italic;
    color: #707070;
  }
`;

export const TextCounter = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  color: #707070;
`;
