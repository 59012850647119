import React from "react";

import * as S from "./styles";
import { ArrowBackIos } from "@mui/icons-material";

const GoBackRow = (props) => {
  const { text } = props;
  return (
    <S.RowContainer 
     style={{ justifyContent: 'end',padding: '2rem', marginLeft: '10rem'}}
    >
      <S.GoBackButton
        onClick={(e) => {
          e.preventDefault();
          window.history.back();
        }}
      >
        <ArrowBackIos />
        <span>Voltar</span>
      </S.GoBackButton>
      <S.TextContainer>{text ? text : ""}</S.TextContainer>
    </S.RowContainer>
  );
};

export default GoBackRow;
