import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Container } from '@mui/material';
import DataTableStoreApproval from 'js/containers/DataTable/DataTableStoreApproval';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';

const StoreApprovalList = () => {
  return (
    <Container>
      <GoBackHeader />
      <HeaderTitle pageInfo="Curadoria de Promoções" />
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <div className="position-btn2">
            <Link
              to={{ pathname: '/admin/aprovado/promocoes', state: '' }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '31px',
                  marginBottom: '15px',
                  fontWeight: '550',
                  textDecoration: 'none',
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                Ver promoções já avaliadas
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
      <DataTableStoreApproval />
    </Container>
  );
};

export default StoreApprovalList;
