import React from 'react';
import { Link } from 'react-router-dom';
import DataTableEstablishmentApproval from 'js/containers/DataTable/DataTableEstablishmentApproval';
import { Button, Container, Grid } from '@mui/material';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const EstablishmentApprovalList = () => {
  const [rows] = [
    {
      id: 'thumbnail',
      numeric: false,
      disablePadding: false,
      label: 'Thumbnail',
    },
    {
      id: 'nome',
      numeric: false,
      disablePadding: false,
      label: 'Nome do estabelecimento',
    },
    {
      id: 'tags',
      numeric: false,
      disablePadding: false,
      label: 'Tipo de estabelecimento',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'requestDate',
      numeric: false,
      disablePadding: false,
      label: 'Data de solicitação',
    },
  ];

  return (
    <Container>
      <GoBackHeader />
      <HeaderTitle pageInfo="Curadoria de Estabelecimentos" />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} />
        <Grid item md={6} xs={12}>
          <div className="position-btn3">
            <Link
              to={{ pathname: '/admin/aprovado/estabelecimentos', state: '' }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'none',
                }}
                title="Ver estabelecimentos"
                variant="contained"
                color="primary"
                size="small"
              >
                Ver estabelecimentos já avaliados
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
      <DataTableEstablishmentApproval
        rows={rows}
        tableNode={'Estabelecimento-Aprovacoes'}
        pathName="/admin/aprovacao/estabelecimentos"
      />
    </Container>
  );
};

export default EstablishmentApprovalList;

