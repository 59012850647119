import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import placeholderBanner from '../../../styles/assets/welcome/novo-banner.webp';
// import placeholderBanner from '../../../styles/assets/welcome/Banner_padrao01-min.png';

import AnimationLoading from 'js/containers/Loading/AnimationLoading';

import updateTrialActionsAction from 'js/core/actions/trialActions';
import RequisitionTrial from 'js/library/services/TrialRequisition';


// import PromoButton from "./PromoButton";
import InitialMessage from './InitialMessage';
import StepsMessage from './StepsMessage';
import CompletedMessage from './CompletedMessage';
import { getUserInfo } from 'js/library/utils/helpers';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import MyBusiness from '../MyBusiness';
import StepsGrid from './StepsGrid';

const Welcome = () => {
  const dispatch = useDispatch();
  let state = useSelector((state) => state.authModel);
  let stateTrial = useSelector((state) => state.trialModel);
  let stateStorePromo = useSelector((state) => state.storePromotionComponent);
  let stateEstablishment = useSelector((state) => state.establishmentInformationModel);

  const [isFakeSpinner, setIsFakeSpinner] = useState(true);
  const [showInitial, setShowInitial] = useState(false);
  const [allStepsOnboarding, setAllStepsOnboarding] = useState({});
  const [isCongrats, setIsCongrats] = useState(false);
  const [allStepsConcluded, setAllStepsConcluded] = useState(false);

  useEffect(() => {
    const getStatusUser = async () => {
      const uid = getUserInfo().uId;
      const res = await getTrialStatus(uid);
      if (
        res.establishmentStep === true &&
        res.storeStep === true &&
        res.relationshipStep === true &&
        res.congrats === false
      ) {
        setIsCongrats(true);
      } else {
        setAllStepsOnboarding(res);
      }
    };

    getStatusUser();

    if (allStepsOnboarding.establishmentStep === true || state.establishmentList !== null) {
      setShowInitial(true);
    }
  }, [stateEstablishment]);

  useEffect(() => {
    RequisitionTrial().then((result) => {
      dispatch(updateTrialActionsAction(result));
    });
    return;
  }, [stateStorePromo, stateEstablishment]);

  setTimeout(() => setIsFakeSpinner(false), 1200);

  const RenderConditional = () => {
    if (isCongrats === true) {
      return (
        <CompletedMessage
          establishmentId={state.establishmentList[0]}
          setAllStepsConcluded={setAllStepsConcluded}
        />
      );
    }
    if (
      allStepsOnboarding.congrats === false ||
      state.establishmentList === null ||
      state.establishmentList === undefined
    ) {
      return showInitial === false ? (
        <InitialMessage showInitial={showInitial} setShowInitial={setShowInitial} />
      ) : (
        <StepsMessage allStepsOnboarding={allStepsOnboarding} />
      );
    } else {
      if (
        state.establishmentList === null ||
        (stateTrial.hasPromo.length === 0 &&
          (stateTrial.hasClient.notRegistered.length === 0 ||
            stateTrial.hasClient.registered.length === 0))
      ) {
        return <StepsMessage allStepsOnboarding={allStepsOnboarding} />;
      } else {
        setAllStepsConcluded(true);
        return <></>;
      }
    }
  };

  if (isFakeSpinner === true) {
    return (
      <div className="containerLoading">
        <AnimationLoading className="itemLoading" />
      </div>
    );
  } else {
    return !allStepsConcluded ? (
      <>
        <StepsGrid showInitial={showInitial} placeholderBanner={placeholderBanner}>
          <RenderConditional />
        </StepsGrid>
      </>
    ) : isCongrats ? (
      <StepsGrid showInitial={showInitial} placeholderBanner={placeholderBanner}>
        {' '}
        <CompletedMessage establishmentId={state.establishmentList[0]} />
      </StepsGrid>
    ) : (
      <MyBusiness />
    );
  }
};

export default Welcome;

