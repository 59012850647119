import React, { useEffect, useRef, useState } from 'react';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import readXlsxFile from 'read-excel-file';

import usePagination from './usePagination';

import { toast } from 'react-toastify';

import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
  Table,
} from '@mui/material';

import { Pagination } from '@mui/material';

import { useStyles } from './useStyles';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { GetPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';
import { ListAllFieldTypes } from 'js/library/utils/API/apiListAllTypes';
import phone from 'phone';
import { GoBackHeader } from './GoBackHeader';
import { HeaderTitle } from './HeaderTitle';
import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';

export default function RegisterListUsers({ history, ...props }) {
  const [clientList, setClientList] = useState([]);
  const [columnsTable, setColumnsTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [clubeId] = useState(window.location.href.split('/')[7]);
  const [formInfos, setFormInfos] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [seuClubeInfos, setSeuClubeInfos] = useState({});
  const [page, setPage] = useState(1);

  const fileUploader = useRef(null);

  const PER_PAGE = 10;

  const count = Math.ceil(clientList.length / PER_PAGE);
  const _DATA = usePagination(clientList, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const classes = useStyles();

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId)
        .then((response) => {
          setSeuClubeInfos(response);
          GetPartnerUserForms(response.partnerId).then((result) => {
            setFormInfos(result);
            setLoading(false);
            const removeOptin = result.formFields.filter((field) => field.fieldName !== 'optIn');
            const columns = removeOptin.map((field) => field.fieldName);

            setColumnsTable(columns);
          });

          ListAllFieldTypes().then((result) => {
            setFieldTypes(result);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [clubeId]);

  const handleExportExcelFile = () => {
    const removeOptin = formInfos.formFields.filter((field) => field.fieldName !== 'optIn');
    const collumnsNames = removeOptin.map((field) => field.fieldName);

    // Criação da planilha
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([collumnsNames]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

    // Define a largura das colunas com base no tamanho dos dados
    const columnWidths = collumnsNames.map((field) => {
      return {
        width: 15,
      };
    });
    worksheet['!cols'] = columnWidths;

    // Conversão do arquivo para o formato binário
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Criação do Blob para salvar o arquivo
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Nome do arquivo
    const fileName = 'template.xlsx';

    // Salva o arquivo
    saveAs(blob, fileName);
  };

  const formatUserList = (formValues) => {
    let userInfoObj = {};
    let contactList = [];
    let documentList = [];
    let addressList = {};

    formInfos.formFields.forEach((field) => {
      const findBlock = fieldTypes.find(({ type }) => type === field.fieldType);

      if (findBlock.block === 'contactList') {
        if (formValues[field.fieldName]) {
          contactList.push({
            type: field.fieldName,
            value: formValues[field.fieldName].value,
          });
        }
      }

      if (findBlock.block === 'documentList') {
        if (formValues[field.fieldName]) {
          documentList.push({
            type: field.fieldName,
            value: formValues[field.fieldName].value,
          });
        }
      }

      if (findBlock.block === 'addressList') {
        if (formValues[field.fieldName]) {
          addressList[field.fieldName] = formValues[field.fieldName].value;
        }
      }

      if (findBlock.block === 'root') {
        if (formValues[field.fieldName]) {
          if (field.inputType === 'date') {
            userInfoObj[field.fieldName] = new Date(formValues[field.fieldName].value).getTime();
          } else {
            userInfoObj[field.fieldName] = formValues[field.fieldName].value;
          }
        }
      }
    });

    userInfoObj = {
      ...userInfoObj,
      contactList: [...contactList],
      documentList: [...documentList],
      addressList: { ...addressList },
      partnerId: seuClubeInfos.partnerId,
      partnerName: seuClubeInfos.clubeId,
    };

    const data = {
      partnerInfo: userInfoObj,
      clubeId: seuClubeInfos.clubeId,
      token: formValues[formInfos.tokenType].value,
      cellphone: formValues.cellPhone.value,
    };

    if (seuClubeInfos.name && seuClubeInfos.name.length !== 0) {
      data.name = seuClubeInfos.name;
    }

    return data;
  };

  const validateField = (fieldName, value) => {
    const getField = formInfos.formFields.find((field) => field.fieldName === fieldName);

    const fieldType = fieldTypes.find(({ type }) => type === getField.fieldType);

    if (fieldType && fieldType.type === 'cellPhone') {
      const phoneValidation = phone(value.toString());
      if (phoneValidation.isValid) {
        return {
          value,
          valid: true,
        };
      } else {
        return {
          value,
          valid: false,
        };
      }
    }

    if (fieldType && fieldType.type === 'cpf') {
      const cpf = value.toString().replace(/[^\d]/g, '');

      if (cpf.length === 11) {
        return {
          value: cpf,
          valid: true,
        };
      } else {
        return {
          value: cpf,
          valid: false,
        };
      }
    }

    return {
      value,
      valid: true,
    };
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const fileExt = file.name.split('.').find((item) => item === 'xlsx');

    if (fileExt === 'xlsx' && fileExt !== undefined) {
      const fileXlsx = await readXlsxFile(event.target.files[0]);

      const columns = fileXlsx[0];
      const data = fileXlsx.slice(1); // Exclui o header do array (nome, telefone, email, data de nascimento, cpf)

      const formattedData = data.map((row) => {
        const obj = {};
        columns.forEach((column, columnIndex) => {
          obj[column] = row[columnIndex]
            ? validateField(column, row[columnIndex])
            : {
                value: '',
                valid: true,
              };
        });
        return obj;
      });

      setClientList(formattedData);
    } else {
      toast.error('Arquivo não é no formato .xlsx');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingRegister(true);

    const filteredUserInfo = clientList.filter((row) => {
      if (row[formInfos.tokenType]) {
        return row;
      }
    }); // Filter para que venha apenas os usuários que possuem tokenType informados.

    const verifyingFieldsInvalids = clientList.filter((row) => {
      let fields = [];
      for (let key in row) {
        row[key].valid === false && fields.push(row[key]);
      }

      if (fields.length > 0) {
        return fields;
      }
    });

    if (verifyingFieldsInvalids.length > 0) {
      setLoadingRegister(false);
      return toast.error(
        `Dados do usuário: ${verifyingFieldsInvalids[0].aliasName.value} estão inválidos`
      );
    }

    if (filteredUserInfo.length === 0) {
      setLoadingRegister(false);
      return toast.error(
        `O ${
          formInfos.tokenType === 'cellPhone' ? 'número de celular' : formInfos.tokenType
        } é obrigatório`
      );
    }

    const formattedUsers = filteredUserInfo.map((client, index) => {
      return formatUserList(client);
    });

    try {
      RegisterUsersBatch_v2(formattedUsers)
        .then((response) => {
          if (response.status === 200) {
            toast.success(
              'Usuários cadastrados com sucesso! Em breve você poderá consultá-los na aba "Ver meus clientes".'
            );
            setClientList([]);
          }
        })
        .catch((error) => {
          toast.error('Erro ao cadastrar usuários.');
        });
    } catch (error) {
      toast.error('Ops, usuários não foram cadastrados.');
    } finally {
      setLoadingRegister(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          height: '50vh',
          width: '100%',
        }}
      >
        <CircularProgress size="6rem" />
      </div>
    );
  }
  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <Container maxWidth={false} className={classes.root}>
        <HeaderTitle pageInfo="Cadastrar em lote" />
        <Grid item xs={12} className={classes.boxInportFiles}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                Carregue um arquivo no formato Excel com o seguinte padrão:
                <br />
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleExportExcelFile}
                >
                  Exportar template
                </Button>
              </Typography>
              <br />
              <Typography variant="subtitle1" style={{ fontWeight: 600, marginTop: '22px' }}>
                <div>
                  {formInfos.tokenType === 'cellPhone'
                    ? 'OBS 1: O dado "cellphone" é obrigatório para realizar o cadastro do cliente. caso não tenha, aparecerá em vermelho na lista abaixo.'
                    : `OBS 1: Os dados "Nome e ${formInfos.tokenType}" são obrigatórios para realizar o cadastro do cliente. Caso não tenha, aparecerá em vermelho na lista abaixo.`}
                </div>
                <div>
                  Obs 2: O número de telefone tem que ter o seguinte formato: (DDD) + telefone -
                  exemplo: +5511970697706, caso contrário o mesmo não será cadastrado.
                </div>
              </Typography>{' '}
              <br />
              <div>
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => fileUploader.current.click()}
                >
                  Carregar arquivo
                </Button>
                <input
                  type="file"
                  accept=".xlsx"
                  ref={fileUploader}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.boxListUsers}>
          {clientList.length > 0 && (
            <>
              <strong>Usuários Listados: </strong>
              {clientList.length}
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columnsTable.map((column, index) => (
                        <TableCell align="left" key={index}>
                          <strong>{column}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_DATA.currentData().map((client, index) => (
                      <TableRow key={index}>
                        {columnsTable.map((item, indexColumns) => (
                          <TableCell
                            key={indexColumns}
                            style={{
                              backgroundColor: `${
                                client[item] && !client[item].valid ? 'red' : '#ffff'
                              }`,
                            }}
                            align="left"
                          >
                            {client[item] && client[item].value}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Pagination
                sx={{ mt: 3 }}
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />

              <div align="center" style={{ marginTop: 15 }}>
                <Button
                  style={{
                    fontWeight: '550',
                    color: loadingRegister ? '#383838' : '#fff',
                    textTransform: 'capitalize',
                    marginBottom: '2rem',
                    background: loadingRegister && '#dbdbdb',
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={(event) => handleSubmit(event)}
                  disabled={clubeId === '' || loadingRegister}
                >
                  {loadingRegister ? (
                    <>
                      <CircularProgress size="23px" style={{ marginRight: '0.5rem' }} />
                      Cadastrar todos
                    </>
                  ) : (
                    'Cadastrar todos'
                  )}
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
