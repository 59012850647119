import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function FieldList({ isMobile, listFields, showProgress, handleSelectFields }) {
  return (
    <Box>
      {listFields.length > 0 ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={listFields}
          getOptionLabel={(option) => option.field}
          onChange={handleSelectFields}
          filterOptions={(options, { inputValue }) => {
            if (!inputValue) return options;
            return options.filter((option) =>
              option.field.toLowerCase().startsWith(inputValue.toLowerCase())
            );
          }}
          sx={{ width: isMobile ? 250 : 300 }}
          noOptionsText="Nenhum resultado encontrado..."
          renderInput={(params) => <TextField {...params} label="Selecione um campo..." />}
          renderOption={(props, option, index) => (
            <li key={`${props.key}-${props.id}-${index.index}`} {...props}>
              {option.field}
            </li>
          )}
        />
      ) : (
        showProgress && <CircularProgress size={40} />
      )}
      {listFields.length === 0 && !showProgress && (
        <Typography variant="span">Não há campos para esse tipo...</Typography>
      )}
    </Box>
  );
}
