import React from 'react';
import { useHistory } from 'react-router';

import { Modal } from '@mui/material';
import * as D from 'js/components/DesignSystem/styles';
import { ContentModalClube, ModalBoxClube } from './BasicRegistration/styles';

export function ModalUpdateClub({ closeModal, openModal }) {
  const history = useHistory();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={closeModal}
    >
      <ModalBoxClube>
        <ContentModalClube>
          <h3>
            <span role="img" aria-label="Concluido">
              ✅
            </span>{' '}
            Clube atualizado com sucesso !
          </h3>
          <div>O que deseja fazer ?</div>
          <div>
            <D.TriiboFilledButton purple={true} style={{ marginRight: '6px' }} onClick={closeModal}>
              Continuar editando
            </D.TriiboFilledButton>
            <D.TriiboFilledButton
              blue={true}
              onClick={() => history.push('/admin/clube/seu-clube')}
            >
              Listar meus clubes
            </D.TriiboFilledButton>
          </div>
        </ContentModalClube>
      </ModalBoxClube>
    </Modal>
  );
}

