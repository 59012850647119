import React from 'react';
import { Link } from 'react-router-dom';
import logoMktPlaceImg from 'styles/assets/logoMktPlace.png';

import {
    Grid,
    Box,
    Button,
    Typography,
    Container,
    useMediaQuery,
} from '@mui/material';

import { ArrowBackIos } from '@mui/icons-material';

import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '30px 100px',

        height: '100%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    title: {
        fontWeight: 'bold',
        marginTop: '15px',
        marginLeft: '-35px',
        color: '#06BAD0',
    },

    label: {
        fontWeight: 'bold',
        marginLeft: '6%',
        fontSize: '24px ',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },

    subtitle: {
        textAlign: 'left',
        fontSize: '14px',
        letterSpacing: '0px',
        marginLeft: '6%',
    },

    subtitle1: {
        fontWeight: 'bold',
        alignItems: 'center',
        fontSize: '25px',
        marginTop: theme.spacing(3),
    },

    arrowBackButton: {
        fontWeight: 'bold',
        cursor: 'pointer',
        marginTop: '20px',
        color: '#06BAD0',
    },

    optionButton: {
        fontWeight: 'bold',
        fontSize: '20px',
        textTransform: 'capitalize',
    },

    img: {
        width: '250px',
        height: '100%',
        paddingTop: '93px'
    },

    link: {
        textDecoration: 'none',
    },

    a: {
        textDecoration: 'none',
    },
}));

const Line = styled(Box)({
    width: 1,
    height: '90%',
    marginLeft: '16%',
    alignSelf: 'center',
    backgroundColor: '#606060',
});

export default function Establishment(props) {
    const classes = useStyles();

    const matches = {
        sm: useMediaQuery('(max-width: 600px)'),
        md: useMediaQuery('(max-width: 1600px), (max-width: 1366px)'),
    };

    const optionButtonsHeightWidth = {
        width: matches.sm || matches.md ? '230px' : '230px',
        height: matches.sm || matches.md ? '40px' : '40px',
        marginBottom: matches.sm || matches.md ? '200px' : '140px',
        fontSize: matches.sm || matches.md ? '20px' : '20px',
        marginLeft: matches.sm || matches.md ? '140px' : '170px',
    };

    const optionTitleHeightWidth = {
        width: matches.sm || matches.md ? '450px' : '650px',
        height: matches.sm || matches.md ? '300px' : '200px',
        fontSize: matches.sm || matches.md ? '13px' : '14px',
    };

    const optionTitle2HeightWidth = {
        width: matches.sm || matches.md ? '500px' : '570px',
        height: matches.sm || matches.md ? '290px' : '230px',
        marginBottom: matches.sm || matches.md ? '-200px' : '-150px',
        fontSize: matches.sm || matches.md ? '22px' : '25px',
    };

    return (
        <Container maxWidth={false} className={classes.root}>
            <Grid container>
                <Grid sm={6}>
                    <Typography variant="h5" className={classes.title}>
            Comprar pontos
                    </Typography>
                </Grid>
                <Grid sm={6} container justify="flex-end">
                    <Link onClick={() => window.history.back()} className={classes.link}>
                        <Typography
                            className={classes.arrowBackButton}
                            variant="h5"
                            gutterBottom
                        >
                            <ArrowBackIos fontSize="small" />
              Voltar
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xl={6} md={7}>
                    <Box display="flex" justifyContent="center" height="100%">
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            pt={matches.md ? 6 : 3}
                            pb={matches.md ? 6 : 3}
                        >
                            <Box>
                                <Typography
                                    className={classes.subtitle1}
                                    style={optionTitle2HeightWidth}
                                >
                  Compre pontos para premiar seus clientes
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        alt=""
                                        className={classes.img}
                                        src={logoMktPlaceImg}
                                        width={matches.hightDefinitionScreen ? 250 : 140}
                                        style={{ margin: '0px' }}
                                    />
                                </div>
                            </Box>
                            <Box>
                                <a
                                    target="new"
                                    rel="noopener noreferrer"
                                    href="https://marketplace.triibo.com.br"
                                    className={classes.a}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.optionButton}
                                        style={optionButtonsHeightWidth}
                                    >
                    Compre agora
                                    </Button>
                                </a>
                            </Box>
                        </Box>
                        <Line />
                    </Box>
                </Grid>
                <Grid xl={6} md={5}>
                    <Box
                        width={matches.sm || matches.md ? '80%' : '80%'}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        ml={2}
                        pt={matches.md ? 6 : 3}
                        pb={matches.md ? 6 : 3}
                    >
                        <Box>
                            <Typography variant="subtitle1" className={classes.label}>
                O que são pontos?
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                className={classes.subtitle}
                                style={optionTitleHeightWidth}
                            >
                Os pontos são a moeda da plataforma Triibo. Com eles você pode
                adquirir produtos e serviços da rede de parceiros e da própria
                Triibo. Os pontos comprados são creditados em sua conta e
                poderão ser utilizados tanto para consumir produtos e serviços
                como pra premiar seus melhores clientes. <br />
                                <br />
                Quando você premia seus clientes com pontos, você os incentiva a
                consumir produtos e serviços na rede Triibo, o que favorece a
                todos os provedores de experiência que participam da plataforma,
                inclusive seu próprio negócio. Quanto mais estabelecimentos
                distribuírem pontos a seus clientes, maior é o volume de
                negócios na plataforma, o que beneficia a todos. <br /> <br />
                Além dos pontos que você pode distribuir a seus clientes, a
                Triibo também oferece a seus usuários pontos que podem ser
                convertidos em produtos e serviços, quando os mesmos interagem
                com a plataforma utilizando o aplicativo Triibo, convidando
                amigos a participar da plataforma ou utilizando promoções
                específicas. É a Triibo ajudando você a ter mais negócios.{' '}
                                <br /> <br />
                Para que você se beneficie da venda de produtos e serviços com
                pontos, não se esqueça de cadastrar seus produtos e serviços em
                nossa plataforma. Se não souber como, entre em contato e teremos
                prazer em ajudar.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container></Grid>
        </Container>
    );
}
