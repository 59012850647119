import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const DataTableQueries = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('priority');
  const [dataTotal, setDataTotal] = useState([]);
  const [dataPesquisa, setDataPesquisa] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pesquisa, setPesquisa] = useState('');

  useEffect(() => {
    getDataListRestApi('queries-redshift')
      .then((dataReceived) => {
        let data = [];
        Object.keys(dataReceived).forEach((item) => {
          data.push(dataReceived[item]);
        });

        setDataTotal(data);
        setDataPesquisa(data);
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  }, []);

  const handleRequestSort = (property) => (event) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    let value = event.target.value.toLowerCase();
    let filteredData = dataTotal.filter(
      (item) =>
        item.title?.toLowerCase().includes(value) ||
        item.subtitle?.toLowerCase().includes(value) ||
        item.keyWordsSet?.toString().toLowerCase().split(',').includes(value)
    );

    setPesquisa(event.target.value);
    setDataPesquisa(filteredData.length > 0 ? filteredData : dataTotal);
  };

  return (
    <Route
      render={({ history }) => (
        <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
          <FormControl fullWidth style={{ marginBottom: '20px', marginTop: '20px' }}>
            <InputLabel htmlFor="adornment-amount">Pesquisar query:</InputLabel>
            <Input
              id="adornment-amount"
              value={pesquisa}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>

          <div>
            <Table stickyHeader aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {props.rows.map((row) => (
                    <TableCell
                      key={row.id}
                      sortDirection={orderBy === row.id ? order : false}
                      style={{ fontWeight: '550' }}
                    >
                      <Tooltip
                        title={
                          row.id === 'thumbnail' ||
                          row.id === 'platform' ||
                          row.id === 'keyWordsSet'
                            ? row.label
                            : 'Ordenar'
                        }
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={
                            row.id === 'thumbnail' ||
                            row.id === 'platform' ||
                            row.id === 'keyWordsSet'
                              ? null
                              : handleRequestSort(row.id)
                          }
                          style={{
                            cursor:
                              row.id === 'thumbnail' ||
                              row.id === 'platform' ||
                              row.id === 'keyWordsSet'
                                ? 'default'
                                : 'pointer',
                          }}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(dataPesquisa, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => (
                    <TableRow
                      key={n.queryId}
                      hover
                      onClick={() =>
                        history.push({
                          pathname: props.pathName + n.queryId,
                          state: n,
                        })
                      }
                      tabIndex={-1}
                    >
                      <TableCell scope="row">{n.name}</TableCell>
                      <TableCell scope="row">{n.query}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={dataPesquisa.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <br />
          <br />
          <br />
        </div>
      )}
    />
  );
};

export default DataTableQueries;

