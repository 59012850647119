export default function reducer(
  state = {
    qrCodeId: null,
    name: null,
    action: null,
    description: null,
    image: null
  },
  action
) {
  switch (action.type) {
    case "GENERATE_QR_CODE_PENDING": {
      return {
        ...state,
        qrCodeId: null,
        name: null,
        action: null,
        description: null,
        image: null
      };
    }

    case "GENERATE_QR_CODE_FULFILLED": {
      return {
        ...state,
        qrCodeId: action.payload.qrCodeId,
        name: action.payload.name,
        action: action.payload.action,
        description: action.payload.description,
        image: action.payload.image
      };
    }

    case "GENERATE_QR_CODE_REJECTED": {
      return {
        ...state,
        qrCodeId: null,
        name: null,
        action: null,
        description: null,
        image: null
      };
    }

    default:
      return state;
  }
}
