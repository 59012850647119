import React from 'react';

import * as D from 'js/components/DesignSystem/styles';

import { BsXLg } from 'react-icons/bs';
import { cloneDeep } from 'lodash';

const TagsDisplay = (props) => {
  //componente TagsDisplay presume que as tags são parte de um array dentro na key de um objeto maior
  //form se refere ao objeto e o setForm o seu setter
  //formKey é a chave onde o array se encontra
  const { form, formKey, setForm } = props;
  return (
    <>
      <D.TriiboTagsField>
        <D.TriiboTagsPlaceholder visibility={form[formKey].length === 0 ? false : true}>
          Digite a tag desejada no campo acima e pressione o botão para adicioná-las
        </D.TriiboTagsPlaceholder>
        {form[formKey].map((body, index) => (
          <>
            <D.UserTags key={index}>
              <D.TagText key={index}>{body}</D.TagText>
              <D.TagDelete
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  let newArr = cloneDeep(form[formKey]);
                  setForm({
                    ...form,
                    [formKey]: [...newArr],
                  });
                }}
              >
                {<BsXLg style={{ color: 'white' }} />}
              </D.TagDelete>
            </D.UserTags>
          </>
        ))}
      </D.TriiboTagsField>
    </>
  );
};

export default TagsDisplay;

