import React from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import ImageDisplay from '../../TriiboComponents/UploadImage/ImageDisplay';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { IoMdAddCircle } from 'react-icons/io';

const ImagesGalleryCuration = ({
  galleryArr,
  setGalleryArr,
  galleryArrExcluded,
  setGalleryArrExcluded,
  setCropModalTwo,
  handleClick,
}) => {
  const handleRemove = (e, index) => {
    e.preventDefault();
    setGalleryArr((prevGalleryArr) => {
      const updatedGalleryArr = [...prevGalleryArr];
      const removedElement = updatedGalleryArr.splice(index, 1)[0];
      if (galleryArrExcluded.length <= 5) {
        setGalleryArrExcluded((prevGalleryArrExcluded) => [
          ...prevGalleryArrExcluded,
          removedElement,
        ]);
      }
      return updatedGalleryArr;
    });
  };

  const handleRemoveExcluded = (e, index) => {
    e.preventDefault();
    setGalleryArrExcluded((prevGalleryArrExcluded) => {
      const updatedGalleryArrExcluded = [...prevGalleryArrExcluded];
      const removedElement = updatedGalleryArrExcluded.splice(index, 1)[0];
      if (galleryArr.length <= 5) {
        setGalleryArr((prevGalleryArr) => [...prevGalleryArr, removedElement]);
      }
      return updatedGalleryArrExcluded;
    });
  };

  // console.log('galleryArr', galleryArr);

  return (
    <>
      <S.Container>
        <S.SectionLabelContainer flexStart={true}>
          <S.SectionLabelBig>Lista de fotos</S.SectionLabelBig>
        </S.SectionLabelContainer>
        <S.GalleryDisplay id="gallery-display">
          <S.SectionLabelContainer flexStart={true}>
            <S.SectionLabelBig>Fotos mantidas/adicionadas</S.SectionLabelBig>
          </S.SectionLabelContainer>
          <S.ImageRow>
            <S.BorderGiver>
              {galleryArr[0] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemove(e, 0);
                  }}
                  visible={true}
                >
                  <MdOutlineRemoveCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArr[0]} />
            </S.BorderGiver>

            <S.BorderGiver>
              {galleryArr[1] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemove(e, 1);
                  }}
                  visible={true}
                >
                  <MdOutlineRemoveCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArr[1]} />
            </S.BorderGiver>

            <S.BorderGiver>
              {galleryArr[2] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemove(e, 2);
                  }}
                  visible={true}
                >
                  <MdOutlineRemoveCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArr[2]} />
            </S.BorderGiver>

            <S.BorderGiver>
              {galleryArr[3] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemove(e, 3);
                  }}
                  visible={true}
                >
                  <MdOutlineRemoveCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArr[3]} />
            </S.BorderGiver>

            <S.BorderGiver>
              {galleryArr[4] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemove(e, 4);
                  }}
                  visible={true}
                >
                  <MdOutlineRemoveCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArr[4]} />
            </S.BorderGiver>
          </S.ImageRow>
        </S.GalleryDisplay>

        <S.PhotoButtons>
          <D.TriiboFilledButton
            disabled={galleryArr.length > 5}
            onClick={(e) => {
              setCropModalTwo(true);
              handleClick(e);
            }}
            blue={true}
          >
            Adicionar fotos
          </D.TriiboFilledButton>
        </S.PhotoButtons>

        <S.GalleryDisplay>
          <S.SectionLabelContainer flexStart={true}>
            <S.SectionLabelBig>Fotos excluídas</S.SectionLabelBig>
          </S.SectionLabelContainer>
          <S.ImageRow>
            <S.BorderGiver>
              {galleryArrExcluded[1] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemoveExcluded(e, 1);
                  }}
                  visible={true}
                >
                  <IoMdAddCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArrExcluded[1]} />
            </S.BorderGiver>
            <S.BorderGiver>
              {galleryArrExcluded[2] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemoveExcluded(e, 2);
                  }}
                  visible={true}
                >
                  <IoMdAddCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArrExcluded[2]} />
            </S.BorderGiver>
            <S.BorderGiver>
              {galleryArrExcluded[3] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemoveExcluded(e, 3);
                  }}
                  visible={true}
                >
                  <IoMdAddCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArrExcluded[3]} />
            </S.BorderGiver>
            <S.BorderGiver>
              {galleryArrExcluded[4] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemoveExcluded(e, 4);
                  }}
                  visible={true}
                >
                  <IoMdAddCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArrExcluded[4]} />
            </S.BorderGiver>
            <S.BorderGiver>
              {galleryArrExcluded[5] && (
                <S.DeleteButton
                  onClick={(e) => {
                    handleRemoveExcluded(e, 5);
                  }}
                  visible={true}
                >
                  <IoMdAddCircle color="#6E3296" />
                </S.DeleteButton>
              )}
              <ImageDisplay srcImg={galleryArrExcluded[5]} />
            </S.BorderGiver>
          </S.ImageRow>
        </S.GalleryDisplay>
      </S.Container>
    </>
  );
};

export default ImagesGalleryCuration;

