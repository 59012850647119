import React from "react";
import { Link } from "react-router-dom";
import { isAdminPage } from "js/library/utils/helpers";

// components
import { Grid, Paper, Typography, Box } from "@mui/material";

// styles
import { useMediumStyles } from "./styles";

export default function PromotionTemplate({ location }) {
  const classes = useMediumStyles();

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {location?.state?.type === "loyalty"
            ? "Promoção de Fidelidade"
            : "Promoção Simples"}
        </Typography>
        <Grid container justify="flex-start" spacing={1}>
          {[0, 1, 2, 3].map((value, index) => (
            <Grid key={value} item>
              <Link
                to={{
                  pathname: isAdminPage()
                    ? "/admin/estabelecimento/" +
                      (location?.state?.dataEstabelecimento?.key
                        ? location?.state?.dataEstabelecimento?.key
                        : location?.state?.dataEstabelecimento?.id) +
                      "/promotions/loyalty/add"
                    : "/estabelecimento/" +
                      (location?.state?.dataEstabelecimento?.key
                        ? location?.state?.dataEstabelecimento?.key
                        : location?.state?.dataEstabelecimento?.id) +
                      "/promotions/loyalty/add",
                  state: {
                    ...location?.state,
                  },
                }}
                style={{
                  textDecoration: "none",
                  cursor: "auto",
                }}
              >
                <Paper
                  className={`${classes.paper} ${
                    index > 0 && classes.linkSpacing
                  }`}
                >
                  <Box className={classes.cardBody}>
                    <Typography variant="subtitle1" className={classes.text}>
                      Título do Template
                    </Typography>
                    <Typography variant="subtitle2">Descrição</Typography>
                  </Box>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
