/*
  COMO UTILIZAR

  Recebe 4 parametros: data, column, checkedById, setCheckedById e perPage.

  data: array com os dados a serem renderizados (precisa adicionar um elemento chamado checkedId nos itens desse data para servir como identificador, seja um número, id, etc),

  exemplo 
  [
    {
      name: "João",
      sobrenome: "Carlos",
      checkedId: +5511948483479
    }
  ]

  column: Array de objetos, onde cada objeto terá um heading (titulo da coluna) e um value (rota para acessar o valor dentro do data)
  
  exemplo
  [
    {heading: "Nome", value: "name"}, 
    {heading: "Cidade", value: "address.city"},
  ]

  checkedById: state com valor inicial new Set() que vai conter os itens selecionados por id (qualquer identificador único. ex: celular, id, etc),

  setCheckedById: setState para atualizar os itens selecionados,

  perPage: Quantos itens vão aparecer por página 
*/

import React, { useEffect, useState } from 'react';

import * as DT from 'js/components/DesignSystem/Table/styles';
import * as D from 'js/components/DesignSystem/styles';

import { BiCircle } from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Checkbox, FormControlLabel, Pagination } from '@mui/material';

import usePagination from '../usePagination';

export default function SelectTable({
  data,
  column,
  checkedById,
  setCheckedById,
  perPage,
  resetValue,
}) {
  const [page, setPage] = useState(1);

  const PER_PAGE = perPage;
  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    resetValue && setCheckedById(new Set()); // resetar lista caso tenha algum valor dentro quando exibir a tabela novamente
  }, []);

  const handleOnChange = (id) => {
    const updatedCheckedById = new Set();

    updatedCheckedById.add(id);

    setCheckedById(updatedCheckedById);
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <DT.TableContainer>
          <DT.Table>
            <thead>
              <DT.TableTR>
                {column.map((item, index) => (
                  <TableHeadItem key={index} item={item} />
                ))}
              </DT.TableTR>
            </thead>
            <tbody>
              {_DATA.currentData().map((item, index) => (
                <TableRow
                  key={index}
                  item={item}
                  column={column}
                  handleOnChange={handleOnChange}
                  checkedById={checkedById}
                  index={index}
                />
              ))}
            </tbody>
          </DT.Table>
        </DT.TableContainer>
      </div>
      <Pagination
        sx={{ margin: '0 auto' }}
        count={count}
        size='large'
        page={page}
        variant='outlined'
        shape='rounded'
        color='secondary'
        onChange={handleChangePage}
      />
    </>
  );
}

const TableHeadItem = ({ item }) => (
  <DT.TableTH>
    <D.TriiboH4>
      <strong>{item.heading}</strong>
    </D.TriiboH4>
  </DT.TableTH>
);

const TableRow = ({ item, column, handleOnChange, checkedById, index }) => (
  <DT.TableTR
    index={index}
    onClick={() => handleOnChange(item.checkedId)}
    style={{
      cursor: 'pointer',
    }}>
    {column.map((columnItem, index) => {
      if (columnItem.value.includes('.')) {
        const itemSplit = columnItem.value.split('.');
        const tableItem = itemSplit.reduce((prev, next, index) => {
          if (index === 0) {
            prev = item[next];
          } else {
            prev = prev[next];
          }
          return prev;
        }, '');

        return (
          <DT.TableTD key={index}>
            {index === 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  paddingLeft: '1rem',
                }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedById.has(item.checkedId)}
                      onChange={() => handleOnChange(item.checkedId)}
                      icon={<BiCircle fontSize='25px' />}
                      checkedIcon={
                        <BsFillCheckCircleFill
                          style={{ marginLeft: '2px' }}
                          fontSize='22px'
                          color='#5A287C'
                        />
                      }
                    />
                  }
                />
                {tableItem}
              </div>
            ) : (
              tableItem
            )}
          </DT.TableTD>
        );
      }

      return (
        <DT.TableTD key={index}>
          {index === 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                paddingLeft: '1rem',
              }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedById.has(item.checkedId)}
                    onChange={() => handleOnChange(item.checkedId)}
                    icon={<BiCircle fontSize='25px' />}
                    checkedIcon={
                      <BsFillCheckCircleFill
                        style={{ marginLeft: '2px' }}
                        fontSize='22px'
                        color='#5A287C'
                      />
                    }
                  />
                }
              />

              {item[`${columnItem.value}`]}
            </div>
          ) : (
            item[`${columnItem.value}`]
          )}
        </DT.TableTD>
      );
    })}
  </DT.TableTR>
);
