import React, { useState } from "react";

import { Button, CircularProgress, Modal } from "@mui/material";

import * as S from "../styles";
import * as D from "../../../DesignSystem/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { getAddPhone } from "js/library/utils/API/SearchUser/apiAddPhone";
import { toast } from "react-toastify";
import { LockClock } from "@mui/icons-material";
import { purple } from "@mui/material/colors";

export default function GeneratePassword({
  cellPhone,
  isModalGenerate,
  handleCloseModal,
}) {
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copiar");
  const [disabled, setDisabled] = useState(true);
  const [password, setPassword] = useState("");
  const [expirationDate, setExpirationDate] = useState();
  const [loading, setLoading] = useState(false);

  const copyPassword = () => {
    if (typeof password === "object") {
      const results = cellPhone.substring(8);
      navigator.clipboard.writeText(results);
      setCopySuccess("Copiado");
    } else {
      navigator.clipboard.writeText(temporaryPassword);
      setCopySuccess("Copiado");
    }
  };

  const currentDate = new Date();
  const futureDate = new Date(
    currentDate.setMinutes(currentDate.getMinutes() + 5)
  );

  const newPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (cellPhone === "") {
      setTemporaryPassword(
        "Não é possível gerar um código para um usuário sem telefone !"
      );
      setDisabled(false);
      setLoading(false);
    }

    const obj = {
      cellphone: cellPhone,
      isTemporary: true,
      expirationDate: Number(futureDate),
    };

    getAddPhone(obj)
      .then((value) => {
        setPassword(value);
        if (!value.result.expirationDate) {
          setTemporaryPassword(`Celular ${cellPhone} é admin`);
          setDisabled(false);
          setLoading(false);
        } else {
          setTemporaryPassword(value.result.code);
          setExpirationDate(value.result.expirationDate);
          setDisabled(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <Modal
      onClose={() => handleCloseModal("closeModalGenerate")}
      open={isModalGenerate}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.ModalBox>
        <S.GenerateModalBox>
          <div className="generateHeader">
            <div className="generateTitle">
              <LockClock sx={{ color: purple[800], fontSize: 60 }} />
              <D.TriiboH2>Código Temporário</D.TriiboH2>
            </div>
          </div>
          <br />
          <S.ContainerPass>
            <Button
              variant="outlined"
              disabled={!disabled}
              onClick={(e) => newPassword(e)}
            >
              Gerar Código
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "center",
                padding: "11px",
              }}
            >
              {loading && <CircularProgress size="20px" />}
              <D.TriiboH5>{temporaryPassword}</D.TriiboH5>
            </div>
            <Button
              variant="outlined"
              disabled={temporaryPassword === ""}
              startIcon={<FileCopyIcon />}
              onClick={copyPassword}
            >
              {copySuccess}
            </Button>
          </S.ContainerPass>
          <br />
          <S.ContainerPass>
            <Button
              variant="contained"
              style={{
                margin: "1rem 0",
                width: "135px",
                background: "#DBDBDB",
                color: "#555555",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              onClick={() => handleCloseModal("closeModalGenerate")}
            >
              Voltar
            </Button>
            {expirationDate ? (
              <D.TriiboH5>{`Código válido até: ${expirationDate}`}</D.TriiboH5>
            ) : (
              <></>
            )}
          </S.ContainerPass>
        </S.GenerateModalBox>
      </S.ModalBox>
    </Modal>
  );
}
