import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;

  @media (max-width: 1220px) {
    padding: 0 2rem;
  }
`;

export const Header = styled.header`
  padding-top: 30px;
  text-align: center;
`;

export const SearchBox = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

export const AddNotificationButton = styled.button`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 200px;
  background-color: #6e32960c;
  color: #6e3296;
  border: 1px dashed #6e3296;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;

  span {
    font-size: 24px;
  }

  &:hover {
    background-color: #6e329633;
  }
`;

export const StatusButton = styled.button`
  height: 100%;
  width: auto;
  background: #328796;
  border: none;
  color: #fff;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 15px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;

  &:hover {
    background-color: #328796cc;
  }
`;
