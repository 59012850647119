import React from 'react';

import { readTxt, removeDuplicatesFromArray } from 'js/library/utils/helpers';

import * as S from './styles';

import { IoIosAdd } from 'react-icons/io';

function removeStringsNotEqualLength(arr, length) {
  return arr.filter((str) => str.length === length);
}

function removeDuplicatesByProperty(arr1, arr2, key) {
  const valuesSet = new Set(arr1.map((obj) => obj[key]));

  return arr2.filter((obj) => !valuesSet.has(obj[key])).concat(arr1);
}

const BatchPost = ({ usersArr, setUsersArr }) => {
  async function handleSubmit(e) {
    let rawList = await readTxt(e.target.files[0]);

    rawList = removeDuplicatesFromArray(rawList);
    rawList = removeStringsNotEqualLength(rawList, 11);

    const extraUsers = rawList.map((user, index) => {
      return { cpf: user };
    });

    const finalArray = removeDuplicatesByProperty(usersArr, extraUsers, 'cpf');

    setUsersArr([...finalArray]);
  }

  return (
    <S.BatchContainer>
      <S.InputContainer></S.InputContainer>
      <label htmlFor="inputFileRef">
        <IoIosAdd className="icon" /> Adicionar arquivo .txt
      </label>
      <input
        id="inputFileRef"
        type="file"
        accept=".txt"
        style={{ display: 'none' }}
        onChange={(e) => {
          handleSubmit(e).then(() => (e.target.value = null));
        }}
      />
    </S.BatchContainer>
  );
};

export default BatchPost;

