import React from "react";

import * as S from "./styles";
import * as D from "js/components/DesignSystem/styles";

import { cloneDeep } from "lodash";

const PostPointsForm = (props) => {
  const { form, setForm } = props;

  function handleChange(e, key) {
    e.preventDefault();
    let cloneForm = cloneDeep(form);

    cloneForm[key] = e.target.value;
    setForm(cloneForm);
  }

  return (
    <S.FormContainer>
      <S.FormField>
        <D.TriiboH6 type="tel">Quantidade de pontos*</D.TriiboH6>
        <D.TriiboTelField
          rows={"1"}
          value={form.points}
          onChange={(e) => {
            handleChange(e, "points");
          }}
        />
      </S.FormField>
      <S.FormField>
        <D.TriiboH6>Nome interno*</D.TriiboH6>
        <D.TriiboTextField
          rows={"1"}
          value={form.internalName}
          onChange={(e) => {
            handleChange(e, "productId");
          }}
        />
      </S.FormField>
      <S.FormField>
        <D.TriiboH6>Descrição no extrato*</D.TriiboH6>
        <D.TriiboTextField
          rows={"1"}
          form={form.description}
          onChange={(e) => {
            handleChange(e, "campaignDescription");
          }}
        />
      </S.FormField>
    </S.FormContainer>
  );
};

export default PostPointsForm;
