import React, { useState, useEffect } from 'react';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { getOrgById } from 'js/library/utils/API/Org/apiGetOrgById';
import { useParams } from 'react-router';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import { cloneDeep } from 'lodash';

const Orgs = ({ form, setForm, options, setOptions }) => {
  const [current, setCurrent] = useState('');

  const [previousValue, setPreviousValue] = useState('');

  const { id } = useParams();

  useEffect(() => {
    if (form.sender.id) {
      const org = options?.find((org) => org.orgID === form.sender.id);
      setPreviousValue(org);
    }
  }, [form.sender.id, options]);

  useEffect(() => {
    if (form.sender.type === 'org') {
      getSeuClube(id).then((res) => {
        if (res.orgs) {
          Promise.all(res.orgs?.map((orgID) => getOrgById(orgID))).then((orgDetailsArray) => {
            const newArr = orgDetailsArray?.map((orgDetails) => orgDetails.orgInfo);

            setOptions(newArr);
          });
        }
      });
    }
  }, [form.sender.type, id, setOptions]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    setCurrent(selectedValue);

    const selectedOrg = options?.find((org) => {
      return org.name === selectedValue;
    });

    const newForm = cloneDeep(form);

    setForm({
      ...newForm,
      sender: {
        ...(newForm.id
          ? newForm.sender
          : {
              id: selectedOrg.orgID,
              name: selectedOrg.name,
              logo: selectedOrg.logoImage,
              type: newForm.sender.type,
            }),
      },
    });
  };

  return (
    <S.Container>
      <D.TriiboH4>
        <b>Assinante da Postagem</b>
      </D.TriiboH4>
      <div style={{ width: '100%' }}>
        {options.length > 0 ? (
          <Select
            fullWidth
            value={current ? current : previousValue ? previousValue.name : 'Selecionar'}
            onChange={handleSelectChange}
          >
            {options?.map((org) => (
              <MenuItem key={org.orgId} value={org.name}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <CircularProgress />
        )}
      </div>
    </S.Container>
  );
};

export default Orgs;

