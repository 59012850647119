import React from 'react';

import * as S from './styles';

export default function Input({ label, value, handleChange, error, ...props }) {
  return (
    <S.Container>
      <label>{label}</label>
      <S.Input hasError={error} value={value} onChange={handleChange} {...props} />
    </S.Container>
  );
}
