import dispatcher from "js/core/dispatcher";
import { voucherSend_v1 } from "js/library/utils/API/apiVoucherSend";

export const voucherSendAction = (dispatch, templateId, batchId, emailFile, consumed, callback=null ) => {
    batchId =  batchId === 'AUTO' ? null : batchId;

    readTxt(emailFile).then((dataReceived) => {
        emailFile = dataReceived;
        
        return dispatcher(dispatch, "VOUCHER_SEND", voucherSend_v1(templateId, batchId, emailFile, consumed, callback) );

    }).catch((error) => {
        // console.log('Erro na data:', error);
    });

    function readTxt(txtFile) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let dataArray = [];

            reader.onload = function () {
                const data = this.result.split(/\r\n|\r|\n/);
                for (let index = 0; index < data.length; index++) {
                    if (data[index] !== "" && data[index] !== '\n' && data[index] !== '\r'&& data[index] !== '\r\n') {
                        dataArray.push(data[index].replace(/[.]/gi, ","))
                    }
                }

                resolve(dataArray);
            };
            reader.readAsText(txtFile);
        });
    }

}