import styled from 'styled-components';

export const Button = styled.button`
  width: ${({ width }) => width || '300px'};
  height: ${({ height }) => height || '42px'};
  background: ${({ bgColor }) => bgColor || '#6e32960c'};
  border: 1px dashed ${({ borderCollor }) => borderCollor || '#6E3296'};
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.4rem;
  color: ${({ color }) => color || '#6E3296'};
  cursor: pointer;

  :hover {
    background-color: #6e329633;
  }
`;
