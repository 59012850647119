import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export default function createStore(uId, storeId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (storeId !== null) {
      authToken(uId)
        .then((token) => {
          axios
            .post(
              `${cfac22('API_HOST_V2')}/stores/pending`,
              { payload },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            )
            .then((result) => {
              // console.log('RESULTADO ===>', result);
              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.storeInfo.id = storeId;
              resolve(result.data.storeInfo);
            })
            .catch((error) => {
              console.log('ERRO STORE --->', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

