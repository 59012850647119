import { apiGenerator } from "js/library/utils/API/apiGenerator";
import dispatcher from "js/core/dispatcher";

export const updateStoreGenerationAction = (dispatch, id, templateId, voucherAmmount, expiration, batchName) => {

    //convertendo a data para milisegundos  
    expiration = expiration.split('T');
    let date = expiration[0].split('-');
    let hour = expiration[1].split(':');
    expiration = new Date(parseInt(date[0], 10), parseInt(date[1] - 1, 10), parseInt(date[2], 10), parseInt(hour[0], 10), parseInt(hour[1], 10)).getTime();

    return dispatcher(dispatch, "GENERATION_INFORMATION", apiGenerator(templateId, voucherAmmount, expiration, batchName)); 
}