import styled from "styled-components";
import { Link } from "react-router-dom";

export const EditButton = styled(Link)`
  max-width: 50px;
  width: 100%;
  background: #6e3296;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: opacity(0.9);
  }
`;
