import styled from "styled-components/macro";
import * as D from "js/components/DesignSystem/styles";

export const TagsAddContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  @media (min-width: 900px) {
    gap: 1.8rem;
  }
`;

export const TagsAddButton = styled.button`
  border: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: white;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) top/100% 800%;
  background-color: ${D.triiboBlue};
  padding: 0.4rem;
  border-radius: 10px;
  min-width: 7rem;
  transition: 0.35 ease-in-out;
  cursor: pointer;

  &:hover {
    background-position: bottom;
  }
`;

export const SearchTags = styled.select`
  background-color: white;
  border: 1px solid ${D.triiboGray};
  width: 18rem;
  height: 3rem;
  border-radius: 10px;
  color: ${D.triiboBlack};
  padding: 0 1rem;
  font-weight: 600;
  /* -webkit-appearance: none; */
`;
