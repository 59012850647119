import React from 'react';
import * as S from './styles';
import { Typography } from '@mui/material';
import Carousel from './Carousel';
// import IconTip from "./IconTip";

export default function MyBusiness() {
  return (
    <S.Container>
      <S.Header>
        <Typography variant="h1">Bem vindo de volta!</Typography>
        <Typography variant="h2">
          Comece a potencializar seu negócio aqui!
        </Typography>
        <Typography variant="h3">
          A interface Triibo possibilita que negócios de todos os tamanhos e
          segmentos cresçam com sucesso!
        </Typography>
      </S.Header>
      <Carousel />
      {/* 
      <S.SecondContainer>
        <S.BannerRows>
          <S.PlanBanner
            href="https://triibo.com.br/triibo-negocios/forma-de-pagamento/"
            target="new"
          >
            <S.BannerText>
              <S.TextPlan>PLANO</S.TextPlan> <S.TextBasic>BÁSICO</S.TextBasic>{" "}
              <S.TextGreen>
                <S.TextFor>A PARTIR DE</S.TextFor>{" "}
                <S.TextPriceRow>
                  <S.TextPrice>R$ 59,90 </S.TextPrice>{" "}
                  <S.TextMonth>MÊS</S.TextMonth>
                </S.TextPriceRow>
              </S.TextGreen>
            </S.BannerText>
          </S.PlanBanner>
          <S.GetPlan>
            <S.GetPlanList>
              <S.GetPlanEntry>Cadastre mais de 50 clientes</S.GetPlanEntry>
              <S.GetPlanEntry>
                Cadastre seus colaboradores em sua equipe
              </S.GetPlanEntry>
              <S.GetPlanEntry>
                Suas promoções participam dos sorteios Triibo
              </S.GetPlanEntry>
            </S.GetPlanList>
            <S.PlanButton
              href="https://triibo.com.br/triibo-negocios/forma-de-pagamento/"
              target="new"
            >
              Contrate um plano
            </S.PlanButton>
          </S.GetPlan>
        </S.BannerRows>
        <S.BannerRows>
          <S.WeeklyTip>
            <S.PurpleText>
              Dica da Semana{" "}
              <IconTip
                width={document.body.clientWidth < 600 ? "1.4rem" : "2.6rem"}
              />
            </S.PurpleText>
            <S.TipPara>
              Para você que é um pequeno empreendedor ou que simplesmente
              gosta de saber sobre negócios e quer ficar por dentro do
              assunto, aqui você encontra conteúdos frequentemente.
            </S.TipPara>
            <S.TipButton href="https://triibo.com.br/blog/" target="_blank">
              Veja o conteúdo
            </S.TipButton>
          </S.WeeklyTip>
          <S.IllustrationBanner />
        </S.BannerRows>
      </S.SecondContainer> */}
    </S.Container>
  );
}
