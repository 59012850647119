import { findStoreByProximity } from 'js/library/utils/API/CampaignLandinPage/apiFindStoreByProximity';
import { getEstablishmentInfoInBatch } from 'js/library/utils/API/Establishment/apiGetEstablishmentInfoInBatch';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { getStoresByIds } from 'js/library/utils/API/Store/apiGetStoresById';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { separateBase64String } from 'js/library/utils/helpers';

const getListEstablishments = async (search, from, size) => {
  const response = await apiListEstablishment(search, from, size);
  return response;
};

const getListPromotions = async (title, isPublished, from, size) => {
  const data = await findStoreByProximity(title, isPublished, from, size);
  if (data.success) {
    return data.response;
  }
};

const getStoreNameById = async (storesIdsList) => {
  const data = await getStoresByIds(storesIdsList);
  if (data.success) {
    return data.response;
  }
};

const getEstablishmentsNameById = async (IdsList) => {
  const data = await getEstablishmentInfoInBatch(IdsList);
  if (data.success) {
    return data.response;
  }
};

const uploadPrimaryImg = async (image, clubeId) => {
  const firstPart = image.split('/');
  const [typeImg] = firstPart[1].split(';');
  const newImage = separateBase64String(image);

  const response = await uploadFiles('campaignLp', {
    mimeType: newImage.mimeType,
    fileName: `bannerModule_${typeImg}_${clubeId}`,
    buffer: newImage.buffer,
  });

  if (response) return response;
};

const highlightsTypes = [
  { option: 'Estabelecimento', type: 'establishment' },
  { option: 'Promoção', type: 'promotion' },
];

export default {
  getListEstablishments,
  uploadPrimaryImg,
  getStoreNameById,
  getEstablishmentsNameById,
  getListPromotions,
  highlightsTypes,
};
