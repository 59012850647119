import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import { Typography } from '@mui/material';
import CardCarousel from './CardCarousel';

import * as S from './styles';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import cfac22 from 'js/library/utils/cfac22';

export default function Carousel() {
  const [establishmentList, setEstablishmentList] = useState([]);
  const [establishmentListCarousel, setEstablishmentListCarousel] = useState([]);
  let state = useSelector((state) => state.authModel);
  const customeSlider = React.createRef();
  const [displayArrow, setDisplayArrow] = useState(false);

  useEffect(() => {
    if (state.establishmentList !== null || state.establishmentList !== undefined) {
      setEstablishmentList(state.establishmentList);
    }
  }, []);

  useEffect(() => {
    const newEstablishmentList = [
      ...establishmentList,
      // { addBusiness: false } //comentado para esconder link de adicionar negócio
    ];

    newEstablishmentList.map((item) => {
      if (item.addBusiness === undefined || item.addBusiness === null) {
        item.addBusiness = false;
      }
    });

    setEstablishmentListCarousel(newEstablishmentList);

    if (newEstablishmentList.length > 3) {
      setDisplayArrow(true);
    }
  }, [establishmentList]);

  useEffect(() => {
    // console.log(establishmentListCarousel);
  }, [establishmentListCarousel]);

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const settings = {
    className: 'sliderCustom',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      establishmentListCarousel.length > 0
        ? establishmentListCarousel.length >= 3
          ? 3
          : establishmentListCarousel.length
        : 1,
    slidesToScroll: 1,
  };

  return (
    <S.Carousel>
      <Typography variant="h3">Qual negócio você deseja usar?</Typography>
      {establishmentListCarousel.length > 0 && (
        <div className="sliderBox">
          <S.ArrowButton
            className="prevArrow"
            onClick={() => gotoPrev()}
            displayArrow={displayArrow}
          >
            <MdOutlineArrowBackIos className="icon" />
          </S.ArrowButton>
          <Slider {...settings} ref={customeSlider}>
            {establishmentListCarousel.map((card, index) => (
              <CardCarousel
                key={index}
                title={card.nome}
                img={
                  card.fotoThumb
                    ? cfac22('STORAGE_URL') + 'estabelecimento%2F' + card.fotoThumb + '?alt=media'
                    : ''
                }
                addBusiness={card.addBusiness}
                link={card}
              />
            ))}
          </Slider>
          <S.ArrowButton
            className="nextArrow"
            onClick={() => gotoNext()}
            displayArrow={displayArrow}
          >
            <MdOutlineArrowForwardIos className="icon" />
          </S.ArrowButton>
        </div>
      )}
    </S.Carousel>
  );
}

