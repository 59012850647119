//ARQUIVO REFERENTE AO STYLED-COMPONENTS, TOMAR CUIDADO PARA NÃO ENTRAR EM CONFLITO COM O MUI
import styled from 'styled-components/macro';
import InputMask from 'react-input-mask';

import * as D from 'js/components/DesignSystem/styles';
import ReactInputMask from 'react-input-mask';

export const RegisterUsefullLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.4rem;
`;

export const AreaHeader = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${D.triiboPurple};
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 1.334;
`;

export const AreaSubtitle = styled.span`
  color: ${D.triiboGray};
`;

export const GoBack = styled.span`
  border: none;
  cursor: pointer;
`;

export const LinkPlacerSpan = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

export const DropdownZone = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  right: -13rem;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px #00000029;
  width: 14rem;
  min-height: 20rem;
  background: white;
  border-radius: 10px;
  cursor: default;
`;

export const DropdownButton = styled.button`
  border: 0;
  min-width: 3.4rem;
  min-height: 3.4rem;
  border-radius: 50%;
  background: ${D.triiboPurple};
  cursor: pointer;

  @media (min-width: 600px) {
    min-width: 5rem;
    min-height: 5rem;
  }
`;

export const LinkFieldGroup = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.6rem;
`;

export const LinkFieldLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  color: ${D.triiboBlack};
  font-size: 0.8rem;
  width: 100%;
  padding-left: 0.8rem;
`;

export const LinkFieldContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 60rem;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: red;
  border: 0;
  cursor: pointer;
`;

export const LinkSpanLabel = styled.label`
  text-align: center;
  font-weight: 600;
  color: ${D.triiboBlack};

  @media (min-width: 900px) {
    padding: 0 10rem;
  }
`;

export const LinkSpan = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  color: ${D.triiboGray};
  cursor: pointer;
`;

export const EstablishmentLinkHolder = styled.span`
  padding: 0.3rem;
  border: 1px solid ${D.triiboGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;

  @media (min-width: 600px) {
    max-width: 35rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
  margin-top: 1.6rem;

  @media (min-width: 900px) {
    flex-direction: row;
    margin-top: 3.8rem;
  }
`;

export const ButtonGrid = styled.span`
  display: grid;
  grid: auto / auto auto auto;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const TableCellContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const DeleteButtonContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DeleteUserButton = styled.button`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: red;
  border-radius: 4px;
  margin-left: 1rem;
  border: 0;
  cursor: pointer;
`;

export const RegisterUnitForm = styled.form`
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: scroll;
`;

export const DeleteUserModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
`;

export const DeleteUserModalButtonContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Adornment = styled.div`
  margin-left: 1rem;
  color: #383838;
`;

export const SelectNation = styled.select`
  border: none;
  color: #383838d6;
  cursor: pointer;
  font-family: 'NotoColorEmojiLimited', 'Source Sans Pro', sans-serif;
  font-size: 17px;
  height: 90%;
  outline: none;
`;

export const NationMask = styled(ReactInputMask)`
  align-content: center;
  border: none;
  color: #383838d6;
  flex-direction: row;
  font-family: 'NotoColorEmojiLimited', 'Source Sans Pro', sans-serif;
  font-size: 17px;
  height: 90%;
  justify-content: flex-end;
  outline: none;

  @media screen and (max-width: 752px) {
    width: 8rem;
  }
`;

export const ModalMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const CellphoneArea = styled.div`
  border: 1.5px solid #aaa;
  border-radius: 5px;
  height: 100%;
  max-width: 100%;

  @media screen and (max-width: 600px) {
    height: 3.5rem;
    width: 8rem;
  }
`;

export const BoxSearchInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  margin-bottom: 6rem;

  height: 42px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;

  .searchIcon {
    button {
      background: none;
      border-left: 1px solid #ccc;
      border-radius: 0;
      border-right: none;
      border-top: none;
      border-bottom: none;
      padding: 0 1rem;

      img {
        width: 24px;
      }
    }
  }
`;

export const InputSearch = styled(InputMask)`
  flex: 1;
  padding-left: 1rem;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #383838d6;
  border-radius: 10px;
`;

export const Container = styled.div`
  padding: 0rem 1rem 4rem 1rem;
  @media (min-width: 1020px) {
    max-width: 1000px;
    margin: 0px auto 150px auto;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
`;

export const ModalTextArea = styled(D.TriiboTextField)`
  min-height: 2.6rem;
`;

