import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ImagePreview = styled.div`
  background: #f2f2f2;
  width: 100%;
  max-height: 369px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5rem 0; */
  margin-bottom: 2.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    margin: 5rem 0;
  }
`;

export const Interactions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
`;

export const BuyButton = styled.div`
  background: #6e3296;
  padding: 0.3rem 1rem;
  border-radius: 10px;

  h5 {
    color: #fff;
    font-weight: 600;
  }
`;

export const MessageAndTitlePreview = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .title {
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .boxMessage {
    max-height: 100px;
    height: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    h4 {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;
