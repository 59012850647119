import axios from 'axios';

import authTokenWithScUIDNull from '../authTokenWithScUIDNull';
import cfac22 from '../../cfac22';

export default async function getUserSorteSaude(CPF) {
  const token = await authTokenWithScUIDNull();

  const config = {
    method: 'post',
    url: `${cfac22('API_HOST_V2')}/users/getUserBrasilCap/${CPF}`,
    headers: { authorization: `Bearer ${token}` },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log('>>>> ERROR SorteSaude >>>>>', error);
        if (error.response && error.response.status === 422) {
          resolve(error);
        } else return reject(error);
      });
  });
}
