import React from "react";

import searchIcon from "styles/assets/SearchUser/search.svg";
import * as S from "./styles";

export default function InputSearch({
  value,
  onChange,
  disabled,
  placeholder,
}) {
  return (
    <S.InputBox>
      <S.Input
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      <S.SearchIconButton type="submit">
        <img src={searchIcon} alt="" />
      </S.SearchIconButton>
    </S.InputBox>
  );
}
