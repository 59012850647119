import React from 'react';

import logoTriiboImg from '../../../../../../styles/assets/communications/logo_triibo.svg';
import { IoIosArrowDown } from 'react-icons/io';

import { PreviewHeader } from '../styles';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

export default function NotificationPreview({ formCommunication }) {
  return (
    <S.Container>
      <PreviewHeader>
        <img alt="logo-triibo" src={logoTriiboImg} />
        <D.TriiboH5>Triibo</D.TriiboH5>
      </PreviewHeader>
      <S.Cards>
        <S.DefaultCard>
          <div className="div-1"></div>
          <div className="div-2">
            <div></div>
            <div></div>
          </div>
        </S.DefaultCard>
        <S.NotificationCard>
          <S.NotificationCardHeader>
            <img alt="logo-triibo" src={logoTriiboImg} />
            <D.TriiboH5>
              <b>{formCommunication.title ? formCommunication.title : 'Título da mensagem'}</b>
            </D.TriiboH5>
            <D.TriiboH6>2h</D.TriiboH6>
          </S.NotificationCardHeader>

          <S.NotificationCardContent>
            <div className="boxMessage">
              <D.TriiboH4>
                {formCommunication.message ? formCommunication.message : 'Mensagem'}
              </D.TriiboH4>
            </div>
            <IoIosArrowDown color="#707070" size={14} />
          </S.NotificationCardContent>
        </S.NotificationCard>
        <S.DefaultCard>
          <div className="div-1"></div>
          <div className="div-2">
            <div></div>
            <div></div>
          </div>
        </S.DefaultCard>
      </S.Cards>
    </S.Container>
  );
}

