import CryptoJS from 'crypto-js';
import md5 from 'js/library/utils/md5.js';
import firebase from 'firebase/app';
import { store } from 'js/core/configureStore';

import axios from 'axios';

import bell from 'styles/assets/estabelecimentos/interestingLinks/bell.svg';
import cutlery from 'styles/assets/estabelecimentos/interestingLinks/cutlery.svg';
import menu from 'styles/assets/estabelecimentos/interestingLinks/menu.svg';
import burgerAndSoda from 'styles/assets/estabelecimentos/interestingLinks/burgerAndSoda.svg';

import blog from 'styles/assets/estabelecimentos/interestingLinks/blog.png';
import facebook from 'styles/assets/estabelecimentos/interestingLinks/facebook.png';
import instagram from 'styles/assets/estabelecimentos/interestingLinks/instagram.png';
import landing_page from 'styles/assets/estabelecimentos/interestingLinks/landing_page.png';
import loja_virtual from 'styles/assets/estabelecimentos/interestingLinks/loja_virtual.png';
import site from 'styles/assets/estabelecimentos/interestingLinks/site.png';
import tiktok from 'styles/assets/estabelecimentos/interestingLinks/tiktok.png';
import whats from 'styles/assets/estabelecimentos/interestingLinks/whats.png';
import youtube from 'styles/assets/estabelecimentos/interestingLinks/youtube.png';
import twitter from 'styles/assets/estabelecimentos/interestingLinks/twitter-logo.png';
import cfac22 from './cfac22';

export const establishmentIcons = [
  { icon: bell, name: 'bell.svg' },
  { icon: cutlery, name: 'cutlery.svg' },
  { icon: menu, name: 'menu.svg' },
  { icon: burgerAndSoda, name: 'burgerAndSoda.svg' },
  { icon: blog, name: 'blog.png' },
  { icon: facebook, name: 'facebook.png' },
  { icon: instagram, name: 'instagram.png' },
  { icon: landing_page, name: 'landing_page.png' },
  { icon: loja_virtual, name: 'loja_virtual.png' },
  { icon: site, name: 'site.png' },
  { icon: tiktok, name: 'tiktok.png' },
  { icon: whats, name: 'whats.png' },
  { icon: youtube, name: 'youtube.png' },
  { icon: twitter, name: 'twitter.png' },
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

//VALIDA DATA REGISTRADA, APOS O TIMER DEFINIDO EM MINUTOS RETORNA INVALIDA
export function validateRegistrationDate(registrationDate, timer) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;
    const date = Math.floor((mkTimeCurrent - mkTime) / 60);
    if (date > timer || registrationDate === null) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
}

export function sendUidMatomo(uid) {
  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['setDocumentTitle', window.location.host + '/' + document.title]);
  _paq.push(['setCookieDomain', '*.' + window.location.host]);
  _paq.push(['setDomains', ['*.' + window.location.host]]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = 'https://matomo.triibo.com.br/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '11']);
    _paq.push(['setUserId', uid]);
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
}

export function colorText(hex) {
  hex = hex.replace('#', '');

  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  var a = parseInt(hex.substring(6, 8), 16);

  // Calculate the luminance using the YIQ formula
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Determine if the text color should be white or black based on luminance
  return yiq >= 148 || a < 65 ? '#000000' : '#ffffff';
}

export function rgbaToHex(rgba) {
  rgba = rgba.replace(/\s+/g, '').toLowerCase();

  const rgbaPattern = /^rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3}),?(0?\.\d+|1|0)?\)$/;
  const result = rgbaPattern.exec(rgba);

  if (!result) {
    throw new Error('Formato RGBA inválido');
  }

  let r = parseInt(result[1]);
  let g = parseInt(result[2]);
  let b = parseInt(result[3]);
  let a = parseFloat(result[4]);

  r = r.toString(16).padStart(2, '0');
  g = g.toString(16).padStart(2, '0');
  b = b.toString(16).padStart(2, '0');

  if (!isNaN(a)) {
    a = Math.round(a * 255)
      .toString(16)
      .padStart(2, '0');
  } else {
    a = '';
  }

  return `#${r}${g}${b}${a}`;
}

export function hexToRgba(hex, alpha = 1) {
  hex = hex.replace(/^#/, '');

  if (!/^([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(hex)) {
    throw new Error('Cor hexadecimal inválida');
  }

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

//CONVERTER DATA DOS DADOS DOS GRAFICOS
export function parseDate(isoDate) {
  const inicialDate = isoDate.split('T');
  const dateSplit = inicialDate[0];
  const date = dateSplit.split('-');
  const day = date[2];
  const month = date[1];
  return `${day}/${month}`;
}

//CONVERTE MILISEGUNDOS PARA DATA
export function convertToDate(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();
  const hour =
    converteddDate.getHours() < 10 ? '0' + converteddDate.getHours() : converteddDate.getHours();
  const minute =
    converteddDate.getMinutes() < 10
      ? '0' + converteddDate.getMinutes()
      : converteddDate.getMinutes();

  converteddDate = year + '-' + month + '-' + day + 'T' + hour + ':' + minute;

  return converteddDate;
}

export function convertToDateEdit(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();

  converteddDate = year + '-' + month + '-' + day;

  return converteddDate;
}

export const convertDate = (value, type) => {
  const oneDayInMilliseconds = 86400000;
  const initDate = 75540000;
  const finalDate = 10790000;
  let timeStamp = new Date(value).getTime() + oneDayInMilliseconds;

  switch (type) {
    case 'start':
      return (timeStamp = new Date(value).getTime() + oneDayInMilliseconds - initDate);
    case 'final':
      return (timeStamp = new Date(value).getTime() + oneDayInMilliseconds + finalDate);
    default:
      return timeStamp;
  }
};

export function msToTime(duration) {
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  let days = Math.floor(duration / (1000 * 60 * 60 * 24));

  days = days < 10 ? '0' + days : days;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return { days, hours, minutes };
}

//CONVERTE DATA PARA MILISEGUNDOS
export function convertToMillis(date) {
  let converteddDate = date.split('T');

  let newDate = converteddDate[0].split('-');
  let hour = converteddDate[1].split(':');

  converteddDate = new Date(
    parseInt(newDate[0], 10),
    parseInt(newDate[1] - 1, 10),
    parseInt(newDate[2], 10),
    parseInt(hour[0], 10),
    parseInt(hour[1], 10)
  ).getTime();

  return converteddDate;
}

//CONVERTE MILISEGUNDOS PARA DATA DD/MM/AAAA
export function convertToDateDefault(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();

  converteddDate = day + '/' + month + '/' + year;

  return converteddDate;
}

//transforma um arquivo .txt em um array
export function readTxt(txtFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let dataArray = [];

    reader.onload = function () {
      const data = this.result.split('\n');
      for (let index = 0; index < data.length; index++) {
        if (
          data[index] !== '' &&
          data[index] !== '\n' &&
          data[index] !== '\r' &&
          data[index] !== '\r\n'
        ) {
          dataArray.push(data[index].trim().replace(/[.]/gi, ','));
        }
      }

      resolve(dataArray);
    };
    reader.readAsText(txtFile);
  });
}

export function encrypt(msg, key) {
  if (!msg) return null;

  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };

  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };

  let json = CryptoJS.AES.encrypt(msg, myEncrypt.pass, options);

  return json.ciphertext.toString(CryptoJS.enc.Base64);
}

export function decrypt(msg, key) {
  if (!msg) return null;

  msg = decodeURIComponent(msg);

  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };

  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };

  let json = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(msg),
    },
    myEncrypt.pass,
    options
  );
  return json.toString(CryptoJS.enc.Utf8);
}

export function hashTriibo1(apiToken, passPhrase, channelToken) {
  let challenge1 = passPhrase + apiToken; //Passo 1: String a apiToken + passPhrase
  challenge1 = md5.hexMD5(challenge1); //Passo 2: challenge1 ou challenge intermediário

  let challenge2 = challenge1 + channelToken; //Passo 3: String a challenge1 + channelToken - sendo este valor o do ITEM B ou ex: keychannelclubmapfre (é a sua Private KEY)
  challenge2 = md5.hexMD5(challenge2);

  return challenge2;
}

export function cellPhoneMask(value) {
  value = value.replace(/\D/g, '');

  let cellPhone = '';

  if (value.length === 0) {
    cellPhone = '';
  } else if (value.length >= 1 && value.length <= 2) {
    cellPhone = '(' + value.substr(0, 2);
  } else if (value.length > 2 && value.length <= 7) {
    cellPhone = '(' + value.substr(0, 2) + ') ' + value.substr(2, 5);
  } else {
    cellPhone = '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 4);
  }

  return cellPhone;
}

export function cellPhoneMask2(value) {
  value = value.replace(/\D/g, '');

  let cellPhone = '';

  if (value.length === 0 || (value.length === 2 && value === '55')) {
    cellPhone = '';
  } else if (value.length === 1) {
    cellPhone = '+55 (' + value.substr(0, 1);
  } else if (value.length > 1 && value.length <= 4) {
    cellPhone = '+55 (' + value.substr(2, 2);
  } else if (value.length > 4 && value.length <= 9) {
    cellPhone = '+55 (' + value.substr(2, 2) + ') ' + value.substr(4, 5);
  } else {
    cellPhone = '+55 (' + value.substr(2, 2) + ') ' + value.substr(4, 5) + '-' + value.substr(9, 4);
  }

  return cellPhone;
}

export function cellPhoneMaskwithout55(value) {
  value = value.replace(/\D/g, '');
  let cellPhone = '';

  if (value.length > 2) {
    cellPhone = `(${value.substr(0, 2)}) `;
  } else {
    cellPhone = `(${value.substr(0, 2)}`;
  }

  if (value.length > 2) {
    cellPhone += value.substr(2, 5);
  }

  if (value.length > 7) {
    cellPhone += `-${value.substr(7, 4)}`;
  }

  return cellPhone;
}

export function cpfMask(value) {
  value = value.replace(/\D/g, '');

  let cpf = '';

  if (value.length === 0) {
    cpf = '';
  } else if (value.length >= 1 && value.length <= 3) {
    cpf = value.substr(0, 3);
  } else if (value.length > 3 && value.length <= 6) {
    cpf = value.substr(0, 3) + '.' + value.substr(3, 3);
  } else if (value.length > 6 && value.length <= 9) {
    cpf = value.substr(0, 3) + '.' + value.substr(3, 3) + '.' + value.substr(6, 3);
  } else {
    cpf =
      value.substr(0, 3) +
      '.' +
      value.substr(3, 3) +
      '.' +
      value.substr(6, 3) +
      '-' +
      value.substr(9, 2);
  }

  return cpf;
}

function isTimestampMilliseconds(num) {
  if (typeof num !== 'number' || !Number.isInteger(num)) {
    return false;
  }

  const now = Date.now();
  const minDate = new Date(1970, 0, 1).getTime();

  // Verifica se o número está no intervalo de timestamps válidos em milissegundos
  return num >= minDate && num <= now;
}

export function checkInfo(key, value) {
  const isDate = isTimestampMilliseconds(value);
  switch (key) {
    case 'birthDate':
    case 'dateOfBirth':
      return formatDate(parseInt(value, 10));

    case 'dateVerified':
    case 'created':
    case 'dateAcceptance':
    case 'lastLogin':
    case 'lastlogin':
    case 'modified':
    case 'registrationDate':
    case 'updateDate':
      return formatDateWithTime(parseInt(value, 10));

    default:
      return isDate ? formatDate(parseInt(value, 10)) : JSON.stringify(value);
  }
}

export function formatDate(date) {
  let month = new Date(date).getMonth() + 1;
  month = month < 10 ? '0' + month : month;

  return new Date(date).getDate() + '/' + month + '/' + new Date(date).getFullYear();
}

export function formatDateWithTime(date) {
  return (
    new Date(date).getDate() +
    '/' +
    (new Date(date).getMonth() + 1) +
    '/' +
    new Date(date).getFullYear() +
    ' ' +
    new Date(date).getHours() +
    ':' +
    new Date(date).getMinutes() +
    ':' +
    new Date(date).getSeconds()
  );
}

export function maskCEP(cep) {
  cep = cep.replace(/\D/g, '');
  cep = cep.replace(/(\d{5})(\d)/, '$1-$2');
  cep = cep.slice(0, 9);
  cep = cep.replace(/(\d{5})(\d{3})$/, '$1-$2');
  return cep;
}

export function maskCPF2(cpf) {
  return cpf
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function maskCnpj(v) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.slice(0, 17);

  v = v.replace(/(\d{4})(\d{2})$/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return v;
}

export function removeDuplicateUsingFilter(arr) {
  if (arr !== null && arr !== undefined) {
    let unique_array = arr.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return unique_array;
  } else {
    return [];
  }
}

export function findIndexInArray(array, type, value) {
  let x = null;

  if (Array.isArray(array)) {
    array.map(function (array, i) {
      if (array !== null && array[type] === value) {
        x = i;
      }

      return x;
    });
  }

  return x;
}

export function findValueInArray(array, type, value) {
  let x = null;

  if (Array.isArray(array)) {
    array.map(function (array, i) {
      if (array !== null && array[type] !== undefined && array[type] === value) {
        x = array;
      }

      return x;
    });
  }

  return x;
}

export function login_v3(cellphone) {
  return new Promise((resolve) => {
    let linkedAccount = `${cellphone}@sms,triibo,com,br`;

    let userInfo = {
      phone: cellphone,
      linkedAccount: linkedAccount,
    };

    axios
      .post(cfac22('API_HOST_V1') + 'login_v3', userInfo, {
        headers: {
          access_token: cfac22('ACCESS_TOKEN'),
        },
      })
      .then((result) => {
        if (result.data.success === true) {
          resolve(result.data.user);
        } else {
          resolve(false);
        }
      });
  });
}

export function getUid(cellPhone) {
  return new Promise((resolve) => {
    let authEmail = cellPhone + '@sms.triibo.com.br';

    firebase
      .auth()
      .createUserWithEmailAndPassword(authEmail, 'triibo18')
      .then((result) => {
        resolve(result.user.uid);
      })
      .catch(function (error) {
        console.log('ERRO AO CRIAR USUÁRIO NO AUTH ===>', error);
      });
  });
}

export function getUserInfo() {
  const codeInputUser = store.getState().codeInputModel.userInfo;
  const signInUser = store.getState().signInModel.userInfo;

  if (codeInputUser === null) {
    return signInUser;
  } else {
    return codeInputUser;
  }
}

export const days = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

export function toDataURL(url) {
  return new Promise((fulfill, reject) => {
    if (url === null) fulfill(null);
    else {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          fulfill(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }
  });
}

export function isAdminPage() {
  let pathnameArray = window.location.pathname.split('/');

  if (pathnameArray[1] === 'admin') {
    return true;
  }

  return false;
}

export function b64toBlob(file) {
  file = file.split(',');
  const b64Data = file[1];
  const data = file[0].split(';');
  const newData = data[0].split(':');
  const contentType = newData[1];
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += 512) {
    var slice = byteCharacters.slice(offset, offset + 512);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function compareDatesFromStr(timeOne, timeTwo) {
  const dateObjOne = new Date('1/1/1999 ' + timeOne); // data 1/1/1999 arbitrário, serve apenas para criar um novo Date válido
  const dateObjTwo = new Date('1/1/1999 ' + timeTwo);

  if (dateObjTwo > dateObjOne) {
    return true;
  } else {
    return false;
  }
}

//converte tabela em formato novo de datas de abertura de estabelecimento aberto para legado
export function convertDatesToNewFormat(legacyTablesArr) {
  let newTablesArr = [];

  legacyTablesArr.map((item) => {
    newTablesArr.push({
      tipo:
        item.ativo === false
          ? 'fechado'
          : item.horarioInicio === '00:00' && item.horarioFim === '23:59'
          ? '24h'
          : 'aberto', //verificar se 24h ou não
      turnos:
        item.horarioInicio && item.horarioFim
          ? [{ inicio: item.horarioInicio, fim: item.horarioFim }]
          : [],
    });
    return item;
  });

  return newTablesArr;
}

export const randomizeNumbersString = (str) => {
  let newStr = str.replace(/[0-9]/g, `${Math.floor(Math.random() * 10)}`);

  return newStr;
};

export const getBase64FromUrl = async (url) => {
  //converte imagem em URL para b64
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

//converte formato dd/mm/yyyy em timestamp
export const convertDateToTimestamp = (dateString) => {
  const [day, month, year] = dateString.split('/');
  const date = new Date(year, month - 1, day);
  const timestamp = date.getTime();
  return timestamp.toString();
};

//busca arquivo de iimagem de um URL
export async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

// funcão simples que concatena dois objetos
export function mergeObjects(obj1, obj2) {
  return { ...obj1, ...obj2 };
}

//função que procura pelo valor de um objecto pela chave 'type'
export function getValueByType(objectsArray, targetType) {
  for (const obj of objectsArray) {
    if (obj.type === targetType) {
      return obj.value;
    }
  }
  return null; // Retorna null se 'type' não for encontrado no array
}

//recursivamente encontra um valor de uma dada chave de um objeto
export function findValueByKey(obj, keyToFind) {
  if (!obj || typeof obj !== 'object') {
    return undefined;
  }

  let result;

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (key === keyToFind) {
      result = value;
    } else if (typeof value === 'object') {
      const nestedValue = findValueByKey(value, keyToFind);
      if (nestedValue !== undefined) {
        result = nestedValue;
      }
    }
  });

  return result;
}

export function removeDuplicatesFromArray(arr) {
  const uniqueArray = arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return uniqueArray;
}

export function separateBase64String(base64String) {
  const parts = base64String.split(',');

  if (parts.length !== 2) {
    throw new Error('Invalid base64 string format');
  }

  const header = parts[0];
  const data = parts[1];

  const [dataType, mimeType] = header.split(':');

  // const buffer = Buffer.from(data, 'base64');

  return {
    dataType: dataType,
    mimeType: mimeType.split(';')[0],
    buffer: data,
  };
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(',')[1];
      const mimeType = blob.type;
      const formattedString = `data:${mimeType};base64,${base64String}`;
      resolve(formattedString);
    };
    reader.onerror = () => {
      reject(new Error('Error reading blob as base64.'));
    };
    reader.readAsDataURL(blob);
  });
}

// url mask function

export function handleModeUrl(enteredUrl) {
  let formattedUrl = enteredUrl.trim();

  // Verifica se a URL começa com "http://" sem "s" ou "https://"
  if (formattedUrl.startsWith('http://') && !formattedUrl.startsWith('https://')) {
    // Remove o prefixo "http://"
    formattedUrl = formattedUrl.slice(7);
  }

  // Adiciona o prefixo "https://" se necessário
  if (!formattedUrl.startsWith('https://')) {
    formattedUrl = 'https://' + formattedUrl;
  }

  return formattedUrl;
}
