import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';

import * as S from '../styles';
import arrowDropDown from '../../../../../styles/assets/SearchUser/arrow-dropdown.svg';

import DeleteIcon from '@mui/icons-material/Delete';
import CrownIcon from '../../../../../styles/assets/SearchUser/crown.svg';
import { useEffect } from 'react';
import { GetSignatures } from 'js/library/utils/API/SearchUser/apiGetSignatures';
import { CreateSignature } from 'js/library/utils/API/SearchUser/apiCreateSignature';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { toast } from 'react-toastify';
import { DeleteSignature } from 'js/library/utils/API/SearchUser/apiDeleteSignature';
import { CheckEstAdmin } from 'js/library/utils/API/SearchUser/apiCheckEstAdmin';

export default function Signature({ isModalSignature, handleCloseModal, userv3 }) {
  const [signatureList, setSignatureList] = useState([]);
  const [business, setBusiness] = useState([]);
  const [idSubscriber, setSubscriber] = useState('');
  const [addSignatureButtomIsCheck, setAddSignatureButtomIsCheck] = useState(false);
  const [isBusinessSelectOpen, setIsBusinessSelectOpen] = useState(false);
  const [isPlanSelectOpen, setIsPlanSelectOpen] = useState(false);
  const [selectedOptionBusiness, setSelectedOptionBusiness] = useState('');
  const [selectedOptionPlan, setSelectedOptionPlan] = useState('free');
  const [loading, setLoading] = useState(false);
  const plans = ['free', 'basico', 'premium'];

  const toggling = () => setIsBusinessSelectOpen(!isBusinessSelectOpen);
  const toggling2 = () => setIsPlanSelectOpen(!isPlanSelectOpen);

  const onOptionClicked = (value, select) => () => {
    if (select === 'business') {
      setSelectedOptionBusiness(value);
      setIsBusinessSelectOpen(false);
    } else {
      setSelectedOptionPlan(value);
      setIsPlanSelectOpen(false);
    }
  };

  const getSignatures = async () => {
    if (userv3 !== null && userv3 !== false && userv3 !== undefined && userv3 !== '') {
      const response = await GetSignatures(userv3.uId).then(async (result) => {
        const data = result.signatures;
        if (data !== null && data !== undefined) {
          const mapData = data.map(async (item) => {
            const establishmentInfo = await getEstablishmentId(item.establishmentId);
            const data = {
              signatureId: item.signatureId,
              signatureType: item.signatureType,
              establishmentId: item.establishmentId,
              establishmentName: establishmentInfo.nome,
            };
            return data;
          });
          const resolved = await Promise.all(mapData);
          setSignatureList(resolved);
        }
        return;
      });

      return response;
    }
    return;
  };

  const checkUserIsGestor = async () => {
    if (userv3 !== null && userv3 !== false && userv3 !== undefined && userv3 !== '') {
      try {
        const data = await CheckEstAdmin(userv3.uId, 'gestor');
        setBusiness(data);
      } catch (error) {}
    }
    return;
  };

  useEffect(() => {
    getSignatures();
    checkUserIsGestor();
  }, [userv3]);

  const handleSubmitSignature = () => {
    setLoading(true);

    const data = {
      signatureType: selectedOptionPlan,
      signatureId: idSubscriber,
      establishmentId: selectedOptionBusiness.id,
      uid: userv3.uId,
    };

    CreateSignature(data)
      .then((response) => {
        setLoading(false);
        getSignatures();
        toast.success('Assinatura adicionada com sucesso.');
        setAddSignatureButtomIsCheck(false);
        setSelectedOptionBusiness('');
        setSubscriber('');
        setSelectedOptionPlan('');
      })
      .catch((response) => {
        setLoading(false);
        toast.error('Somente usuários premium podem adicionar novas assinaturas.');
      });
  };

  const handleDeleteSignature = (establishmentId) => {
    DeleteSignature(establishmentId, userv3.uId)
      .then(() => {
        const updateSignatureList = signatureList.filter(
          (item) => item.establishmentId !== establishmentId
        );

        setSignatureList(updateSignatureList);
      })
      .catch(() => {
        console.log('não deu certo');
      });
  };

  const handleCloseModalAndReset = () => {
    handleCloseModal('closeModalSignature')();
    setAddSignatureButtomIsCheck(false);
    setSelectedOptionBusiness('');
    setSubscriber('');
    setSelectedOptionPlan('');
  };

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalSignature')}
      open={isModalSignature}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={{ width: '100%', height: '100%' }}>
        <S.ModalBox style={{ maxHeight: '90%' }}>
          <div className="header">
            <img src={CrownIcon} alt="" />
            <Typography variant="h2" component="h2" id="modal-title">
              Assinaturas
            </Typography>
            <Button
              // disabled={business.length === 0 ? true : false}
              className="addButton"
              onClick={() =>
                business.length > 0
                  ? setAddSignatureButtomIsCheck(true)
                  : toast.warn('Usuário não possuí um negócio.')
              }
            >
              <span>+</span> Adicionar outro
            </Button>
          </div>
          <S.SignatureBox>
            <Box className="wrapper">
              {addSignatureButtomIsCheck && (
                <div className="SignatureCard add">
                  <div className="inputsBox">
                    <div className="dropDownContainer">
                      <label htmlFor="">Negócio</label>
                      <div className="dropDown">
                        <div className="dropDownHeader" onClick={toggling}>
                          <p>{selectedOptionBusiness ? selectedOptionBusiness.nome : ''}</p>
                          <img
                            src={arrowDropDown}
                            className={isBusinessSelectOpen ? 'iconActive' : ''}
                            alt=""
                          />
                        </div>
                        {isBusinessSelectOpen && (
                          <div className="dropDownListContainer">
                            <ul className="dropDownList">
                              {business.map((option, index) => (
                                <li
                                  className="listItem"
                                  onClick={onOptionClicked(option, 'business')}
                                  key={index}
                                >
                                  {option.nome}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <FormControl className="inputBox">
                      <label htmlFor="">ID assinante</label>
                      <input
                        type="text"
                        className="input"
                        value={idSubscriber}
                        onChange={(e) => setSubscriber(e.target.value)}
                      />
                    </FormControl>
                    <div className="dropDownContainer">
                      <label htmlFor="">Plano</label>
                      <div className="dropDown">
                        <div className="dropDownHeader" onClick={toggling2}>
                          <p>{selectedOptionPlan}</p>
                          <img
                            src={arrowDropDown}
                            className={isPlanSelectOpen ? 'iconActive' : ''}
                            alt=""
                          />
                        </div>
                        {isPlanSelectOpen && (
                          <div className="dropDownListContainer">
                            <ul className="dropDownList">
                              {plans.map((plan, index) => (
                                <li
                                  className="listItem"
                                  onClick={onOptionClicked(plan, 'plan')}
                                  key={index}
                                >
                                  {plan}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="listSignatures">
                {signatureList ? (
                  signatureList.map((item, index) => (
                    <div className="SignatureCard" key={index}>
                      <div className="inputsBox">
                        <FormControl className="inputBox">
                          <label htmlFor="">Negócio</label>
                          <input disabled className="input" value={item.establishmentName} />
                        </FormControl>
                        <FormControl className="inputBox">
                          <label htmlFor="">ID assinante</label>
                          <input disabled className="input" value={item.signatureId} />
                        </FormControl>
                        <FormControl className="inputBox">
                          <label htmlFor="">Plano</label>
                          <input disabled className="input" value={item.signatureType} />
                        </FormControl>
                      </div>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteSignature(item.establishmentId)}
                      >
                        <DeleteIcon className="iconDelete" />
                      </IconButton>
                    </div>
                  ))
                ) : (
                  <div style={{ margin: '0 auto' }}>Usuário não possui assinaturas</div>
                )}
              </div>
            </Box>

            <Box className="actionsButtons">
              <Button variant="contained" className="button1" onClick={handleCloseModalAndReset}>
                Voltar
              </Button>
              <Button
                disabled={addSignatureButtomIsCheck === false || loading === true}
                variant="contained"
                className="button2"
                onClick={() => {
                  handleSubmitSignature();
                }}
              >
                {loading && <CircularProgress style={{ marginRight: '0.5rem' }} size="23px" />}
                Salvar
              </Button>
            </Box>
          </S.SignatureBox>
        </S.ModalBox>
      </div>
    </Modal>
  );
}
