export default function reducer(state = {
  userInfo: null
}, action) {

  switch (action.type) {

    case "SIGN_IN_PENDING":
      {
        return {
          ...state,
          userInfo: null
        };
      }

    case "SIGN_IN_FULFILLED":
      {
        return {
          ...state,
          userInfo: action.payload.userInfo
        };
      }

    case "SIGN_IN_REJECTED":
      {
        return {
          ...state,
          userInfo: null
        };
      }

    default:
      return state;
  }

}