import axios from 'axios';
import authTokenWithScAndUid from '../authTokenWithScAndUid';
import cfac22 from '../../cfac22';

export function GetUserMapfre(uId) {
  return new Promise(async (resolve, reject) => {
    const token = await authTokenWithScAndUid(uId);

    const config = {
      method: 'get',
      url: `${cfac22('API_HOST_V2')}/mapfre/getUserMapfre`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then((response) => {
        resolve(response.data.response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

