import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  List,
  ListItemText,
  Pagination,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import usePagination from '../usePagination';

function Row(props) {
  const { row, columns, translatedChildData } = props;
  const [open, setOpen] = useState(false);

  const translatedRow = translatedChildData.find((data) => data['Identificação'] === row.id);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => (
          <TableCell key={column.value} align={column.align || 'left'}>
            {row[column.value]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" fontWeight="bold">
                Detalhes
              </Typography>
              <List>
                {translatedRow &&
                  Object.entries(translatedRow).map(([key, value]) => (
                    <ListItemText
                      key={key}
                      primary={
                        <>
                          <strong>{key}:</strong> {value}
                        </>
                      }
                    />
                  ))}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      align: PropTypes.string,
    })
  ).isRequired,
  translatedChildData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function CollapsibleTable({
  columns,
  data,
  translatedChildData,
  perPage,
  isPagination,
}) {
  const [page, setPage] = useState(1);
  const PER_PAGE = perPage;
  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map((column) => (
              <TableCell key={column.value} align={column.align || 'left'}>
                {column.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {_DATA.currentData().map((row) => (
            <Row
              key={row.id}
              row={row}
              columns={columns}
              translatedChildData={translatedChildData}
            />
          ))}
        </TableBody>
      </Table>
      {isPagination ? null : (
        <Pagination
          sx={{ margin: '0 auto' }}
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          color="secondary"
          onChange={handleChangePage}
        />
      )}
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      align: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  translatedChildData: PropTypes.arrayOf(PropTypes.object).isRequired,
};
