import React from 'react';
import { TriiboH1 } from 'js/components/DesignSystem/styles';
import * as S from "./styles";

export function HeaderTitle({ pageInfo }) {

  return (
   <>
   <S.AreaHeader 
   style={{  
   justifyContent: "center", 
   marginTop:'1rem', 
   marginBottom:'1rem' 
   }}>
      <TriiboH1 >
        {pageInfo} 
      </TriiboH1>
      </S.AreaHeader>
    </>
  );
}