import React, { useState } from 'react';

import {
  Modal,
  Typography,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

import * as S from '../styles';
import { toast } from 'react-toastify';
import { DeleteUser } from 'js/library/utils/API/SearchUser/apiDeleteUser';
import { deleteBrasilCapPartner } from 'js/library/utils/API/SearchUser/deleteBrasilCapPartner';
import { DeleteUserPartner } from 'js/library/utils/API/SearchUser/apiDeleteUserPartner';

export default function DeleteUserModal({
  userUid,
  isModalDeleteUserOpen,
  handleCloseModal,
  setUserv3,
  partnerName,
  partnerList,
}) {
  const [loadingDeleteCompleted, setLoadingDeleteCompleted] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const handleDeleteAllData = async () => {
    setLoadingDeleteCompleted(true);

    if (partnerName === 'Triibo') {
      DeleteUser(userUid)
        .then((res) => {
          setLoadingDeleteCompleted(false);
          toast.success('Usuário deletado com sucesso.');
          setUserv3(null);
          handleCloseModal('closeModalDeleteUser');
        })
        .catch((err) => {
          setLoadingDeleteCompleted(false);
          toast.error('Erro ao deletar usuário.');
          handleCloseModal('closeModalDeleteUser');
        });

      return;
    }

    if (partnerName === 'BrasilCap') {
      deleteBrasilCapPartner(partnerList.partnerId, userUid)
        .then((res) => {
          toast.success('Usuário deletado com sucesso.');
          setLoadingDeleteCompleted(false);
          handleCloseModal('closeModalDeleteUser');
        })
        .catch((err) => {
          console.log(err);
          setLoadingDeleteCompleted(false);
          toast.error('Erro ao deletar usuário.');
          handleCloseModal('closeModalDeleteUser');
        });

      return;
    }

    DeleteUserPartner(userUid, partnerList.partnerId)
      .then((res) => {
        toast.success('Usuário deletado com sucesso.');
        setLoadingDeleteCompleted(false);
        handleCloseModal('closeModalDeleteUser');
      })
      .catch((err) => {
        console.log(err);
        setLoadingDeleteCompleted(false);
        toast.error('Erro ao deletar usuário.');
        handleCloseModal('closeModalDeleteUser');
      });
  };

  return (
    <Modal
      open={isModalDeleteUserOpen}
      onClose={() => handleCloseModal('closeModalDeleteUser')}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'>
      <S.ModalBox>
        <S.DeleteUserModalBox>
          <Typography variant='h2' component='h2' id='modal-title'>
            Tem certeza?
          </Typography>

          <Typography variant='h4' component='h4' id='modal-description'>
            {partnerName === 'Triibo'
              ? 'Tem certeza que deseja deletar esta conta? Serão deletados os dados da Triibo e de todos os parceiros. A ação não pode ser desfeita'
              : 'Tem certeza que deseja deletar esta conta? Serão deletados todos os dados do parceiro. A ação não pode ser desfeita.'}
          </Typography>

          <Typography variant='h4' component='h4'>
            Para prosseguir com a ação, escreva DELETAR no campo abaixo:
          </Typography>

          <TextField
            InputProps={{
              inputProps: {
                style: { textAlign: 'center' },
              },
            }}
            variant='standard'
            value={confirmDelete}
            onChange={(e) => setConfirmDelete(e.target.value)}
          />

          <Box className='boxButtons'>
            <Button
              variant='contained'
              className='cancel'
              onClick={() => handleCloseModal('closeModalDeleteUser')}>
              cancelar
            </Button>

            <Button
              variant='contained'
              className='deletePartial'
              onClick={handleDeleteAllData}
              disabled={loadingDeleteCompleted || confirmDelete !== 'DELETAR'}>
              {loadingDeleteCompleted && (
                <CircularProgress
                  style={{ marginRight: '0.5rem' }}
                  size='23px'
                />
              )}
              Confirmar
            </Button>
          </Box>
        </S.DeleteUserModalBox>
      </S.ModalBox>
    </Modal>
  );
}
