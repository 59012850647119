import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import DataTableQrcode from 'js/containers/DataTable/DataTableQrcode';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const useStyles = makeStyles((theme) => ({
  buttonAddQrcode: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1.3rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const QrcodeList = () => {
  const classes = useStyles();

  const [rows] = useState([
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Descrição',
    },
    { id: 'action', numeric: false, disablePadding: false, label: 'Ação' },
    {
      id: 'keyWordsSet',
      numeric: false,
      disablePadding: false,
      label: 'Keywords',
    },
    { id: 'active', numeric: false, disablePadding: false, label: 'Ativo' },
    {
      id: 'button_action',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ]);

  const [pathName] = useState('/admin/configuracoes/qrcodes/editar/');

  return (
    <div
      style={{
        maxHeight: '100%',
        padding: '4rem 32px',
        maxWidth: '100%',
      }}
    >
      <GoBackHeader />
      <HeaderTitle pageInfo="QRCodes" />
      <Grid container>
        <Grid item xs={6} />
        <Grid item xs={6} />
      </Grid>
      <Box className={classes.buttonAddQrcode}>
        <Link
          to={{
            pathname: '/admin/configuracoes/qrcodes/adicionar',
            state: '',
          }}
          style={{ textDecoration: 'none' }}
        >
          <Button
            style={{
              fontWeight: '550',
              color: 'white',
              textTransform: 'capitalize',
            }}
            title="Adicionar Organização"
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
          >
            Adicionar QRCode
          </Button>
        </Link>
      </Box>

      <DataTableQrcode rows={rows} tableNode={'QRCodes'} pathName={pathName} />
    </div>
  );
};

export default QrcodeList;

