import * as React from 'react';

import * as S from './style';

import { BsFillCheckCircleFill } from 'react-icons/bs';

const IconOffersBadge = (props) => (
    <span style={{position: 'relative', display: 'flex',}}>
        <S.SvgBadge>
            <BsFillCheckCircleFill style={{ color: '#78AA1E' }} />
        </S.SvgBadge>
        
        <svg
            data-name="Grupo 10631"
            // width="2.4rem"
            // height="2.4rem"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                data-name="Caminho 15487"
                d="m63.084 30.02-1.944 3.964a11.039 11.039 0 0 1 5.593 6.325 11.039 11.039 0 0 1-.514 8.428l3.979 1.942a15.418 15.418 0 0 0 .722-11.797 15.45 15.45 0 0 0-7.836-8.863Z"
                fill="#6e3296"
                style={{
                    strokeWidth: 1.9,
                }}
            />
            <path
                data-name="Caminho 15488"
                d="m67.03 22.3-1.906 3.908a19.57 19.57 0 0 1 9.927 11.237 19.555 19.555 0 0 1-.907 14.96l3.907 1.905a23.976 23.976 0 0 0 1.125-18.286A23.92 23.92 0 0 0 67.029 22.3Z"
                fill="#6e3296"
                style={{
                    strokeWidth: 1.9,
                }}
            />
            <path
                data-name="Caminho 15489"
                d="m56.925 37.97-4.59-13.331-4.099 1.41-8.767 14.474-16.393 5.644-3.39 3.465 4.938 14.343 4.804.639L45.312 77.48l6.148-2.117-9.482-15.072 3.842-1.323 15.116 3.963 4.098-1.411-4.59-13.332a5.13 5.13 0 0 0 2.338-6.52 5.13 5.13 0 0 0-5.857-3.698z"
                fill="#6e3296"
                style={{
                    strokeWidth: 1.9,
                }}
            />
            <path
                data-name="Ret\xE2ngulo 1518"
                fill="none"
                style={{
                    strokeWidth: 0.95,
                }}
                d="M2.5 2.5h95v95h-95z"
            />
            <g
                data-name="Elipse 565"
                transform="matrix(1.9 0 0 1.9 2.5 2.5)"
                fill="none"
                stroke="#6e3296"
                strokeWidth={4}
            >
                <circle cx={25} cy={25} r={25} stroke="none" />
                <circle cx={25} cy={25} r={23} />
            </g>
        </svg>
    </span>
    
);

export default IconOffersBadge;