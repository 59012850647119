import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Menu,
  MenuItem,
  Snackbar,
  Modal,
  IconButton,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  CircularProgress,
  Box,
  Button,
  Alert,
  Container,
} from '@mui/material';
import { Delete, Edit, Cancel, Sms } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import InputMask from 'react-input-mask';

import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';
import { cellPhoneMask2, randomizeNumbersString } from 'js/library/utils/helpers';

import ValidatorsInformation from 'js/components/Validators/ValidatorsInformation';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import addAdminEstablishment from 'js/library/utils/API/Establishment/apiAddAdminEstablishment';
import addSmsDispatch from 'js/library/utils/API/Establishment/apiSendSmsDispatch';
import deleteAdminEstablishment from 'js/library/utils/API/Establishment/deleteAdminEstablishment';
import editAdminEstablishment from 'js/library/utils/API/Establishment/apiEditAdminEstablishment';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { RegisterUserModal } from '../Establishment/RelationshipScreen/RegisterUserModal';

function getModalStyle(theme) {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    backgroundColor: 'white',
    width: 800,
    maxWidth: '90%',
    maxHeight: '90%',
    padding: '50px 20px 30px 20px',
    boxSizing: 'border-box',
    overflowY: 'auto',
  };
}

const modalStyles = {
  display: 'flex',
  width: '100%',
  gap: '20px',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '3rem 2rem',
};

class AdminConfigurationExternal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admins: props.location.state.admins ?? [],
      establishmentId: props.match.params.id,
      establishmentName: props.location.state.nome,
      gestor: props.location.state.idGestor,
      administrador: '',
      nome: '',
      telefone: '',
      uid: '',
      userFound: null,
      sendingSMS: false,
      openAlert: false,
      openModal: false,
      anchorEl: null,
      selectedIndex: null,
      loading: false,
      snackMessage: 'Usuário não existe',
      loadingSearchUser: false,
      error: false,
      currentDDD: '+55',
      inputMask: '(99) 99999-9999',
      currentCountry: '',
      openModalRegisterUser: false,
      openRegisterModal: false,
      cellphone: '',
    };

    getEstablishmentId(this.state.establishmentId)
      .then((resp) => {
        this.setState({ admins: resp.admins ?? [] });
      })
      .catch((error) => {
        console.error('Erro ao obter lista de administradores:', error);
      });
    window.pathname = null;
  }

  searchUser = (cellphone) => {
    this.setState({
      loadingSearchUser: true,
      userFound: null,
      nome: '',
      telefone: '',
      uid: '',
    });

    if (cellphone.length > 3) {
      const formattedCellphone = this.state.currentDDD + cellphone.replace(/\D+/g, '');

      if (
        this.state.admins.length > 0 &&
        this.state.admins.find((i) => i.telefone === formattedCellphone)
      ) {
        this.setState({
          openAlert: true,
          loadingSearchUser: false,
          snackMessage: `Telefone digitado já está na equipe`,
          error: true,
        });
      } else {
        userReadCellphone_v3(formattedCellphone)
          .then((verification) => {
            if (verification && verification.userInfo) {
              // Existing user found
              this.setState({
                nome: verification.userInfo.name,
                telefone: formattedCellphone,
                uid: verification.userInfo.uId,
                userFound: true,
                loadingSearchUser: false,
              });
            } else {
              this.setState({
                loadingSearchUser: false,
                openModalRegisterUser: true,
              });
            }
          })
          .catch((error) => {
            console.error('Erro ao buscar informação do usuário:', error);
            this.setState({
              error: true,
              openAlert: true,
              loadingSearchUser: false,
              snackMessage: 'Erro ao buscar informação do usuário',
            });
          });
      }
    } else {
      this.setState({
        error: true,
        openAlert: true,
        loadingSearchUser: false,
        snackMessage: 'Digite o telefone do usuário',
      });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleOpenRegisterModal = () => {
    this.setState({ openRegisterModal: true, openModal: false });
  };

  handleSendSms = async (cell, uid) => {
    this.setState({ sendingSMS: `true-${cell}` });
    const admin = {
      telefone: cell,
      uid: uid,
    };

    try {
      await addSmsDispatch(admin, this.state.establishmentId);
      this.setState({
        sendingSMS: false,
        openAlert: true,
        loading: false,
        snackMessage: 'SMS enviado com sucesso',
      });
    } catch (error) {
      // Handle error
      console.error('Error sending SMS:', error);
      this.setState({
        openAlert: true,
        loading: false,
        snackMessage: 'Erro ao tentar enviar SMS',
      });
    }
  };

  addAdmin = (type) => {
    this.setState({ loading: true });

    const admin = {
      cargo: type === 'atendente' ? 'attendant' : type,
      nome: this.state.nome,
      telefone: this.state.telefone,
      uid: this.state.uid,
    };

    addAdminEstablishment(admin, this.state.establishmentId)
      .then(() => {
        this.setState({
          admins: [...this.state.admins, admin],
          loading: false,
          nome: '',
          telefone: '',
          uid: '',
          openAlert: true,
          error: false,
          snackMessage: 'Usuário adicionado com sucesso',
          userFound: null,
          openModal: false,
          administrador: '',
        });
      })
      .catch((error) => {
        console.error('Erro em addAdmin:', error);
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Erro ao tentar adicionar o tipo de cargo';
        this.setState({
          openAlert: true,
          loading: false,
          snackMessage: message,
        });
      });
  };

  changeAdmin = async (idx, type) => {
    try {
      this.setState({ anchorEl: false, loading: true });

      let newAdmins = this.state.admins;
      newAdmins[idx].cargo = type;

      await editAdminEstablishment(newAdmins[idx], this.state.establishmentId);

      this.setState({ admins: newAdmins, loading: false });
    } catch (error) {
      console.error('Erro em changeAdmin:', error);
      this.setState({
        loading: false,
        openAlert: true,
        snackMessage: 'Erro ao tentar alterar o cargo do administrador',
      });
    }
  };

  deleteAdmin = (idx) => {
    this.setState({ loading: true });

    let newAdmins = this.state.admins;
    let oldAdmin = newAdmins.splice(idx, 1);

    deleteAdminEstablishment(oldAdmin[0], this.state.establishmentId)
      .then(() => {
        this.setState({
          error: false,
          loading: false,
          openAlert: true,
          admins: newAdmins,
          snackMessage: 'Usuário removido com sucesso',
        });
      })
      .catch((error) => {
        console.error('Erro em deleteAdmin (updateDatabase):', error);
        this.setState({
          error: true,
          loading: false,
          openAlert: true,
          snackMessage: 'Erro ao tentar remover informação na lista de validadores',
        });
      });
  };

  handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    this.setState({
      cellPhone: '',
      currentDDD: valuesArr[0],
      inputMask: valuesArr[1],
      fullMask: valuesArr[2],
      currentCountry: e.target.value,
    });
  };

  render = () => {
    if (this.state.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
            }}
          />
        );
      } else {
        return (
          <div style={{ padding: '4rem 32px' }}>
            <GoBackHeader />
            <HeaderTitle pageInfo="Gerenciar Equipe" />
            <Box
              sx={{
                padding: '2.336rem 2rem',
                maxWidth: '100%',
              }}
            >
              <Grid container>
                <Grid item container xs={12} direction="row" justifyContent="right">
                  <Button
                    title="Adicionar Usuário"
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ userFound: null, openModal: true })}
                  >
                    Adicionar Usuário
                  </Button>
                </Grid>
              </Grid>

              {this.state.admins.length > 0 ? (
                <div>
                  <Table style={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead
                      style={{
                        marginBottom: '2em',
                        backgroundColor: '#fafafa',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableRow style={{ minWidth: '100%' }}>
                        <TableCell
                          style={{
                            fontWeight: '550',
                            borderBottom: 0,
                          }}
                          align="left"
                        >
                          Nome
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: '550',
                            borderBottom: 0,
                          }}
                          align="center"
                        >
                          Telefone
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: '550',
                            borderBottom: 0,
                          }}
                          align="center"
                        >
                          Cargo
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: '550',
                            borderBottom: 0,
                          }}
                          align="center"
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.admins?.map((item, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell align="left" style={{ textTransform: 'capitalize' }}>
                              {item.nome}
                            </TableCell>

                            <TableCell align="center">{cellPhoneMask2(item.telefone)}</TableCell>

                            <TableCell align="center" style={{ textTransform: 'capitalize' }}>
                              {item.cargo}
                            </TableCell>

                            <TableCell align="right">
                              <Button
                                title="Enviar SMS"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={() => this.handleSendSms(item.telefone, item.uid)}
                                disabled={this.state.sendingSMS === `true-${item.telefone}`}
                              >
                                {this.state.sendingSMS === `true-${item.telefone}` ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <Sms />
                                )}
                              </Button>
                              <Button
                                title="QRCode e editar cracha"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={() =>
                                  this.setState({
                                    validatorName: item.nome,
                                    validatorEmail: `${item.telefone}@sms,triibo,com,br`,
                                    openValidatorModal: true,
                                  })
                                }
                              >
                                <svg
                                  viewBox="0 0 448 512"
                                  style={{
                                    height: '1.5em',
                                    width: '1.5em',
                                    padding: '1.5px 0px',
                                  }}
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"
                                  />
                                </svg>
                              </Button>

                              <Button
                                title="Editar Cargo"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(event) =>
                                  this.setState({
                                    selectedIndex: idx,
                                    anchorEl: event.currentTarget,
                                  })
                                }
                              >
                                <Edit />
                              </Button>

                              {this.state.superUser || this.state.gestor ? (
                                <Button
                                  title="Excluir"
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={() => this.deleteAdmin(idx)}
                                >
                                  <Delete />
                                </Button>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.setState({ anchorEl: false })}
                      >
                        {this.state.selectedIndex !== null &&
                          this.state.admins[this.state.selectedIndex].cargo !== 'gestor' &&
                          this.state.superUser &&
                          this.state.admins.some((el) => el.cargo === 'gestor') === false && (
                            <MenuItem
                              onClick={() => this.changeAdmin(this.state.selectedIndex, 'gestor')}
                              disabled={
                                this.state.admins &&
                                this.state.admins.some((el) => el.cargo === 'gestor')
                              }
                            >
                              Tornar Gestor
                            </MenuItem>
                          )}

                        {this.state.selectedIndex !== null &&
                          this.state.admins[this.state.selectedIndex].cargo !== 'administrador' && (
                            <MenuItem
                              onClick={() =>
                                this.changeAdmin(this.state.selectedIndex, 'administrador')
                              }
                            >
                              Tornar Administrador
                            </MenuItem>
                          )}

                        {this.state.selectedIndex !== null &&
                          this.state.admins[this.state.selectedIndex].cargo !== 'validador' && (
                            <MenuItem
                              onClick={() =>
                                this.changeAdmin(this.state.selectedIndex, 'validador')
                              }
                            >
                              Tornar Atendente
                            </MenuItem>
                          )}
                      </Menu>
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '150px',
                    marginBottom: '50px',
                    textAlign: 'center',
                  }}
                >
                  Não há administradores cadastrados
                </div>
              )}

              {this.state.openRegisterModal && (
                <RegisterUserModal
                  isModalRegisterUserOpen={this.state.openRegisterModal}
                  dataCellphone={this.state.cellphone}
                  handleCloseModalRegisterUser={() =>
                    this.setState({
                      openModalRegisterUser: false,
                      openRegisterModal: false,
                      cellphone: '',
                    })
                  }
                />
              )}

              {this.state.openValidatorModal && (
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={this.state.openValidatorModal}
                  onClose={() =>
                    this.setState({
                      openValidatorModal: false,
                      validatorName: '',
                      validatorEmail: '',
                    })
                  }
                >
                  <div
                    style={Object.assign(getModalStyle(), {
                      width: '75%',
                      overflow: 'auto',
                    })}
                  >
                    <ValidatorsInformation
                      state={{
                        nome: this.state.validatorName,
                        identificacao1: this.state.validatorName,
                        email: this.state.validatorEmail,
                        idEstabelecimento: this.state.establishmentId,
                        nomeEstabelecimento: this.state.establishmentName,
                        idGestor: this.state.idGestor,
                      }}
                    />
                  </div>
                </Modal>
              )}

              {/* MODAL DE ADIÇÃO DE USUÁRIO */}
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.openModal}
                onClose={() =>
                  this.setState({
                    openModal: false,
                    userFound: null,
                    nome: '',
                    telefone: '',
                    uid: '',
                    administrador: '',
                  })
                }
              >
                <div style={getModalStyle()}>
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openModal: false,
                        userFound: null,
                        nome: '',
                        telefone: '',
                        uid: '',
                        administrador: '',
                        cellphone: '',
                        openModalRegisterUser: false,
                      })
                    }
                    edge="end"
                    style={{
                      position: 'fixed',
                      top: 0,
                      right: 0,
                      padding: '0',
                      margin: '0.5em',
                    }}
                  >
                    <Cancel />
                  </IconButton>

                  {/* Busca de usuário */}
                  {this.state.userFound !== false && (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ marginTop: '0' }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          color="primary"
                          style={{ fontWeight: '600' }}
                        >
                          Adicionar Usuário
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: '1em' }}>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.searchUser(this.state.administrador);
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '1rem',
                              border: ' 1px solid #afafaf',
                              borderRadius: '4px',
                              height: '42px',
                              width: '100%',
                            }}
                          >
                            <select
                              style={{
                                fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                                height: '100%',
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                fontSize: '17px',
                                cursor: 'pointer',
                              }}
                              value={this.state.currentCountry}
                              onChange={this.handleCountryChange}
                            >
                              {phoneMaskArray.map((country, index) => (
                                <option
                                  style={{
                                    fontFamily:
                                      "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                                  }}
                                  key={country.id}
                                  value={[country.ddd, country.mask, country.fullMask]}
                                >
                                  {country.emoji} {country.initials} {country.ddd}
                                </option>
                              ))}
                            </select>
                            <InputMask
                              style={{
                                fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                                alignContent: 'center',
                                height: '100%',

                                border: 'none',
                                outline: 'none',
                                fontSize: '17px',
                                color: '#383838d6',
                                borderRadius: '0px',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                width: '100%',
                              }}
                              mask={this.state.inputMask}
                              value={this.state.administrador}
                              placeholder={randomizeNumbersString(this.state.inputMask)}
                              onChange={this.handleChange('administrador')}
                            />
                          </div>
                          <br />

                          {this.state.loadingSearchUser ? (
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <CircularProgress />
                            </Grid>
                          ) : (
                            this.state.userFound === null && (
                              <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <Button
                                  style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                    fontWeight: '550',
                                  }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.searchUser(this.state.administrador);
                                    this.setState({ cellphone: this.state.administrador });
                                  }}
                                  disabled={this.state.loadingSearchUser}
                                >
                                  {this.state.loadingSearchUser ? (
                                    <CircularProgress />
                                  ) : (
                                    'Adicionar'
                                  )}
                                </Button>
                              </Grid>
                            )
                          )}
                        </form>
                      </Grid>
                      {this.state.openModalRegisterUser ? (
                        <Container sx={modalStyles}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{ fontWeight: '600', color: '#FF0000' }}
                          >
                            Telefone digitado não possui cadastro!
                          </Typography>
                          <Button
                            style={{
                              display: 'block',
                              margin: '0 auto',
                              color: 'white',
                              textTransform: 'capitalize',
                              fontWeight: '550',
                              width: '50%',
                            }}
                            variant="contained"
                            color="primary"
                            onClick={this.handleOpenRegisterModal}
                          >
                            Criar Cadastro
                          </Button>
                        </Container>
                      ) : (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={this.state.superUser ? 4 : 6}
                            style={{ marginTop: '1em' }}
                          >
                            <Grid container>
                              {this.state.userFound === true && (
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    style={{
                                      fontWeight: '600',
                                      textAlign: 'center',
                                    }}
                                  >
                                    Nome
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    style={{ textAlign: 'center' }}
                                  >
                                    {this.state.nome}
                                  </Typography>
                                </Grid>
                              )}

                              {this.state.userFound === true && (
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    style={{
                                      fontWeight: '600',
                                      textAlign: 'center',
                                    }}
                                  >
                                    Telefone
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    style={{ textAlign: 'center' }}
                                  >
                                    {this.state.telefone}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={this.state.superUser ? 8 : 6}
                            style={{ marginTop: '1em' }}
                          >
                            <Grid container justify="space-between" spacing={2}>
                              {this.state.userFound === true && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={8}
                                  md={this.state.superUser ? 4 : 6}
                                  style={{ marginTop: '1em' }}
                                >
                                  <Button
                                    style={{
                                      display: 'block',
                                      width: '100%',
                                      margin: '0 auto',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                      fontWeight: '550',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    //atualiza type de usuário administrador
                                    onClick={() => this.addAdmin('administrador')}
                                  >
                                    Administrador
                                  </Button>
                                </Grid>
                              )}

                              {this.state.userFound === true && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={this.state.superUser ? 4 : 6}
                                  style={{ marginTop: '1em' }}
                                >
                                  <Button
                                    style={{
                                      display: 'block',
                                      width: '100%',
                                      margin: 'auto',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                      fontWeight: '550',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.addAdmin('validador')}
                                  >
                                    Atendente
                                  </Button>
                                </Grid>
                              )}

                              {this.state.userFound === true &&
                                (this.state.admins.length === 0 ||
                                  !this.state.admins.find((i) => i.cargo === 'Gestor')) && (
                                  <Grid item xs={12} sm={6} md={4} style={{ marginTop: '1em' }}>
                                    <Button
                                      style={{
                                        display: 'block',
                                        width: '100%',
                                        margin: '0 auto',
                                        color: 'white',
                                        textTransform: 'capitalize',
                                        fontWeight: '550',
                                      }}
                                      variant="contained"
                                      color="primary"
                                      disabled={
                                        this.state.admins &&
                                        this.state.admins.some((el) => el.cargo === 'gestor')
                                      }
                                      onClick={() => this.addAdmin('gestor')}
                                    >
                                      Gestor
                                    </Button>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </div>
              </Modal>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.openAlert}
                onClose={() => this.setState({ openAlert: false })}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
              >
                <Alert
                  onClose={() => this.setState({ openAlert: false })}
                  severity={this.state.error ? 'error' : 'success'}
                >
                  {this.state.snackMessage}
                </Alert>
              </Snackbar>
            </Box>
          </div>
        );
      }
    }
  };
}

export default AdminConfigurationExternal;
