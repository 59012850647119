import * as React from 'react';

const IconMenu = (props) => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Ret\xE2ngulo 1584"
                    fill="#6e3296"
                    d="M0 0h31.665v26.519H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Painel adm">
            <g data-name="Adicionar estabelecimento">
                <path
                    data-name="Ret\xE2ngulo 1515"
                    fill="none"
                    style={{
                        strokeWidth: 0.950001,
                    }}
                    d="M2.5 2.5h95v95h-95z"
                />
                <g
                    data-name="Elipse 570"
                    transform="matrix(1.9 0 0 1.9 2.5 2.5)"
                    fill="none"
                    stroke="#6e3296"
                    strokeWidth={4}
                >
                    <circle cx={25} cy={25} r={25} stroke="none" />
                    <circle cx={25} cy={25} r={23} />
                </g>
            </g>
            <g data-name="Grupo 10567">
                <g
                    data-name="Grupo 10566"
                    clipPath="url(#a)"
                    fill="#6e3296"
                    transform="matrix(1.9 0 0 1.9 19.917 19.6)"
                >
                    <path
                        data-name="Caminho 15490"
                        d="M29.417 22.73h-5.9V7.471c0-1.108.271-1.375 1.39-1.376 1.134 0 2.268-.005 3.4 0 .8 0 1.141.326 1.142 1.113q.007 7.536 0 15.072c0 .139-.023.278-.038.448"
                    />
                    <path
                        data-name="Caminho 15491"
                        d="M22.305 22.734h-5.84c-.012-.226-.033-.424-.033-.623V10.445c0-1.011.269-1.281 1.277-1.284 1.174 0 2.348-.009 3.522 0 .772.007 1.125.326 1.127 1.074q.015 6.111 0 12.222a1.658 1.658 0 0 1-.054.276"
                    />
                    <path
                        data-name="Caminho 15492"
                        d="M15.197 22.727h-5.86c-.013-.22-.034-.418-.035-.616v-8.028c0-1.073.281-1.353 1.356-1.357 1.113 0 2.226-.006 3.339 0 .933.006 1.236.3 1.237 1.207q.006 4.138 0 8.275c0 .16-.021.321-.035.517"
                    />
                    <path
                        data-name="Caminho 15493"
                        d="M15.813 26.515H1.4a3.357 3.357 0 0 1-.617-.019.929.929 0 0 1 .008-1.838 2.92 2.92 0 0 1 .555-.017h28.947a3.392 3.392 0 0 1 .556.015.929.929 0 0 1-.008 1.845 4.093 4.093 0 0 1-.617.014H15.813"
                    />
                    <path
                        data-name="Caminho 15494"
                        d="M18.497 1.92c-.445-.02-.71-.017-.97-.046a.975.975 0 0 1-.93-.967.936.936 0 0 1 .978-.906q1.545-.007 3.091.057a.859.859 0 0 1 .895.912q.015 1.574-.048 3.149a.932.932 0 0 1-1.862-.035c-.011-.163 0-.328 0-.608-.2.156-.343.251-.468.363a24.876 24.876 0 0 1-14.146 6.306.955.955 0 0 1-1.14-.786.925.925 0 0 1 .916-1.083 26 26 0 0 0 6.277-1.553 24.062 24.062 0 0 0 7.41-4.8"
                    />
                    <path
                        data-name="Caminho 15495"
                        d="M8.141 22.73H2.208v-2.155c0-1.049-.005-2.1 0-3.146.006-.768.319-1.12 1.067-1.132q1.914-.03 3.829 0a.928.928 0 0 1 1.028.989c.02 1.787.007 3.575.007 5.441"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default IconMenu;
