import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { getUserInfo } from '../../helpers';
import cfac22 from '../../cfac22';

export default function createEstablishment(establishmentId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (establishmentId !== null) {
      const userInfo = getUserInfo();

      authToken(userInfo.uId)
        .then((token) => {
          axios
            .post(
              `${cfac22('API_HOST_V2')}/establishments/pending`,
              { payload },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            )
            .then((result) => {
              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.establishmentInfos.id = establishmentId;

              resolve(result.data.establishmentInfos);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}
