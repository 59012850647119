import { apiGetLpByPartnerId } from 'js/library/utils/API/CampaignLandinPage/apiGetLpByPartnerId';
import { toast } from 'react-toastify';

const getLandingPages = async (partnerId, title, from, size) => {
  const body = {
    payload: {
      partnerId,
      title,
      from,
      size,
    },
  };

  const response = await apiGetLpByPartnerId(body);

  if (response?.success) {
    return response?.partnerInfo;
  } else {
    toast.error(response?.error);
  }
};

export default {
  getLandingPages,
};
