import React, { useEffect, useRef, useState } from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import closeImg from '../../../../../../styles/assets/communications/closeImg.svg';
import editImg from '../../../../../../styles/assets/communications/edit.svg';
import placeholderImportImg from '../../../../../../styles/assets/communications/placeholderImportImg.svg';
import saveFile from '../../../../../../styles/assets/communications/saveFile.svg';
import CropperModal from 'js/components/DesignSystem/Cropper/CropperModal';
import { downloadImage } from 'js/library/services/StorageManager';

export default function ImageCrop({ image, setImage, previousImage, setPreviousImage }) {
  const previousImageSecondParams = previousImage
    ? previousImage.split('/').slice(1).join('/')
    : null;
  const [imageCrop, setImageCrop] = useState('');
  const [imagePreview, setImagePreview] = useState('');

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageFormatted = await downloadImage('postFiles', previousImageSecondParams);

        setImagePreview(imageFormatted);
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };

    if (previousImageSecondParams) loadImage();
  }, [previousImageSecondParams]);

  const [isCropImageOpen, setIsCropImageOpen] = useState(false);

  useEffect(() => {
    !image ? setImage(imagePreview) : setImage(image);
  }, [image, imagePreview]);

  const hiddenFileInput = useRef(null);

  const handleClickInputImage = (e) => {
    setImageCrop(image ? image : imagePreview);
    hiddenFileInput.current.click();
  };

  const dragEvents = {
    onDragEnter: (e) => {
      e.preventDefault();
    },
    onDragLeave: (e) => {
      e.preventDefault();
    },
    onDragOver: (e) => {
      e.preventDefault();
    },
    onDrop: (e) => {
      e.preventDefault();
      e.stopPropagation();
      let file;
      if (e.dataTransfer) {
        file = e.dataTransfer.files[0];
      } else if (e.target) {
        file = e.target.files[0];
      }

      if (!file) {
        return;
      }

      if (file.size > 1048576 * 10) {
        alert('Arquivo excedeu 10MB');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      setIsCropImageOpen(true);

      reader.onloadend = () => {
        const newImage = reader.result;
        setImageCrop(newImage);
      };

      e.target.value = null;
    },
  };

  const handleChangeImage = (e) => {
    e.preventDefault();

    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else if (e.target) {
      file = e.target.files[0];
    }

    if (!file) {
      return;
    }

    if (file.size > 1048576 * 10) {
      alert('Arquivo excedeu 10MB');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    setIsCropImageOpen(true);

    reader.onloadend = () => {
      const newImage = reader.result;
      setImageCrop(newImage ? newImage : imagePreview);
    };
  };

  const handleAddImage = (event) => {
    event.preventDefault();
    setImage('');
    handleClickInputImage();
  };

  return (
    <S.Container>
      <CropperModal
        open={isCropImageOpen}
        setOpen={setIsCropImageOpen}
        img={imageCrop}
        setImg={setImage}
      />

      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*"
        onChange={(e) => {
          handleChangeImage(e);
        }}
        style={{ visibility: 'hidden', display: 'none' }}
      />
      <div>
        <D.TriiboH4>
          <b>Imagem</b>
        </D.TriiboH4>
        <D.TriiboH5>
          Escolha a imagem que será exibida na notificação/post. Tamanho ideal: 4:3
        </D.TriiboH5>
      </div>

      <S.ImageImportContainer imageIsImported={image !== '' ? true : false}>
        {image === '' ? (
          <S.ImageImportBox {...dragEvents}>
            <img src={placeholderImportImg} alt="" />
            <h5>
              Arraste e solte os arquivos ou <span>selecione</span>
            </h5>
            <div>
              <S.ButtonImportImage onClick={(e) => handleAddImage(e)}>
                <img src={saveFile} alt="Icone salvar" /> Carregar arquivo
              </S.ButtonImportImage>
              <D.TriiboH6>Tamanho máx: 4mb</D.TriiboH6>
            </div>
          </S.ImageImportBox>
        ) : (
          <S.PreviewImageBox>
            <S.ResetImage className="resetImageIcon" onClick={() => setImage('')}>
              <img src={closeImg} alt="" />
            </S.ResetImage>
            <img src={image} alt="" className="image" />
            <S.editImage className="editImageIcon" onClick={(e) => handleAddImage(e)}>
              <img src={editImg} alt="" /> Editar
            </S.editImage>
          </S.PreviewImageBox>
        )}
      </S.ImageImportContainer>
    </S.Container>
  );
}

