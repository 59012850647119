import React, { useEffect, useCallback, useMemo } from 'react';

// services
import { AttendanceServices } from './AttendanceServices';

// context hooks
import { usePromotions, useModal } from '../Context/Attendance';

import { Grid, Box, Typography, Container, Modal } from '@mui/material';

// components
import Header from '../Header';
import { PromotionCard } from './Cards';
import { Fade, PromotionModalForm } from './Modals';

// styles
import useStyles from './styles';

// main component
export default function AttendanceScreen({ location, history }) {
  const { openModal, setOpenModal, setIsUserRegister } = useModal();
  const { currentPromotions, setCurrentPromotions } = usePromotions();

  const { lat, long, nome } = location.state;

  const classes = useStyles();

  // Memoize the attendanceServices instance to avoid unnecessary re-creations
  const attendanceServices = useMemo(
    () => new AttendanceServices(location.state),
    [location.state]
  );

  const handleSearch = (userData, walletData) => {
    history.push(`${history.location.pathname}/cliente`, {
      ...userData,
      ...walletData,
      lat,
      long,
    });
  };

  const renderPromotionCards = useCallback(
    () =>
      currentPromotions.map((promotion) => {
        const isActive =
          promotion.isPublished === true &&
          promotion.enableDate <= Date.now() &&
          promotion.disableDate >= Date.now();
        const isAwaiting = promotion.aprovacao?.status === 'aberto';
        return promotion.type === 'businessPartner' || !isActive || isAwaiting ? (
          ' '
        ) : (
          <PromotionCard
            key={promotion.id}
            {...{
              classes,
              promotion,
              attendanceServices,
            }}
          />
        );
      }),
    [currentPromotions, attendanceServices, classes]
  );

  // Adjust useEffect dependencies to include the required variables
  useEffect(() => {
    attendanceServices.getPromotions(setCurrentPromotions);
    setIsUserRegister(false);
  }, [attendanceServices, setCurrentPromotions, setIsUserRegister]);

  return (
    <Container maxWidth={false} classes={{ root: classes.root }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => {
          setIsUserRegister(false);
          setOpenModal(!openModal);
        }}
        closeAfterTransition
        disableAutoFocus
        disableEnforceFocus
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <PromotionModalForm {...{ classes }} />
          </div>
        </Fade>
      </Modal>

      <Header establishmentName={nome} title="Atendimento" action={handleSearch} />

      <br />
      <hr />
      <br />

      <Grid container>
        <Typography
          variant="body1"
          component="h3"
          classes={{ body1: classes.cardText }}
          style={{ marginBottom: '1rem' }}
        >
          Minhas promoções
        </Typography>
        <Grid item md={12} className={classes.promotions}>
          {currentPromotions.length <= 0 ? (
            <Box display="flex" justifyContent="center">
              {console.log('currentPromotions.length', currentPromotions.length)}
              <Typography>Não existe promoções cadastradas!</Typography>
            </Box>
          ) : (
            <Box display="flex" flexWrap="wrap">
              {renderPromotionCards()}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
