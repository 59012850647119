import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Box, Grid } from '@mui/material';

import StepsButton from './StepsButton';

import { listarEstabelecimentos } from 'js/library/services/AuthenticationManager';

const ConditionalLink = ({ children, to, condition }) => (
  // !!condition && to ? (
  //   <Link to={to} style={{ textDecoration: 'none', color: 'black' }}>
  //     {children}
  //   </Link>
  // ) : (
  //   <div>{children}</div>
  // );

  <Link to={to} style={{ textDecoration: 'none', color: 'black' }}>
    {children}
  </Link>
);

const StepsMessage = ({ allStepsOnboarding }) => {
  let state = useSelector((state) => state.authModel);

  const AltRequisition = async () => {
    const promiseEstablishment = await listarEstabelecimentos();

    return promiseEstablishment;
  };

  let establishmentArr = [];
  if (state.establishmentList !== null) {
    establishmentArr = state.establishmentList;
  } else {
    establishmentArr = AltRequisition;
  }

  function getEstablishmentId(establishmentArr) {
    if (allStepsOnboarding.establishmentStep !== true) {
      return '';
    } else {
      window.sessionStorage.setItem('firstStep', 'complete');
      return establishmentArr[0];
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={1} md={0}></Grid>
        <Grid
          item
          xs={12}
          md={12}
          justifyContent='center'
          alignItems='center'
          gap={1}
          sx={{
            paddingRight: { xs: '0', md: '4rem' },
          }}>
          <Typography
            variant='h4'
            align='center'
            color='#383838'
            display='block'
            sx={{ fontSize: { md: '3.8rem' } }}>
            <strong>Siga os passos</strong>
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            color='#383838'
            sx={{ fontSize: { md: '1.2rem' } }}>
            {!allStepsOnboarding.establishmentStep
              ? 'Faça o cadastro de seu negócio'
              : ''}
            {(allStepsOnboarding.establishmentStep &&
              !allStepsOnboarding.storeStep &&
              !allStepsOnboarding.relationshipStep) ||
            (allStepsOnboarding.establishmentStep &&
              !allStepsOnboarding.storeStep &&
              allStepsOnboarding.relationshipStep)
              ? 'Faça o cadastro da sua primeira promoção'
              : ''}
            {allStepsOnboarding.establishmentStep &&
            allStepsOnboarding.storeStep &&
            !allStepsOnboarding.relationshipStep
              ? 'Faça o cadastro de seus clientes'
              : ''}
          </Typography>

          <Box
            sx={[
              {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.75rem',
                marginTop: '1rem',
              },
            ]}>
            <ConditionalLink
              to={{ pathname: '/estabelecimento/novo/adicionar', state: '' }}
              condition={
                !allStepsOnboarding.establishmentStep
                  ? establishmentArr.length === 0
                    ? true
                    : false
                  : false
              }>
              <Box
                onClick={() => {
                  window.sessionStorage.setItem('firstStep', 'incomplete');
                }}>
                <StepsButton
                  mainText='1 - Adicione o seu negócio'
                  status={
                    establishmentArr.length === 0
                      ? 1
                      : !allStepsOnboarding.establishmentStep
                      ? 1
                      : 0
                  }
                />
              </Box>
            </ConditionalLink>

            <ConditionalLink
              to={{
                pathname:
                  establishmentArr.length === 0
                    ? ''
                    : `/estabelecimento/${establishmentArr[0].id}/promocoes/`,
                state: establishmentArr[0],
              }}
              condition={
                !allStepsOnboarding.storeStep
                  ? establishmentArr.length === 0
                    ? false
                    : true
                  : false
              }>
              <Box
                onClick={() => {
                  window.sessionStorage.setItem('secondStepNew', 'incomplete');
                }}>
                <StepsButton
                  mainText='2 - Cadastre uma promoção'
                  status={
                    establishmentArr.length === 0
                      ? 2
                      : !allStepsOnboarding.storeStep ||
                        establishmentArr.length === 0
                      ? 1
                      : 0
                  }
                />
              </Box>
            </ConditionalLink>

            <ConditionalLink
              to={{
                pathname:
                  establishmentArr.length === 0
                    ? ''
                    : `/estabelecimento/${
                        getEstablishmentId(establishmentArr).id
                      }/relacionamento/cadastro-usuarios/step`,
                state: getEstablishmentId(establishmentArr),
              }}
              condition={
                !allStepsOnboarding.relationshipStep
                  ? establishmentArr.length === 0 ||
                    !allStepsOnboarding.storeStep
                    ? false
                    : true
                  : false
              }>
              <Box
                onClick={() => {
                  window.sessionStorage.setItem('thirdStep', 'incomplete');
                }}>
                <StepsButton
                  mainText='3 - Cadastre seus clientes'
                  status={
                    establishmentArr.length === 0 ||
                    !allStepsOnboarding.storeStep
                      ? 2
                      : !allStepsOnboarding.relationshipStep
                      ? 1
                      : 0
                  }
                />
              </Box>
            </ConditionalLink>
          </Box>
        </Grid>
        <Grid item xs={1} md={0}></Grid>
      </Grid>
    </>
  );
};

export default StepsMessage;
