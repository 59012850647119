import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  TextField,
  Button,
  Grid,
  Typography,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { Save, ArrowBack } from '@mui/icons-material';
import { withStyles } from '@mui/styles';

import Loading from 'js/containers/Loading/Loading';
import { updateStoreGenerationAction } from 'js/core/actions/generationInformationActions';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

function StoreGenerationInformation(props) {
  const [state, setState] = useState({
    id: props,
    templateId: props.location.state.templateId,
    voucherAmmount: null,
    expiration: null,
    batchName: null,
    qtdDisponivel: 0,
    open: false,
    goBack: false,
    openAlert: false,
    messageAlert: '',
    generator: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getVoucherTemplate(state.templateId)
      .then((dataReceived) => {
        setState((prevState) => ({
          ...prevState,
          qtdDisponivel: dataReceived.result.qtdDisponivel,
          generator: dataReceived.result.generator,
        }));
      })
      .catch((error) => {
        // console.log('Erro na data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state.templateId]);

  const handleChange = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
      goBack: true,
    }));
  };

  const goBack = () => {
    if (state.goBack === false) {
      window.history.back();
    } else {
      handleClickOpen();
    }
  };

  const checkInformation = () => {
    const checkInformation =
      state.templateId === null ||
      state.templateId === '' ||
      state.voucherAmmount === null ||
      state.voucherAmmount === '' ||
      state.expiration === null ||
      state.expiration === '' ||
      state.batchName === null ||
      state.batchName === '';

    if (checkInformation === true) {
      setState((prevState) => ({
        ...prevState,
        openAlert: true,
        messageAlert: 'Todas as informações obrigatórias devem estar preenchidas.',
      }));
    } else {
      setLoading(true);
      props.updateStoreGenerationComponent(
        state.id,
        state.templateId,
        state.voucherAmmount,
        state.expiration,
        state.batchName
      );
    }
  };

  const handleClickOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      openCheck: false,
    }));
  };

  if (loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  // Se houver uma pathname definida, redirecione para essa rota
  if (window.pathname !== null) {
    return (
      <Redirect
        to={{
          pathname: window.pathname,
          state: state.dataEstabelecimento,
        }}
      />
    );
  }

  return (
    <div style={{ padding: '100px 50px' }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          checkInformation();
        }}
      >
        <FormGroup>
          <Typography variant="h5" gutterBottom color="primary" style={{ fontWeight: '700' }}>
            {' '}
            Gerar Voucher
          </Typography>

          <Grid item sm={12} xs={6}>
            <Typography
              variant="h5"
              gutterBottom
              color="primary"
              style={{ fontWeight: '50', float: 'left' }}
            >
              {' '}
              Quantidade Disponível: {state.qtdDisponivel ?? '0'}
            </Typography>
          </Grid>

          <TextField
            style={{ width: '300px' }}
            className="input-field"
            type="text"
            value={state.batchName === null ? '' : state.batchName}
            id="batchName"
            label="Nome do Lote de Cupons"
            required={true}
            onChange={handleChange('batchName')}
          />

          <TextField
            style={{ width: '300px' }}
            required={true}
            onChange={handleChange('voucherAmmount')}
            className="input-field"
            type="number"
            id="voucherAmmount"
            label="Quantidade de Cupons"
          />

          <br />
          <TextField
            required={true}
            style={{ width: '300px' }}
            id="expiration"
            label="Data de Validade do Cupom"
            type="datetime-local"
            value={state.expiration}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange('expiration')}
          />
        </FormGroup>

        <Button
          style={{
            fontWeight: '550',
            marginTop: '50px',
            color: 'white',
            textTransform: 'capitalize',
          }}
          variant="contained"
          color="secondary"
          onClick={() => goBack()}
          startIcon={<ArrowBack />}
        >
          Voltar
        </Button>

        <Button
          style={{
            fontWeight: '550',
            marginTop: '50px',
            marginLeft: '10px',
            color: 'white',
            textTransform: 'capitalize',
          }}
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<Save />}
        >
          Salvar
        </Button>
      </form>

      {/*ALERTS E DIALOGS*/}
      <Dialog
        open={state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Deseja voltar a página anterior?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            As alterações realizadas não serão salvas.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancelar
          </Button>
          <Button onClick={() => window.history.back()} color="primary">
            voltar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    id: state.generationInformationModel.id,
    templateId: state.generationInformationModel.templateId,
    voucherAmmount: state.generationInformationModel.voucherAmmount,
    expiration: state.generationInformationModel.expiration,
    batchName: state.generationInformationModel.batchName,

    loading: state.generationInformationComponent.loading,
    success: state.generationInformationComponent.success,
    error: state.generationInformationComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateStoreGenerationComponent: (id, templateId, voucherAmmount, expiration, batchName) =>
    updateStoreGenerationAction(dispatch, id, templateId, voucherAmmount, expiration, batchName),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StoreGenerationInformation);

