import React from 'react';

// COMPONENTES
import DataTablePoints from '../../PointsManagement/DataTablePoints';

export default function PointsClub(props) {
  const clubId = props.match.params.id;

  return <DataTablePoints clubId={clubId} />;
}
