import React, { useEffect, useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUserInfo, isAdminPage } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';

// STYLES
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Error, CheckCircle, WatchLater } from '@mui/icons-material';
import { ImQrcode } from 'react-icons/im';
import { FiSend } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';
import { RiCoupon2Line } from 'react-icons/ri';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';

// APIS
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const DataTable = (props) => {
  const {
    adminToggle,
    classes,
    dataEstabelecimento,
    downloadImage,
    duplicatePromotion,
    establishmentId,
    handleChangePage,
    isActive,
    isDisableCheck,
    loadingList,
    newFilteredPromotionList,
    page,
    promotionList,
    rows,
    rowsPerPage,
    toggleSendVoucherModal,
  } = props;

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('active');
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let promotionPositionClicked = null;
    const activePromotions = window?.promoListOrganized?.newFilteredPromotionList;
    const promotionIndex = window?.promoListOrganized?.index;
    const isChecked = window?.promoListOrganized?.isChecked;

    if (window?.promoListOrganized) {
      if (activePromotions.length > 0 && !isChecked) {
        activePromotions.forEach((promo) => {
          if (promo.key === window?.promoListOrganized?.promotionPositionClicked) {
            promotionPositionClicked = window?.promoListOrganized?.promotionPositionClicked;
          } else {
            promotionPositionClicked =
              promotionIndex > 1 ? activePromotions[promotionIndex - 1]?.key : '';
          }
        });
      } else {
        promotionPositionClicked = window?.promoListOrganized?.promotionPositionClicked;
      }

      const element = document.getElementById(promotionPositionClicked);

      if (element?.scrollIntoView) {
        element.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
    }
  }, []);

  const handleRequestSort = (event, property) => {
    setOrder((prevOrder) => {
      const isAsc = orderBy === property && prevOrder === 'asc';
      setOrderBy(property);
      return isAsc ? 'desc' : 'asc';
    });
  };

  const setPagePosition = useCallback(
    (key, index, n) => {
      switch (true) {
        case n.aprovacao && n.aprovacao.status === 'negado':
          localStorage.setItem('statusPromotion', 'Reprovado');
          break;
        case n.aprovacao && n.aprovacao.status === 'aberto':
          localStorage.setItem('statusPromotion', 'Em Revisão');
          break;
        case n.isPublished === true && n.enableDate <= Date.now() && n.disableDate >= Date.now():
          localStorage.setItem('statusPromotion', 'Ativo');
          break;
        default:
          localStorage.setItem('statusPromotion', 'Inativo');
      }

      window.promoListOrganized = {
        page,
        rowsPerPage,
        establishmentId,
        promotionListActive: promotionList,
        newFilteredPromotionList: newFilteredPromotionList,
        isDisableCheck,
        isActive,
        loadingList,
        type: 'promo-edit',
        promotionPositionClicked: key,
        index,
      };
    },
    [
      page,
      rowsPerPage,
      establishmentId,
      promotionList,
      newFilteredPromotionList,
      isDisableCheck,
      isActive,
      loadingList,
    ]
  );

  const handleGetVoucherTemplate = useCallback((dataPromotion, history) => {
    getVoucherTemplate(dataPromotion.templateId)
      .then((data) => {
        if (data.result.generator) {
          setOpenDialog(true);
        } else {
          history.push({
            pathname: '/admin/store/gerarVoucher',
            state: dataPromotion,
          });
        }
      })
      .catch((error) => {
        console.error('Erro na requisição da getVoucherTemplate', error);
      });
  }, []);

  return (
    <Route
      render={({ history }) => (
        <div className="scrollbar" style={{ width: '100%', paddingBottom: '10px' }}>
          <div className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {rows.map((row, index) => (
                      <TableCell
                        key={`${row.id}-${index}`}
                        numeric={row.numeric}
                        padding={row.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === row.id ? order : false}
                        style={{ fontWeight: '550' }}
                      >
                        <Tooltip
                          title={row?.id === 'thumbnail' ? 'Thumbnail' : 'Ordenar'}
                          placement={row?.numeric ? 'bottom-end' : 'bottom-start'}
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={
                              row.id === 'thumbnail' ? null : () => handleRequestSort(row.id)
                            }
                            style={{ cursor: row.id === 'thumbnail' ? 'default' : 'pointer' }}
                          >
                            {row?.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stableSort(promotionList, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n, i) => {
                      let pathname = null;
                      const userAdmin = getUserInfo().admin;

                      if (isAdminPage() && adminToggle) {
                        pathname = n.hasGroup
                          ? `/admin/estabelecimento/${establishmentId}/ofertas/promocaoAgrupada/edit/${n.key}`
                          : n.type === 'businessPartner'
                          ? `/admin/estabelecimento/${establishmentId}/promocaoexterna/${n.id}/editar/`
                          : `/admin/estabelecimento/${establishmentId}/ofertas/promocao/edit/${n.key}`;
                      } else {
                        pathname = n.hasGroup
                          ? `/estabelecimento/${establishmentId}/promocaofidelidade/editar/`
                          : n.type === 'businessPartner'
                          ? `/estabelecimento/${establishmentId}/promocaoexterna/${n.id}/editar/`
                          : `/estabelecimento/${establishmentId}/promocao/${n.id}/editar/`;
                        localStorage.setItem('fromWherePromotion', 'estabelecimento-promo');
                      }

                      return (
                        <TableRow
                          id={n.key}
                          onClick={() => setPagePosition(n.key, i, n)}
                          hover
                          key={`${n.key}-${i}`}
                        >
                          <TableCell
                            scope="row"
                            padding="none"
                            folder="estabelecimento"
                            onClick={() => {
                              if (userAdmin && n.aprovacao?.status === 'aberto') {
                                toast.error(
                                  'Não é possível acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                );
                              } else {
                                history.push({
                                  pathname,
                                  state: {
                                    ...n,
                                    dataEstabelecimento,
                                  },
                                });
                              }
                            }}
                            tabIndex={-1}
                          >
                            {n.downloadedThumb ? (
                              <img
                                onLoad={() =>
                                  downloadImage(n.thumbnail, n.key, n.downloadedThumb, n.type)
                                }
                                src={n.downloadedThumb}
                                alt="Thumb da Oferta"
                                className="thumbnail"
                                width="65px"
                              />
                            ) : (
                              'loading...'
                            )}
                          </TableCell>

                          <TableCell
                            scope="row"
                            onClick={() => {
                              if (userAdmin && n.aprovacao?.status === 'aberto') {
                                toast.error(
                                  'Não é possível acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                );
                              } else {
                                history.push({
                                  pathname,
                                  state: {
                                    ...n,
                                    dataEstabelecimento,
                                  },
                                });
                              }
                            }}
                          >
                            {/* {n.edicao ? n.edicao.changedFields.title : n.title} */}
                            {n.edicao && n.edicao.changedFields
                              ? n.edicao.changedFields.title
                              : n.title}
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              if (userAdmin && n.aprovacao?.status === 'aberto') {
                                toast.error(
                                  'Não é possível acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                );
                              } else {
                                history.push({
                                  pathname,
                                  state: {
                                    ...n,
                                    dataEstabelecimento,
                                  },
                                });
                              }
                            }}
                          >
                            {n.hasGroup
                              ? 'Fidelidade'
                              : n.type === 'promotion'
                              ? 'Simples'
                              : n.type === 'product'
                              ? 'Vitriine'
                              : n.type === 'businessPartner'
                              ? 'Site externo'
                              : null}
                          </TableCell>

                          <TableCell
                            scope="row"
                            onClick={() => {
                              if (userAdmin && n.aprovacao?.status === 'aberto') {
                                toast.error(
                                  'Não é possível acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                );
                              } else {
                                history.push({
                                  pathname,
                                  state: {
                                    ...n,
                                    dataEstabelecimento,
                                  },
                                });
                              }
                            }}
                          >
                            {(() => {
                              switch (true) {
                                case n.aprovacao && n.aprovacao.status === 'negado':
                                  return 'Reprovado';
                                case n.aprovacao && n.aprovacao.status === 'aberto':
                                  return 'Em Revisão';
                                case n.isPublished === true &&
                                  n.enableDate <= Date.now() &&
                                  n.disableDate >= Date.now():
                                  return 'Ativo';
                                default:
                                  return 'Inativo';
                              }
                            })()}
                          </TableCell>

                          <TableCell
                            scope="row"
                            align="right"
                            style={{ width: '10%', whiteSpace: 'nowrap' }}
                          >
                            <Tooltip title="Gerar cupom">
                              <span>
                                <Button
                                  style={{
                                    margin: '0 5px',
                                    fontWeight: '550',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => handleGetVoucherTemplate(n, history)}
                                  disabled={
                                    n?.type !== 'promotion'
                                      ? true
                                      : n?.aprovacao?.status === 'negado'
                                      ? true
                                      : n?.aprovacao?.status === 'aberto'
                                      ? true
                                      : n?.isPublished === true &&
                                        n?.enableDate <= Date.now() &&
                                        n?.disableDate >= Date.now()
                                      ? false
                                      : true
                                  }
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                >
                                  <RiCoupon2Line fontSize="22px" />
                                </Button>
                              </span>
                            </Tooltip>

                            {openDialog && (
                              <Dialog
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', boxShadow: 'none' }}
                              >
                                <DialogContent>
                                  <DialogContentText
                                    id="alert-dialog-description"
                                    sx={{
                                      height: 'max-content',
                                      paddingLeft: '10px',
                                      paddingRight: '15px',
                                      marginTop: '10px',
                                    }}
                                  >
                                    <b>ATENÇÃO:</b> Não é possível gerar lotes para promoções
                                    ilimitadas!
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => setOpenDialog(false)}>Fechar</Button>
                                </DialogActions>
                              </Dialog>
                            )}

                            <Tooltip title="Gerar QRCode">
                              <span>
                                <Button
                                  onClick={() => {
                                    history.push({
                                      pathname: 'promocoes/qrcode/generate',
                                      state: {
                                        ...n,
                                        dataEstabelecimento,
                                      },
                                    });
                                  }}
                                  style={{
                                    margin: '0 5px',
                                    fontWeight: '550',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                  }}
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                >
                                  <ImQrcode fontSize="22px" />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title="Enviar Cupom">
                              <span>
                                <Button
                                  onClick={() => {
                                    localStorage.setItem('statePromotion', JSON.stringify(n));
                                    toggleSendVoucherModal(true, n);
                                  }}
                                  disabled={
                                    n.isPublished === true &&
                                    n.enableDate <= Date.now() &&
                                    n.disableDate >= Date.now() &&
                                    (n.type === 'promotion' || n.type === 'product')
                                      ? false
                                      : true
                                  }
                                  style={{
                                    margin: '0 5px',
                                    fontWeight: '550',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                  }}
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                >
                                  <FiSend fontSize="22px" />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title="Duplicar Promoção">
                              <span>
                                <Button
                                  onClick={() => {
                                    const dsbButton = [...disabledButtons];
                                    dsbButton[i] = true;
                                    setDisabledButtons(dsbButton);
                                    duplicatePromotion(n.key);
                                  }}
                                  disabled={disabledButtons[i] === true}
                                  style={{
                                    margin: '0 5px',
                                    fontWeight: '550',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                  }}
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                >
                                  <MdContentCopy fontSize="22px" />
                                </Button>
                              </span>
                            </Tooltip>
                          </TableCell>

                          <TableCell scope="row">
                            {(() => {
                              switch (true) {
                                case n.aprovacao && n.aprovacao.status === 'negado':
                                  return (
                                    <Tooltip title="Reprovado" placement="top">
                                      <Error style={{ color: 'red' }} />
                                    </Tooltip>
                                  );
                                case n.aprovacao && n.aprovacao.status === 'aberto':
                                  return (
                                    <Tooltip title="Em Revisão" placement="top">
                                      <WatchLater style={{ color: '#08bad3' }} />
                                    </Tooltip>
                                  );
                                case n.isPublished === true &&
                                  n.enableDate <= Date.now() &&
                                  n.disableDate >= Date.now():
                                  return (
                                    <Tooltip title="Ativo" placement="top">
                                      <CheckCircle style={{ color: 'lime' }} />
                                    </Tooltip>
                                  );
                                default:
                                  return (
                                    <Tooltip title="Inativo" placement="top">
                                      <EventBusyRoundedIcon style={{ color: '#aaa' }} />
                                    </Tooltip>
                                  );
                              }
                            })()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>

            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={promotionList.length > 9 ? promotionList.length + 1 : promotionList.length} // Contagem de registros
              rowsPerPage={10} // O size está sempre fixo em 10
              page={page} // Página atual
              onPageChange={handleChangePage} // Atualiza a página corretamente
              // labelRowsPerPage="Promoções por página:" // Traduz o "Rows per page:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              } // Traduz a paginação em ingles
            />
          </div>
        </div>
      )}
    />
  );
};

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataTable);
