import React from "react";
import * as D from "js/components/DesignSystem/styles";
import { Link } from "react-router-dom";

export function StyledButton(props) {
  const { value, index, url, onClick } = props;

  //Array de escolha de cores para o StyledButton
  const cor = ["#08BAD0", "#6E3296", "#6c63ff"];

  let newColor = cor[index % cor.length];

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      to={url}
      onClick={handleClick}
      style={{ textDecoration: "none", width: "100%" }}
    >
      <D.CardButton newColor={newColor}>{value}</D.CardButton>
    </Link>
  );
}
