import React, { useState } from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import Banner from '../ModelsComponents/Banner';
import Highlights from '../ModelsComponents/Highlights';
import Carousels from '../ModelsComponents/Carousels';
import GoBackRow from 'js/components/DesignSystem/GoBackRow';

export default function CreateModels() {
  const [currentModel, setCurrentModel] = useState('highlights');

  const ConditionalRendering = () => {
    const renderModels = {
      banner: <Banner />,
      highlights: <Highlights />,
      carousels: <Carousels />,
    };

    return renderModels[currentModel];
  };

  return (
    <S.Container 
     style={{padding:'4rem'}}
    >
      <GoBackRow />
      <D.TriiboH1>Criar Módulos</D.TriiboH1>

      <S.Header>
        <PrimaryButton
          bgColor={currentModel === 'banner' && '#6e3296'}
          onClick={() => setCurrentModel('banner')}>
          Banner
        </PrimaryButton>
        <PrimaryButton
          bgColor={currentModel === 'highlights' && '#6e3296'}
          onClick={() => setCurrentModel('highlights')}>
          Destaque
        </PrimaryButton>
        <PrimaryButton
          bgColor={currentModel === 'carousels' && '#6e3296'}
          onClick={() => setCurrentModel('carousels')}>
          Carrosséis
        </PrimaryButton>
      </S.Header>

      <S.Content>{ConditionalRendering()}</S.Content>
    </S.Container>
  );
}
