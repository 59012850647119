import styled from "styled-components";

export const ActionsTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  border-top: 1px solid #707070;
  padding-top: 1rem;
`;

export const ActionButtonTable = styled.button`
  width: 200px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;

  &:hover {
    h5 {
      font-weight: 600;
    }
  }
`;
