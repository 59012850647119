import { Table, TableContainer, TableHead } from "@mui/material";
import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const TbContainer = styled(TableContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TbContent = styled(Table)`
  min-width: 300px;
  max-width: 1550px;
`;

export const SearchText = styled.h3`
  color: #06bad0;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

export const BoxSearchInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  margin-bottom: 2rem;

  height: 42px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;

  .searchIcon {
    button {
      background: none;
      border-left: 1px solid #ccc;
      border-radius: 0;
      border-right: none;
      border-top: none;
      border-bottom: none;
      padding: 0 1rem;

      img {
        width: 24px;
      }
    }
  }
`;

export const Adornment = styled.div`
  margin-left: 1rem;
  color: #383838;

  select {
    font-family: NotoColorEmojiLimited, Source Sans Pro, sans-serif;
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.1rem;
    color: #383838d6;
    border-radius: 0.7rem;
    cursor: pointer;
  }

  option {
    font-family: NotoColorEmojiLimited, Source Sans Pro, sans-serif;
  }
`;

export const InputSearch = styled(ReactInputMask)`
  flex: 1;
  padding-left: 1rem;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #383838d6;
  border-radius: 10px;
`;

export const TableHeaderMui5 = styled(TableHead)`
  background-color: #fafafa;
  font-weight: bold;
`;

export const DeleteButtonContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const DeleteUserButton = styled.button`
  display: block;
  width: 2rem;
  height: 2rem;
  background: red;
  border-radius: 4px;
  margin-right: 0.3rem;
  border: 0;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50vh;
  width: 100%;
`;
