const defaultState = {
  establishmentList: [],
  superUser: null,
  openDialogQuit: false,
  open: false,
  lastUpdate: Date.now(),
  cellPhone: '',
  checkLogin: '',
  authenticated: false,
  adminMenu: false,
  loading: true,
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'AUTH_VERIFY': {
      if (action.payload.checkLogin === 'deslogado') {
        let tempState = { ...state };

        tempState.checkLogin = '';

        return tempState;
      }

      let tempState = { ...state, ...action.payload };

      return tempState;
    }
    case 'ESTABLISHMENT_REGISTER': {
      if (action.payload.checkLogin === 'deslogado') {
        let tempState = { ...state };

        tempState.checkLogin = 'deslogado';

        return tempState;
      }
      let tempState = { ...state, ...action.payload };

      return tempState;
    }
    case 'LOGOUT': {
      return { ...state, authenticated: false };
    }

    default:
      return state;
  }
}

export default reducer;
