import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { getFeedContent } from 'js/library/utils/API/apiGetFeedContent';
import { deleteFeedContent } from 'js/library/utils/API/apiDeleteFeedContent';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { TableTD, TableTH } from 'js/components/DesignSystem/Table/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { convertToDateDefault } from 'js/library/utils/helpers';
import { Link } from 'react-router-dom';
import { BsTrash2Fill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import GoBackRow from 'js/components/DesignSystem/GoBackRow';

function removeElementById(array, id) {
  return array.filter((obj) => obj.id !== id);
}

const ListModules = () => {
  const [loadingPage, setLoadingPage] = useState(true);
  const history = useHistory();
  const [feed, setFeed] = useState([]);
  const [currentQuery, setCurrentQuery] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState('');

  useEffect(() => {
    setLoadingPage(true);
    getFeedContent(currentQuery)
      .then((res) => {
        const responseFeed = res.response ? res.response.feed : [];
        const untrackFeed = res.response.untrack ? res.response.untrack : [];
        let filteredFeed = responseFeed.concat(untrackFeed);
        filteredFeed.map((object) => {
          if (object.status) {
            object.activeDate = 'Ativo';
          } else if (Date.now() > object.enableDate && Date.now() < object.disableDate) {
            object.activeDate = 'Válido';
          } else {
            object.activeDate = 'Expirado';
          }
          object.enableDate = convertToDateDefault(object.updateDate);
          object.disableDate = convertToDateDefault(object.disableDate);
          return object;
        });

        // const sendDate = convertToDateDefault(notification.updateDate);
        setFeed(filteredFeed);
        setLoadingPage(false);
      })
      .catch(() => {
        setLoadingPage(false);
      });
  }, [currentQuery]);

  const column = [
    { heading: 'Tracker', value: 'trackingId' },
    { heading: 'Tipo', value: 'type' },
    { heading: 'Início', value: 'enableDate' },
    { heading: 'Vencimento', value: 'disableDate' },
    { heading: 'Status', value: 'activeDate' },
  ];

  const redirectToPage = () => {
    history.push('/admin/configuracoes/configHomeMapfre/adicionarModulos');
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <TableTH>
          <S.ButtonsContainer>
            <S.AddNotificationButton style={{ marginLeft: 'auto' }} onClick={redirectToPage}>
              <span>+</span> Adicionar módulo
            </S.AddNotificationButton>
            <S.AddNotificationButton
              style={{ marginLeft: 'auto' }}
              onClick={() => setCurrentQuery('')}
              active={currentQuery === '' ? true : false}
            >
              {'Mostrar ativos'}
            </S.AddNotificationButton>
            <S.AddNotificationButton
              style={{ marginLeft: 'auto' }}
              onClick={() => setCurrentQuery('all')}
              active={currentQuery === 'all' ? true : false}
            >
              {'Mostrar todos'}
            </S.AddNotificationButton>
            <S.AddNotificationButton
              style={{ marginLeft: 'auto' }}
              onClick={() => setCurrentQuery('inactive')}
              active={currentQuery === 'inactive' ? true : false}
            >
              {'Mostrar válidos e ativos'}
            </S.AddNotificationButton>
          </S.ButtonsContainer>
        </TableTH>
      ),
      childrenComponent: (
        <>
          {childrens ? (
            <TableTD style={{ textAlign: 'right' }}>
              <S.EditOrDeleteCOntainer>
                <Link
                  to={{
                    pathname: '/admin/configuracoes/configHomeMapfre/editarModulos/' + childrens.id,
                    state: childrens,
                  }}
                >
                  <S.StatusButton>Editar módulo</S.StatusButton>
                </Link>
                <S.StatusButton
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentId(childrens.id);
                    setModalIsOpen(true);
                  }}
                >
                  <BsTrash2Fill />
                </S.StatusButton>
              </S.EditOrDeleteCOntainer>
            </TableTD>
          ) : (
            <></>
          )}
        </>
      ),
    };
  };

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <>
      <Dialog
        open={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
      >
        <S.DialogueContentsContainer>
          <S.DialogueTitle>Deseja apagar o módulo?</S.DialogueTitle>
          <S.DialogueButtonContainer>
            <D.TriiboFilledButton
              blue={true}
              onClick={() => {
                deleteFeedContent(currentId).then(() => {
                  let newArr = removeElementById(cloneDeep(feed), currentId);
                  setFeed(newArr);
                  toast.success('Apagado com sucesso!');
                  setModalIsOpen(false);
                });
              }}
            >
              Sim
            </D.TriiboFilledButton>
            <D.TriiboFilledButton
              black={true}
              onClick={() => {
                setModalIsOpen(!modalIsOpen);
              }}
            >
              Retornar
            </D.TriiboFilledButton>
          </S.DialogueButtonContainer>
        </S.DialogueContentsContainer>
      </Dialog>
      <S.Container>
        <GoBackRow />
        <S.Header>
          <D.TriiboH1>Listagem de módulos</D.TriiboH1>
        </S.Header>
        {/* 
      <S.SearchBox>
        <InputSearch
          value={inputSearchNotification}
          onChange={handleChangeInputSearchNotification}
          disabled={false}
          placeholder="Título do módulo"
        />
      </S.SearchBox> */}

        <CustomButtonsTable
          data={feed}
          perPage={10}
          column={column}
          componentReceiver={componentReceiver}
        />
      </S.Container>
    </>
  );
};

export default ListModules;
