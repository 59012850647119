import React, { useState } from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from '../styles';
import { IoIosWarning } from 'react-icons/io';
import { convertToDateDefault } from 'js/library/utils/helpers';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

const FormStepThree = ({
  formState,
  newFormState,
  finalFormState,
  disableDateField,
  handleClickBtnEdit,
}) => {
  const date = convertToDateDefault(finalFormState.disableDate);
  const [countryID, setCountryID] = useState(0);

  return (
    <S.CardContainer white>
      <S.TagsLabelContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <S.TagsBigLabel>Tags do seu negócio</S.TagsBigLabel>
          {JSON.stringify(newFormState.tags) !== JSON.stringify(formState.tags) && (
            <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
          )}
        </div>
      </S.TagsLabelContainer>

      <S.TagsContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'stretch',
            gap: '5px',
          }}
        >
          <D.TriiboTagsField>
            {finalFormState.tags.map((body, index) => (
              <D.UserTags key={index}>
                <D.TagText key={body[index]}>{body}</D.TagText>
              </D.UserTags>
            ))}
          </D.TriiboTagsField>
          <S.MoveIconMobile left>
            <S.Icon $purple={true} onClick={(e) => handleClickBtnEdit(e, 'tags')} size={24} />
          </S.MoveIconMobile>
        </div>
      </S.TagsContainer>

      <S.FormElement>
        <S.TagsLabelContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <S.TagsBigLabel>Data de desativação</S.TagsBigLabel>
            {newFormState.disableDate !== formState.disableDate && (
              <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
            )}
          </div>
        </S.TagsLabelContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <D.TriiboTextField
            disabled
            rows={1}
            placeholder={'00/00/0000.'}
            value={
              finalFormState.disableDate
                ? date
                : ('0' + disableDateField.getDate()).slice(-2) +
                  '/' +
                  ('0' + (disableDateField.getMonth() + 1)).slice(-2) +
                  '/' +
                  disableDateField.getFullYear()
            }
            style={{ textTransform: 'lowercase' }}
          />
          <S.MoveIconMobile left>
            <S.Icon $purple={true} onClick={(e) => handleClickBtnEdit(e, 'Data')} size={24} />
          </S.MoveIconMobile>
        </div>
      </S.FormElement>
      <S.FormElement>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <S.FormLabel black>Email de atendimento*</S.FormLabel>
          {newFormState.email !== formState.email && (
            <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
          )}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <D.TriiboTextField
            disabled
            rows={1}
            placeholder={'Visível apenas para a equipe Triibo.'}
            value={finalFormState.email}
            style={{ textTransform: 'lowercase' }}
          />
          <S.MoveIconMobile left>
            <S.Icon $purple={true} onClick={(e) => handleClickBtnEdit(e, 'email')} size={24} />
          </S.MoveIconMobile>
        </div>
      </S.FormElement>
      <S.FormElement>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <S.FormLabel black>Site</S.FormLabel>
          {newFormState.site !== formState.site && (
            <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
          )}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <D.TriiboTextField
            disabled
            rows={1}
            placeholder={'https://triibo.com.br'}
            value={finalFormState.site}
            style={{ textTransform: 'lowercase' }}
          />
          <S.MoveIconMobile left>
            <S.Icon $purple={true} onClick={(e) => handleClickBtnEdit(e, 'site')} size={24} />
          </S.MoveIconMobile>
        </div>
      </S.FormElement>
      <S.FormElement>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <S.FormLabel black={true}>Telefone Privado*</S.FormLabel>
          {newFormState.contact[0] !== formState.contact[0] && (
            <IoIosWarning color="#ecdd0a" size={22} style={{ marginLeft: '5px' }} />
          )}
        </div>
        <S.TagsSmallLabel>
          Apenas a equipe Triibo terá acesso, será utilizado para suporte
        </S.TagsSmallLabel>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <S.contactFieldMask>
            <S.PlaceholderContainer>
              <S.ContactCountryField
                isPhone={true}
                value={countryID}
                onChange={(e) => {
                  e.preventDefault();
                  setCountryID(e.target.value);
                }}
              >
                {phoneMaskArray.map(({ initials, emoji, ddd, id }, index) => {
                  return (
                    <S.Options key={id} value={id}>
                      {emoji} {initials} {ddd}
                    </S.Options>
                  );
                })}
              </S.ContactCountryField>
              <div style={{ padding: '0 5px' }}></div>
              <S.ContactTextField
                value={
                  finalFormState.contatos && finalFormState.contatos.length > 0
                    ? finalFormState.contatos[0].replace('+55', '')
                    : finalFormState.contatos
                }
                mask={phoneMaskArray.filter((e) => e.id === parseInt(countryID))[0].mask}
              />
            </S.PlaceholderContainer>
          </S.contactFieldMask>
          <S.MoveIconMobile left>
            <S.Icon
              $purple={true}
              onClick={(e) => handleClickBtnEdit(e, 'Telefone Privado')}
              size={24}
            />
          </S.MoveIconMobile>
        </div>
      </S.FormElement>
    </S.CardContainer>
  );
};

export default FormStepThree;

