import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { getUserInfo } from '../../helpers';
import cfac22 from '../../cfac22';

export default function deleteAdminEstablishment(admin, establishmentId) {
  return new Promise((resolve, reject) => {
    if (establishmentId !== null) {
      const userInfo = getUserInfo();

      authToken(userInfo.uId)
        .then((token) => {
          const payload = {
            admin: {
              cargo: admin.cargo,
              nome: admin.nome,
              telefone: admin.telefone,
              uid: admin.uid,
            },
            establishmentId: establishmentId,
          };

          axios
            .delete(`${cfac22('API_HOST_V2')}/establishments/admin/delete`, {
              data: payload,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((result) => {
              resolve(result.data.establishmentInfos);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject(new Error('Invalid establishmentId'));
    }
  });
}
