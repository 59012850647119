import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

import { findIndexInArray } from 'js/library/utils/helpers.js';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1.js';
import { LoginHistory } from '../../API/api_loginHistory';
import { loginUser } from 'js/library/services/AuthenticationManager.js';

export async function validateCode(code, cellPhone, transactionId) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        axios
          .post(
            cfac22('API_HOST_V2') + '/sms/validateSMS',
            {
              transactionId: transactionId,
              cellphone: cellPhone,
              code: code,
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            console.log('RESULTADO VALIDATE SMS ===>', result);

            //pesquisar usuário no banco
            const userInfo = {
              contactList: [
                {
                  type: 'cellPhone',
                  value: cellPhone,
                },
              ],
            };

            getUserInfo_v1(['getUserInfo'], userInfo)
              .then((result) => {
                const cellPhone =
                  userInfo.contactList[findIndexInArray(userInfo.contactList, 'type', 'cellPhone')]
                    .value;

                if (
                  result.data.success.userInfo === null ||
                  result.data.success.userInfo.triiboId === undefined
                ) {
                  window.pathname = '/signIn';

                  resolve({
                    cellPhone: cellPhone,
                    userInfo: null,
                    error: null,
                  });
                } else {
                  loginUser(
                    result.data.success.userInfo.triiboId.replace(/[,]/gi, '.'),
                    result.data.success.userInfo.passPhrase
                  )
                    .then(async function () {
                      await LoginHistory({
                        tag: 'LOGIN_WEBADM',
                        uid: result.data.success.userInfo.uId,
                      });

                      resolve({
                        cellPhone: cellPhone,
                        userInfo: result.data.success.userInfo,
                        error: null,
                      });

                      window.pathname = '/admin';
                    })
                    .catch((error) => {
                      // console.log('ERROR LOGIN FIREBASE ===>', error);
                      reject(error);
                    });
                }
              })
              .catch((error) => {
                //console.log('ERROR VALIDATE SMS ===>', error);
                reject(error);
              });
          })
          .catch((error) => {
            //console.log('ERROR VALIDATE SMS ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
