import React from 'react';
import * as D from 'js/components/DesignSystem/styles';
import * as S from '../styles';
import { IoIosWarning } from 'react-icons/io';

const FormStepOne = ({
  formState,
  newFormState,
  finalFormState,
  handleClickBtnEdit,
}) => {
  return (
    <S.CardContainer>
      <S.FormElement>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <S.FormLabel>Nome do negócio*</S.FormLabel>
          {newFormState.nome !== formState.nome && (
            <IoIosWarning
              color="#ecdd0a"
              size={22}
              style={{ marginLeft: '5px' }}
            />
          )}
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <D.TriiboTextField
            disabled
            rows={1}
            placeholder={'Nome do negócio*'}
            maxLength="40"
            value={finalFormState.nome}
          />
          <S.MoveIconMobile>
            <S.Icon
              onClick={(e) => handleClickBtnEdit(e, 'nome')}
              size={24}
              style={{ marginLeft: '0.5rem' }}
            />
          </S.MoveIconMobile>
        </div>
      </S.FormElement>
      <S.FormElement>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <S.FormLabel>Descrição do negócio*</S.FormLabel>
          {newFormState.descricao !== formState.descricao && (
            <IoIosWarning
              color="#ecdd0a"
              size={22}
              style={{ marginLeft: '5px' }}
            />
          )}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <S.TextCountContainer>
            <D.TriiboTextField
              disabled
              rows={13}
              placeholder={'Descrição do negócio*'}
              maxLength="600"
              value={finalFormState.descricao}
            />
            <S.TextCounter>
              {finalFormState.descricao.length} {'/600'}
            </S.TextCounter>
          </S.TextCountContainer>
          <S.MoveIconMobile description={true}>
            <S.Icon
              onClick={(e) => handleClickBtnEdit(e, 'descricao')}
              size={24}
              style={{ marginLeft: '0.5rem' }}
            />
          </S.MoveIconMobile>
        </div>
      </S.FormElement>
    </S.CardContainer>
  );
};

export default FormStepOne;
