import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import * as S from './style';

import ArrowButton from './ArrowButton';
import UserMenu from './UserMenu';
import AdminMenu from './AdminMenu';
import ExitMenu from './ExitMenu';

const Sidebar = (props) => {
    const { state } = props;
    const [open, setOpen] = React.useState(false);
    // const [isPremium, setIsPremium] = React.useState(false);
    const [modalStatus, setModalStatus] = React.useState(false);
    let superUser = useSelector((state) => state.authModel.superUser);
    const [isAdmin, setIsAdmin] = React.useState(superUser);
    let currentEst = useSelector((state) => state.currentEstModel.currentEst);

    const establishmentArr = state.establishmentList;

    const [switchText, setSwitchText] = React.useState('');

    useEffect(() => {

        if (superUser === true) {
            setSwitchText('trocar para usuário');
        } else {
            setSwitchText('trocar para administrador');
        }
        
    }, []);

    return (
        <>
            <S.Drawer open={open} >
                <S.MenuHolder>1</S.MenuHolder>

                <S.MenuHolder>
                   
                    <ArrowButton open={open} setOpen={setOpen} />
                    {isAdmin ? 
                        <AdminMenu open={open} setOpen={setOpen} state={state} currentEst={currentEst} establishmentArr={establishmentArr} />
                        :
                        <UserMenu open={open} setOpen={setOpen} state={state} currentEst={currentEst} establishmentArr={establishmentArr} />
                    }
                    
                    
                    
                </S.MenuHolder>
                <S.MenuHolder>
                    <ExitMenu open={open} setOpen={setOpen} 
                        modalStatus={modalStatus} setModalStatus={setModalStatus} 
                        superUser={superUser} 
                        setIsAdmin={setIsAdmin} isAdmin={isAdmin}
                        switchText={switchText} setSwitchText={setSwitchText} />
                </S.MenuHolder>
            </S.Drawer>
        </>
    );
};

export default Sidebar;