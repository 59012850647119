import React from 'react';
import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';

import { FaRegImage } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 75%;
  background-color: transparent;
  /* border: 1px solid ${D.triiboGray}; */
  /* border-radius: 10px; */
`;

const ImgContainer = styled.span`
  /* z-index: -1; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(234, 239, 242, 1);
  /* background: transparent; */
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ srcImg }) =>
    srcImg &&
    `
    display: none;
    `}
`;

const Img = styled.img.attrs((props) => ({
  alt: props.Alt || 'imagem do usuário',
}))`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: all 1s ease-in-out;
`;

const ImageDisplay = (props) => {
  const { srcImg } = props;

  return (
    <>
      <Container>
        {srcImg ? <Img src={srcImg} /> : <></>}
        <ImgContainer>
          <FaRegImage
            style={{
              display: 'block',
              color: 'rgba(187, 191, 194, 1)',
              width: '50%',
              height: '50%',
            }}
          />
        </ImgContainer>
      </Container>
    </>
  );
};

export default ImageDisplay;
