import React from 'react';

// COMPONENTES
import DataTablePoints from 'js/components/Configurations/PointsManagement/DataTablePoints';

export default function PointsEstablishment(props) {
  const establishmentId = props.match.params.id;

  return <DataTablePoints establishmentId={establishmentId} />;
}
