import styled from "styled-components";

export const SwitchView = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  padding: 0.5rem;

  h3 {
    color: #5a287c;
  }
`;
