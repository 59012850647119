import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { store } from 'js/core/configureStore';

import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

import { getVoucherGroup } from 'js/library/utils/API/getVoucherGroup';

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Typography,
  FormGroup,
  Switch,
  FormLabel,
  FormControlLabel,
  FormControl,
  MenuItem,
  Grid,
  Modal,
  Snackbar,
  Tooltip,
  Badge,
  colors,
  ThemeProvider,
  RadioGroup,
  Radio,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';

import { Alert } from '@mui/lab';
import {
  ArrowBack,
  AddPhotoAlternate,
  Help,
  Check,
  Clear,
  Close,
  FileCopy,
} from '@mui/icons-material';
import * as S from 'js/components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { BsXLg } from 'react-icons/bs';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CropFreeIcon from '@mui/icons-material/CropFree';

import { Cropper } from 'react-image-cropper';
import PropTypes from 'prop-types';

import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import Loading from 'js/containers/Loading/Loading';
import { updateStoreGroupInformationAction } from 'js/core/actions/storeGroupInformationActions';
import { downloadImage } from 'js/library/services/StorageManager';
import { verificaPerfil } from 'js/library/services/AuthenticationManager';
import { msToTime } from 'js/library/utils/helpers.js';
import { getUserInfo, findValueInArray, formatDate, isAdminPage } from 'js/library/utils/helpers';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';

import { verificarAprovacaoPromocao } from 'js/library/services/DatabaseManager';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

// Estilo da notificação - Badge
const StyledBadge = styled(Badge)({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',

    '&:hover': {
      backgroundColor: colors['red'][700],
      color: 'white',
      cursor: 'pointer',
      border: 'none',
    },
  },
});

const StyledBadge2 = styled(Badge)({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',
  },
});

const ErrorButton = styled(Button)({
  root: {
    color: colors['red'][500],
    backgroundColor: colors['red'][500],
    '&:hover': {
      backgroundColor: colors['red'][700],
    },
  },
});

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

// Estilo da mensagem de aprovado/rejeitado - Alert
const StyledAlert = styled(Alert)({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: '6px 50px',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '25px',
    textAlignLast: 'center',
  },
});

class StoreGroupExternal extends Component {
  constructor(props, context) {
    super(props, context);

    const dataEstabelecimento = props.location.state.dataEstabelecimento; //? props.location.state.dataEstabelecimento : store.getState().establishmentInformationModel;

    const establishmentListInfos = store.getState().authModel.establishmentList;

    this.dayMs = 86400000;
    this.responseDays = 14; //Tempo de espera para aprovação em dias

    //convertendo enable date
    let enableDate = new Date(Date.now());
    if (props.location.state.enableDate !== undefined) {
      enableDate = new Date(props.location.state.enableDate);
    }

    const enableYear = enableDate.getFullYear();
    const enableMonth =
      enableDate.getMonth() + 1 < 10
        ? '0' + (enableDate.getMonth() + 1)
        : enableDate.getMonth() + 1;
    const enableDay = enableDate.getDate() < 10 ? '0' + enableDate.getDate() : enableDate.getDate();
    const enableHour =
      enableDate.getHours() < 10 ? '0' + enableDate.getHours() : enableDate.getHours();
    const enableMinute =
      enableDate.getMinutes() < 10 ? '0' + enableDate.getMinutes() : enableDate.getMinutes();

    const templates =
      props.location.state.key === undefined
        ? [
            {
              id:
                props.location.state.templateId === undefined
                  ? firebaseDatabase.ref('Voucher-Template-v2').push().key
                  : props.location.state.templateId,
              titulo: null,
              campanhaTriibo: false,
              consumoUnico: false,
              cooldown: 0,
              cooldownDays: 0,
              cooldownHours: 0,
              cooldownMinutes: 0,
              dataGeracao: null,
              generator: true,
              nomeTemplate: null,
              qtdDisponivel: null,
              hasSimultaneo: false,
              qtdSimultaneo: 1,
              type: 'store',
              value: 1,
            },
          ]
        : [{}];

    enableDate =
      enableYear + '-' + enableMonth + '-' + enableDay + 'T' + enableHour + ':' + enableMinute;

    //convertendo disable date
    let disableDate = new Date(Date.now() + 86400000 * 365);
    if (props.location.state.disableDate !== undefined) {
      disableDate = new Date(props.location.state.disableDate);
    }

    const disableYear = disableDate.getFullYear();
    const disableMonth =
      disableDate.getMonth() + 1 < 10
        ? '0' + (disableDate.getMonth() + 1)
        : disableDate.getMonth() + 1;
    const disableDay =
      disableDate.getDate() < 10 ? '0' + disableDate.getDate() : disableDate.getDate();
    const disableHour =
      disableDate.getHours() < 10 ? '0' + disableDate.getHours() : disableDate.getHours();
    const disableMinute =
      disableDate.getMinutes() < 10 ? '0' + disableDate.getMinutes() : disableDate.getMinutes();

    disableDate =
      disableYear + '-' + disableMonth + '-' + disableDay + 'T' + disableHour + ':' + disableMinute;

    this.state = {
      //dados do estabelecimento
      idEstabelecimento: props.match.params.id,
      idGestor:
        dataEstabelecimento !== undefined && dataEstabelecimento.idGestor !== undefined
          ? dataEstabelecimento.idGestor
          : null,
      nomeEstabelecimento:
        dataEstabelecimento !== undefined && dataEstabelecimento.nome !== undefined
          ? dataEstabelecimento.nome
          : props.location.state.establishmentName,
      lat:
        dataEstabelecimento !== undefined && dataEstabelecimento.lat !== undefined
          ? dataEstabelecimento.lat
          : null,
      long:
        dataEstabelecimento !== undefined && dataEstabelecimento.long !== undefined
          ? dataEstabelecimento.long
          : null,
      address:
        dataEstabelecimento.endereco === 'Brasil'
          ? dataEstabelecimento.endereco
          : `${dataEstabelecimento.logradouro}, ${dataEstabelecimento.numero} - ${dataEstabelecimento.bairro}, ${dataEstabelecimento.cidade} - ${dataEstabelecimento.estado}, `,
      neighborhood: dataEstabelecimento.bairro,

      dataEstabelecimento: dataEstabelecimento,

      //dados do template
      templates,
      copyTemplateMessage: 'Copiar ID do template',

      //dados do template	Counter Match
      templateCounterMatch: {
        id: null,
        titulo: null,
        detailsTitle: null,
        descricao: null,
        detailsDescription: null,
        plural: null,
        singular: null,
        consumoUnico: false,
        cooldown: 0,
        cooldownDays: 0,
        cooldownHours: 0,
        cooldownMinutes: 0,
        dataGeracao: null,
        generator: true,
        nomeTemplate: null,
        qtdDisponivel: null,
        hasSimultaneo: false,
        qtdSimultaneo: 1,
        type: 'store',
        disableDateHours: 0,
        disableDateDays: 0,
        disableDateMinutes: 0,
        hasDisableDate: false,

        equalImagePhotoFile: null,
        equalImageThumbnailFile: null,

        equalImagePhoto: false,
        equalImageThumbnail: false,

        thumbnail: null,
        foto: null,

        loadedThumbnail: null,
        loadedFoto: null,

        oldThumbnail: null,
        oldPhoto: null,

        fileFoto: null,
        fileThumbnail: null,

        oldPhotoList: null, // props.location.state.photoList == undefined ? null : props.location.state.photoList,
        photoListFile: null,
        loadedPhotoList: null,
      },

      sameInformation: false,

      //dados da store
      idStore:
        props.location.state.id === undefined
          ? props.location.state.key === undefined
            ? null
            : props.location.state.key
          : props.location.state.id,
      level: props.location.state.level === undefined ? 99 : props.location.state.level,
      region: props.location.state.region === undefined ? 'local' : props.location.state.region,
      description:
        props.location.state.description === undefined ? null : props.location.state.description,
      showQRCode:
        props.location.state.id === undefined
          ? true
          : props.location.state.showQRCode === undefined
          ? null
          : props.location.state.showQRCode,
      title: props.location.state.title === undefined ? null : props.location.state.title,
      storeType: props.location.state.type === undefined ? 'promotion' : props.location.state.type,
      autoValidate:
        props.location.state.id === undefined
          ? 'cliente'
          : props.location.state.autoValidate === true
          ? 'cliente'
          : 'estabelecimento',
      isPublished:
        props.location.state.isPublished === undefined ? false : props.location.state.isPublished,
      enableDate,
      disableDate,
      buttonLabel:
        props.location.state.buttonLabel === undefined ? '' : props.location.state.buttonLabel,
      buttonDescription:
        props.location.state.buttonDescription === undefined
          ? ''
          : props.location.state.buttonDescription,
      showButtonDescription:
        props.location.state.showButtonDescription === undefined
          ? false
          : props.location.state.showButtonDescription,
      goToPlaceLabel:
        props.location.state.goToPlaceLabel === undefined
          ? ''
          : props.location.state.goToPlaceLabel,
      showGoToPlace:
        props.location.state.showGoToPlace === undefined
          ? false
          : props.location.state.showGoToPlace,
      //se consumo for carteira ==>showQRCode: null
      //se consumo for promocao showQRCode: true; qntSimultanea:1
      consumo:
        props.location.state.id === undefined
          ? 'carteira'
          : props.location.state.showQRCode === undefined ||
            props.location.state.showQRCode === null
          ? 'carteira'
          : 'promocao',
      hasVoucher:
        props.location.state.hasVoucher === undefined ? null : props.location.state.hasVoucher,
      keywords: '',
      keywordsList:
        props.location.state.keyWordsSet === undefined ? [] : props.location.state.keyWordsSet,

      tagCapture: '',
      tags: props.location.state.tags === undefined ? [] : props.location.state.tags,

      storeThumbnail:
        props.location.state.thumbnail === undefined ? null : props.location.state.thumbnail,
      storePhoto: props.location.state.photo === undefined ? null : props.location.state.photo,

      loadedStoreThumbnail:
        props.location.state.downloadedThumb === undefined
          ? null
          : props.location.state.downloadedThumb,
      loadedStorePhoto: props.location.state.photo === undefined ? null : placeholder,

      oldStoreThumbnail:
        props.location.state.thumbnail === undefined ? null : props.location.state.thumbnail,
      oldStorePhoto: props.location.state.photo === undefined ? null : props.location.state.photo,

      fileStoreThumbnail: null,
      fileStorePhoto: null,

      //agrupamento
      idGroup: 'newGroup',
      state: 'ongoing',
      created: null,
      groupName: null,
      plural: null,
      singular: null,
      groupTitle: null,
      groupDetailsTitle: null,
      groupDescription: null,
      groupDetailsDescription: null,
      groupType: 'limitada',
      counterRoof: 10,

      foto: null,
      thumbnail: null,

      loadedFoto: null,
      loadedThumbnail: null,

      oldFoto: null,
      oldThumbnail: null,

      equalImageThumbnailFile: null,
      equalImagePhotoFile: null,

      fileFoto: null,
      fileThumbnail: null,

      //states de validação local
      oldGroup: null,
      templatesGroup: null,
      equalImageThumbnail: false,
      equalImagePhoto: false,
      open: false,
      openModal: false,
      goBack: false,
      indexCrop: 0,
      openAlert: false,
      messageAlert: '',
      expanded: null,

      voucherGroup: [{}],
      chosenGroup: null,
      loading: false,

      oldPhotoList:
        props.location.state.photoList === undefined ? null : props.location.state.photoList,
      photoListFile: null,
      loadedPhotoList: null,

      //mensagem de aprovação
      newStatus: null,
      messageApproval: '',

      //Botões de adicionar imagem
      disableButtonImageCarteira: false,
      disableButtonImagePremio: false,

      disableButtonUpdate: true,

      templatesLoaded:
        props.location.state.templateId || templates.find(({ id }) => !!id === true) ? true : false,

      modalStepIsOpen: false,
      establishmentListInfos: establishmentListInfos,
    };

    if (props.location.state.photoList !== undefined) {
      let photoListFile = [];

      Object.keys(props.location.state.photoList).map((item, i) =>
        downloadImage('promocao', props.location.state.photoList[i]).then((downloadedImage) => {
          photoListFile[i] = downloadedImage;
          this.setState({ photoListFile });
        })
      );
    }

    verificaPerfil(findValueInArray(getUserInfo().contactList, 'type', 'cellPhone').value).then(
      (result) => {
        this.setState({ superUser: result });
      }
    );

    const folderThumb =
      props.location.state.type === 'product' && props.location.state.thumbnail !== undefined
        ? props.location.state.thumbnail.split('/')[0]
        : 'promocao';

    const thumbImg =
      props.location.state.type === 'product' && props.location.state.thumbnail !== undefined
        ? props.location.state.thumbnail.split('/')[1]
        : props.location.state.thumbnail;

    const folderPhoto =
      props.location.state.type === 'product' && props.location.state.photo !== undefined
        ? props.location.state.photo.split('/')[0]
        : 'promocao';

    const photoImg =
      props.location.state.type === 'product' && props.location.state.photo !== undefined
        ? props.location.state.photo.split('/')[1]
        : props.location.state.photo;

    if (
      props.location.state.thumbnail !== undefined &&
      this.state.loadedStoreThumbnail === placeholderThumbnail
    ) {
      downloadImage(folderThumb, thumbImg)
        .then((downloadedImage) => {
          this.setState({ loadedStoreThumbnail: downloadedImage });
        })
        .catch(() => {
          // console.log("Foto não encontrada:", props.location.state.thumbnail);
        });
    }

    if (props.location.state.photo !== undefined) {
      downloadImage(folderPhoto, photoImg)
        .then((downloadedImage) => {
          this.setState({ loadedStorePhoto: downloadedImage });
        })
        .catch(() => {
          // console.log("Foto não encontrada:", props.location.state.photo);
        });
    }

    //baixando dados da Voucher-Template-v2, caso seja edição
    if (this.state.idStore !== null) {
      //baixando o Id da Voucher Group
      getVoucherTemplate(props.location.state.templateId)
        .then((dataReceived) => {
          let templates = [];

          //lidando com a informação baixada
          dataReceived.id = props.location.state.templateId;

          let cooldownTemplate = msToTime(dataReceived.cooldown);
          dataReceived.cooldownDays = cooldownTemplate.days;
          dataReceived.cooldownHours = cooldownTemplate.hours;
          dataReceived.cooldownMinutes = cooldownTemplate.minutes;

          dataReceived.hasSimultaneo = dataReceived.qtdSimultaneo === 1 ? false : true;
          dataReceived.nomeEstabelecimento = this.state.nomeEstabelecimento;

          //inserindo no array
          templates.push(dataReceived);
          this.setState({
            idGroup: dataReceived.groupId,
            oldGroup: dataReceived.groupId,
            templates,
            cooldownDays: cooldownTemplate.days,
            cooldownHours: cooldownTemplate.hours,
            cooldownMinutes: cooldownTemplate.minutes,
            titulo: dataReceived.titulo,
            descricao: dataReceived.descricao,
            detailsDescription: dataReceived.detailsDescription,
            idGestor: dataReceived.idGestor,
            lat: this.state.lat === null ? dataReceived.lat : this.state.lat,
            long: this.state.lat === null ? dataReceived.long : this.state.long,
            sameInformation:
              dataReceived.descricao === this.state.description &&
              dataReceived.titulo === this.state.title,
          });

          //baixando os dados da Voucher Group
          getVoucherGroup(dataReceived.result.groupId)
            .then((dataReceived) => {
              //console.log("DataReceived", dataReceived)
              if (dataReceived.thumbnail !== undefined) {
                this.setState({ loadedThumbnail: placeholder });
                downloadImage('voucher', dataReceived.thumbnail)
                  .then((downloadedImage) => {
                    this.setState({
                      oldThumbnail: dataReceived.thumbnail,
                      thumbnail: dataReceived.thumbnail,
                      loadedThumbnail: downloadedImage,
                    });
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }
              if (dataReceived.photo !== undefined) {
                this.setState({ loadedFoto: placeholder });
                downloadImage('voucher', dataReceived.photo)
                  .then((downloadedImage) => {
                    this.setState({
                      oldFoto: dataReceived.photo,
                      foto: dataReceived.photo,
                      loadedFoto: downloadedImage,
                    });
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.photo);
                  });
              }

              //baixando os dados do template counter match
              getVoucherTemplate(dataReceived.counterMatch)
                .then((dataReceived) => {
                  if (dataReceived.thumbnail !== undefined) {
                    dataReceived.loadedThumbnail = placeholder;
                    downloadImage('voucher', dataReceived.thumbnail)
                      .then((downloadedImage) => {
                        const templateCounterMatch = this.state.templateCounterMatch;
                        templateCounterMatch.oldThumbnail = dataReceived.thumbnail;
                        templateCounterMatch.loadedThumbnail = downloadedImage;

                        this.setState({
                          templateCounterMatch,
                        });
                      })
                      .catch(() => {
                        // console.log(
                        //   "Foto não encontrada:",
                        //   dataReceived.thumbnail
                        // );
                      });
                  }

                  if (dataReceived.foto !== undefined) {
                    dataReceived.loadedFoto = placeholder;
                    downloadImage('voucher', dataReceived.foto)
                      .then((downloadedImage) => {
                        const templateCounterMatch = this.state.templateCounterMatch;
                        templateCounterMatch.oldPhoto = dataReceived.foto;
                        templateCounterMatch.loadedFoto = downloadedImage;

                        this.setState({
                          templateCounterMatch,
                        });
                      })
                      .catch(() => {
                        // console.log(
                        //   "Foto não encontrada:",
                        //   dataReceived.thumbnail
                        // );
                      });
                  }

                  //
                  dataReceived.hasSimultaneo = dataReceived.qtdSimultaneo === 1 ? false : true;
                  dataReceived.hasDisableDate =
                    dataReceived.disableDate !== undefined && dataReceived.disableDate !== null
                      ? true
                      : false;

                  //convertendo disable date
                  let disableDateSeconds = dataReceived.disableDate / 100 / 36000;

                  disableDateSeconds = disableDateSeconds.toString().split('.');
                  dataReceived.disableDateHours =
                    disableDateSeconds[0] !== undefined &&
                    disableDateSeconds !== null &&
                    isNaN(disableDateSeconds) === false
                      ? parseInt(disableDateSeconds[0], 10)
                      : 0;
                  dataReceived.disableDateMinutes =
                    disableDateSeconds[1] !== undefined &&
                    disableDateSeconds !== null &&
                    isNaN(disableDateSeconds) === false
                      ? parseInt(disableDateSeconds[1], 10)
                      : 0;

                  let disableDateTemplateCounterMatch = msToTime(dataReceived.disableDate);
                  dataReceived.disableDateDays =
                    disableDateTemplateCounterMatch.days !== undefined &&
                    disableDateTemplateCounterMatch.days !== null &&
                    isNaN(disableDateTemplateCounterMatch.days) === false
                      ? parseInt(disableDateTemplateCounterMatch.days, 10)
                      : 0;
                  dataReceived.disableDateHours =
                    disableDateTemplateCounterMatch.hours !== undefined &&
                    disableDateTemplateCounterMatch.hours !== null &&
                    isNaN(disableDateTemplateCounterMatch.hours) === false
                      ? parseInt(disableDateTemplateCounterMatch.hours, 10)
                      : 0;
                  dataReceived.disableDateMinutes =
                    disableDateTemplateCounterMatch.minutes !== undefined &&
                    disableDateTemplateCounterMatch.minutes !== null &&
                    isNaN(disableDateTemplateCounterMatch.minutes) === false
                      ? parseInt(disableDateTemplateCounterMatch.minutes, 10)
                      : 0;

                  let cooldownTemplateCounterMatch = msToTime(dataReceived.cooldown);
                  dataReceived.cooldownDays =
                    cooldownTemplateCounterMatch.days !== undefined &&
                    cooldownTemplateCounterMatch.days !== null &&
                    isNaN(cooldownTemplateCounterMatch.days) === false
                      ? parseInt(cooldownTemplateCounterMatch.days, 10)
                      : 0;
                  dataReceived.cooldownHours =
                    cooldownTemplateCounterMatch.hours !== undefined &&
                    cooldownTemplateCounterMatch.hours !== null &&
                    isNaN(cooldownTemplateCounterMatch.hours) === false
                      ? parseInt(cooldownTemplateCounterMatch.hours, 10)
                      : 0;
                  dataReceived.cooldownMinutes =
                    cooldownTemplateCounterMatch.minutes !== undefined &&
                    cooldownTemplateCounterMatch.minutes !== null &&
                    isNaN(cooldownTemplateCounterMatch.minutes) === false
                      ? parseInt(cooldownTemplateCounterMatch.minutes, 10)
                      : 0;
                  dataReceived.nomeEstabelecimento = this.state.nomeEstabelecimento;

                  this.setState({
                    templateCounterMatch: {
                      ...this.state.templateCounterMatch,
                      ...dataReceived,
                    },
                    templatesLoaded: true,
                  });
                })
                .catch((error) => {
                  // console.log("Erro na data:", error);
                });

              //gravando id do Template Counter Match
              const templateCounterMatch = this.state.templateCounterMatch;
              templateCounterMatch.id = dataReceived.counterMatch;

              this.setState({
                state: dataReceived.state,
                groupName: dataReceived.groupName === undefined ? null : dataReceived.groupName,
                created: dataReceived.created === undefined ? null : dataReceived.created,
                plural: dataReceived.plural === undefined ? null : dataReceived.plural,
                singular: dataReceived.singular === undefined ? null : dataReceived.singular,
                groupTitle: dataReceived.title === undefined ? null : dataReceived.title,
                groupDetailsTitle:
                  dataReceived.detailsTitle === undefined ? null : dataReceived.detailsTitle,
                groupDescription:
                  dataReceived.description === undefined ? null : dataReceived.description,
                groupDetailsDescription:
                  dataReceived.detailsDescription === undefined
                    ? null
                    : dataReceived.detailsDescription,
                groupType:
                  dataReceived.counterMatch === undefined || dataReceived.counterMatch === null
                    ? 'ilimitada'
                    : 'limitada',
                counterRoof:
                  dataReceived.counterRoof === undefined ? null : dataReceived.counterRoof,
                foto: dataReceived.photo === undefined ? null : dataReceived.photo,
                thumbnail: dataReceived.thumbnail === undefined ? null : dataReceived.thumbnail,
                templateCounterMatch,

                templatesGroup: dataReceived.templates,
              });
            })
            .catch((error) => {
              // console.log("Erro na data:", error);
            });
        })
        .catch((error) => {
          // console.log("Erro na data:", error);
        });
    }

    // Baixando grupos de cupom
    getVoucherGroup()
      .then((dataReceived) => {
        this.setState({ voucherGroup: dataReceived });
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });

    // Verifica aprovação
    if (this.state.idStore) {
      verificarAprovacaoPromocao(
        this.state.idStore,
        this.state.storeType,
        this.state.idEstabelecimento,
        this.state.nomeEstabelecimento
      )
        .then((result) => {
          this.setState({ aprovacao: result });

          // Pega nome do aprovador
          if (result.approverId && result.approverId !== '') {
            let userInfo = {
              uId: result.approverId,
            };
            getUserInfo_v1(['getUserInfo'], userInfo).then((user) => {
              // console.log("Approver Info:", user);
              if (
                user.data &&
                user.data.success &&
                user.data.success.userInfo &&
                user.data.success.userInfo.name
              ) {
                this.setState({
                  approverName: user.data.success.userInfo.name,
                });
              }
            });
          }
        })
        .catch((error) => {
          // console.log("Erro ao verificar aprovação", error);
        });
    }

    window.pathname = null;
  }

  handleTagCapture = (e) => {
    this.setState({ tagCapture: e.target.value });
  };

  handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handlePushTag();
    }
  };

  handlePushTag = (e) => {
    e.preventDefault();
    let tagHolder = this.state.tags;
    if (
      tagHolder.indexOf(this.state.tagCapture.toLocaleLowerCase()) === -1 &&
      this.state.tagCapture.length !== 0
    ) {
      tagHolder.push(this.state.tagCapture.toLowerCase());
    }
    this.setState({
      tags: [...tagHolder],
      tagCapture: '',
      disableButtonUpdate: false,
    });
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    //tratando casos especiais

    //não permitir que o campo quantidade de cupons para resgate do prêmio seja menor que 1.
    if (name === 'counterRoof') {
      return this.setState({
        [name]: event.target.value < 1 ? 1 : event.target.value,
        goBack: true,
        openAlert: event.target.value < 1 ? true : false,
        messageAlert: event.target.value < 1 ? 'Este valor não pode ser menor que 1.' : '',
      });
    }

    if (name === 'singular' || name === 'plural') {
      let aux = this.state.templateCounterMatch;
      aux[name] = event.target.value;
      return this.setState({
        templateCounterMatch: aux,
        [name]: event.target.value,
      });
    }

    if (name === 'level')
      return this.setState({
        [name]:
          event.target.value > 99 || event.target.value < 1 ? this.state.level : event.target.value,
        openAlert: event.target.value > 99 || event.target.value < 1 ? true : false,
        messageAlert:
          event.target.value > 99 || event.target.value < 1
            ? 'O nível da hierarquia deve ser entre 1 e 99.'
            : null,
      });

    if (
      name === 'cooldownDays' ||
      name === 'cooldownHours' ||
      name === 'cooldownMinutes' ||
      name === 'counterRoof'
    ) {
      let newTemplates = this.state.templates;

      if (name === 'cooldownMinutes' && event.target.value > 59) {
        newTemplates[0][name] = 59;

        return this.setState({
          templates: newTemplates,
          goBack: true,
        });
      }

      if (name === 'cooldownHours' && event.target.value > 23) {
        newTemplates[0][name] = 23;

        return this.setState({
          templates: newTemplates,
          goBack: true,
        });
      }

      if (name === 'counterRoof') {
        const counterRoof = event.target.value < 0 ? 0 : event.target.value;
        return this.setState({
          counterRoof,
          goBack: true,
          openAlert: event.target.value < 0 ? true : false,
          messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
        });
      }

      newTemplates[0][name] = event.target.value < 0 ? 0 : event.target.value;
      return this.setState({
        templates: newTemplates,
        goBack: true,
        openAlert: event.target.value < 0 ? true : false,
        messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
      });
    }

    // coupon get mode check
    if (name === 'couponGetMode')
      return this.setState({
        [name]: event.target.value,
      });

    if (event.target.value === 'promocao')
      return this.setState({
        [name]: event.target.value,
        showQRCode: true,
        goBack: true,
      });

    //ADICIONAL
    if (event.target.value === 'carteira')
      return this.setState({
        [name]: event.target.value,
        goBack: true,
        showQRCode: null,
      });

    if (name === 'title' && this.state.sameInformation)
      return this.setState({
        title: event.target.value,
        titulo: event.target.value,
        goBack: true,
        showQRCode: null,
      });

    if (name === 'description' && this.state.sameInformation)
      return this.setState({
        description: event.target.value,
        descricao: event.target.value,
        goBack: true,
        showQRCode: null,
      });

    //casos normais
    return this.setState({
      [name]: event.target.value,
      goBack: true,
    });
  };

  handleChangeTemplate = (index, name) => (event) => {
    let templates = this.state.templates;
    let templateCounterMatch = this.state.templateCounterMatch;

    if (index !== null) {
      if (
        name === 'cooldownDays' ||
        name === 'cooldownHours' ||
        name === 'cooldownMinutes' ||
        name === 'value'
      ) {
        if (name === 'cooldownMinutes' && event.target.value > 59) {
          templates[index][name] = 59;
        } else if (name === 'cooldownHours' && event.target.value > 23) {
          templates[index][name] = 23;
        } else {
          templates[index][name] = event.target.value < 0 ? 0 : event.target.value;

          this.setState({
            openAlert: event.target.value < 0 ? true : false,
            messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
          });
        }
      } else {
        templates[index][name] = event.target.value;
      }
    } else {
      if (
        name === 'cooldownDays' ||
        name === 'cooldownHours' ||
        name === 'cooldownMinutes' ||
        name === 'value' ||
        name === 'disableDateHours' ||
        name === 'disableDateMinutes' ||
        name === 'disableDateDays'
      ) {
        if (name === 'cooldownMinutes' && event.target.value > 59) {
          templateCounterMatch[name] = 59;
        } else if (name === 'cooldownHours' && event.target.value > 23) {
          templateCounterMatch[name] = 23;
        } else {
          templateCounterMatch[name] = event.target.value < 0 ? 0 : event.target.value;

          this.setState({
            openAlert: event.target.value < 0 ? true : false,
            messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
          });
        }
      } else if (name === 'titulo') {
        templateCounterMatch[name] = event.target.value;
        templateCounterMatch.detailsTitle = event.target.value;

        this.setState({
          templateCounterMatch,
        });
      } else if (name === 'descricao') {
        templateCounterMatch[name] = event.target.value;
        templateCounterMatch.detailsDescription = event.target.value;

        this.setState({
          templateCounterMatch,
        });
      } else {
        templateCounterMatch[name] = event.target.value;
      }
    }

    this.setState({
      templates,
      templateCounterMatch,
      goBack: true,
    });
  };

  handleChangeSwitch = (name) => (event) => {
    let { disableButtonImageCarteira, disableButtonImagePremio } = this.state;

    if (name === 'equalImagePhoto') {
      if (disableButtonImageCarteira) {
        disableButtonImageCarteira = false;
      } else {
        disableButtonImageCarteira = true;
      }
    }

    if (name === 'equalImagePhotoCupom') {
      if (disableButtonImagePremio) {
        disableButtonImagePremio = false;
      } else {
        disableButtonImagePremio = true;
      }
    }

    if (name === 'isPublished') {
      this.setState({
        [name]: !event.target.checked,
        goBack: true,
      });
    } else {
      this.setState({
        [name]: event.target.checked,
        goBack: true,
      });
    }

    this.setState({ disableButtonImageCarteira, disableButtonImagePremio });
  };

  handleChangeSwitchTemplate = (index, name) => (event) => {
    let templates = this.state.templates;
    let templateCounterMatch = this.state.templateCounterMatch;

    if (index !== null) {
      templates[index][name] = event.target.checked;
    } else {
      templateCounterMatch[name] = event.target.checked;
    }

    this.setState({
      templates,
      templateCounterMatch,
      goBack: true,
    });
  };

  handleOpenModal = () => {
    // let elementHtml = document.getElementsByTagName('html')[0];
    // let elementBody = document.getElementsByTagName('body')[0];

    // elementHtml.setAttribute('style', 'position: fixed; overflow: hidden; height:100%;');
    // elementBody.setAttribute('style', 'position: fixed; overflow: hidden; height:100%;');

    this.setState({ openModal: true });
  };

  //handle Modal
  handleCloseModal = () => {
    // let elementHtml = document.getElementsByTagName('html')[0];
    // let elementBody = document.getElementsByTagName('body')[0];

    // elementHtml.setAttribute('style', '');
    // elementBody.setAttribute('style', '');

    this.setState({ openModal: false });
  };

  fileChangedHandler = (name, oldName) => (event) => {
    this.setState({ [oldName]: this.state[name] });

    if (event.target.files[0] !== undefined) {
      const data = [URL.createObjectURL(event.target.files[0])];
      this.setState({ imageToCrop: data, imageCroped: name }, this.handleOpenModal);
    }
  };

  photoListChangedHandler = (event) => {
    let listImages = [];

    for (let index = 0; index < event.target.files.length; index++) {
      listImages[index] = URL.createObjectURL(event.target.files[index]);
    }

    if (event.target.files !== undefined) {
      this.setState(
        { imageToCrop: listImages, imageCroped: 'photoListFile' },
        this.handleOpenModal
      );
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;

    if (newCrop.indexOf('.') >= 0) {
      const templateCounterMatch = this.state.templateCounterMatch;
      const splitName = newCrop.split('.')[1];

      templateCounterMatch[splitName] = this[state].crop();

      this.setState(
        {
          templateCounterMatch,
          imageToCrop: null,
          goBack: true,
        },
        this.handleCloseModal
      );
    } else {
      //imagem recebida do crop
      let node = this[state].crop();

      this.setState(
        {
          [newCrop]: node,
          imageToCrop: null,
          goBack: true,
        },
        this.handleCloseModal
      );
    }
  }

  photoListCropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    //verificando se é um array de imagens
    let data = [];
    if (this.state[newCrop] !== null) {
      // const a = this.state.indexCrop === 0 ? [] : this.state[newCrop];
      let a = this.state[newCrop];
      data = a.concat(node);
    } else {
      data = [node];
    }

    this.setState(
      {
        [newCrop]: this.state.imageCroped === 'photoListFile' ? data.slice(0, 4) : node,
        indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
        goBack: true,
        imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
      },
      () => {
        if (endArray === undefined) {
          this.handleCloseModal();
        }
      }
    );
  }

  handleEqualImages = (name) => (event) => {
    if (name === 'templateCounterMatch') {
      let templateCounterMatch = this.state.templateCounterMatch;

      if (event.target.checked === true) {
        templateCounterMatch.equalImagePhotoFile =
          this.state.fileStorePhoto === null
            ? this.state.loadedStorePhoto
            : this.state.fileStorePhoto;
        templateCounterMatch.equalImageThumbnailFile =
          this.state.fileStoreThumbnail === null
            ? this.state.loadedStoreThumbnail
            : this.state.fileStoreThumbnail;
      } else {
        templateCounterMatch.equalImagePhotoFile = null;
        templateCounterMatch.equalImageThumbnailFile = null;
      }

      this.setState({ templateCounterMatch });
    } else {
      if (event.target.checked === true) {
        this.setState({
          equalImageThumbnailFile:
            this.state.fileStoreThumbnail === null
              ? this.state.loadedStoreThumbnail
              : this.state.fileStoreThumbnail,
          equalImagePhotoFile:
            this.state.fileStorePhoto === null
              ? this.state.loadedStorePhoto
              : this.state.fileStorePhoto,
        });
      } else {
        this.setState({
          equalImageThumbnailFile: null,
          equalImagePhotoFile: null,
        });
      }
    }
  };

  handleChangePanel = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  //HANDLE ALERT
  goBack = () => {
    if (this.state.goBack === false) {
      window.history.back();
    } else {
      this.handleClickOpen();
    }
  };

  checkInformation = () => {
    const checkInformation =
      (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) ||
      (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) ||
      (this.state.fileThumbnail === null &&
        this.state.loadedThumbnail === null &&
        this.state.equalImageThumbnailFile === null) ||
      (this.state.equalImagePhotoFile === null &&
        this.state.fileFoto === null &&
        this.state.loadedFoto === null) ||
      (this.state.templateCounterMatch.equalImageThumbnailFile === null &&
        this.state.templateCounterMatch.fileThumbnail === null &&
        this.state.templateCounterMatch.loadedThumbnail === null) ||
      (this.state.templateCounterMatch.equalImagePhotoFile === null &&
        this.state.templateCounterMatch.fileFoto === null &&
        this.state.templateCounterMatch.loadedFoto === null) ||
      this.state.templatesLoaded === null;
    // ((!this.state.photoListFile || this.state.photoListFile === []) && (!this.state.loadedPhotoList || this.state.loadedPhotoList === [])) ||

    if (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail.',
      });
    } else if (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar ao menos uma imagem na lista de fotos.',
      });
    } else if (
      this.state.fileThumbnail === null &&
      this.state.loadedThumbnail === null &&
      this.state.equalImageThumbnailFile === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail para o cupom.',
      });
    } else if (
      this.state.equalImagePhotoFile === null &&
      this.state.fileFoto === null &&
      this.state.loadedFoto === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de detalhe para o cupom.',
      });
    } else if (
      this.state.templateCounterMatch.equalImageThumbnailFile === null &&
      this.state.templateCounterMatch.fileThumbnail === null &&
      this.state.templateCounterMatch.loadedThumbnail === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail para o cupom do prêmio.',
      });
    } else if (
      this.state.templateCounterMatch.equalImagePhotoFile === null &&
      this.state.templateCounterMatch.fileFoto === null &&
      this.state.templateCounterMatch.loadedFoto === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de detalhe para o cupom do prêmio.',
      });
    } else if (this.state.templatesLoaded === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'Informações do cupom ainda não carregadas, aguarde e tente novamente.',
      });
    } else if (this.state.tags.length < 1) {
      this.setState({
        openAlert: true,
        messageAlert: 'Obrigatorio adicionar pelo menos uma tag.',
      });
    } else if (checkInformation === true) {
      this.setState({
        openAlert: true,
        messageAlert: 'Todas as informações devem ser preenchidas.',
      });
    } else {
      this.setState({ loading: true });

      //Fazer esquema dos dias para horas

      let auxTemplates = this.state.templates;

      auxTemplates.map((item, index) => {
        auxTemplates[index]['nomeTemplate'] = this.state.titulo;
        auxTemplates[index]['titulo'] = this.state.titulo;
        auxTemplates[index]['detailsTitle'] = this.state.titulo;
        auxTemplates[index]['descricao'] = this.state.descricao;
        auxTemplates[index]['detailsDescription'] = this.state.detailsDescription;
        auxTemplates[index]['cooldownHours'] =
          parseInt(this.state.templates[index]['cooldownHours'], 10) +
          24 * parseInt(this.state.templates[index]['cooldownDays'], 10);
        return (auxTemplates[index]['cooldownDays'] = null);
      });

      let auxTemplateCounterMatch = this.state.templateCounterMatch;
      auxTemplateCounterMatch['cooldownHours'] =
        parseInt(this.state.templateCounterMatch['cooldownHours'], 10) +
        24 * parseInt(this.state.templateCounterMatch['cooldownDays'], 10);
      auxTemplateCounterMatch['cooldownDays'] = null;

      auxTemplateCounterMatch['titulo'] = this.state.title;
      auxTemplateCounterMatch['detailsTitle'] = this.state.title;

      auxTemplateCounterMatch['descricao'] = this.state.description;
      auxTemplateCounterMatch['detailsDescription'] = this.state.description;

      let groupTitle = this.state.title;
      let groupDetailsTitle = this.state.title;
      let groupDescription = this.state.description;
      let groupDetailsDescription = this.state.description;

      const uid = getUserInfo().uId;
      getTrialStatus(uid).then((result) => {
        updateTrialStatus({
          establishmentStep: result.establishmentStep,
          storeStep: true,
          relationshipStep: result.relationshipStep,
          congrats: result.congrats,
        }).then((result) => {
          if (window.location.href.split('/').find((e) => e === 'step')) {
            if (
              window.pathname !== null &&
              JSON.parse(JSON.stringify(window.sessionStorage)).secondStep === 'incomplete'
            ) {
              window.sessionStorage.setItem('secondStep', 'complete');
              this.setState({ modalStepIsOpen: true });
            }
          }
        });
      });

      this.props.updateStoreGroupInformationComponent(
        this.state.idStore,
        this.state.active,
        this.state.level,
        this.state.region,
        this.state.description,
        this.state.keywordsSetConsumptionList,
        this.state.messageBlockedKw,
        this.state.showQRCode,
        this.state.title,
        this.state.autoValidate,
        this.state.hasVoucher,
        this.state.enableDate,
        this.state.disableDate,
        this.state.isPublished,
        this.state.keywordsList,
        this.state.tags,
        this.state.fileStoreThumbnail,
        this.state.oldStoreThumbnail,
        this.state.storeThumbnail,
        this.state.fileStorePhoto,
        this.state.oldStorePhoto,
        this.state.storePhoto,
        this.state.idEstabelecimento,
        this.state.idGestor,
        this.state.nomeEstabelecimento,
        this.state.lat,
        this.state.long,
        this.state.address,
        this.state.neighborhood,

        this.state.buttonLabel,
        this.state.buttonDescription,
        this.state.showButtonDescription,
        this.state.goToPlaceLabel,
        this.state.showGoToPlace,

        this.state.idGroup,

        this.state.state,
        this.state.created,
        this.state.title,
        this.state.plural,
        this.state.singular,
        groupTitle,
        groupDetailsTitle,
        groupDescription,
        groupDetailsDescription,
        this.state.groupType,
        this.state.counterRoof,
        this.state.equalImageThumbnailFile,
        this.state.equalImagePhotoFile,
        this.state.fileThumbnail,
        this.state.oldThumbnail,
        this.state.thumbnail,
        this.state.fileFoto,
        this.state.oldFoto,
        this.state.foto,
        this.state.oldGroup,
        this.state.templatesGroup,

        auxTemplates,

        auxTemplateCounterMatch,
        this.state.oldPhotoList,
        this.state.photoListFile,
        this.state.messageApproval,
        this.state.newStatus
      );
      window.history.back();
    }
  };

  //HANDLE DIALOGS
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  //handle alerts
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  changeApproval = (newStatus) => {
    this.setState({ newStatus, approval: true });
  };

  removeImage = (idx) => {
    if (this.state.photoListFile && idx < this.state.photoListFile.length) {
      let newPhotoList = this.state.photoListFile;
      newPhotoList.splice(idx, 1);
      this.setState({
        photoListFile: newPhotoList,
        disableButtonUpdate: false,
      });
    } else {
      // console.log("Imagem não existe");
    }
  };

  mainImage = (idx) => {
    let currentFoto = this.state.fileStorePhoto
      ? this.state.fileStorePhoto
      : this.state.loadedStorePhoto;
    let newPhotoListFile = this.state.photoListFile;
    let newFoto = this.state.photoListFile[idx];

    newPhotoListFile.splice(idx, 1);
    newPhotoListFile.unshift(currentFoto);

    this.setState({ photoListFile: newPhotoListFile, fileStorePhoto: newFoto });
  };

  handleChangeGroup = (name) => (event) => {
    if (event.target.value !== null) {
      const chosenGroup = this.state.voucherGroup[event.target.value];

      this.setState({
        oldGroup: this.state.idGroup !== event.target.value ? this.state.idGroup : null,
      });

      this.setState({
        idGroup: event.target.value,
        state: chosenGroup.state,
        created: chosenGroup.created,
        groupName: chosenGroup.groupName,
        plural: chosenGroup.plural,
        singular: chosenGroup.singular,
        groupTitle: chosenGroup.title,
        groupDetailsTitle: chosenGroup.detailsTitle,
        groupDescription: chosenGroup.description,
        groupDetailsDescription: chosenGroup.detailsDescription,
        counterRoof: chosenGroup.counterRoof !== undefined ? chosenGroup.counterRoof : 0,
        groupType:
          chosenGroup.counterMatch === undefined && chosenGroup.counterRoof === undefined
            ? 'ilimitada'
            : 'limitada',
        foto: chosenGroup.photo,
        thumbnail: chosenGroup.thumbnail,

        loadedThumbnail: chosenGroup.thumbnail !== undefined ? placeholder : null,
        loadedFoto: chosenGroup.photo !== undefined ? placeholder : null,

        oldFoto: null,
        oldThumbnail: null,

        equalImageThumbnailFile: null,
        equalImagePhotoFile: null,

        fileFoto: null,
        fileThumbnail: null,

        equalImageThumbnail: false,
        equalImagePhoto: false,

        [name]: event.target.value,
        goBack: true,
      });

      if (chosenGroup.thumbnail !== undefined) {
        downloadImage('voucher', chosenGroup.thumbnail)
          .then((downloadedImage) => {
            this.setState({ loadedThumbnail: downloadedImage });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", chosenGroup.thumbnail);
          });
      }

      if (chosenGroup.photo !== undefined) {
        downloadImage('voucher', chosenGroup.photo)
          .then((downloadedImage) => {
            this.setState({ loadedFoto: downloadedImage });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", chosenGroup.photo);
          });
      }

      this.downloadTemplateCounterMatch(chosenGroup.counterMatch);
    } else {
      this.setState({
        idGroup: 'newGroup',
        created: null,
        groupName: null,
        plural: null,
        singular: null,
        groupTitle: null,
        groupDetailsTitle: null,
        groupDescription: null,
        groupDetailsDescription: null,
        counterRoof: 0,
        groupType: 'limitada',
        foto: null,
        thumbnail: null,

        loadedFoto: null,
        loadedThumbnail: null,

        oldFoto: null,
        oldThumbnail: null,

        equalImageThumbnailFile: null,
        equalImagePhotoFile: null,

        fileFoto: null,
        fileThumbnail: null,

        equalImageThumbnail: false,
        equalImagePhoto: false,

        [name]: null,
        goBack: true,
      });

      const templateCounterMatch = {
        id: null,
        titulo: null,
        detailsTitle: null,
        descricao: null,
        detailsDescription: null,
        plural: null,
        singular: null,
        consumoUnico: false,
        cooldown: null,
        cooldownHours: 0,
        cooldownMinutes: 0,
        dataGeracao: null,
        generator: true,
        nomeTemplate: null,
        qtdDisponivel: null,
        hasSimultaneo: false,
        qtdSimultaneo: 1,
        type: 'store',
        equalImagePhotoFile: null,
        equalImageThumbnailFile: null,
        equalImagePhoto: false,
        equalImageThumbnail: false,
        thumbnail: null,
        foto: null,
        loadedThumbnail: null,
        loadedFoto: null,
        oldThumbnail: null,
        oldPhoto: null,
        fileFoto: null,
        fileThumbnail: null,
      };

      this.setState({ templateCounterMatch });
    }
  };

  downloadTemplateCounterMatch = (counterMatchId) => {
    getVoucherTemplate(counterMatchId)
      .then((dataReceived) => {
        dataReceived.id = counterMatchId;
        dataReceived.fileFoto = null;
        dataReceived.fileThumbnail = null;
        dataReceived.equalImagePhoto = false;
        dataReceived.equalImagePhotoFile = null;
        dataReceived.equalImageThumbnail = false;
        dataReceived.equalImageThumbnailFile = null;
        dataReceived.loadedThumbnail = dataReceived.thumbnail !== undefined ? placeholder : null;
        dataReceived.loadedFoto = dataReceived.photo !== undefined ? placeholder : null;

        dataReceived.generator =
          dataReceived.generator === undefined ? false : dataReceived.generator;

        let seconds = dataReceived.cooldown / 100 / 36000;
        seconds = seconds.toString().split('.');
        dataReceived.cooldownHours = seconds[0] !== undefined ? parseInt(seconds[0], 10) : 0;
        dataReceived.cooldownMinutes = seconds[1] !== undefined ? parseInt(seconds[1], 10) : 0;

        dataReceived.hasSimultaneo = dataReceived.qtdSimultaneo === 1 ? false : true;

        this.setState({
          templateCounterMatch: dataReceived,
        });

        if (dataReceived.thumbnail !== undefined) {
          downloadImage('voucher', dataReceived.thumbnail)
            .then((downloadedImage) => {
              const templateCounterMatch = this.state.counterMatch;
              templateCounterMatch.loadedThumbnail = downloadedImage;
              this.setState({ templateCounterMatch });
            })
            .catch(() => {
              // console.log("Foto não encontrada:", dataReceived.thumbnail);
            });
        }

        if (dataReceived.photo !== undefined) {
          downloadImage('voucher', dataReceived.photo)
            .then((downloadedImage) => {
              const templateCounterMatch = this.state.counterMatch;
              templateCounterMatch.loadedFoto = downloadedImage;
              this.setState({ templateCounterMatch });
            })
            .catch(() => {
              // console.log("Foto não encontrada:", dataReceived.photo);
            });
        }
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  };

  //handle downshift
  handleChangeDownshift = (item) => {
    let { keywordsList } = this.state;

    if (keywordsList.indexOf(item) === -1) {
      keywordsList = [...keywordsList, item];
    }

    this.setState({
      keywords: '',
      keywordsList,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keywordsList = [...state.keywordsList];
      keywordsList.splice(keywordsList.indexOf(item), 1);
      return { keywordsList };
    });
  };
  //fim handle downshift

  //Função que verifica se é a mesma informação
  sameInformation = () => (event) => {
    this.setState({
      titulo: this.state.title,
      descricao: this.state.description,
    });
  };

  handleStateButton = () => {
    this.setState({ disableButtonUpdate: false });
  };

  copyTemplateIdToClipboard = () => {
    navigator.clipboard.writeText(this.props.location.state.templateId);
    this.setState({ copyTemplateMessage: 'ID copiado' });
  };

  render = () => {
    if (this.state.approval === true) {
      this.setState({ approval: false });
      this.checkInformation();
    }

    const { classes } = this.props;

    let fotos = [];
    for (let i = 0; i < 4; i++) {
      let exists =
        (this.state.photoListFile && this.state.photoListFile[i]) ||
        (this.state.loadedPhotoList && this.state.loadedPhotoList[i]);
      fotos.push(
        <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
          <StyledBadge
            badgeContent={
              exists ? (
                <Tooltip title="Excluir">
                  <Close
                    style={{ width: '100%', height: '100%' }}
                    onClick={() => this.removeImage(i)}
                  />
                </Tooltip>
              ) : (
                ''
              )
            }
            invisible={!exists}
          >
            <Tooltip title={exists ? 'Tornar principal' : 'Utilize o botão "Adicionar fotos"'}>
              <img
                style={{ cursor: exists ? 'pointer' : 'default' }}
                onClick={exists ? () => this.mainImage(i) : null}
                src={
                  this.state.photoListFile && this.state.photoListFile[i]
                    ? this.state.photoListFile[i]
                    : this.state.loadedPhotoList && this.state.loadedPhotoList[i]
                    ? this.state.loadedPhotoList[i]
                    : placeholder
                }
                width="100%"
                height="100%"
                alt="Lista de Fotos"
              />
            </Tooltip>
          </StyledBadge>
        </Grid>
      );
    }

    if (this.props.loading && this.state.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (
        window.pathname !== null &&
        JSON.parse(JSON.stringify(window.sessionStorage)).secondStepNew === 'incomplete'
      ) {
        window.sessionStorage.setItem('secondStepNew', 'complete');
        return (
          <Redirect
            to={{
              // pathname: window.pathname + 'passos',
              pathname: `/estabelecimento/${this.state.establishmentListInfos[0].id}/secondStep`,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      }
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      } else {
        return (
          <div
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
              overflowY: 'auto',
              padding: '4rem 32px',
              margin: '0 auto',
            }}
          >
            <Grid item sm={12} xs={12}>
              {/* alert com msg aprovação */}
              {this.state.aprovacao && this.state.aprovacao.status !== 'aprovado' && (
                <StyledAlert
                  variant="filled"
                  severity={this.state.aprovacao.status === 'negado' ? 'error' : 'info'}
                >
                  {this.state.aprovacao.status === 'negado'
                    ? this.state.aprovacao.comment
                    : `Aguarde aprovação até ${formatDate(
                        this.state.aprovacao.requestDate + this.dayMs * this.responseDays
                      )}`}
                </StyledAlert>
              )}
            </Grid>

            <HeaderTitle pageInfo={this.state.nomeEstabelecimento} />
            {this.state.idStore === null ? (
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                marginBottom="10px"
                style={{ fontWeight: '700', fontSize: '25px' }}
              >
                Cadastro da Promoção
              </Typography>
            ) : (
              <EditShowId text="Editar Promoção" id={this.state.idStore} />
            )}

            <form
              onChange={() => this.handleStateButton()}
              onSubmit={(e) => {
                e.preventDefault();
                this.checkInformation();
              }}
            >
              <FormGroup>
                <TextField
                  style={{ width: '100%', marginTop: '30px' }}
                  className="input-field"
                  type="text"
                  value={this.state.title === null ? '' : this.state.title}
                  id="title"
                  label="Título da promoção"
                  placeholder="Ex: Promoção Triibo da Semana (Limite de 25 caracteres)"
                  onChange={this.handleChange('title')}
                  required
                />

                <TextField
                  style={{ width: '100%' }}
                  className="input-field"
                  multiline
                  type="text"
                  value={this.state.description === null ? '' : this.state.description}
                  id="description"
                  required
                  label="Descrição da promoção"
                  placeholder="Ex: Nesta Promoção ganhe da Triibo um prêmio ao juntar 10 cupons"
                  onChange={this.handleChange('description')}
                />
                <div>
                  <S.TagsLabelContainer>
                    <S.TagsBigLabel>Tags da promoção</S.TagsBigLabel>
                    <S.TagsSmallLabel>
                      As tags auxiliam na classificação e busca do seu negócio dentro do App.
                      Adicione uma palavra por vez.
                    </S.TagsSmallLabel>
                  </S.TagsLabelContainer>

                  <S.TagsContainer>
                    <S.TagsAddContainer>
                      <D.TriiboTextField
                        rows={1}
                        placeholder={'Ex.pizza*'}
                        value={this.state.tagCapture}
                        onChange={(e) => {
                          this.handleTagCapture(e);
                        }}
                        onKeyDown={(e) => {
                          this.handlePressEnter(e);
                        }}
                      />
                      <S.TagsAddButton
                        blue={true}
                        onClick={(e) => {
                          this.handlePushTag(e);
                        }}
                      >
                        Adicionar tag
                      </S.TagsAddButton>
                    </S.TagsAddContainer>
                    <D.TriiboTagsField>
                      <D.TriiboTagsPlaceholder
                        visibility={this.state.tags.length === 0 ? false : true}
                      >
                        Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                      </D.TriiboTagsPlaceholder>
                      {this.state.tags.length > 0 &&
                        this.state.tags.map((body, index) => (
                          <>
                            <D.UserTags key={index}>
                              <D.TagText>{body}</D.TagText>
                              <D.TagDelete
                                onClick={(e) => {
                                  e.preventDefault();
                                  let newArr = this.state.tags;
                                  newArr.splice(index, 1);
                                  this.setState({
                                    tags: [...newArr],
                                    disableButtonUpdate: false,
                                  });
                                }}
                              >
                                {<BsXLg style={{ color: 'white' }} />}
                              </D.TagDelete>
                            </D.UserTags>
                          </>
                        ))}
                    </D.TriiboTagsField>
                  </S.TagsContainer>
                </div>

                <br />
                <br />

                <Typography
                  style={{
                    marginTop: '10px',
                    marginBottom: '-15px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Fotos da promoção
                </Typography>
                <br />
                <br />

                <div className="flex-external">
                  <div
                    className="img-thumb"
                    style={{
                      maxWidth: '400px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: '550', textAlign: 'center' }}
                    >
                      Thumbnail (aparecerá em "Lista de Promoções")
                    </Typography>

                    <img
                      src={thumbnailPromocao}
                      alt="Thumbnail"
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        maxHeight: '447px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    />

                    <img
                      className="img-promocao-fidelidade1"
                      alt="thumbnail"
                      src={
                        this.state.fileStoreThumbnail
                          ? this.state.fileStoreThumbnail
                          : this.state.loadedStoreThumbnail
                          ? this.state.loadedStoreThumbnail
                          : placeholder
                      }
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        value=""
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={this.fileChangedHandler('fileStoreThumbnail', 'storeThumbnail')}
                        id="thumbnail"
                      />

                      <label htmlFor="thumbnail">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            marginTop: '15px',
                            fontWeight: '550',
                            marginLeft: '20px',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          startIcon={<AddPhotoAlternate color="#fff" />}
                        >
                          Adicionar Foto
                        </Button>

                        <Tooltip title="As imagens devem ser 4x3.">
                          <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                        </Tooltip>
                      </label>
                    </div>
                  </div>

                  {/* Lista de fotos */}
                  <div>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      className="style-text"
                      style={{ fontWeight: '550' }}
                    >
                      Lista de Fotos (aparecerá em "Detalhe da Promoção")
                    </Typography>

                    <section className="grid-style">
                      <div
                        className="Foto-detalhe"
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <img
                          src={listaFotos}
                          alt="thumbnail"
                          style={{
                            width: '100%',
                            maxWidth: '392px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <StyledBadge2
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%',
                          }}
                          invisible={
                            (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                            (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                              ? false
                              : true
                          }
                          badgeContent={
                            <Tooltip
                              title={
                                (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                                (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                                  ? 'Para trocar clique em uma imagem ao lado'
                                  : 'Utilize o botão "Adicionar fotos"'
                              }
                            >
                              <Help color="secondary" style={{ fontSize: 18 }} />
                            </Tooltip>
                          }
                          color="primary"
                        >
                          <img
                            alt="foto da promoção"
                            src={
                              this.state.fileStorePhoto
                                ? this.state.fileStorePhoto
                                : this.state.loadedStorePhoto
                                ? this.state.loadedStorePhoto
                                : placeholder
                            }
                            width="100%"
                            style={{
                              maxWidth: '393px',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                            }}
                          />
                        </StyledBadge2>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {this.state.loadedStorePhoto || this.state.fileStorePhoto ? (
                            <div>
                              <input
                                value=""
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={this.photoListChangedHandler}
                                id="listaFotos"
                              />

                              <label htmlFor="listaFotos">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={{
                                    marginTop: '15px',
                                    fontWeight: '550',
                                    color: '#fff',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={<AddPhotoAlternate color="#fff" />}
                                >
                                  Adicionar Foto na Lista
                                </Button>

                                <Tooltip
                                  title="As imagens devem ser 4x3."
                                  style={{ marginTop: '36px' }}
                                >
                                  <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                                </Tooltip>
                              </label>
                            </div>
                          ) : (
                            <div>
                              <input
                                value=""
                                id="photoStore"
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={this.fileChangedHandler('fileStorePhoto', 'storePhoto')}
                              />
                              <label htmlFor="photoStore">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={{
                                    marginTop: '15px',
                                    fontWeight: '550',
                                    color: '#fff',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={<AddPhotoAlternate color="#fff" />}
                                >
                                  Adicionar Foto De Detalhe
                                </Button>
                              </label>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="lista-detalhes" id="photoList">
                        {fotos}
                      </div>
                    </section>
                  </div>
                </div>

                <br />
                <Typography
                  style={{
                    marginTop: '50px',
                    marginBottom: '-15px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  placeholder="Placeholder"
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Informações do cupom fidelidade (para uso da promoção)
                </Typography>
                <br />
                {this.state.idStore !== null ? (
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{
                        fontWeight: '550',
                        color: '#fff',
                        textTransform: 'capitalize',
                      }}
                      startIcon={<FileCopy />}
                      onClick={() => this.copyTemplateIdToClipboard()}
                    >
                      {this.state.copyTemplateMessage}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <br />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.sameInformation}
                      onChange={this.handleChangeSwitch('sameInformation')}
                      color="primary"
                      onClick={this.sameInformation()}
                    />
                  }
                  label={'Utilizar as mesmas informações da promoção'}
                />

                <TextField
                  style={{
                    width: '100%',
                    display: this.state.sameInformation ? 'none' : 'inline-flex',
                  }}
                  placeholder="Ex: Leve 2 e pague 1"
                  className="input-field"
                  value={
                    this.state.sameInformation
                      ? this.state.title
                      : !this.state.titulo
                      ? ''
                      : this.state.titulo
                  }
                  type="text"
                  id="titulo"
                  required
                  onChange={this.handleChange('titulo')}
                  label="Título do cupom na carteira"
                />

                <TextField
                  style={{
                    width: '100%',
                    display: this.state.sameInformation ? 'none' : 'inline-flex',
                  }}
                  placeholder="Ex: Leve 2 produtos e pague 1. (Limite de 60 caracteres)"
                  className="input-field"
                  value={
                    this.state.sameInformation
                      ? this.state.description
                      : !this.state.descricao
                      ? ''
                      : this.state.descricao
                  }
                  multiline
                  onChange={this.handleChange('descricao')}
                  type="text"
                  id="descricao"
                  required
                  label="Descrição resumida na carteira"
                />

                <TextField
                  style={{ width: '100%' }}
                  placeholder="Ex: Parabéns, você ganhou um desconto. Apresente este cupom no local e aproveite!"
                  className="input-field"
                  value={!this.state.detailsDescription ? '' : this.state.detailsDescription}
                  multiline
                  type="text"
                  id="detailsDescription"
                  required
                  onChange={this.handleChange('detailsDescription')}
                  label="Descrição detalhada na carteira"
                />

                <br />
                <br />

                <Typography
                  style={{
                    fontWeight: '400',
                    fontSize: '1rem',
                    marginTop: '30px',
                    marginLeft: '-45px',
                  }}
                  gutterBottom
                >
                  Tempo de espera para pegar outro cupom
                </Typography>
                <Grid container item lg={8}>
                  <Grid item lg={1}>
                    <TextField
                      style={{ width: '80px' }}
                      className="input-field"
                      value={
                        this.state.templates &&
                        this.state.templates[0] &&
                        this.state.templates[0].cooldownDays
                          ? this.state.templates[0].cooldownDays
                          : ''
                      }
                      onChange={this.handleChange('cooldownDays')}
                      type="number"
                      id="cooldownDays"
                      required
                      label="Dias"
                    />
                  </Grid>
                  <Grid item lg={1}>
                    <TextField
                      style={{ width: '80px' }}
                      className="input-field"
                      value={
                        this.state.templates &&
                        this.state.templates[0] &&
                        this.state.templates[0].cooldownHours
                          ? this.state.templates[0].cooldownHours
                          : ''
                      }
                      onChange={this.handleChange('cooldownHours')}
                      type="number"
                      id="cooldownHours"
                      required
                      label="Horas"
                    />
                  </Grid>
                  <Grid item lg={1}>
                    <TextField
                      style={{ width: '90px' }}
                      className="input-field"
                      value={
                        this.state.templates &&
                        this.state.templates[0] &&
                        this.state.templates[0].cooldownMinutes
                          ? this.state.templates[0].cooldownMinutes
                          : ''
                      }
                      onChange={this.handleChange('cooldownMinutes')}
                      type="number"
                      id="cooldownMinutes"
                      required
                      label="Minutos"
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.disableButtonImageCarteira}
                      onChange={this.handleChangeSwitch('equalImagePhoto')}
                      color="primary"
                      onClick={this.handleEqualImages('equalImagePhotoFile')}
                    />
                  }
                  label={'Utilizar as mesmas imagens da promoção'}
                />

                <br />
                {!this.state.disableButtonImageCarteira && (
                  <Typography
                    style={{
                      marginTop: '10px',
                      marginBottom: '-15px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                    variant="h5"
                    gutterBottom
                    color="primary"
                  >
                    Fotos do cupom na carteira
                  </Typography>
                )}

                <br />
                <br />

                {/* início da renderização condicional */}
                {!this.state.disableButtonImageCarteira && (
                  <div className="flex-external">
                    <div
                      className="img-thumb"
                      style={{
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        style={{ fontWeight: '550', textAlign: 'center' }}
                      >
                        Thumbnail do Cupom (aparecerá na "Carteira")
                      </Typography>

                      <img
                        alt="Thumbnail"
                        src={thumbnailPromocao}
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <img
                        alt="thumbnail"
                        className="img-promocao-fidelidade2"
                        src={
                          this.state.equalImageThumbnailFile !== null
                            ? this.state.equalImageThumbnailFile
                            : this.state.fileThumbnail !== null
                            ? this.state.fileThumbnail
                            : this.state.loadedThumbnail !== null
                            ? this.state.loadedThumbnail
                            : placeholder
                        }
                      />

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                          value=""
                          disabled={this.state.equalImageThumbnail === true ? true : false}
                          id="thumbailTemplate"
                          accept="image/*"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={this.fileChangedHandler('fileThumbnail', 'thumbnail')}
                        />

                        <label htmlFor="thumbailTemplate">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{
                              marginTop: '15px',
                              fontWeight: '550',
                              marginLeft: '20px',
                              color: '#fff',
                              textTransform: 'capitalize',
                            }}
                            disabled={this.state.disableButtonImageCarteira}
                            startIcon={<AddPhotoAlternate color="#fff" />}
                          >
                            Adicionar Foto
                          </Button>

                          <Tooltip title="As imagens devem ser 4x3.">
                            <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                          </Tooltip>
                        </label>
                      </div>
                    </div>

                    <div
                      className="img-thumb"
                      style={{
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        style={{ fontWeight: '550', textAlign: 'center' }}
                      >
                        Foto Detalhada (aparecerá em "Detalhe do Cupom")
                      </Typography>

                      <img
                        src={listaFotos}
                        alt="Foto em detalhe"
                        style={{
                          maxWidth: '100%',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <img
                        width="100%"
                        style={{ position: 'absolute', left: '0' }}
                        alt="Foto em detalhe"
                        src={
                          this.state.equalImagePhotoFile !== null
                            ? this.state.equalImagePhotoFile
                            : this.state.fileFoto !== null
                            ? this.state.fileFoto
                            : this.state.loadedFoto !== null
                            ? this.state.loadedFoto
                            : placeholder
                        }
                      />

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                          value=""
                          disabled={this.state.equalImagePhoto === true ? true : false}
                          id="photoTemplate"
                          accept="image/*"
                          style={{ display: 'none' }}
                          type="file"
                          onChange={this.fileChangedHandler('fileFoto', 'foto')}
                        />

                        <label htmlFor="photoTemplate">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{
                              marginTop: '15px',
                              fontWeight: '550',
                              marginLeft: '20px',
                              color: '#fff',
                              textTransform: 'capitalize',
                            }}
                            disabled={this.state.disableButtonImageCarteira}
                            startIcon={<AddPhotoAlternate color="#fff" />}
                          >
                            Adicionar Foto
                          </Button>

                          <Tooltip title="As imagens devem ser 4x3.">
                            <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                          </Tooltip>
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <FormControl className="input-field">
                  <TextField
                    className="input-field"
                    value={this.state.singular === null ? '' : this.state.singular}
                    type="text"
                    id="singular"
                    required
                    label="Objeto da carteira no singular"
                    placeholder="Nome usado para o cupom na carteira no singular (cupom, carimbo)"
                    onChange={this.handleChange('singular')}
                  />

                  <TextField
                    className="input-field"
                    value={this.state.plural === null ? '' : this.state.plural}
                    type="text"
                    id="plural"
                    required
                    label="Objeto da carteira no plural"
                    placeholder="Nome usado para o cupom na carteira no plural (cupons, carimbos)"
                    onChange={this.handleChange('plural')}
                  />

                  <FormLabel style={{ marginTop: '40px' }} component="legend">
                    Quantidade de cupons para resgate do prêmio
                  </FormLabel>

                  <TextField
                    disabled={this.state.groupType === 'ilimitada' ? true : false}
                    style={{
                      width: '90px',
                      padding: '8px',
                      marginLeft: '-6px',
                    }}
                    className="input-field"
                    value={this.state.counterRoof}
                    type="number"
                    id="qtdSimultaneo"
                    required
                    onChange={this.handleChange('counterRoof')}
                  />
                  {/* fim */}

                  <br />
                  <Typography
                    style={{
                      marginTop: '50px',
                      marginBottom: '-15px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                    placeholder="Placeholder"
                    variant="h5"
                    gutterBottom
                    color="primary"
                  >
                    Cupom de prêmio
                  </Typography>
                  <br />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.disableButtonImagePremio}
                        onChange={this.handleChangeSwitch('equalImagePhotoCupom')}
                        color="primary"
                        onClick={this.handleEqualImages('templateCounterMatch')}
                      />
                    }
                    label={'Utilizar as mesmas imagens da promoção'}
                  />

                  <br />
                  <br />
                  <br />

                  {!this.state.disableButtonImagePremio && (
                    <div className="flex-external">
                      <div
                        className="img-thumb"
                        style={{
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="secondary"
                          style={{ fontWeight: '550', textAlign: 'center' }}
                        >
                          Thumbnail do Cupom (aparecerá na "Carteira")
                        </Typography>

                        <img
                          alt="thumbnail promocao"
                          src={thumbnailPromocao}
                          style={{
                            width: '100%',
                            maxWidth: '400px',
                            maxHeight: '447px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <img
                          alt="thumbnail"
                          className="img-promocao-fidelidade2"
                          src={
                            this.state.templateCounterMatch.equalImageThumbnailFile !== null
                              ? this.state.templateCounterMatch.equalImageThumbnailFile
                              : this.state.templateCounterMatch.fileThumbnail !== null
                              ? this.state.templateCounterMatch.fileThumbnail
                              : this.state.templateCounterMatch.loadedThumbnail !== null
                              ? this.state.templateCounterMatch.loadedThumbnail
                              : placeholder
                          }
                        />

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            value=""
                            disabled={
                              this.state.templateCounterMatch.equalImageThumbnail === true
                                ? true
                                : false
                            }
                            id="thumbailTemplateCounterMatch"
                            accept="image/*"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.fileChangedHandler(
                              'templateCounterMatch.fileThumbnail',
                              'templateCounterMatch.thumbnail'
                            )}
                          />

                          <label htmlFor="thumbailTemplateCounterMatch">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                marginTop: '15px',
                                fontWeight: '550',
                                marginLeft: '20px',
                                color: '#fff',
                                textTransform: 'capitalize',
                              }}
                              disabled={this.state.disableButtonImagePremio}
                              startIcon={<AddPhotoAlternate />}
                            >
                              Adicionar Foto
                            </Button>

                            <Tooltip title="As imagens devem ser 4x3.">
                              <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                            </Tooltip>
                          </label>
                        </div>
                      </div>

                      <div
                        className="img-thumb"
                        style={{
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="secondary"
                          style={{ fontWeight: '550', textAlign: 'center' }}
                        >
                          Foto Detalhada (aparecerá em "Detalhe do Cupom")
                        </Typography>

                        <img
                          src={listaFotos}
                          alt="Foto detalhada"
                          style={{
                            maxWidth: '100%',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <img
                          width="100%"
                          alt="Foto detalhe"
                          style={{ position: 'absolute', left: '0' }}
                          src={
                            this.state.templateCounterMatch.equalImagePhotoFile !== null
                              ? this.state.templateCounterMatch.equalImagePhotoFile
                              : this.state.templateCounterMatch.fileFoto !== null
                              ? this.state.templateCounterMatch.fileFoto
                              : this.state.templateCounterMatch.loadedFoto !== null
                              ? this.state.templateCounterMatch.loadedFoto
                              : placeholder
                          }
                        />

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            value=""
                            disabled={
                              this.state.templateCounterMatch.equalImagePhoto === true
                                ? true
                                : false
                            }
                            id="photoTemplateCounterMatch"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.fileChangedHandler(
                              'templateCounterMatch.fileFoto',
                              'templateCounterMatchfoto'
                            )}
                          />

                          <label htmlFor="photoTemplateCounterMatch">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                marginTop: '15px',
                                fontWeight: '550',
                                marginLeft: '20px',
                                color: '#fff',
                                textTransform: 'capitalize',
                              }}
                              disabled={this.state.disableButtonImagePremio}
                              startIcon={<AddPhotoAlternate />}
                            >
                              Adicionar Foto
                            </Button>

                            <Tooltip title="As imagens devem ser 4x3.">
                              <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* fim da renderização condicional. */}

                  <div style={{ marginTop: '0px' }}>
                    {/* ÁREA DE CADASTRO DOS TEMPLATES */}

                    {Object(this.state.templates).map((item, i) => (
                      <div key={item.id}>
                        <FormControl className="input-field" style={{ marginTop: '30px' }}>
                          <FormControl className="input-field"></FormControl>
                        </FormControl>
                      </div>
                    ))}

                    {/* CADASTRO DO TEMPLATE FINAL */}
                    <div>
                      <FormLabel style={{ marginTop: '20px' }} component="legend">
                        Processo de Validação
                      </FormLabel>

                      <RadioGroup
                        aria-label="autoValidate"
                        name="autoValidate"
                        value={this.state.autoValidate}
                        onChange={this.handleChange('autoValidate')}
                      >
                        <FormControlLabel
                          value="estabelecimento"
                          control={<Radio color="primary" />}
                          label="Celular do Estabelecimento"
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          value="cliente"
                          control={<Radio color="primary" />}
                          label="Celular do Cliente"
                          labelPlacement="end"
                        />
                      </RadioGroup>

                      <FormLabel style={{ marginTop: '20px' }} component="legend">
                        Tipo de Consumo
                      </FormLabel>
                      <RadioGroup
                        aria-label="consumo"
                        name="consumo"
                        value={this.state.consumo}
                        onChange={this.handleChange('consumo')}
                      >
                        <FormControlLabel
                          value="promocao"
                          control={<Radio color="primary" />}
                          label="Imediato"
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          value="carteira"
                          control={<Radio color="primary" />}
                          label="Posterior"
                          labelPlacement="end"
                        />
                      </RadioGroup>

                      <FormControl className="input-field" style={{ marginTop: '30px' }}>
                        <FormControlLabel
                          style={{ marginTop: '10px' }}
                          control={
                            <Switch
                              checked={this.state.templateCounterMatch.hasDisableDate}
                              onChange={this.handleChangeSwitchTemplate(null, 'hasDisableDate')}
                              color="primary"
                            />
                          }
                          label={'Definir data de validade'}
                        />

                        <FormLabel style={{ marginTop: '20px' }} component="legend">
                          Tempo de validade do cupom
                        </FormLabel>
                        <Grid container spacing={4}>
                          <Grid item lg={1}>
                            <TextField
                              disabled={!this.state.templateCounterMatch.hasDisableDate}
                              style={{ width: '70px' }}
                              className="input-field"
                              value={this.state.templateCounterMatch.disableDateDays}
                              onChange={this.handleChangeTemplate(null, 'disableDateDays')}
                              type="number"
                              id="disableDateDays"
                              label="Dias"
                              required
                            />
                          </Grid>

                          <Grid item lg={1}>
                            <TextField
                              disabled={!this.state.templateCounterMatch.hasDisableDate}
                              style={{ width: '70px' }}
                              className="input-field"
                              value={this.state.templateCounterMatch.disableDateHours}
                              onChange={this.handleChangeTemplate(null, 'disableDateHours')}
                              type="number"
                              id="disableDateHours"
                              label="Horas"
                              required
                            />
                          </Grid>

                          <Grid item lg={1}>
                            <TextField
                              disabled={!this.state.templateCounterMatch.hasDisableDate}
                              style={{ width: '70px' }}
                              className="input-field"
                              value={this.state.templateCounterMatch.disableDateMinutes}
                              onChange={this.handleChangeTemplate(null, 'disableDateMinutes')}
                              type="number"
                              id="disableDateMinutes"
                              label="Minutos"
                              required
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </div>
                  </div>
                </FormControl>

                {window.location.pathname.split('/')[1] === 'admin' && (
                  <section>
                    <div>
                      <br />
                      <FormLabel
                        style={{ paddingBottom: '30px', paddingTop: '30px' }}
                        component="legend"
                      >
                        <br />
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="primary"
                          style={{ fontWeight: '700', fontSize: '20px' }}
                        >
                          Campo de Mensagem
                        </Typography>
                      </FormLabel>
                    </div>
                    <br />

                    <div>
                      <form
                        onSubmit={(e) => e.preventDefault()}
                        className={classes.root}
                        noValidate
                      >
                        {this.state.aprovacao && this.state.aprovacao.comment && (
                          <FormLabel component="legend">
                            <strong>Último comentário:</strong> {this.state.aprovacao.comment}
                          </FormLabel>
                        )}

                        <br />

                        {this.state.aprovacao &&
                          this.state.aprovacao.approverId &&
                          this.state.approverName && (
                            <FormLabel component="legend">
                              <em>Aprovador: {this.state.approverName}</em>
                            </FormLabel>
                          )}

                        <br />

                        <FormLabel component="legend">
                          Em caso de reprova, este campo é obrigatório
                        </FormLabel>

                        <br />

                        <ThemeProvider className="input-field">
                          <TextField
                            multiline
                            style={{ width: '60%' }}
                            className={classes.margin}
                            label="Mensagem"
                            variant="outlined"
                            onChange={(e) => this.setState({ messageApproval: e.target.value })}
                            value={this.state.messageApproval}
                          />
                        </ThemeProvider>

                        <div>
                          <Button
                            style={{
                              fontWeight: '550',
                              marginTop: '20px',
                              color: 'white',
                              textTransform: 'capitalize',
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => this.changeApproval('aprovado')}
                            startIcon={<Check />}
                          >
                            Aprovar
                          </Button>

                          <ErrorButton
                            style={{
                              fontWeight: '550',
                              marginLeft: '10px',
                              marginTop: '20px',
                              color: 'white',
                              textTransform: 'capitalize',
                            }}
                            variant="contained"
                            onClick={() => this.changeApproval('negado')}
                            startIcon={<Clear />}
                            disabled={!this.state.messageApproval}
                          >
                            Reprovar
                          </ErrorButton>
                        </div>
                      </form>
                    </div>
                  </section>
                )}
              </FormGroup>

              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '50px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => this.goBack()}
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>

              <Button
                disabled={this.state.disableButtonUpdate}
                style={{
                  fontWeight: '550',
                  marginTop: '50px',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<DoneAllIcon color="white" />}
              >
                {isAdminPage()
                  ? 'Salvar'
                  : !this.state.idStore
                  ? 'Solicitar Aprovação'
                  : this.state.aprovacao && this.state.aprovacao.status === 'negado'
                  ? 'Solicitar Revisão'
                  : 'Atualizar Informação'}
              </Button>
            </form>

            {/* ALERTS */}
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {'Deseja voltar a página anterior'}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  As alterações realizadas não serão salvas.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  cancelar
                </Button>
                <Button onClick={() => window.history.back()} color="primary">
                  voltar
                </Button>
              </DialogActions>
            </Dialog>
            {/* FIM ALERTS */}
            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openModal}
            >
              <div style={getModalStyle()}>
                <Typography
                  variant="h5"
                  gutterBottom
                  color="primary"
                  style={{ fontWeight: '600', textAlign: 'center' }}
                >
                  Editar Imagem
                </Typography>

                <div className="style-modalCrop">
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop[this.state.indexCrop]}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={4 / 3}
                    />
                  )}
                </div>

                <div
                  style={{
                    position: 'fixed',
                    bottom: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: '18px',
                    marginLeft: '-6',
                  }}
                >
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleCloseModal()}
                    startIcon={<ArrowBack />}
                  >
                    Cancelar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.state.imageCroped === 'photoListFile') {
                        this.photoListCropImage('image');
                      } else {
                        this.cropImage('image');
                      }
                    }}
                    startIcon={<CropFreeIcon color="white" />}
                  >
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}

            {/* ALERT */}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messageAlert}</span>}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.storePromotionComponent.loading,
    success: state.storePromotionComponent.success,
    error: state.storePromotionComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateStoreGroupInformationComponent: (
    idStore,
    active,
    level,
    region,
    description,
    keywordsSetConsumptionList,
    messageBlockedKw,
    showQRCode,
    title,
    autoValidate,
    hasVoucher,
    enableDate,
    disableDate,
    isPublished,
    keywords,
    tags,
    fileStoreThumbnail,
    oldStoreThumbnail,
    storeThumbnail,
    fileStorePhoto,
    oldStorePhoto,
    storePhoto,

    idEstabelecimento,
    idGestor,
    nomeEstabelecimento,
    lat,
    long,
    address,
    neighborhood,

    buttonLabel,
    buttonDescription,
    showButtonDescription,
    goToPlaceLabel,
    showGoToPlace,

    idGroup,
    state,
    created,
    groupName,
    plural,
    singular,
    groupTitle,
    groupDetailsTitle,
    groupDescription,
    groupDetailsDescription,
    groupType,
    counterRoof,
    equalImageThumbnailFile,
    equalImagePhotoFile,
    fileThumbnail,
    oldThumbnail,
    thumbnail,
    fileFoto,
    oldFoto,
    foto,
    oldGroup,
    templatesGroup,

    templates,

    templateCounterMatch,
    oldPhotoList,
    photoListFile,
    messageApproval,
    newStatus
  ) =>
    updateStoreGroupInformationAction(
      dispatch,
      idStore,
      active,
      level,
      region,
      description,
      keywordsSetConsumptionList,
      messageBlockedKw,
      showQRCode,
      title,
      autoValidate,
      hasVoucher,
      enableDate,
      disableDate,
      isPublished,
      keywords,
      tags,
      fileStoreThumbnail,
      oldStoreThumbnail,
      storeThumbnail,
      fileStorePhoto,
      oldStorePhoto,
      storePhoto,

      idEstabelecimento,
      idGestor,
      nomeEstabelecimento,
      lat,
      long,
      address,
      neighborhood,

      buttonLabel,
      buttonDescription,
      showButtonDescription,
      goToPlaceLabel,
      showGoToPlace,

      idGroup,
      state,
      created,
      groupName,
      plural,
      singular,
      groupTitle,
      groupDetailsTitle,
      groupDescription,
      groupDetailsDescription,
      groupType,
      counterRoof,
      equalImageThumbnailFile,
      equalImagePhotoFile,
      fileThumbnail,
      oldThumbnail,
      thumbnail,
      fileFoto,
      oldFoto,
      foto,
      oldGroup,
      templatesGroup,

      templates,

      templateCounterMatch,
      oldPhotoList,
      photoListFile,
      messageApproval,
      newStatus
    ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(StoreGroupExternal);

