import styled from "styled-components";

export const ModalBox = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 10px;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 10px;
    border: 3px solid #f2f2f2;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseModalButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    svg {
      color: #f13a3a;
    }
  }
`;

export const InsertInfo = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  height: 42px;
  gap: 2rem;
`;

export const TextFild = styled.input`
  flex: 1;
  height: 100%;
  padding: 0 1rem;
  border: 1px solid #757575;
  border-radius: 10px;
  font-size: 15px;
`;

export const addButton = styled.button`
  height: 100%;
  border: none;
  border-radius: 10px;
  background: #328796;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0 1.5rem;
  cursor: pointer;

  h5 {
    font-weight: 600;
    color: #fff;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ListInfos = styled.ul`
  list-style: none;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #f2f2f2;
  }
`;

export const Item = styled.li`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 42px;
  background: #f2f2f2;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 12px 2rem;
`;

export const DeleteItem = styled.button`
  position: absolute;
  right: -1px;
  top: -1px;
  height: 42px;
  border: none;
  border-radius: 10px;
  padding: 0 0.6rem;
  background: #f13a3a;
  cursor: pointer;

  &:hover {
    filter: contrast(0.8);
  }

  svg {
    color: #fff;
  }
`;
