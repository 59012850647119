import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import useStyles from './styles';
import logoTriibo from 'styles/assets/Logo_Triibo.png';
import NotPermissionAnimation from 'styles/assets/animations/Not_Permission.json';

const NotPermission = () => {
    const classes = useStyles();

    const bodyMovinOptions = {
        loop: true,
        autoplay: true,
        animationData: NotPermissionAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className={classes.content}>
            <header className={classes.header}>
                <img className={classes.logo} src={logoTriibo} alt="Logo Triibo" />
            </header>
            <Grid container className={classes.root}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box className={classes.info}>
                        <Typography variant="h1" component="h1" className={classes.title}>
                OPSS!!!
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                Você não possui permissão para acessar essa página. Caso acredite
                que ocorreu algum engano, contacte o suporte triibo.
                        </Typography>
                        <Link to="/admin" className={classes.link}>
                            <Button className={classes.btnBack} variant="contained" color="primary">
                Voltar para Home
                            </Button>
                        </Link>
                    </Box>
                </Grid>
                <Grid className={classes.animation} item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Lottie options={bodyMovinOptions} />
                </Grid>
            </Grid>
        </div>
    );
};

export default NotPermission;
