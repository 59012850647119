import React, { useState } from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { TableTD } from 'js/components/DesignSystem/Table/styles';

import GoBackRow from 'js/components/DesignSystem/GoBackRow';
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';

import { BsTrash2Fill } from 'react-icons/bs';

import UnitPost from './UnitPost';
import BatchPost from './BatchPost';
import PostPointsForm from './PostPointsForm';
import Switcher from './Switcher';
import SaveButton from './SaveButton';

const PostPoints = () => {
  const [usersArr, setUsersArr] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [form, setForm] = useState({
    points: 0,
    productId: '',
    campaignDescription: '',
  });

  const postComponentsArray = [
    <UnitPost usersArr={usersArr} setUsersArr={setUsersArr} />,
    <BatchPost usersArr={usersArr} setUsersArr={setUsersArr} />,
  ];

  const column = [
    { heading: 'uId', value: 'uId' },
    { heading: 'CPF', value: 'cpf' },
    { heading: 'triiboId', value: 'triiboId' },
  ];

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: <></>,
      childrenComponent: (
        <>
          {childrens ? (
            <TableTD style={{ textAlign: 'right' }}>
              <D.TriiboFilledButton
                blue={true}
                onClick={(e) => {
                  e.preventDefault();
                  setUsersArr(usersArr.filter((item) => item['cpf'] !== childrens['cpf']));
                }}
              >
                <BsTrash2Fill />
              </D.TriiboFilledButton>
            </TableTD>
          ) : (
            <></>
          )}
        </>
      ),
    };
  };

  return (
    <>
      <S.Container>
        <GoBackRow />
        <D.TriiboH1>Creditar pontos</D.TriiboH1>
        <PostPointsForm form={form} setForm={setForm} />
        <Switcher setCurrentIndex={setCurrentIndex} />
        {postComponentsArray[currentIndex]}
        {!usersArr.length ? (
          <></>
        ) : (
          <CustomButtonsTable
            data={usersArr}
            perPage={10}
            column={column}
            componentReceiver={componentReceiver}
          />
        )}
        <SaveButton usersArr={usersArr} form={form} />
      </S.Container>
    </>
  );
};

export default PostPoints;
