import { Box } from '@mui/material';
import React, { useRef } from 'react';
import { useTemplate } from '../Context/Marketing';

const Templates = ({ classes, template, setValueTab }) => {
  const {
    selectedTemplate,
    setSelectedTemplate,
    setListElementSelected,
    selectedFormat,
    selectedColorTemplate,
    setSelectedColorTemplate,
    loadingImage,
  } = useTemplate();

  const itemSelect = useRef();
  const { name, elements, backgroundImages: nameImg } = template;
  const { backgroundImages } = selectedTemplate;

  const handleSelect = () => {
    setSelectedColorTemplate(0);
    setListElementSelected([...elements]);
    setSelectedTemplate({ ...template });
  };

  const imageSize = {
    landscape: { width: '282px', height: '200px' },
    portrait: { width: '200px', height: '282px' },
    square: { width: '275px', height: '275px' },
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      ref={itemSelect}
      onClick={() => {
        handleSelect();
        setValueTab(0);
      }}
      style={{
        pointerEvents: `${loadingImage ? 'none' : ''}`,
        width: `${imageSize[selectedFormat].width}`,
        height: `${imageSize[selectedFormat].height}`,
        border:
          backgroundImages[0].image === nameImg[0].image
            ? '5px solid #08BAD0'
            : 'none',
      }}>
      {backgroundImages[0].image === nameImg[0].image ? (
        <img
          src={backgroundImages[selectedColorTemplate].image}
          className={classes.imgTemplate}
          alt={name}
        />
      ) : (
        <img
          src={nameImg[0].image}
          className={classes.imgTemplate}
          alt={name}
        />
      )}
    </Box>
  );
};

export default Templates;
