import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

import cfac22 from './cfac22';

const config = {
  apiKey: cfac22('FIREBASE_APIKEY'),
  authDomain: cfac22('FIREBASE_AUTHDOMAIN'),
  databaseURL: cfac22('FIREBASE_DATABASEURL'),
  projectId: cfac22('FIREBASE_PROJECTID'),
  storageBucket: cfac22('FIREBASE_STORAGEBUCKET'),
  messagingSenderId: cfac22('FIREBASE_MESSAGINGSENDERID'),
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseDatabase = firebase.database();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage();
