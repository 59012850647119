import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

export function financialCredit_v1(client, value, eventType, eventDescription) {
  return new Promise(function (resolve, reject) {
    getChannelTokenUidNull()
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_V1') + 'financialCredit_v1',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                uId: null,
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                channelTokenId: token,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              clientId: client,
              eventId: null,
              value,
              eventType,
              eventDescription,
            },
            {
              headers: {
                access_token: cfac22('ACCESS_TOKEN'),
              },
            }
          )
          .then((result) => {
            resolve(result.data.success);
          })
          .catch((error) => {
            //console.log('ERRO API FINANCIAL CREDIT ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
