import { pushDatabase } from "js/library/services/DatabaseManager";
import dispatcher from "js/core/dispatcher";

export const updateValidatorsAction = (dispatch, idEstabelecimento, idGestor, validador, isValidator) => {   
    if (isValidator === false) { 
        validador = null;
    }
    
    const data = {idEstabelecimento, idGestor};

 
    return dispatcher(dispatch, "VALIDATORS_INFORMATION", pushDatabase('Estabelecimento-Validador', validador, data, null) );
}