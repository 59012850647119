import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

import useStyles from "./styles";
import NotFoundAnimation from "styles/assets/animations/Not_Found.json";
import AnimationLoading from "js/containers/Loading/AnimationLoading";
import {
  checkUserStatus,
} from "js/library/services/AuthenticationManager";

const NotFound = () => {
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const bodyMovinOptions = {
    loop: true,
    autoplay: true,
    animationData: NotFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function statusUser() {
      try{
        const user = await checkUserStatus();
        setLoading(false);

        if (user) {
          setIsAuthenticatedUser(true);
          
        } else {
          setIsAuthenticatedUser(false);
  
        }

      } catch {
        setLoading(false);
        setIsAuthenticatedUser(false);

      }
     
    } 

    statusUser();
  }, []);

  if(loading){
    return (
      <div className="containerLoading">
        <AnimationLoading />
      </div>
    ) 
  }

  return (
    <div className={classes.contentNotFound}>
      <Grid container className={classes.root}> 
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} item  xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box className={classes.info}>
            <Typography variant="h1" component="h1" className={classes.title}>
              OPSS!!!
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Parece que você tentou acessar uma página que não existe!!!
            </Typography>
            <Link to={`${isAuthenticatedUser ? '/admin' : '/'}`} className={classes.link}>
              <Button className={classes.btnBack} variant="contained" color="primary">
                Voltar para {isAuthenticatedUser ? 'Home' : 'Login'}
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item className={classes.animation} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Lottie options={bodyMovinOptions} />
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;