import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { pushDatabase_v1 } from 'js/library/services/DatabaseManager';
import dispatcher from 'js/core/dispatcher';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { getStoreChangeRequest } from 'js/library/utils/API/Store/apiGetStoreChangeRequest';
import createStore from 'js/library/utils/API/Store/apiCreateStore';
import { getUserInfo } from 'js/library/utils/helpers';
import { createVoucherTemplate } from 'js/library/utils/API/apiCreateVoucherTemplate';

export const duplicatePromotionAction = (dispatch, storeKey) => {
  const duplicatePromotion = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const uId = getUserInfo().uId;

        const storeData = await getStoreChangeRequest(storeKey);
        const store = storeData.response.storeInfo;

        if (store.templateId) {
          const copyTemplateKey = firebaseDatabase.ref('Voucher-Template-v2').push().key;

          const template = await getVoucherTemplate(store.templateId);
          const templateResult = template.result;

          templateResult.titulo = templateResult.titulo + ' - Cópia';

          if (templateResult.groupId) {
            templateResult.detailsTitle = templateResult.detailsTitle + ' - Cópia';
            templateResult.nomeTemplate = templateResult.nomeTemplate + ' - Cópia';

            const group = await getDataListRestApi(`Voucher-Group/${templateResult.groupId}`);
            group.detailsTitle = group.detailsTitle + ' - Cópia';
            group.groupName = group.groupName + ' - Cópia';
            group.title = group.title + ' - Cópia';
            group.templates = {
              [copyTemplateKey]: templateResult.value,
            };

            const counterMatch = await getVoucherTemplate(group.counterMatch);
            counterMatch.detailsTitle = counterMatch.detailsTitle + ' - Cópia';
            counterMatch.titulo = counterMatch.titulo + ' - Cópia';

            const copyCounterMatch = await pushDatabase_v1('Voucher-Template-v2', counterMatch);
            group.counterMatch = copyCounterMatch.key;

            const copyGroup = await pushDatabase_v1('Voucher-Group', group);
            template.result.groupId = copyGroup.key;
          }

          delete templateResult.updateDate;
          delete templateResult.qtdDisponivel;

          const copyTemplate = await createVoucherTemplate(uId, templateResult);
          store.templateId = copyTemplate.data.result;
        }

        store.title = store.title + ' - Cópia';

        const idStore = firebaseDatabase.ref('Store').push().key;
        store.id = idStore;
        delete store.updateDate;
        const copyStore = await createStore(uId, idStore, store, null);

        resolve(copyStore);

        window.location.reload();
      } catch (err) {
        reject(err);
      }
    });
  };

  return dispatcher(dispatch, 'DUPLICATE_PROMOTION', duplicatePromotion());
};
