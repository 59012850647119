export default function reducer(
  state = {
    id: null,
    idGestor: null,
    nome: null,
    tags: null,
     vitrineOrdem: null,
    dataInclusao: null,
    dataAlteracao: null,
    endereco: null,
    lat: null,
    long: null,
    site: null,
    email: null,
    contatos: null,
    descricao: null,
    fotoThumb: null,
    listaFotos: null,
    funcionamento: null,
    isPublished: null,
    enableDate: null,
    disableDate: null,
    keyWordsSet: null,
    horarios: null,
    redesSociais: null,
    contatoTriibo: null,
    contatoComercial: null,
    cep: null,
    numero: null,
    complemento: null,
    logradouro: null,
    bairro: null,
    cidade: null,
    estado: null,
    signatureId: null,
    useFullLinks: null
  },
  action
) {
  switch (action.type) {
    case "ESTABLISHMENT_INFORMATION_PENDING": {
      return {
        ...state,
        id: null,
        idGestor: null,
        nome: null,
        tags: null,
        // vitrineOrdem: null,
        dataInclusao: null,
        dataAlteracao: null,
        endereco: null,
        lat: null,
        long: null,
        site: null,
        email: null,
        contatos: null,
        descricao: null,
        fotoThumb: null,
        listaFotos: null,
        funcionamento: null,
        isPublished: null,
        enableDate: null,
        disableDate: null,
        keyWordsSet: null,
        horarios: null,
        redesSociais: null,
        contatoTriibo: null,
        contatoComercial: null,
        cep: null,
        numero: null,
        complemento: null,
        logradouro: null,
        bairro: null,
        cidade: null,
        estado: null,
        signatureId: null,
        useFullLinks: null
      };
    }

    case "ESTABLISHMENT_INFORMATION_FULFILLED": {
      return {
        ...state,
        id: action.payload.id,
        idGestor: action.payload.idGestor,
        nome: action.payload.nome,
        tags: action.payload.tags,
         vitrineOrdem: action.payload.vitrineOrdem,
        dataInclusao: action.payload.dataInclusao,
        dataAlteracao: action.payload.dataAlteracao,
        endereco: action.payload.endereco,
        lat: action.payload.lat,
        long: action.payload.long,
        site: action.payload.site,
        email: action.payload.email,
        contatos: action.payload.contatos,
        descricao: action.payload.descricao,
        fotoThumb: action.payload.fotoThumb,
        listaFotos: action.payload.listaFotos,
        funcionamento: action.payload.funcionamento,
        isPublished: action.payload.isPublished,
        enableDate: action.payload.enableDate,
        disableDate: action.payload.disableDate,
        keyWordsSet: action.payload.keyWordsSet,
        horarios: action.payload.horarios,
        redesSociais: action.payload.redesSociais,
        contatoTriibo: action.payload.contatoTriibo,
        contatoComercial: action.payload.contatoComercial,
        cep: action.payload.cep,
        numero: action.payload.numero,
        complemento: action.payload.complemento,
        logradouro: action.payload.logradouro,
        bairro: action.payload.bairro,
        cidade: action.payload.cidade,
        estado: action.payload.estado,
        signatureId: action.payload.signatureId,
        useFullLinks: action.payload.useFullLinks
      };
    }

    case "ESTABLISHMENT_INFORMATION_REJECTED": {
      return {
        ...state,
        id: null,
        idGestor: null,
        nome: null,
        tags: null,
         vitrineOrdem: null,
        dataInclusao: null,
        dataAlteracao: null,
        endereco: null,
        lat: null,
        long: null,
        site: null,
        email: null,
        contatos: null,
        descricao: null,
        fotoThumb: null,
        listaFotos: null,
        funcionamento: null,
        isPublished: null,
        enableDate: null,
        disableDate: null,
        keyWordsSet: null,
        horarios: null,
        redesSociais: null,
        contatoTriibo: null,
        contatoComercial: null,
        cep: null,
        numero: null,
        complemento: null,
        logradouro: null,
        bairro: null,
        cidade: null,
        estado: null,
        signatureId: null,
        useFullLinks: null
      };
    }

    default:
      return state;
  }
}
