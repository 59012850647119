export default function reducer(
  state = {
    id: null,
    active: null,
    detailsTitle: null,
    detailsDescription: null,
    title: null,
    description: null,
    establishmentId: null,
    establishmentName: null,
    lat: null,
    long: null,
    dueDate: null,
    url: null,
    photo: null,
    thumbnail: null,
    type: null,
    droidWebView: null,
    iOSWebView: null,
    campanhaTriibo: null,
    checkoutCode: null,

    buttonLabel: null,
    buttonDescription: null,
    showButtonDescription: null,
    goToPlaceLabel: null,
    showGoToPlace: null,
  },
  action
) {
  switch (action.type) {
    case "STORE_BUSINESS_PARTNER_PENDING": {
      return {
        ...state,
        id: null,
        active: null,
        detailsTitle: null,
        detailsDescription: null,
        title: null,
        description: null,
        establishmentId: null,
        establishmentName: null,
        lat: null,
        long: null,
        dueDate: null,
        url: null,
        photo: null,
        thumbnail: null,
        type: null,
        droidWebView: null,
        iOSWebView: null,
        campanhaTriibo: null,
        checkoutCode: null,

        buttonLabel: null,
        buttonDescription: null,
        showButtonDescription: null,
        goToPlaceLabel: null,
        showGoToPlace: null,
      };
    }

    case "STORE_BUSINESS_PARTNER_FULFILLED": {
      return {
        ...state,
        id: action.payload.id,
        active: action.payload.active,
        detailsTitle: action.payload.detailsTitle,
        detailsDescription: action.payload.detailsDescription,
        title: action.payload.title,
        description: action.payload.description,
        establishmentId: action.payload.establishmentId,
        establishmentName: action.payload.establishmentName,
        lat: action.payload.lat,
        long: action.payload.long,
        dueDate: action.payload.dueDate,
        url: action.payload.url,
        photo: action.payload.photo,
        thumbnail: action.payload.thumbnail,
        type: action.payload.type,
        droidWebView: action.payload.droidWebView,
        iOSWebView: action.payload.iOSWebView,
        campanhaTriibo: action.payload.campanhaTriibo,
        checkoutCode: action.payload.checkoutCode,

        buttonLabel: action.payload.buttonLabel,
        buttonDescription: action.payload.buttonDescription,
        showButtonDescription: action.payload.showButtonDescription,
        goToPlaceLabel: action.payload.goToPlaceLabel,
        showGoToPlace: action.payload.showGoToPlace,
      };
    }

    case "STORE_BUSINESS_PARTNER_REJECTED": {
      return {
        ...state,
        id: null,
        active: null,
        detailsTitle: null,
        detailsDescription: null,
        title: null,
        description: null,
        establishmentId: null,
        establishmentName: null,
        lat: null,
        long: null,
        dueDate: null,
        url: null,
        photo: null,
        thumbnail: null,
        type: null,
        droidWebView: null,
        iOSWebView: null,
        campanhaTriibo: null,
        checkoutCode: null,

        buttonLabel: null,
        buttonDescription: null,
        showButtonDescription: null,
        goToPlaceLabel: null,
        showGoToPlace: null,
      };
    }

    default:
      return state;
  }
}
