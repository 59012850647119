import React, { useState } from "react";

import * as S from "./styles";

import { AiFillFile } from "react-icons/ai";

const Switcher = (props) => {
  const { setCurrentIndex } = props;
  const [buttonBoolean, setButtonBoolean] = useState(false);

  function handleSwitch(e, index) {
    e.preventDefault();
    setCurrentIndex(index);
  }

  return (
    <S.SwitcherContainer>
      <S.SwitcherButton
        onClick={(e) => {
          handleSwitch(e, 0);
          setButtonBoolean(false);
        }}
        disabled={!buttonBoolean}
      >
        Unitário
      </S.SwitcherButton>
      <S.SwitcherButton
        onClick={(e) => {
          handleSwitch(e, 1);
          setButtonBoolean(true);
        }}
        disabled={buttonBoolean}
      >
        <AiFillFile />{" "}{"Carregar lista"}
      </S.SwitcherButton>
    </S.SwitcherContainer>
  );
};

export default Switcher;
